import Home from "@/pages/static/Home";
import Login from "@/pages/static/Login";
import About from "@/pages/static/About";
import Contact from "@/pages/static/Contact";
import Dashboard from "@/pages/dashboards/MainDashboard";
import UserDashboard from "@/pages/dashboards/UserDashboard";
import SchoolDashboard from "@/pages/dashboards/SchoolDashboard";
import MySchoolDashboard from "@/pages/dashboards/MySchoolDashboard";
import store from "../store";
import ASCDashboard from "@/pages/dashboards/ASCDashboard";
import AscDataCollection from "@/pages/dashboards/AscDataCollection";
import ASCCollectionUpdate from "@/pages/dashboards/ASCCollectionUpdate";
import MOEReviewCollection from "@/components/dashboardPage/school/ASCDashboard/supervisorComponents/moeSubmissionReview";
import NMECDashboard from "@/pages/dashboards/NMECDashboard";
import AuditTrailDashboard from "@/pages/dashboards/AuditTrailDashboard";

import { createRouter, createWebHistory } from "vue-router";
import DataTableDashboard from "@/pages/dashboards/DataTableDashboard";
import ComparativeAnalysisDashboard from "@/pages/dashboards/ComparativeAnalysisDashboard";
import ASCInfoAndCoverageTables from "@/pages/dashboards/TableScreens/ASCInfoAndCoverageTables";
import StudentsPupilsTables from "@/pages/dashboards/TableScreens/StudentsPupilsTables";
import TeachersTables from "@/pages/dashboards/TableScreens/TeachersTables";
import FacilitiesTables from "@/pages/dashboards/TableScreens/FacilitiesTables"
import KeyIndicatorTables from "@/pages/dashboards/TableScreens/KeyIndicatorTables"
import PopulationProjectionTables from "@/pages/dashboards/TableScreens/PopulationProjectionTables"
import HistoricalDataDashboard from "@/pages/dashboards/HistoricalDataDashboard"
import ExploreDashboard from "@/pages/dashboards/ExploreDashboard"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/ascDashboard",
    name: "ASC Dashboard",
    component: ASCDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ascDashboard/dataCollection",
    name: "ascDataCollection",
    component: AscDataCollection,
    meta: {
      requiresAuth: true,
    },
  },


  {
    path: "/ascDashboard/collectionUpdate",
    name: "ASCCollectionUpdate",
    component: ASCCollectionUpdate,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/ascDashboard/MOEReviewcollection",
    name: "MOEReviewCollection",
    component: MOEReviewCollection,
    meta: {
      requiresAuth: true,
    },
  },
  
  
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/userDashboard",
    name: "User Dashboard",
    component: UserDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/schoolDashboard",
    name: "School Dashboard",
    component: SchoolDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mySchool",
    name: "My School",
    component: MySchoolDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nmecDashboard",
    name: "NMEC Dashboard",
    component: NMECDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dataTableDashboard",
    name: "Data Table Dashboard",
    component: DataTableDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/exploreDashboard",
    name: "Explore Dashboard",
    component: ExploreDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/historicalData",
    name: "Historical Data Dashboard",
    component: HistoricalDataDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/comparativeAnalysis",
    name: "Comparative Analysis Dashboard",
    component: ComparativeAnalysisDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/auditTrailDashboard",
    name: "Audit Trail Dashboard",
    component: AuditTrailDashboard,
  },
  {
    path: "/ascInfoAndCovTables",
    redirect: "/ascInfoAndCovTables/0"
  },
  {
    path: "/ascInfoAndCovTables/:index",
    name: "ascInfoAndCovTables",
    component: ASCInfoAndCoverageTables,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/studentsPupilTables/:index",
    name: "studentsPupilTables",
    component: StudentsPupilsTables,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/teachersTables/:index",
    name: "teachersTables",
    component: TeachersTables,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/facilitiesTables/:index",
    name: "facilitiesTables",
    component: FacilitiesTables,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/teachersTables/:index",
    name: "teachersTables",
    component: TeachersTables,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/keyIndicatorTables/:index",
    name: "keyIndicatorTables",
    component: KeyIndicatorTables,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/populationProjectionTables/:index",
    name: "populationProjectionTables",
    component: PopulationProjectionTables,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const path = window.location.path;
    if (!store.getters.isAuthenticated) {
      next({ name: "Login", query: { redirect: path } });
    } else next();
  } else next();
});
export default router;
