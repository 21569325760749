<template>
  <div>

    <senior-birth-cert-section
        v-if="currentStage === seniorStages[0]"
        @submissionDone="updateActiveStagesSenior"
    />

    <new-entrants-s-s-one
        v-if="currentStage === seniorStages[1]"
        @submissionDone="updateActiveStagesSenior"
    />

    <senior-enrolment-by-streams
        v-if="currentStage === seniorStages[2]"
        @submissionDone="updateActiveStagesSenior"
    />

    <senior-enrolment-by-age
        v-if="currentStage === seniorStages[3]"
        @submissionDone="updateActiveStagesSenior"
    />

    <student-flow-by-class
        school-class="SS1"
        v-if="currentStage === seniorStages[4]"
        @submissionDone="updateActiveStagesSenior"
    />

    <student-flow-by-class
        school-class="SS2"
        v-if="currentStage === seniorStages[5]"
        @submissionDone="updateActiveStagesSenior"
    />

    <student-flow-by-class
        school-class="SS3"
        v-if="currentStage === seniorStages[6]"
        @submissionDone="updateActiveStagesSenior"
    />

    <persons-with-special-needs-by-class
        school-class="SS1"
        v-if="currentStage === seniorStages[7]"
        @submissionDone="updateActiveStagesSenior"
    />

    <persons-with-special-needs-by-class
        school-class="SS2"
        v-if="currentStage === seniorStages[8]"
        @submissionDone="updateActiveStagesSenior"
    />

    <persons-with-special-needs-by-class
        school-class="SS3"
        v-if="currentStage === seniorStages[9]"
        @submissionDone="completed"
    />

    <div v-if="currentStage === null" class="notif-container">
      <p class="info">Please click on the continue button to go continue to the staff section.</p>
    </div>

  </div>
</template>

<script>
import SeniorBirthCertSection
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/senior/SeniorBirthCertSection";
import NewEntrantsSSOne
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/senior/NewEntrantsSSOne";
import SeniorEnrolmentByStreams
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/senior/SeniorEnrolmentByStreams";
import SeniorEnrolmentByAge
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/senior/SeniorEnrolmentByAge";
import StudentFlowByClass
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/StudentFlowByClass";
import PersonsWithSpecialNeedsByClass
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/PersonsWithSpecialNeedsByClass";
import dataCollectionMixin from "@/helpers/mixins/dataCollectionMixin";
import {mapMutations} from "vuex";

export default {
  name: "SeniorSecSchool",
  emits: ['reload'],
  mixins: [dataCollectionMixin],
  components: {
    PersonsWithSpecialNeedsByClass,
    StudentFlowByClass,
    SeniorEnrolmentByAge,
    SeniorEnrolmentByStreams,
    NewEntrantsSSOne,
    SeniorBirthCertSection
  },
  data() {
    return {
      seniorStages: [
        "birth cert senior", "new entrant senior", "enrolment by stream senior", "enrolment by age senior",
        "student flow for ss 1", "student flow for ss 2", "student flow for ss 3",
        "special needs ss 1", "special needs ss 2", "special needs ss 3"
      ]
    }
  },
  computed: {
    currentStage() {
      return this.$store.state.questionnaireService.activeEnrolmentStage
    }
  },
  mounted() {
    if(this.currentStage == null) {
      this.setActiveEnrolmentStage(this.seniorStages[0])
    }
  },
  methods: {
    ...mapMutations(['setActiveEnrolmentStage', 'setQAMessage']),

    updateActiveStagesSenior() {
      let newIndex = this.seniorStages.indexOf(this.currentStage) + 1
      let newStage = this.seniorStages[newIndex]
      this.setActiveEnrolmentStage(newStage)
    },

    completed() {      
      this.setQAMessage("You're done with the enrolment section. " +
          "Please click on the continue button to go continue to the staff section.")      
      this.setActiveEnrolmentStage(null)
      this.$emit('saveEnrolment')
    }
  }
}
</script>

<style scoped>
.notif-container {
  height: 16em;
}
</style>