<template>
  <div class="graph-container d-flex flex-column bg-white radius-full mt-4 mb-4">
    <div class="d-flex justify-content-between">
      <span class="font-sm fw-bold">Total Schools Chart</span>
      <a-select 
        :value="totalSchoolsChartOption"
        style="width: 120px"
        placeholder="View by"
        :options="viewByOptions.map(option => ({value: option}))"
        @change="totalSchoolsChartOptionChange"
      />
    </div>
    <div>
      <canvas class="charts" id="total-schools-chart" height="300"></canvas>
    </div>    
  </div>
</template>

<script>
import { computed, onMounted } from '@vue/runtime-dom'
import {Select} from "ant-design-vue"
import Chart from "chart.js/auto";
import { useStore } from 'vuex'
import { watch, ref } from 'vue'

export default {
  name: "TotalSchoolsChart",
  components: {
    "a-select": Select,
  },
  emits: ['reload'],
  setup(props, {emit}) {
    const totalSchoolsChartOption = ref('')
    const store = useStore()
    const params = ref()
    const viewByOptions = ['LGA', 'Level', 'Classification']
    const selectedOptions = ref('')
    const chartData = ref(null)
    const xAxisLabel = ref([])
    const dataSet = ref([])    
    const totalSchoolsChartData = computed(() => store.state.analysis.totalSchoolsData)

    const populateChart = () => {            
      dataSet.value = Object.values(totalSchoolsChartData.value.data.map(item => item.total_no_of_schools))      
      let checkLevel = Object.values(totalSchoolsChartData.value.data)[0].id.level
      let checkClassification = Object.values(totalSchoolsChartData.value.data)[0].id.classification      
      
      if (checkLevel !== undefined) {
        totalSchoolsChartOption.value = 'Level'
        xAxisLabel.value = Object.values(totalSchoolsChartData.value.data.map(item => item.id.level))
      } else if (checkClassification !== undefined) {
        totalSchoolsChartOption.value = 'Classification'
        xAxisLabel.value = Object.values(totalSchoolsChartData.value.data.map(item => item.id.classification))
      } else {
        totalSchoolsChartOption.value = 'LGA'
        xAxisLabel.value = Object.values(totalSchoolsChartData.value.data.map(item => item.id.lga))
      }
      
      chartData.value = {
        type: 'bar',
        data: {
          labels: xAxisLabel.value,
          datasets: [
            {
              label: "Total Schools",
              categoryPercentage: 0.3,
              borderRadius: 2,
              data: dataSet.value,
              backgroundColor: 'rgba(0, 242, 104, 1)',
            },
          ]
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        },
      }
    }
    
    watch(
      () => totalSchoolsChartData.value,
      () => {        
        if (totalSchoolsChartData.value !== null && chartData.value === null) {    
          emit('reload')
        }
      }
    )

    onMounted(() => {
      if (totalSchoolsChartData.value === null) {
        store.dispatch('fetchTotalSchoolsChartData', params.value)
      } else {
        populateChart()
        const totalSchoolsChart = document.getElementById('total-schools-chart')     
        new Chart(totalSchoolsChart, chartData.value)     
      }
    }) 

    const totalSchoolsChartOptionChange = (value) => {      
      const param = value.toLowerCase().replace(' ', '_')
      selectedOptions.value = param
      chartData.value = null
      store.dispatch('fetchTotalSchoolsChartData', param)
    }

    return {
      viewByOptions,
      selectedOptions,
      totalSchoolsChartOption,
      totalSchoolsChartOptionChange
    }
  },
}
</script>

<style scoped>
.charts {
  max-height: 300px;
}
</style>