<template>
  <div>
    <section id="junior-sec">
      <junior-student-book-section
          v-if="activeStage === stages[0]"
          @submissionDone="gotoNextStage" />
      <junior-teacher-book-section
          v-if="activeStage === stages[1]"
          @submissionDone="gotoNextStage" />
    </section>
  </div>
</template>

<script>
import JuniorTeacherBookSection
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/junior/JuniorTeacherBookSection";
import JuniorStudentBookSection
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/junior/JuniorStudentBookSection";
import {mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
export default {
  name: "BookJunior",
  components: {JuniorStudentBookSection, JuniorTeacherBookSection},
  setup() {
    const store = useStore()
    const stages = ["junior-student", "junior-teacher"]
    const activeStage = computed(() => store.state.questionnaireService.activeBookStage).value
    if (activeStage === null) {
      store.commit('setActiveBookStage', stages[0])
    }
    return {
      stages,
      activeStage
    }
  },
  methods: {
    ...mapMutations(['setActiveBookStage']),
    gotoNextStage() {
      const nextStageIndex = this.stages.indexOf(this.activeStage) + 1
      if (nextStageIndex < this.stages.length) {
        this.setActiveBookStage(this.stages[nextStageIndex])
      } else {
        this.$emit('juniorStagesCompleted')
      }
    }
  }
}
</script>

<style scoped>

</style>