<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"    
        :scroll="{ x: 1300 }"        
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal     
      :visible="showModal" 
      title="Update Enrolment By Age Junior Students" 
      @cancel="handleCancel">
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Update</a-button>
      </template>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="School Class"
            name="schoolClass"
          >
            <input type="text" class="form-control" :value="schoolClass" disabled>
          </a-form-item>          
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Gender"
            name="gender"
          >
            <input type="text" class="form-control" :value="gender" disabled>
          </a-form-item>          
        </a-col>
      </a-row>
            
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="Below Six Years"
            name="belowSixYears"
          >
            <input type="number" class="form-control" v-model="belowSixYears">
          </a-form-item>    
        </a-col>
        <a-col :span="11">        
          <a-form-item
            label="Six Years"
            name="belowSixYears"
          >
            <input type="number" class="form-control" v-model="sixYears">
          </a-form-item>        
        </a-col>
      </a-row>

      <a-row justify="space-between">
        <a-col :span="11">            
          <a-form-item
            label="Seven Years"
            name="sevenYears"
          >
            <input type="number" class="form-control" v-model="sevenYears">
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Eight Years"
            name="eightYears"
          >
            <input type="number" class="form-control" v-model="eightYears">
          </a-form-item>                
        </a-col>
      </a-row>  

      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="Nine Years"
            name="NineYears"
          >
            <input type="number" class="form-control" v-model="nineYears">
          </a-form-item>           
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Ten Years"
            name="TenYears"
          >
            <input type="number" class="form-control" v-model="tenYears">
          </a-form-item>           
        </a-col>
      </a-row> 

      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="Eleven Years"
            name="ElevenYears"
          >
            <input type="number" class="form-control" v-model="elevenYears">
          </a-form-item>           
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Above Eleven Years"
            name="AboveElevenYears"
          >
            <input type="number" class="form-control" v-model="aboveElevenYears">
          </a-form-item>           
        </a-col>
      </a-row>  
    </a-modal>    
  </div>
</template>

<script>
import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { onMounted, ref } from 'vue'
import { mapActions, mapMutations, useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'EnrolmentNewEntrantPryOneTable',
  emits: ['viewEnrolmentInfo'],
  props: ['title', 'schoolId'],
  mixins: [enrolmentMixinVue], 
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
  },
  setup() {
    const store = useStore()
    const showModal = ref(false)
    const selectedObject = ref(null)
    const schoolClass = ref()
    const gender = ref()
    const belowSixYears = ref()
    const sixYears = ref()
    const sevenYears = ref()
    const eightYears = ref()
    const nineYears = ref()
    const tenYears = ref()
    const elevenYears = ref()
    const aboveElevenYears = ref()

    const columns = [
      { title: 'School Class', dataIndex: 'schoolClass', fixed: 'left', width: 120 }, 
      { title: 'Gender', dataIndex: 'gender', fixed: 'left', width: 100 },
      { title: 'Below Six Years', dataIndex: 'belowSixYears', className: 'content-alignment' },
      { title: 'Six Years', dataIndex: 'sixYears', className: 'content-alignment' },
      { title: 'Seven Years', dataIndex: 'sevenYears', className: 'content-alignment' },    
      { title: 'Eight Years', dataIndex: 'eightYears', className: 'content-alignment' },    
      { title: 'Nine Years', dataIndex: 'nineYears', className: 'content-alignment' },                  
      { title: 'Ten Years', dataIndex: 'tenYears', className: 'content-alignment' },    
      { title: 'Eleven Years', dataIndex: 'elevenYears', className: 'content-alignment' },    
      { title: 'Above Eleven Years', dataIndex: 'aboveElevenYears', className: 'content-alignment' },                  
      {
        title: 'operation',
        dataIndex: 'operation', 
        fixed: 'right',  
        width: 100   
      },
    ]

    return {    
      columns,      
      selectedObject,
      schoolClass,
      gender,

      showModal,

      belowSixYears,
      sixYears,
      sevenYears,
      eightYears,
      nineYears,
      tenYears,
      elevenYears,
      aboveElevenYears,
    }
  },
  computed: {
    pryNewEntrantData() {
      return this.$store.state.enrolment.pryNewEntrantData
    },
    dataSource() {
      const dataItems = []      
      if (this.pryNewEntrantData != null) {
        const data = Object.values(this.pryNewEntrantData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolClass: element["schoolClass"],
                gender: element["gender"],
                belowSixYears: element["belowSix6Year"],
                sixYears: element["six6Year"],
                sevenYears: element["seven7Year"],
                eightYears: element["eight8Year"],
                nineYears: element["nine9Year"],
                tenYears: element["ten10Year"],
                elevenYears: element["eleven11Year"],
                aboveElevenYears: element["aboveEeleven11Year"] 
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {
    ...mapActions(['updateRequest', 'fetchPryNewEntrant']),
    ...mapMutations(['setPryNewEntrantData']),

    editRecord(key) {
      const detail = this.pryNewEntrantData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolClass = detail.schoolClass
        this.gender = detail.gender

        this.belowSixYears = detail.belowSix6Year
        this.sixYears = detail.six6Year
        this.sevenYears = detail.seven7Year
        this.eightYears = detail.eight8Year
        this.nineYears = detail.nine9Year
        this.tenYears = detail.ten10Year 
        this.elevenYears = detail.eleven11Year
        this.aboveElevenYears = detail.aboveEeleven11Year               
      } 
    },

    handleSubmit() {
      const payload = {
        endpoint: `questionaire/enrollment/section-b2/${this.selectedObject.id}`,
        body: {
          belowSix6Year: Number(this.belowSixYears),
          six6Year: Number(this.sixYears),
          seven7Year: Number(this.sevenYears),
          eight8Year: Number(this.eightYears),
          nine9Year: Number(this.nineYears),   
          ten10Year: Number(this.tenYears),
          eleven11Year: Number(this.elevenYears),
          aboveEeleven11Year: Number(this.aboveElevenYears),          
        }
      }
      this.updateRequest(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.belowThreeYears = ''
            this.threeYears = ''
            this.fourYears = '' 
            this.fiveYears = ''
            this.aboveFiveYears = ''
            
            const type = this.updateMessage.status
                        
            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });

            if (type === 'success') {              
              this.setPryNewEntrantData(null)
              this.fetchPryNewEntrant()
            }            
          }
        })  
    },

    handleCancel() {
      this.showModal = false
    },
  },
  mounted(){
    if(!this.schoolId) {
      this.$store.commit('setPryNewEntrantData', null)
      this.$store.dispatch('fetchPryNewEntrant')
    } else {
      this.$store.commit('setPryNewEntrantData', null)
      this.$store.dispatch('fetchPryNewEntrant', this.schoolId)
    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
