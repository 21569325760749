<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"    
        :scroll="{ x: 1300 }"        
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>

function replaceUnderScore(str) {
  if (str !== undefined) return str.replaceAll("_", " ")
}

import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { returnYesOrNo } from '@/helpers/utils/helper_functions.js'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'RegisteredClassroomsTable',
  emits: ['viewClassroomInfo'],
  props: ['title'],
  mixins: [enrolmentMixinVue],
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
  },
  setup() {
    const endpoint = 'f/classroom-info'
    const store = useStore()
    const message = computed(() => store.state.classroom.updateMessage)
    onMounted(() => {      
      store.dispatch('fetchRequest', endpoint)
        .then(() => {
          if (message.value !== null) {
            notification.open({
              type: message.value.status,
              message: "Registered Classrooms",
              description: message.value.message
            })
          }
        })
    })

    const columns = [
      { title: 'School Level', dataIndex: 'schoolLevel', fixed: 'left', width: 120 }, 
      { title: 'Description', dataIndex: 'description', fixed: 'left', width: 100 },
      { title: 'School Type', dataIndex: 'schoolType', className: 'content-alignment', width: 150 },
      { title: 'Year Of Construction', dataIndex: 'yearOfConstruction', className: 'content-alignment', width: 150 },
      { title: 'Condition', dataIndex: 'condition', className: 'content-alignment', width: 150 },    
      { title: 'Floor Material', dataIndex: 'floorMaterial', className: 'content-alignment', width: 150 },    
      { title: 'Wall Material', dataIndex: 'wallMaterial', className: 'content-alignment', width: 150 },
      { title: 'Roof Material', dataIndex: 'roofMaterial', className: 'content-alignment', width: 150 },                  
      { title: 'Enough Seats', dataIndex: 'enoughSeat', className: 'content-alignment', width: 150 },    
      { title: 'Good Blackboard', dataIndex: 'goodBlackBoard', className: 'content-alignment', width: 150 },
      { title: 'Shares Workshop', dataIndex: 'shared', className: 'content-alignment', width: 150 },              
      { title: 'Lenght In Meter', dataIndex: 'lenInMtr', className: 'content-alignment', width: 150 },
      { title: 'Width In Meter', dataIndex: 'widthInMtr', className: 'content-alignment', width: 150 },               
      {
        title: 'operation',
        dataIndex: 'operation', 
        fixed: 'right',  
        width: 100   
      },
    ]

    return {    
      columns,
    }
  },
  computed: {
    classroomData() {
      return this.$store.state.classroom.classroomData
    },

    dataSource() {
      const dataItems = []      
      if (this.classroomData != null) {
        const data = Object.values(this.classroomData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolLevel: replaceUnderScore(element["schoolLevel"]),
                description: replaceUnderScore(element["description"]),
                schoolType: replaceUnderScore(element["schoolType"]),
                yearOfConstruction: element["yearOfConstruction"],
                condition: replaceUnderScore(element["condition"]),
                floorMaterial: replaceUnderScore(element["floorMaterial"]),
                wallMaterial: replaceUnderScore(element["wallMaterial"]),
                roofMaterial: replaceUnderScore(element["roofMaterial"]),
                enoughSeat: returnYesOrNo(element["enoughSeat"]),
                goodBlackBoard: returnYesOrNo(element["goodBlackBoard"]),
                shared: returnYesOrNo(element["shared"]),
                lenInMtr: element["lenInMtr"],
                widthInMtr: element["widthInMtr"],
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {
    editRecord(key) {
      const detail = this.classroomData.find(record => record["id"] === key)
      this.$emit('viewClassroomInfo', detail)
    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
