<template>
  <div>
    <welcome-view />
    <tabs-view />
    <benefits-view />
    <how-it-works-view />
  </div>
</template>
<script>
import WelcomeView from "../../components/homePage/WelcomeView";
import TabsView from "../../components/homePage/TabsView";
import BenefitsView from "../../components/homePage/BenefitsView";
import HowItWorksView from "../../components/homePage/HowItWorksView";

export default {
  name: "Home",
  components: {
    WelcomeView,
    TabsView,
    BenefitsView,
    HowItWorksView,
  },  
};
</script>