<template>
  <div>
    <section class="mb-5 mx-4 pt-4">
      <p class="font-sm fw-bold">Number of core subject Teachers' textbooks available in the School provided by government.</p>
      <div class="d-flex mb-2">
        <div class="col-5"><p class="font-sm fw-medium">Text book</p></div>
        <span class="col me-1 text-center font-sm fw-medium">SSS 1</span>
        <span class="col me-1 text-center font-sm fw-medium">SSS 2</span>
        <span class="col me-1 text-center font-sm fw-medium">SSS 3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">English</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Mathematics</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Biology</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Civic Education</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="civicEducationSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="civicEducationSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="civicEducationSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "SeniorTeacherBookSection",
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const englishSSOneTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "SS1",
      number: ''
    }
    const englishSSTwoTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "SS2",
      number: ''
    }
    const englishSSThreeTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "SS3",
      number: ''
    }
    const mathSSOneTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "SS1",
      number: ''
    }
    const mathSSTwoTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "SS2",
      number: ''
    }
    const mathSSThreeTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "SS3",
      number: ''
    }
    const biologySSOneTeacher = {
      user: "TEACHER",
      subject: "BIOLOGY",
      schoolClass: "SS1",
      number: ''
    }
    const biologySSTwoTeacher = {
      user: "TEACHER",
      subject: "BIOLOGY",
      schoolClass: "SS2",
      number: ''
    }
    const biologySSThreeTeacher = {
      user: "TEACHER",
      subject: "BIOLOGY",
      schoolClass: "SS3",
      number: ''
    }
    const civicEducationSSOneTeacher = {
      user: "TEACHER",
      subject: "CIVIC_EDUCATION",
      schoolClass: "SS1",
      number: ''
    }
    const civicEducationSSTwoTeacher = {
      user: "TEACHER",
      subject: "CIVIC_EDUCATION",
      schoolClass: "SS2",
      number: ''
    }
    const civicEducationSSThreeTeacher = {
      user: "TEACHER",
      subject: "CIVIC_EDUCATION",
      schoolClass: "SS3",
      number: ''
    }

    return {
      englishSSOneTeacher,
      englishSSTwoTeacher,
      englishSSThreeTeacher,
      mathSSOneTeacher,
      mathSSTwoTeacher,
      mathSSThreeTeacher,
      biologySSOneTeacher,
      biologySSTwoTeacher,
      biologySSThreeTeacher,
      civicEducationSSOneTeacher,
      civicEducationSSTwoTeacher,
      civicEducationSSThreeTeacher,

      schoolInfo,
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    }
  },
  methods: {
    ...mapActions(['submitStudentTeacherBook']),
    submitForm() {
      const subjects = [
        this.englishSSOneTeacher,
        this.englishSSTwoTeacher,
        this.englishSSThreeTeacher,
        this.mathSSOneTeacher,
        this.mathSSTwoTeacher,
        this.mathSSThreeTeacher,
        this.biologySSOneTeacher,
        this.biologySSTwoTeacher,
        this.biologySSThreeTeacher,
        this.civicEducationSSOneTeacher,
        this.civicEducationSSTwoTeacher,
        this.civicEducationSSThreeTeacher,
      ]
      this.submitStudentTeacherBook({id: this.schoolInfo.id, body: subjects})
          .then(() => {
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>