<template>
  <div class="d-flex flex-column">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div class="d-flex justify-content-between align-items-center my-4">
      <span class="font-normal fw-bold">School staff table</span>
    </div>

    <div class="d-flex radius-half bg-white shadow-sm p-3 mb-3">
      <div class="col-6 d-flex flex-column pe-3">
        <span class="font-tiny fw-bold mb-2">What are you looking for?</span>
        <div class="custom-input-group bg-accent">
          <span class="p-1">
            <span class="material-icons font-normal pt-1 grey-shade">
              search
            </span>
          </span>
          <input
              type="text"
              class="ph-sm no-outline-input font-tiny fw-bolder"
              placeholder="Search"
              v-model="searchField"
          />
        </div>
      </div>
      <div class="col-3 d-flex flex-column pe-2">
        <span class="font-tiny fw-bold mb-2">Filter</span>
        <div>
          <select
              class="form-select text-dark"
              aria-label="Default select example"
              v-model="searchFilter"
          >
            <option class="bg-white grey-shade" selected>All</option>
            <option class="bg-white" value="description">Description/Label</option>
            <option class="bg-white" value="yearOfConstruction">Year of construction</option>
            <option class="bg-white" value="condition">Condition</option>
            <option class="bg-white" value="length">length</option>
            <option class="bg-white" value="width">width</option>
          </select>
        </div>
      </div>
      <div class="col-3 d-flex flex-column ps-2">
        <span class="font-tiny fw-bold mb-2">Sort</span>
        <div>
          <select
              class="form-select text-dark"
              aria-label="Default select example"
              v-model="sortOption"
          >
            <option class="bg-white grey-shade" selected>All</option>
            <option class="bg-white" value="description">Description/Label</option>
            <option class="bg-white" value="yearOfConstruction">Year of construction</option>
            <option class="bg-white" value="condition">Condition</option>
            <option class="bg-white" value="length">length</option>
            <option class="bg-white" value="width">width</option>
          </select>
        </div>
      </div>
    </div>

    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-hover font-sm" aria-label="Users table">
        <thead>
        <tr>
          <th class="align-middle py-3 ps-4 big-cell first" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Class description</span>
              <div class="vertical-icons ms-2">
                <span 
                  @click="descriptionArrowUpClicked"
                  class="material-icons material-icon-spacing font-medium pointer-no-bg">
                  arrow_drop_up
                </span>
                <span 
                  @click="descriptionArrowDownClicked"
                  class="material-icons material-icon-spacing font-medium pointer-no-bg">
                  arrow_drop_down
                </span>
              </div>
            </div>
          </th>
          <th class="align-middle py-3 medium-cell" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Year of construction</span>
              <div class="vertical-icons ms-2">
                <span 
                  @click="yearArrowUpClicked"
                  class="material-icons material-icon-spacing font-medium pointer-no-bg">
                  arrow_drop_up
                </span>
                <span 
                  @click="yearArrowDownClicked"
                  class="material-icons material-icon-spacing font-medium pointer-no-bg">
                  arrow_drop_down
                </span>
              </div>
            </div>
          </th>
          <th class="align-middle py-3 other-cell" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Condition</span>
              <div class="vertical-icons ms-2">
                <span 
                  @click="conditionArrowUpClicked"
                  class="material-icons material-icon-spacing font-medium pointer-no-bg">
                  arrow_drop_up
                </span>
                <span 
                  @click="conditionArrowDownClicked"
                  class="material-icons material-icon-spacing font-medium pointer-no-bg">
                  arrow_drop_down
                </span>
              </div>
            </div>
          </th>
          <th class="align-middle py-3 other-cell" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Length (m)</span>
              <div class="vertical-icons ms-2">
                <span 
                  @click="lengthArrowUpClicked"
                  class="material-icons material-icon-spacing font-medium pointer-no-bg">
                  arrow_drop_up
                </span>
                <span 
                  @click="lengthArrowDownClicked"
                  class="material-icons material-icon-spacing font-medium pointer-no-bg">
                  arrow_drop_down
                </span>
              </div>
            </div>
          </th>
          <th class="align-middle py-3 other-cell" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Width (m)</span>
              <div class="vertical-icons ms-2">
                <span 
                  @click="widthArrowUpClicked"
                  class="material-icons material-icon-spacing font-medium pointer-no-bg">
                  arrow_drop_up
                </span>
                <span 
                  @click="widthArrowDownClicked"
                  class="material-icons material-icon-spacing font-medium pointer-no-bg">
                  arrow_drop_down
                </span>
              </div>
            </div>
          </th>
          <th class="align-middle py-3 other-cell" scope="col"></th>
        </tr>
        </thead>
        <tbody v-if="filteredClasses.length > 0">
        <tr
            class="pointer-no-bg"
            v-for="(classroom, index) in filteredClasses"
            :key="index"
            @click="staffProfile(classroom)"
        >
          <td class="py-3 ps-4 table-cell first">
            {{ classroom.description}}
          </td>
          <td class="py-3 table-cell">{{ classroom.yearOfConstruction }}</td>
          <td class="py-3 table-cell">{{ classroom.condition }}</td>
          <td class="py-3 table-cell">{{ classroom.length }}</td>
          <td class="py-3 table-cell">{{ classroom.width }}</td>
          <td class="text-end dropend pe-2 py-3">
              <span @click.stop class="p-3">
                <span 
                  class="material-icons-outlined grey-accent px-3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  more_vert
                </span>
                <ul
                    class="
                    dropdown-menu
                    action-menu
                    bg-accent
                    shadow
                    font-tiny
                    radius-half
                  "
                    aria-labelledby="options"
                >
                  <li @click="staffProfile(classroom)">
                    <span class="dropdown-item grey-accent">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        person
                      </span>
                      Profile
                    </span>
                  </li>
                  <li @click="editUser(classroom)">
                    <span class="dropdown-item grey-accent" href="#">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        edit
                      </span>
                      Edit
                    </span>
                  </li>
                </ul>
              </span>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td colspan="8" class="text-center fw-medium">
            {{ message }}
          </td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end align-items-center font-sm pb-3 px-4">
        <span>Rows per page:
          <select v-model="numberOfRows" v-on:change="onRowsChanged($event)" class="outline-clear" id="row">
            <option value="5">5</option>
            <option value="12">12</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>

        <span class="font-sm ms-3">1 to {{numberOfRows}} of {{totalRecord}}
          <span
              @click="fetchPrev"
              class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_back_ios
          </span>
          <span
              @click="fetchNext"
              class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_forward_ios
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import {stringifiedRoles} from "@/helpers/utils/user_roles";
import {resetAll} from "@/helpers/utils/helper_functions";
import {ref} from "@vue/runtime-core";
import {mapActions, mapMutations, useStore} from "vuex";
import {onMounted, toRef} from "vue";

export default {
  name: "ClassroomTable",
  components: { ClipLoader },
  props: ['title', 'schoolId'],
  setup(props) {
    const store = useStore()
    const schoolId = toRef(props, 'schoolId')

    onMounted(() => {
      store.dispatch('fetchSchoolStaff', schoolId.value)
    })
    const roles = stringifiedRoles
    const numberOfRows = ref(8)

    return {
      numberOfRows,
      roles,
      lodash
    };
  },
  data() {
    return {
      searchFilter: '',
      searchField: '',
      sortOption: '',
      selectedUser: null,
      arrowsState: {
        descriptionArrowUp: false,
        descriptionArrowDown: false,
        yearArrowUp: false,
        yearArrowDown: false,
        conditionArrowUp: false,
        conditionArrowDown: false,
        lengthArrowUp: false,
        lengthArrowDown: false,
        widthArrowUp: false,
        widthArrowDown: false
      }
    }
  },
  computed: {
    fetchedContent() {
      return this.$store.state.auth.schoolStaff
    },

    classrooms() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.content
      }
      return []
    },

    message() {
      return this.$store.state.auth.message ? this.$store.state.auth.message : "No record found!"
    },

    filteredClasses() {
      const { classrooms, searchField, filterBy, sortOption } = this
      const { descriptionArrowUp, descriptionArrowDown, yearArrowUp, yearArrowDown,
        conditionArrowUp, conditionArrowDown, lengthArrowUp, lengthArrowDown,
        widthArrowUp, widthArrowDown
      } = this.arrowsState

      if (descriptionArrowUp) {
        this.sortUsers(classrooms, "description")
      }
      if (descriptionArrowDown) {
        this.sortUsers(classrooms, "description", "DSC")
      }
      if (yearArrowUp) {
        this.sortUsers(classrooms, "yearOfConstruction")
      }
      if (yearArrowDown) {
        this.sortUsers(classrooms, "yearOfConstruction", "DSC")
      }
      if (conditionArrowUp) {
        this.sortUsers(classrooms, "condition")
      }
      if (conditionArrowDown) {
        this.sortUsers(classrooms, "condition", "DSC")
      }
      if (lengthArrowUp) {
        this.sortUsers(classrooms, "length")
      }
      if (lengthArrowDown) {
        this.sortUsers(classrooms, "length", "DSC")
      }
      if (widthArrowUp) {
        this.sortUsers(classrooms, "width")
      }
      if (widthArrowDown) {
        this.sortUsers(classrooms, "width", "DSC")
      }

      this.sortUsers(classrooms, sortOption)
      if (classrooms !== undefined) {
        return classrooms.filter(classroom => filterBy(classroom).toLowerCase().includes(searchField.toLowerCase()))
      } else {
        return []
      }

    },

    totalRecord() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.totalElements
      }
      return 0
    },

    currentPage() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.pageNumber + 1
      }
      return 0
    },

    previousPage() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.pageNumber - 1
      }
      return 0
    },

    loading() {
      return this.$store.state.auth.loading
    },

    requestError() {
      console.log(this.$store.state.auth.message);
      return this.$store.state.auth.message
    },
  },
  methods: {
    ...mapActions(["fetchSchoolStaff", "activateDeactivate"]),
    ...mapMutations(["setLoadingStatus", "setNumberOfRows"]),
    staffProfile(classroom) {
      this.$emit("showStaffProfile", classroom);
    },

    // editUser(classroomId) {
    //   this.$emit("editUser", classroomId);
    // },

    fetchPagedUsers() {
      console.log("Fetching classrooms...")
      // this.fetchSchoolStaff();
    },

    onRowsChanged(event) {
      const number = event.target.value
      console.log("I'm now going to fetch " + number + " classrooms.");
      this.setNumberOfRows(number)
    },

    fetchNext() {
      if (this.fetchedContent.last === false) {
        // this.fetchSchoolStaff(this.currentPage)
      }
    },

    fetchPrev() {
      if (this.fetchedContent.first === false) {
        // this.fetchUsers(this.previousPage)
      }
    },

    filterBy(classroom) {
      if (this.searchFilter === "" || this.searchFilter === "description") {
        return classroom.description
      }
      else if (this.searchFilter === "yearOfConstruction") {
        return classroom.yearOfConstruction
      }
      else if (this.searchFilter === "condition") {
        return classroom.condition
      }
      else if (this.searchFilter === "length") {
        return classroom.length
      }
      else if (this.searchFilter === "width") {
        return classroom.width
      }
    },

    sortUsers(classrooms, sortFilter, order = "ASC") {
      if (sortFilter) {
        switch (sortFilter) {
          case "description":
            return classrooms.sort((classroom1, classroom2) => {
              if (order === "ASC") {
                return (classroom1.description > classroom2.description) ? 1 : ((classroom2.description > classroom1.description) ? -1 : 0)
              }
              return (classroom2.description > classroom1.description) ? 1 : ((classroom1.description > classroom2.description) ? -1 : 0)
            });

          case "yearOfConstruction":
            return classrooms.sort((classroom1, classroom2) => {
              if (order === "ASC") {
                return (classroom1.yearOfConstruction > classroom2.yearOfConstruction) ? 1 : ((classroom2.yearOfConstruction > classroom1.yearOfConstruction) ? -1 : 0)
              }
              return (classroom2.yearOfConstruction > classroom1.yearOfConstruction) ? 1 : ((classroom1.yearOfConstruction > classroom2.yearOfConstruction) ? -1 : 0)
            });

          case "condition":
            return classrooms.sort((classroom1, classroom2) => {
              if (order === "ASC") {
                return (classroom1.condition > classroom2.condition) ? 1 : ((classroom2.condition > classroom1.condition) ? -1 : 0)
              }
              return (classroom2.condition > classroom1.condition) ? 1 : ((classroom1.condition > classroom2.condition) ? -1 : 0)
            });

          case "length":
            return classrooms.sort((classroom1, classroom2) => {
              if (order === "ASC") {
                return (classroom1.length > classroom2.length) ? 1 : ((classroom2.length > classroom1.length) ? -1 : 0)
              }
              return (classroom2.length > classroom1.length) ? 1 : ((classroom1.length > classroom2.length) ? -1 : 0)
            });

          case "width":
            return classrooms.sort((classroom1, classroom2) => {
              if (order === "ASC") {
                return (classroom1.width > classroom2.width) ? 1 : ((classroom2.width > classroom1.width) ? -1 : 0)
              }
              return (classroom2.width > classroom1.width) ? 1 : ((classroom1.width > classroom2.width) ? -1 : 0)
            });
        }
      }
    },

    descriptionArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.descriptionArrowUp = true
    },

    descriptionArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.descriptionArrowDown = true
    },

    yearArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.yearArrowUp = true
    },

    yearArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.yearArrowDown = true
    },

    conditionArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.conditionArrowUp = true
    },

    conditionArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.conditionArrowDown = true
    },

    lengthArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.lengthArrowUp = true
    },

    lengthArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.lengthArrowDown = true
    },

    widthArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.widthArrowUp = true
    },

    widthArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.widthArrowDown = true
    },

    // showUpdateRoleModal(classroom) {
    //   this.selectedUser = classroom
    //   this.$emit("showUpdateRoleModal", classroom)
    // },
  },
};
</script>

<style scoped>
.big-cell {
  width: 28%;
}
.medium-cell {
  width: 24%;
}
.other-cells {
  width: 12%
}
</style>