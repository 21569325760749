<template>
  <div class="graph-container d-flex flex-column bg-white radius-full mt-4 mb-2">
    <div class="d-flex justify-content-between">
      <span class="font-sm fw-bold">Repeater rate.</span>
      <a-select 
        :value="repeaterRateOption"
        style="width: 120px"
        placeholder="View by"
        size="small"
        :options="viewByOptions.map(option => ({value: option}))"
        @change="enrolmentRateChange"
      />
    </div>
    <canvas class="charts" id="repeater-rate-chart" height="300"></canvas>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from 'vue'
import {Select} from "ant-design-vue"
import Chart from "chart.js/auto";
import { useStore } from 'vuex'


export default {
  name: "RepeatersRateChart",
  components: {"a-select": Select},
    setup(props, {emit}) {
    const repeaterRateOption = ref('')
    const store = useStore()
    const params = ref()
    const viewByOptions = ['LGA', 'Gender', 'School class', 'Classification']
    const selectedOptions = ref('')
    const chartData = ref(null)
    const xAxisLabel = ref([])
    const dataSet = ref([])    
    const repeaterRate = computed(() => store.state.analysis.repeaterRate)

    const populateChart = () => {            
      dataSet.value = Object.values(repeaterRate.value.data.map(item => item.value))
      let checkLGA = Object.values(repeaterRate.value.data)[0].id.lga
      let checkSchoolClass = Object.values(repeaterRate.value.data)[0].id.school_class
      let checkClassification = Object.values(repeaterRate.value.data)[0].id.classification
      if (checkLGA !== undefined) {
        repeaterRateOption.value = 'LGA'
        xAxisLabel.value = Object.values(repeaterRate.value.data.map(item => item.id.lga))      
      } else if (checkSchoolClass !== undefined) {
        repeaterRateOption.value = 'School Class'
        xAxisLabel.value = Object.values(repeaterRate.value.data.map(item => item.id.school_class))
      } else if (checkClassification !== undefined) {
        repeaterRateOption.value = 'Classification'
        xAxisLabel.value = Object.values(repeaterRate.value.data.map(item => item.id.classification))
      } else {
        repeaterRateOption.value = 'Gender'
        xAxisLabel.value = Object.values(repeaterRate.value.data.map(item => item.id.gender))
      }
      
      chartData.value = {
        type: 'bar',
        data: {
          labels: xAxisLabel.value,
          datasets: [
            {
              label: "Repeaters Rate",
              categoryPercentage: 0.3,
              borderRadius: 2,
              data: dataSet.value,
              backgroundColor: 'rgba(0, 242, 104, 1)',
            },
          ]
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        },
      }
    }
    
    watch(
      () => repeaterRate.value,
      () => {        
        if (repeaterRate.value !== null && chartData.value === null) {    
          emit('reload')
        }
      }
    )

    onMounted(() => {
      if (repeaterRate.value === null) {
        store.dispatch('fetchRepeaterRateChart', params.value)
      } else {
        populateChart()
        const repeaterRateChart = document.getElementById('repeater-rate-chart')     
        new Chart(repeaterRateChart, chartData.value)     
      }
    }) 

    const enrolmentRateChange = (value) => {      
      const param = value.toLowerCase().replace(' ', '_')
      selectedOptions.value = param
      chartData.value = null
      store.dispatch('fetchRepeaterRateChart', param)
    }

    return {
      viewByOptions,
      selectedOptions,
      repeaterRateOption,
      enrolmentRateChange
    }
  },
}
</script>