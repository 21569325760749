<script>
export default {
  computed: {
    loading() {
      return this.$store.state.enrolment.updateLoading
    },

    updateMessage() {
      return this.$store.state.enrolment.updateMessage
    },
    
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  }
}
</script>