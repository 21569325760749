<template>
  <div class="d-dlex flex-column">
    <p class="fw-bold grey-accent my-4 pt-2">Change Password</p>
    <div
      class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white shadow-sm"
    >
      <div class="row justify-content-between px-2 pb-4">
        <div class="col-6 mt-3 px-3">
          <label for="dob" class="form-label font-sm fw-normal">
            Username
          </label>
          <input type="text" class="form-control" v-model="userInfo.username" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label for="password" class="form-label font-sm fw-normal">
            Current Password
          </label>
          <input
            type="password"
            class="form-control"
            placeholder=""
            v-model="userInfo.currentPassword"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label for="newPassword" class="form-label font-sm fw-normal">
            New Password
          </label>
          <input
            type="password"
            class="form-control"
            placeholder=""
            v-model="userInfo.newPassword"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label for="confPassword" class="form-label font-sm fw-normal">
            Confirm Password
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Nigerian"
            v-model="userInfo.confPassword"
          />
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mb-3">
        <button
          class="custom-btn my-2 font-tiny"
          @click="$emit('updateAccountSettings')"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AccountSetting",
  data() {
    return {
      userInfo: {
        username: "",
        currentPassword: "",
        newPassword: "",
        confPassword: "",
      },
    };
  },
};
</script>
