<template>
  <div class="d-dlex flex-column">
    <p class="fw-bold grey-accent my-4 pt-2">Edit Dashboard Setting</p>
    <div
      class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white shadow-sm"
    >
      <div class="row justify-content-center px-2 pb-4">
        <div class="col-10 mt-3 px-3">
          <label for="dob" class="form-label font-sm fw-normal">
            Interface language
          </label>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
          >
            <option class="bg-white" selected>Select language</option>
            <option class="bg-white" value="1">English</option>
            <option class="bg-white" value="2">Yoruba</option>
          </select>
        </div>
        <div class="col-10 mt-3 px-3">
          <label for="password" class="form-label font-sm fw-normal">
            Database language
          </label>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
          >
            <option class="bg-white" selected>Select language</option>
            <option class="bg-white" value="1">English</option>
            <option class="bg-white" value="2">Yoruba</option>
          </select>
        </div>
        <div class="col-10 mt-3 px-3">
          <label for="style" class="form-label font-sm fw-normal">
            Style
          </label>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
          >
            <option class="bg-white" selected>Select style</option>
            <option class="bg-white" value="1">dark</option>
            <option class="bg-white" value="2">light</option>
          </select>
        </div>
        <div class="col-10 mt-3 px-3">
          <label for="confPassword" class="form-label font-sm fw-normal">
            Enable messages and email notifications
          </label>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
          >
            <option class="bg-white" selected>Yes</option>
            <option class="bg-white" value="1">No</option>
          </select>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mb-3">
        <button
          class="custom-btn my-2 font-tiny"
          @click="$emit('updateAccountSettings')"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DashboardSetting",
  data() {
    return {
      userInfo: {
        username: "",
        currentPassword: "",
        newPassword: "",
        confPassword: "",
      },
    };
  },
};
</script>
