<template>
  <div>
    <section class="mb-5 mx-4 pt-4">
      <p class="font-sm fw-bold">Number of core subject textbooks available to pupils provided by government.</p>
      <div class="d-flex mb-2">
        <div class="col-3"><p class="font-sm fw-medium">Text book</p></div>
        <span class="col me-1 text-center font-sm fw-medium">JSS 1</span>
        <span class="col me-1 text-center font-sm fw-medium">JSS 2</span>
        <span class="col me-1 text-center font-sm fw-medium">JSS 3</span>
        <span class="col me-1 text-center font-sm fw-medium">SSS 1</span>
        <span class="col me-1 text-center font-sm fw-medium">SSS 2</span>
        <span class="col me-1 text-center font-sm fw-medium">SSS 3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">English</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSThreeStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Mathematics</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSThreeStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Biology</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologyJSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologyJSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologyJSSThreeStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Physics</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsJSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsJSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsJSSThreeStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Chemistry</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistryJSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistryJSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistryJSSThreeStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistrySSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistrySSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistrySSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Basic Technology</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSThreeStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "VocationalStudentBook",
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const englishJSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "JSS1",
      number: ''
    }
    const englishJSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "JSS2",
      number: ''
    }
    const englishJSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "JSS3",
      number: ''
    }
    const englishSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "SS1",
      number: ''
    }
    const englishSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "SS2",
      number: ''
    }
    const englishSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "SS3",
      number: ''
    }

    const mathJSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "JSS1",
      number: ''
    }
    const mathJSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "JSS2",
      number: ''
    }
    const mathJSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "JSS3",
      number: ''
    }
    const mathSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "SS1",
      number: ''
    }
    const mathSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "SS2",
      number: ''
    }
    const mathSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "SS3",
      number: ''
    }

    const biologyJSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "BIOLOGY",
      schoolClass: "JSS1",
      number: ''
    }
    const biologyJSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "BIOLOGY",
      schoolClass: "JSS2",
      number: ''
    }
    const biologyJSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "BIOLOGY",
      schoolClass: "JSS3",
      number: ''
    }
    const biologySSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "BIOLOGY",
      schoolClass: "SS1",
      number: ''
    }
    const biologySSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "BIOLOGY",
      schoolClass: "SS2",
      number: ''
    }
    const biologySSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "BIOLOGY",
      schoolClass: "SS3",
      number: ''
    }
    const physicsJSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "PHYSICS",
      schoolClass: "JSS1",
      number: ''
    }
    const physicsJSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "PHYSICS",
      schoolClass: "JSS2",
      number: ''
    }
    const physicsJSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "PHYSICS",
      schoolClass: "JSS3",
      number: ''
    }
    const physicsSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "PHYSICS",
      schoolClass: "SS1",
      number: ''
    }
    const physicsSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "PHYSICS",
      schoolClass: "SS2",
      number: ''
    }
    const physicsSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "PHYSICS",
      schoolClass: "SS3",
      number: ''
    }

    const chemistryJSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "CHEMISTRY",
      schoolClass: "JSS1",
      number: ''
    }
    const chemistryJSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "CHEMISTRY",
      schoolClass: "JSS2",
      number: ''
    }
    const chemistryJSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "CHEMISTRY",
      schoolClass: "JSS3",
      number: ''
    }
    const chemistrySSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "CHEMISTRY",
      schoolClass: "SS1",
      number: ''
    }
    const chemistrySSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "CHEMISTRY",
      schoolClass: "SS2",
      number: ''
    }
    const chemistrySSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "CHEMISTRY",
      schoolClass: "SS3",
      number: ''
    }

    const basicTechJSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS1",
      number: ''
    }
    const basicTechJSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS2",
      number: ''
    }
    const basicTechJSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS3",
      number: ''
    }
    const basicTechSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "SS1",
      number: ''
    }
    const basicTechSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "SS2",
      number: ''
    }
    const basicTechSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "SS3",
      number: ''
    }

    return {
      englishJSSOneStudent,
      englishJSSTwoStudent,
      englishJSSThreeStudent,
      englishSSOneStudent,
      englishSSTwoStudent,
      englishSSThreeStudent,

      mathJSSOneStudent,
      mathJSSTwoStudent,
      mathJSSThreeStudent,
      mathSSOneStudent,
      mathSSTwoStudent,
      mathSSThreeStudent,

      biologyJSSOneStudent,
      biologyJSSTwoStudent,
      biologyJSSThreeStudent,
      biologySSOneStudent,
      biologySSTwoStudent,
      biologySSThreeStudent,

      physicsJSSOneStudent,
      physicsJSSTwoStudent,
      physicsJSSThreeStudent,
      physicsSSOneStudent,
      physicsSSTwoStudent,
      physicsSSThreeStudent,

      chemistryJSSOneStudent,
      chemistryJSSTwoStudent,
      chemistryJSSThreeStudent,
      chemistrySSOneStudent,
      chemistrySSTwoStudent,
      chemistrySSThreeStudent,

      basicTechJSSOneStudent,
      basicTechJSSTwoStudent,
      basicTechJSSThreeStudent,
      basicTechSSOneStudent,
      basicTechSSTwoStudent,
      basicTechSSThreeStudent,

      schoolInfo,
    }
  },
  methods: {
    ...mapActions(['submitStudentTeacherBook']),

    submitForm() {
      const subjects = [
        this.englishJSSOneStudent,
        this.englishJSSTwoStudent,
        this.englishJSSThreeStudent,
        this.englishSSOneStudent,
        this.englishSSTwoStudent,
        this.englishSSThreeStudent,

        this.mathJSSOneStudent,
        this.mathJSSTwoStudent,
        this.mathJSSThreeStudent,
        this.mathSSOneStudent,
        this.mathSSTwoStudent,
        this.mathSSThreeStudent,

        this.biologyJSSOneStudent,
        this.biologyJSSTwoStudent,
        this.biologyJSSThreeStudent,
        this.biologySSOneStudent,
        this.biologySSTwoStudent,
        this.biologySSThreeStudent,

        this.physicsJSSOneStudent,
        this.physicsJSSTwoStudent,
        this.physicsJSSThreeStudent,
        this.physicsSSOneStudent,
        this.physicsSSTwoStudent,
        this.physicsSSThreeStudent,

        this.chemistryJSSOneStudent,
        this.chemistryJSSTwoStudent,
        this.chemistryJSSThreeStudent,
        this.chemistrySSOneStudent,
        this.chemistrySSTwoStudent,
        this.chemistrySSThreeStudent,

        this.basicTechJSSOneStudent,
        this.basicTechJSSTwoStudent,
        this.basicTechJSSThreeStudent,
        this.basicTechSSOneStudent,
        this.basicTechSSTwoStudent,
        this.basicTechSSThreeStudent,
      ]
      this.submitStudentTeacherBook({id: this.schoolInfo.id, body: subjects})
          .then(() => {
            console.log("Submission done")
          })
    }
  }
}
</script>

<style scoped>

</style>