<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"    
        :scroll="{ x: 1300 }"        
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >               
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal     
      :visible="showModal" 
      title="Update Available Classroom" 
      @cancel="handleCancel">
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Update</a-button>
      </template>
      <a-form layout="vertical">          
        <a-row justify="space-between">
          <a-col :span="24">
            <a-form-item
              label="School Level"
              name="schoolLevel"
            >
              <input type="text" class="form-control" :value="schoolLevel" disabled>
            </a-form-item>          
          </a-col>
        </a-row>
        
        <a-row justify="space-between">        
          <a-col :span="24">        
            <a-form-item
              label="Are Classes Held Outside"
              name="classesAreHeldOutside"
            >
              <a-select              
                :value="classesAreHeldOutside"
                :bordered="false"
                style="width: 100%; height: 40px; borderRadius: 8px; border: none; backgroundColor: #f4f5fc; color: #212121DB"
                @change="handleChange"
              >
                <a-select-option value='true'>YES</a-select-option>
                <a-select-option value='false'>NO</a-select-option>
              </a-select>              
            </a-form-item>        
          </a-col>
        </a-row>

        <a-row justify="space-between">  
          <a-col :span="24">
            <a-form-item
              label="Number Of Classrooms In School"
              name="numberOfClassrooms"
            >
              <input type="number" class="form-control" v-model="numberOfClassrooms">
            </a-form-item>    
          </a-col>
        </a-row>

        <a-row justify="space-between">
          <a-col :span="24">            
            <a-form-item
              label="Number Of Playrooms For ECCD"
              name="numberOfPlayrooms"
            >
              <input type="text" class="form-control" v-model="numberOfPlayrooms" :disabled="!selectedSchoolHasECCD">
            </a-form-item>
          </a-col>
        </a-row>   
      </a-form>
    </a-modal>
  </div>
</template>

<script>

function returnYesOrNo(someBool) {
  return (someBool) ? "YES" : "NO"
}

function returnTrueOrFalse(value) {
  if (value === "YES") return true
  else if (value === "NO") return false
  else return value
}

function checkECCD(record) {
  return (record === null) ? "No ECCD" : record
}

import { Table, Button, Space, Modal, Select, SelectOption, Form, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { onMounted, ref, computed } from 'vue'
import { mapActions, useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'EnrolmentByAgeJuniorTable',
  emits: ['viewEnrolmentInfo'],
  props: ['title'],
  mixins: [enrolmentMixinVue], 
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form-item': FormItem,
    'a-form': Form,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
    'a-select': Select,
    'a-select-option': SelectOption,
  },
  setup() {
    const endpoint = 'f/classrooms'
    const store = useStore()  
    const showModal = ref(false)
    const selectedObject = ref(null)
    const schoolLevel = ref()    
    const numberOfClassrooms = ref()
    const classesAreHeldOutside = ref()
    const numberOfPlayrooms = ref()
    const selectedSchoolHasECCD = ref(false)
    const message = computed(() => store.state.classroom.updateMessage)

    onMounted(() => {    
      store.dispatch('fetchRequest', endpoint)
      .then(() => {        
        if (message.value != null) {
          const type = message.value.status
          if (type === 'error') {
            notification.open({
              type: type,
              message: 'Update Message',
              description: message.value.message
            });   
          }
        }
      })
    })

    const handleChange = value => {
      classesAreHeldOutside.value = value
    };

    const columns = [
      { title: 'School Level', dataIndex: 'schoolLevel', fixed: 'left', width: 120 },       
      { title: 'Number Of Classrooms', dataIndex: 'numberOfClassrooms', className: 'content-alignment' },
      { title: 'Are classes Held Outside', dataIndex: 'classesAreHeldOutside', className: 'content-alignment' },
      { title: 'Number Of Playrooms for ECCD', dataIndex: 'numberOfPlayrooms', className: 'content-alignment' },    
      
      {
        title: 'operation',
        dataIndex: 'operation', 
        fixed: 'right',  
        width: 100   
      },
    ]

    return {    
      columns,   
      showModal,
      selectedObject,
      schoolLevel,    
      numberOfClassrooms,
      classesAreHeldOutside,
      numberOfPlayrooms,
      selectedSchoolHasECCD,

      handleChange,
      dropdownStyle: {
        style: {
          width: '100%',
          height: '56px',
          borderRadius: 8,          
          border: 'none',
          backgroundColor: '#f4f5fc',
          color: '#212121DB',
        }
      }
      
    }
  },
  computed: {
    classroomData() {
      return this.$store.state.classroom.classroomData
    },
    updateMessage() {
      return this.$store.state.classroom.updateMessage
    },
    dataSource() {
      const dataItems = []      
      if (this.classroomData != null) {
        const data = Object.values(this.classroomData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolLevel: element["schoolLevel"],                             
                numberOfClassrooms: element["howManyClassroomsAreThereInTheSchool"],
                classesAreHeldOutside: returnYesOrNo(element["areThereanyClassesHeldOUtside"]),
                numberOfPlayrooms: checkECCD(element["howManyPlayRoomsAreTHereInSchoolForEccd"]),
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {
    ...mapActions(['fetchRequest', 'updateSubmissionRequest']),

    editRecord(key) {
      const detail = this.classroomData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolLevel = detail.schoolLevel        
        this.numberOfClassrooms = detail.howManyClassroomsAreThereInTheSchool
        this.classesAreHeldOutside = returnYesOrNo(detail.areThereanyClassesHeldOUtside)
        this.numberOfPlayrooms = checkECCD(detail.howManyPlayRoomsAreTHereInSchoolForEccd)

        if (detail.schoolLevel === 'PRE_PRIMARY') {
          this.selectedSchoolHasECCD = true
        } else {
          this.selectedSchoolHasECCD = false
        }
      } 
    },

    handleSubmit() {      
      const body = {
        howManyClassroomsAreThereInTheSchool: Number(this.numberOfClassrooms),
        areThereanyClassesHeldOUtside: JSON.parse(returnTrueOrFalse(this.classesAreHeldOutside.toString())),                     
      }
      if (this.selectedSchoolHasECCD) {
        body['howManyPlayRoomsAreTHereInSchoolForEccd'] = Number(this.numberOfPlayrooms)
      }

      const payload = {        
        endpoint: `f/classrooms/${this.selectedObject.id}`,
        body: body
      }

      this.updateSubmissionRequest(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.numberOfClassrooms = ''
            this.classesAreHeldOutside = ''
            this.numberOfPlayrooms = '' 
            
            const type = this.updateMessage.status
                        
            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });

            if (type === 'success') {              
              this.fetchRequest("f/classrooms/")
            }
          }
        })  
    },

    handleCancel() {
      this.showModal = false
    },
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
