<template>
  <div>
    <p class="my-3 fw-bold">Comparative Analysis</p>
    <div class="d-flex bg-white radius-full mt-3 p-5">
      <div class="col d-flex flex-column pe-2">
        <span class="font-tiny fw-bold mb-2">Compare by</span>
        <div>
          <a-select 
            :value="compareBy"
            mode="multiple"
            style="width: 100%; background-color: #f4f5fc"
            placeholder="Select option(s)"
            :options="compareByOptions"
          />
        </div>
      </div>
      <div class="col d-flex flex-column pe-2">
        <span class="font-tiny fw-bold mb-2">Select LGA(s)</span>
        <div>
          <a-select 
            :value="selectedLGs"
            mode="multiple"
            style="width: 100%"
            placeholder="Please select LGA(s)"
            :options="lgs"
          />
        </div>
      </div>
      <div class="col d-flex flex-column pe-2">
        <span class="font-tiny fw-bold mb-2">Select School(s)</span>
        <div>
          <a-select 
            :value="selectedSchools"
            mode="multiple"
            style="width: 100%"
            placeholder="Please select school(s)"
            :options="schools"
          />
        </div>
      </div>
      <div class="col d-flex align-items-end">
        <button 
          @click="gotoCADashboard"
          class="ca-btn font-tiny fw-medium">
          Compare options
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import stateNLG from "@/helpers/utils/state_local.json";
import {Select} from "ant-design-vue"
import {useRouter} from "vue-router";
import {ref} from "vue"

export default {
  name: "ComparativeAnalysis",
  components: {
    "a-select": Select,
  },
  setup() {
    const router = useRouter()
    const state = Object.entries(stateNLG)[35]
    const localGovs = Object.values(state[1].locals)
    const compareBy = ref([])
    const selectedLGs = ref([])
    const selectedSchools = ref([])

    const schools = ref([])
    const options = ["LGAs", "Schools"]
    const compareByOptions = options.map((item) => ({value: item}))
    const lgs = localGovs.map((lga) => ({value: lga.name}))

    const handleCompareByChanged = () => {
      console.log("Value has changed");
    }

    const gotoCADashboard = () => {         
      router.push({ path: "comparativeAnalysis" })
    }

    return { 
      lgs,
      compareByOptions,
      schools,

      compareBy,
      selectedLGs,                
      selectedSchools,
      
      gotoCADashboard,
      handleCompareByChanged
    }
  },
}
</script>

<style scoped>
.dropdowns-container {
  height: 300px;
}

.ca-btn {
  width: 100%;
  height: 32px;
  border-radius: 5px;
  color: #fff;
  background-color: #0030FF;
  border: none;
  outline: none;
}
</style>