<template>
  <div>
    <section class="mb-5 mx-4 pt-4">
      <p class="font-sm fw-bold">Number of core subject Teachers' textbooks available in the School provided by government.</p>
      <div class="d-flex mb-2">
        <div class="col-3"><p class="font-sm fw-medium">Text book</p></div>
        <span class="col me-1 text-center font-sm fw-medium">JSS 1</span>
        <span class="col me-1 text-center font-sm fw-medium">JSS 2</span>
        <span class="col me-1 text-center font-sm fw-medium">JSS 3</span>
        <span class="col me-1 text-center font-sm fw-medium">SSS 1</span>
        <span class="col me-1 text-center font-sm fw-medium">SSS 2</span>
        <span class="col me-1 text-center font-sm fw-medium">SSS 3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">English</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSThreeTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Mathematics</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSThreeTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Biology</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologyJSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologyJSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologyJSSThreeTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Physics</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsJSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsJSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsJSSThreeTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="physicsSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Chemistry</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistryJSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistryJSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistryJSSThreeTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistrySSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistrySSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="chemistrySSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Basic Technology</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSThreeTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "VocationalTeacherBook",
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const englishJSSOneTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "JSS1",
      number: ''
    }
    const englishJSSTwoTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "JSS2",
      number: ''
    }
    const englishJSSThreeTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "JSS3",
      number: ''
    }
    const englishSSOneTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "SS1",
      number: ''
    }
    const englishSSTwoTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "SS2",
      number: ''
    }
    const englishSSThreeTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "SS3",
      number: ''
    }

    const mathJSSOneTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "JSS1",
      number: ''
    }
    const mathJSSTwoTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "JSS2",
      number: ''
    }
    const mathJSSThreeTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "JSS3",
      number: ''
    }
    const mathSSOneTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "SS1",
      number: ''
    }
    const mathSSTwoTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "SS2",
      number: ''
    }
    const mathSSThreeTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "SS3",
      number: ''
    }

    const biologyJSSOneTeacher = {
      user: "TEACHER",
      subject: "BIOLOGY",
      schoolClass: "JSS1",
      number: ''
    }
    const biologyJSSTwoTeacher = {
      user: "TEACHER",
      subject: "BIOLOGY",
      schoolClass: "JSS2",
      number: ''
    }
    const biologyJSSThreeTeacher = {
      user: "TEACHER",
      subject: "BIOLOGY",
      schoolClass: "JSS3",
      number: ''
    }
    const biologySSOneTeacher = {
      user: "TEACHER",
      subject: "BIOLOGY",
      schoolClass: "SS1",
      number: ''
    }
    const biologySSTwoTeacher = {
      user: "TEACHER",
      subject: "BIOLOGY",
      schoolClass: "SS2",
      number: ''
    }
    const biologySSThreeTeacher = {
      user: "TEACHER",
      subject: "BIOLOGY",
      schoolClass: "SS3",
      number: ''
    }
    const physicsJSSOneTeacher = {
      user: "TEACHER",
      subject: "PHYSICS",
      schoolClass: "JSS1",
      number: ''
    }
    const physicsJSSTwoTeacher = {
      user: "TEACHER",
      subject: "PHYSICS",
      schoolClass: "JSS2",
      number: ''
    }
    const physicsJSSThreeTeacher = {
      user: "TEACHER",
      subject: "PHYSICS",
      schoolClass: "JSS3",
      number: ''
    }
    const physicsSSOneTeacher = {
      user: "TEACHER",
      subject: "PHYSICS",
      schoolClass: "SS1",
      number: ''
    }
    const physicsSSTwoTeacher = {
      user: "TEACHER",
      subject: "PHYSICS",
      schoolClass: "SS2",
      number: ''
    }
    const physicsSSThreeTeacher = {
      user: "TEACHER",
      subject: "PHYSICS",
      schoolClass: "SS3",
      number: ''
    }

    const chemistryJSSOneTeacher = {
      user: "TEACHER",
      subject: "CHEMISTRY",
      schoolClass: "JSS1",
      number: ''
    }
    const chemistryJSSTwoTeacher = {
      user: "TEACHER",
      subject: "CHEMISTRY",
      schoolClass: "JSS2",
      number: ''
    }
    const chemistryJSSThreeTeacher = {
      user: "TEACHER",
      subject: "CHEMISTRY",
      schoolClass: "JSS3",
      number: ''
    }
    const chemistrySSOneTeacher = {
      user: "TEACHER",
      subject: "CHEMISTRY",
      schoolClass: "SS1",
      number: ''
    }
    const chemistrySSTwoTeacher = {
      user: "TEACHER",
      subject: "CHEMISTRY",
      schoolClass: "SS2",
      number: ''
    }
    const chemistrySSThreeTeacher = {
      user: "TEACHER",
      subject: "CHEMISTRY",
      schoolClass: "SS3",
      number: ''
    }

    const basicTechJSSOneTeacher = {
      user: "TEACHER",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS1",
      number: ''
    }
    const basicTechJSSTwoTeacher = {
      user: "TEACHER",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS2",
      number: ''
    }
    const basicTechJSSThreeTeacher = {
      user: "TEACHER",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS3",
      number: ''
    }
    const basicTechSSOneTeacher = {
      user: "TEACHER",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "SS1",
      number: ''
    }
    const basicTechSSTwoTeacher = {
      user: "TEACHER",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "SS2",
      number: ''
    }
    const basicTechSSThreeTeacher = {
      user: "TEACHER",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "SS3",
      number: ''
    }

    return {
      englishJSSOneTeacher,
      englishJSSTwoTeacher,
      englishJSSThreeTeacher,
      englishSSOneTeacher,
      englishSSTwoTeacher,
      englishSSThreeTeacher,

      mathJSSOneTeacher,
      mathJSSTwoTeacher,
      mathJSSThreeTeacher,
      mathSSOneTeacher,
      mathSSTwoTeacher,
      mathSSThreeTeacher,

      biologyJSSOneTeacher,
      biologyJSSTwoTeacher,
      biologyJSSThreeTeacher,
      biologySSOneTeacher,
      biologySSTwoTeacher,
      biologySSThreeTeacher,

      physicsJSSOneTeacher,
      physicsJSSTwoTeacher,
      physicsJSSThreeTeacher,
      physicsSSOneTeacher,
      physicsSSTwoTeacher,
      physicsSSThreeTeacher,

      chemistryJSSOneTeacher,
      chemistryJSSTwoTeacher,
      chemistryJSSThreeTeacher,
      chemistrySSOneTeacher,
      chemistrySSTwoTeacher,
      chemistrySSThreeTeacher,

      basicTechJSSOneTeacher,
      basicTechJSSTwoTeacher,
      basicTechJSSThreeTeacher,
      basicTechSSOneTeacher,
      basicTechSSTwoTeacher,
      basicTechSSThreeTeacher,

      schoolInfo,
    }
  },
  methods: {
    ...mapActions(['submitStudentTeacherBook']),

    submitForm() {
      const subjects = [
        this.englishJSSOneTeacher,
        this.englishJSSTwoTeacher,
        this.englishJSSThreeTeacher,
        this.englishSSOneTeacher,
        this.englishSSTwoTeacher,
        this.englishSSThreeTeacher,

        this.mathJSSOneTeacher,
        this.mathJSSTwoTeacher,
        this.mathJSSThreeTeacher,
        this.mathSSOneTeacher,
        this.mathSSTwoTeacher,
        this.mathSSThreeTeacher,

        this.biologyJSSOneTeacher,
        this.biologyJSSTwoTeacher,
        this.biologyJSSThreeTeacher,
        this.biologySSOneTeacher,
        this.biologySSTwoTeacher,
        this.biologySSThreeTeacher,

        this.physicsJSSOneTeacher,
        this.physicsJSSTwoTeacher,
        this.physicsJSSThreeTeacher,
        this.physicsSSOneTeacher,
        this.physicsSSTwoTeacher,
        this.physicsSSThreeTeacher,

        this.chemistryJSSOneTeacher,
        this.chemistryJSSTwoTeacher,
        this.chemistryJSSThreeTeacher,
        this.chemistrySSOneTeacher,
        this.chemistrySSTwoTeacher,
        this.chemistrySSThreeTeacher,

        this.basicTechJSSOneTeacher,
        this.basicTechJSSTwoTeacher,
        this.basicTechJSSThreeTeacher,
        this.basicTechSSOneTeacher,
        this.basicTechSSTwoTeacher,
        this.basicTechSSThreeTeacher,
      ]
      this.submitStudentTeacherBook({id: this.schoolInfo.id, body: subjects})
          .then(() => {
            console.log("Submission done")
          })
    }
  }
}
</script>

<style scoped>

</style>