<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2"> Characteristics of Public Pre-Primary and Primary schools with various needs</p>

    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-tiny" aria-label="Users table">
        <thead>
        <tr>
          <td class="align-middle lg-cell py-1 ps-4">
            <div class="d-flex align-items-baseline">
              <span class="font-sm fw-bold align-text-bottom"></span>
            </div>
          </td>
          <td class="align-middle lg-cell py-1 ps-4">
            <div class="d-flex align-items-baseline">
              <span class="font-sm fw-bold align-text-bottom"></span>
            </div>
          </td>
          <td class="align-middle py-1" colspan="2">
            <div class="d-flex flex-nowrap justify-content-center">
              <span class="font-sm fw-bold">In need of Major Repairs</span>
            </div>
          </td>
          <td class="align-middle py-1" colspan="2">
            <div class="d-flex flex-nowrap justify-content-center">
              <span class="font-sm fw-bold">With insufficient Seating</span>
            </div>
          </td>
          <td class="align-middle py-1" colspan="2">
            <div class="d-flex flex-nowrap justify-content-center">
              <span class="font-sm fw-bold">Without a good blackboard</span>
            </div>
          </td>
          <td class="align-middle lg-cell py-1 ps-4">
            <div class="d-flex align-items-baseline">
              <span class="font-sm fw-bold align-text-bottom"></span>
            </div>
          </td>
          <td class="align-middle py-1" colspan="2">
            <div class="d-flex flex-nowrap justify-content-center">
              <span class="font-sm fw-bold">Schools where some classes are held outside</span>
            </div>
          </td>
          <td class="align-middle py-1" colspan="2">
            <div class="d-flex flex-nowrap justify-content-center">
              <span class="font-sm fw-bold">Schools without a health facility</span>
            </div>
          </td>
        </tr>
        <tr>
          <th class="align-middle py-1 ps-4 table-cell">LGA</th>
          <th class="align-middle py-1 table-cell">Total number of usable classrooms</th>
          <th class="align-middle py-1 cell-size" scope="col">Num</th>
          <th class="align-middle py-1 cell-size" scope="col">%</th>
          <th class="align-middle py-1 cell-size" scope="col">Num</th>
          <th class="align-middle py-1 cell-size" scope="col">%</th>
          <th class="align-middle py-1 cell-size" scope="col">Num</th>
          <th class="align-middle py-1 cell-size" scope="col">%</th>
          <th class="align-middle py-1 cell-size" scope="col">Total number of schools</th>
          <th class="align-middle py-1 cell-size" scope="col">Num</th>
          <th class="align-middle py-1 cell-size" scope="col">%</th>
          <th class="align-middle py-1 cell-size" scope="col">Num</th>
          <th class="align-middle py-1 cell-size" scope="col">%</th>
        </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg"
            v-for="(data, index) in tableData"
            :key="index" 
          >
            <td class="py-3 ps-4 table-cell">{{ data.lga }}</td>
            <td class="py-3 cell-size">{{ data.total_number_of_usable_classrooms }}</td>
            <td class="py-3 cell-size">{{ data.number_in_need_of_major_repairs }}</td>
            <td class="py-3 cell-size">{{ data.percentage_in_need_of_major_repairs + "%"}}</td>
            <td class="py-3 cell-size">{{ data.number_with_insufficient_seating }}</td>
            <td class="py-3 cell-size">{{ data.percentage_with_insufficient_seating + "%" }}</td>
            <td class="py-3 cell-size">{{ data.number_without_a_good_blackboard }}</td>
            <td class="py-3 cell-size">{{ data.percentage_without_a_good_blackboard + "%"}}</td>
            <td class="py-3 cell-size">{{ data.total_number_of_schools }}</td>
            <td class="py-3 cell-size">{{ data.number_schools_where_some_classes_are_held_outside }}</td>
            <td class="py-3 cell-size">{{ data.percentage_schools_where_some_classes_are_held_outside + "%"}}</td>
            <td class="py-3 cell-size">{{ data.number_schools_without_a_health_facility }}</td>
            <td class="py-3 cell-size">{{ data.percentage_schools_without_a_health_facility + "%"}}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="13" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="d-flex justify-content-end align-items-center font-sm pb-3 px-4">
          <span class="font-tiny">Rows per page:
          <select v-model="numberOfRows" v-on:change="onRowsChanged($event)" class="outline-clear font-tiny" id="row">
            <option value="12">12</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>
        <span class="font-tiny ms-3">1 to {{numberOfRows}} of {{totalRecord}}
            <span
                @click="fetchPrev"
                class="material-icons-outlined font-tiny pointer-no-bg ms-2"
            >
              arrow_back_ios
            </span>
            <span
                @click="fetchNext"
                class="material-icons-outlined font-tiny pointer-no-bg ms-2"
            >
              arrow_forward_ios
            </span>
          </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import stateNLG from "@/helpers/utils/state_local.json";
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "CharacteristicsOfPublicPrePryAndPry",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 3, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })
   
    return {
      stateNLG,
    }
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }  
}
</script>

<style scoped>

</style>