const state = {
  errorCode: null,
  errorMessage: null,
  errorDescription: null
}

const mutations = {
  setErrorCode(state, value) {
    state.errorCode = value
  },

  setErrorMessage(state, message) {
    state.errorMessage = message
  },

  setErrorDescription(state, description) {
    state.errorDescription = description
  },
}

export default {
  state,
  mutations
}