<template>
  <div>
    <section class="mb-5 px-lg-5">
      <p class="font-sm fw-bold">Number of students by subject in {{ schoolClass }}</p>
      <div class="d-flex mb-2">
        <div class="col-6"></div>
        <span class="col-3 text-center font-sm fw-normal">Male</span>
        <span class="col-3 text-center font-sm fw-normal">Female</span>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">English</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleEnglish.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleEnglish.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Mathematics</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleMathematics.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleMathematics.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Social studies</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleSocialStudies.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleSocialStudies.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Basic science</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleBasicScience.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleBasicScience.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Civic education</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleCivicEducation.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleCivicEducation.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Cultural and creative art</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleCulturalAndCreativeArt.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleCulturalAndCreativeArt.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Physical and health education</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMalePHE.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemalePHE.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Computer</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleComputer.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleComputer.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Basic technology</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleBasicTech.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleBasicTech.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Agriculture</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleAgriculture.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleAgriculture.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Home economics</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleHomeEconomics.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleHomeEconomics.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Business studies</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleBusinessStudies.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleBusinessStudies.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">French language</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleFrench.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleFrench.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Arabic</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleArabic.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleArabic.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Christian religious studies</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleCRS.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleCRS.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Islamic religious studies</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleIRS.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleIRS.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Igbo</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleIgbo.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleIgbo.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Yoruba</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleYoruba.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleYoruba.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Hausa</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleHausa.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleHausa.number" type="number" aria-label="female">
        </div>
      </div>
    </section>
    <div class="d-flex flex-column align-items-center justify-content-center mb-4">
      <button
        class="custom-btn font-tiny fw-bold"
        @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {toRefs} from "vue";
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "NumberOfSubjectJunior",
  props: ['schoolClass'],
  setup(props) {
    const store = useStore()
    const { schoolClass }  = toRefs(props)
    const currentClass = (schoolClass.value).replace(" ", "").toUpperCase()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const subjects = {
        numberOfMaleEnglish: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "ENGLISH",
          number: ''
        },
        numberOfFemaleEnglish: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "ENGLISH",
          number: ''
        },
        numberOfMaleMathematics: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "MATHEMATICS",
          number: ''
        },
        numberOfFemaleMathematics: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "MATHEMATICS",
          number: ''
        },
        numberOfMaleSocialStudies: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "SOCIAL_STUDY",
          number: ''
        },
        numberOfFemaleSocialStudies: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "SOCIAL_STUDY",
          number: ''
        },
        numberOfMaleBasicScience: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "BASIC_SCIENCE",
          number: ''
        },
        numberOfFemaleBasicScience: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "BASIC_SCIENCE",
          number: ''
        },
        numberOfMaleCivicEducation: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "CIVIC_EDUCATION",
          number: ''
        },
        numberOfFemaleCivicEducation: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "CIVIC_EDUCATION",
          number: ''
        },
        numberOfMaleCulturalAndCreativeArt: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "CULTURAL_CREATIVE_ART",
          number: ''
        },
        numberOfFemaleCulturalAndCreativeArt: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "CULTURAL_CREATIVE_ART",
          number: ''
        },
        numberOfMalePHE: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "PHYSICAL_AND_HEALTH_EDUCATION",
          number: ''
        },
        numberOfFemalePHE: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "PHYSICAL_AND_HEALTH_EDUCATION",
          number: ''
        },
        numberOfMaleComputer: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "COMPUTER",
          number: ''
        },
        numberOfFemaleComputer: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "COMPUTER",
          number: ''
        },
        numberOfMaleBasicTech: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "BASIC_TECHNOLOGY",
          number: ''
        },
        numberOfFemaleBasicTech: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "BASIC_TECHNOLOGY",
          number: ''
        },
        numberOfMaleAgriculture: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "AGRICULTURE",
          number: ''
        },
        numberOfFemaleAgriculture: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "AGRICULTURE",
          number: ''
        },
        numberOfMaleHomeEconomics: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "HOME_ECONOMICS",
          number: ''
        },
        numberOfFemaleHomeEconomics: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "HOME_ECONOMICS",
          number: ''
        },
        numberOfMaleBusinessStudies: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "BUSINESS_STUDIES",
          number: ''
        },
        numberOfFemaleBusinessStudies: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "BUSINESS_STUDIES",
          number: ''
        },
        numberOfMaleFrench: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "FRENCH_LANGUAGE",
          number: ''
        },
        numberOfFemaleFrench: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "FRENCH_LANGUAGE",
          number: ''
        },
        numberOfMaleArabic: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "ARABIC",
          number: ''
        },
        numberOfFemaleArabic: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "ARABIC",
          number: ''
        },
        numberOfMaleCRS: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "CHRISTIAN",
          number: ''
        },
        numberOfFemaleCRS: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "CHRISTIAN",
          number: ''
        },
        numberOfMaleIRS: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "ISLAMIC",
          number: ''
        },
        numberOfFemaleIRS: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "ISLAMIC",
          number: ''
        },
        numberOfMaleIgbo: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "IGBO",
          number: ''
        },
        numberOfFemaleIgbo: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "IGBO",
          number: ''
        },
        numberOfMaleHausa: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "HAUSA",
          number: ''
        },
        numberOfFemaleHausa: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "HAUSA",
          number: ''
        },
        numberOfMaleYoruba: {
          gender: "MALE",
          schoolClass: currentClass,
          subject: "YORUBA",
          number: ''
        },
        numberOfFemaleYoruba: {
          gender: "FEMALE",
          schoolClass: currentClass,
          subject: "YORUBA",
          number: ''
        },
    }
    return {
      subjects,
      id: schoolInfo.id
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitNumberOfStudentBySubject']),

    async submitForm() {
      const {subjects, id} = this
      const entries = Object.entries(subjects)
      let entriesObject = []
      for (let i in entries) {
        entriesObject[i] = entries[i][1]
      }
      const requestObject = {
        id: id,
        body: entriesObject,
      }
      await this.submitNumberOfStudentBySubject(requestObject)
        .then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          })
          if (this.responseCode === 201) {
            this.$emit('submissionDone')
          }
        })
    }
  }
}
</script>

<style scoped>

</style>