<template>
  <div>
    <section class="mb-5 mx-4 pt-4">
      <p class="font-sm fw-bold">Senior secondary school.</p>
      <p class="font-sm fw-bold">Number of core subject textbooks available to students provided by government.</p>
      <div class="d-flex mb-2">
        <div class="col-5"><p class="font-sm fw-medium">Text book</p></div>
        <span class="col me-1 text-center font-sm fw-medium">SSS 1</span>
        <span class="col me-1 text-center font-sm fw-medium">SSS 2</span>
        <span class="col me-1 text-center font-sm fw-medium">SSS 3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">English</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Mathematics</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Biology</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="biologySSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Civic Education</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="civicEducationSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="civicEducationSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="civicEducationSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "SeniorStudentBookSection",
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const englishSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "SS1",
      number: ''
    }
    const englishSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "SS2",
      number: ''
    }
    const englishSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "SS3",
      number: ''
    }
    const mathSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "SS1",
      number: ''
    }
    const mathSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "SS2",
      number: ''
    }
    const mathSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "SS3",
      number: ''
    }
    const biologySSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "BIOLOGY",
      schoolClass: "SS1",
      number: ''
    }
    const biologySSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "BIOLOGY",
      schoolClass: "SS2",
      number: ''
    }
    const biologySSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "BIOLOGY",
      schoolClass: "SS3",
      number: ''
    }
    const civicEducationSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "CIVIC_EDUCATION",
      schoolClass: "SS1",
      number: ''
    }
    const civicEducationSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "CIVIC_EDUCATION",
      schoolClass: "SS2",
      number: ''
    }
    const civicEducationSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "CIVIC_EDUCATION",
      schoolClass: "SS3",
      number: ''
    }

    return {
      englishSSOneStudent,
      englishSSTwoStudent,
      englishSSThreeStudent,
      mathSSOneStudent,
      mathSSTwoStudent,
      mathSSThreeStudent,
      biologySSOneStudent,
      biologySSTwoStudent,
      biologySSThreeStudent,
      civicEducationSSOneStudent,
      civicEducationSSTwoStudent,
      civicEducationSSThreeStudent,

      schoolInfo,
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    }
  },
  methods: {
    ...mapActions(['submitStudentTeacherBook']),
    submitForm() {
      const subjects = [
        this.englishSSOneStudent,
        this.englishSSTwoStudent,
        this.englishSSThreeStudent,
        this.mathSSOneStudent,
        this.mathSSTwoStudent,
        this.mathSSThreeStudent,
        this.biologySSOneStudent,
        this.biologySSTwoStudent,
        this.biologySSThreeStudent,
        this.civicEducationSSOneStudent,
        this.civicEducationSSTwoStudent,
        this.civicEducationSSThreeStudent,
      ]
      this.submitStudentTeacherBook({id: this.schoolInfo.id, body: subjects})
          .then(() => {
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>