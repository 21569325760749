export const emisconfig = {
  stateName: "YOBE",
  baseURL: "https://yobe.emis.gov.ng/",
  // baseURL: "https://febe-102-215-57-249.ngrok-free.app/",
  stateCode: 35,
  // 7=> BORNO
  // 1 => ADAMAWA
  // 35 => YOBE
  stateLogo: "",
};
