<template>
  <div class="d-flex flex-row-reverse bg-accent">
    
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>

    <enrolment-update-sidebar 
      :showAll="activeScreen === 'submission-review'" 
      @showAllSubmissions="showSubmissionReviews" />

    <div class="col-md-9 main-content px-5 pt-4 align-self-end">
      <dashboard-header 
        :hasBack="true" 
        @goBack="handleBack" />

      <submission-review 
        v-if="activeScreen === 'submission-review'"
        @enrolmentInfo="viewEnrolmentDetail"
        @facilityInfo ="viewFacilityDetail"
        :institutionId="institutionId" />   

      <birth-cert-table 
        :title="enrolmentSubmissionSection[0]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[0]['screen']" />

      <enrolment-by-age-pre-primary-table 
        :title="enrolmentSubmissionSection[1]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[1]['screen']" />

      <enrolment-by-age-primary-table 
        :title="enrolmentSubmissionSection[2]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[2]['screen']" />

      <enrolment-by-age-junior-table 
        :title="enrolmentSubmissionSection[3]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[3]['screen']" />
        
      <enrolment-by-age-senior-table 
        :title="enrolmentSubmissionSection[4]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[4]['screen']" />
        
      <enrolment-new-entrant-pry-one-table 
        :title="enrolmentSubmissionSection[5]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[5]['screen']" />

      <enrolment-new-entrant-j-s-s-one-table 
        :title="enrolmentSubmissionSection[6]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[6]['screen']" />

      <enrolment-new-entrant-s-s-one-table 
        :title="enrolmentSubmissionSection[7]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[7]['screen']" />
        
      <enrolment-by-stream-table 
        :title="enrolmentSubmissionSection[8]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[8]['screen']" />    
        
      <student-flow-by-class-table 
        :title="enrolmentSubmissionSection[9]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[9]['screen']" />
        
      <persons-with-special-needs-table 
        :title="enrolmentSubmissionSection[10]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[10]['screen']" />
        
      <enrolment-of-orphans-table 
        :title="enrolmentSubmissionSection[11]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[11]['screen']" />
        
      <enrolment-NABTEB 
        :title="enrolmentSubmissionSection[12]['title']"
        :schoolId="selectedSchoolId"
        v-if="activeScreen == enrolmentSubmissionSection[12]['screen']" />
      <facility-registration-table 
      :key="facKey" @reload="facKey++"
      :title="facilitySubmissionSections[0]['title']"
      :schoolId="selectedSchoolId"
      v-if="activeScreen == facilitySubmissionSections[0]['screen']"
      />
        
    </div>
  </div>
</template>

<script>
import SubmissionReview from "@/components/dashboardPage/school/ASCDashboard/supervisorComponents/SubmissionReview";
import DashboardHeader from "@/components/dashboardPage/DashboardHeader";
import BirthCertTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/BirthCertTable.vue';
import EnrolmentByAgePrePrimaryTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/EnrolmentByAgePrePrimaryTable.vue';
import EnrolmentByAgePrimaryTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/EnrolmentByAgePrimaryTable.vue';
import EnrolmentByAgeJuniorTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/EnrolmentByAgeJuniorTable.vue';
import EnrolmentByAgeSeniorTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/EnrolmentByAgeSeniorTable.vue';
import EnrolmentNewEntrantPryOneTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/EnrolmentNewEntrantPryOneTable.vue';
import EnrolmentNewEntrantJSSOneTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/EnrolmentNewEntrantJSSOneTable.vue';
import EnrolmentNewEntrantSSOneTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/EnrolmentNewEntrantSSOneTable.vue';
import EnrolmentByStreamTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/EnrolmentByStreamTable.vue';
import EnrolmentByAgeForClassTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/EnrolmentByAgeForClassTable.vue';
import StudentFlowByClassTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/StudentFlowByClassTable.vue';
import PersonsWithSpecialNeedsTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/PersonsWithSpecialNeedsTable.vue';
import EnrolmentOfOrphansTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/EnrolmentOfOrphansTable.vue';
import EnrolmentNABTEB from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/enrolmentSections/EnrolmentNABTEBTable.vue';
import FacilityRegistrationTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/facilitiesSections/FacilityRegistrationTable.vue';
import EnrolmentUpdateSidebar from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/EnrolmentUpdateSidebar.vue';
import dashboardMixinVue from "@/helpers/mixins/dashboardMixin.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { useStore } from 'vuex';
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router';

export default {
  components: {   
    DashboardHeader, 
    SubmissionReview,
    BirthCertTable,
    EnrolmentByAgePrePrimaryTable,
    EnrolmentByAgePrimaryTable,
    EnrolmentByAgeJuniorTable,
    EnrolmentByAgeSeniorTable,
    EnrolmentNewEntrantPryOneTable,
    EnrolmentNewEntrantJSSOneTable,
    EnrolmentNewEntrantSSOneTable,
    EnrolmentByStreamTable,
    EnrolmentByAgeForClassTable,
    StudentFlowByClassTable,
    PersonsWithSpecialNeedsTable,
    EnrolmentOfOrphansTable,
    EnrolmentNABTEB,
    EnrolmentUpdateSidebar,
    ClipLoader,
    FacilityRegistrationTable,
  },
  name: "ASCCollectionUpdate",
  mixins: [dashboardMixinVue],
  setup() {
    const store = useStore()
    const router = useRouter()
    const ownSchool = computed(() => store.state.school.ownSchool)
    const institutionId = ref()
    const facKey = ref(0)
    const selectedSchoolId = ref()
    if (ownSchool !== null) {
      institutionId.value = ownSchool.value.id
    }
    
    const loading = computed(() => store.state.enrolment.loading)    
    const activeScreen = ref('submission-review')
    const previousScreen = ref([])
    const enrolmentSubmissionSection = ref([
      {
        key: '0',
        screen: 'birthcertTable',
        title: 'Enrolment By Birth Certificate',
      },
      {
        key: '1',
        screen: 'enrolmentByAgePrePrimary',
        title: 'Enrolment By Age Pre-primary',
      },
      {
        key: '2',
        screen: 'enrolmentByAgePrimary',
        title: 'Enrolment By Age Primary',
      },
      {
        key: '3',
        screen: 'enrolmentByAgeJunior',
        title: 'Enrolment By Age Junior',
      },
      {
        key: '4',
        screen: 'enrolmentByAgeSenior',
        title: 'Enrolment By Age Senior',
      },
      {
        key: '5',
        screen: 'newEntrantPryOne',
        title: 'Enrolment Of New Entrant Primary One',
      },
      {
        key: '6',
        screen: 'newEntrantJSSOne',
        title: 'Enrolment Of New Entrant JSS One',
      },
      {
        key: '7',
        screen: 'newEntrantSSOne',
        title: 'Enrolment Of New Entrant SS One',
      },
      {
        key: '8',
        screen: 'enrolmentByStream',
        title: 'Enrolment By Stream',
      },
      {
        key: '9',
        screen: 'studentFlow',
        title: 'Enrolment Of Student Flow By Class',
      },
      {
        key: '10',
        screen: 'PersonsWithSpecialNeeds',
        title: 'Enrolment Of Persons With Special Needs By Class',
      },
      {
        key: '11',
        screen: 'OrphansEnrolment',
        title: 'Enrolment Of Orphans By Grade',
      },
      {
        key: '12',
        screen: 'nabtebEnrolment',
        title: 'Enrolment Of NABTEB Examination Of Previous School Year',
      },
    ])
    const viewEnrolmentDetail = ({key, schoolId}) => {
      const object = enrolmentSubmissionSection.value.find(item => item.key === `${key}`)
      updatePreviousScreens()
      activeScreen.value = object['screen']
      selectedSchoolId.value = schoolId
    } 

    const facilitySubmissionSections = ref([
      {
        key: '0',
        screen: 'facilityReg',
        title: 'Facility Registration',
      },
      {
        key: '1',
        title: 'Facility Type ',
      },
      {
        key: '2',
        title: 'Power Source',
      },
      {
        key: '3',
        title: 'Health Facilities',
      },
      {
        key: '4',
        title: 'ECCD Facilities',
      },
      {
        key: '5',
        title: 'Seaters Registration',
      },
      {
        key: '6',
        title: 'Toilet Registration',
      },
    ])

    const viewFacilityDetail = (key) => {
      const object = facilitySubmissionSections.value.find(item => item.key === `${key}`)
      updatePreviousScreens()
      activeScreen.value = object['screen']
    } 

    const showSubmissionReviews = () => {
      updatePreviousScreens()
      activeScreen.value = 'submission-review'
    }

    const handleBack = () => {
      if (previousScreen.value.length > 0) {
        activeScreen.value = previousScreen.value.pop()
      } else {
        router.back();
      }
    }

    const updatePreviousScreens = () => {
      previousScreen.value = [...previousScreen.value, activeScreen.value]
    }

    return {
      selectedSchoolId,
      loading,
      activeScreen,
      institutionId,
      enrolmentSubmissionSection,
      previousScreen,
      viewEnrolmentDetail,
      viewFacilityDetail,
      facilitySubmissionSections,
      showSubmissionReviews,
      handleBack,
      facKey
    }
  },
}
</script>