<template>
  <div id="welcome-view" class="px-0">
    <main class="container mb-4 mb-lg-0">
      <home-nav-bar></home-nav-bar>
      <div class="d-flex flex-wrap mb-4">
        <div
          class="col-12 col-lg-6 order-sm-2 order-lg-1 d-flex flex-column pe-5"
        >
          <h1 class="my-4 pt-5 custom-h1 fw-bold">
            EDUCATION MANAGEMENT INFORMATION SYSTEM
          </h1>
          <p class="mb-3 mb-lg-5">
            Welcome to the Education Management Information System (EMIS). A
            Platform for accessing relevant education data and information.
          </p>
          <!-- /Borno/Yobe-->
          <p>
            The {{ stateName }} EMIS will be used for the collection,
            integration, maintenance and dissemination of education data and
            information to support decision making, planning, policy analysis,
            monitoring and evaluation of all levels of education institutions
            and also provide information to the general public.
          </p>
          <div>
            <button class="btn btn-outline-custom font-tiny btn-rd px-3 py-2">
              Learn more
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-6 order-sm-1 order-lg-2 ps-4 pt-4">
          <img
            id="welcome-image"
            class="img-fluid"
            :src="ViewImage"
            alt="Image of a class teacher with students"
            srcset=""
          />
        </div>
      </div>
      <div
        class="d-flex flex-wrap justify-content-center align-items-center mt-3"
      >
        <span>In partnership with: </span>
        <img class="log-height mx-2" :src="UnicefLogo" alt="Unicef Logo" />
        <img class="log-height" :src="COA" alt="Nigeria coat of arm logo" />
      </div>
    </main>
  </div>
</template>

<script>
import HomeNavBar from "../includes/NavBar";
import ViewImage from "@/assets/images/class.png";
import UnicefLogo from "@/assets/images/unicef.png";
import COA from "@/assets/images/coa.png";
import { emisconfig } from "../../../app.config";

export default {
  name: "Contact",
  components: {
    HomeNavBar,
  },
  setup() {
    return {
      stateName: emisconfig.stateName,
      ViewImage,
      UnicefLogo,
      COA,
    };
  },
};
</script>

<style lang="scss" scoped>
#welcome-view {
  background-image: url("../../assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

#welcome-image {
  max-height: 65vh;
}

.log-height {
  height: 32px;
}
</style>
