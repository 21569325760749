<template>
  <nav class="navbar navbar-expand-lg w-100 pt-lg-3">
    <div class="container-fluid">
      <div class="">
        <router-link to="/" class="navbar-brand">
          <img :src="logoSrc" alt="state logo" id="state-logo" />
        </router-link>
      </div>
      <button
        class="navbar-toggler btn-outline-custom"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#homePageNavBar"
        aria-controls="homePageNavBar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <!-- <i class="bi bi-list"></i> -->
        <span class="material-icons-outlined">
          menu
        </span>
      </button>
      <div
        class="
          col-lg-6
          justify-content-lg-end
          collapse
          navbar-collapse
          mt-4 mt-lg-0
        "
        id="homePageNavBar"
      >
        <div class="navbar-nav">
          <div class="d-flex flex-column justify-content-center">
            <router-link class="nav-link px-3 font-sm" to="/">Home</router-link>
            <span class="d-none d-lg-block bottom-bar"></span>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <router-link class="nav-link px-3 font-sm" to="/about"
              >About</router-link
            >
            <span class="d-none d-lg-block bottom-bar"></span>
          </div>
          <div class="d-flex flex-column justify-content-center">
            <router-link class="nav-link px-3 font-sm" to="/contact"
              >Contact</router-link
            >
            <span class="d-none d-lg-block bottom-bar"></span>
          </div>
          <div
            v-if="isAuthUser"
            class="d-flex flex-column justify-content-center"
          >
            <router-link class="nav-link px-3 font-sm" to="/dashboard"
              >Dashboard</router-link
            >
            <span class="d-none d-lg-block bottom-bar"></span>
          </div>
        </div>
        <div class="d-flex mt-3 mt-lg-0 custom-margin">
          <div class="custom-input-group header-search bg-white">
            <!-- <span class="p-1"><i class="bi bi-search"></i></span> -->
            <span class="material-icons-outlined p-1">
              search
            </span>
            <input
              type="text"
              class="ph-sm no-outline-input font-tiny fw-bolder"
              placeholder="Search"
            />
          </div>
          <router-link
            v-if="!isAuthUser"
            to="/login"
            class="
              btn btn-primary
              text-center
              mx-3
              py-2
              font-sm
              custom-btn-wide
            "
          >
            Login
          </router-link>
          <span
              v-else
              class="
              btn btn-primary
              text-center
              mx-3
              py-2
              font-sm
            "
            @click="logout"
          >
            Sign out
          </span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import stateLogo from "@/assets/images/adamawa_logo.png";
import router from "@/router";
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  name: "HomeNaveBar",
  setup() {
    const store = useStore();
    const logoSrc = stateLogo;
    const isAuthUser = computed(() => store.getters.isAuthenticated);
    const logout = () => {
      store.commit('logout')
      router.push('/login')
    }
    return {
      logoSrc,
      isAuthUser,
      logout
    };
  },
};
</script>

<style lang="scss">
#state-logo {
  width: 48px;
  height: 48px;
}

.nav-link {
  color: #000 !important;
}

.router-link-active.router-link-exact-active {
  color: #0030ff !important;
}

.router-link-active.router-link-exact-active {
  + .bottom-bar {
    width: 1rem;
    height: 0.18rem;
    margin-left: 1rem;
    margin-top: -0.25rem;
    background: #0030ff !important;
  }
}

.custom-margin {
  @media (min-width: 992px) {
    margin-left: 200px;
  }
}
</style>
