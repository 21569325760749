<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"    
        :scroll="{ x: 1300 }"        
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal     
      :visible="showModal" 
      title="Update Enrolment By Age Pre Primary Students" 
      @cancel="handleCancel">
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Update</a-button>
      </template>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="School Class"
            name="schoolClass"
          >
            <input type="text" class="form-control" :value="schoolClass" disabled>
          </a-form-item>          
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Gender"
            name="gender"
          >
            <input type="text" class="form-control" :value="gender" disabled>
          </a-form-item>          
        </a-col>
      </a-row>
      
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="Below Three Years"
            name="belowThreeYears"
          >
            <input type="number" class="form-control" v-model="belowThreeYears">
          </a-form-item>    
        </a-col>
        <a-col :span="11">        
          <a-form-item
            label="Three Years"
            name="belowThreeYears"
          >
            <input type="number" class="form-control" v-model="threeYears">
          </a-form-item>        
        </a-col>
      </a-row>

      <a-row justify="space-between">
        <a-col :span="11">            
          <a-form-item
            label="Four Years"
            name="fourYears"
          >
            <input type="number" class="form-control" v-model="fourYears">
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Five Years"
            name="fiveYears"
          >
            <input type="number" class="form-control" v-model="fiveYears">
          </a-form-item>                
        </a-col>
      </a-row>  

      <a-row justify="space-between">
        <a-col :span="24">
          <a-form-item
            label="Above Five Years"
            name="aboveFiveYears"
          >
            <input type="number" class="form-control" v-model="aboveFiveYears">
          </a-form-item>           
        </a-col>
      </a-row>  
    </a-modal>
  </div>
</template>

<script>
import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { onMounted, ref, } from 'vue'
import { mapActions, useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'EnrolmentByAgePrePrimaryTable',
  emits: ['viewEnrolmentInfo'],
  props: ['title', 'schoolId'],
  mixins: [enrolmentMixinVue],
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
  },
  setup() {
    const store = useStore()
    const showModal = ref(false)
    const selectedObject = ref(null)
    const schoolClass = ref('')
    const gender = ref('')
    const belowThreeYears = ref()
    const threeYears = ref()
    const fourYears = ref()
    const fiveYears = ref()
    const aboveFiveYears = ref()

    const columns = [
      { title: 'School Class', dataIndex: 'schoolClass', fixed: 'left', width: 120 }, 
      { title: 'Gender', dataIndex: 'gender', fixed: 'left', width: 100 },
      { title: 'Below Three Years', dataIndex: 'belowThreeYears', className: 'content-alignment' },
      { title: 'Three Years', dataIndex: 'threeYears', className: 'content-alignment' },
      { title: 'Four Years', dataIndex: 'fourYears', className: 'content-alignment' },    
      { title: 'Five Years', dataIndex: 'fiveYears', className: 'content-alignment' },    
      { title: 'Above Five Years', dataIndex: 'aboveFiveYears', className: 'content-alignment' },                  
      {
        title: 'operation',
        dataIndex: 'operation', 
        fixed: 'right',  
        width: 100   
      },
    ]

    return {    
      columns,  
      showModal,    

      schoolClass,
      gender,

      selectedObject,
      belowThreeYears,
      threeYears,
      fourYears,
      fiveYears,
      aboveFiveYears,
    }
  },
  computed: {
    prePryEnrolmentData() {
      return this.$store.state.enrolment.prePryEnrolmentByAgeData
    },
    dataSource() {
      const dataItems = []      
      if (this.prePryEnrolmentData != null) {
        const data = Object.values(this.prePryEnrolmentData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolClass: element["schoolClass"],
                gender: element["gender"],
                belowThreeYears: element["belowThree3Year"],
                threeYears: element["three3Year"],
                fourYears: element["four4year"],
                fiveYears: element["five5Year"],
                aboveFiveYears: element["aboveFive5Year"]
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {
    ...mapActions(['updatePrePryEnrolmentByAge']),

    editRecord(key) {
      const detail = this.prePryEnrolmentData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail   
        
        this.schoolClass = detail.schoolClass
        this.gender = detail.gender
        
        this.belowThreeYears = detail.belowThree3Year
        this.threeYears = detail.three3Year
        this.fourYears = detail.four4year
        this.fiveYears = detail.five5Year
        this.aboveFiveYears = detail.aboveFive5Year
      }  
    },

    handleSubmit() {
      const payload = {
        id: this.selectedObject.id,
        body: {
          belowThree3Year: Number(this.belowThreeYears),
          three3Year: Number(this.threeYears),
          four4year: Number(this.fourYears),
          five5Year: Number(this.fiveYears),
          aboveFive5Year: Number(this.aboveFiveYears),          
        }
      }
      this.updatePrePryEnrolmentByAge(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.belowThreeYears = ''
            this.threeYears = ''
            this.fourYears = '' 
            this.fiveYears = ''
            this.aboveFiveYears = ''
            
            const type = this.updateMessage.status
                        
            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });
          }
        })  
    },
        
    handleCancel() {
      this.showModal = false
    },
  },
  mounted(){
    if(!this.schoolId) {
      this.$store.commit('setPrePryEnrolmentByAgeData', null)
    this.$store.dispatch('fetchPrePryEnrolmentByAge')
    } else {
      this.$store.commit('setPrePryEnrolmentByAgeData', null)
      this.$store.dispatch('fetchPrePryEnrolmentByAge', this.schoolId)
    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
