<template>
  <div class="d-flex flex-column">
    <section class="radius-full bg-white px-5 pt-5 mb-4">

      <h5 class="ps-lg-5">Vocational School</h5>

      <number-of-subject-by-class-vocational
          v-if="activeStage === stages[0]"
          @submissionDone="continueToNextStage"
          school-class="JSS 1" />

      <number-of-subject-by-class-vocational
          v-if="activeStage === stages[1]"
          @submissionDone="continueToNextStage"
          school-class="JSS 2" />

      <number-of-subject-by-class-vocational
          v-if="activeStage === stages[2]"
          @submissionDone="continueToNextStage"
          school-class="JSS 3" />

      <number-of-subject-by-class-vocational
          v-if="activeStage === stages[3]"
          @submissionDone="continueToNextStage"
          school-class="SS 1" />

      <number-of-subject-by-class-vocational
          v-if="activeStage === stages[4]"
          @submissionDone="continueToNextStage"
          school-class="SS 2" />

      <number-of-subject-by-class-vocational
          v-if="activeStage === stages[5]"
          @submissionDone="stagesCompleted"
          school-class="SS 3" />

    </section>
  </div>
</template>

<script>
import NumberOfSubjectByClassVocational
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/subjects/levels/NumberOfSubjectByClassVocational";
import {computed} from "@vue/runtime-core";
import {mapMutations, useStore} from "vuex";

export default {
  name: "VocationalAndScience",
  components: {NumberOfSubjectByClassVocational},
  setup() {
    const store = useStore()
    const formStage = computed(() => store.state.questionnaireService.activeSubjectStage).value
    const stages = ["js1", "js2", "js3", "ss1", "ss2", "ss3"]
    if (formStage === null) {
      store.commit('setActiveSubjectStage', stages[0])
    }
    return {stages}
  },
  computed: {
    activeStage() {
      return this.$store.state.questionnaireService.activeSubjectStage
    }
  },
  methods: {
    ...mapMutations(['setActiveSubjectStage', 'setQAMessage']),
    saveSubject() {
      this.$emit('saveSubject')
    },
    continueToNextStage() {
      let currentStageIndex = this.stages.indexOf(this.activeStage)
      const nextStage = this.stages[++currentStageIndex]

      this.setActiveSubjectStage(nextStage)
    },
    stagesCompleted() {
      this.setQAMessage("You have successfully submitted all forms.")
       this.saveSubject()
    }
  }
}
</script>