<template>
  <div class="mt-2">
    <div class="table-responsive bg-white shadow-sm mx-5 radius-full mb-5">
      <table class="table table-striped table-borderless font-tiny" aria-label="Users table">
        <thead>
        <tr>
          <th class="align-top py-3 ps-4 big-cell first">
            <span class="font-tiny">Table Name</span>
          </th>
          <th class="align-top py-3 small-cell text-center">
            <span class="font-tiny">Action</span>
          </th>
        </tr>
        </thead>
        <tbody>
          <tr class="pointer-no-bg"
            v-for="(table, index) in tables"
            :key="index"
          >
            <td class="py-3 ps-4 first">{{ table }}</td>
            <td class="py-3 text-center">
              <span @click="showTableWithIndex(index)" class="view-table">view table</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import {useRouter} from "vue-router";

export default {
  name: "ASCInformationAndCoverage",
  setup() {
    const router = useRouter()

    const tables = [
        "Number of schools",
        "Number of private schools by level",
        "Number of public schools, enrolment and teachers",
        "Number of public schools with special curriculum",
        "Selective characteristics of public schools (Absolute number)",
        "Selective characteristics of public schools (Percentage)"
    ]

    const showTableWithIndex = (index) => {
      router.push({ path: `/ascInfoAndCovTables/${index}` })
    }

    return {
      tables,
      showTableWithIndex
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.big-cell {
  width: 80%;
}
.small-cell {
  width: 20%;
}
.view-table {
  color: #000;
  &:hover {
    text-decoration: underline;
  }
}
</style>