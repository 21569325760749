<template>
  <div class="d-flex px-0 mt-5">
    <div
      class="
        d-flex
        flex-column
        align-items-center
        justify-content-center
        action-card
        me-2
      "
      @click="$emit('createUser')"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 49.6 52.718"
        >
          <g
            id="Component_145_1"
            data-name="Component 145 – 1"
            transform="translate(2 2)"
            opacity="0.8"
          >
            <line
              id="Line_67"
              data-name="Line 67"
              y2="48.718"
              transform="translate(22.995)"
              fill="none"
              stroke="#3c414c"
              stroke-linecap="round"
              stroke-width="4"
            />
            <line
              id="Line_68"
              data-name="Line 68"
              x2="45.6"
              transform="translate(0 24.554)"
              fill="none"
              stroke="#3c414c"
              stroke-linecap="round"
              stroke-width="4"
            />
          </g>
        </svg>
      </span>
      <span class="font-sm mt-3">Create user</span>
    </div>
    <div
      class="
        d-flex
        flex-column
        align-items-center
        justify-content-center
        action-card
        ms-2
      "
      @click="$emit('usersTable')"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 49.6 52"
        >
          <g
            id="Component_146_1"
            data-name="Component 146 – 1"
            transform="translate(2 2)"
            opacity="0.8"
          >
            <line
              id="Line_69"
              data-name="Line 69"
              x2="45.6"
              fill="none"
              stroke="#3c414c"
              stroke-linecap="round"
              stroke-width="4"
            />
            <line
              id="Line_70"
              data-name="Line 70"
              x2="45.6"
              transform="translate(0 16)"
              fill="none"
              stroke="#3c414c"
              stroke-linecap="round"
              stroke-width="4"
            />
            <line
              id="Line_71"
              data-name="Line 71"
              x2="45.6"
              transform="translate(0 32)"
              fill="none"
              stroke="#3c414c"
              stroke-linecap="round"
              stroke-width="4"
            />
            <line
              id="Line_72"
              data-name="Line 72"
              x2="45.6"
              transform="translate(0 48)"
              fill="none"
              stroke="#3c414c"
              stroke-linecap="round"
              stroke-width="4"
            />
          </g>
        </svg>
      </span>
      <span class="font-sm mt-3">View all users</span>
    </div>
    <div
        class="
        d-flex
        flex-column
        align-items-center
        justify-content-center
        action-card
        ms-2
      "
        @click="$emit('viewLearnersTable')"
    >
      <span>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 49.6 52"
        >
          <g
              id="Component_146_1"
              data-name="Component 146 – 1"
              transform="translate(2 2)"
              opacity="0.8"
          >
            <line
                id="Line_69"
                data-name="Line 69"
                x2="45.6"
                fill="none"
                stroke="#3c414c"
                stroke-linecap="round"
                stroke-width="4"
            />
            <line
                id="Line_70"
                data-name="Line 70"
                x2="45.6"
                transform="translate(0 16)"
                fill="none"
                stroke="#3c414c"
                stroke-linecap="round"
                stroke-width="4"
            />
            <line
                id="Line_71"
                data-name="Line 71"
                x2="45.6"
                transform="translate(0 32)"
                fill="none"
                stroke="#3c414c"
                stroke-linecap="round"
                stroke-width="4"
            />
            <line
                id="Line_72"
                data-name="Line 72"
                x2="45.6"
                transform="translate(0 48)"
                fill="none"
                stroke="#3c414c"
                stroke-linecap="round"
                stroke-width="4"
            />
          </g>
        </svg>
      </span>
      <span class="font-sm mt-3">View all learners</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "UsersManagement",
  setup() {
    return {}
  }
};
</script>

<style>
.action-card {
  width: 20rem;
  height: 11rem;
  background: #fff;
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
</style>