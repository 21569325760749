<template>
  <div>
    <div class="mt-2">
      <div class="table-responsive bg-white shadow-sm radius-full mx-5 pt-3 mb-5">
        <a-table
            class="ant-table-striped"
            size="small"      
            :data-source="dataSource"
            :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        >
          <a-table-column width="80%">
            <template #title><span class="ps-4">Table Name</span></template>
            <template #default="{record}">
              <span class="ps-4">{{ record.name }}</span>
            </template>
          </a-table-column>
          <a-table-column width="20%" key="action" title="Action">        
            <template #default="{record}">
              <span @click="showTableWithIndex(record.key)">view table</span>
            </template>
          </a-table-column >
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>

import {useRouter} from "vue-router";
import {Table, TableColumn} from "ant-design-vue";

class Item {
  constructor(key, name) {
    this.key = key
    this.name = name
  }
}

export default {
  name: "Teachers",
  components: {
    'a-table': Table,
    'a-table-column': TableColumn
  },
  setup() {
    const router = useRouter()

    const tables = [
      "Number of public pre-primary school teachers by gender and LGA",
      "Number of public primary school teachers by gender and LGA",
      "Pupil-Teacher Ratio by LGA, Public Primary",
      "Number of public junior secondary school teachers by gender and LGA",
      "Pupil-Teacher Ratio by LGA, Public Junior Secondary School",
      "Number of public senior secondary school teachers by gender and LGA",
      "Pupil-Teacher Ratio by LGA, Public Senior Secondary School",
      "Number of private school teachers by gender and LGA, (All Levels)",
      "Pupil-teacher ratio in private schools by LGA, (All Levels)",
      "Percentage of long-term absent teachers (Public Only)",
      "Percentage of teachers by highest level of education attained (Public Only)", 
      "Percentage of teachers by source of salary (Public Only)"
    ]

    const dataSource = Array.from(tables, (item, index) => new Item(index, item) )

    const showTableWithIndex = (index) => {
      router.push({ path: `/teachersTables/${index}` })
    }

    return {
      dataSource,
      showTableWithIndex
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style lang="scss">
.big-cell {
  width: 80%;
}
.small-cell {
  width: 20%;
}
.view-table {
  color: #000;
  &:hover {
    text-decoration: underline;
  }
}
</style>