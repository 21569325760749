<template>
  <div>
    <section id="senior-sec">
      <senior-student-book-section
          v-if="activeStage === stages[0]"
          @submissionDone="gotoNextStage" />
      <senior-teacher-book-section
          v-if="activeStage === stages[1]"
          @submissionDone="gotoNextStage" />
    </section>
  </div>
</template>

<script>
import SeniorTeacherBookSection
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/senior/SeniorTeacherBookSection";
import SeniorStudentBookSection
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/senior/SeniorStudentBookSection";
import {mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
export default {
  name: "BookSenior",
  components: {SeniorStudentBookSection, SeniorTeacherBookSection},
  setup() {
    const store = useStore()
    const stages = ["senior-student", "senior-teacher"]
    const activeStage = computed(() => store.state.questionnaireService.activeBookStage).value
    if (activeStage === null) {
      store.commit('setActiveBookStage', stages[0])
    }
    return {
      stages,
      activeStage
    }
  },
  methods: {
    ...mapMutations(['setActiveBookStage']),
    gotoNextStage() {
      const nextStageIndex = this.stages.indexOf(this.activeStage) + 1
      if (nextStageIndex < this.stages.length) {
        this.setActiveBookStage(this.stages[nextStageIndex])
      } else {
        this.$emit('seniorStagesCompleted')
      }
    }
  }
}
</script>

<style scoped>

</style>