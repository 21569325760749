<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"    
        :scroll="{ x: 1300 }"        
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>

function replaceUnderScore(str) {
  if (str !== undefined) return str.replaceAll("_", " ")
}

import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'NonClassroomsTable',
  emits: ['viewNonClassroomInfo'],
  props: ['title'],
  mixins: [enrolmentMixinVue],
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
  },
  setup() {
    const endpoint = 'f/classroom-numbers'
    const store = useStore()
    const message = computed(() => store.state.classroom.updateMessage)
    onMounted(() => {      
      store.dispatch('fetchRequest', endpoint)
        .then(() => {
          if (message.value !== null) {
            notification.open({
              type: message.value.status,
              message: "Non-Classrooms",
              description: message.value.message
            })
          }
        })
    })

    const columns = [
      { title: 'School Level', dataIndex: 'schoolLevel', fixed: 'left', width: 120 }, 
      { title: 'No. Of Staffrooms', dataIndex: 'numberOfStaffRoom', fixed: 'left'},
      { title: 'No. of Laboratories', dataIndex: 'numberOfLab', className: 'content-alignment'},
      { title: 'No. of Offices', dataIndex: 'numberOfOffice', className: 'content-alignment'},
      { title: 'No. of Libraries', dataIndex: 'numberOfLibraries', className: 'content-alignment'},    
      { title: 'No. of Stores', dataIndex: 'numberOfstore', className: 'content-alignment'},    
      { title: 'No. of Others', dataIndex: 'numberOfOther', className: 'content-alignment'},      
      {
        title: 'operation',
        dataIndex: 'operation', 
        fixed: 'right',  
        width: 100   
      },
    ]

    return {    
      columns,
    }
  },
  computed: {
    classroomData() {
      return this.$store.state.classroom.classroomData
    },

    dataSource() {
      const dataItems = []      
      if (this.classroomData != null) {
        const data = Object.values(this.classroomData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolLevel: replaceUnderScore(element["schoolLevel"]),
                numberOfStaffRoom: element["numberOfStaffRoom"],
                numberOfLab: element["numberOfLab"],
                numberOfOffice: element["numberOfOffice"],
                numberOfLibraries: element["numberOfLibraries"],
                numberOfstore: element["numberOfstore"],
                numberOfOther: element["numberOfOther"],                
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {
    editRecord(key) {
      const detail = this.classroomData.find(record => record["id"] === key)
      this.$emit('viewNonClassroomInfo', detail)
    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
