<template>
  <div class="bg-accent d-flex flex-column min-vh-100">
    <div class="container pt-4">
      <dashboard-header 
        @goBack="goBack"
        :hasBack="true"
      />
      <div v-if="loadingStatus" class="overlay">
        <clip-loader class="loading"></clip-loader>
      </div>
      <public-pre-pry-teacher-by-gender-and-l-g-a v-if="index === '0'" />
      <public-pry-teacher-by-gender-and-l-g-a v-if="index === '1'" />
      <pupil-teacher-ratio-public-pry v-if="index === '2'" />
      <public-junior-teacher-by-gender-and-l-g-a v-if="index === '3'" />
      <pupil-teacher-ratio-public-junior v-if="index === '4'" />
      <public-senior-teacher-by-gender-and-l-g-a v-if="index === '5'" />
      <pupil-teacher-ratio-public-senior v-if="index === '6'" />
      <private-school-teachers-all-levels v-if="index === '7'" />
      <pupil-teacher-ratio-private-all-levels v-if="index === '8'" />
      <long-term-absent-teachers-public-only v-if="index === '9'" />
      <teachers-highest-education-level-attained v-if="index === '10'" />
      <teachers-source-of-salary-public-only v-if="index === '11'" />
    </div>
  </div>
</template>

<script>
import PupilTeacherRatioPublicPry from "@/components/dashboardPage/dataTable/TeachersTables/PupilTeacherRatioPublicPry";
import PublicPrePryTeacherByGenderAndLGA
  from "@/components/dashboardPage/dataTable/TeachersTables/PublicPrePryTeacherByGenderAndLGA";
import PublicPryTeacherByGenderAndLGA
  from "@/components/dashboardPage/dataTable/TeachersTables/PublicPryTeacherByGenderAndLGA";
import PublicJuniorTeacherByGenderAndLGA
  from "@/components/dashboardPage/dataTable/TeachersTables/PublicJuniorTeacherByGenderAndLGA";
import PublicSeniorTeacherByGenderAndLGA
  from "@/components/dashboardPage/dataTable/TeachersTables/PublicSeniorTeacherByGenderAndLGA";
import PupilTeacherRatioPublicJunior
  from "@/components/dashboardPage/dataTable/TeachersTables/PupilTeacherRatioPublicJunior";
import PupilTeacherRatioPublicSenior
  from "@/components/dashboardPage/dataTable/TeachersTables/PupilTeacherRatioPublicSenior";
import PrivateSchoolTeachersAllLevels
  from "@/components/dashboardPage/dataTable/TeachersTables/PrivateSchoolTeachersAllLevels";
import PupilTeacherRatioPrivateAllLevels
  from "@/components/dashboardPage/dataTable/TeachersTables/PupilTeacherRatioPrivateAllLevels";
import LongTermAbsentTeachersPublicOnly
  from "@/components/dashboardPage/dataTable/TeachersTables/LongTermAbsentTeachersPublicOnly";
import TeachersHighestEducationLevelAttained
  from "@/components/dashboardPage/dataTable/TeachersTables/TeachersHighestEducationLevelAttained";
import TeachersSourceOfSalaryPublicOnly
  from "@/components/dashboardPage/dataTable/TeachersTables/TeachersSourceOfSalaryPublicOnly";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import DashboardHeader from '@/components/dashboardPage/DashboardHeader.vue';  
import {useRoute, useRouter} from "vue-router";
import { useStore } from 'vuex';
import { computed } from 'vue'

export default {
  name: "TeachersTables",
  components: {
    TeachersSourceOfSalaryPublicOnly,
    TeachersHighestEducationLevelAttained,
    LongTermAbsentTeachersPublicOnly,
    PupilTeacherRatioPrivateAllLevels,
    PrivateSchoolTeachersAllLevels,
    PupilTeacherRatioPublicSenior,
    PupilTeacherRatioPublicJunior,
    PublicSeniorTeacherByGenderAndLGA,
    PublicJuniorTeacherByGenderAndLGA,
    PublicPryTeacherByGenderAndLGA, 
    PublicPrePryTeacherByGenderAndLGA, 
    PupilTeacherRatioPublicPry,
    ClipLoader,
    DashboardHeader,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const loadingStatus = computed(() => store.state.datatables.loadingStatus)
    const index = route.params.index
    const goBack = () => { store.commit('setCurrentStage', 3); router.back() }
    return {
      index,
      loadingStatus,
      goBack,
    }
  } 
}
</script>
