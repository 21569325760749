<template>
  <div class="d-flex flex-column px-5 py-4 my-5 radius-full bg-white justify-content-center align-items-center">
    <div class="col-10 col-lg-8 d-flex flex-column justify-content-center align-items-center py-5">
      <span class="mb-3 fw-medium">Is this questionnaire for mass literacy?</span>
      <div class="d-flex">
        <button
          @click="$emit('other')"
          class="fw-medium pointer-no-bg make-btn font-sm">No, It isn't</button>

        <button
          @click="$emit('nmec')"
          class="custom-btn font-sm ms-3">Yes It Is</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionnaireOption"
}
</script>

<style scoped>

</style>