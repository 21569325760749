export const userRoles = [
  'SUPER_ADMIN',
  'MOE_ADMIN',
  'SMOE_ADMIN',
  'SUBEB_ADMIN',
  'SAME_ADMIN',
  'SMORA_ADMIN',
  'ADSMEB_ADMIN',
  'SUPERVISOR',
  'SMOE_PRS',
  'SUBEB_PRS',
  'SAME_PRS',
  'SMORA_PRS',
  'ADSMEB_PRS',
  'SMOE_EMIS_LGA_OFFICER',
  'SMOE_SUBEB_LGA_OFFICER',
  'SMOE_EMIS_STATE_OFFICER',
  'SMOE_SUBEB_STATE_OFFICER',
  'SBMC',
  'ENUMERATOR',
  'PRINCIPAL',
  'HEADTEACHER'
]

export const stringifiedRoles = new Map([
  ["ROLE_SUPER_ADMIN", "Super Admin"],
  ["ROLE_MOE_ADMIN", "MOE Admin"],
  ["ROLE_SMOE_ADMIN", "SMOE Admin"],
  ["ROLE_SUBEB_ADMIN", "SUBEB Admin"],
  ["ROLE_SAME_ADMIN", "SAME Admin"],
  ["ROLE_SMORA_ADMIN", "SMORA Admin"],
  ["ROLE_ADSMEB_ADMIN", "ADSMEB Admin"],
  ["ROLE_SUPERVISOR", "Supervisor"],
  ["ROLE_SMOE_PRS", "SMOE PRS"],
  ["ROLE_SUBEB_PRS", "SUBEB PRS"],
  ["ROLE_SAME_PRS", "SAME PRS"],
  ["ROLE_SMORA_PRS", "SMORA PRS"],
  ["ROLE_ADSMEB_PRS", "ADSMEB PRS"],
  ["ROLE_SMOE_EMIS_LGA_OFFICER", "EMIS LGA Officer"],
  ["ROLE_SMOE_SUBEB_LGA_OFFICER", "SUBEB LGA Officer"],
  ["ROLE_SMOE_EMIS_STATE_OFFICER", "EMIS State Officer"],
  ["ROLE_SMOE_SUBEB_STATE_OFFICER", "SUBEB State Officer"],
  ["ROLE_SBMC", "SBMC"],
  ["ROLE_USER", "User"],
  ["ROLE_ENUMERATOR", "Enumerator"],
  ["ROLE_PRINCIPAL", "Principal"],
  ["ROLE_HEADTEACHER", "Head Teacher"]
])