<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table
        class="ant-table-striped"
        size="small"
        :columns="columns"
        :dataSource="dataSource"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'active'">
            <span>
              <a-tag :color="record.active === 1 ? 'green' : 'volcano'">
                <a-popconfirm
                  v-if="dataSource.length"
                  :title="
                    record.active === 1
                      ? 'sure to Deactivate?'
                      : 'Sure to Activate ?'
                  "
                  @confirm="onActive(record.key), (record.active = 0)"
                >
                  <a>{{ record.active === 1 ? "Active" : "Not Active" }}</a>
                </a-popconfirm>
              </a-tag>
            </span>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal
      :visible="showModal"
      title="Update Enrolment By Age Junior Students"
      @cancel="handleCancel"
    >
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleSubmit"
          >Update</a-button
        >
      </template>
      <a-row>
        <a-col :span="24">
          <a-form-item label="Start Date" name="startDate">
            <input type="date" class="form-control" :value="startDate" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item label="questionaire Start Date" name="questionaireStart">
            <input
              type="date"
              class="form-control"
              :value="questionaireStart"
            />
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <a-form-item label="questioniare End Date " name="questioniareEnd">
            <input type="date" class="form-control" :value="questioniareEnd" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item label="LGA Validate start" name="lgaValidationStart">
            <input
              type="date"
              class="form-control"
              v-model="lgaValidationStart"
            />
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <a-form-item label="LGA Validation End" name="lgaValidatiionEnd">
            <input
              type="date"
              class="form-control"
              v-model="lgaValidatiionEnd"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="State Vaidation start"
            name="stateValidationStart"
          >
            <input
              type="date"
              class="form-control"
              v-model="stateValidationStart"
            />
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <a-form-item label="State validation end" name="stateValidationEnd">
            <input
              type="date"
              class="form-control"
              v-model="stateValidationEnd"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row justify="space-between">
        <a-col :span="24">
          <a-form-item label="Report Generation" name="reportGenerationDate">
            <input
              type="date"
              class="form-control"
              v-model="reportGenerationDate"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row justify="space-between">
        <a-col :span="24">
          <a-form-item label="Session" name="session">
            <input type="date" class="form-control" v-model="session" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import {
  Table,
  Button,
  Space,
  Modal,
  FormItem,
  Input,
  Row,
  Col,
  notification,
  Tag,
  Popconfirm,
} from "ant-design-vue";
import { onMounted, ref } from "vue";
import { mapActions, mapMutations, useStore } from "vuex";
import enrolmentMixinVue from "@/helpers/mixins/enrolmentMixin.vue";

export default {
  name: "EnrolmentByAgeJuniorTable",
  emits: ["viewEnrolmentInfo"],
  props: ["planningData"],
  mixins: [enrolmentMixinVue],
  components: {
    "a-table": Table,
    "a-space": Space,
    "a-button": Button,
    "a-modal": Modal,
    "a-form-item": FormItem,
    "a-input": Input,
    "a-row": Row,
    "a-col": Col,
    "a-tag": Tag,
    "a-popconfirm": Popconfirm,
  },
  setup() {
    const store = useStore();
    const showModal = ref(false);
    const selectedObject = ref(null);
    const startDate = ref("");
    const questionaireStart = ref("");
    const questioniareEnd = ref("");
    const lgaValidationStart = ref("");
    const lgaValidatiionEnd = ref("");
    const stateValidationStart = ref("");
    const stateValidationEnd = ref("");
    const reportGenerationDate = ref("");
    const session = ref("");

    const columns = [
      { title: "Session", dataIndex: "session" },
      { title: "Start date", dataIndex: "startDate" },
      { title: "Status", dataIndex: "active" },
    ];

    return {
      columns,
      showModal,
      selectedObject,
      startDate,
      questionaireStart,
      questioniareEnd,
      lgaValidationStart,
      lgaValidatiionEnd,
      stateValidationStart,
      stateValidationEnd,
      reportGenerationDate,
      session,
    };
  },
  computed: {
    juniorEnrolmentData() {
      return this.$store.state.enrolment.juniorEnrolmentByAgeData;
    },
    dataSource() {
      const dataItems = [];
      dataItems.push(this.$store.state.asc.activePlanning);
      return dataItems;
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage;
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message;
      }
      return "";
    },
  },
  methods: {
    ...mapActions(["updateRequest", "fetchJuniorEnrolmentByAge"]),
    ...mapMutations(["setJuniorEnrolmentByAgeData"]),
    onActive(id) {
      this.$store.dispatch("deActivatePlanning").then(() => {
        this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        });
        this.$emit("reFetchPlanning");
        this.$store.dispatch("checkPlanning").then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          });
        });
      });
    },

    handleCancel() {
      this.showModal = false;
    },
  },
  mounted() {
    console.log(this.dataSource, "table");
    this.$store.dispatch("checkPlanning");
  },
};
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td,
th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
