<template>
  <div>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"            
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="viewEnrolmentDetail(record.key)">
                View
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Table, Button, Space } from "ant-design-vue"
import { computed, ref } from 'vue'

export default {
  name: 'EnrolmentsTable',
  emits: ['viewEnrolmentInfo'],
  props:['schoolId'],
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,
  },
  setup(props, {emit}) {
    const columns = [
      { title: 'Submission Title', dataIndex: 'title', width: "75%" },    
      {
        title: 'operation',
        dataIndex: 'operation',        
      },
    ]

    const dataSource = ref([
      {
        key: '0',
        title: 'Enrolment By Birth Certificate',
      },
      {
        key: '1',
        title: 'Enrolment By Age Pre-primary',
      },
      {
        key: '2',
        title: 'Enrolment By Age Primary',
      },
      {
        key: '3',
        title: 'Enrolment By Age Junior',
      },
      {
        key: '4',
        title: 'Enrolment By Age Senior',
      },
      {
        key: '5',
        title: 'Enrolment Of New Entrant Primary One',
      },
      {
        key: '6',
        title: 'Enrolment Of New Entrant JSS One',
      },
      {
        key: '7',
        title: 'Enrolment Of New Entrant SS One',
      },
      {
        key: '8',
        title: 'Enrolment By Stream',
      },
      {
        key: '9',
        title: 'Enrolment Of Student Flow By Class',
      },
      {
        key: '10',
        title: 'Enrolment Of Persons With Special Needs By Class',
      },
      {
        key: '11',
        title: 'Enrolment Of Orphans By Grade',
      },
      {
        key: '12',
        title: 'Enrolment Of NABTEB Examination Of Previous School Year',
      },
    ])

    

    return {
      columns,
      dataSource,         
    }
  },

  computed: {
    selectedSchoolId() {
      return this.schoolId
    }
  },
  methods: {
    viewEnrolmentDetail(key){
      this.$emit('viewEnrolmentInfo', {key:key, schoolId: this.selectedSchoolId})
    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>