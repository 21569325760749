<template>
  <div class="d-dlex flex-column">
    <div
      class="d-flex justify-content-end align-items-center mt-1 mb-2 mt-5"
    ></div>

    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white ">
      <div class="row justify-content-between px-2 pb-4">
        <h3 class="text-center mb-5">
          FEDERAL MINISTRY OF EDUCATION SCHOOL CENSUS FORM PRE-PRIMARY
          AND PRIMARY
        </h3>

        <p class="text-center">
          1). Please ensure that, as an ENUMERATOR, you have two copies of this
          School Census Form for every school. After you have completed all
          forms, give one copy to the school for records and return a copy to
          your SUPERVISOR.
        </p>
        <p class="text-center mt-5">
          2). Please read the instructions and the examples provided. Make sure
          that each section is completed carefully and that all your figures and
          totals are correct.
        </p>
      </div>

      <div class="d-flex justify-content-center">
        <button
          class="custom-btn font-tiny fw-bold"
          @click="$emit('showOption')"
        >
          Proceed
        </button>
      </div>
      <div class="d-flex flex-column align-items-center mb-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    const schoolInfo = {};
    return {
      schoolInfo,
    };
  },
};
</script>
