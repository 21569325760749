<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Gross Enrolment Ratio (GER)</p>

    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-tiny" aria-label="Users table">
        <thead>
          <tr>
            <th class="align-middle py-3 ps-4"></th>
            <th class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-center">
                <span class="font-tiny">Primary</span>
              </div>
            </th>
            <th class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-center">
                <span class="font-tiny">JSS</span>
              </div>
            </th>
            <th class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-center">
                <span class="font-tiny">SSS</span>
              </div>
            </th>
          </tr>
          <tr>
            <th class="align-middle py-3 ps-4 offering-cell">Number of schools with: </th>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
          </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg"
            v-for="(data, index) in tableData"
            :key="index"
          >
            <td class="py-3 ps-4 offering-cell">{{ data.lga }}</td>
            <td class="py-3 cell-size">{{ data.boys_primary }}</td>
            <td class="py-3 cell-size">{{ data.girls_primary }}</td>
            <td class="py-3 cell-size">{{ data.total_primary }}</td>
            <td class="py-3 cell-size">{{ data.boys_jss }}</td>
            <td class="py-3 cell-size">{{ data.girls_jss }}</td>
            <td class="py-3 cell-size">{{ data.total_jss }}</td>
            <td class="py-3 cell-size">{{ data.boys_sss }}</td>
            <td class="py-3 cell-size">{{ data.girls_sss }}</td>
            <td class="py-3 cell-size">{{ data.total_sss }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "GrossEnrolmentRatio",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 26, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })

    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
.offering-cell {
  width: 28%;
}
.cell-size {
  width: 8%;
}
</style>