<template>
  <div>
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"           
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if=" moeUser !== 'ROLE_MOE_ADMIN' && column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>
    <a-modal     
      :visible="showModal" 
      title="Update Number of Students By Subject" 
      @cancel="handleCancel">
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Submit</a-button>
      </template>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="School Class"
            name="schoolClass"
          >
            <input class="form-control" :value="schoolClass" disabled />
          </a-form-item>          
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Gender"
            name="gender"
          >
            <input class="form-control" :value="gender" disabled />
          </a-form-item>          
        </a-col>
      </a-row>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="Subject"
            name="subject"
          >
            <input class="form-control" :value="subject" disabled />
          </a-form-item>          
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Number of Student By Subject"
            name="number"
          >
            <input class="form-control" type="number" :value="numberOfStudent" />
          </a-form-item>          
        </a-col>
      </a-row>          
    </a-modal>
  </div>
</template>

<script>
import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification } from "ant-design-vue"
import { onMounted, ref } from 'vue'
import { useStore, mapActions, mapMutations } from 'vuex'
import {computed} from "@vue/runtime-core";
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'NumberStudentBySubject',
  emits: ['viewEnrolmentInfo'],
  props: ['title', 'schoolId'],
  mixins: [enrolmentMixinVue],
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,
    'a-modal': Modal,
     'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
    'a-notification': Notification,
  },
  setup() {
    const store = useStore()
    const showModal = ref(false)
    const selectedObject = ref(null)
    const schoolClass = ref('')
    const gender = ref('')
    const subject = ref('')
    const numberOfStudent = ref('')
    onMounted(() => {
      // store.dispatch('fetchNumberOfStudentBySubject', 2)
      // console.log(store.state.numberOfStudentBySubject);
    })    
    const columns = [
      { title: 'School Class', dataIndex: 'schoolClass', width: '15%' }, 
      { title: 'Gender', dataIndex: 'gender', width: '15%' },
      { title: 'Subject', dataIndex: 'subject', width: '30%' },           
      { title: 'Num of Students', dataIndex: 'number', width: '10%' },           
      {
        title: 'operation',
        dataIndex: 'operation',    
        width: '15%'
      },
    ]
    const moeUser = computed(() => store.state.auth.user.role)
    return {  
      moeUser,    
      columns,
      showModal,
    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    numberOfStudentBySubjectData() {
      return this.$store.state.questionnaireService.numberOfStudentBySubject
    },
    dataSource() {
      const dataItems = []      
      if (this.numberOfStudentBySubjectData != null) {
        const data = Object.values(this.numberOfStudentBySubjectData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                subject: element["subject"],
                gender: element["gender"],
                schoolClass: element["schoolClass"],
                number: element["number"]
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  mounted(){
    console.log(this.schoolId)
    const selectedSchoolId = this.schoolId ? this.schoolId : this.ownSchool.id
   this.$store.dispatch('fetchNumberOfStudentBySubject', selectedSchoolId)
  },
  methods: {
    ...mapActions(['updateNumberOfStudentBySubject']),
    ...mapMutations(['setUpdateMessageLoading']),
    editRecord(key) {
      const detail = this.numberOfStudentBySubjectData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolClass = detail.schoolClass
        this.gender = detail.gender
        this.subject = detail.subject
        this.numberOfStudent = detail.number
      }  
    },
    handleSubmit() {
      const payload = {
        id: this.selectedObject.id,
        body: {
          subject: this.subject,
          gender: this.gender,
          schoolClass: this.schoolClass,
          number: this.numberOfStudent
        }
      }

      console.log(payload)
      this.updateNumberOfStudentBySubject(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.schoolClass = ''
            this.gender = ''
            this.subject = ''
            this.numberOfStudent = ''
            
            const type = this.updateMessage.status + ""
                        
            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });
          }
        })      
    },
    handleCancel() {
      this.showModal = false
    },
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>