<template>
  <div class="d-flex flex-column">
    <div class="d-flex mt-5">
      <div class="col">
        <div class="percentage-container bg-white radius-full me-2">
          <Progress
            type="circle"
            :stroke-color="{'0%': 'rgba(0,1,126,0.6)', '100%': '#00017EBE'}"
            :strokeWidth="5"
            :width="100"
            :height="100"
            :percent="80">
            <template #format="">
              <Progress
                  type="circle"
                  :stroke-color="{'0%': 'rgba(249,136,16,0.7)', '100%': '#f98810'}"
                  :strokeWidth="6" :width="80"
                  :height="80"
                  :percent="65">
                <template #format="">
                  <Progress
                      type="circle"
                      :stroke-color="{'0%': 'rgba(91,199,227,0.7)', '100%': '#5BC7E3'}"
                      :strokeWidth="8" :width="60"
                      :height="60"
                      :percent="80" :format="() => ''" >
                  </Progress>
                </template>
              </Progress>
            </template>
          </Progress>
          <div class="d-flex flex-column ms-4">
            <span class="font-md fw-medium mb-3">Percentage of underage pupils</span>
            <span class="d-flex flex-column">
              <span class="font-tiny fw-normal mb-1">
                <span class="skew-box skye-blue me-2"></span>
                Adamawa - 80%
              </span>
              <span class="font-tiny fw-normal mb-1">
                <span class="skew-box color-ef me-2"></span>
                Yobe - 65%
              </span>
              <span class="font-tiny fw-normal mb-1">
                <span class="skew-box deep-blue me-2"></span>
                Borno - 85%
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="percentage-container bg-white radius-full ms-2">
          <Progress
              type="circle"
              :stroke-color="{'0%': 'rgba(0,1,126,0.6)', '100%': '#00017EBE'}"
              :strokeWidth="5"
              :width="100"
              :height="100"
              :percent="80">
            <template #format="">
              <Progress
                  type="circle"
                  :stroke-color="{'0%': 'rgba(249,136,16,0.7)', '100%': '#f98810'}"
                  :strokeWidth="6" :width="80"
                  :height="80"
                  :percent="65">
                <template #format="">
                  <Progress
                      type="circle"
                      :stroke-color="{'0%': 'rgba(91,199,227,0.7)', '100%': '#5BC7E3'}"
                      :strokeWidth="8" :width="60"
                      :height="60"
                      :percent="80" :format="() => ''" >
                  </Progress>
                </template>
              </Progress>
            </template>
          </Progress>
          <div class="d-flex flex-column ms-4">
            <span class="font-md fw-medium mb-3">Percentage of overage pupils</span>
            <span class="d-flex flex-column">
              <span class="font-tiny fw-normal mb-1">
                <span class="skew-box skye-blue me-2"></span>
                Adamawa - 80%
              </span>
              <span class="font-tiny fw-normal mb-1">
                <span class="skew-box color-ef me-2"></span>
                Yobe - 65%
              </span>
              <span class="font-tiny fw-normal mb-1">
                <span class="skew-box deep-blue me-2"></span>
                Borno - 85%
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="graph-container d-flex flex-column bg-white radius-full my-5">
      <p class="font-sm fw-bold">Enrolment rate of pre-school education</p>
      <canvas class="charts" id="pre-school-enrolment-chart" height="300"></canvas>
    </div>

    <div class="graph-container d-flex flex-column bg-white radius-full mb-5">
      <p class="font-sm fw-bold">Enrolment rate of alternative instruction forms of pre-school education</p>
      <canvas class="charts" id="pre-school-alternative-chart" height="300"></canvas>
    </div>

    <div class="graph-container d-flex flex-column bg-white radius-full mb-5">
      <p class="font-sm fw-bold">Attendance rate</p>
      <canvas class="charts" id="attendance-rate-chart" height="300"></canvas>
    </div>
  </div>
</template>

<script>
import {Progress} from 'ant-design-vue'
import {onMounted} from "vue";
import Chart from "chart.js/auto";

export default {
  name: "CAAccess",
  components: {
    Progress
  },
  setup() {
    const states = ["Adamawa", "Borno", "Yobe"]
    const enrolmentData = [80, 65, 85]
    const altEnrolmentData = [50, 15, 65]
    const attendanceData = [25, 10, 50]
    const preSchoolEnrolmentData = {
      type: 'bar',
      maintainAspectRatio: false,
      data: {
        labels: states,
        datasets: [
          {
            label: "Enrolment rate",
            data: enrolmentData,
            barPercentage: .6,
            backgroundColor: [
              'rgba(0, 204, 242, 1)',
              'rgba(249, 136, 16, 1)',
              'rgba(0, 1, 126, .75)',
            ],
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom'
          }
        }
      },
    }
    const preSchoolAlternativeData = {
      type: 'bar',
      maintainAspectRatio: false,
      data: {
        labels: states,
        datasets: [
          {
            label: "Alternative pre-pry enrolment",
            data: altEnrolmentData,
            barPercentage: .6,
            backgroundColor: [
              'rgba(0, 204, 242, 1)',
              'rgba(249, 136, 16, 1)',
              'rgba(0, 1, 126, .75)',
            ],
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom'
          }
        }
      },
    }
    const attendanceRateData = {
      type: 'bar',
      maintainAspectRatio: false,
      data: {
        labels: states,
        datasets: [
          {
            label: "Attendance rate",
            data: attendanceData,
            barPercentage: .6,
            backgroundColor: [
              'rgba(0, 204, 242, 1)',
              'rgba(249, 136, 16, 1)',
              'rgba(0, 1, 126, .75)',
            ],
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom'
          }
        }
      },
    }

    onMounted(() => {
      const prySchoolChart = document.getElementById("pre-school-enrolment-chart")
      const prySchoolAlternativeChart = document.getElementById("pre-school-alternative-chart")
      const attendanceRateChart = document.getElementById("attendance-rate-chart")

      new Chart(prySchoolChart, preSchoolEnrolmentData)
      new Chart(prySchoolAlternativeChart, preSchoolAlternativeData)
      new Chart(attendanceRateChart, attendanceRateData)
    })
  }
}
</script>

<style scoped>

</style>