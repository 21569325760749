<template>
  <div class="graph-container d-flex flex-column bg-white radius-full mt-4 mb-4">
    <div class="d-flex justify-content-between">
      <span class="font-sm fw-bold">Pupil Teachers Ratio</span>
      <a-select 
        :value="pupilTeacherRatioOption"
        style="width: 180px"
        placeholder="View by"
        :options="viewByOptions.map(option => ({value: option}))"
        @change="pupilTeacherRatioOptionChange"
      />
    </div>
    <div>
      <canvas class="charts" id="pupil-teacher-ratio-chart" height="300"></canvas>
    </div>    
  </div>
</template>

<script>
import {Select} from "ant-design-vue"
import Chart from "chart.js/auto";
import { useStore } from 'vuex'
import { watch, ref, computed, onMounted } from 'vue'

export default {
  name: "PupilTeacherRatioChart",
  components: {
    "a-select": Select,
  },
  emits: ['reload'],
  setup(props, {emit}) {
    const pupilTeacherRatioOption = ref('')
    const store = useStore()
    const params = ref()    
    const viewByOptions = ['LGA', 'Level', 'Classification', 'Total Students', 'Total Teachers', 
      'Qualified Teachers', 'Pupil Teacher Ratio', 'Pupil Teacher Ratio Qualified'
    ]

    const selectedOptions = ref('')
    const chartData = ref(null)
    const xAxisLabel = ref([])
    const dataSet = ref([])    
    const pupilTeacherRatioData = computed(() => store.state.analysis.pupilTeachersData)

    const populateChart = () => {
      let checkTotalTeachers = Object.values(pupilTeacherRatioData.value.data)[0].total_teachers
      let checkQualifiedTeachers = Object.values(pupilTeacherRatioData.value.data)[0].qualified_teachers
      let checkPupilTeachers = Object.values(pupilTeacherRatioData.value.data)[0].pupil_teacher_ratio
      let checkPupilQualifiedTeachers = Object.values(pupilTeacherRatioData.value.data)[0].pupil_teacher_ratio_qualified
      
      if (checkTotalTeachers !== undefined) {
        pupilTeacherRatioOption.value = 'Total Teachers'
        dataSet.value = Object.values(pupilTeacherRatioData.value.data.map(item => item.total_teachers))
      } else if (checkQualifiedTeachers !== undefined) {
        pupilTeacherRatioOption.value = 'Qualified Teachers'
        dataSet.value = Object.values(pupilTeacherRatioData.value.data.map(item => item.qualified_teachers))
      } else if (checkPupilTeachers !== undefined) {
        pupilTeacherRatioOption.value = 'Pupil Teacher Ratio'
        dataSet.value = Object.values(pupilTeacherRatioData.value.data.map(item => item.pupil_teacher_ratio))
      } else if (checkPupilQualifiedTeachers !== undefined) {
        pupilTeacherRatioOption.value = 'Pupil Teacher Ratio Qualified'
        dataSet.value = Object.values(pupilTeacherRatioData.value.data.map(item => item.pupil_teacher_ratio_qualified))
      } else {
        pupilTeacherRatioOption.value = 'Total Student'
        dataSet.value = Object.values(pupilTeacherRatioData.value.data.map(item => item.total_students))
      }

      let checkLevel = Object.values(pupilTeacherRatioData.value.data)[0].id.level
      let checkClassification = Object.values(pupilTeacherRatioData.value.data)[0].id.classification      
      if (checkLevel !== undefined) {
        pupilTeacherRatioOption.value = 'Level'
        xAxisLabel.value = Object.values(pupilTeacherRatioData.value.data.map(item => item.id.level))
      } else if (checkClassification !== undefined) {
        pupilTeacherRatioOption.value = 'Classification'
        xAxisLabel.value = Object.values(pupilTeacherRatioData.value.data.map(item => item.id.classification))
      } else {
        pupilTeacherRatioOption.value = 'LGA'
        xAxisLabel.value = Object.values(pupilTeacherRatioData.value.data.map(item => item.id.lga))
      }
      
      chartData.value = {
        type: 'bar',
        data: {
          labels: xAxisLabel.value,
          datasets: [
            {
              label: "Pupil Teachers Ratio",
              categoryPercentage: 0.3,
              borderRadius: 2,
              data: dataSet.value,
              backgroundColor: 'rgba(0, 242, 104, 1)',
            },
          ]
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        },
      }
    }
    
    watch(
      () => pupilTeacherRatioData.value,
      () => {        
        if (pupilTeacherRatioData.value !== null && chartData.value === null) {    
          console.log("I'm reloading now!");
          emit('reload')
        }
      }
    )

    onMounted(() => {
      if (pupilTeacherRatioData.value === null) {
        store.dispatch('fetchPupilTeachersRatioData', params.value)
      } else {
        populateChart()
        const combinedEnrolmentChart = document.getElementById('pupil-teacher-ratio-chart')     
        new Chart(combinedEnrolmentChart, chartData.value)     
      }
    }) 

    const pupilTeacherRatioOptionChange = (value) => {      
      const param = value.toLowerCase().replaceAll(' ', '_')
      selectedOptions.value = param
      chartData.value = null
      store.dispatch('fetchPupilTeachersRatioData', param)
    }

    return {
      viewByOptions,
      selectedOptions,
      pupilTeacherRatioOption,
      pupilTeacherRatioOptionChange
    }
  },
}
</script>

<style scoped>
.charts {
  max-height: 300px;
}
</style>