<template>
  <div class="d-flex flex-row-reverse bg-accent">
    <dashboard-sidebar
      :activeScreen="activeComponent"
      @showAccess="showAccess"
      @showQuality="showQuality"
      @showManagement="showManagement" 
      @showComparativeAnalysis="showCA" />
    <div class="col-md-10 main-content px-5 pt-4 align-self-end">
      <dashboard-header
        @goBack="goBack"
        @userDashboard="showUserDashboard"
        @schoolDashboard="showSchoolDashboard"
        @userProfile="showProfilePage"
        @ascDashboard="gotoASCDashboard"
      />
      <personal-info
        :auth-user="user"
        v-if="screens.showProfile" />

      <h4>{{ title }}</h4>
      <!-- <div
          v-if="!screens.showProfile"
          class="d-flex flex-column mb-4">
        <span class="d-flex">
          <span
            :class="[isActive === 'public' ? 'is-active-bordered' : 'is-inactive-bordered',
            'px-3 py-2 font-sm fw-normal pointer-no-bg']"
          >
            Public
          </span>
          <span
            :class="[isActive === 'private' ? 'is-active-bordered' : 'is-inactive-bordered',
            'px-3 py-2 font-sm fw-normal pointer-no-bg']"
          >
            Private
          </span>
        </span>
      </div> -->
      <access v-if="screens.access" />
      <quality v-if="screens.quality" />
      <management v-if="screens.management" />
      <comparative-analysis v-if="screens.comparativeAnalysis" />
    </div>
  </div>
</template>

<script>
import dashboardMixinVue from "@/helpers/mixins/dashboardMixin.vue";
import DashboardSidebar from "@/components/dashboardPage/DashboardSidebar";
import DashboardHeader from "@/components/dashboardPage/DashboardHeader";
import PersonalInfo from "@/components/dashboardPage/user/PersonalInfo";
import Access from "@/components/dashboardPage/main/Access";
import Quality from "@/components/dashboardPage/main/Quality";
import Management from "@/components/dashboardPage/main/Management";
import ComparativeAnalysis from "@/components/dashboardPage/main/ComparativeAnalysis";
import {resetAll} from "@/helpers/utils/helper_functions";

export default {
  name: "Dashboard",
  mixins: [dashboardMixinVue],
  components: {
    Management,
    Quality,
    Access,
    PersonalInfo,
    DashboardHeader,
    DashboardSidebar,
    ComparativeAnalysis,
  },
  data() {
    return {
      isActive: 'public',
      activeComponent: 'access',
      title: '',
      screens: {
        showProfile: false,
        access: true,
        quality: false,
        management: false,
        comparativeAnalysis: false
      }
    };
  },
  mounted() {
    if (this.activeComponent == "access") {
      this.title = 'Access';
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    showProfilePage() {
      resetAll(this.screens)
      this.screens.showProfile = true;
      this.activeComponent = 'profile'
    },
    showUserDashboard() {
      this.gotoUserDashboard();
    },
    showSchoolDashboard() {
      this.gotoSchoolDashboard();
    },
    showAccess() {
      resetAll(this.screens)
      this.screens.access = true
      this.activeComponent = 'access'
      this.title = 'Access'
    },
    showQuality() {
      resetAll(this.screens)
      this.screens.quality = true
      this.activeComponent = 'quality',
      this.title = 'Quality'
    },
    showManagement() {
      resetAll(this.screens)
      this.screens.management = true
      this.activeComponent = 'management'
    },
    showCA() {
      resetAll(this.screens)
      this.screens.comparativeAnalysis = 'comparativeAnalysis'
    }
  },
};
</script>
