<template>
  <div class="d-dlex flex-column">
    <p class="fw-bold grey-accent mt-4 pt-2 mb-0">Personal Information</p>
    <div class="d-flex justify-content-end align-items-center mt-1 mb-2">
      <div class="dropdown">
        <span
          class="dropdown-toggle grey-accent font-tiny"
          type="button"
          id="options"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="bi bi-three-dots-vertical"></i> Option
        </span>
        <ul
          class="
            dropdown-menu dropdown-menu-end
            bg-accent
            shadow
            font-tiny
            radius-half
          "
          aria-labelledby="options"
        >
          <li>
            <a class="dropdown-item grey-accent" href="#"
              ><i class="bi bi-pencil-fill me-2"></i> Make Admin</a
            >
          </li>
          <li>
            <a class="dropdown-item grey-accent" href="#"
              ><i class="bi bi-x-lg me-2"></i> Delete User</a
            >
          </li>
        </ul>
      </div>
      <span
        class="grey-accent pointer-no-bg font-tiny px-3 mt-1"
        @click="$emit('editUserInfo')"
      >
        <i class="bi bi-pencil-fill"></i> Edit
      </span>
    </div>

    <div
      class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white shadow-sm"
    >
      <div class="d-flex flex-column px-4">
        <img
          :src="dummyAvatar"
          class="rounded-circle"
          width="100"
          height="100"
          alt=""
        />
        <span class="font-tiny grey-accent mt-1"
          ><i class="bi bi-pen-fill pb-1"></i> Change Photo</span
        >
      </div>
      <div class="row justify-content-between px-2 pb-4">
        <div class="col-4 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            First name
          </label>
          <input
            type="text"
            class="form-control"          
            :value="firstName"
            disabled
          />
        </div>
        <div class="col-4 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Last name
          </label>
          <input
            type="text"
            class="form-control"
            :value="lastName"
            disabled
          />
        </div>
        <div class="col-4 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Middle name
          </label>
          <input
            type="text"
            class="form-control"
            :value="middleName"
            disabled
          />
        </div>
        <div class="col-4 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Date of birth
          </label>
          <input
            type="text"
            class="form-control"
            :value="dob"
            disabled
          />
        </div>
        <div class="col-4 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Gender
          </label>
          <input
            type="text"
            class="form-control"
            :value="gender"
            disabled
          />
        </div>
        <div class="col-4 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            State
          </label>
          <input
            type="text"
            class="form-control"
            :value="state"
            disabled
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Email
          </label>
          <input
            type="email"
            class="form-control"
            :value="email"
            disabled
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Phone number
          </label>
          <input
            type="tel"
            class="form-control"
            :value="mobile"
            disabled
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Nationality
          </label>
          <input
            type="text"
            class="form-control"
            :value="nationality"
            disabled
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Local Government
          </label>
          <input
            type="text"
            class="form-control"
            :value="lga"
            disabled
          />
        </div>
      </div>
    </div>

    <p class="fw-bold mb-1">Institute of Affiliation</p>
    <div class="d-flex justify-content-end">
      <span class="grey-accent font-tiny px-3 my-1">
        <i class="bi bi-pencil-fill"></i> Edit
      </span>
    </div>

    <div
      class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white shadow-sm"
    >
      <div class="row pb-4">
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Role
          </label>
          <input
            type="text"
            class="form-control"
            :value="role"
            disabled
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Organisation
          </label>
          <input
            type="text"
            class="form-control"
            :value="organisation"
            disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import avatar from "@/assets/images/dummyUser.png";
import { toRef, ref } from '@vue/reactivity';

export default {
  name: "PersonalInfo",
  props: ["authUser"],
  setup(props) {
    const dummyAvatar = avatar;
    const user = toRef(props, 'authUser')

    const firstName = ref('')
    const lastName = ref('')
    const middleName = ref('')
    const dob = ref('')
    const gender = ref('')
    const state = ref('')
    const email = ref('')
    const mobile = ref('')
    const nationality = ref('')
    const lga = ref('')
    const role = ref('')
    const organisation = ref('')

    if (user.value !== undefined && user.value !== null) {
      firstName.value = user.value.firstName
      lastName.value = user.value.lastName
      middleName.value = user.value.middleName
      dob.value = user.value.dob
      gender.value = user.value.gender
      state.value = user.value.state
      email.value = user.value.email
      mobile.value = user.value.mobile
      nationality.value = user.value.nationality
      lga.value = user.value.lga
      role.value = user.value.role
      organisation.value = user.value.organisation
    } 

    return {
      dummyAvatar,
      firstName,
      lastName,
      middleName,
      dob,
      gender,
      state,
      email,
      mobile,
      nationality,
      lga,
      role,
      organisation,
    };
  },
};
</script>
