const state = {
  userDashboardActiveScreen: null,
}

const mutations = {
  setUserDashboardActiveScreen(state, value) {
    state.userDashboardActiveScreen = value
  }
}

export default {
  state,
  mutations,
}