<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">

      <clip-loader v-if="loading" class="loader-item mt-3"></clip-loader>

      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"                  
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal     
      :visible="showModal" 
      title="Update FLHE Question" 
      @cancel="handleCancel">
      
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Update</a-button>
      </template>

      <a-form layout="vertical">   
        <a-row justify="space-between">
          <a-col :span="24">
            <a-form-item
              label="School Level"
              name="schoolLevelOption"
            >
              <input type="text" class="form-control" :value="schoolLevelOption" disabled>
            </a-form-item>          
          </a-col>        
        </a-row>

        <a-row justify="space-between">
          <a-col :span="24">
            <a-form-item
              label="Gender"
              name="schoolLevelOption"
            >
              <input type="text" class="form-control" :value="gender" disabled>
            </a-form-item>          
          </a-col>        
        </a-row>

        <a-row justify="space-between">
          <a-col :span="24">
            <a-form-item
              :label="flheQuestionGenderOption"
              name="schoolLevelOption"
            >
              <input type="text" class="form-control" v-model="answerNumber">
            </a-form-item>          
          </a-col>        
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>

function replaceUnderScore(str) {
  if (str !== undefined) return str.replaceAll("_", " ")
}

import { Table, Button, Space, Modal, Select, SelectOption, Form, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { onMounted, ref, computed } from 'vue'
import { mapActions, useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: 'FLHEGenderBasedQuestionsTable',
  emits: ['viewEnrolmentInfo'],
  props: ['title'],
  mixins: [enrolmentMixinVue], 
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form': Form,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
    'a-select': Select,
    'a-select-option': SelectOption,
    'a-form-item': FormItem,
    ClipLoader,
  },
  setup() {      
    const endpoint = 'questionaire/flhe-gender'
    const store = useStore()  
    const loading = computed(() => store.state.classroom.updateLoading)
    const message = computed(() => store.state.classroom.updateMessage)

    onMounted(() => {
      store.dispatch('fetchRequest', endpoint)
      .then(() => {
        if (message.value != null) {
          const type = message.value.status
          if (type === 'error') {
            notification.open({
              type: type,
              message: 'Update Message',
              description: message.value.message
            });   
          }
        }
      })
    })

    const showModal = ref(false)
    const selectedObject = ref(null)
    const selectedQuestion = ref('')
    const schoolLevelOption = ref()
    const gender = ref()
    const flheQuestionGenderOption = ref()
    const answerNumber = ref()

    const genderQuestions = {
      "Q1" : "Number of students that received/particated in Life Skills-based " + 
                "Family Live HIV/AIDS Education (FLHE) in the previous year?",
      "Q2" : "How many teachers in your school received formal training on FLHE?",
      "Q3" : "How many teachers in your school who received formal training in the previous " +
							"year also taught related topics on FLHE?"
    }

    const columns = [
      { title: 'School Class', dataIndex: 'schoolLevelOption'},       
      { title: 'Question', dataIndex: 'flheQuestionGenderOption', width: '50%'},
      { title: 'Gender', dataIndex: 'gender'},
      { title: 'Answer', dataIndex: 'answerNumber', className: 'content-alignment' },
      {
        title: 'operation',
        dataIndex: 'operation',         
      },
    ]

    return {    
      endpoint,
      loading,
      columns,  
      showModal,
      selectedObject,
      schoolLevelOption,
      gender,
      flheQuestionGenderOption,      
      answerNumber,

      selectedQuestion,    
      genderQuestions,
    }
  },
  computed: {
    classroomData() {
      return this.$store.state.classroom.classroomData
    },
    updateMessage() {
      return this.$store.state.classroom.updateMessage
    },
    dataSource() {
      const dataItems = []      
      if (this.classroomData != null) {
        const data = Object.values(this.classroomData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolLevelOption: replaceUnderScore(element["schoolLevelOption"]),
                gender: element["gender"],
                flheQuestionGenderOption: this.genderQuestions[element["flheQuestionGenderOption"]],
                answerNumber: element["answerNumber"],
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {

    ...mapActions(['updateSubmissionRequest', 'fetchRequest']),    

    editRecord(key) {
      const detail = this.classroomData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolLevelOption = replaceUnderScore(detail.schoolLevelOption)
        this.gender = detail.gender
        this.flheQuestionGenderOption = this.genderQuestions[detail.flheQuestionGenderOption]
        this.answerNumber = detail.answerNumber
      } 
    },

    handleSubmit() {
      const payload = {        
        endpoint: `questionaire/flhe-gender/${this.selectedObject.id}`,
        body: {
          flheQuestionGenderOption: this.selectedObject.flheQuestionGenderOption,
          gender: this.selectedObject.gender,
          schoolLevelOption: this.selectedObject.schoolLevelOption,
          flheQuestionTypeGender: this.selectedObject.flheQuestionTypeGender,
          school: this.selectedObject.school,
          answerNumber: Number(this.answerNumber),            
        }
      }
      this.updateSubmissionRequest(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.flheQuestionGenderOption = ''
            this.answerNumber = ''
            
            const type = this.updateMessage.status

            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });            

            if (type === 'success') {              
              this.fetchRequest(this.endpoint)
            }                      
          }
        })  
    },

    handleCancel() {
      this.showModal = false
    },
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
