<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Percentage of long-term absent teachers (Public Only)</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-tiny" aria-label="Users table">
        <thead>
        <tr>
          <th class="align-middle lg-cell py-3 ps-4" rowspan="2">
            Percentage of long-term absent teachers on:
          </th>
          <th class="align-middle text-center py-3" colspan="2">Primary</th>
          <th class="align-middle text-center py-3" colspan="2">Junior secondary</th>
          <th class="align-middle text-center py-3" colspan="2">Senior secondary</th>
          <th class="align-middle text-center py-3" colspan="2">Science & Tech secondary schools</th>
        </tr>
        <tr>
          <th class="align-middle py-3 cell-size" scope="col">Urban %</th>
          <th class="align-middle py-3 cell-size" scope="col">Rural %</th>
          <th class="align-middle py-3 cell-size" scope="col">Urban %</th>
          <th class="align-middle py-3 cell-size" scope="col">Rural %</th>
          <th class="align-middle py-3 cell-size" scope="col">Urban %</th>
          <th class="align-middle py-3 cell-size" scope="col">Rural %</th>
          <th class="align-middle py-3 other-cell" scope="col">Urban %</th>
          <th class="align-middle py-3 other-cell" scope="col">Rural %</th>
        </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
        <tr class="pointer-no-bg"
          v-for="(data, index) in tableData"
          :key="index"  
        >
          <td class="py-3 ps-4 lg-cell">{{ data.percentage_of_long_term_absent_teachers_on_ }}</td>
          <td class="py-3 cell-size">{{ data.primary_urban_percentage }}</td>
          <td class="py-3 cell-size">{{ data.primary_rural_percentage }}</td>
          <td class="py-3 cell-size">{{ data.junior_secondary_urban_percentage }}</td>
          <td class="py-3 cell-size">{{ data.junior_secondary_rural_percentage }}</td>
          <td class="py-3 cell-size">{{ data.senior_secondary_urban_percentage }}</td>
          <td class="py-3 cell-size">{{ data.senior_secondary_rural_percentage }}</td>
          <td class="py-3 cell-size">{{ data.science_and_tech_secondary_schools_urban_percentage }}</td>
          <td class="py-3 cell-size">{{ data.science_and_tech_secondary_schools_rural_percentage }}</td>
        </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="9" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "LongTermAbsentTeachersPublicOnly",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 45, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })

    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
.lg-cell {
  width: 20%;
}
.cell-size {
  width: 10%;
}
</style>