<template>
  <div class="px-5">
    <div v-if="loadingStatus" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <historical-public-pry-enrolments />
  </div>
</template>

<script>
import HistoricalPublicPryEnrolments
  from "@/components/dashboardPage/dataTable/HistoricalASInfoTables/HistoricalPublicPryEnrolments";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import {computed, onMounted} from "vue"
import { useStore } from 'vuex';

export default {
  name: "HistoricalASCInfo",
  components: {HistoricalPublicPryEnrolments, ClipLoader},
  setup(){
    const store = useStore()
    onMounted(() => {
      store.commit("setCurrentStage", null)
    })
    const loadingStatus = computed(() => store.state.datatables.loadingStatus)
    return {
      loadingStatus
    }
  },
}
</script>

<style scoped>

</style>