<template>
  <div class="bg-accent d-flex flex-column min-vh-100">
    <div class="container py-4">
      <dashboard-header 
        @goBack="goBack"
        :hasBack="true"
      />
      <div v-if="loadingStatus" class="overlay">
        <clip-loader class="loading"></clip-loader>
      </div>
      <number-of-classrooms-by-school-type-l-g-a  v-if="index === '0'" />
      <pupil-classroom-ratio v-if="index === '1'" />
      <characteristics-of-public-pre-pry-and-pry v-if="index === '2'" />
      <selective-characteristics-public-pre-pry-and-pry v-if="index === '3'" />
      <characteristics-of-public-junior-school v-if="index === '4'" />
      <selective-characteristics-junior-school v-if="index === '5'" />
      <characteristics-of-public-senior-school v-if="index === '6'" />
      <selective-characteristics-senior-school v-if="index === '7'" />
      <characteristics-of-public-sci-tech-school v-if="index === '8'" />
      <selective-characteristics-sci-tech-school v-if="index === '9'" />
      <main-source-of-water-pry-in-percentage v-if="index === '10'" />
      <main-source-of-water-junior-in-percentage v-if="index === '11'" />
      <main-source-of-water-senior-in-percentage v-if="index ==='12'" />
      <toilet-public-pry-school v-if="index === '13'" />
      <toilet-public-junior-school v-if="index === '14'" />
      <toilet-public-senior-school v-if="index === '15'" />
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import NumberOfClassroomsBySchoolTypeLGA
  from "@/components/dashboardPage/dataTable/FacilitiesTables/NumberOfClassroomsBySchoolTypeLGA";
import PupilClassroomRatio from "@/components/dashboardPage/dataTable/FacilitiesTables/PupilClassroomRatio";
import CharacteristicsOfPublicPrePryAndPry
  from "@/components/dashboardPage/dataTable/FacilitiesTables/CharacteristicsOfPublicPrePryAndPry";
import SelectiveCharacteristicsPublicPrePryAndPry from "@/components/dashboardPage/dataTable/FacilitiesTables/SelectiveCharacteristicsPublicPrePryAndPry";
import CharacteristicsOfPublicJuniorSchool
  from "@/components/dashboardPage/dataTable/FacilitiesTables/CharacteristicsOfPublicJuniorSchool";
import CharacteristicsOfPublicSeniorSchool
  from "@/components/dashboardPage/dataTable/FacilitiesTables/CharacteristicsOfPublicSeniorSchool";
import SelectiveCharacteristicsJuniorSchool
  from "@/components/dashboardPage/dataTable/FacilitiesTables/SelectiveCharacteristicsJuniorSchool";
import SelectiveCharacteristicsSeniorSchool
  from "@/components/dashboardPage/dataTable/FacilitiesTables/SelectiveCharacteristicsSeniorSchool";
import CharacteristicsOfPublicSciTechSchool
  from "@/components/dashboardPage/dataTable/FacilitiesTables/CharacteristicsOfPublicSciTechSchool";
import SelectiveCharacteristicsSciTechSchool
  from "@/components/dashboardPage/dataTable/FacilitiesTables/SelectiveCharacteristicsSciTechSchool";
import MainSourceOfWaterPryInPercentage
  from "@/components/dashboardPage/dataTable/FacilitiesTables/MainSourceOfWaterPryInPercentage";
import MainSourceOfWaterJuniorInPercentage
  from "@/components/dashboardPage/dataTable/FacilitiesTables/MainSourceOfWaterJuniorInPercentage";
import MainSourceOfWaterSeniorInPercentage
  from "@/components/dashboardPage/dataTable/FacilitiesTables/MainSourceOfWaterSeniorInPercentage";
import ToiletPublicPrySchool from "@/components/dashboardPage/dataTable/FacilitiesTables/ToiletPublicPrySchool";
import ToiletPublicJuniorSchool from "@/components/dashboardPage/dataTable/FacilitiesTables/ToiletPublicJuniorSchool";
import ToiletPublicSeniorSchool from "@/components/dashboardPage/dataTable/FacilitiesTables/ToiletPublicSeniorSchool";
import DashboardHeader from '@/components/dashboardPage/DashboardHeader.vue';
import {computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import { useStore } from 'vuex';

export default {
  name: "FacilitiesTables",
  components: {
    ToiletPublicSeniorSchool,
    ToiletPublicJuniorSchool,
    ToiletPublicPrySchool,
    MainSourceOfWaterSeniorInPercentage,
    MainSourceOfWaterJuniorInPercentage,
    MainSourceOfWaterPryInPercentage,
    SelectiveCharacteristicsSciTechSchool,
    CharacteristicsOfPublicSciTechSchool,
    SelectiveCharacteristicsSeniorSchool,
    SelectiveCharacteristicsJuniorSchool,
    CharacteristicsOfPublicSeniorSchool,
    CharacteristicsOfPublicJuniorSchool,
    SelectiveCharacteristicsPublicPrePryAndPry,
    CharacteristicsOfPublicPrePryAndPry, 
    PupilClassroomRatio, 
    NumberOfClassroomsBySchoolTypeLGA,
    ClipLoader,
    DashboardHeader,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const loadingStatus = computed(() => store.state.datatables.loadingStatus)
    const index = route.params.index
    const goBack = () => { store.commit('setCurrentStage', 4); router.back() }
    return {
      index,
      loadingStatus,
      goBack,
    }
  }
}
</script>

<style scoped>

</style>