<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"           
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>
    <a-modal     
      :visible="showModal" 
      title="Update Enrolment By Birth Certificate" 
      @cancel="handleCancel">
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Submit</a-button>
      </template>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="School Class"
            name="schoolClass"
          >
            <a-input :value="schoolClass" disabled />
          </a-form-item>          
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Gender"
            name="gender"
          >
            <a-input :value="gender" disabled />
          </a-form-item>          
        </a-col>
      </a-row>
      <a-row justify="space-between">
        <a-col :span="11">
          <div>
            <label for="npopc" class="form-label">National Populations Commission</label>
            <input type="number" class="form-control" id="npopc" v-model="npopc">
          </div>    
        </a-col>
        <a-col :span="11">
          <div>
            <label for="others" class="form-label">Others</label>
            <input type="number" class="form-control" id="others" v-model="others">
          </div>       
        </a-col>
      </a-row>          
    </a-modal>
  </div>
</template>

<script>
import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification } from "ant-design-vue"
import { onMounted, ref } from 'vue'
import { mapActions, mapMutations, useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'EnrolmentByBirthCertificateTable',
  emits: ['viewEnrolmentInfo'],
  props: ['title', 'schoolId'],
  mixins: [enrolmentMixinVue],
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
  },
  setup() {
    const store = useStore()
    const showModal = ref(false)
    const selectedObject = ref(null)
    const schoolClass = ref('')
    const gender = ref('')
    const npopc = ref()
    const others = ref()  

    const columns = [
      { title: 'School Class', dataIndex: 'schoolClass', width: '15%' }, 
      { title: 'Gender', dataIndex: 'gender', width: '15%' },
      { title: 'Nation Populations Commission', dataIndex: 'npopc', width: '40%', className: 'content-alignment' },    
      { title: 'Others', dataIndex: 'others', width: '15%' },           
      {
        title: 'operation',
        dataIndex: 'operation',    
        width: '15%'
      },
    ]

    return {      
      columns, 
      showModal,       
      selectedObject,         
      schoolClass,
      gender,
      npopc,
      others,
    }
  },
  computed: {
    selectedSchoolId(){
      return this.schoolId
    },
    birthCertData() {
      return this.$store.state.enrolment.birthCertData
    },
    dataSource() {
      const dataItems = []      
      if (this.birthCertData != null) {
        const data = Object.values(this.birthCertData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                npopc: element["npopc"],
                others: element["others"],
                gender: element["gender"],
                schoolClass: element["schoolClass"]
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {
    ...mapActions(['updateBirthCertEnrolment']),
    ...mapMutations(['setUpdateMessageLoading']),

    editRecord(key) {
      const detail = this.birthCertData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolClass = detail.schoolClass
        this.gender = detail.gender
        this.npopc = detail.npopc
        this.others = detail.others
      }      
    },
    handleSubmit() {
      const payload = {
        id: this.selectedObject.id,
        body: {
          npopc: Number(this.npopc),
          others: Number(this.others)
        }
      }

      console.log(payload);

      this.updateBirthCertEnrolment(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.npopc = ''
            this.others = ''
            
            const type = this.updateMessage.status
                        
            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });
          }
        })      
    },
    handleCancel() {
      this.showModal = false
    },
    onClose() {
      this.setUpdateMessageLoading(null)
    }

  },
  mounted(){
   if(!this.selectedSchoolId) {
    this.$store.commit('setBirthCertData', null)
    this.$store.dispatch('fetchBirthCertEnrolment')
   } else {
     console.log('fetch selected');
    this.$store.commit('setBirthCertData', null)
    this.$store.dispatch('fetchBirthCertEnrolment', this.selectedSchoolId)
   }

  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>