<template>
  <div class="graph-container d-flex flex-column bg-white radius-full mt-4 mb-2">
    <div class="d-flex justify-content-between">
      <span class="font-sm fw-bold">Not Combined Enrolment rate.</span>
      <a-select 
        :value="notCombinedEnrolmentOption"
        style="width: 120px"
        placeholder="View by"
        size="small"
        :options="viewByOptions.map(option => ({value: option}))"
        @change="enrolmentRateChange"
      />
    </div>
    <canvas class="charts" id="not-combined-chart" height="300"></canvas>
  </div>
</template>

<script>
import {Select} from "ant-design-vue"
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import Chart from "chart.js/auto";

export default {
  name: "NotCombinedChart",
  emits: ['reload'],
  components: {"a-select": Select,},
  setup(props, {emit}) {
    const notCombinedEnrolmentOption = ref('')
    const store = useStore()
    const params = ref()
    const viewByOptions = ['LGA', 'Gender', 'Level', 'Age Group', 'Classification']
    const selectedOptions = ref('')
    const chartData = ref(null)
    const xAxisLabel = ref([])
    const dataSet = ref([])    
    const notCombinedEnrolment = computed(() => store.state.analysis.notCombinedEnrolment)

    const populateChart = () => {            
      dataSet.value = Object.values(notCombinedEnrolment.value.data.map(item => item.no_of_enrolment))
      let checkLGA = Object.values(notCombinedEnrolment.value.data)[0].id.lga
      let checkLevel = Object.values(notCombinedEnrolment.value.data)[0].id.level
      let checkAgeGroup = Object.values(notCombinedEnrolment.value.data)[0].id.age_group
      let checkClassification = Object.values(notCombinedEnrolment.value.data)[0].id.classification      
      if (checkLGA !== undefined) {
        notCombinedEnrolmentOption.value = 'LGA'
        xAxisLabel.value = Object.values(notCombinedEnrolment.value.data.map(item => item.id.lga))      
      } else if (checkAgeGroup !== undefined) {
        notCombinedEnrolmentOption.value = 'Age Group'
        xAxisLabel.value = Object.values(notCombinedEnrolment.value.data.map(item => item.id.age_group))
      } else if (checkLevel !== undefined) {
        notCombinedEnrolmentOption.value = 'Level'
        xAxisLabel.value = Object.values(notCombinedEnrolment.value.data.map(item => item.id.level))
      } else if (checkClassification !== undefined) {
        notCombinedEnrolmentOption.value = 'Classification'
        xAxisLabel.value = Object.values(notCombinedEnrolment.value.data.map(item => item.id.classification))
      } else {
        notCombinedEnrolmentOption.value = 'Gender'
        xAxisLabel.value = Object.values(notCombinedEnrolment.value.data.map(item => item.id.gender))
      }
      
      chartData.value = {
        type: 'bar',
        data: {
          labels: xAxisLabel.value,
          datasets: [
            {
              label: "Not Combined Enrolment Rate",
              categoryPercentage: 0.3,
              borderRadius: 2,
              data: dataSet.value,
              backgroundColor: 'rgba(0, 242, 104, 1)',
            },
          ]
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        },
      }
    }
    
    watch(
      () => notCombinedEnrolment.value,
      () => {        
        if (notCombinedEnrolment.value !== null && chartData.value === null) {    
          emit('reload')
        }
      }
    )

    onMounted(() => {
      if (notCombinedEnrolment.value === null) {
        store.dispatch('fetchNotCombinedEnrolmentChart', params.value)
      } else {
        populateChart()
        const notCombinedChart = document.getElementById('not-combined-chart')     
        new Chart(notCombinedChart, chartData.value)     
      }
    }) 

    const enrolmentRateChange = (value) => {      
      const param = value.toLowerCase().replace(' ', '_')
      selectedOptions.value = param
      chartData.value = null
      store.dispatch('fetchNotCombinedEnrolmentChart', param)
    }

    return {
      viewByOptions,
      notCombinedEnrolmentOption,
      enrolmentRateChange
    }
  },
}
</script>