<template>
  <div class="d-flex flex-row-reverse bg-accent">
    <user-sidebar
      :manageUser="screens.usersManagement"
      :profile="screens.profile"
      :account="screens.accountSetting"
      :dashboard="screens.dashboardSetting"
      @showProfile="showProfile"
      @showAccSetting="showAccountSetting"
      @showDbSetting="showDashboardSetting"
      @showUsersManagement="showUsersManagement" />

    <div class="col-md-9 main-content px-5 pt-4 align-self-end">
      
      <div>
        <dashboard-header
          :hasBack="hasBack"
          @goBack="handleGoBack"
          @userDashboard="gotoUserDashboard"
          @schoolDashboard="gotoSchoolDashboard"
          @userProfile="showProfile"
          @editUser="editUserProfile"
          @accountSetting="showAccountSetting"
          @dashboardSetting="showDashboardSetting"
          @ascDashboard="gotoASCDashboard" />
      </div>

      <div class="px-5">
        
        <personal-info
          :authUser="user"
          v-if="screens.profile"
          @editUserInfo="editUserProfile" />
        
        <account-setting v-if="screens.accountSetting" />
        
        <dashboard-setting v-if="screens.dashboardSetting" />

        <users-management
          v-if="screens.usersManagement"
          @createUser="showCreateUser"
          @usersTable="showUsersTable"
          @viewLearnersTable="showLearnersTable" />

        <create-user
          v-if="screens.createUser"
          :edit="userEdit"
          :user="user"
          :key="createUserKey"
          @reload="createUserKey++" />

        <users-table
          v-if="screens.usersTable"
          @addNewUser="showCreateUser"
          @userDraft="showUserDraftTable"
          @showUserProfile="showProfile"
          @editUser="editUser"
          @showUpdateRoleModal=showModal />

        <create-learner 
          v-if="screens.createLearner" />

        <learners-table
          v-if="screens.learnersTable"
          :editLearner="editLearner"
          @addNewLearner="showAddNewLearner" />
          
      </div>
    </div>

    <transition name="modal">
      <modal v-if="roleModalIsVisible">
        <template v-slot:header>
          <span>Update User Role</span>
          <span
            @click="closeModal"
            class="float-right"><i class="bi bi-x-square required"></i></span>
        </template>
        <template v-slot:body>
          <div class="mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Current Role
            </label>
            <input
                type="text"
                class="form-control"
                placeholder="User role"
                v-model="selectedUserRole"
            />
          </div>
          <div class="mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              New Role
            </label>
            <select class="form-select" aria-label="Type of school" v-model="newRole">
              <option class="bg-white" selected>Select role</option>
              <option
                  class="bg-white"
                  v-for="(role, index) in roles"
                  :key="index"
                  :value="'ROLE_' + role"
              >
                {{ role.replace("_", " ") }}
              </option>
            </select>
          </div>
        </template>
        <template v-slot:footer>
          <div class="w-100 d-flex align-items-center justify-content-center">
            <button
                class="custom-btn font-tiny fw-bold"
                @click="updateUserRole"
            >
              Continue
            </button>
          </div>
        </template>
      </modal>
    </transition>

    <transition name="modal">
      <modal v-if="hasMessage" @close="closeModal">
        <template v-slot:header>
          <span>Update</span>
        </template>
        <template v-slot:body>
          <p>{{ message }}</p>
        </template>
      </modal>
    </transition>
    {{message}}
  </div>
</template>

<script>
import Modal from "@/helpers/utils/Modal";
import DashboardHeader from "../../components/dashboardPage/DashboardHeader.vue";
import PersonalInfo from "../../components/dashboardPage/user/PersonalInfo.vue";
import UserSidebar from "../../components/dashboardPage/user/UserSidebar.vue";
import AccountSetting from "../../components/dashboardPage/user/AccountSetting.vue";
import DashboardSetting from "../../components/dashboardPage/user/DashboardSetting.vue";
import UsersManagement from "../../components/dashboardPage/user/UsersManagement.vue";
import dashboardMixinVue from "../../helpers/mixins/dashboardMixin.vue";
import CreateUser from "../../components/dashboardPage/user/CreateUser.vue";
import UsersTable from "../../components/dashboardPage/user/UsersTable.vue";
import {resetAll} from "@/helpers/utils/helper_functions";
import LearnersTable from "@/components/dashboardPage/user/LearnersTable";
import CreateLearner from "@/components/dashboardPage/user/CreateLearner";
import {mapActions, mapMutations} from "vuex";
import {userRoles} from "@/helpers/utils/user_roles";

export default {
  name: "UserDashboard",
  mixins: [dashboardMixinVue],
  components: {
    CreateLearner,
    LearnersTable,
    UserSidebar,
    AccountSetting,
    DashboardHeader,
    DashboardSetting,
    PersonalInfo,
    UsersManagement,
    CreateUser,
    UsersTable,
    Modal
  },
  mounted() {
    let screen = this.activeComponent
    if (screen === 'accountSetting') {
      this.showAccountSetting()
    } else if (screen === 'dashboardSetting') {
      this.showDashboardSetting()
    } else if (screen === 'userProfile') {
      this.showProfile(this.authUser.id)
    } else if (screen === 'editUser') {
      this.editUser(this.authUser)
    } else {
      this.showUsersManagement()
    }
  },
  data() {
    const roles = userRoles;
    const screens = {
      profile: false,
      accountSetting: false,
      dashboardSetting: false,
      usersManagement: false,
      createUser: false,
      usersTable: false,
      createLearner: false,
      learnersTable: false,
      draftTable: false,
    };
    return {
      createUserKey: 0,
      tableTitle: '',
      hasBack: true,
      user: null,
      editLearner: false,
      userEdit: false,
      screens,
      roles,
      roleModalIsVisible: false,
      selectedUser: null,
      newRole: null,
      selectedUserRole: null,
      lastScreen: null,
      currentScreen: null,
    };
  },
  computed: {
    authUser() {
      return this.$store.getters.loginUser;
    },

    allUsers() {
      return this.$store.state.auth.users.content
    },

    message() {
      return this.$store.state.auth.message
    },
    
    hasMessage() {
      return this.message !== null
    },

    activeComponent() {
      return this.$store.state.helper.userDashboardActiveScreen
    }
  },
  methods: {
    ...mapActions(["updateRoleForUser"]),
    ...mapMutations(["setMessage", "setUserDashboardActiveScreen"]),

    showProfile(userId) {
      this.setUserDashboardActiveScreen(null)      
      if (userId === this.authUser.id) {
        this.user = this.authUser
      } else if (userId) {
        this.user = this.allUsers.find(user => user.id === userId);
      } else {
        this.user = undefined;
      }
      this.setLastScreen()
      resetAll(this.screens);
      this.screens.profile = true;
      this.currentScreen = 'profile'
    },
    showAccountSetting() {
      this.setUserDashboardActiveScreen(null)
      this.setLastScreen()
      resetAll(this.screens);
      this.screens.accountSetting = true;
      this.currentScreen = 'accountSetting'
    },
    showDashboardSetting() {
      this.setUserDashboardActiveScreen(null)
      this.setLastScreen()
      resetAll(this.screens);
      this.screens.dashboardSetting = true;
      this.currentScreen = 'dashboardSetting'
    },
    showUsersManagement() {
      this.setUserDashboardActiveScreen(null)
      this.setLastScreen()
      resetAll(this.screens);
      this.screens.usersManagement = true;
      this.currentScreen = 'usersManagement'
    },
    showCreateUser() {
      this.setUserDashboardActiveScreen(null)
      this.setLastScreen()
      if (!this.userEdit) {
        resetAll(this.screens);       
      }
      this.screens.createUser = true;
      this.currentScreen = 'createUser'
    },
    showAddNewLearner() {
      this.setUserDashboardActiveScreen(null)
      this.setLastScreen()
      resetAll(this.screens)
      this.screens.createLearner = true;
      this.currentScreen = 'createLearner'
    },
    showUsersTable() {   
      this.setUserDashboardActiveScreen(null)
      this.setLastScreen()   
      resetAll(this.screens);
      this.screens.usersTable = true;
      this.currentScreen = 'usersTable'
    },
    showLearnersTable() {
      this.setUserDashboardActiveScreen(null)
      this.setLastScreen()
      resetAll(this.screens);
      this.screens.learnersTable = true;
      this.currentScreen = 'learnersTable'
    },    
    editUserProfile() {
      this.setUserDashboardActiveScreen(null)
      this.setLastScreen()
      this.showCreateUser();
      this.userEdit = true;    
    },
    showUserDraftTable() {
      this.setUserDashboardActiveScreen(null)
      this.setLastScreen()
      resetAll(this.screens);
      this.screens.draftTable = true;
      this.currentScreen = 'draftTable'
    },
    editUser(user) {      
      this.setUserDashboardActiveScreen(null)      
      this.setLastScreen()
      resetAll(this.screens);  
      this.user = user
      this.userEdit = true;
      this.showCreateUser();      
    },
    showModal(user) {
      this.roleModalIsVisible = true
      if (user !== null) {
        this.selectedUser = user
        this.selectedUserRole = user.role
      }
    },
    closeModal() {
      this.roleModalIsVisible = false
      this.setMessage(null)
    },
    updateUserRole() {
      this.roleModalIsVisible = false
      const requestBody = {
        role: this.newRole,
        user: this.selectedUser
      }
      this.updateRoleForUser(requestBody)
    },
    setLastScreen() {
      let screens = Object.entries(this.screens)
      screens.forEach((key) => {
        if (key[1] === true) {
          this.lastScreen = key[0]
        }
      });
    },
    handleGoBack() {
      if (this.lastScreen === null || this.currentScreen === this.lastScreen) {
        this.goBack()
      } else {        
        resetAll(this.screens)
        this.screens[this.lastScreen] = true
        this.currentScreen = this.lastScreen        

        console.log(this.lastScreen);
      }
    },
    showActiveScreen(screen) {
      if (screen === 'accountSetting') {
        this.showAccountSetting()
      } else if (screen === 'dashboardSetting') {
        this.showDashboardSetting()
      } else if (screen === 'userProfile') {
        this.showProfile(this.authUser.id)
      } else if (screen === 'editUser') {
        this.showProfile(this.authUser)
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../style/variable";

.active {
  color: $blue-dm;
}
</style>
