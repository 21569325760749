<template>
  <div class="container d-flex flex-wrap justify-content-center">
    <div class="col-10 col-lg-7">
      <!-- <div style="height:70vh" id="map"></div> -->
      <map-component></map-component>
    </div>
    <div class="col-12 col-lg-5">
      <div
        class="outer-container d-flex flex-column mx-auto ms-lg-auto my-md-4"
      >
        <p class="font-tiny">Showing information for all state.</p>
        <div class="table-container w-100">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th>LGAs</th>
                <th>Public Schools</th>
                <th>Private Schools</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(lg, index) in lgs"
                :key="index"
              >
                <td>{{ lg.name }}</td>
                <td>{{ lodash.random(1, 100) }}</td>
                <td>{{ lodash.random(1, 100) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapComponent from "@/helpers/utils/MapComponent.vue"
import stateNLG from "@/helpers/utils/state_local.json";
import lodash from "lodash";

export default {
  name: "StatisticsView",
  components: { MapComponent, },
  setup() {
    const state = Object.entries(stateNLG)[35];
    const lgs = Object.values(state[1].locals);

    return {
      lgs,
      lodash
    }
  }
}
</script>

<style scoped>
</style>
