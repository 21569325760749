<template>
  <div class="custom-card d-flex flex-column p-3 bg-white">
    <img :src="img" :alt="title" class="card-img" />
    <span :class="color" class="custom-bar"></span>
    <span class="fw-bold font-tiny">{{ title }}</span>
    <span class="font-tiny mt-2">{{ description }}</span>
  </div>
</template>

<script>
export default {
  props: ["img", "color", "title", "description"],
};
</script>

<style lang="scss">
@import "@/style/variable";

.custom-card {
  height: 15rem;
  width: 12rem;
  margin: 1rem;
  border-radius: $full-rem;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transition: opacity 0.2s ease-in-out;
  }
}

.custom-bar {
  display: inline-block;
  width: 3rem;
  height: 2px;
  margin: 2rem 0.2rem;
}

.card-img {
  width: 60px;
  height: 60px;
}
</style>