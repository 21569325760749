<template>
  <div class="px-5">
    <facilitator-learner-progress-header
      :firstItem="stages[0]"
      :secondItem="stages[1]"
      :firstPhase="phase.isCenterInfo"
      :secondPhase="phase.isLearnerInfo" />

    <p class="font-md fw-bold mt-2 mb-4">Learners Information</p>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <section 
        v-if="stageIsCentreInfo"
        class="first">      
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Center code </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter name of equipment"            
            />
          </div>

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              How many learners enrolled at the center regardless of the programme?
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Male"                
                />
              </div>
              <div class="col-6 ps-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Female"                
                />
              </div>
            </div>
          </div>
          
          <div class="d-flex align-items-center justify-content-center">
            <span
              class="p-2 d-flex align-items-center justify-content-center pointer-no-bg"              
            >
              <span class="material-icons-outlined dm-color me-1">
                add_circle_outline
              </span>
              <span class="font-sm fw-normal dm-color my-3"> Save and add new equipment </span>
            </span>
          </div>
        </div>
      </section>

      <section 
        v-if="stageIsLearnerInfo"
        class="second">        
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Name of learner
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="First name"            
            />
          </div>

          <div class="col-4 mt-5 px-3">
            <input
              type="text"
              class="form-control"
              placeholder="Middle name"            
            />
          </div>

          <div class="col-4 mt-5 px-3">
            <input
              type="text"
              class="form-control"
              placeholder="Last name"            
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Age </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter age of learner"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Employment status </label>
            <select class="form-select">
              <option value="">Select employment status</option>
              <option value="employed (full-time)">Employed (Full-time)</option>
              <option value="employed (part-time)">Employed (Part-time)</option>
              <option value="self-employed">Self Employed</option>
              <option value="unemployed">UnEmployed</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Distance to learning centre </label>
            <select class="form-select">
              <option value="">Select distance to centre</option>
              <option value="close to work place">Close to Work Place</option>
              <option value="(1-2km) from home">(1-2km) From Home</option>
              <option value="(3-4km) from home">(3-4Km) From Home</option>
              <option value="above 4Km">Above 4Km</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Type of programme </label>
            <select class="form-select">
              <option value="">Select type of programme</option>
              <option value="basic literacy">Basic Literacy</option>
              <option value="post literacy">Post Literacy</option>
              <option value="vocational education">Vocational Education</option>
              <option value="continuing education">Continuing Education</option>
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Programme enrolled </label>
            <select class="form-select">
              <option value="">Select programme enrolled</option>
              <option value="literacy by radio">Literacy By Radio</option>
              <option value="NFE girl-child education">NFE Girl-Child Education</option>
              <option value="NFE out-of-school (boys education)">NFE Out-Of-School (Boys Education)</option>
              <option value="integrated qur'anic education">Integrated Qur'anic Education</option>
              <option value="workers education">Workers Education</option>
              <option value="normadic adult literacy">Normadic Adult Literacy</option>
              <option value="women in purdah">Women In Purdah</option>
              <option value="women education">Women Education</option>
              <option value="e-learning">E-Learning</option>              
              <option value="prison education">Prison Education</option>
              <option value="NFE out-of-school (girls education)">NFE Out-Of-School (Girls Education)</option>
              <option value="traditional class (mixed)">Traditional Class (Mixed)</option>              
              <option value="Others">Others</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Language preference for learning </label>
            <select class="form-select">
              <option value="">Select preffered language</option>
              <option value="english">English</option>
              <option value="mother tongue">Mother tongue</option>
              <option value="other">Other</option>
            </select>
          </div>          

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Has learner been examined in an enrolled programme
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="hasEnrolled" value="yes" id="yesEnrolled">
                  <label class="form-check-label font-sm" for="yesEnrolled">
                    Yes
                  </label>
                </div>
              </div>
              <div class="col-6 ps-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="hasEnrolled" value="no" id="notEnrolled">
                  <label class="form-check-label font-sm" for="notEnrolled">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Has learner passed an enrolled programme?
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="hasPassedAnEnrolledProgramme" value="yes" id="yesPassed">
                  <label class="form-check-label font-sm" for="yesPassed">
                    Yes
                  </label>
                </div>
              </div>
              <div class="col-6 ps-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="hasPassedAnEnrolledProgramme" value="no" id="notPassed">
                  <label class="form-check-label font-sm" for="notPassed">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>    

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Was learner present at the time of this visit
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="learnerPresentAtVisit" value="yes" id="learnerPresent">
                  <label class="form-check-label font-sm" for="learnerPresent">
                    Yes
                  </label>
                </div>
              </div>
              <div class="col-6 ps-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="learnerPresentAtVisit" value="no" id="learnerNotPresent">
                  <label class="form-check-label font-sm" for="learnerNotPresent">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>                    
        </div>          
      </section>

      <div class="mb-3">
        <span v-if="stage !== 0" @click="gotoPreviousStage" class="d-flex align-items-center font-tiny dm-color fw-bold mx-2 float-start pointer-no-bg"> 
          <i class="bi bi-caret-left-fill"></i> 
          <span class="pb-1 ms-1"> Previous </span>
        </span>
        <span v-if="stage !== (stages.length - 1)" @click="gotoNextStage" class="d-flex align-items-center font-tiny dm-color fw-bold mx-2 float-end pointer-no-bg"> 
          <span class="pb-1 me-1"> Next </span>
          <i class="bi bi-caret-right-fill"></i> 
        </span>
      </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <input
        type="submit"
        class="custom-btn font-tiny fw-bold"
        value="Continue"
        @click="$emit('continue')"
      />
      <span class="font-sm fw-normal dm-color my-3 pointer-no-bg">
        Save and continue later
      </span>
    </div>
  </div>
</template>

<script>
import FacilitatorLearnerProgressHeader from '../../../../includes/FacilitatorLearnerProgressHeader.vue'
export default {  
  name: "LearnersInfo",
  components: { FacilitatorLearnerProgressHeader },
  data() {
    return {
      stages: ["Centre Info", "Learner Info"],
      stage: 0,
      currentStage: "Centre Info",
      phase: {
        isCenterInfo: true,
        isLearnerInfo: false
      }
    }
  },
  computed: {
    stageIsCentreInfo(){ return this.currentStage === this.stages[0] },
    stageIsLearnerInfo(){ return this.currentStage === this.stages[1] },    
  },
    methods: {
    gotoNextStage() {
      this.stage = ++this.stage
      if (this.stage === 1) this.phase.isLearnerInfo = true
      this.currentStage = this.stages[this.stage]
    },

    gotoPreviousStage() {
      this.stage = --this.stage
      if (this.stage === 0) this.phase.isLearnerInfo = false
      this.currentStage = this.stages[this.stage]
    }
  },
}
</script>

<style scoped>

</style>