<template>
  <div class="dashboard-sb col-md-3 position-fixed top-0 start-0 pl-4">
    <div class="d-flex flex-column mt-lg-4 ms-lg-5 ps-lg-4">
      <div class="d-flex flex-nowrap align-items-center text-decoration-none">
        <router-link to="/">
          <img :src="logoSrc" width="40" height="40" alt="" />
        </router-link>
        <span class="font-sm fw-bold ps-2">{{ state }} STATE EMIS</span>
      </div>
      <div
        v-if="displaySidebar && !isSupervisor"
        class="d-flex flex-column mt-5 ps-2"
      >
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              :class="[
                stages.identificationDone
                  ? 'material-icons'
                  : 'material-icons-outlined',
                activeScreen.schoolIdentification
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showIdentification')"
            class="progress-option"
            :class="{
              isDone: stages.identificationDone,
              isActive: activeScreen.schoolIdentification,
            }"
          >
            School Identification
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle"
              :class="[
                stages.characteristicsDone
                  ? 'material-icons'
                  : 'material-icons-outlined',
                activeScreen.characteristics
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showCharacteristics')"
            class="progress-option"
            :class="{
              isDone: stages.characteristicsDone,
              isActive: activeScreen.characteristics,
            }"
          >
            School Characteristics
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle"
              :class="[
                stages.enrolmentDone
                  ? 'material-icons'
                  : 'material-icons-outlined',
                activeScreen.enrolment
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showEnrolment')"
            class="progress-option"
            :class="{
              isDone: stages.enrolmentDone,
              isActive: activeScreen.enrolment,
            }"
          >
            Enrolment
          </span>
        </div>
        <div v-if="notPrivateSchool" class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle"
              :class="[
                stages.staffDone ? 'material-icons' : 'material-icons-outlined',
                activeScreen.staff
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showStaff')"
            class="progress-option"
            :class="{ isDone: stages.staffDone, isActive: activeScreen.staff }"
          >
            Staff
          </span>
        </div>
        <div v-if="notPrivateSchool" class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle"
              :class="[
                stages.classroomDone
                  ? 'material-icons'
                  : 'material-icons-outlined',
                activeScreen.classroom
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showClassroom')"
            class="progress-option"
            :class="{
              isDone: stages.classroomDone,
              isActive: activeScreen.classroom,
            }"
          >
            Classrooms
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle"
              :class="[
                stages.facilitiesDone
                  ? 'material-icons'
                  : 'material-icons-outlined',
                activeScreen.facilities
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showFacilities')"
            class="progress-option"
            :class="{
              isDone: stages.facilitiesDone,
              isActive: activeScreen.facilities,
            }"
          >
            Facilities
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle"
              :class="[
                stages.subjectDone
                  ? 'material-icons'
                  : 'material-icons-outlined',
                activeScreen.subject
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showSubject')"
            class="progress-option"
            :class="{
              isDone: stages.subjectDone,
              isActive: activeScreen.subject,
            }"
          >
            Number of students by subject
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle"
              :class="[
                stages.bookDone ? 'material-icons' : 'material-icons-outlined',
                activeScreen.book
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showBook')"
            class="progress-option"
            :class="{ isDone: stages.bookDone, isActive: activeScreen.book }"
          >
            Student/teacher book
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle"
              :class="[
                stages.qualificationDone
                  ? 'material-icons'
                  : 'material-icons-outlined',
                activeScreen.qualification
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showTeacher')"
            class="progress-option"
            :class="{
              isDone: stages.qualificationDone,
              isActive: activeScreen.qualification,
            }"
          >
            Teachers qualification
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle"
              :class="[
                stages.familyDone
                  ? 'material-icons'
                  : 'material-icons-outlined',
                activeScreen.family
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showFamily')"
            class="progress-option"
            :class="{
              isDone: stages.familyDone,
              isActive: activeScreen.family,
            }"
          >
            Family life
          </span>
        </div>
        <div class="progress-menu last grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle last-circle"
              :class="[
                stages.attestationDone
                  ? 'material-icons'
                  : 'material-icons-outlined',
                activeScreen.attestation
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showAttestation')"
            class="progress-option last-option"
            :class="{
              isDone: stages.attestationDone,
              isActive: activeScreen.attestation,
            }"
          >
            Attestation
          </span>
        </div>
      </div>
      <div v-else-if="isSupervisor" class="d-flex flex-column mt-5 ps-2">
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle"
              :class="[
                activeScreen.review
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
            <!-- stages.familyDone ? 'material-icons' : 'material-icons-outlined', -->
          </span>
          <span
            @click="$emit('showReview')"
            class="progress-option"
            :class="{
              isDone: stages.familyDone,
              isActive: activeScreen.family,
            }"
          >
            Review Questionnaire
          </span>
        </div>
        <div class="progress-menu last grey-accent">
          <span class="progress-circle-container nm-l-tiny">
            <span
              class="progress-circle last-circle"
              :class="[
                stages.attestationDone
                  ? 'material-icons'
                  : 'material-icons-outlined',
                activeScreen.attestation
                  ? 'material-icons'
                  : 'material-icons-outlined',
                'progress-circle',
              ]"
            >
              circle
            </span>
          </span>
          <span
            @click="$emit('showAttestation')"
            class="progress-option last-option"
            :class="{
              isDone: stages.attestationDone,
              isActive: activeScreen.attestation,
            }"
          >
            Attestation
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stateLogo from "@/assets/images/adamawa_logo.png";
import { mapGetters, useStore } from "vuex";
import { computed } from "vue";
import Roles from "@/authorities/roles";
import { emisconfig } from "../../../../../../app.config";

export default {
  name: "AscDataCollectionSidebar",
  props: ["displaySidebar", "profile", "account", "dashboard", "activeScreen"],
  setup() {
    const logoSrc = stateLogo;
    const state = emisconfig.stateName.toUpperCase();
    const store = useStore();
    const userMe = computed(() => store.getters.loginUser).value;
    const isSupervisor = userMe.role === Roles.supervisor;

    return {
      logoSrc,
      state,
      isActive: true,
      isSupervisor,
    };
  },
  computed: {
    ...mapGetters(["getSchoolType"]),
    stages() {
      return this.$store.state.questionnaireStages.stages;
    },
    notPrivateSchool() {
      return this.getSchoolType !== "private";
    },
  },
  methods: {},
};
</script>
