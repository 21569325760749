<template>
  <div>
    <section class="mb-5">
      <p class="fw-bold">New entrant in SSS1</p>
      <div class="d-flex mb-2">
        <div class="col-6"></div>
        <span class="col-3 text-center font-sm fw-normal">Male</span>
        <span class="col-3 text-center font-sm fw-normal">Female</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">Below 15 years</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="ss1Male.below15Year" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="ss1Female.below15Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">15 years</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="ss1Male.fifteen15Year" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="ss1Female.fifteen15Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">16 years</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="ss1Male.sixteen16Year" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="ss1Female.sixteen16Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">17 years</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="ss1Male.seventeen17Year" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="ss1Female.seventeen17Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex">
        <div class="col-6"><span class="font-sm fw-normal">Above 17 years</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="ss1Male.above17Year" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="ss1Female.above17Year" type="number" aria-label="female">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "NewEntrantsSSOne",
  setup() {
    const ss1Male = {
      below15Year: "",
      fifteen15Year: "",
      sixteen16Year: "",
      seventeen17Year: "",
      above17Year: "",
      gender: "MALE",
      schoolClass: "SS1"
    }
    const ss1Female = {
      below15Year: "",
      fifteen15Year: "",
      sixteen16Year: "",
      seventeen17Year: "",
      above17Year: "",
      gender: "FEMALE",
      schoolClass: "SS1"
    }

    return {
      ss1Male,
      ss1Female
    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitEnrolmentNewEntrantSenior']),

    async submitForm() {
      const body = {
        requestBody: [this.ss1Male, this.ss1Female],
        school: this.ownSchool.id
      }
      await this.submitEnrolmentNewEntrantSenior(body)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>