<template>
  <div id="footer-view" class="d-flex flex-column align-items-center mb-4 py-5">
    <span class="dm-color fw-bold font-sm mb-3">HOW IT WORKS</span>
    <h5 class="fw-bold mb-3">EMIS Integrates</h5>
    <div class="col-lg-8 d-flex justify-content-center flex-wrap mx-auto pt-4">
      <div
        class="
          col-10 col-md-6 col-lg-4
          d-flex
          flex-column
          align-items-center
          px-4
          mb-3 mb-md-0
        "
      >
        <img
          class="custom-icons"
          :src="collaboration"
          alt="Collaboration tools"
        />
        <span class="font-tiny fw-bold mb-2">Collaboration Tools</span>
        <span class="text-center font-tiny px-2">
          Provides amazing tools to collaborate at all levels.
        </span>
      </div>
      <div
        class="
          col-10 col-md-6 col-lg-4
          d-flex
          flex-column
          align-items-center
          px-4
          mb-3 mb-md-0
        "
      >
        <img
          class="custom-icons"
          :src="studentInfo"
          alt="Collaboration tools"
        />
        <span class="font-tiny fw-bold mb-2">Student Information System</span>
        <span class="text-center font-tiny px-2">
          Tools for students information collection and management
        </span>
      </div>
      <div
        class="
          col-10 col-md-6 col-lg-4
          d-flex
          flex-column
          align-items-center
          px-4
          mb-3 mb-md-0
        "
      >
        <img class="custom-icons" :src="management" alt="Collaboration tools" />
        <span class="font-tiny fw-bold mb-2">Learning Management System</span>
        <span class="text-center font-tiny px-2">
          Tools for managements to make informed decisions.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import collaboration from "@/assets/images/collaborate.png";
import studentInfo from "@/assets/images/student_info.png";
import management from "@/assets/images/management.png";

export default {
  name: "HowItWorksView",
  data() {
    return {
      collaboration,
      studentInfo,
      management,
    };
  },
};
</script>

<style lang="scss">
.custom-icons {
  width: 64px;
  height: 64px;
  margin-bottom: 1.5rem;
}
</style>