<template>
  <div>
    <section class="third">
      <div class="d-flex mb-4">
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-medium mb-3"> Number of rooms other than classrooms in this school </label>
          <div class="d-flex flex-wrap align-items-center">
            <div class="col-6 pe-3 font-sm fw-normal mb-2">
              School Level
            </div>
            <div class="col-6 ps-3 mb-2">
              <select class="form-select" required aria-label="Default select example"  @change="checkLevel($event)" v-model="nonClassrooms.level">
                <option value="">Select level</option>
                <option value="PRE_PRIMARY">Pre-Primary</option>
                <option value="PRIMARY">Primary</option>
                <option value="JUNIOR">Junior</option>
                <option value="SENIOR">Senior</option>
              </select>
            </div>
            <div class="col-6 pe-3 font-sm fw-normal mb-2">
              Staff room
            </div>
            <div class="col-6 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="nonClassrooms.staffRoom"
              />
            </div>
            <div class="col-6 pe-3 font-sm fw-normal mb-2">
              Office
            </div>
            <div class="col-6 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="nonClassrooms.office"
              />
            </div>
            <div class="col-6 pe-3 font-sm fw-normal mb-2">
              Library
            </div>
            <div class="col-6 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="nonClassrooms.library"
              />
            </div>
            <div class="col-6 pe-3 font-sm fw-normal mb-2">
              Laboratory
            </div>
            <div class="col-6 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="nonClassrooms.laboratory"
              />
            </div>
            <div class="col-6 pe-3 font-sm fw-normal mb-2">
              Store room
            </div>
            <div class="col-6 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="nonClassrooms.storeRoom"
              />
            </div>
            <div class="col-6 pe-3 font-sm fw-normal mb-2">
              Others
            </div>
            <div class="col-6 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="nonClassrooms.others"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center mb-4">
        <button v-if="isFetched" @click="submitForm" class="custom-btn font-tiny fw-bold">Update</button>
        <button v-else @click="submitForm" class="custom-btn font-tiny fw-bold">Save</button>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapMutations, useStore} from "vuex";
import {computed, ref, toRef} from "@vue/runtime-core";
import {reactive} from "vue";

export default {
  name: "NonClassroom",
  emits: ['gotoNext', 'reload'],
  props: ['selectedNonClassroom'],
  setup(props) {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const regInfo = computed(() => store.state.questionnaireService.nonClassroomInfo).value
    const selectedNonClassroomInfo = toRef(props, 'selectedNonClassroom')
    const nonClassroomObject = ref(null)

    let id = '', numberOfLab = '', numberOfStaffRoom = '', numberOfOffice = '', numberOfLibraries = '',
        numberOfstore = '', numberOfOther = '', schoolLevel = ''

    if (selectedNonClassroomInfo.value !== undefined) {
      nonClassroomObject.value = selectedNonClassroomInfo.value
    } else {
      nonClassroomObject.value = regInfo
    }

    const isFetched = nonClassroomObject.value !== null

    if (nonClassroomObject.value !== null) {
      ({
        id, numberOfLab, numberOfStaffRoom, numberOfOffice, numberOfLibraries,
        numberOfstore, numberOfOther, schoolLevel
      } = nonClassroomObject.value)
    }

    const nonClassrooms = reactive({
      level: schoolLevel,
      staffRoom: numberOfStaffRoom,
      office: numberOfOffice,
      library: numberOfLibraries,
      laboratory: numberOfLab,
      storeRoom: numberOfstore,
      others: numberOfOther,
    })

    return {
      id,
      nonClassrooms,
      schoolInfo,
      isFetched,
      selectedNonClassroomInfo,
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitNonClassroomRegistration', 'updateNonClassroomRegistration', 'fetchNonClassroomRegistration']),
    ...mapMutations(['setNonClassroomInfo', 'setClassroomUpdateLoading']),

    async submitForm() {
      const {schoolInfo, nonClassrooms} = this
      const body = {
        school: schoolInfo.id,
        schoolLevel: nonClassrooms.level,
        numberOfstore: nonClassrooms.storeRoom,
        numberOfLibraries: nonClassrooms.library,
        numberOfOffice: nonClassrooms.office,
        numberOfLab: nonClassrooms.laboratory,
        numberOfStaffRoom: nonClassrooms.staffRoom,
        numberOfOther: nonClassrooms.others,
      }

      if (this.isFetched) {
        const requestBody = {
          id: this.id,
          body: body
        }

        if (this.selectedNonClassroomInfo !== undefined) {
          this.setClassroomUpdateLoading(true)
        }

        await this.updateNonClassroomRegistration(requestBody)
          .then(() => {
            this.setClassroomUpdateLoading(false)
            if (this.responseCode === 200 || this.responseCode === 204) {
              this.setNonClassroomInfo(null)
              this.nonClassrooms.staffRoom = ""
              this.nonClassrooms.office = ""
              this.nonClassrooms.library = ""
              this.nonClassrooms.laboratory = ""
              this.nonClassrooms.storeRoom = ""
              this.nonClassrooms.others = ""

              if (this.selectedNonClassroomInfo !== undefined) {
                this.$notification.open({
                  type: 'success',
                  message: 'Update Successful',
                  description: 'Non-Classroom info updated!'
                });
                this.$emit('reload')
              } else {
                this.$emit('gotoNext')
              }              
            }
          })
      } else {
        await this.submitNonClassroomRegistration(body)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 201) {
              this.$emit('gotoNext')
            }
          })
      }
    },

    async checkLevel(event) {
      const requestBody = {
        id: this.schoolInfo.id,
        level: this.nonClassrooms.level
      }
      if (event.target.value !== "") {
        await this.fetchNonClassroomRegistration(requestBody)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 200) {
              this.$emit('reload')
            } else {
              this.setNonClassroomInfo(null)
              this.nonClassrooms.staffRoom = ""
              this.nonClassrooms.office = ""
              this.nonClassrooms.library = ""
              this.nonClassrooms.laboratory = ""
              this.nonClassrooms.storeRoom = ""
              this.nonClassrooms.others = ""
            }
          })
      }
    }
  }
}
</script>

<style scoped>

</style>