<template>
  <div>
    <section class="mb-5 mx-4 pt-4">
      <p class="font-sm fw-bold">Pre-primary and primary.</p>
      <p class="font-sm fw-bold">Number of core subject textbooks available to pupils provided by government.</p>
      <div class="d-flex mb-2">
        <div class="col-2"><p class="font-sm fw-medium">Text book</p></div>
        <span class="col me-1 text-center font-sm fw-medium">Pre-primary</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 1</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 2</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 3</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 4</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 5</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 6</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">English</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrePrimaryPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrimaryOnePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrimaryTwoPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrimaryThreePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrimaryFourPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrimaryFivePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0">
          <input class="form-control px-1 outline" v-model="englishPrimarySixPupil.availableBook" type="text" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Mathematics</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrePrimaryPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrimaryOnePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrimaryTwoPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrimaryThreePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrimaryFourPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrimaryFivePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0">
          <input class="form-control px-1 outline" v-model="mathPrimarySixPupil.availableBook" type="text" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Social studies</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrePrimaryPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimaryOnePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimaryTwoPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimaryThreePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimaryFourPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimaryFivePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimarySixPupil.availableBook" type="text" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Basic Science / technology</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrePrimaryPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrimaryOnePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrimaryTwoPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrimaryThreePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrimaryFourPupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrimaryFivePupil.availableBook" type="text" aria-label="male">
        </div>
        <div class="col px-0">
          <input class="form-control px-1 outline" v-model="basicSciencePrimarySixPupil.availableBook" type="text" aria-label="female">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "PupilsBookSection",
  emits: ['submissionDone'],
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const englishPrePrimaryPupil = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "PRE_PRIMARY",
      availableBook: ''
    }
    const englishPrimaryOnePupil = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "PRIMARY1",
      availableBook: ''
    }
    const englishPrimaryTwoPupil = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "PRIMARY2",
      availableBook: ''
    }
    const englishPrimaryThreePupil = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "PRIMARY3",
      availableBook: ''
    }
    const englishPrimaryFourPupil = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "PRIMARY4",
      availableBook: ''
    }
    const englishPrimaryFivePupil = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "PRIMARY5",
      availableBook: ''
    }
    const englishPrimarySixPupil = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "PRIMARY6",
      availableBook: ''
    }

    const mathPrePrimaryPupil = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "PRE_PRIMARY",
      availableBook: ''
    }
    const mathPrimaryOnePupil = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY1",
      availableBook: ''
    }
    const mathPrimaryTwoPupil = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY2",
      availableBook: ''
    }
    const mathPrimaryThreePupil = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY3",
      availableBook: ''
    }
    const mathPrimaryFourPupil = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY4",
      availableBook: ''
    }
    const mathPrimaryFivePupil = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY5",
      availableBook: ''
    }
    const mathPrimarySixPupil = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY6",
      availableBook: ''
    }

    const socialStudiesPrePrimaryPupil = {
      user: "STUDENT_PUPIL",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRE_PRIMARY",
      availableBook: ''
    }
    const socialStudiesPrimaryOnePupil = {
      user: "STUDENT_PUPIL",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY1",
      availableBook: ''
    }
    const socialStudiesPrimaryTwoPupil = {
      user: "STUDENT_PUPIL",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY2",
      availableBook: ''
    }
    const socialStudiesPrimaryThreePupil = {
      user: "STUDENT_PUPIL",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY3",
      availableBook: ''
    }
    const socialStudiesPrimaryFourPupil = {
      user: "STUDENT_PUPIL",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY4",
      availableBook: ''
    }
    const socialStudiesPrimaryFivePupil = {
      user: "STUDENT_PUPIL",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY5",
      availableBook: ''
    }
    const socialStudiesPrimarySixPupil = {
      user: "STUDENT_PUPIL",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY6",
      availableBook: ''
    }

    const basicSciencePrePrimaryPupil = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_SCIENCE_TECHNOLOGY",
      schoolClass: "PRE_PRIMARY",
      availableBook: ''
    }
    const basicSciencePrimaryOnePupil = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_SCIENCE_TECHNOLOGY",
      schoolClass: "PRIMARY1",
      availableBook: ''
    }
    const basicSciencePrimaryTwoPupil = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_SCIENCE_TECHNOLOGY",
      schoolClass: "PRIMARY2",
      availableBook: ''
    }
    const basicSciencePrimaryThreePupil = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_SCIENCE_TECHNOLOGY",
      schoolClass: "PRIMARY3",
      availableBook: ''
    }
    const basicSciencePrimaryFourPupil = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_SCIENCE_TECHNOLOGY",
      schoolClass: "PRIMARY4",
      availableBook: ''
    }
    const basicSciencePrimaryFivePupil = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_SCIENCE_TECHNOLOGY",
      schoolClass: "PRIMARY5",
      availableBook: ''
    }
    const basicSciencePrimarySixPupil = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_SCIENCE_TECHNOLOGY",
      schoolClass: "PRIMARY6",
      availableBook: ''
    }

    return {
      englishPrePrimaryPupil,
      englishPrimaryOnePupil,
      englishPrimaryTwoPupil,
      englishPrimaryThreePupil,
      englishPrimaryFourPupil,
      englishPrimaryFivePupil,
      englishPrimarySixPupil,

      mathPrePrimaryPupil,
      mathPrimaryOnePupil,
      mathPrimaryTwoPupil,
      mathPrimaryThreePupil,
      mathPrimaryFourPupil,
      mathPrimaryFivePupil,
      mathPrimarySixPupil,

      socialStudiesPrePrimaryPupil,
      socialStudiesPrimaryOnePupil,
      socialStudiesPrimaryTwoPupil,
      socialStudiesPrimaryThreePupil,
      socialStudiesPrimaryFourPupil,
      socialStudiesPrimaryFivePupil,
      socialStudiesPrimarySixPupil,

      basicSciencePrePrimaryPupil,
      basicSciencePrimaryOnePupil,
      basicSciencePrimaryTwoPupil,
      basicSciencePrimaryThreePupil,
      basicSciencePrimaryFourPupil,
      basicSciencePrimaryFivePupil,
      basicSciencePrimarySixPupil,

      schoolInfo,
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitStudentTeacherBook']),

    submitForm() {
      const subjects = [
        this.englishPrePrimaryPupil,
        this.englishPrimaryOnePupil,
        this.englishPrimaryTwoPupil,
        this.englishPrimaryThreePupil,
        this.englishPrimaryFourPupil,
        this.englishPrimaryFivePupil,
        this.englishPrimarySixPupil,

        this.mathPrePrimaryPupil,
        this.mathPrimaryOnePupil,
        this.mathPrimaryTwoPupil,
        this.mathPrimaryThreePupil,
        this.mathPrimaryFourPupil,
        this.mathPrimaryFivePupil,
        this.mathPrimarySixPupil,

        this.socialStudiesPrePrimaryPupil,
        this.socialStudiesPrimaryOnePupil,
        this.socialStudiesPrimaryTwoPupil,
        this.socialStudiesPrimaryThreePupil,
        this.socialStudiesPrimaryFourPupil,
        this.socialStudiesPrimaryFivePupil,
        this.socialStudiesPrimarySixPupil,

        this.basicSciencePrePrimaryPupil,
        this.basicSciencePrimaryOnePupil,
        this.basicSciencePrimaryTwoPupil,
        this.basicSciencePrimaryThreePupil,
        this.basicSciencePrimaryFourPupil,
        this.basicSciencePrimaryFivePupil,
        this.basicSciencePrimarySixPupil,
      ]
      this.submitStudentTeacherBook({id: this.schoolInfo.id, body: subjects})
        .then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          })
          // if (this.responseCode === 201) {
          //   this.$emit('submissionDone')
          // }
        })
    }
  }
}
</script>

<style scoped>

</style>