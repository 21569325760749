<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Number of classrooms by school type and LGA</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-tiny" aria-label="Users table">
        <thead>
        <tr>
          <th class="align-middle lg-cell py-3 ps-4" rowspan="2">
            <div class="d-flex align-items-baseline">
              <span class="font-sm fw-bold align-text-bottom">LGA</span>
            </div>
          </th>
          <th class="align-middle py-3" colspan="6">
            <div class="d-flex flex-nowrap justify-content-center">
              <span class="font-sm fw-bold">No of Usable Classroom</span>
            </div>
          </th>
          <th class="align-middle py-3" rowspan="2">
            <div class="d-flex flex-nowrap justify-content-center">
              <span class="font-sm fw-bold">Number Unusable *(Public Only)</span>
            </div>
          </th>
          <th class="align-middle py-3" rowspan="2">
            <div class="d-flex flex-nowrap justify-content-center">
              <span class="font-sm fw-bold">Number Under Construction *(Public Only)</span>
            </div>
          </th>
        </tr>
        <tr>
          <th class="align-middle py-3 cell-size" scope="col">Primary Public</th>
          <th class="align-middle py-3 cell-size" scope="col">Junior Public</th>
          <th class="align-middle py-3 cell-size" scope="col">Science & Tech Public</th>
          <th class="align-middle py-3 cell-size" scope="col">Senior Public</th>
          <th class="align-middle py-3 cell-size" scope="col">Private All levels</th>
          <th class="align-middle py-3 cell-size" scope="col">TOTAL</th>
        </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg"
            v-for="(data, index) in tableData"
            :key="index"
          >
            <td class="py-3 ps-4 lg-cell">{{ data.lga }}</td>
            <td class="py-3 cell-size">{{ data.no_of_usable_classrooms_primary_public }}</td>
            <td class="py-3 cell-size">{{ data.no_of_usable_classrooms_junior_public }}</td>
            <td class="py-3 cell-size">{{ data.no_of_usable_classrooms_science_and_tech_public }}</td>
            <td class="py-3 cell-size">{{ data.no_of_usable_classrooms_senior_public }}</td>
            <td class="py-3 cell-size">{{ data.no_of_usable_classrooms_private_all_levels }}</td>
            <td class="py-3 cell-size">{{ data.no_of_usable_classrooms_total }}</td>
            <td class="py-3 cell-size">{{ data.number_unusabl_public_only }}</td>
            <td class="py-3 cell-size">{{ data.number_under_construction_public_only }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="9" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "NumberOfClassroomsBySchoolTypeLGA",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 36, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })

    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
.lg-cell {
  width: 20%;
}
.cell-size {
  width: 10%;
}
</style>