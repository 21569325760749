<template>
  <div class="dashboard-sb col-md-3 position-fixed top-0 start-0 pl-4">
    <div class="d-flex flex-column mt-lg-4 ms-lg-5 ps-lg-4">
      <div class="d-flex flex-nowrap align-items-center text-decoration-none">
        <router-link to="/">
          <img :src="logoSrc" width="40" height="40" alt="" />
        </router-link>
        <span class="font-sm fw-bold ps-2">{{ state }} STATE EMIS</span>
      </div>
      <div class="d-flex flex-column mt-5 ps-2">
        <div class="progress-menu grey-accent">
          <span
            class="progress-circle-container nm-l-tiny"
            :class="[
              stages.centerIdentification
                ? 'material-icons'
                : 'material-icons-outlined',
              screens.identification
                ? 'material-icons'
                : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle"> circle </span>
          </span>
          <span
            @click="$emit('showCenterIdentification')"
            class="progress-option"
            :class="{ isDone: false, isActive: true }"
          >
            Learning center identification
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span
            class="progress-circle-container nm-l-tiny"
            :class="[
              stages.facilitiesAndEquipment
                ? 'material-icons'
                : 'material-icons-outlined',
              screens.facilities ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle"> circle </span>
          </span>
          <span
            @click="$emit('showFacilitiesAndEquipment')"
            class="progress-option"
            :class="{ isDone: false, isActive: false }"
          >
            Center facilities and equipment
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span
            class="progress-circle-container nm-l-tiny"
            :class="[
              stages.facilitatorsInfo
                ? 'material-icons'
                : 'material-icons-outlined',
              screens.facilitators
                ? 'material-icons'
                : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle"> circle </span>
          </span>
          <span
            @click="$emit('showFacilitatorsInfo')"
            class="progress-option"
            :class="{ isDone: false, isActive: false }"
          >
            Facilitators information
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span
            class="progress-circle-container nm-l-tiny"
            :class="[
              stages.learnersInfo
                ? 'material-icons'
                : 'material-icons-outlined',
              screens.learners ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle"> circle </span>
          </span>
          <span
            @click="$emit('showLearnersInfo')"
            class="progress-option"
            :class="{ isDone: false, isActive: false }"
          >
            Learners information
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span
            class="progress-circle-container nm-l-tiny"
            :class="[
              stages.learningMaterials
                ? 'material-icons'
                : 'material-icons-outlined',
              screens.learningMaterials
                ? 'material-icons'
                : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle"> circle </span>
          </span>
          <span
            @click="$emit('showLearningMaterials')"
            class="progress-option"
            :class="{ isDone: false, isActive: false }"
          >
            Learning materials available for different programs
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span
            class="progress-circle-container nm-l-tiny"
            :class="[
              stages.instructionalMaterials
                ? 'material-icons'
                : 'material-icons-outlined',
              screens.instructionalMaterials
                ? 'material-icons'
                : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle"> circle </span>
          </span>
          <span
            @click="$emit('showInstructionalMaterials')"
            class="progress-option"
            :class="{ isDone: false, isActive: false }"
          >
            Instructional materials available for different programs
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span
            class="progress-circle-container nm-l-tiny"
            :class="[
              stages.vocation ? 'material-icons' : 'material-icons-outlined',
              screens.vocation ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle"> circle </span>
          </span>
          <span
            @click="$emit('showVocationProgram')"
            class="progress-option"
            :class="{ isDone: false, isActive: false }"
          >
            Vocation education programs
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span
            class="progress-circle-container nm-l-tiny"
            :class="[
              stages.challenges ? 'material-icons' : 'material-icons-outlined',
              screens.challenges ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle"> circle </span>
          </span>
          <span
            @click="$emit('showChallenges')"
            class="progress-option last-option"
            :class="{ isDone: false, isActive: false }"
          >
            Challenges at center
          </span>
        </div>
        <div class="progress-menu last grey-accent">
          <span
            class="progress-circle-container nm-l-tiny"
            :class="[
              stages.suggestions ? 'material-icons' : 'material-icons-outlined',
              screens.suggestions
                ? 'material-icons'
                : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle last-circle"> circle </span>
          </span>
          <span
            @click="$emit('showSuggestion')"
            class="progress-option last-option"
            :class="{ isDone: false, isActive: false }"
          >
            Suggestions for improvement
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stateLogo from "@/assets/images/adamawa_logo.png";
import { mapGetters } from "vuex";
import { emisconfig } from "../../../../../../app.config";

export default {
  name: "NMECSidebar",
  props: ["profile", "account", "dashboard", "screens"],
  setup() {
    const logoSrc = stateLogo;
    const state = emisconfig.stateName.toUpperCase();

    return {
      logoSrc,
      state,
      isActive: true,
    };
  },
  computed: {
    ...mapGetters(["getSchoolType"]),
    stages() {
      return this.$store.state.nmec.stages;
    },
    notPrivateSchool() {
      return this.getSchoolType !== "private";
    },
  },
  methods: {},
};
</script>
