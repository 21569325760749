<template>
  <div>

    <junior-birth-cert-section
        v-if="activeStage === juniorStages[0]"
        @submissionDone="updateActiveStagesJunior"
    />

    <new-entrants-j-s-s-one
        v-if="activeStage === juniorStages[1]"
        @submissionDone="updateActiveStagesJunior"
    />

    <junior-enrolment-by-streams
        v-if="activeStage === juniorStages[2]"
        @submissionDone="updateActiveStagesJunior"
    />

    <junior-enrolment-by-age
        v-if="activeStage === juniorStages[3]"
        @submissionDone="updateActiveStagesJunior"
    />

    <student-flow-by-class
        school-class="JSS1"
        v-if="activeStage === juniorStages[4]"
        @submissionDone="updateActiveStagesJunior"
    />

    <student-flow-by-class
        school-class="JSS2"
        v-if="activeStage === juniorStages[5]"
        @submissionDone="updateActiveStagesJunior"
    />

    <student-flow-by-class
        school-class="JSS3"
        v-if="activeStage === juniorStages[6]"
        @submissionDone="updateActiveStagesJunior"
    />

    <persons-with-special-needs-by-class
        school-class="JSS1"
        v-if="activeStage === juniorStages[7]"
        @submissionDone="updateActiveStagesJunior"
    />

    <persons-with-special-needs-by-class
        school-class="JSS2"
        v-if="activeStage === juniorStages[8]"
        @submissionDone="updateActiveStagesJunior"
    />

    <persons-with-special-needs-by-class
        school-class="JSS3"
        v-if="activeStage === juniorStages[9]"
        @submissionDone="gotoNextSection"
    />

  </div>
</template>

<script>
import JuniorBirthCertSection
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/junior/JuniorBirthCertSection";
import NewEntrantsJSSOne
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/junior/NewEntrantsJSSOne";
import JuniorEnrolmentByStreams
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/junior/JuniorEnrolmentByStreams";
import JuniorEnrolmentByAge
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/junior/JuniorEnrolmentByAge";
import StudentFlowByClass
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/StudentFlowByClass";
import PersonsWithSpecialNeedsByClass
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/PersonsWithSpecialNeedsByClass";
import {mapMutations, useStore} from "vuex";
import {computed, onMounted} from "@vue/runtime-core";

export default {
  name: "JuniorSecSchool",
  components: {
    PersonsWithSpecialNeedsByClass,
    StudentFlowByClass,
    JuniorEnrolmentByAge,
    JuniorEnrolmentByStreams,
    NewEntrantsJSSOne,
    JuniorBirthCertSection
  },
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const levels = schoolInfo.levelsOffered.map(item => item.toLowerCase())
    const activeStageState = computed(() => store.state.questionnaireService.activeEnrolmentStage)
    const hasSSS = levels.includes("senior")
    const stages = [
        "birth cert junior", "new entrant junior", "enrolment by stream junior", "enrolment by age junior",
        "student flow for jss 1", "student flow for jss 2", "student flow for jss 3",
        "special needs jss 1", "special needs jss 2", "special needs jss 3"
    ]
    onMounted(() => {
      if (activeStageState.value === null) {
        console.log("I'm being called!");
        store.commit('setActiveEnrolmentStage', stages[0])
      }
    })
    return {
      juniorStages: stages,
      hasSSS
    }
  },
  computed: {
    activeStage() {
      return this.$store.state.questionnaireService.activeEnrolmentStage
    }
  },
  methods: {
    ...mapMutations(['setActiveEnrolmentStage']),
    updateActiveStagesJunior() {
      let newIndex = this.juniorStages.indexOf(this.activeStage) + 1
      let newStage = this.juniorStages[newIndex]
      this.setActiveEnrolmentStage(newStage)
    },

    gotoNextSection() {
      if (this.hasSSS) {
        this.setActiveEnrolmentStage("birth cert senior")
      }
    }
  }
}
</script>

<style scoped>

</style>