<template>
  <div class="d-flex flex-column">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div class="d-flex justify-content-between align-items-center my-4">
      <span class="font-normal fw-bold">All Classrooms</span>
    </div>

    <div class="d-flex radius-half bg-white shadow-sm p-3 mb-3">
      <div class="col-6 d-flex flex-column pe-3">
        <span class="font-tiny fw-bold mb-2">What are you looking for?</span>
        <div class="custom-input-group bg-accent">
          <span class="p-1"><i class="bi bi-search grey-shade"></i></span>
          <input
              type="text"
              class="ph-sm no-outline-input font-tiny fw-bolder"
              placeholder="Search"
              v-model="searchField"
          />
        </div>
      </div>
      <div class="col-3 d-flex flex-column pe-2">
        <span class="font-tiny fw-bold mb-2">Filter</span>
        <div>
          <select
              class="form-select text-dark"
              aria-label="Default select example"
              v-model="searchFilter"
          >
            <option class="bg-white grey-shade" selected>All</option>
            <option class="bg-white" value="description">Description</option>
            <option class="bg-white" value="condition">Condition</option>
            <option class="bg-white" value="level">Level</option>
            <option class="bg-white" value="length">Length</option>
            <option class="bg-white" value="width">Width</option>
            <option class="bg-white" value="yearOfConstruction">Year of construction</option>
          </select>
        </div>
      </div>
      <div class="col-3 d-flex flex-column ps-2">
        <span class="font-tiny fw-bold mb-2">Sort</span>
        <div>
          <select
              class="form-select text-dark"
              aria-label="Default select example"
              v-model="sortOption"
          >
            <option class="bg-white grey-shade" selected>All</option>
            <option class="bg-white" value="description">Description</option>
            <option class="bg-white" value="condition">Condition</option>
            <option class="bg-white" value="level">Level</option>
            <option class="bg-white" value="length">Length</option>
            <option class="bg-white" value="width">Width</option>
            <option class="bg-white" value="yearOfConstruction">Year of construction</option>
          </select>
        </div>
      </div>
    </div>

    <div class="bg-white radius-full shadow-sm mb-5">
      <table class="table table-hover font-sm" aria-label="classroom table">
        <thead>
        <tr>
          <th class="align-middle big-cell py-3 ps-4" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Description</span>
              <div class="vertical-icons ms-2">
                <i @click="descriptionArrowUpClicked" class="bi bi-caret-up-fill font-tiny mt-1"></i>
                <i @click="descriptionArrowDownClicked" class="bi bi-caret-down-fill font-tiny nm-t-small"></i>
              </div>
            </div>
          </th>
          <th class="align-middle medium-cell py-3" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Condition</span>
              <div class="vertical-icons ms-2">
                <i @click="conditionArrowUpClicked" class="bi bi-caret-up-fill font-tiny mt-1"></i>
                <i @click="conditionArrowDownClicked" class="bi bi-caret-down-fill font-tiny nm-t-small"></i>
              </div>
            </div>
          </th>
          <th class="align-middle other-cells py-3" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>School Level</span>
              <div class="vertical-icons ms-2">
                <i @click="levelArrowUpClicked" class="bi bi-caret-up-fill font-tiny mt-1"></i>
                <i @click="levelArrowDownClicked" class="bi bi-caret-down-fill font-tiny nm-t-small"></i>
              </div>
            </div>
          </th>
          <th class="align-middle other-cells py-3" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Year of Construction</span>
              <div class="vertical-icons ms-2">
                <i @click="yearOfConstructionArrowUpClicked" class="bi bi-caret-up-fill font-tiny mt-1"></i>
                <i @click="yearOfConstructionArrowDownClicked" class="bi bi-caret-down-fill font-tiny nm-t-small"></i>
              </div>
            </div>
          </th>
          <th class="align-middle other-cells py-3" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Length(m)</span>
              <div class="vertical-icons ms-2">
                <i @click="lengthArrowUpClicked" class="bi bi-caret-up-fill font-tiny mt-1"></i>
                <i @click="lengthArrowDownClicked" class="bi bi-caret-down-fill font-tiny nm-t-small"></i>
              </div>
            </div>
          </th>
          <th class="align-middle other-cells py-3" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Width(m)</span>
              <div class="vertical-icons ms-2">
                <i @click="widthArrowUpClicked" class="bi bi-caret-up-fill font-tiny mt-1"></i>
                <i @click="widthArrowDownClicked" class="bi bi-caret-down-fill font-tiny nm-t-small"></i>
              </div>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            class="pointer-no-bg"
            v-for="(classroom, index) in filteredClassrooms"
            :key="index"
        >
          <td class="align-middle py-3 ps-4">{{ classroom.description }}</td>
          <td class="py-3">{{ classroom.condition }}</td>
          <td class="py-3">{{ classroom.schoolLevel }}</td>
          <td class="py-3">{{ classroom.yearOfConstruction }}</td>
          <td class="py-3">{{ classroom.lenInMtr }}</td>
          <td class="py-3">{{ classroom.widthInMtr }}</td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end align-items-center font-sm pb-3 px-4">
        <span>Rows per page:
          <select v-model="rowCount" v-on:change="onNumRowChanged($event)" class="outline-clear" id="row">
            <option value="12">12</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>

        <span class="font-sm ms-3">1 to {{rowCount}} of {{totalRecord}}
          <span
              @click="fetchPrev"
              class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_back_ios
          </span>
          <span
              @click="fetchNext"
              class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_forward_ios
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader";
import {mapActions, mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import defineAbilityFor from "@/authorities";
import {onMounted} from "vue";
import {resetAll} from "@/helpers/utils/helper_functions";

export default {
  name: "ClassroomsTable",
  components: { ClipLoader },
  props: ['id'],
  setup(props) {
    const store = useStore();
    const userMe = computed(() => store.getters.loginUser).value;
    const ability = defineAbilityFor(userMe);

    onMounted(() => {
      store.dispatch('fetchSchoolClassrooms', {id: props.id, nextPage: 0})
    })

    return {
      ability,
      schoolId: props.id
    }
  },
  data() {
    return {
      searchField: "",
      searchFilter: "",
      sortOption: "",
      arrowsState: {
        descriptionArrowUp: false,
        descriptionArrowDown: false,
        conditionArrowUp: false,
        conditionArrowDown: false,
        levelArrowUp: false,
        levelArrowDown: false,
        yearOfConstructionArrowUp: false,
        yearOfConstructionArrowDown: false,
        lengthArrowUp: false,
        lengthArrowDown: false,
        widthArrowUp: false,
        widthArrowDown: false,
      }
    };
  },
  computed: {
    loading() {
      return this.$store.state.school.loading
    },

    rowCount() {
      return this.$store.state.school.numberOfRows
    },

    classroomData() {
      return this.$store.state.school.schoolClassrooms
    },

    classrooms() {
      if (this.classroomData !== null) {
        return this.classroomData.content
      }
      return []
    },

    totalRecord() {
      if (this.classroomData !== null) {
        return this.classroomData.totalElements
      }
      return 0
    },

    currentPage() {
      if (this.classroomData !== null) {
        return this.classroomData.pageNumber + 1
      }
      return 0
    },

    previousPage() {
      if (this.classroomData !== null) {
        return this.classroomData.pageNumber - 1
      }
      return 0
    },

    filteredClassrooms() {
      const { classrooms, searchField, sortOption, sortClassrooms } = this
      const {
        descriptionArrowUp, descriptionArrowDown, conditionArrowUp, conditionArrowDown,
        levelArrowUp, levelArrowDown, yearOfConstructionArrowUp, yearOfConstructionArrowDown,
        lengthArrowUp, lengthArrowDown, widthArrowUp, widthArrowDown,
      } = this.arrowsState

      if (descriptionArrowUp) {
        sortClassrooms("description")
      }
      if (descriptionArrowDown) {
        sortClassrooms("description", "DSC")
      }
      if (conditionArrowUp) {
        sortClassrooms("condition")
      }
      if (conditionArrowDown) {
        sortClassrooms("condition", "DSC")
      }
      if (levelArrowUp) {
        sortClassrooms("level")
      }
      if (levelArrowDown) {
        sortClassrooms("level", "DSC")
      }
      if (yearOfConstructionArrowUp) {
        sortClassrooms("yearOfConstruction")
      }
      if (yearOfConstructionArrowDown) {
        sortClassrooms("yearOfConstruction", "DSC")
      }
      if (lengthArrowUp) {
        sortClassrooms("lengthInMtr")
      }
      if (lengthArrowDown) {
        sortClassrooms("lengthInMtr", "DSC")
      }
      if (widthArrowUp) {
        sortClassrooms("widthInMtr")
      }
      if (widthArrowDown) {
        sortClassrooms("widthInMtr", "DSC")
      }

      sortClassrooms(sortOption)
      // return []

      if (classrooms !== undefined && classrooms.length > 0) {
        return classrooms.filter(classroom => this.filterClassroomsBy(classroom).toLowerCase().includes(searchField.toLowerCase()))
      } else
        return []
    },
  },
  methods: {
    ...mapActions(['fetchSchoolClassrooms']),
    ...mapMutations(['setNumRowsClassrooms']),

    filterClassroomsBy(classroom) {
      const {searchFilter} = this
      if (searchFilter === "" || searchFilter === "description") {
        return classroom.description
      }
      else if (searchFilter === "condition") {
        return classroom.condition
      }
      else if (searchFilter === "level") {
        return classroom.schoolLevel
      }
      else if (searchFilter === "yearOfConstruction") {
        return classroom.yearOfConstruction
      }
      else if (searchFilter === "length") {
        return classroom.lenInMtr
      } else {
        return classroom.widthInMtr
      }
    },

    sortClassrooms(sortFilter, order = "ASC") {
      const {Classrooms} = this
      if (sortFilter) {
        switch (sortFilter) {
          case "description":
            return Classrooms.sort((classroom1, classroom2) => {
              if (order === "ASC") {
                return (classroom1.description > classroom2.description) ? 1 : ((classroom2.description > classroom1.description) ? -1 : 0)
              }
              return (classroom2.description > classroom1.description) ? 1 : ((classroom1.description > classroom2.description) ? -1 : 0)
            })

          case "condition":
            return Classrooms.sort((classroom1, classroom2) => {
              if (order === "ASC") {
                return (classroom1.condition > classroom2.condition) ? 1 : ((classroom2.condition > classroom1.condition) ? -1 : 0)
              }
              return (classroom2.condition > classroom1.condition) ? 1 : ((classroom1.condition > classroom2.condition) ? -1 : 0)
            })

          case "level":
            return Classrooms.sort((classroom1, classroom2) => {
              if (order === "ASC") {
                return (classroom1.schoolLevel > classroom2.schoolLevel) ? 1 : ((classroom2.schoolLevel > classroom1.schoolLevel) ? -1 : 0)
              }
              return (classroom2.schoolLevel > classroom1.schoolLevel) ? 1 : ((classroom1.schoolLevel > classroom2.schoolLevel) ? -1 : 0)
            })

          case "yearOfConstruction":
            return Classrooms.sort((classroom1, classroom2) => {
              if (order === "ASC") {
                return (classroom1.yearOfConstruction > classroom2.yearOfConstruction) ? 1 : ((classroom2.yearOfConstruction > classroom1.yearOfConstruction) ? -1 : 0)
              }
              return (classroom2.yearOfConstruction > classroom1.yearOfConstruction) ? 1 : ((classroom1.yearOfConstruction > classroom2.yearOfConstruction) ? -1 : 0)
            })

          case "length":
            return Classrooms.sort((classroom1, classroom2) => {
              if (order === "ASC") {
                return (classroom1.lenInMtr > classroom2.lenInMtr) ? 1 : ((classroom2.lenInMtr > classroom1.lenInMtr) ? -1 : 0)
              }
              return (classroom2.lenInMtr > classroom1.lenInMtr) ? 1 : ((classroom1.lenInMtr > classroom2.lenInMtr) ? -1 : 0)
            })

          case "width":
            return Classrooms.sort((classroom1, classroom2) => {
              if (order === "ASC") {
                return (classroom1.widthInMtr > classroom2.widthInMtr) ? 1 : ((classroom2.widthInMtr > classroom1.widthInMtr) ? -1 : 0)
              }
              return (classroom2.widthInMtr > classroom1.widthInMtr) ? 1 : ((classroom1.widthInMtr > classroom2.widthInMtr) ? -1 : 0)
            })
        }
      }
    },

    descriptionArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.descriptionArrowUp = true
    },

    descriptionArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.descriptionArrowDown = true
    },

    conditionArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.conditionArrowUp = true
    },

    conditionArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.conditionArrowDown = true
    },

    levelArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.levelArrowUp = true
    },

    levelArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.levelArrowDown = true
    },

    yearOfConstructionArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.yearOfConstructionArrowUp = true
    },

    yearOfConstructionArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.yearOfConstructionArrowDown = true
    },

    lengthArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.lengthArrowUp = true
    },

    lengthArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.lengthArrowDown = true
    },

    widthArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.widthArrowUp = true
    },

    widthArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.widthArrowDown = true
    },

    onNumRowChanged(event) {
      const number = event.target.value
      console.log("I'm now going to fetch " + number + " users.");
      this.setNumRowsClassrooms(number)
      this.fetchSchoolClassrooms({id: this.schoolId})
    },

    fetchNext() {
      if (this.classroomData.last === false) {
        this.fetchSchoolClassrooms({id: this.schoolId, nextPage: this.currentPage})
      }
    },

    fetchPrev() {
      if (this.classroomData.first === false) {
        this.fetchSchoolClassrooms({id: this.schoolId, nextPage: this.previousPage})
      }
    },
  },
};
</script>

<style scoped>
.big-cell {
  width: 28%;
}
.medium-cell {
  width: 24%;
}
.other-cells {
  width: 12%
}
</style>