<script>
import { resetAll } from "@/helpers/utils/helper_functions";

export default {
  name: "dataCollectionMixin",
  data() {
    const screen = {
      profile: false,
      draftTable: false,
      introScreen: true,
      schoolIdentification: false,
      characteristics: false,
      enrolment: false,
      staff: false,
      classroom: false,
      facilities: false,
      subject: false,
      book: false,
      qualification: false,
      family: false,
      attestation: false,
      review: false,
      submittedQuestionaire: true,
      submissionReview: false,

    }
    return {
      screen
    }
  },
  computed: {
    notPrivateSchool() { 
      return this.$store.getters.getSchoolType !== "private"
    }, 
  },
  methods: {
    //
    // THE FOLLOWING METHODS HELPS IN NAVIGATING THE DIFFERENT SCREENS
    //

    showSchoolIdentification() {
      resetAll(this.screen);
      this.screen.schoolIdentification = true;
    },

    showCharacteristics() {
      resetAll(this.screen);
      this.screen.characteristics = true;
    },

    showEnrolment() {
      resetAll(this.screen)
      this.screen.enrolment = true
    },

    showStaff() {
      resetAll(this.screen);
      if (this.notPrivateSchool) {
        this.screen.staff = true;
      } else {
        this.showClassroom()
      }
    },

    showClassroom() {
      resetAll(this.screen);
      if (this.notPrivateSchool) {
        this.screen.classroom = true;
      } else {
        this.showFacilities()
      }
    },

    showFacilities() {
      resetAll(this.screen);
      this.screen.facilities = true;
    },

    showSubject() {
      resetAll(this.screen)
      this.screen.subject = true
    },

    showBook() {
      resetAll(this.screen);
      this.screen.book = true;
    },

    showQualification() {
      resetAll(this.screen);
      this.screen.qualification = true;
    },

    showFamily() {
      resetAll(this.screen)
      this.screen.family = true
    },

    showAttestation() {
      resetAll(this.screen)
      this.screen.attestation = true
    },

    showReview() {
      resetAll(this.screen)
      this.screen.review = true
    },

    showSubmittedQuestionaire() {
      resetAll(this.screen)
      this.screen.submittedQuestionaire = true
    },

  }
}
</script>
