<template>
  <div class="bg-accent pb-5">
    <div class="container">
      <home-nav-bar></home-nav-bar>
      <div
        class="
          col-11 col-md-8 col-lg-4
          mx-auto
          d-flex
          flex-column
          align-item-center
          my-5
        "
      >
        <p class="fw-bold font-sm text-center">Get in touch with us</p>
        <p class="font-tiny text-center">
          All fields with <span class="required">*</span> are mandatory, and
          request for data must be reasonable.
        </p>
        <div
          id="contact-container"
          class="d-flex flex-column bg-white w100 radius-full"
        >
          <div class="row mt-3">
            <label for="name" class="form-label font-sm fw-normal">
              Name<span class="required">*</span>
            </label>
            <div class="col px-1">
              <input
                type="text"
                class="form-control"
                placeholder="First name"
                aria-label="First name"
              />
            </div>
            <div class="col px-1">
              <input
                type="text"
                class="form-control"
                placeholder="Last name"
                aria-label="Last name"
              />
            </div>
          </div>
          <div class="mt-3">
            <label for="email" class="form-label font-sm fw-normal">
              Email<span class="required">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="Your data will be sent to this address."
            />
          </div>
          <div class="row mt-3">
            <label for="name" class="form-label font-sm fw-normal">
              Organization<span class="required">*</span>
            </label>
            <div class="col px-1">
              <input
                type="text"
                class="form-control"
                placeholder="Organization"
                aria-label="Organization"
              />
            </div>
            <div class="col px-1">
              <input
                type="text"
                class="form-control"
                placeholder="Designation"
                aria-label="Designation"
              />
            </div>
          </div>
          <div class="row mt-3">
            <label for="name" class="form-label font-sm fw-normal">
              Select country and State<span class="required">*</span>
            </label>
            <div class="col px-1">
              <input
                type="text"
                class="form-control"
                placeholder="Select Country"
                aria-label="Select country"
              />
            </div>
            <div class="col px-1">
              <input
                type="text"
                class="form-control"
                placeholder="Select State"
                aria-label="Select state"
              />
            </div>
          </div>
          <div class="mt-3">
            <label for="purpose" class="form-label font-sm fw-normal">
              Purpose<span class="required">*</span>
            </label>
            <textarea
              class="form-control form-control-ta"
              placeholder="State your intended user of the data"
              id="purpose"
              rows="3"
            ></textarea>
          </div>
          <div class="d-flex justify-content-center mt-4 mb-5 align-center">
            <button class="btn custom-primary-btn">Submit</button>
          </div>
        </div>
        <p class="font-tiny fw-bolder text-center mt-5 mb-2">
          for none data related enquiries, send us a mail
        </p>
        <p class="font-tiny fw-bold text-center dm-color">hr@edu.gov.ng</p>
        <p class="font-tiny fw-bolder text-center">
          +234 898 908 089 <br />
          +234 898 908 089
        </p>
        <p class="font-tiny fw-bolder text-center">
          Plot B, oﬀ Samuel Ladoke Akintola Boulevard, Garki , Abuja
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import HomeNavBar from "../../components/includes/NavBar";

export default {
  name: "Contact",
  components: {
    HomeNavBar,
  },
  setup() {},
};
</script>

<style lang="scss">
#contact-container {
  margin: 0.25rem -2rem;
  padding: 1rem 3rem;
}
</style>