<template>
  <div class="px-5">
    <!-- <p class="fw-bold font-tiny mt-2">Profile of LGAs based on Key School Indicators in Public Primary School for {{ dataObject.selectedYear }} ASC</p> -->

    <div class="bg-white shadow-sm radius-half font-tiny mt-3">
      <a-table
        class="ant-table-striped"
        size="middle"
        :columns="columns"
        :dataSource="data"
        :scroll="{ x: 1500 }"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      />
    </div>
  </div>
</template>

<script>
import stateNLG from "@/helpers/utils/state_local.json";
import dashboardMixinVue from "@/helpers/mixins/dashboardMixin";
import { range } from "@/helpers/utils/helper_functions";
import { Table } from "ant-design-vue";
import { reactive, ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "KeyIndicatorPublicPrimarySchool",
  components: {
    "a-table": Table,
  },
  mixins: [dashboardMixinVue],
  setup() {
    const store = useStore();
    const state = Object.entries(stateNLG)[35];
    const lgs = Object.values(state[1].locals);
    const today = new Date();
    const currentYear = today.getFullYear();
    const feasibleYears = range(currentYear - 35, currentYear);
    const currentSession = ref(currentYear - 1 + "/" + currentYear);
    const searchField = ref("");
    const session = computed(() => store.state.datatables.datatableSession);

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null);
        store.dispatch("fetchAnalysis", { index: 30, session: session.value });
      } else {
        store.commit("setDatatableMessage", "Please select a session.");
      }
    };

    onMounted(() => {
      fetchAnalysis();
    });

    watch(
      () => session.value,
      () => {
        fetchAnalysis();
      }
    );

    const dataObject = reactive({
      lga: "",
      selectedYear: currentSession,
    });

    const columns = [
      { title: "LGA", width: 120, dataIndex: "lga", key: "lga", fixed: "left" },
      {
        title: "Enrollment in primary schools",
        width: 150,
        dataIndex: "pryEnrolment",
        key: "pryEnrolment",
      },
      {
        title: "Enrollment in pre-primary schools",
        dataIndex: "prePryEnrolment",
        key: "1",
        width: 150,
      },
      {
        title: "Number of primary schools",
        dataIndex: "prySchoolCount",
        key: "2",
        width: 150,
      },
      {
        title: "Number of teachers in primary schools ",
        dataIndex: "prySchoolTeacherCount",
        key: "3",
        width: 150,
      },
      {
        title: "Number of usable classrooms in pre-primary and primary",
        dataIndex: "usableClassroomsCount",
        key: "4",
        width: 150,
      },
      {
        title: "Pupils/classroom ratio in pre-primary and primary schools",
        dataIndex: "pupilsClassroomRatio",
        key: "5",
        width: 150,
      },
      {
        title:
          "% of public pre-primary and primary schools with source of water",
        dataIndex: "percentagePublicPrePryAndPryWithWS",
        key: "6",
        width: 150,
      },
      {
        title: "Pupils/toilet ratio in primary and pre-primary",
        dataIndex: "pupilsToiletsRatio",
        key: "7",
        width: 150,
      },
      {
        title:
          "% of public pre-primary and primary schools with no health facilities",
        dataIndex: "percentagePublicPrePryAndPryWithHF",
        key: "8",
        width: 150,
      },
      {
        title:
          "% of classrooms in public pre-primary and primary schools without good blackboard",
        dataIndex: "percentagePublicPrePryAndPryWithBB",
        key: "8",
        width: 150,
        fixed: "right",
      },
    ];

    return {
      loading: false,
      hasBack: false,
      lgs,
      dataObject,
      feasibleYears,
      searchField,
      columns,
    };
  },
  computed: {
    data() {
      const data = this.$store.state.datatables.tableData;
      if (data == null) {
        return [];
      } else {
        return data.map((row) => ({
          key: row.id,
          lga: row.lga,
          pryEnrolment: row.enrolment_in_primary_schools,
          prePryEnrolment: row.enrolment_in_pre_primary_schools,
          prySchoolCount: row.number_of_primary_schools,
          prySchoolTeacherCount: row.number_of_teachers_in_primary_schools,
          usableClassroomsCount:
            row.no_of_usable_classrooms_pre_primary_and_primary,
          pupilsClassroomRatio:
            row.pupils_classrooms_ratio_in_pre_primary_and_primary_schools,
          percentagePublicPrePryAndPryWithWS:
            row.percentage_of_public_pre_primary_and_primary_schools_with_no_source_of_water,
          pupilsToiletsRatio: row.pupil_toilet_ratio_in_pre_primary_and_primary,
          percentagePublicPrePryAndPryWithHF:
            row.percentage_of_public_pre_primary_and_primary_schools_with_no_health_facilities,
          percentagePublicPrePryAndPryWithBB:
            row.percentage_of_classrooms_in_public_pre__primary_and_primary_schools_without_good_blackboard,
        }));
      }
    },
    state() {
      return this.data[0].state;
    },
    year() {
      return this.data[0].year;
    },
    session() {
      return this.data[0].session;
    },
  },
};
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td,
th {
  background-color: #f4f5fc;
}
</style>
