<template>
  <div class="px-5">
    <facilitator-learner-progress-header
      :firstItem="stages[0]"
      :secondItem="stages[1]"
      :firstPhase="phase.isCenterInfo"
      :secondPhase="phase.isFacilitatorInfo" />

    <p class="font-md fw-bold mt-2 mb-4">Facilitators Information</p>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <section 
        v-if="stageIsCentreInfo"
        class="first">      
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Center code </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter name of equipment"            
            />
          </div>

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              How many facilitators are available at the center regardless of their status and qualification?
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Male"                
                />
              </div>
              <div class="col-6 ps-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Female"                
                />
              </div>
            </div>
          </div>
          
          <div class="d-flex align-items-center justify-content-center">
            <span
              class="p-2 d-flex align-items-center justify-content-center pointer-no-bg"
            >
              <span class="material-icons-outlined dm-color me-1">
                add_circle_outline
              </span>
              <span class="font-sm fw-normal dm-color my-3"> Save and add new equipment </span>
            </span>
          </div>
        </div>
      </section>

      <section 
        v-if="stageIsFacilitatorInfo"
        class="second">        
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Name of facilitator
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="First name"            
            />
          </div>

          <div class="col-4 mt-5 px-3">
            <input
              type="text"
              class="form-control"
              placeholder="Middle name"            
            />
          </div>

          <div class="col-4 mt-5 px-3">
            <input
              type="text"
              class="form-control"
              placeholder="Last name"            
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Gender
            </label>
            <select class="form-select">
              <option value="">Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Age </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter age of facilitator"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Type of facilitator </label>
            <select class="form-select">
              <option value="">Select type of facilitator</option>
              <option value="full-time">Full-time</option>
              <option value="part-time">Part-time</option>
              <option value="volunteer">Volunteer</option>  
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Source of salary </label>
            <select class="form-select">
              <option value="">Select source of salary</option>
              <option value="federal government">Federal Government</option>
              <option value="state government">State Government</option>
              <option value="local government">Local Government</option>
              <option value="volunteer">Volunteer</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Years of experience </label>
            <input 
              type="number"
              class="form-control"
              placeholder="Enter years of experience" />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Academic qualification(s) </label>
            <select class="form-select">
              <option value="">Select academic qualification(s)</option>
              <option value="FSLC">FSLC</option>
              <option value="SSCE/WASC/Grade II">SSCE/WASC/Grade II</option>
              <option value="OND/Diploma">OND/Diploma</option>
              <option value="NCE">NCE</option>
              <option value="Degree/HND/Graduate">Degree/HND/Graduate</option>
              <option value="PhD/Masters">PhD/Masters</option>
              <option value="Others">Others</option>
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Programme </label>
            <select class="form-select">
              <option value="">Select programme</option>
              <option value="basic literacy">Basic Literacy</option>
              <option value="post literacy">Post Literacy</option>
              <option value="literacy by radio">Literacy By Radio</option>
              <option value="NFE girl-child education">NFE Girl-Child Education</option>
              <option value="NFE out-of-school (boys education)">NFE Out-Of-School (Boys Education)</option>
              <option value="integrated qur'anic education">Integrated Qur'anic Education</option>
              <option value="workers education">Workers Education</option>
              <option value="normadic adult literacy">Normadic Adult Literacy</option>
              <option value="women in purdah">Women In Purdah</option>
              <option value="women education">Women Education</option>
              <option value="literacy for correctional education">Literacy For Correctional Education</option>
              <option value="NFE out-of-school (girls education)">NFE Out-Of-School (Girls Education)</option>
              <option value="e-learning">E-Learning</option>
              <option value="traditional class (mixed)">Traditional Class (Mixed)</option>
              <option value="vocational education">Vocational Education</option>
              <option value="continuing education">Continuing Education</option>
              <option value="Others">Others</option>
            </select>
          </div>          

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> If other, please specify </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter programme" />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Instructor status </label>
            <select class="form-select">
              <option value="">Select instructor status</option>
              <option value="full-time">Full-time</option>
              <option value="part-time">Part-time</option>
              <option value="volunteer">Volunteer</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Salary/allowance received monthly </label>
            <input
              type="number"
              step="0.000000"
              class="form-control"
              placeholder="Enter monthly allowance/salary" />
          </div>          

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Was facilitator present at time of visit? </label>
            <select class="form-select">
              <option value="">Select option</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>          
      </section>

      <div class="mb-3">
        <span v-if="stage !== 0" @click="gotoPreviousStage" class="d-flex align-items-center font-tiny dm-color fw-bold mx-2 float-start pointer-no-bg"> 
          <i class="bi bi-caret-left-fill"></i> 
          <span class="pb-1 ms-1"> Previous </span>
        </span>
        <span v-if="stage !== (stages.length - 1)" @click="gotoNextStage" class="d-flex align-items-center font-tiny dm-color fw-bold mx-2 float-end pointer-no-bg"> 
          <span class="pb-1 me-1"> Next </span>
          <i class="bi bi-caret-right-fill"></i> 
        </span>
      </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <input
        type="submit"
        class="custom-btn font-tiny fw-bold"
        value="Continue"
        @click="$emit('continue')"
      />
      <span class="font-sm fw-normal dm-color my-3 pointer-no-bg">
        Save and continue later
      </span>
    </div>
  </div>
</template>

<script>

import FacilitatorLearnerProgressHeader from '../../../../includes/FacilitatorLearnerProgressHeader.vue'
export default {
  name: "FacilitatorsInfo",
  components: { FacilitatorLearnerProgressHeader },
  data() {
    return {
      stages: ["Centre Info", "Facilitator Info"],
      stage: 0,
      currentStage: "Centre Info",
      phase: {
        isCenterInfo: true,
        isFacilitatorInfo: false
      }
    }
  },
  computed: {
    stageIsCentreInfo(){ return this.currentStage === this.stages[0] },
    stageIsFacilitatorInfo(){ return this.currentStage === this.stages[1] },    
  },
    methods: {
    gotoNextStage() {
      this.stage = ++this.stage
      if (this.stage === 1) this.phase.isFacilitatorInfo = true
      this.currentStage = this.stages[this.stage]
    },

    gotoPreviousStage() {
      this.stage = --this.stage
      if (this.stage === 0) this.phase.isFacilitatorInfo = false
      this.currentStage = this.stages[this.stage]
    }
  },
}
</script>


<style scoped>

</style>