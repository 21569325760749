<template>
  <div>
    <div class="col-12 d-flex flex-column mx-3 pe-3">
      <span class="font-tiny fw-bold mb-2">Search workshop by file description?</span>
      <div class="d-flex w-100">
        <div class="custom-input-group bg-accent col-9">
          <span class="p-1"><i class="bi bi-search grey-shade"></i></span>
          <input
              type="text"
              v-model="description"
              class="ph-sm no-outline-input font-tiny fw-bolder"
              placeholder="Search if staff already exist..."
          />
        </div>
        <span
            @click="findWorkshop"
            class="btn btn-primary text-center mx-3 py-2 font-sm"
        >
          Find staff
        </span>
      </div>
    </div>
    <section class="fourth">
      <div class="row justify-content-between px-2 pb-4">
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Workshop description/label </label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter class number"
            v-model="classroomInfo.description" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Year of construction
          </label>
          <input
              type="number"
              class="form-control"
              placeholder="Enter year of construction"
              v-model="classroomInfo.yearOfConstruction"
          />
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School type
          </label>
          <select class="form-select" aria-label="Default select example" v-model="classroomInfo.level">
            <option value="">Select level</option>
            <option value="PRE_PRIMARY">Pre-Primary</option>
            <option value="PRIMARY">Primary</option>
            <option value="JUNIOR">Junior</option>
            <option value="SENIOR">Senior</option>
          </select>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Type of workshop </label>
          <select class="form-select" aria-label="Type of workshop" v-model="classroomInfo.typeOfWorkshop">
            <option value="">Select type of workshop</option>
            <option
                v-for="(workshop, index) in availableWorkshops"
                :key="index"
                :value="workshop.toLowerCase()"
            >
              {{ workshop }}
            </option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> What is the present condition of this class? </label>
          <select class="form-select" aria-label="Gender" v-model="classroomInfo.presentCondition">
            <option value="">Select class condition</option>
            <option value="good">Good</option>
            <option value="need minor repairs">Needs minor repairs</option>
            <option value="need major repairs">Needs major repairs</option>
            <option value="under construction">Under construction</option>
            <option value="unusable">Unusable</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            What material is the floor made of?
          </label>
          <select
              class="form-select"
              aria-label="Material floor is made of"
              v-model="classroomInfo.floorMaterial"
          >
            <option value="">Select material</option>
            <option value="mud earth">Mud/Earth</option>
            <option value="concrete">Concrete</option>
            <option value="wood">Wood</option>
            <option value="tile terrazzo">Tile/Terrazzo</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            What material is the wall made of?
          </label>
          <select
              class="form-select"
              aria-label="Material wall is made of"
              v-model="classroomInfo.wallMaterial"
          >
            <option value="">Select material</option>
            <option value="mud">Mud</option>
            <option value="cement concrete">Cement or Concrete</option>
            <option value="wood bamboo">Wood or Bamboo</option>
            <option value="iron sheet">Iron sheets</option>
            <option value="burnt bricks">Burnt bricks</option>
            <option value="stone">Stone</option>
            <option value="dwarf or no walls">No walls or dwarf walls</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            What material is the roof made of?
          </label>
          <select
              class="form-select"
              aria-label="Material roof is made of"
              v-model="classroomInfo.roofMaterial"
          >
            <option value="">Select material</option>
            <option value="mud">Mud</option>
            <option value="cement/concrete">Cement or Concrete</option>
            <option value="wood/bamboo">Wood or Bamboo</option>
            <option value="ceramic tiles">Ceramic tiles</option>
            <option value="iron sheet">Iron sheets</option>
            <option value="asbestos">Asbestos</option>
            <option value="no roof">No roof</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Length (in meters) </label>
          <input
              type="number"
              class="form-control"
              placeholder="Enter class number"
              v-model="classroomInfo.length" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Width (in meters)
          </label>
          <input
              type="number"
              class="form-control"
              placeholder="Enter year of construction"
              v-model="classroomInfo.width"
          />
        </div>


        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Is this workshop shared with other school(s)? </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check" aria-label="Type of teaching is full-time">
                <input
                    class="form-check-input"
                    type="radio"
                    name="shared"
                    v-model="classroomInfo.shared"
                    value="true"
                    id="is-shared"
                />
                <label class="form-check-label font-sm" for="is-shared">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="shared"
                    v-model="classroomInfo.shared"
                    value="false"
                    id="not-shared"
                />
                <label class="form-check-label font-sm" for="not-shared">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Does this classroom have good blackboard? </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check" aria-label="Type of teaching is full-time">
                <input
                    class="form-check-input"
                    type="radio"
                    name="hasBlackBoard"
                    v-model="classroomInfo.hasBlackBoard"
                    value="true"
                    id="workshop-has-blackboard"
                />
                <label class="form-check-label font-sm" for="workshop-has-blackboard">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="hasBlackBoard"
                    v-model="classroomInfo.hasBlackBoard"
                    value="false"
                    id="no-blackboard-in-workshop"
                />
                <label class="form-check-label font-sm" for="no-blackboard-in-workshop">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Are there enough seats for the students in this workshop? </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check" aria-label="Are there enough seats">
                <input
                    class="form-check-input"
                    type="radio"
                    name="hasEnoughSeats"
                    v-model="classroomInfo.hasEnoughSeats"
                    value="true"
                    id="has-enough"
                />
                <label class="form-check-label font-sm" for="has-enough">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="hasEnoughSeats"
                    v-model="classroomInfo.hasEnoughSeats"
                    value="false"
                    id="no-enough"
                />
                <label class="form-check-label font-sm" for="no-enough">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="isUpdate" class="d-flex flex-column align-items-center justify-content-center mb-4">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="updateForm"
      >
        Update
      </button>
    </div>
    <div v-else class="d-flex flex-column align-items-center justify-content-center mb-4">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "TypeOfWorkshop",
  emits: ['reload'],
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const workshopSearchResult = computed(() => store.state.questionnaireService.workshopSearchResult).value
    const availableWorkshops = ["Electrical Installation", "Computer Operation", "Basic Computer Electronics", "Computer Programming",
      "Wood Metal Finishing", "Furniture Design and Construction", "Auto mechanics", "Building Construction", "General Woodwork",
      "Electrical/Electronics", "Clothing & Textile Design", "Upholstery", "Introduction to Building", "Walls, Floors and Ceiling Finishing",
      "Concreting", "Garment Construction", "Brick, Block Laying", "Refrigeration And Air Conditioning", "Fabrication And Welding",
      "Plumbing", "Technical Drawing Studies", "Mechanical Crafting", "Printing", "Agricultural Mechanization", "Catering", "Carpenter and Joinery"
    ]
    let id = '', yearOfConstruction = '', condition = '', lenInMtr = '', widthInMtr = '', floorMaterial = '', wallMaterial = '',
        roofMaterial = '', enoughSeat = '', goodBlackBoard = '', description = '', typeOfWorkShop = '', shared = '', schoolLevel = ''

    let isUpdate = workshopSearchResult !== null
    if (workshopSearchResult !== null) {
      ({
        id, condition, description, enoughSeat, floorMaterial, goodBlackBoard, typeOfWorkShop,
        lenInMtr, roofMaterial, schoolLevel, shared, wallMaterial, widthInMtr, yearOfConstruction
      } = workshopSearchResult)
    }

    console.log(typeOfWorkShop.replace('_', " ").toLowerCase())

    const classroomInfo = {
      level: schoolLevel,
      description: description,
      yearOfConstruction: yearOfConstruction,
      typeOfWorkshop: typeOfWorkShop.replace('_', " ").toLowerCase(),
      presentCondition: condition.replace('_', " ").toLowerCase(),
      floorMaterial: floorMaterial.replace('_', " ").toLowerCase(),
      wallMaterial: wallMaterial.replace('_', " ").toLowerCase(),
      roofMaterial: roofMaterial.replace('_', " ").toLowerCase(),
      length: lenInMtr,
      width: widthInMtr,
      shared: shared,
      hasBlackBoard: goodBlackBoard,
      hasEnoughSeats: enoughSeat,
    }

    return {
      availableWorkshops,
      classroomInfo,
      schoolInfo,
      description: "",
      id,
      isUpdate
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitTypeOfWorkshop', 'findWorkshopByDescription', 'updateTypeOfWorkshop']),
    ...mapMutations(['setWorkshopSearchResult']),

    async submitForm() {
      const {classroomInfo, schoolInfo} = this
      const workshop = classroomInfo.typeOfWorkshop.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g,'_').toUpperCase();
      const body = {
        school: schoolInfo.id,
        schoolLevel: classroomInfo.level,
        description: classroomInfo.description,
        yearOfConstruction: classroomInfo.yearOfConstruction,
        condition: classroomInfo.presentCondition.replace(' ', '_').toUpperCase(),
        lenInMtr: classroomInfo.length,
        widthInMtr: classroomInfo.width,
        floorMaterial: classroomInfo.floorMaterial.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g, '_').toUpperCase(),
        wallMaterial: classroomInfo.wallMaterial.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g, '_').toUpperCase(),
        roofMaterial: classroomInfo.roofMaterial.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g, '_').toUpperCase(),
        typeOfWorkshop: workshop,
        enoughSeat: classroomInfo.hasEnoughSeats,
        goodBlackBoard: classroomInfo.hasBlackBoard,
        shared: classroomInfo.shared
      }
      await this.submitTypeOfWorkshop(body)
    },

    async updateForm() {
      const {classroomInfo, schoolInfo, id} = this
      const workshop = classroomInfo.typeOfWorkshop.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g,'_').toUpperCase();
      const body = {
        school: schoolInfo.id,
        schoolLevel: classroomInfo.level,
        description: classroomInfo.description,
        yearOfConstruction: classroomInfo.yearOfConstruction,
        condition: classroomInfo.presentCondition.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g, '_').toUpperCase(),
        lenInMtr: classroomInfo.length,
        widthInMtr: classroomInfo.width,
        floorMaterial: classroomInfo.floorMaterial.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g, '_').toUpperCase(),
        wallMaterial: classroomInfo.wallMaterial.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g, '_').toUpperCase(),
        roofMaterial: classroomInfo.roofMaterial.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g, '_').toUpperCase(),
        typeOfWorkshop: workshop,
        enoughSeat: classroomInfo.hasEnoughSeats,
        goodBlackBoard: classroomInfo.hasBlackBoard,
        shared: classroomInfo.shared
      }

      const requestBody = {
        id: id,
        body: body
      }

      await this.updateTypeOfWorkshop(requestBody)
          .then(() => {

            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 204) {
              this.setWorkshopSearchResult(null)
              this.$emit('reload')
            }
          })
    },

    async findWorkshop() {
      if (this.description !== '') { await this.findWorkshopByDescription(this.description) }
    }
  }
}
</script>

<style scoped>

</style>