<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Number of public senior secondary school teachers by gender and LGA</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-sm" aria-label="Users table">
        <thead>
        <tr>
          <th class="align-middle py-3 ps-4"></th>
          <th class="align-middle text-center py-3" colspan="4">All Teachers</th>
          <th class="align-middle text-center py-3" colspan="5">Public qualify teachers</th>
        </tr>
        <tr>
          <th class="align-middle py-3 ps-4 lga-cell col-md-2">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">LGA</span>
            </div>
          </th>
          <th class="align-middle py-3 small-cell col-md-2">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-sm fw-bold">M</span>
            </div>
          </th>
          <th class="align-middle py-3 small-cell col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-sm fw-bold">F</span>
            </div>
          </th>
          <th class="align-middle py-3 small-cell col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-sm fw-bold">MF</span>
            </div>
          </th>
          <th class="align-middle py-3 small-cell col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-sm fw-bold">F%</span>
            </div>
          </th>
          <th class="align-middle py-3 small-cell col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-sm fw-bold">M</span>
            </div>
          </th>
          <th class="align-middle py-3 small-cell col-md-1" scope="col">
            <span class="font-sm fw-bold">F</span>
          </th>
          <th class="align-middle py-3 small-cell col-md-1" scope="col">
            <span class="font-sm fw-bold">MF</span>
          </th>
          <th class="align-middle py-3 other-cell col-md-1" scope="col">
            <span class="font-sm fw-bold">% qualified</span>
          </th>
          <th class="align-middle py-3 other-cell col-md-1" scope="col">
            <span class="font-sm fw-bold">% female</span>
          </th>
        </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg" 
            v-for="(data, index) in tableData"
            :key="index"            
          >
            <td class="py-3 ps-4 lga-cell first">{{ data.lga }}</td>
            <td class="py-3 small-cell">{{ data.all_teachers_m }}</td>
            <td class="py-3 small-cell">{{ data.all_teachers_f }}</td>
            <td class="py-3 small-cell">{{ data.all_teachers_t }}</td>
            <td class="py-3 small-cell">{{ data.all_teachers_f_percentage }}</td>
            <td class="py-3 small-cell">{{ data.public_qualify_teachers_m }}</td>
            <td class="py-3 small-cell">{{ data.public_qualify_teachers_f }}</td>
            <td class="py-3 small-cell">{{ data.public_qualify_teachers_t }}</td>
            <td class="py-3 other-cell">{{ data.public_qualify_teachers_percentage_qualified }}</td>
            <td class="py-3 other-cell">{{ data.public_qualify_teachers_percentage_female }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "PublicSeniorTeacherByGenderAndLGA",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 44, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })

    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }  
}
</script>

<style scoped>
.lga-cell {
  width: 16%;
}
.other-cell {
  width: 14%;
}
.small-cell {
  width: 8%;
}
</style>