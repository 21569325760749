<template>
  <div class="graph-container d-flex flex-column bg-white radius-full mt-4 mb-4">
    <div class="d-flex justify-content-between">
      <span class="font-sm fw-bold">Water infrastructures</span>
      <a-select 
        :value="waterInfrastructureOption"
        style="width: 120px"
        placeholder="View by"
        :options="viewByOptions.map(option => ({value: option}))"
        @change="waterInfrastructureOptionChange"
      />
    </div>
    <div>
      <canvas class="charts" id="water-infrastructure-chart" height="300"></canvas>
    </div>    
  </div>
</template>

<script>
import { computed, onMounted } from '@vue/runtime-dom'
import {Select} from "ant-design-vue"
import Chart from "chart.js/auto";
import { useStore } from 'vuex'
import { watch, ref } from 'vue'

export default {
  name: "WaterInfrastructureChart",
  components: {
    "a-select": Select,
  },
  emits: ['reload'],
  setup(props, {emit}) {
    const waterInfrastructureOption = ref('')
    const store = useStore()
    const params = ref()
    const viewByOptions = ['LGA', 'Level', 'Classification', 'Water']
    const selectedOptions = ref('')
    const chartData = ref(null)
    const xAxisLabel = ref([])
    const dataSet = ref([])    
    const waterInfrastructureData = computed(() => store.state.analysis.waterChartData)

    const populateChart = () => {            
      dataSet.value = Object.values(waterInfrastructureData.value.data.map(item => item.percentage))
      let checkLGA = Object.values(waterInfrastructureData.value.data)[0].id.lga
      let checkLevel = Object.values(waterInfrastructureData.value.data)[0].id.level
      let checkClassification = Object.values(waterInfrastructureData.value.data)[0].id.classification      
      if (checkLGA !== undefined) {
        waterInfrastructureOption.value = 'LGA'
        xAxisLabel.value = Object.values(waterInfrastructureData.value.data.map(item => item.id.lga))      
      } else if (checkLevel !== undefined) {
        waterInfrastructureOption.value = 'Level'
        xAxisLabel.value = Object.values(waterInfrastructureData.value.data.map(item => item.id.level))
      } else if (checkClassification !== undefined) {
        waterInfrastructureOption.value = 'Classification'
        xAxisLabel.value = Object.values(waterInfrastructureData.value.data.map(item => item.id.classification))
      } else {
        waterInfrastructureOption.value = 'Water'
        xAxisLabel.value = Object.values(waterInfrastructureData.value.data.map(item => item.id.water))
      }
      
      chartData.value = {
        type: 'bar',
        data: {
          labels: xAxisLabel.value,
          datasets: [
            {
              label: "Water Infrastructure",
              categoryPercentage: 0.3,
              borderRadius: 2,
              data: dataSet.value,
              backgroundColor: 'rgba(0, 242, 104, 1)',
            },
          ]
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        },
      }
    }
    
    watch(
      () => waterInfrastructureData.value,
      () => {        
        if (waterInfrastructureData.value !== null && chartData.value === null) {    
          console.log("I'm reloading now!");
          emit('reload')
        }
      }
    )

    onMounted(() => {
      if (waterInfrastructureData.value === null) {
        store.dispatch('fetchWaterChartData', params.value)
      } else {
        populateChart()
        const combinedEnrolmentChart = document.getElementById('water-infrastructure-chart')     
        new Chart(combinedEnrolmentChart, chartData.value)     
      }
    }) 

    const waterInfrastructureOptionChange = (value) => {      
      const param = value.toLowerCase().replace(' ', '_')
      selectedOptions.value = param
      chartData.value = null
      store.dispatch('fetchWaterChartData', param)
    }

    return {
      viewByOptions,
      selectedOptions,
      waterInfrastructureOption,
      waterInfrastructureOptionChange
    }
  },
}
</script>

<style scoped>
.charts {
  max-height: 300px;
}
</style>