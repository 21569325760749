<template>
  <div class="d-flex flex-column">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <form @submit.prevent="createUser">
      <p class="fw-bold grey-accent mt-4 pt-2 mb-3">{{ operation }}</p>
      <div
        class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white shadow-sm"
      >
        <div class="d-flex flex-column px-4">
          <img
            :src="dummyAvatar"
            class="rounded-circle"
            width="100"
            height="100"
            alt=""
          />
          <span class="font-tiny grey-accent mt-1"
            ><i class="bi bi-pen-fill pb-1"></i> Change Photo</span
          >
        </div>
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> First name </label>
            <input
              type="text"
              placeholder="First name"
              v-model="userInfo.firstName"
              v-bind:class="{
                'form-control': true,
                'is-invalid':
                  !validString(userInfo.firstName) && firstNameBlured,
              }"
              v-on:blur="firstNameBlured = true"
            />
            <div class="invalid-feedback">First name is required!</div>
          </div>
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Last name </label>
            <input
              type="text"
              class="form-control"
              placeholder="Last name"
              v-model="userInfo.lastName"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validString(userInfo.lastName) && lastNameBlured,
              }"
              v-on:blur="lastNameBlured = true"
            />
            <div class="invalid-feedback">Last name is required!</div>
          </div>
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Middle name </label>
            <input
              type="text"
              class="form-control"
              placeholder="Middle name"
              v-model="userInfo.middleName"
            />
          </div>
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Gender </label>
            <select
              class="form-select border-0 text-dark"
              aria-label="Default select example"
              v-model="userInfo.gender"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validString(userInfo.gender) && genderBlured,
              }"
              v-on:onChange="genderBlured = true"
            >
              <option v-if="userInfo.gender" :value="userInfo.gender" selected>
                {{ userInfo.gender }}
              </option>

              <option class="bg-white" selected>Select gender</option>
              <option class="bg-white" value="male">Male</option>
              <option class="bg-white" value="female">Female</option>
            </select>
            <div class="invalid-feedback">Gender is required!</div>
          </div>
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Local Government
            </label>
            <select
              class="form-select text-dark"
              aria-label="Default select example"
              v-model="userInfo.lga"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validString(userInfo.lga) && lgaBlured,
              }"
              v-on:onChange="lgaBlured = true"
            >
              <option v-if="userInfo.lga" :value="userInfo.lga" selected>
                {{ userInfo.lga }}
              </option>

              <option class="bg-white" selected>Select LGA</option>
              <option
                class="bg-white"
                v-for="(lg, index) in lgs"
                :key="index"
                :value="lg.name"
              >
                {{ lg.name }}
              </option>
            </select>
            <div class="invalid-feedback">LGA is required!</div>
          </div>
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Nationality </label>
            <select
              class="form-select text-dark"
              aria-label="Nationality"
              v-model="userInfo.nationality"
              v-bind:class="{
                'form-control': true,
                'is-invalid':
                  !validString(userInfo.nationality) && nationalityBlured,
              }"
              v-on:blur="nationalityBlured = true"
            >
              <option
                v-if="userInfo.nationality"
                :value="userInfo.nationality"
                selected
              >
                {{ userInfo.nationality }}
              </option>
              <option value="Nigeria" selected>Nigeria</option>
            </select>
            <div class="invalid-feedback">Nationality is required!</div>
          </div>
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Phone number </label>
            <input
              type="number"
              placeholder="Phone number"
              v-model="userInfo.mobile"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validNumber(userInfo.mobile) && mobileBlured,
              }"
              v-on:blur="mobileBlured = true"
            />
            <div class="invalid-feedback">Phone number is required</div>
          </div>
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Email </label>
            <input
              type="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              class="form-control"
              placeholder="Email Address"
              v-model="userInfo.email"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validEmail(userInfo.email) && emailBlured,
              }"
              v-on:blur="emailBlured = true"
            />
            <div class="invalid-feedback">
              A valid email address is required!
            </div>
          </div>
        </div>
      </div>

      <p class="fw-bold mb-3">Institute of Affiliation</p>
      <div
        class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white shadow-sm"
      >
        <div class="row pb-4">
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Role </label>
            <select
              class="form-select text-dark"
              aria-label="Default select example"
              v-model="userInfo.role"
              @change="roleSelected, (roleBlured = true)"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validString(userInfo.role) && roleBlured,
              }"
            >
              <option class="bg-white" selected>Select role</option>
              <option
                class="bg-white"
                v-for="(role, index) in user_roles"
                :key="index"
                :value="'ROLE_' + role"
              >
                {{ role.replace("_", " ") }}
              </option>
            </select>
            <div class="invalid-feedback">Role is required!</div>
          </div>
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Organization </label>
            <select
              class="form-select text-dark"
              aria-label="Default select example"
              v-model="userInfo.organizationId"
              @change="onChangeOrg"
              v-bind:class="{
                'form-control': true,
                'is-invalid':
                  !validNumber(userInfo.organizationId) && organizationalBlured,
              }"
            >
              <option>Select organisation</option>
              <option
                v-for="(school, index) in schools"
                :key="index"
                :value="school.id"
              >
                {{ school.name }}
              </option>
            </select>
            <div class="invalid-feedback">Organization is required!</div>
          </div>
        </div>
      </div>

      <div v-if="isSchoolStaff">
        <p class="fw-bold mb-3">Additional information for staff</p>
        <div
          class="collapse d-flex flex-column px-5 py-4 mb-4 radius-full bg-white shadow-sm"
        >
          <div class="row pb-4">
            <div class="col-6 mt-3 px-3">
              <label class="form-label font-sm fw-normal">
                Staff file number</label
              >
              <input
                type="number"
                class="form-control"
                placeholder="Enter staff file number"
              />
            </div>
            <div class="col-6 mt-3 px-3">
              <label class="form-label font-sm fw-normal">
                Year of posting to this school
              </label>
              <select
                class="form-select"
                aria-label="Year of posting to this school"
              >
                <option value="">Select year of posting to this school</option>
                <option v-for="year in feasibleYears" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="col-6 mt-3 px-3">
              <label class="form-label font-sm fw-normal">
                Grade level/step
              </label>
              <select class="form-select" aria-label="Teaching qualification">
                <option value="">Select grade level/step</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
            <div class="col-6 mt-3 px-3">
              <label class="form-label font-sm fw-normal">
                Academic qualification
              </label>
              <select class="form-select" aria-label="Academic qualification">
                <option value="">Select academic qualification</option>
                <option value="below ssce">Below SSCE</option>
                <option value="ssce/wasc">SSCE/WASC</option>
                <option value="ond/diploma">OND/Diploma</option>
                <option value="nce">NCE</option>
                <option value="degree/hnd graduate">Degree/HND graduate</option>
                <option value="phd/masters">PhD/Masters degree</option>
              </select>
            </div>
            <div class="col-6 mt-3 px-3">
              <label class="form-label font-sm fw-normal">
                Teaching qualification
              </label>
              <select class="form-select" aria-label="Teaching qualification">
                <option value="">Select teaching qualification</option>
                <option value="nce">NCE</option>
                <option value="pgde">PGDE</option>
                <option value="b.ed or equivalent">B.Ed or equivalent</option>
                <option value="m.ed or equivalent">M.Ed or equivalent</option>
                <option value="grade II or equivalent">
                  Grade II or equivalent
                </option>
                <option value="none">None</option>
              </select>
            </div>
            <div class="col-6 mt-3 px-3">
              <label class="form-label font-sm fw-normal">
                Subject of qualification
              </label>
              <select class="form-select" aria-label="Subject of qualification">
                <option value="">Select subject of qualification</option>
                <option
                  v-for="(subject, index) in subjects"
                  :key="index"
                  :value="subject"
                >
                  {{ subject }}
                </option>
              </select>
            </div>
            <div class="col-6 mt-3 px-3">
              <label class="form-label font-sm fw-normal">
                Area of specialization
              </label>
              <select class="form-select" aria-label="Area of specialization">
                <option value="">Select area of specialization</option>
                <option
                  v-for="(subject, index) in subjects"
                  :key="index"
                  :value="subject"
                >
                  {{ subject }}
                </option>
              </select>
            </div>
            <div class="col-6 mt-3 px-3">
              <label class="form-label font-sm fw-normal">
                Main subject taught
              </label>
              <select class="form-select" aria-label="Main subject taught">
                <option value="">Select main subject taught</option>
                <option
                  v-for="(subject, index) in subjects"
                  :key="index"
                  :value="subject"
                >
                  {{ subject }}
                </option>
              </select>
            </div>
            <div class="col-12 mt-3 px-3">
              <label class="form-label font-sm fw-normal">
                Teaching type
              </label>
              <div class="d-flex">
                <div class="col-6 pe-3">
                  <div
                    class="form-check"
                    aria-label="Type of teaching is full-time"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="typeOfTeaching"
                      value="full-time"
                      id="full-time"
                    />
                    <label class="form-check-label font-sm" for="full-time">
                      Full-time
                    </label>
                  </div>
                </div>
                <div class="col-6 ps-3">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="typeOfTeaching"
                      value="part-time"
                      id="part-time"
                    />
                    <label class="form-check-label font-sm" for="part-time">
                      Part-time
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <input
          type="submit"
          class="custom-btn font-tiny fw-bold"
          value="Edit user"
          v-if="edit"
        />
        <button v-else class="custom-btn font-tiny fw-bold" type="submit">
          Create user
        </button>
      </div>
    </form>
    <!--    <modal v-if="showModal" @close="closeModal">-->
    <!--      <template v-slot:body> {{ message }} </template>-->
    <!--    </modal>-->
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import avatar from "@/assets/images/emptyAvatar.png";
import stateNLG from "@/helpers/utils/state_local.json";
import { userRoles } from "@/helpers/utils/user_roles";
import { mapActions, mapGetters, mapMutations, useStore } from "vuex";
import { onMounted, toRef } from "@vue/runtime-core";
import { range } from "@/helpers/utils/helper_functions";
import { computed, ref } from "vue";

export default {
  name: "CreateUser",
  created() {
    if (this.$store.state.school.schools === null) {
      this.loadOrganization();
    }
  },
  components: {
    ClipLoader,
  },
  props: ["edit", "user"],
  setup(props) {
    const today = new Date();
    const store = useStore();
    const orgs = computed(() => store.state.school.schools).value;
    let organizations = ref(orgs);
    const currentYear = today.getFullYear() + 1;
    const feasibleYears = range(currentYear - 35, currentYear);
    // const lgs = Object.values(state[1].locals);
    const user = toRef(props, "user");
    let isSchoolStaff = ref(false);
    console.log(orgs, ">>><<<");
    let id = "",
      firstName = "",
      lastName = "",
      middleName = "",
      gender = "",
      lga = "",
      mobile = "",
      email = "",
      role = "",
      organizationId = "";

    if (user.value !== undefined && user.value !== null) {
      ({
        id,
        firstName,
        lastName,
        middleName,
        gender,
        lga,
        mobile,
        email,
        role,
        organizationId,
      } = user.value);
    } else if (props.edit) {
      console.log(computed(() => store.getters.loginUser).value);
      ({
        id,
        firstName,
        lastName,
        middleName,
        gender,
        lga,
        mobile,
        email,
        role,
        organizationId,
      } = computed(() => store.getters.loginUser).value);
    }

    const user_roles = userRoles;
    // const userInfo = {
    //     firstName: firstName,
    //     lastName: lastName,
    //     middleName: middleName,
    //     gender: gender,
    //     nationality: "Nigeria",
    //     lga: lga,
    //     mobile: mobile,
    //     email: email,
    //     role: role,
    //     organizationId: organizationId,
    //     staffId: "",
    //     yearOfPostingToSchool: "",
    //     gradeLevel: "",
    //     academicQualification: "",
    //     teachingQualification: "",
    //     subjectOfQualification: "",
    //     areaOfSpecialization: "",
    //     mainSubjectTaught: "",
    //     teachingType: ""
    //   }

    const schoolRoles = [
      "ROLE_PRINCIPAL",
      "ROLE_HEADTEACHER",
      "ROLE_ENUMERATOR",
      "ROLE_SUPERVISOR",
      "ROLE_SBMC",
    ];

    const subjects = [
      "English",
      "Mathematics",
      "Social studies",
      "Basic science",
      "Hausa/Igbo/Yoruba",
      "Caregiving",
      "Science",
      "Humanities",
      "Technology",
    ];

    const roleSelected = (event) => {
      if (schoolRoles.includes(event.target.value)) {
        organizations.value = orgs.filter(
          (org) => org.organizationCode === "EDUCATIONAL"
        );
        isSchoolStaff.value = true;
      } else {
        organizations.value = orgs.filter(
          (org) => org.organizationCode !== "EDUCATIONAL"
        );
        isSchoolStaff.value = false;
      }
    };
    return {
      user_roles,
      schoolRoles,
      feasibleYears,
      subjects,
      // userInfo,
      id,
      organizations,
      isSchoolStaff,
      roleSelected,
    };
  },
  beforeMount() {
    this.lgs = Object.values(this.state[1].locals);
    this.operation = this.edit ? "Edit User" : "Create User";
    this.selectedUser = this.user;
    if (this.edit && this.user) {
      this.userInfo.firstName = this.user.firstName;
      this.userInfo.lastName = this.user.lastName;
      this.userInfo.middleName = this.user.middleName;
      this.userInfo.gender = this.user.gender;
      this.userInfo.nationality = this.user.nationality;
      this.userInfo.lga = this.user.lga;
      this.userInfo.mobile = this.user.mobile;
      this.userInfo.email = this.user.email;
      this.userInfo.role = this.user.role;
      this.userInfo.organizationId = this.user.organizationId;
    }
  },
  data() {
    return {
      state: Object.entries(stateNLG)[35],
      selectedUser: "",
      lgs: "",
      operation: "",
      userInfo: {
        firstName: "",
        lastName: "",
        middleName: "",
        gender: "",
        nationality: "Nigeria",
        lga: "",
        mobile: "",
        email: "",
        role: "",
        organizationId: "",
        staffId: "",
        yearOfPostingToSchool: "",
        gradeLevel: "",
        academicQualification: "",
        teachingQualification: "",
        subjectOfQualification: "",
        areaOfSpecialization: "",
        mainSubjectTaught: "",
        teachingType: "",
        schoolCode: "",
      },
      valid: false,
      firstNameBlured: false,
      lastNameBlured: false,
      genderBlured: false,
      lgaBlured: false,
      nationalityBlured: false,
      mobileBlured: false,
      emailBlured: false,
      roleBlured: false,
      organizationalBlured: false,
      dummyAvatar: avatar,
      showModal: false,
    };
  },
  computed: {
    schools() {
      if (this.$store.state.school.schools !== null)
        return this.$store.state.school.schools;
      else return [];
    },
    message() {
      return this.$store.state.auth.message;
    },
    loading() {
      return this.$store.state.auth.loading;
    },
  },
  mounted() {
    console.log(this.schools, "<<<<<<<<<<<<<<");
  },
  methods: {
    ...mapActions(["registerUser", "fetchSchools", "updateUser", "fetchUsers"]),
    ...mapGetters(["getMessage"]),
    ...mapMutations(["setLoadingStatus", "setMessage"]),
    validate() {
      this.firstNameBlured = true;
      this.lastNameBlured = true;
      this.genderBlured = true;
      this.lgaBlured = true;
      this.nationalityBlured = true;
      this.mobileBlured = true;
      this.emailBlured = true;
      this.roleBlured = true;
      this.organizationalBlured = true;
      if (
        this.validString(this.userInfo.firstName) &&
        this.validString(this.userInfo.lastName) &&
        this.validString(this.userInfo.gender) &&
        this.validString(this.userInfo.lga) &&
        this.validString(this.userInfo.nationality) &&
        this.validNumber(this.userInfo.mobile) &&
        this.validEmail(this.userInfo.email) &&
        this.validString(this.userInfo.role) &&
        this.validNumber(this.userInfo.organizationId)
      ) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    validString(name) {
      if (name.length > 0) {
        return true;
      }
    },
    validNumber(number) {
      if (number > 0) {
        return true;
      }
    },
    validEmail(email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      if (re.test(email.toLowerCase())) {
        return true;
      }
    },
    onChangeOrg() {
      this.organizationalBlured = true;
      console.log(
        this.schools.find((s) => s.id == this.userInfo.organizationId)
      );
      this.userInfo.schoolCode = this.schools.find(
        (s) => s.id == this.userInfo.organizationId
      );
    },
    async createUser() {
      this.validate();
      if (this.valid) {
        const user = {
          firstName: this.userInfo.firstName,
          lastName: this.userInfo.lastName,
          middleName: this.userInfo.middleName,
          gender: this.userInfo.gender.toUpperCase(),
          nationality: this.userInfo.nationality,
          lga: this.userInfo.lga.toUpperCase(),
          mobile: this.userInfo.mobile,
          email: this.userInfo.email,
          role: this.userInfo.role.replace(" ", "_"),
          organizationId: this.userInfo.organizationId,
          // schoolCode: this.schools.find(
          //   (s) => s.id == this.userInfo.organizationId
          // ),
        };

        if (this.operation === "Edit User") {
          try {
            const body = {
              id: this.id,
              user: user,
            };
            await this.updateUser(body);
            if (this.getMessage !== null) {
              this.showModal = true;
              this.$emit("fetchNewUsers");
              this.$store.dispatch("fetchUsers");
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            await this.registerUser(user).then(() => {
              if (this.getMessage !== null) {
                this.showModal = true;
                this.$emit("reload");
                this.$store.dispatch("fetchUsers");
              }
            });
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        console.log("Validation error");
      }
    },

    closeModal() {
      this.showModal = false;
      this.setMessage(null);
    },

    async loadOrganization() {
      await this.fetchSchools().then(() => {
        this.$emit("reload");
      });
    },
  },
};
</script>
