<template>
  <div>
    <section class="mb-5 mx-4">
      <p class="font-sm fw-bold">Number of core subject Teachers' textbooks available in the School provided by government.</p>
      <div class="d-flex mb-2">
        <div class="col-2"><p class="font-sm fw-medium">Text book</p></div>
        <span class="col me-1 text-center font-sm fw-medium">Pre-primary</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 1</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 2</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 3</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 4</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 5</span>
        <span class="col me-1 text-center font-sm fw-medium">Primary 6</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">English</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrePrimaryTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrimaryOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrimaryTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrimaryThreeTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrimaryFourTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishPrimaryFiveTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0">
          <input class="form-control px-1 outline" v-model="englishPrimarySixTeacher.number" type="text" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Mathematics</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrePrimaryTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrimaryOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrimaryTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrimaryThreeTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrimaryFourTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathPrimaryFiveTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0">
          <input class="form-control px-1 outline" v-model="mathPrimarySixTeacher.number" type="text" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Social studies</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrePrimaryTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimaryOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimaryTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimaryThreeTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimaryFourTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimaryFiveTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0">
          <input class="form-control px-1 outline" v-model="socialStudiesPrimarySixTeacher.number" type="text" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Basic Science / technology</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrePrimaryTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrimaryOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrimaryTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrimaryThreeTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrimaryFourTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicSciencePrimaryFiveTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0">
          <input class="form-control px-1 outline" v-model="basicSciencePrimarySixTeacher.number" type="text" aria-label="female">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "TeachersTeacherBookSection",
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const englishPrePrimaryTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "PRE_PRIMARY",
      number: ''
    }
    const englishPrimaryOneTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "PRIMARY1",
      number: ''
    }
    const englishPrimaryTwoTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "PRIMARY2",
      number: ''
    }
    const englishPrimaryThreeTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "PRIMARY3",
      number: ''
    }
    const englishPrimaryFourTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "PRIMARY4",
      number: ''
    }
    const englishPrimaryFiveTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "PRIMARY5",
      number: ''
    }
    const englishPrimarySixTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "PRIMARY6",
      number: ''
    }

    const mathPrePrimaryTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "PRE_PRIMARY",
      number: ''
    }
    const mathPrimaryOneTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY1",
      number: ''
    }
    const mathPrimaryTwoTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY2",
      number: ''
    }
    const mathPrimaryThreeTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY3",
      number: ''
    }
    const mathPrimaryFourTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY4",
      number: ''
    }
    const mathPrimaryFiveTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY5",
      number: ''
    }
    const mathPrimarySixTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "PRIMARY6",
      number: ''
    }

    const socialStudiesPrePrimaryTeacher = {
      user: "TEACHER",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRE_PRIMARY",
      number: ''
    }
    const socialStudiesPrimaryOneTeacher = {
      user: "TEACHER",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY1",
      number: ''
    }
    const socialStudiesPrimaryTwoTeacher = {
      user: "TEACHER",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY2",
      number: ''
    }
    const socialStudiesPrimaryThreeTeacher = {
      user: "TEACHER",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY3",
      number: ''
    }
    const socialStudiesPrimaryFourTeacher = {
      user: "TEACHER",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY4",
      number: ''
    }
    const socialStudiesPrimaryFiveTeacher = {
      user: "TEACHER",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY5",
      number: ''
    }
    const socialStudiesPrimarySixTeacher = {
      user: "TEACHER",
      subject: "SOCIAL_STUDIES",
      schoolClass: "PRIMARY6",
      number: ''
    }

    const basicSciencePrePrimaryTeacher = {
      user: "TEACHER",
      subject: "BASIC_SCIENCE",
      schoolClass: "PRE_PRIMARY",
      number: ''
    }
    const basicSciencePrimaryOneTeacher = {
      user: "TEACHER",
      subject: "BASIC_SCIENCE",
      schoolClass: "PRIMARY1",
      number: ''
    }
    const basicSciencePrimaryTwoTeacher = {
      user: "TEACHER",
      subject: "BASIC_SCIENCE",
      schoolClass: "PRIMARY2",
      number: ''
    }
    const basicSciencePrimaryThreeTeacher = {
      user: "TEACHER",
      subject: "BASIC_SCIENCE",
      schoolClass: "PRIMARY3",
      number: ''
    }
    const basicSciencePrimaryFourTeacher = {
      user: "TEACHER",
      subject: "BASIC_SCIENCE",
      schoolClass: "PRIMARY4",
      number: ''
    }
    const basicSciencePrimaryFiveTeacher = {
      user: "TEACHER",
      subject: "BASIC_SCIENCE",
      schoolClass: "PRIMARY5",
      number: ''
    }
    const basicSciencePrimarySixTeacher = {
      user: "TEACHER",
      subject: "BASIC_SCIENCE",
      schoolClass: "PRIMARY6",
      number: ''
    }

    return {
      englishPrePrimaryTeacher,
      englishPrimaryOneTeacher,
      englishPrimaryTwoTeacher,
      englishPrimaryThreeTeacher,
      englishPrimaryFourTeacher,
      englishPrimaryFiveTeacher,
      englishPrimarySixTeacher,

      mathPrePrimaryTeacher,
      mathPrimaryOneTeacher,
      mathPrimaryTwoTeacher,
      mathPrimaryThreeTeacher,
      mathPrimaryFourTeacher,
      mathPrimaryFiveTeacher,
      mathPrimarySixTeacher,

      socialStudiesPrePrimaryTeacher,
      socialStudiesPrimaryOneTeacher,
      socialStudiesPrimaryTwoTeacher,
      socialStudiesPrimaryThreeTeacher,
      socialStudiesPrimaryFourTeacher,
      socialStudiesPrimaryFiveTeacher,
      socialStudiesPrimarySixTeacher,

      basicSciencePrePrimaryTeacher,
      basicSciencePrimaryOneTeacher,
      basicSciencePrimaryTwoTeacher,
      basicSciencePrimaryThreeTeacher,
      basicSciencePrimaryFourTeacher,
      basicSciencePrimaryFiveTeacher,
      basicSciencePrimarySixTeacher,

      schoolInfo,
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    }
  },
  methods: {
    ...mapActions(['submitStudentTeacherBook']),

    submitForm() {
      const subjects = [
        this.englishPrePrimaryTeacher,
        this.englishPrimaryOneTeacher,
        this.englishPrimaryTwoTeacher,
        this.englishPrimaryThreeTeacher,
        this.englishPrimaryFourTeacher,
        this.englishPrimaryFiveTeacher,
        this.englishPrimarySixTeacher,

        this.mathPrePrimaryTeacher,
        this.mathPrimaryOneTeacher,
        this.mathPrimaryTwoTeacher,
        this.mathPrimaryThreeTeacher,
        this.mathPrimaryFourTeacher,
        this.mathPrimaryFiveTeacher,
        this.mathPrimarySixTeacher,

        this.socialStudiesPrePrimaryTeacher,
        this.socialStudiesPrimaryOneTeacher,
        this.socialStudiesPrimaryTwoTeacher,
        this.socialStudiesPrimaryThreeTeacher,
        this.socialStudiesPrimaryFourTeacher,
        this.socialStudiesPrimaryFiveTeacher,
        this.socialStudiesPrimarySixTeacher,

        this.basicSciencePrePrimaryTeacher,
        this.basicSciencePrimaryOneTeacher,
        this.basicSciencePrimaryTwoTeacher,
        this.basicSciencePrimaryThreeTeacher,
        this.basicSciencePrimaryFourTeacher,
        this.basicSciencePrimaryFiveTeacher,
        this.basicSciencePrimarySixTeacher,
      ]
      this.submitStudentTeacherBook({id: this.schoolInfo.id, body: subjects})
          .then(() => {
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>