import axios from "axios";
import { emisconfig } from "../../../../app.config";

const uninterceptedAxios = axios.create();
uninterceptedAxios.defaults.baseURL = `${emisconfig.baseURL}/`;
uninterceptedAxios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const initState = {
  loading: false,
  updateLoading: false,
  updateMessage: null,
  activeStagePrePry: "",
  birthCertData: null,
  prePryEnrolmentByAgeData: null,
  primaryEnrolmentByAgeData: null,
  juniorEnrolmentByAgeData: null,
  seniorEnrolmentByAgeData: null,
  pryNewEntrantData: null,
  juniorNewEntrantData: null,
  seniorNewEntrantData: null,
  studentFlowData: null,
  personsWithSpecialNeedsData: null,
  orphansEnrolmentData: null,
  nabtebEnrolmentData: null,
  streamEnrolmentData: null,
};

const state = initState;

const actions = {
  async fetchBirthCertEnrolment({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-a/school/${id}`
      : `questionaire/enrollement/section-a`;
    console.log(url);
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setBirthCertData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchPrePryEnrolmentByAge({ commit }, state, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-c4/school/${id}`
      : `questionaire/enrollement/section-c4`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setPrePryEnrolmentByAgeData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchPrimaryEnrolmentByAge({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-c3/school/${id}`
      : `questionaire/enrollement/section-c3`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setPrimaryEnrolmentByAgeData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchJuniorEnrolmentByAge({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-c2/school/${id}`
      : `questionaire/enrollement/section-c2`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setJuniorEnrolmentByAgeData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchSeniorEnrolmentByAge({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-c5/school/${id}`
      : `questionaire/enrollement/section-c5`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setSeniorEnrolmentByAgeData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchPryNewEntrant({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-b2/school/${id}`
      : `questionaire/enrollement/section-b2`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setPryNewEntrantData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchJuniorNewEntrant({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-b1/school/${id}`
      : `questionaire/enrollement/section-b1`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setJuniorNewEntrantData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchSeniorNewEntrant({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-b3/school/${id}`
      : `questionaire/enrollement/section-b3`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setSeniorNewEntrantData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchStreamEnrolmentData({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-c1/school/${id}`
      : `questionaire/enrollement/section-c1`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setStreamEnrolmentData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchStudentFlowData({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-g/school/${id}`
      : `questionaire/enrollement/section-g`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setStudentFlowData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchPersonsWithSpecialNeedsData({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-f/school/${id}`
      : `questionaire/enrollement/section-f`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setPersonsWithSpecialNeedsData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchOrphansEnrolmentData({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-e/school/${id}`
      : `questionaire/enrollement/section-e`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setOrphansEnrolmentData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  async fetchNABTEBEnrolmentData({ commit, state }, id) {
    commit("setEnrolmentLoading", true);
    const url = id
      ? `questionaire/enrollement/section-d/school/${id}`
      : `questionaire/enrollement/section-d`;
    await axios
      .get(url, { timeout: 15000 })
      .then((res) => {
        commit("setEnrolmentLoading", false);
        commit("setNabtebEnrolmentData", res.data);
      })
      .catch((err) => {
        commit("setEnrolmentLoading", false);
        console.log(err);
      });
  },

  // ENROLMENT UPDATE

  async updateRequest({ commit, state }, payload) {
    const { endpoint, body } = payload;
    commit("setUpdateLoadingStatus", true);
    await uninterceptedAxios
      .patch(endpoint, body, { timeout: 15000 })
      .then((res) => {
        commit("setUpdateLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        console.log(res.data);
      })
      .catch(() => {
        commit("setUpdateLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Error updating data!",
        });
      });
  },

  async updateBirthCertEnrolment({ dispatch, state }, payload) {
    const { id, body } = payload;
    const requestObject = {
      endpoint: `questionaire/enrollment/section-a/${id}`,
      body: body,
    };
    await dispatch("updateRequest", requestObject);
  },

  async updatePrePryEnrolmentByAge({ dispatch, state }, payload) {
    const { id, body } = payload;
    const requestObject = {
      endpoint: `questionaire/enrollment/section-c4/${id}`,
      requestBody: body,
    };
    await dispatch("updateRequest", requestObject);
  },
};

const mutations = {
  setActiveStagePrePry(state, value) {
    state.activeStagePrePry = value;
  },

  setEnrolmentLoading(state, value) {
    state.loading = value;
  },

  setBirthCertData(state, value) {
    state.birthCertData = value;
  },

  setPrePryEnrolmentByAgeData(state, value) {
    state.prePryEnrolmentByAgeData = value;
  },

  setPrimaryEnrolmentByAgeData(state, value) {
    state.primaryEnrolmentByAgeData = value;
  },

  setJuniorEnrolmentByAgeData(state, value) {
    state.juniorEnrolmentByAgeData = value;
  },

  setSeniorEnrolmentByAgeData(state, value) {
    state.seniorEnrolmentByAgeData = value;
  },

  setPryNewEntrantData(state, value) {
    state.pryNewEntrantData = value;
  },

  setJuniorNewEntrantData(state, value) {
    state.juniorNewEntrantData = value;
  },

  setSeniorNewEntrantData(state, value) {
    state.seniorNewEntrantData = value;
  },

  setStudentFlowData(state, value) {
    state.studentFlowData = value;
  },

  setPersonsWithSpecialNeedsData(state, value) {
    state.personsWithSpecialNeedsData = value;
  },

  setOrphansEnrolmentData(state, value) {
    state.orphansEnrolmentData = value;
  },

  setNabtebEnrolmentData(state, value) {
    state.nabtebEnrolmentData = value;
  },

  setStreamEnrolmentData(state, value) {
    state.streamEnrolmentData = value;
  },

  setUpdateLoadingStatus(state, value) {
    state.updateLoading = value;
  },

  setUpdateMessageLoading(state, value) {
    state.updateMessage = value;
  },

  resetEnrolmentState(state) {
    Object.assign(state, initState);
  },
};

export default {
  state,
  actions,
  mutations,
};
