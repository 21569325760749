<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"    
        :scroll="{ x: 1300 }"        
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal     
      :visible="showModal" 
      title="Update Enrolment By Age Junior Students" 
      @cancel="handleCancel">
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Update</a-button>
      </template>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="School Class"
            name="schoolClass"
          >
            <input type="text" class="form-control" :value="schoolClass" disabled>
          </a-form-item>          
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Gender"
            name="gender"
          >
            <input type="text" class="form-control" :value="gender" disabled>
          </a-form-item>          
        </a-col>
      </a-row>
      
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="Below Twelve Years"
            name="belowTwelveYears"
          >
            <input type="number" class="form-control" v-model="belowTwelveYears">
          </a-form-item>    
        </a-col>
        <a-col :span="11">        
          <a-form-item
            label="Twelve Years"
            name="TwelveYears"
          >
            <input type="number" class="form-control" v-model="twelveYears">
          </a-form-item>        
        </a-col>
      </a-row>

      <a-row justify="space-between">
        <a-col :span="11">            
          <a-form-item
            label="Thirteen Years"
            name="thirteenYears"
          >
            <input type="number" class="form-control" v-model="thirteenYears">
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Fourteen Years"
            name="FourteenYears"
          >
            <input type="number" class="form-control" v-model="fourteenYears">
          </a-form-item>                
        </a-col>
      </a-row>  

      <a-row justify="space-between">
        <a-col :span="24">
          <a-form-item
            label="Above Fourteen Years"
            name="aboveFourteenYears"
          >
            <input type="number" class="form-control" v-model="aboveFourteenYears">
          </a-form-item>           
        </a-col>
      </a-row>  
    </a-modal>
  </div>
</template>

<script>
import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { onMounted, ref } from 'vue'
import { mapActions, mapMutations, useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'EnrolmentByAgeJuniorTable',
  emits: ['viewEnrolmentInfo'],
  props: ['title', 'schoolId'],
  mixins: [enrolmentMixinVue], 
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
  },
  setup() {
    const store = useStore()
    const showModal = ref(false)
    const selectedObject = ref(null)
    const schoolClass = ref()
    const gender = ref()
    const belowTwelveYears = ref()
    const twelveYears = ref()
    const thirteenYears = ref()
    const fourteenYears = ref()
    const aboveFourteenYears = ref()

    const columns = [
      { title: 'School Class', dataIndex: 'schoolClass', fixed: 'left', width: 120 }, 
      { title: 'Gender', dataIndex: 'gender', fixed: 'left', width: 100 },
      { title: 'Below Twelve Years', dataIndex: 'belowTwelveYears', className: 'content-alignment' },
      { title: 'Twelve Years', dataIndex: 'twelveYears', className: 'content-alignment' },
      { title: 'Thirteen Years', dataIndex: 'thirteenYears', className: 'content-alignment' },    
      { title: 'Fourteen Years', dataIndex: 'fourteenYears', className: 'content-alignment' },    
      { title: 'Above Fourteen Years', dataIndex: 'aboveFourteenYears', className: 'content-alignment' },                  
      {
        title: 'operation',
        dataIndex: 'operation', 
        fixed: 'right',  
        width: 100   
      },
    ]

    return {    
      columns,   
      showModal,
      selectedObject,
      schoolClass,
      gender,
      belowTwelveYears,
      twelveYears,
      thirteenYears,
      fourteenYears,
      aboveFourteenYears,
    }
  },
  computed: {
    juniorEnrolmentData() {
      return this.$store.state.enrolment.juniorEnrolmentByAgeData
    },
    dataSource() {
      const dataItems = []      
      if (this.juniorEnrolmentData != null) {
        const data = Object.values(this.juniorEnrolmentData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolClass: element["schoolClass"],
                gender: element["gender"],                
                belowTwelveYears: element["belowTwelve12Year"],
                twelveYears: element["twelve12Year"],
                thirteenYears: element["thirteen13Year"],
                fourteenYears: element["fourteen14Year"],
                aboveFourteenYears: element["aboveFourteen14Year"]
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {
    ...mapActions(['updateRequest', 'fetchJuniorEnrolmentByAge']),
    ...mapMutations(['setJuniorEnrolmentByAgeData']),

    editRecord(key) {
      const detail = this.juniorEnrolmentData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolClass = detail.schoolClass
        this.gender = detail.gender
        this.belowTwelveYears = detail.belowTwelve12Year
        this.twelveYears = detail.twelve12Year
        this.thirteenYears = detail.thirteen13Year
        this.fourteenYears = detail.fourteen14Year
        this.aboveFourteenYears = detail.aboveFourteen14Year
      } 
    },

    handleSubmit() {
      const payload = {        
        endpoint: `questionaire/enrollment/section-c2/${this.selectedObject.id}`,
        body: {
          belowTwelve12Year: Number(this.belowTwelveYears),
          twelve12Year: Number(this.twelveYears),
          thirteen13Year: Number(this.thirteenYears),
          fourteen14Year: Number(this.fourteenYears),
          aboveFourteen14Year: Number(this.aboveFourteenYears),          
        }
      }
      this.updateRequest(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.belowTwelveYears = ''
            this.twelveYears = ''
            this.thirteenYears = '' 
            this.fourteenYears = ''
            this.aboveFourteenYears = ''
            
            const type = this.updateMessage.status
                        
            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });

            if (type === 'success') {              
              this.setJuniorEnrolmentByAgeData(null)
              this.fetchJuniorEnrolmentByAge()
            }
          }
        })  
    },

    handleCancel() {
      this.showModal = false
    },
  },
  mounted() {
    if(!this.schoolId) {
      this.$store.commit('setJuniorEnrolmentByAgeData', null)
      this.$store.dispatch('fetchJuniorEnrolmentByAge')
    } else {
      this.$store.commit('setJuniorEnrolmentByAgeData', null)
      this.$store.dispatch('fetchJuniorEnrolmentByAge', this.schoolId)
    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
