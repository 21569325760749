export const resetAll = (object) => {
  for (const item in object) {
    object[item] = false;
  }
  return object;
}

export const getNumberOfSeaterOrEmpty = (object) => {
  if (object !== undefined) {
    return object.numberOfSeater
  }
  return ''
}

export const getSeaterIdOrUndefined = (object) => {
  if (object !== undefined) {
    return object.id
  }
  return undefined
}


export const clearObjectData = (object) => {
  for (const item in object) {
    object[item] = ""
  }
  return object
}

export const range = (start, end) => {
  const length = end - start;
  return Array.from({ length }, (_, i) => start + i);
}

export const returnYesOrNo = (someBool) => {
  return (someBool) ? "YES" : "NO"
}

export const returnTrueOrFalse = (value) => {
  if (value === "YES") return true
  else if (value === "NO") return false
  else return value
}

export const replaceUnderscore = (str) => {
  return str.replaceAll("_", " ")
}

export const checkECCD = (record) => {
  return (record === null) ? "No ECCD" : record
}
