<template>
  <div class="graph-container d-flex flex-column bg-white radius-full mt-4 mb-2">
    <div class="d-flex justify-content-between">
      <span class="font-sm fw-bold">Enrolment rate for student with special needs</span>
      <a-select 
        :value="specialNeedEnrolmentOptions"
        style="width: 120px"
        placeholder="View by"
        size="small"
        :options="viewByOptions.map(option => ({value: option}))"
        @change="enrolmentRateChange"
      />
    </div>
    <canvas class="charts" id="special-needs-chart" height="300"></canvas>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Chart from "chart.js/auto";
import {Select} from "ant-design-vue"

export default {
  name: "SpecialNeedsChart",
  components: {"a-select": Select},
  setup(props, {emit}) {
    const specialNeedEnrolmentOptions = ref('')
    const store = useStore()
    const params = ref()
    const viewByOptions = ['Disability', 'School Class', 'Classification']
    const selectedOptions = ref('')
    const chartData = ref(null)
    const xAxisLabel = ref([])
    const dataSet = ref([])    
    const specialNeedsEnrolment = computed(() => store.state.analysis.specialNeedsEnrolment)

    const populateChart = () => {            
      dataSet.value = Object.values(specialNeedsEnrolment.value.data.map(item => item.enrolment))
      let checkSchoolClass = Object.values(specialNeedsEnrolment.value.data)[0].id.school_class
      let checkClassification = Object.values(specialNeedsEnrolment.value.data)[0].id.classification
      if (checkSchoolClass !== undefined) {
        specialNeedEnrolmentOptions.value = 'Scholl Class'
        xAxisLabel.value = Object.values(specialNeedsEnrolment.value.data.map(item => item.id.school_class))      
      } else if (checkClassification !== undefined) {
        specialNeedEnrolmentOptions.value = 'Classification'
        xAxisLabel.value = Object.values(specialNeedsEnrolment.value.data.map(item => item.id.classification))
      } else {
        specialNeedEnrolmentOptions.value = 'Disability'
        xAxisLabel.value = Object.values(specialNeedsEnrolment.value.data.map(item => item.id.disability))
      }
      
      chartData.value = {
        type: 'bar',
        data: {
          labels: xAxisLabel.value,
          datasets: [
            {
              label: "Special Needs",
              categoryPercentage: 0.3,
              borderRadius: 2,
              data: dataSet.value,
              backgroundColor: 'rgba(0, 242, 104, 1)',
            },
          ]
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        },
      }
    }
    
    watch(
      () => specialNeedsEnrolment.value,
      () => {        
        if (specialNeedsEnrolment.value !== null && chartData.value === null) {              
          emit('reload')
        }
      }
    )

    onMounted(() => {
      if (specialNeedsEnrolment.value === null) {
        store.dispatch('fetchSpecialNeedsEnrolment', params.value)
      } else {
        populateChart()
        const specialNeedsEnrolmentChart = document.getElementById('special-needs-chart')     
        new Chart(specialNeedsEnrolmentChart, chartData.value)     
      }
    }) 

    const enrolmentRateChange = (value) => {      
      const param = value.toLowerCase().replace(' ', '_')
      selectedOptions.value = param
      chartData.value = null
      store.dispatch('fetchSpecialNeedsEnrolment', param)
    }

    return {
      viewByOptions,
      selectedOptions,
      specialNeedEnrolmentOptions,
      enrolmentRateChange
    }
  }
}
</script>