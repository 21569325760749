<template>
  <div class="d-flex flex-column">
    <div v-if="false" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <form @submit.prevent="">
      <p class="fw-bold grey-accent mt-4 pt-2 mb-3">{{ operation }}</p>
      <div
          class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white shadow-sm"
      >
        <div class="d-flex flex-column px-4">
          <img
              :src="dummyAvatar"
              class="rounded-circle"
              width="100"
              height="100"
              alt=""
          />
          <span class="font-tiny grey-accent mt-1"
          ><i class="bi bi-pen-fill pb-1"></i> Change Photo</span
          >
        </div>
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> First name </label>
            <input
                type="text"
                class="form-control"
                placeholder="First name"
                required
            />
          </div>
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Last name </label>
            <input
                type="text"
                class="form-control"
                placeholder="Last name"
                required
            />
          </div>
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Middle name </label>
            <input
                type="text"
                class="form-control"
                placeholder="Middle name"
            />
          </div>        
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Gender </label>
            <select
                class="form-select border-0 text-dark"
                aria-label="Default select example"
            >
              <option class="bg-white" selected>Select gender</option>
              <option class="bg-white" value="male">Male</option>
              <option class="bg-white" value="female">Female</option>
            </select>
          </div>
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Local Government
            </label>
            <select
                class="form-select text-dark"
                aria-label="Default select example"
                required
            >
              <option class="bg-white" selected>Select organisation</option>
              <option
                  class="bg-white"
                  v-for="(lg, index) in lgs"
                  :key="index"
                  :value="lg.name"
              >
                {{ lg.name }}
              </option>
            </select>
          </div>
          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Nationality </label>
            <select
                class="form-select text-dark"
                aria-label="Default select example"
                disabled
            >
              <option class="bg-white" selected>Nigeria</option>
            </select>
          </div>
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Email </label>
            <input
                type="email"
                class="form-control"
                placeholder="Email Address"
                required
            />
          </div>
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Date of birth </label>
            <input type="date" class="form-control"/>
          </div>            
        </div>
      </div>

      <p class="fw-bold mb-3">Additional information</p>
      <div
          class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white shadow-sm"
      >
        <div class="row pb-4">
          
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Marital status </label>
            <select
                class="form-select text-dark"
                aria-label="Default select example"
            >
              <option class="bg-white" selected>Select marital status</option>
              <option class="bg-white" value="married">Married</option>
              <option class="bg-white" value="single">Single</option>
              <option class="bg-white" value="divorced">Divorced</option>
              <option class="bg-white" value="widow">Widow</option>
            </select>
          </div>
          
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Employment status </label>
            <select class="form-select">
              <option value="">Select employment status</option>
              <option value="employed (full-time)">Employed (Full-time)</option>
              <option value="employed (part-time)">Employed (Part-time)</option>
              <option value="self-employed">Self Employed</option>
              <option value="unemployed">UnEmployed</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Distance to learning centre </label>
            <select class="form-select">
              <option value="">Select distance to centre</option>
              <option value="close to work place">Close to Work Place</option>
              <option value="(1-2km) from home">(1-2km) From Home</option>
              <option value="(3-4km) from home">(3-4Km) From Home</option>
              <option value="above 4Km">Above 4Km</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Type of programme </label>
            <select class="form-select">
              <option value="">Select type of programme</option>
              <option value="basic literacy">Basic Literacy</option>
              <option value="post literacy">Post Literacy</option>
              <option value="vocational education">Vocational Education</option>
              <option value="continuing education">Continuing Education</option>
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Programme enrolled </label>
            <select class="form-select">
              <option value="">Select programme enrolled</option>
              <option value="literacy by radio">Literacy By Radio</option>
              <option value="NFE girl-child education">NFE Girl-Child Education</option>
              <option value="NFE out-of-school (boys education)">NFE Out-Of-School (Boys Education)</option>
              <option value="integrated qur'anic education">Integrated Qur'anic Education</option>
              <option value="workers education">Workers Education</option>
              <option value="normadic adult literacy">Normadic Adult Literacy</option>
              <option value="women in purdah">Women In Purdah</option>
              <option value="women education">Women Education</option>
              <option value="e-learning">E-Learning</option>              
              <option value="prison education">Prison Education</option>
              <option value="NFE out-of-school (girls education)">NFE Out-Of-School (Girls Education)</option>
              <option value="traditional class (mixed)">Traditional Class (Mixed)</option>              
              <option value="Others">Others</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Language preference for learning </label>
            <select class="form-select">
              <option value="">Select preffered language</option>
              <option value="english">English</option>
              <option value="mother tongue">Mother tongue</option>
              <option value="other">Other</option>
            </select>
          </div>          

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Has learner been examined in an enrolled programme
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="hasEnrolled" value="yes" id="yesEnrolled">
                  <label class="form-check-label font-sm" for="yesEnrolled">
                    Yes
                  </label>
                </div>
              </div>
              <div class="col-6 ps-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="hasEnrolled" value="no" id="notEnrolled">
                  <label class="form-check-label font-sm" for="notEnrolled">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Has learner passed an enrolled programme?
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="hasPassedAnEnrolledProgramme" value="yes" id="yesPassed">
                  <label class="form-check-label font-sm" for="yesPassed">
                    Yes
                  </label>
                </div>
              </div>
              <div class="col-6 ps-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="hasPassedAnEnrolledProgramme" value="no" id="notPassed">
                  <label class="form-check-label font-sm" for="notPassed">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>    

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Was learner present at the time of this visit
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="learnerPresentAtVisit" value="yes" id="learnerPresent">
                  <label class="form-check-label font-sm" for="learnerPresent">
                    Yes
                  </label>
                </div>
              </div>
              <div class="col-6 ps-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="learnerPresentAtVisit" value="no" id="learnerNotPresent">
                  <label class="form-check-label font-sm" for="learnerNotPresent">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <button v-if="edit" class="custom-btn font-tiny fw-bold" type="submit">
          Edit user
        </button>
        <button v-else class="custom-btn font-tiny fw-bold" type="submit">
          Create Learner
        </button>
      </div>
    </form>
    <modal v-if="showModal" @close="closeModal">
      <template v-slot:header> </template>
      <template v-slot:body> {{ message }} </template>
    </modal>
  </div>
</template>


<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import avatar from "@/assets/images/emptyAvatar.png";
import stateNLG from "@/helpers/utils/state_local.json";
import Modal from "@/helpers/utils/Modal.vue";
import { ref } from "@vue/runtime-core";

export default {
  name: "CreateLearner", 
  components: { ClipLoader, Modal },
  setup(props) {
    const operation = props.edit ? "Edit Learner" : "Create Learner";
    const state = Object.entries(stateNLG)[36];
    const lgs = Object.values(state[1].locals);
    const dummyAvatar = ref(avatar)
    const showModal = ref(false)
    const edit = ref(props.edit)

    return {
      operation,
      dummyAvatar,
      edit,
      lgs,
      showModal,
    }
  }
}
</script>

<style scoped>

</style>