import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import asc from './asc'
import school from './school'
import error from './error'
import questionnaireStages from './questionnaire/stages'
import questionnaire from './questionnaire/sections'
import questionnaireService from './questionnaire/services'
import enrolment from './questionnaire/enrolment'
import classroom from './questionnaire/classroom'
import nmec from './nmec/nmecStages'
import analysis from "./analysis";
import explore from "./explore";
import datatables from "./datatables";
import helper from "./helper";

const store = createStore({
  modules: {
    asc: asc,
    error: error,
    auth: auth,
    school: school,
    questionnaireStages: questionnaireStages,
    questionnaire: questionnaire,
    questionnaireService: questionnaireService,
    enrolment: enrolment,
    classroom: classroom,
    nmec: nmec,
    explore: explore,
    analysis: analysis,
    datatables: datatables,
    helper: helper
  },
  plugins: [createPersistedState()]
})

export default store