<template>
  <div>
    <p class="font-md fw-bold my-4">{{ currentStageTitle }}</p>
    <div
      class="d-flex justify-content-center mb-4"
      id="facilityRegistrationHeader"
    >
      <span
        v-for="index in stages.length"
        :key="index"
        :class="{ 'bg-contrast': stage + 1 >= index }"
        class="stage-indicator mx-1"
      ></span>
    </div>
    <div class="d-flex flex-column">
      <facility-registration
        :showTitle="showTitle"
        :schoolInfo="schooltoShow"
        :key="facKey"
        @reload="facKey++"
        v-if="currentStage === stages[0]"
      />

      <facility-type
        :showTitle="showTitle"
        :schoolInfo="schooltoShow"
        :key="facKey"
        @reload="facKey++"
        v-if="isPrivate && currentStage === stages[1]"
      />

      <power-source
        :showTitle="showTitle"
        :schoolInfo="schooltoShow"
        :key="facKey"
        @reload="facKey++"
        v-if="currentStage === stages[2]"
      />

      <fence-structure
        :showTitle="showTitle"
        :schoolInfo="schooltoShow"
        :key="facKey"
        @reload="facKey++"
        v-if="currentStage === stages[3]"
      />

      <health-facilities
        :showTitle="showTitle"
        :schoolInfo="schooltoShow"
        :key="facKey"
        @reload="facKey++"
        v-if="currentStage === stages[4]"
      />

      <e-c-c-d-facilities
        :showTitle="showTitle"
        :schoolInfo="schooltoShow"
        :key="facKey"
        @reload="facKey++"
        v-if="hasPrePrimaryOrPrimary && currentStage === stages[5]"
      />

      <seaters-registration
        :showTitle="showTitle"
        :schoolInfo="schooltoShow"
        :key="facKey"
        @reload="facKey++"
        class="seaters px-5"
        v-if="currentStage === stages[6]"
      />

      <toilets-registration
        :showTitle="showTitle"
        :schoolInfo="schooltoShow"
        :key="facKey"
        @reload="facKey++"
        class="toilet px-5 py-4"
        v-if="currentStage === stages[7]"
      />

      <div class="px-5 mb-3 negative-mt">
        <span
          v-if="stage !== 0"
          @click="gotoPreviousStage"
          class="d-flex align-items-center font-tiny dm-color fw-bold mx-2 float-start pointer-no-bg"
        >
          <i class="bi bi-caret-left-fill"></i>
          <span class="pb-1 ms-1"> Previous </span>
        </span>
        <span
          v-if="stage !== stages.length - 1"
          @click="gotoNextStage"
          class="d-flex align-items-center font-tiny dm-color fw-bold mx-2 float-end pointer-no-bg"
        >
          <span class="pb-1 me-1"> Next </span>
          <i class="bi bi-caret-right-fill"></i>
        </span>
      </div>
    </div>

    <div
      v-if="showTitle"
      class="d-flex flex-column align-items-center justify-content-center"
    >
      <span
        @click="$emit('saveFacilities')"
        class="font-sm dm-color fw-medium my-3 pointer-no-bg"
      >
        <strong>Continue</strong>
      </span>
    </div>

    <!-- <transition name="modal">
      <modal v-if="hasMessage" @close="closeModal">
        <template v-slot:body>
          <p>{{ message }}</p>
        </template>
        <template v-slot:footer>
          <button
              v-if="stage === stages.length - 1"
              class="btn btn-sm btn-success px-4" @click="continueToNextSection">
            Continue
          </button>
          <button class="btn btn-sm btn-success px-4" @click="closeModal">
            Close
          </button>
        </template>

      </modal>
    </transition> -->
  </div>
</template>

<script>
import { mapMutations, useStore } from "vuex";
import { computed, ref } from "@vue/runtime-core";
import _ from "lodash";
import Modal from "@/helpers/utils/Modal";
import FacilityRegistration from "@/components/dashboardPage/school/ASCDashboard/questionnaire/facilities/FacilityRegistration";
import SeatersRegistration from "@/components/dashboardPage/school/ASCDashboard/questionnaire/facilities/SeatersRegistration";
import ToiletsRegistration from "@/components/dashboardPage/school/ASCDashboard/questionnaire/facilities/ToiletsRegistration";
import PowerSource from "@/components/dashboardPage/school/ASCDashboard/questionnaire/facilities/registration/PowerSource";
import FenceStructure from "@/components/dashboardPage/school/ASCDashboard/questionnaire/facilities/registration/FenceStructure";
import HealthFacilities from "@/components/dashboardPage/school/ASCDashboard/questionnaire/facilities/registration/HealthFacilities";
import ECCDFacilities from "@/components/dashboardPage/school/ASCDashboard/questionnaire/facilities/registration/ECCDFacilities";
import FacilityType from "@/components/dashboardPage/school/ASCDashboard/questionnaire/facilities/registration/FacilityType";

export default {
  props: ["showTitle", "selectedSchoolInfo"],
  name: "Facilities",
  components: {
    FacilityType,
    ToiletsRegistration,
    SeatersRegistration,
    FacilityRegistration,
    Modal,
    ECCDFacilities,
    HealthFacilities,
    FenceStructure,
    PowerSource,
  },
  setup(props) {
    const store = useStore();
    const specials = [
      "vocation_training",
      "science_technology",
      "science_technology_vocational",
    ];
    let levels = [];
    let isScienceOrVocational = ref(false);
    let isPrivate = ref(false);
    // const schooltoShow = ref('')
    const hasPrePrimary = levels.includes("pre_primary");
    const hasPrimary = levels.includes("primary");
    const hasJSS = levels.includes("junior");
    const hasSSS = levels.includes("senior");
    const hasPrePrimaryOrPrimary = computed(() => hasPrimary || hasPrePrimary);
    const schoolToShowFacilities = props.selectedSchoolInfo
      ? props.selectedSchoolInfo
      : store.state.school.ownSchool;
    const schooltoShow = computed(() => schoolToShowFacilities);
    if (schoolToShowFacilities !== null) {
      levels = schoolToShowFacilities.levelsOffered.map((item) =>
        item.toLowerCase()
      );
      isScienceOrVocational.value = specials.includes(
        schoolToShowFacilities.schoolType.toLowerCase()
      );
      isPrivate.value = schoolToShowFacilities.classification === "PRIVATE";
    }

    return {
      schooltoShow,
      hasPrePrimary,
      hasPrimary,
      hasJSS,
      hasSSS,
      hasPrePrimaryOrPrimary,
      isPrivate,
      isScienceOrVocational,
    };
  },
  data() {
    const stages = [
      "facilities registration",
      "facility type",
      "power-source",
      "fence structure",
      "health facilities",
      "eccd facilities",
      "seater registration",
      "toilets registration",
    ];
    return {
      stages,
      stage: 0,
      facKey: 0,
      currentStage: "facilities registration",
      currentStageIndex: 0,
    };
  },
  computed: {
    message() {
      return this.$store.state.questionnaireService.message;
    },
    hasMessage() {
      return this.message !== null;
    },
    currentStageTitle() {
      return _.capitalize(this.currentStage);
    },
  },
  methods: {
    ...mapMutations(["setQAMessage", "setResponseCode"]),

    gotoNextStage() {
      this.stage = ++this.stage;
      if (
        (this.stage === 1 && !this.isPrivate) ||
        (this.stage === 5 && !this.hasPrePrimaryOrPrimary)
      ) {
        this.stage = ++this.stage;
      }
      this.currentStage = this.stages[this.stage];
    },
    gotoPreviousStage() {
      if (this.stage !== 0) {
        this.stage = --this.stage;
        if (
          (this.stage === 1 && !this.isPrivate) ||
          (this.stage === 5 && !this.hasPrePrimaryOrPrimary)
        ) {
          this.stage = --this.stage;
        }
        this.currentStage = this.stages[this.stage];
      }
    },
    closeModal() {
      this.setQAMessage(null);
      this.setResponseCode(null);
      this.workshopKey += 1;
    },

    continueToNextSection() {
      this.setQAMessage(null);
      this.setResponseCode(null);
      this.$emit("saveFacilities");
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
