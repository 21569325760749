<template>
  <div>

    <pre-primary-and-primary-birth-cert-section
        v-if="activeStage === prePrimaryStages[0]"
        @submissionDone="updateActiveStage"
    />

    <pre-primary-and-primary-enrolment-by-age
        v-if="activeStage === prePrimaryStages[1]"
        @submissionDone="updateActiveStage"
    />

    <new-entrants-primary-one
        v-if="activeStage === prePrimaryStages[2]"
        @submissionDone="updateActiveStage"
    />

    <enrolment-by-streams
        v-if="activeStage === prePrimaryStages[3]"
        @submissionDone="updateActiveStage"
    />

    <enrolment-by-age-for-class
        schoolClass="Primary 1"
        v-if="activeStage === prePrimaryStages[4]"
        @submissionDone="updateActiveStage"
    />

    <enrolment-by-age-for-class
        schoolClass="Primary 2"
        v-if="activeStage === prePrimaryStages[5]"
        @submissionDone="updateActiveStage"
    />

    <enrolment-by-age-for-class
        schoolClass="Primary 3"
        v-if="activeStage === prePrimaryStages[6]"
        @submissionDone="updateActiveStage"
    />

    <enrolment-by-age-for-class
        schoolClass="Primary 4"
        v-if="activeStage === prePrimaryStages[7]"
        @submissionDone="updateActiveStage"
    />

    <enrolment-by-age-for-class
        schoolClass="Primary 5"
        v-if="activeStage === prePrimaryStages[8]"
        @submissionDone="updateActiveStage"
    />

    <enrolment-by-age-for-class
        schoolClass="Primary 6"
        v-if="activeStage === prePrimaryStages[9]"
        @submissionDone="updateActiveStage"
    />

    <student-flow-by-class
        school-class="Primary 1"
        v-if="activeStage === prePrimaryStages[10]"
        @submissionDone="updateActiveStage"
    />

    <student-flow-by-class
        school-class="Primary 2"
        v-if="activeStage === prePrimaryStages[11]"
        @submissionDone="updateActiveStage"
    />

    <student-flow-by-class
        school-class="Primary 3"
        v-if="activeStage === prePrimaryStages[12]"
        @submissionDone="updateActiveStage"
    />

    <student-flow-by-class
        school-class="Primary 4"
        v-if="activeStage === prePrimaryStages[13]"
        @submissionDone="updateActiveStage"
    />

    <student-flow-by-class
        school-class="Primary 5"
        v-if="activeStage === prePrimaryStages[14]"
        @submissionDone="updateActiveStage"
    />

    <student-flow-by-class
        school-class="Primary 6"
        v-if="activeStage === prePrimaryStages[15]"
        @submissionDone="updateActiveStage"
    />

    <persons-with-special-needs-by-class
        school-class="ECCD" 
        v-if="activeStage === prePrimaryStages[16]"
        @submissionDone="updateActiveStage"
    />

    <persons-with-special-needs-by-class
        school-class="Nursery 2"
        v-if="activeStage === prePrimaryStages[17]"
        @submissionDone="updateActiveStage"
    />

    <persons-with-special-needs-by-class
        school-class="Nursery 3"
        v-if="activeStage === prePrimaryStages[18]"
        @submissionDone="updateActiveStage"
    />

    <persons-with-special-needs-by-class
        school-class="Primary 1"
        v-if="activeStage === prePrimaryStages[19]"
        @submissionDone="updateActiveStage"
    />

    <persons-with-special-needs-by-class
        school-class="Primary 2"
        v-if="activeStage === prePrimaryStages[20]"
        @submissionDone="updateActiveStage"
    />

    <persons-with-special-needs-by-class
        school-class="Primary 3"
        v-if="activeStage === prePrimaryStages[21]"
        @submissionDone="updateActiveStage"
    />

    <persons-with-special-needs-by-class
        school-class="Primary 4"
        v-if="activeStage === prePrimaryStages[22]"
        @submissionDone="updateActiveStage"
    />

    <persons-with-special-needs-by-class
        school-class="Primary 5"
        v-if="activeStage === prePrimaryStages[23]"
        @submissionDone="updateActiveStage"
    />

    <persons-with-special-needs-by-class
        school-class="Primary 6"
        v-if="activeStage === prePrimaryStages[24]"
        @submissionDone="updateActiveStage"
    />

    <number-of-orphans-by-grade
        v-if="activeStage === prePrimaryStages[25]"
        @orphanSubmissionDone="gotoNextSection"
    />

  </div>
</template>

<script>
import PrePrimaryAndPrimaryBirthCertSection
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/PrePrimaryAndPrimaryBirthCertSection";
import PrePrimaryAndPrimaryEnrolmentByAge
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/PrePrimaryAndPrimaryEnrolmentByAge";
import NewEntrantsPrimaryOne
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/NewEntrantsPrimaryOne";
import EnrolmentByStreams
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/EnrolmentByStreams";
import EnrolmentByAgeForClass
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/EnrolmentByAgeForClass";
import StudentFlowByClass
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/StudentFlowByClass";
import PersonsWithSpecialNeedsByClass
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/PersonsWithSpecialNeedsByClass";
import NumberOfOrphansByGrade
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/enrolment/prePrimaryAndPrimary/NumberOfOrphansByGrade";
import {mapMutations, useStore} from "vuex";
import {computed, onMounted} from "@vue/runtime-core";
export default {
  name: "PrePrimaryAndPrimary",
  components: {
    NumberOfOrphansByGrade,
    PersonsWithSpecialNeedsByClass,
    StudentFlowByClass,
    EnrolmentByAgeForClass,
    EnrolmentByStreams,
    NewEntrantsPrimaryOne,
    PrePrimaryAndPrimaryEnrolmentByAge,
    PrePrimaryAndPrimaryBirthCertSection
  },
  setup() {
    const store = useStore()
    const activeStageState = computed(() => store.state.questionnaireService.activeEnrolmentStage)
    const stages = [
        "birth cert", "enrolment by age", "new entrant", "enrolment by streams",
        "enrolment for pry 1", "enrolment for pry 2", "enrolment for pry 3",
        "enrolment for pry 4", "enrolment for pry 5", "enrolment for pry 6",
        "student flow pry 1", "student flow pry 2", "student flow pry 3",
        "student flow pry 4", "student flow pry 5", "student flow pry 6",
        "special needs eccd", "special needs nur 2", "special needs nur 3",
        "special needs pry 1", "special needs pry 2", "special needs pry 3",
        "special needs pry 4", "special needs pry 5", "special needs pry 6",
        "orphans by grade"
    ]  
    onMounted(() => {      
      console.log(activeStageState.value);
      if (activeStageState.value === null) {              
        store.commit('setActiveEnrolmentStage', stages[0])        
      }
    })
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const levels = schoolInfo.levelsOffered.map(item => item.toLowerCase())
    const hasJSS = levels.includes("junior")
    const hasSSS = levels.includes("senior")

    return {
      prePrimaryStages: stages,
      hasJSS,
      hasSSS
    }
  },
  computed: {
    activeStage() {
      return this.$store.state.questionnaireService.activeEnrolmentStage
    }
  },
  methods: {
    ...mapMutations(['setActiveEnrolmentStage']),
    updateActiveStage() {
      let newIndex = this.prePrimaryStages.indexOf(this.activeStage) + 1
      let newStage = this.prePrimaryStages[newIndex]
      this.setActiveEnrolmentStage(newStage)
    },

    gotoNextSection() {
      if (this.hasJSS) {
        this.setActiveEnrolmentStage("birth cert junior")
      } else if (this.hasSSS) {
        this.setActiveEnrolmentStage("birth cert senior")
      }
    }
  }
}
</script>

<style scoped>

</style>