<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">

      <clip-loader v-if="loading" class="loader-item mt-3"></clip-loader>

      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"                  
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal     
      :visible="showModal" 
      title="Update Enrolment By Age Junior Students" 
      @cancel="handleCancel">
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Update</a-button>
      </template>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="School Level"
            name="schoolLevelOption"
          >
            <input type="text" class="form-control" :value="schoolLevelOption" disabled>
          </a-form-item>          
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Gender"
            name="gender"
          >
            <input type="text" class="form-control" :value="gender" disabled>
          </a-form-item>          
        </a-col>
      </a-row>
      
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="Highest Qualification"
            name="highestQualification"
          >
            <input type="text" class="form-control" v-model="highestQualification">
          </a-form-item>    
        </a-col>
        <a-col :span="11">        
          <a-form-item
            label="Number"
            name="value"
          >
            <input type="number" class="form-control" v-model="value">
          </a-form-item>        
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>

function replaceUnderScore(str) {
  if (str !== undefined) return str.replaceAll("_", " ")
}

import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { onMounted, ref, computed } from 'vue'
import { mapActions, useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: 'TeacherQualificationViewAndUpdate',
  emits: ['viewEnrolmentInfo'],
  props: ['title'],
  mixins: [enrolmentMixinVue], 
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
    ClipLoader,
  },
  setup() {      
    const endpoint = 'auth/qualifications'
    const store = useStore()  
    const loading = computed(() => store.state.classroom.updateLoading)
    const message = computed(() => store.state.classroom.updateMessage)
    const showModal = ref(false)
    const selectedObject = ref(null)
    const schoolLevelOption = ref()
    const gender = ref()
    const highestQualification = ref()
    const value = ref()

    onMounted(() => {
      store.dispatch('fetchRequest', endpoint)
      .then(() => {
        if (message.value != null) {
          const type = message.value.status
          if (type === 'error') {
            notification.open({
              type: type,
              message: 'Update Message',
              description: message.value.message
            });   
          }
        }
      })
    })

    const columns = [
      { title: 'School Class', dataIndex: 'schoolLevelOption', fixed: 'left'}, 
      { title: 'Gender', dataIndex: 'gender', fixed: 'left'},
      { title: 'Highest Qualification', dataIndex: 'highestQualification', className: 'content-alignment' },
      { title: 'Number', dataIndex: 'value', className: 'content-alignment' },
      {
        title: 'operation',
        dataIndex: 'operation', 
        fixed: 'right',  
      },
    ]

    return {    
      endpoint,
      columns,  
      showModal,
      selectedObject,
      schoolLevelOption,
      gender,
      highestQualification,
      loading,
      value,
    }
  },
  computed: {
    classroomData() {
      return this.$store.state.classroom.classroomData
    },
    updateMessage() {
      return this.$store.state.classroom.updateMessage
    },
    dataSource() {
      const dataItems = []      
      if (this.classroomData != null) {
        const data = Object.values(this.classroomData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolLevelOption: replaceUnderScore(element["schoolLevelOption"]),
                gender: element["gender"],
                highestQualification: replaceUnderScore(element["highestQualification"]),
                value: element["value"],
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {

    ...mapActions(['updateSubmissionRequest', 'fetchRequest']),    

    editRecord(key) {
      const detail = this.classroomData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolLevelOption = detail.schoolLevelOption
        this.gender = detail.gender
        this.highestQualification = detail.highestQualification
        this.value = detail.value
      } 
    },

    handleSubmit() {
      const payload = {        
        endpoint: `auth/qualification-all`,
        body: [{
          highestQualification: this.highestQualification,
          value: Number(this.value),   
          id: Number(this.selectedObject.id)    
        }]
      }
      this.updateSubmissionRequest(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.highestQualification = ''
            this.value = ''
            
            const type = this.updateMessage.status

            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });            

            if (type === 'success') {              
              this.fetchRequest(this.endpoint)
            }                      
          }
        })  
    },

    handleCancel() {
      this.showModal = false
    },
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
