<template>
  <div>
    <progress-header
        :first-item="stages[0]"
        :second-item="stages[1]"
        :third-item="stages[2]"
        :fourth-item="stages[3]"
        :first-phase="phase.isAvailableClassrooms"
        :second-phase="phase.isClassroomRegistration"
        :third-phase="phase.isNonClassrooms"        
        :fourth-phase="phase.isWorkshops"
        :has-fourth-item="isScienceOrVocational"
    />

    <p class="font-md fw-bold mt-2 mb-4">Classroom</p>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">

      <available-classroom :key="availableClassroomKey" @reload="availableClassroomKey++" @gotoNext="gotoNextStage" v-if="isFirstStage" />

      <classroom-registration :key="classKey" @reload="classKey++" :has-pre-primary="hasPrePrimary" v-if="isSecondStage" />

      <non-classroom :key="nonClassroomKey" @reload="nonClassroomKey++" v-if="isThirdStage" />

      <type-of-workshop :key="workshopKey" @reload="workshopKey++" v-if="isFourthStage" />

      <div class="mb-3">
        <span v-if="stage !== 0" @click="gotoPreviousStage" class="d-flex align-items-center font-tiny dm-color fw-bold mx-2 float-start pointer-no-bg"> 
          <i class="bi bi-caret-left-fill"></i> 
          <span class="pb-1 ms-1"> Previous </span>
        </span>
        <span v-if="stage !== lastStage" @click="gotoNextStage" class="d-flex align-items-center font-tiny dm-color fw-bold mx-2 float-end pointer-no-bg"> 
          <span class="pb-1 me-1"> Next </span>
          <i class="bi bi-caret-right-fill"></i> 
        </span>
      </div>

    </div>

    <div class="d-flex flex-column align-items-center justify-content-center">
        <span
            @click="$emit('saveClassroom')"
            class="font-sm dm-color fw-medium my-3 pointer-no-bg"
        >
          <strong>Continue</strong>
        </span>
    </div>
  </div>
</template>

<script>
import {mapMutations, useStore} from "vuex";
import ProgressHeader from "@/components/includes/progressHeader";
import {computed, ref} from "@vue/runtime-core";
import AvailableClassroom
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/classroom/AvailableClassroom";
import ClassroomRegistration
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/classroom/ClassroomRegistration";
import NonClassroom from "@/components/dashboardPage/school/ASCDashboard/questionnaire/classroom/NonClassroom";
import TypeOfWorkshop from "@/components/dashboardPage/school/ASCDashboard/questionnaire/classroom/TypeOfWorkshop";

export default {
  name: "Classroom",
  components: {TypeOfWorkshop, NonClassroom, ClassroomRegistration, AvailableClassroom, ProgressHeader},
  setup() {
    const store = useStore()
    const otherRooms = ["Staff room", "Office", "Library", "Laboratory", "Store room", "Others"]
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const specials = ["vocation_training", "science_technology", "science_technology_vocational"]
    let levels = []
    let isScienceOrVocational = ref(false)
    if (schoolInfo !== null) {
      levels = schoolInfo.levelsOffered.map(item => item.toLowerCase())
      isScienceOrVocational.value = specials.includes(schoolInfo.schoolType.toLowerCase())
    }
    const hasPrePrimary = levels.includes("pre_primary")
    const lastStage = isScienceOrVocational.value ? 3 : 2

    const classroomInfo = {
      hasClassesOutside: "",
      numberOfNonClassrooms: "",
      numberOfPlayrooms: "",
      numberOfClassrooms: "",

      numberOfPlayroomsForECCD: "",      
      classNumber: "",
      yearOfConstruction: "",
      presentCondition: "",
      length: "",
      width: "",
      floorMaterial: "",
      roofMaterial: "",
      wallMaterial: "",
      hasBlackBoard: "",
      hasWritingBoard: "",
      hasEnoughSeats: "",
    }

    return {
      classroomInfo,
      otherRooms,
      hasPrePrimary,
      lastStage,
      isScienceOrVocational,
    }
  },
  data() {
    return {
      classKey: 0,
      workshopKey: 0,
      availableClassroomKey: 0,
      nonClassroomKey: 0,
      regKey: 0,
      stages: ["Available classrooms", "Classroom registration", "Non-classrooms", "Workshops"],
      stage: 0,
      currentStage: "Available classrooms",
      phase: {
        isAvailableClassrooms: true,
        isClassroomRegistration: false,
        isNonClassrooms: false,
        isWorkshops: false,
      },
    }
  },
  computed: {
    isFirstStage() {
      return this.currentStage === this.stages[0]
    },
    isSecondStage() {
      return this.currentStage === this.stages[1]
    },
    isThirdStage() {
      return this.currentStage === this.stages[2]
    },
    isFourthStage() {
      return this.currentStage === this.stages[3]
    },
    message() {
      return this.$store.state.questionnaireService.message
    },

    hasMessage() {
      return this.message !== null
    },
  },
  methods: {
    ...mapMutations(['setClassroomData', 'setQAMessage', 'setResponseCode']),

    gotoNextStage() {
      this.stage = ++this.stage
      if (this.stage === 1) this.phase.isClassroomRegistration = true
      if (this.stage === 2) this.phase.isNonClassrooms = true
      if (this.stage === 3) this.phase.isWorkshops = true
      this.currentStage = this.stages[this.stage]
    },

    gotoPreviousStage() {
      if (this.stage !== 0) {
        this.stage = --this.stage
        if (this.stage === 0) this.phase.isClassroomRegistration = false
        if (this.stage === 1) this.phase.isNonClassrooms = false
        if (this.stage === 2) this.phase.isWorkshops = false
        this.currentStage = this.stages[this.stage]
      }
    },

    closeModal() {
      this.setQAMessage(null)
      this.setResponseCode(null)
      this.workshopKey += 1
    },

    continueToNextSection() {
      this.setQAMessage(null)
      this.setResponseCode(null)
      this.$emit("saveClassroom");
    },

    saveAndAddNewNonClassroom() {},

    // saveClassroom() {
    //   this.setClassroomData(this.classroomInfo);
    //   this.$emit("saveClassroom");
    // }
  }
}
</script>

<style scoped>

</style>