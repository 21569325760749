const initState = {
    previousScreen: [],
    stages: {
        identificationDone: false,
        characteristicsDone: false,
        enrolmentDone: false,
        staffDone: false,
        classroomDone: false,
        facilitiesDone: false,
        subjectDone: false,
        bookDone: false,
        qualificationDone: false,
        familyDone: false,
        attestationDone: false
    },
}

const state = initState

const mutations = {
    setPreviousScreen(state, screen) {
        if (!state.previousScreen.includes(screen)) {
            state.previousScreen.push(screen)
        }
    },

    updatePrevious(state, newPrevScreens) {
        state.previousScreen = [...newPrevScreens]
    },

    setIdentificationDone(state, isDone) {
        state.stages.identificationDone = isDone
    },

    setCharacteristicsDone(state, isDone) {
        state.stages.characteristicsDone = isDone
    },

    setEnrolmentDone(state, isDone) {
        state.stages.enrolmentDone = isDone
    },

    setStaffDone(state, isDone) {
        state.stages.staffDone = isDone
    },

    setClassroomDone(state, isDone) {
        state.stages.classroomDone = isDone
    },

    setFacilitiesDone(state, isDone) {
        state.stages.facilitiesDone = isDone
    },

    setSubjectDone(state, isDone) {
        state.stages.subjectDone = isDone
    },

    setBookDone(state, isDone) {
        state.stages.bookDone = isDone
    },

    setQualificationDone(state, isDone) {
        state.stages.qualificationDone = isDone
    },

    setFamilyDone(state, isDone) {
        state.stages.familyDone = isDone
    },

    setAttestationDone(state, isDone) {
        state.stages.attestationDone = isDone
    },

    resetQuestionnaireStageState(state) {
        Object.assign(state, initState)
    }
    
}

export default { state, mutations }
