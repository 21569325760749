<template>
  <div>
    <section class="mb-5">
      <p class="font-sm fw-bold">How many <strong>MALE</strong> students were enrolled with birth certificate in Pre-primary and primary 1</p>
      <div class="d-flex mb-2">
        <div class="col-3"><p class="font-sm fw-bold">Organization</p></div>
        <span class="col me-1 text-center font-sm fw-normal">ECCD 1</span>
        <span class="col me-1 text-center font-sm fw-normal">ECCD 2</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 1</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 2</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 3</span>
        <span class="col me-1 text-center font-sm fw-normal">Primary 1</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">National Populations Commission</span></div>
        <div class="col px-0 me-1">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryMale.edc1MaleNPC" aria-label="enrolled with birth certificate female">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryMale.edc2MaleNPC" aria-label="enrolled with birth certificate female">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryMale.nursery1MaleNPC" aria-label="enrolled with birth certificate female">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryMale.nursery2MaleNPC" aria-label="enrolled with birth certificate female">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryMale.nursery3MaleNPC" aria-label="enrolled with birth certificate female">
        </div>
        <div class="col px-0">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryMale.primary1MaleNPC" aria-label="enrolled with birth certificate female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Others</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="birthCertPrePrimaryMale.edc1MaleOther" type="number" aria-label="Other female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="birthCertPrePrimaryMale.edc2MaleOther" type="number" aria-label="Other female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="birthCertPrePrimaryMale.nursery1MaleOther" type="number" aria-label="Other female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="birthCertPrePrimaryMale.nursery2MaleOther" type="number" aria-label="Other female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="birthCertPrePrimaryMale.nursery3MaleOther" type="number" aria-label="Other female">
        </div>
        <div class="col">
          <input class="form-control outline" v-model="birthCertPrePrimaryMale.primary1MaleOther" type="number" aria-label="Other female">
        </div>
      </div>
    </section>
    <section class="mb-5">
      <p class="font-sm fw-bold">How many <strong>FEMALE</strong> students were enrolled with birth certificate in Pre-primary and primary 1</p>
      <div class="d-flex mb-2">
        <div class="col-3"><p class="font-sm fw-bold">Organization</p></div>
        <span class="col me-1 text-center font-sm fw-normal">ECCD 1</span>
        <span class="col me-1 text-center font-sm fw-normal">ECCD 2</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 1</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 2</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 3</span>
        <span class="col me-1 text-center font-sm fw-normal">Primary 1</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">National Populations Commission</span></div>
        <div class="col px-0 me-1">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryFemale.edc1FemaleNPC" aria-label="enrolled with birth certificate female">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryFemale.edc2FemaleNPC" aria-label="enrolled with birth certificate female">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryFemale.nursery1FemaleNPC" aria-label="enrolled with birth certificate female">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryFemale.nursery2FemaleNPC" aria-label="enrolled with birth certificate female">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryFemale.nursery3FemaleNPC" aria-label="enrolled with birth certificate female">
        </div>
        <div class="col px-0">
          <input class="form-control outline" type="number" v-model="birthCertPrePrimaryFemale.primary1FemaleNPC" aria-label="enrolled with birth certificate female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Others</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="birthCertPrePrimaryFemale.edc1FemaleOther" type="number" aria-label="Other female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="birthCertPrePrimaryFemale.edc2FemaleOther" type="number" aria-label="Other female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="birthCertPrePrimaryFemale.nursery1FemaleOther" type="number" aria-label="Other female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="birthCertPrePrimaryFemale.nursery2FemaleOther" type="number" aria-label="Other female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="birthCertPrePrimaryFemale.nursery3FemaleOther" type="number" aria-label="Other female">
        </div>
        <div class="col">
          <input class="form-control outline" v-model="birthCertPrePrimaryFemale.primary1FemaleOther" type="number" aria-label="Other female">
        </div>
      </div>
    </section>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "PrePrimaryAndPrimaryBirthCertSection",
  data() {
    return {
      birthCertPrePrimaryMale: {
        edc1MaleNPC: "",
        edc2MaleNPC: "",
        nursery1MaleNPC: "",
        nursery2MaleNPC: "",
        nursery3MaleNPC: "",
        primary1MaleNPC: "",
        edc1MaleOthers: "",
        edc2MaleOthers: "",
        nursery1MaleOthers: "",
        nursery2MaleOthers: "",
        nursery3MaleOthers: "",
        primary1MaleOthers: "",
      },
      birthCertPrePrimaryFemale: {
        edc1FemaleNPC: "",
        edc2FemaleNPC: "",
        nursery1FemaleNPC: "",
        nursery2FemaleNPC: "",
        nursery3FemaleNPC: "",
        primary1FemaleNPC: "",
        edc1FemaleOthers: "",
        edc2FemaleOthers: "",
        nursery1FemaleOthers: "",
        nursery2FemaleOthers: "",
        nursery3FemaleOthers: "",
        primary1FemaleOthers: "",
      }
    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
    hasMessage() {
      return this.message !== null
    },
  },
  methods: {
    ...mapActions(['submitEnrolmentBirthCert']),

    async submitForm() {
      const birthCert = [
        {
          npopc: this.birthCertPrePrimaryMale.edc1MaleNPC,
          others: this.birthCertPrePrimaryMale.edc1MaleOther,
          gender: "MALE",
          schoolClass: "KG1"
        },
        {
          npopc: this.birthCertPrePrimaryFemale.edc2FemaleNPC,
          others: this.birthCertPrePrimaryFemale.edc2FemaleOther,
          gender: "FEMALE",
          schoolClass: "KG2"
        },
        {
          npopc: this.birthCertPrePrimaryMale.nursery1MaleNPC,
          others: this.birthCertPrePrimaryMale.nursery1MaleOther,
          gender: "MALE",
          schoolClass: "NURSERY1"
        },
        {
          npopc: this.birthCertPrePrimaryFemale.nursery1FemaleNPC,
          others: this.birthCertPrePrimaryFemale.nursery1FemaleOther,
          gender: "FEMALE",
          schoolClass: "NURSERY1"
        },
        {
          npopc: this.birthCertPrePrimaryMale.nursery2MaleNPC,
          others: this.birthCertPrePrimaryMale.nursery2MaleOther,
          gender: "MALE",
          schoolClass: "NURSERY2"
        },
        {
          npopc: this.birthCertPrePrimaryFemale.nursery2FemaleNPC,
          others: this.birthCertPrePrimaryFemale.nursery2FemaleOther,
          gender: "FEMALE",
          schoolClass: "NURSERY2"
        },
        {
          npopc: this.birthCertPrePrimaryMale.nursery3MaleNPC,
          others: this.birthCertPrePrimaryMale.nursery3MaleOther,
          gender: "MALE",
          schoolClass: "NURSERY3"
        },
        {
          npopc: this.birthCertPrePrimaryFemale.nursery3FemaleNPC,
          others: this.birthCertPrePrimaryFemale.nursery3FemaleOther,
          gender: "FEMALE",
          schoolClass: "NURSERY3"
        },
        {
          npopc: this.birthCertPrePrimaryMale.primary1MaleNPC,
          others: this.birthCertPrePrimaryMale.primary1MaleOther,
          gender: "MALE",
          schoolClass: "PRIMARY1"
        },
        {
          npopc: this.birthCertPrePrimaryFemale.primary1FemaleNPC,
          others: this.birthCertPrePrimaryFemale.primary1FemaleOther,
          gender: "FEMALE",
          schoolClass: "PRIMARY1"
        },
      ]
      const school = this.ownSchool.id

      const body = {
        requestBody: birthCert,
        school: school
      }

      await this.submitEnrolmentBirthCert(body)
      .then(() => {
        this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        })
        if (this.responseCode === 201) {
          this.$emit('submissionDone')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>