<template>
  <div>
    <section class="mb-5">
      <p class="fw-bold">Junior Secondary Enrolment by age for the current school year.</p>
      <div class="d-flex mb-2">
        <div class="col-6"></div>
        <span class="col-3 text-center font-sm fw-normal">Male</span>
        <span class="col-3 text-center font-sm fw-normal">Female</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">Below 12 years</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="jss1Male.belowTwelve12Year" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="jss1Female.belowTwelve12Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">12 years</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="jss1Male.twelve12Year" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="jss1Female.twelve12Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">13 years</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="jss1Male.thirteen13Year" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="jss1Female.thirteen13Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">14 years</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="jss1Male.fourteen14Year" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="jss1Female.fourteen14Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex">
        <div class="col-6"><span class="font-sm fw-normal">Above 14 years</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="jss1Male.aboveFourteen14Year" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="jss1Female.aboveFourteen14Year" type="number" aria-label="female">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "JuniorEnrolmentByAge",
  setup() {
    const jss1Male = {
      belowTwelve12Year: "",
      twelve12Year: "",
      thirteen13Year: "",
      fourteen14Year: "",
      aboveFourteen14Year: "",
      gender: "MALE",
      schoolClass: "JSS1"
    }
    const jss1Female = {
      belowTwelve12Year: "",
      twelve12Year: "",
      thirteen13Year: "",
      fourteen14Year: "",
      aboveFourteen14Year: "",
      gender: "FEMALE",
      schoolClass: "JSS1"
    }

    return {
      jss1Male,
      jss1Female
    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
    
  },
  methods: {
    ...mapActions(['submitJuniorEnrolmentByAgeCurrentYear']),

    async submitForm() {
      const body = {
        requestBody: [this.jss1Male, this.jss1Female],
        school: this.ownSchool.id
      }
      await this.submitJuniorEnrolmentByAgeCurrentYear(body)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>