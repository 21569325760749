<template>
  <div class="d-flex flex-column">
    <div class="graph-container d-flex flex-column bg-white radius-full mb-5">
      <p class="font-sm fw-bold">Historical Gross Parity Index</p>
      <canvas class="charts" id="historical-gpi-data-chart" height="300"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { computed, watch, onMounted, ref } from 'vue';
import { useStore } from 'vuex'

export default {
  name: "GPIHistoricalDataChart",
  emits: ['reload'],
  setup(props, {emit}) {
    const store = useStore();
    const historicalDataLineChartData = ref({})
    const gpiData = computed(() => store.state.analysis.gpiHistoricalData)
    const colors = ['rgba(0, 204, 242, 1)', 'rgba(113, 11, 121, 1)', 'rgba(249, 136, 16, 1)']

    if (gpiData.value === null) {
      store.dispatch('fetchGPIHistoricalDataChartData')      
    } else {
      const dataList = Object.values(gpiData.value.data)
      const xAxisLabels = dataList.map(data => Object.keys(data)).flat()
      const dataListValues = dataList.map(data => Object.values(data)).flat()      
      const firstDataValue = dataListValues[0]
      const groupKeys = Object.keys(firstDataValue)

      const dataset = [{
        label: 'GPI',
        tension: .2,
        fill: false,
        data: dataListValues.map(item => item[groupKeys[0]]),
        borderColor: colors[0],
      }]

      historicalDataLineChartData.value = {
        type: 'line',
        data: {
          labels: xAxisLabels,
          datasets: Array.from(dataset)
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        },
      }        
    }

    watch(
      () => gpiData.value,
      () => {
        if (gpiData.value !== null) {
          emit('reload')
        }
      }
    )

    onMounted(() => {
      const gpiHistoricalLineChart = document.getElementById("historical-gpi-data-chart")
      new Chart(gpiHistoricalLineChart, historicalDataLineChartData.value)
    })


  
    return {}
  },
}
</script>