<template>
  <div>
    <section class="mb-5">
      <p class="fw-bold">Junior Secondary Enrolment by age for the current school year</p>
      <div class="d-flex mb-2">
        <div class="col-6"></div>
        <span class="col-2 text-center font-sm fw-normal">JS1</span>
        <span class="col-2 text-center font-sm fw-normal">JS2</span>
        <span class="col-2 text-center font-sm fw-normal">JS3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">Number of streams</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="jss1Streams.numberOfStream" type="number" aria-label="male">
        </div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="jss2Streams.numberOfStream" type="number" aria-label="female">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="jss3Streams.numberOfStream" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex">
        <div class="col-6"><span class="font-sm fw-normal">Number of streams with multi-grade teaching</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="jss1Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="male">
        </div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="jss2Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="female">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="jss3Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="female">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "JuniorEnrolmentByStreams",
  setup() {
    const jss1Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "JSS1"
    }
    const jss2Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "JSS2"
    }
    const jss3Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "JSS3"
    }

    return {
      jss1Streams,
      jss2Streams,
      jss3Streams
    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitEnrolmentByStream']),

    async submitForm() {
      const body = {
        requestBody: [this.jss1Streams, this.jss2Streams, this.jss3Streams],
        school: this.ownSchool.id
      }
      await this.submitEnrolmentByStream(body)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>