<template>
  <div>
    <section class="mb-5">
      <p class="font-sm fw-bold">Number of orphans in by grade for <strong>MALE</strong> Pre-primary</p>
      <div class="d-flex mb-2">
        <div class="col-2"><p class="font-sm fw-bold">Type</p></div>
        <span class="col me-1 text-center font-sm fw-bold">KG 1</span>
        <span class="col me-1 text-center font-sm fw-bold">KG 2</span>
        <span class="col me-1 text-center font-sm fw-bold">Nur. 1</span>
        <span class="col me-1 text-center font-sm fw-bold">Nur. 2</span>
        <span class="col me-1 text-center font-sm fw-bold">Nur. 3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost mother</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg1OrphansMale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg2OrphansMale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur1OrphansMale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur2OrphansMale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur3OrphansMale.lostMother" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost father</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg1OrphansMale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg2OrphansMale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur1OrphansMale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur2OrphansMale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur3OrphansMale.lostFather" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost both</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg1OrphansMale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg2OrphansMale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur1OrphansMale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur2OrphansMale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur3OrphansMale.lostBothParent" type="text" aria-label="male">
        </div>
      </div>
    </section>

    <section class="mb-5">
      <p class="font-sm fw-bold">Number of orphans by grade for <strong>FEMALE</strong> Pre-primary</p>
      <div class="d-flex mb-2">
        <div class="col-2"><p class="font-sm fw-bold">Type</p></div>
        <span class="col me-1 text-center font-sm fw-bold">KG 1</span>
        <span class="col me-1 text-center font-sm fw-bold">KG 2</span>
        <span class="col me-1 text-center font-sm fw-bold">Nur. 1</span>
        <span class="col me-1 text-center font-sm fw-bold">Nur. 2</span>
        <span class="col me-1 text-center font-sm fw-bold">Nur. 3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost mother</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg1OrphansFemale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg2OrphansFemale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur1OrphansFemale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur2OrphansFemale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur3OrphansFemale.lostMother" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost father</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg1OrphansFemale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg2OrphansFemale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur1OrphansFemale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur2OrphansFemale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur3OrphansFemale.lostFather" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost both</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg1OrphansFemale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="kg2OrphansFemale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur1OrphansFemale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur2OrphansFemale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="nur3OrphansFemale.lostBothParent" type="text" aria-label="male">
        </div>
      </div>
    </section>

    <section class="mb-5">
      <p class="font-sm fw-bold">Number of orphans in by grade for <strong>MALE</strong> Primary</p>

      <div class="d-flex mb-2">
        <div class="col-2"><p class="font-sm fw-bold">Type</p></div>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 1</span>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 2</span>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 3</span>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 4</span>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 5</span>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 6</span>
      </div>

      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost mother</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry1OrphansMale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry2OrphansMale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry3OrphansMale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry4OrphansMale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry5OrphansMale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry6OrphansMale.lostMother" type="text" aria-label="male">
        </div>
      </div>

      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost father</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry1OrphansMale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry2OrphansMale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry3OrphansMale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry4OrphansMale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry5OrphansMale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry6OrphansMale.lostFather" type="text" aria-label="male">
        </div>
      </div>

      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost both</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry1OrphansMale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry2OrphansMale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry3OrphansMale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry4OrphansMale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry5OrphansMale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry6OrphansMale.lostBothParent" type="text" aria-label="male">
        </div>
      </div>

    </section>

    <section class="mb-5">
      <p class="font-sm fw-bold">Number of orphans in by grade for <strong>FEMALE</strong> Primary</p>

      <div class="d-flex mb-2">
        <div class="col-2"><p class="font-sm fw-bold">Type</p></div>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 1</span>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 2</span>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 3</span>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 4</span>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 5</span>
        <span class="col me-1 text-center font-sm fw-bold">Pry. 6</span>
      </div>

      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost mother</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry1OrphansFemale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry2OrphansFemale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry3OrphansFemale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry4OrphansFemale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry5OrphansFemale.lostMother" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry6OrphansFemale.lostMother" type="text" aria-label="male">
        </div>
      </div>

      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost father</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry1OrphansFemale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry2OrphansFemale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry3OrphansFemale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry4OrphansFemale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry5OrphansFemale.lostFather" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry6OrphansFemale.lostFather" type="text" aria-label="male">
        </div>
      </div>

      <div class="d-flex mb-3">
        <div class="col-2"><span class="font-sm fw-normal">Lost both</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry1OrphansFemale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry2OrphansFemale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry3OrphansFemale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry4OrphansFemale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry5OrphansFemale.lostBothParent" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="pry6OrphansFemale.lostBothParent" type="text" aria-label="male">
        </div>
      </div>

    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "NumberOfOrphansByGrade",
  setup() {
    const kg1OrphansMale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "MALE",
      schoolClass: "KG1"
    }
    const kg1OrphansFemale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "FEMALE",
      schoolClass: "KG1"
    }
    const kg2OrphansMale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "MALE",
      schoolClass: "KG2"
    }
    const kg2OrphansFemale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "FEMALE",
      schoolClass: "KG2"
    }
    const nur1OrphansMale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "MALE",
      schoolClass: "NURSERY1"
    }
    const nur1OrphansFemale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "FEMALE",
      schoolClass: "NURSERY1"
    }
    const nur2OrphansMale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "MALE",
      schoolClass: "NURSERY2"
    }
    const nur2OrphansFemale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "FEMALE",
      schoolClass: "NURSERY2"
    }
    const nur3OrphansMale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "MALE",
      schoolClass: "NURSERY3"
    }
    const nur3OrphansFemale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "FEMALE",
      schoolClass: "NURSERY3"
    }
    const pry1OrphansMale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "MALE",
      schoolClass: "PRIMARY1"
    }
    const pry1OrphansFemale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "FEMALE",
      schoolClass: "PRIMARY1"
    }
    const pry2OrphansMale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "MALE",
      schoolClass: "PRIMARY2"
    }
    const pry2OrphansFemale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "FEMALE",
      schoolClass: "PRIMARY2"
    }
    const pry3OrphansMale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "MALE",
      schoolClass: "PRIMARY3"
    }
    const pry3OrphansFemale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "FEMALE",
      schoolClass: "PRIMARY3"
    }
    const pry4OrphansMale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "MALE",
      schoolClass: "PRIMARY4"
    }
    const pry4OrphansFemale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "FEMALE",
      schoolClass: "PRIMARY4"
    }
    const pry5OrphansMale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "MALE",
      schoolClass: "PRIMARY5"
    }
    const pry5OrphansFemale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "FEMALE",
      schoolClass: "PRIMARY5"
    }
    const pry6OrphansMale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "MALE",
      schoolClass: "PRIMARY6"
    }
    const pry6OrphansFemale = {
      lostMother: "",
      lostFather: "",
      lostBothParent: "",
      gender: "FEMALE",
      schoolClass: "PRIMARY6"
    }

    return {
      kg1OrphansMale,
      kg1OrphansFemale,
      kg2OrphansMale,
      kg2OrphansFemale,
      nur1OrphansMale,
      nur1OrphansFemale,
      nur2OrphansMale,
      nur2OrphansFemale,
      nur3OrphansMale,
      nur3OrphansFemale,
      pry1OrphansMale,
      pry1OrphansFemale,
      pry2OrphansMale,
      pry2OrphansFemale,
      pry3OrphansMale,
      pry3OrphansFemale,
      pry4OrphansMale,
      pry4OrphansFemale,
      pry5OrphansMale,
      pry5OrphansFemale,
      pry6OrphansMale,
      pry6OrphansFemale
    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitEnrolmentNumberOfOrphans']),

    async submitForm() {
      const body = {
        requestBody: [
          this.kg1OrphansMale, this.kg1OrphansFemale, this.kg2OrphansMale, this.kg2OrphansFemale,
          this.nur2OrphansMale, this.nur2OrphansFemale, this.nur3OrphansMale, this.nur3OrphansFemale,
          this.pry1OrphansMale, this.pry1OrphansFemale, this.pry2OrphansMale, this.pry2OrphansFemale,
          this.pry3OrphansMale, this.pry3OrphansFemale, this.pry4OrphansMale, this.pry4OrphansFemale,
          this.pry5OrphansMale, this.pry5OrphansFemale, this.pry6OrphansMale, this.pry6OrphansFemale
        ],
        school: this.ownSchool.id
      }

      await this.submitEnrolmentNumberOfOrphans(body)
          .then(() => {

            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 201) {
              this.$emit('orphanSubmissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>