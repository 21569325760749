<template>
  <div class="d-flex flex-column">
    <quality-charts-by-level :level="level" />
  </div>
</template>

<script>
import QualityChartsByLevel from "@/components/dashboardPage/main/quality/QualityChartsByLevel";
import { ref } from 'vue'

export default {
  name: "Quality",
  components: {QualityChartsByLevel},
  setup() {
    const level = ref("pre-primary")
    const isActive = ref('pre-primary')
    return {
      level,
      isActive      
    }
  }
}
</script>

<style scoped>
  .bordered-header {
    border-bottom: 1px solid #efefef;
  }
  .bordered-header-active {
    border-bottom: 1px solid #0000FF !important;
  }
  .is-good {
    background: hsla(124, 98%, 35%, 0.09) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: green;  
  }
  .is-poor {
    background: hsla(16, 98%, 35%, 0.09) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: #B33102;  
    
  }
  .facility-container {
    max-height: 340px;
    overflow-y: scroll;
  }
  .full-height {
    height: 80%;
  }

  .title-height {
    height: 20%;
  }
</style>
