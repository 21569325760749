<template>
  <div class="px-5">
    <p class="font-md fw-bold mt-2 mb-4">Vocation Education Programmes</p>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <section class="second">        
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Vocation education programme
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter vocation education programme"            
            />
          </div>

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Number of facilitators in specific vocational programmes
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Male"                
                />
              </div>
              <div class="col-6 ps-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Female"                
                />
              </div>
            </div>
          </div>

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Number of apprentices
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Male"                
                />
              </div>
              <div class="col-6 ps-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Female"                
                />
              </div>
            </div>
          </div>

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Number of equipment available for specific vocational programmes
            </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter number of equipment"
            />
          </div>

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Number of completers
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Male"                
                />
              </div>
              <div class="col-6 ps-3">
                <input
                  type="number"
                  class="form-control"
                  placeholder="Female"                
                />
              </div>
            </div>
          </div>      

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Number of functional equipment
            </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter number of functional equipment"
            />
          </div>    

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number required </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter required number"
            />
          </div>
        </div>          
      </section>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <input
          type="submit"
          class="custom-btn font-tiny fw-bold"
          value="Continue"
          @click="$emit('continue')"
      />
      <span class="font-sm fw-normal dm-color my-3 pointer-no-bg">
        Save and continue later
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "VocationEducation"
}
</script>

<style scoped>

</style>