<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Repeaters in public primary school by Lga, Class and Gender</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-tiny" aria-label="Users table">
        <thead>
        <tr>
          <th class="align-middle py-3 ps-4"></th>
          <th class="align-middle py-3" colspan="2">
            Class 1
          </th>
          <th class="align-middle py-3" colspan="2">
            Class 2
          </th>
          <th class="align-middle py-3" colspan="2">
            Class 3
          </th>
          <th class="align-middle py-3" colspan="2">
            Class 4
          </th>
          <th class="align-middle py-3" colspan="2">
            Class 5
          </th>
          <th class="align-middle py-3" colspan="2">
            Class 6
          </th>
        </tr>
        <tr>
          <th class="align-middle py-3 ps-4 table-cell">LGA</th>
          <th class="align-middle py-3 cell-size" scope="col">Boys</th>
          <th class="align-middle py-3 cell-size" scope="col">Girls</th>
          <th class="align-middle py-3 cell-size" scope="col">Boys</th>
          <th class="align-middle py-3 cell-size" scope="col">Girls</th>
          <th class="align-middle py-3 cell-size" scope="col">Boys</th>
          <th class="align-middle py-3 cell-size" scope="col">Girls</th>
          <th class="align-middle py-3 cell-size" scope="col">Boys</th>
          <th class="align-middle py-3 cell-size" scope="col">Girls</th>
          <th class="align-middle py-3 cell-size" scope="col">Boys</th>
          <th class="align-middle py-3 cell-size" scope="col">Girls</th>
          <th class="align-middle py-3 cell-size" scope="col">Boys</th>
          <th class="align-middle py-3 cell-size" scope="col">Girls</th>
        </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg"
            v-for="(data, index) in tableData"
            :key="index"
          >
            <td class="py-3 ps-4 first-cell">{{ data.lga }}</td>
            <td class="py-3 cell-size">{{ data.class_1_m }}</td>
            <td class="py-3 cell-size">{{ data.class_1_f }}</td>
            <td class="py-3 cell-size">{{ data.class_2_m }}</td>
            <td class="py-3 cell-size">{{ data.class_2_f }}</td>
            <td class="py-3 cell-size">{{ data.class_3_m }}</td>
            <td class="py-3 cell-size">{{ data.class_3_f }}</td>
            <td class="py-3 cell-size">{{ data.class_4_m }}</td>
            <td class="py-3 cell-size">{{ data.class_4_f }}</td>
            <td class="py-3 cell-size">{{ data.class_5_m }}</td>
            <td class="py-3 cell-size">{{ data.class_5_f }}</td>
            <td class="py-3 cell-size">{{ data.class_6_m }}</td>
            <td class="py-3 cell-size">{{ data.class_6_f }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="13" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "RepeaterPublicPrimarySch",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 67, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })
 
    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
.first-cell {
  width: 10%;
}
.cell-size {
  width: 7.5%;
}
</style>