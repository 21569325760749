const Roles = {
  'super_admin': 'ROLE_SUPER_ADMIN',
  'moe_admin': 'ROLE_MOE_ADMIN',
  'smoe_admin': 'ROLE_SMOE_ADMIN',
  'subeb_admin': 'ROLE_SUBEB_ADMIN',
  'same_admin': 'ROLE_SAME_ADMIN',
  'smora_admin': 'ROLE_SMORA_ADMIN',
  'adsmeb_admin': 'ROLE_ADSMEB_ADMIN',
  'supervisor': 'ROLE_SUPERVISOR',
  'smoe_prs': 'ROLE_SMOE_PRS',
  'subeb_prs': 'ROLE_SUBEB_PRS',
  'same_prs': 'ROLE_SAME_PRS',
  'smora_prs': 'ROLE_SMORA_PRS',
  'adsmeb_prs': 'ROLE_ADSMEB_PRS',
  'smoe_emis_lga_officer': 'ROLE_SMOE_EMIS_LGA_OFFICER',
  'smoe_subeb_lga_officer': 'ROLE_SMOE_SUBEB_LGA_OFFICER',
  'smoe_emis_state_officer': 'ROLE_SMOE_EMIS_STATE_OFFICER',
  'smoe_subeb_state_officer': 'ROLE_SMOE_SUBEB_STATE_OFFICER',
  'sbmc': 'ROLE_SBMC',
  'enumerator': 'ROLE_ENUMERATOR',
  'principal': 'ROLE_PRINCIPAL',
  'head_teacher': 'ROLE_HEADTEACHER'
}

export default Roles