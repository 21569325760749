<template>
  <div class="d-flex flex-column">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div class="d-flex justify-content-between align-items-center my-4">
      <span class="font-normal fw-bold">All Qualifications</span>
    </div>

    <div class="d-flex radius-half bg-white shadow-sm p-3 mb-3">
      <div class="col-6 d-flex flex-column pe-3">
        <span class="font-tiny fw-bold mb-2">What are you looking for?</span>
        <div class="custom-input-group bg-accent">
          <span class="p-1"><i class="bi bi-search grey-shade"></i></span>
          <input
              type="text"
              class="ph-sm no-outline-input font-tiny fw-bolder"
              placeholder="Search"
              v-model="searchField"
          />
        </div>
      </div>
      <div class="col-3 d-flex flex-column pe-2">
        <span class="font-tiny fw-bold mb-2">Filter</span>
        <div>
          <select
              class="form-select text-dark"
              aria-label="Default select example"
              v-model="searchFilter"
          >
            <option class="bg-white grey-shade" selected>All</option>
            <option class="bg-white" value="gender">Gender</option>
            <option class="bg-white" value="qualification">Qualification</option>
            <option class="bg-white" value="level">Level</option>
            <option class="bg-white" value="numberOfHolders">Number of Holders</option>
          </select>
        </div>
      </div>
      <div class="col-3 d-flex flex-column ps-2">
        <span class="font-tiny fw-bold mb-2">Sort</span>
        <div>
          <select
              class="form-select text-dark"
              aria-label="Default select example"
              v-model="sortOption"
          >
            <option class="bg-white grey-shade" selected>All</option>
            <option class="bg-white" value="gender">Gender</option>
            <option class="bg-white" value="qualification">Qualification</option>
            <option class="bg-white" value="level">Level</option>
            <option class="bg-white" value="value">Number of Holders</option>
          </select>
        </div>
      </div>
    </div>

    <div class="bg-white radius-full shadow-sm mb-5">
      <table class="table table-hover font-sm" aria-label="qualification table">
        <thead>
        <tr>
          <th class="align-middle py-3 ps-4" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Gender</span>
              <div class="vertical-icons ms-2">
                <i @click="genderArrowUpClicked" class="bi bi-caret-up-fill font-tiny mt-1"></i>
                <i @click="genderArrowDownClicked" class="bi bi-caret-down-fill font-tiny nm-t-small"></i>
              </div>
            </div>
          </th>
          <th class="align-middle py-3" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Highest Qualification</span>
              <div class="vertical-icons ms-2">
                <i @click="qualificationUpClicked" class="bi bi-caret-up-fill font-tiny mt-1"></i>
                <i @click="qualificationDownClicked" class="bi bi-caret-down-fill font-tiny nm-t-small"></i>
              </div>
            </div>
          </th>
          <th class="align-middle py-3" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>School Level</span>
              <div class="vertical-icons ms-2">
                <i @click="levelArrowUpClicked" class="bi bi-caret-up-fill font-tiny mt-1"></i>
                <i @click="levelArrowDownClicked" class="bi bi-caret-down-fill font-tiny nm-t-small"></i>
              </div>
            </div>
          </th>
          <th class="align-middle py-3" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span>Number Of Holders</span>
              <div class="vertical-icons ms-2">
                <i @click="numberOfHoldersUpClicked" class="bi bi-caret-up-fill font-tiny mt-1"></i>
                <i @click="numberOfHoldersDownClicked" class="bi bi-caret-down-fill font-tiny nm-t-small"></i>
              </div>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            class="pointer-no-bg"
            v-for="(qualification, index) in filteredQualifications"
            :key="index"
        >
          <td class="align-middle py-3 ps-4">{{ qualification.gender }}</td>
          <td class="py-3">{{ qualification.highestQualification.replaceAll("_", " ") }}</td>
          <td class="py-3">{{ qualification.schoolLevelOption.replaceAll("_", " ") }}</td>
          <td class="py-3">{{ qualification.value }}</td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end align-items-center font-sm pb-3 px-4">
        <span>Rows per page:
          <select v-model="rowCount" v-on:change="onNumRowChanged($event)" class="outline-clear" id="row">
            <option value="12">12</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>

        <span class="font-sm ms-3">1 to {{rowCount}} of {{totalRecord}}
          <span
              @click="fetchPrev"
              class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_back_ios
          </span>
          <span
              @click="fetchNext"
              class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_forward_ios
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader";
import {mapActions, mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import defineAbilityFor from "@/authorities";
import {onMounted} from "vue";
import {resetAll} from "@/helpers/utils/helper_functions";

export default {
  name: "TeachersQualificationTable",
  components: { ClipLoader },
  props: ['id'],
  setup(props) {
    const store = useStore();
    const userMe = computed(() => store.getters.loginUser).value;
    const ability = defineAbilityFor(userMe);

    onMounted(() => {
      store.dispatch('fetchSchoolTeachersQualification', {id: props.id})
    })

    return {
      ability,
      schoolId: props.id,
    }
  },
  data() {
    return {
      searchField: "",
      searchFilter: "",
      sortOption: "",
      arrowsState: {
        genderArrowUp: false,
        genderArrowDown: false,
        qualificationUp: false,
        qualificationDown: false,
        levelArrowUp: false,
        levelArrowDown: false,
        numberOfHoldersUp: false,
        numberOfHoldersDown: false,
      }
    };
  },
  computed: {
    loading() {
      return this.$store.state.school.loading
    },

    rowCount() {
      return this.$store.state.school.numberOfRows
    },

    qualificationsData() {
      return this.$store.state.school.schoolTeachersQualification
    },

    highestQualifications() {
      if (this.qualificationsData !== null) {
        return this.qualificationsData.content
      }
      return []
    },

    totalRecord() {
      if (this.qualificationsData !== null) {
        return this.qualificationsData.totalElements
      }
      return 0
    },

    currentPage() {
      if (this.qualificationsData !== null) {
        return this.qualificationsData.pageNumber + 1
      }
      return 0
    },

    previousPage() {
      if (this.qualificationsData !== null) {
        return this.qualificationsData.pageNumber - 1
      }
      return 0
    },

    filteredQualifications() {
      const { highestQualifications, searchField, sortOption, sortQualifications } = this
      const {
        genderArrowUp, genderArrowDown, qualificationUp, qualificationDown,
        levelArrowUp, levelArrowDown, numberOfHoldersUp, numberOfHoldersDown,
      } = this.arrowsState

      if (genderArrowUp) {
        sortQualifications("gender")
      }
      if (genderArrowDown) {
        sortQualifications("gender", "DSC")
      }
      if (qualificationUp) {
        sortQualifications("qualification")
      }
      if (qualificationDown) {
        sortQualifications("qualification", "DSC")
      }
      if (levelArrowUp) {
        sortQualifications("level")
      }
      if (levelArrowDown) {
        sortQualifications("level", "DSC")
      }
      if (numberOfHoldersUp) {
        sortQualifications("value")
      }
      if (numberOfHoldersDown) {
        sortQualifications("value", "DSC")
      }

      sortQualifications(sortOption)

      if (highestQualifications !== undefined && highestQualifications.length > 0) {
        return highestQualifications.filter(qualification => this.filterQualificationsBy(qualification).toLowerCase().includes(searchField.toLowerCase()))
      } else
        return []
    },
  },
  methods: {
    ...mapActions(['fetchSchoolTeachersQualification']),
    ...mapMutations(['setNumRowsQualifications']),

    filterQualificationsBy(qualification) {
      const {searchFilter} = this
      if (searchFilter === "" || searchFilter === "gender") {
        return qualification.gender
      }
      else if (searchFilter === "qualification") {
        return qualification.highestQualification
      }
      else if (searchFilter === "level") {
        return qualification.schoolLevelOption
      }
      else {
        return qualification.value
      }
    },

    sortQualifications(sortFilter, order = "ASC") {
      const {highestQualifications} = this
      if (sortFilter) {
        switch (sortFilter) {
          case "gender":
            return highestQualifications.sort((qualification1, qualification2) => {
              if (order === "ASC") {
                return (qualification1.gender > qualification2.gender) ? 1 : ((qualification2.gender > qualification1.gender) ? -1 : 0)
              }
              return (qualification2.gender > qualification1.gender) ? 1 : ((qualification1.gender > qualification2.gender) ? -1 : 0)
            })

          case "qualification":
            return highestQualifications.sort((qualification1, qualification2) => {
              if (order === "ASC") {
                return (qualification1.highestQualification > qualification2.highestQualification) ? 1 : ((qualification2.highestQualification > qualification1.highestQualification) ? -1 : 0)
              }
              return (qualification2.highestQualification > qualification1.highestQualification) ? 1 : ((qualification1.highestQualification > qualification2.highestQualification) ? -1 : 0)
            })

          case "level":
            return highestQualifications.sort((qualification1, qualification2) => {
              if (order === "ASC") {
                return (qualification1.schoolLevelOption > qualification2.schoolLevelOption) ? 1 : ((qualification2.schoolLevelOption > qualification1.schoolLevelOption) ? -1 : 0)
              }
              return (qualification2.schoolLevelOption > qualification1.schoolLevelOption) ? 1 : ((qualification1.schoolLevelOption > qualification2.schoolLevelOption) ? -1 : 0)
            })

          case "numberOfHolders":
            return highestQualifications.sort((qualification1, qualification2) => {
              if (order === "ASC") {
                return (qualification1.value > qualification2.value) ? 1 : ((qualification2.value > qualification1.value) ? -1 : 0)
              }
              return (qualification2.value > qualification1.value) ? 1 : ((qualification1.value > qualification2.value) ? -1 : 0)
            })
        }
      }
    },

    genderArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.genderArrowUp = true
    },

    genderArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.genderArrowDown = true
    },

    qualificationUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.qualificationUp = true
    },

    qualificationDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.qualificationDown = true
    },

    levelArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.levelArrowUp = true
    },

    levelArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.levelArrowDown = true
    },

    numberOfHoldersUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.numberOfHoldersUp = true
    },

    numberOfHoldersDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.numberOfHoldersDown = true
    },

    onNumRowChanged(event) {
      const number = event.target.value
      console.log("I'm now going to fetch " + number + " users.");
      this.setNumRowsQualifications(number)
      this.fetchSchoolTeachersQualification({id: this.schoolId})
    },

    fetchNext() {
      if (this.qualificationsData.last === false) {
        this.fetchSchoolTeachersQualification({id: this.schoolId, nextPage: this.currentPage})
      }
    },

    fetchPrev() {
      if (this.qualificationsData.first === false) {
        this.fetchSchoolTeachersQualification({id: this.schoolId, nextPage: this.previousPage})
      }
    },
  },
}
</script>

<style scoped>

</style>