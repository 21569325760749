<template>
  <div class="bg-accent pb-5">
    <div class="container">
      <home-nav-bar></home-nav-bar>
      <div class="d-flex flex-column">
        <div
          class="d-flex flex-column align-items-center col-10 col-lg-6 mx-auto my-5"
        >
          <span class="dm-color fw-bold font-sm mt-2 mb-1">ABOUT US</span>
          <h5 class="fw-bold mb-3 text-center">
            Empowering Education with Data-Driven Solutions
          </h5>
          <p class="text-center font-sm mb-5">
            The Education Management Information System (EMIS) project,
            supported by UNICEF, is dedicated to transforming the landscape of
            education data management in Nigeria, focusing initially on Borno,
            Adamawa, and Yobe states. Established as part of a strategic
            initiative to improve educational outcomes, EMIS is designed to
            create a seamless, efficient, and accurate system for managing
            education data.
          </p>
          <div>
            <img class="img-fluid" :src="ViewImage" alt="About us image" />
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="col-12 col-lg-6 d-flex flex-column p-4">
            <h4 class="font-sm fw-bold">The Mission</h4>
            <p class="font-sm fw-light lh-lg mt-1">
              To build a responsive, and web-based education data management
              system that enables better decision-making and enhances
              educational outcomes in Nigeria.
            </p>
          </div>
          <div class="col-12 col-lg-6 d-flex flex-column p-4">
            <h4 class="font-sm fw-bold">The Vission</h4>

            <p class="font-sm fw-light lh-lg mt-1">
              To become a benchmark for excellence in education data management,
              ensuring that every decision made in the education sector is
              backed by reliable and actionable data.
            </p>
          </div>
        </div>
        <div>
          <h3 class="font-sm fw-bold text-center mb-4">Key Features</h3>
          <div class="d-flex flex-wrap features gap-3">
            <div class="d-flex justify-content-between">
              <p class="font-sm fw-bold desc-left">
                Centralized Data Management:
              </p>
              <p class="text-left desc">
                A unified platform for managing all education-related data,
                ensuring consistency and reducing fragmentation.
              </p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="font-sm fw-bold desc-left">Real-Time Data Access:</p>
              <p class="text-left desc">
                Providing stakeholders with immediate access to current data for
                timely interventions and informed decision-making.
              </p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="font-sm fw-bold desc-left">Enhanced Data Analysis:</p>
              <p class="text-left desc">
                A unified platform for managing all education-related data,
                ensuring consistency and reducing fragmentation.
              </p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="font-sm fw-bold desc-left">Real-Time Data Access:</p>
              <p class="text-left desc">
                Tracking detailed metrics to enhance transparency and
                accountability within the education sector.
              </p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="font-sm fw-bold desc-left">
                Scalability and Flexibility:
              </p>
              <p class="text-left desc">
                A decentralized system that meets the specific needs of each
                state while providing a comprehensive national overview.
              </p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="font-sm fw-bold desc-left">User-Friendly Interface:</p>
              <p class="text-left desc">
                A responsive design accessible via mobile devices and tablets,
                making the system easy to use for everyone.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeNavBar from "../../components/includes/NavBar";
import ViewImage from "@/assets/images/about.svg";

export default {
  name: "About",
  components: {
    HomeNavBar,
  },
  setup() {
    return {
      ViewImage,
    };
  },
};
</script>
<style scoped>
.features {
  width: 50%;
  margin: 0 auto;
}
.desc {
  width: 400px;
  flex: 1;
}
.item-desc {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 50px;
}
.desc-left {
  width: 200px;
}
</style>
