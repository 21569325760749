<template>
  <div>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"            
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="viewFacilityDetails(record.key)">
                View
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Table, Button, Space } from "ant-design-vue"
import { ref } from 'vue'

export default {
  name: 'FacilitiesTable',
  emits: ['viewFacilityDetails'],
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,
  },
  setup(props, {emit}) {
    const columns = [
      { title: 'Submission Title', dataIndex: 'title', width: "75%" },    
      {
        title: 'operation',
        dataIndex: 'operation',        
      },
    ]

    const dataSource = ref([
      {
        key: '0',
        title: 'Facility Registration',
      },
      {
        key: '1',
        title: 'Facility Type ',
      },
      {
        key: '2',
        title: 'Power Source',
      },
      {
        key: '3',
        title: 'Health Facilities',
      },
      {
        key: '4',
        title: 'ECCD Facilities',
      },
      {
        key: '5',
        title: 'Seaters Registration',
      },
      {
        key: '6',
        title: 'Toilet Registration',
      },
    ])

    const viewFacilityDetails = (key) => {
      emit('viewFacilityInfo', key)
    }

    return {
      columns,
      dataSource,     
      viewFacilityDetails,    
    }
  },
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>