<template>
  <div>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="data"            
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, column }">
          <div style="padding: 8px">
            <a-input 
              ref="searchInput"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
            />       
          </div>
        </template>
        <template #filterIcon="filtered">
          <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #operation="{ record }" >
          <a-popconfirm 
            v-if="data.length"
            title="View submissions"
            placement="rightTop"
            @confirm="viewSchoolSubmissions(record.key)"
          >
            <template #icon=""><span></span></template>
            <a>View</a>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Table, Input, Popconfirm } from "ant-design-vue"
import { SearchOutlined } from "@ant-design/icons-vue"
import { useStore } from 'vuex'
import { onMounted } from '@vue/runtime-dom'
import { reactive } from "vue"

export default {
  name: "SchoolsSubmittedQuestionairesTable",
  emits: ['viewSubmittedQuestionaire'],
  components: {
    'a-table': Table,
    'a-input': Input,
    'a-popconfirm': Popconfirm,  
    SearchOutlined,
  },
  setup(props, {emit}) {
    const store = useStore()
    onMounted(() => {
      store.dispatch('fetchSubmittedQuestionsForOfficers')
    })

    // const searchInput = ref()
    const state = reactive({
      searchText: '',
      searchedColumn: '',
    })

    const columns = [
      { title: 'Institution name', dataIndex: 'institutionName', width: "50%" },
      { 
        title: 'LGA', 
        dataIndex: 'lga', 
        width: "30%",
        slots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender',
        },
        onFilter: (value, record) => record.lga.toString().toLowerCase().includes(value.toLowerCase()),        
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
      },
    ]

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm()
      state.searchText = selectedKeys[0]
      state.searchedColumn = dataIndex
    }

    const viewSchoolSubmissions = (key) => {
      emit('viewSubmittedQuestionaire', key)          
    }

    return {
      columns,
      viewSchoolSubmissions,
      handleSearch,
    }
  },
  computed: {
    data() {
      const data = this.$store.state.questionnaireService.submittedQuestionaires
      if (data == null) {
        return []
      } else {
        return data.map( row => ({
          key: row.institutionId,
          institutionName: row.institutionName,
          lga: row.lga,
        }))
      }

    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>