<template>
  <div>
    <p class="font-md fw-bold mt-2 mb-4">Family Life</p>
    <div class="bg-white radius-full py-4 px-5 my-4">
      <p class="font-sm fw-bold mx-3 mb-3">
        FamilyLife registration (by school level) in current academic class.
      </p>
      <div class="mt-3 mb-4 mx-3">
        <label class="form-label font-sm fw-normal">
          Please select school level
        </label>
        <select
          class="form-select"
          required
          aria-label="Default select example"
          @change="levelChanged($event)"
          v-model="schoolLevelOption"
        >
          <option value="">Select level</option>
          <option value="PRE_PRIMARY">Pre-Primary</option>
          <option value="PRIMARY">Primary</option>
          <option value="JUNIOR">Junior</option>
          <option value="SENIOR">Senior</option>
        </select>
      </div>
    </div>
    <div class="d-flex flex-column bg-white radius-full py-3 px-5 my-4">
      <a-collapse v-model:activeKey="activeKey" :bordered="false" accordion>
        <template #expandIcon="{ isActive }">
          <caret-right-outlined :rotate="isActive ? 90 : 0" />
        </template>

        <a-collapse-panel
          :style="customStyle"
          key="1"
          header="School Rules, Regulations and Guidelines."
        >
          <section class="mb-4 pt-4 mx-3">
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 mb-2 pe-4">
                <span class="font-sm fw-normal"
                  >Has your school communicated information about the rules,
                  regulations and guidelines to relevant stakeholders such as
                  pupils, parents, teachers etc?
                </span>
              </div>
              <div class="col pe-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    name="communication"
                    v-model="communicationQuestion.answer"
                    id="yes-communicated-rules"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="yes-communicated-rules"
                  >
                    Yes
                  </label>
                </div>
              </div>
              <div class="col ps-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    name="communication"
                    v-model="communicationQuestion.answer"
                    id="no-communicated-rules"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="no-communicated-rules"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <p class="font-sm fw-medium">
              Do the rules, regulations and guidelines in your school cover the
              following aspects of FLHE?
            </p>
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 mb-2 pe-4">
                <span class="font-sm fw-normal">Physical Safety in School</span>
              </div>
              <div class="col pe-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    name="safety"
                    v-model="safetyQuestion.answer"
                    id="yes-physical-safety"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="yes-physical-safety"
                  >
                    Yes
                  </label>
                </div>
              </div>
              <div class="col ps-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    name="safety"
                    v-model="safetyQuestion.answer"
                    id="no-physical-safety"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="no-physical-safety"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 mb-2 pe-4">
                <span class="font-sm fw-normal"
                  >Stigma and discrimination towards staff or pupils/living
                  with/affected by HIV, or based on sex, race or ethnicity,
                  religion or any other grounds, sexual harassment and abuse
                </span>
              </div>
              <div class="col pe-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    v-model="stigmaQuestion.answer"
                    name="stigma"
                    id="yes-discrimination"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="yes-discrimination"
                  >
                    Yes
                  </label>
                </div>
              </div>
              <div class="col ps-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    v-model="stigmaQuestion.answer"
                    name="stigma"
                    id="no-discrimination"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="no-discrimination"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 mb-2 pe-4">
                <span class="font-sm fw-normal"
                  >Grievance/disciplinary procedures in case of breach of the
                  regualtions described in the rules and guidelines.
                </span>
              </div>
              <div class="col pe-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    v-model="grievanceQuestion.answer"
                    name="grievance"
                    id="yes-grievance"
                  />
                  <label class="form-check-label font-sm" for="yes-grievance">
                    Yes
                  </label>
                </div>
              </div>
              <div class="col ps-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    v-model="grievanceQuestion.answer"
                    name="grievance"
                    id="no-grievance"
                  />
                  <label class="form-check-label font-sm" for="no-grievance">
                    No
                  </label>
                </div>
              </div>
            </div>
          </section>
          <div class="d-flex justify-content-center align-items-center">
            <button
              class="custom-btn font-tiny fw-bold"
              @click="submitSchoolRules"
            >
              {{ isFetched ? "Update" : "Save" }}
            </button>
          </div>
        </a-collapse-panel>

        <a-collapse-panel
          :style="customStyle"
          key="4"
          header="Orientation process for parents or guardians of pupils."
        >
          <section class="mb-4 mx-3">
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 pe-4">
                <span class="font-sm fw-normal"
                  >How many times has your school organized orientation program
                  for parents or guardians of pupils regarding FLHE programs in
                  the previous academic year?
                </span>
              </div>
              <div class="col me-3">
                <input
                  class="form-control outline"
                  type="number"
                  v-model="answerNumber"
                  placeholder="Enter number of programs"
                  required
                />
              </div>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 pe-4">
                <span class="font-sm fw-normal"
                  >At what forums were the orientation programs provided?
                </span>
              </div>
              <div class="col me-3">
                <div>
                  <input
                    type="text"
                    class="form-control outline"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    placeholder="Select forums"
                    required
                    :value="forumType"
                  />
                  <div
                    class="dropdown-menu bg-accent shadow-sm w-100"
                    aria-labelledby="featuresDropdown"
                  >
                    <div class="d-flex flex-column w-100">
                      <div class="pe-2">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="facility-type"
                            v-model="forum"
                            value="PTA"
                            id="owned"
                          />
                          <label class="form-check-label" for="owned">
                            PTA
                          </label>
                        </div>
                      </div>
                      <div class="pe-2">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="facility-type"
                            v-model="forum"
                            value="OPEN_DAY"
                            id="rented"
                          />
                          <label class="form-check-label" for="rented">
                            Open day
                          </label>
                        </div>
                      </div>
                      <div class="pe-2">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="facility-type"
                            v-model="forum"
                            value="SPECIAL_SESSION"
                            id="granted-free"
                          />
                          <label class="form-check-label" for="granted-free">
                            Special session
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 pe-4">
                <span class="font-sm fw-normal"
                  >When was the last orientation program on FLHE conducted?
                </span>
              </div>
              <div class="col me-3">
                <input
                  class="form-control outline"
                  type="date"
                  v-model="dateOfLastOrientation"
                  placeholder="Select date"
                  aria-label="date"
                  required
                />
              </div>
            </div>
          </section>
          <div class="d-flex justify-content-center align-items-center">
            <button
              class="custom-btn font-tiny fw-bold"
              @click="submitOrientation"
            >
              {{ isFetched ? "Update" : "Save" }}
            </button>
          </div>
        </a-collapse-panel>

        <a-collapse-panel
          :style="customStyle"
          key="2"
          header="Life Skills-based Family Life HIV/AIDS Education (FLHE)."
        >
          <section class="mb-4 mx-3">
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 pe-4">
                <span class="font-sm fw-normal"
                  >Did students of your school receive any form of life
                  skills-based Family Life Health Education (FLHE) in the
                  previous academic year?
                </span>
              </div>
              <div class="col pe-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    v-model="lifeSkillQuestionOne.answer"
                    name="q1"
                    id="yes-received-skill"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="yes-received-skill"
                  >
                    Yes
                  </label>
                </div>
              </div>
              <div class="col ps-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    v-model="lifeSkillQuestionOne.answer"
                    name="q1"
                    id="no-received-skill"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="no-received-skill"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </section>
          <section class="mb-4 mx-3">
            <p class="font-sm fw-bold">
              If yes, indicate which of these topics were covered in the FLHE
              program.
            </p>
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 pe-4">
                <span class="font-sm fw-normal"
                  >Teaching on generic life skills (e.g. decision-making,
                  communication, refusal skills)
                </span>
              </div>
              <div class="col pe-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    v-model="lifeSkillQuestionTwo.answer"
                    name="q2"
                    id="yes-general-life-skill"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="yes-general-life-skill"
                  >
                    Yes
                  </label>
                </div>
              </div>
              <div class="col ps-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    v-model="lifeSkillQuestionTwo.answer"
                    name="q2"
                    id="no-general-life-skill"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="no-general-life-skill"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 pe-4">
                <span class="font-sm fw-normal"
                  >Teaching on reproductive health/FLHE (e.g. teaching on human
                  growth and development, family life, sexual abuse and
                  transmission of STIs).
                </span>
              </div>
              <div class="col pe-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    v-model="lifeSkillQuestionThree.answer"
                    name="q3"
                    id="yes-reproductive-health"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="yes-reproductive-health"
                  >
                    Yes
                  </label>
                </div>
              </div>
              <div class="col ps-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    v-model="lifeSkillQuestionThree.answer"
                    name="q3"
                    id="no-reproductive-health"
                  />
                  <label
                    class="form-check-label font-sm"
                    for="no-reproductive-health"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 pe-4">
                <span class="font-sm fw-normal"
                  >Teaching on HIV transmission and prevention
                </span>
              </div>
              <div class="col pe-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="true"
                    v-model="lifeSkillQuestionFour.answer"
                    name="q4"
                    id="yes-hiv/aid"
                  />
                  <label class="form-check-label font-sm" for="yes-hiv/aid">
                    Yes
                  </label>
                </div>
              </div>
              <div class="col ps-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    value="false"
                    v-model="lifeSkillQuestionFour.answer"
                    name="q4"
                    id="no-hiv/aid"
                  />
                  <label class="form-check-label font-sm" for="no-hiv/aid">
                    No
                  </label>
                </div>
              </div>
            </div>
          </section>
          <div class="d-flex justify-content-center align-items-center">
            <button
              class="custom-btn font-tiny fw-bold"
              @click="submitFamilyLifeEducation"
            >
              {{ isFetched ? "Update" : "Save" }}
            </button>
          </div>
        </a-collapse-panel>

        <a-collapse-panel
          :style="customStyle"
          key="5"
          header="Life skills-based FLHE: Students Participation and Teacher training and teaching."
        >
          <section class="mb-4 mx-3">
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 pe-4">
                <span class="font-sm fw-normal"
                  >Number of students that received/particated in Life
                  Skills-based Family Live HIV/AIDS Education (FLHE) in the
                  previous year?
                </span>
              </div>
              <div class="col me-3">
                <input
                  class="form-control outline"
                  type="number"
                  v-model="genderQuestionOneMale.answerNumber"
                  placeholder="Male"
                  aria-label="male"
                />
              </div>
              <div class="col">
                <input
                  class="form-control outline"
                  type="number"
                  v-model="genderQuestionOneFemale.answerNumber"
                  placeholder="Female"
                  aria-label="female"
                />
              </div>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 pe-4">
                <span class="font-sm fw-normal"
                  >How many teachers in your school received formal training on
                  FLHE?
                </span>
              </div>
              <div class="col me-3">
                <input
                  class="form-control outline"
                  type="number"
                  v-model="genderQuestionTwoMale.answerNumber"
                  placeholder="Male"
                  aria-label="male"
                />
              </div>
              <div class="col">
                <input
                  class="form-control outline"
                  type="number"
                  v-model="genderQuestionTwoFemale.answerNumber"
                  placeholder="Female"
                  aria-label="female"
                />
              </div>
            </div>
            <div class="d-flex align-items-center mb-2">
              <div class="col-7 pe-4">
                <span class="font-sm fw-normal"
                  >How many teachers in your school who received formal training
                  in the previous year also taught related topics on FLHE?
                </span>
              </div>
              <div class="col me-3">
                <input
                  class="form-control outline"
                  type="number"
                  v-model="genderQuestionThreeMale.answerNumber"
                  placeholder="Male"
                  aria-label="male"
                />
              </div>
              <div class="col">
                <input
                  class="form-control outline"
                  type="number"
                  v-model="genderQuestionThreeFemale.answerNumber"
                  placeholder="Female"
                  aria-label="female"
                />
              </div>
            </div>
          </section>
          <div class="d-flex justify-content-center align-items-center">
            <button
              class="custom-btn font-tiny fw-bold"
              @click="submitLifeSkillsByGender"
            >
              {{ isFetched ? "Update" : "Save" }}
            </button>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <span
        @click="$emit('saveFamily')"
        class="font-sm dm-color fw-medium my-3 pointer-no-bg"
      >
        <strong>Continue</strong>
      </span>
    </div>

    <!-- <transition name="modal">
      <modal v-if="hasMessage" @close="closeModal">
        <template v-slot:body>
          <p>{{ message }}</p>
        </template>
      </modal>
    </transition> -->
  </div>
</template>

<script>
import { Collapse, CollapsePanel } from "ant-design-vue";
import { CaretRightOutlined } from "@ant-design/icons-vue";
import { ref, watch } from "vue";
import { computed } from "@vue/runtime-core";
import { mapActions, mapMutations, useStore } from "vuex";
import lodash from "lodash";
import Modal from "@/helpers/utils/Modal";

export default {
  name: "FamilyLife",
  components: {
    "a-collapse": Collapse,
    "a-collapse-panel": CollapsePanel,
    CaretRightOutlined,
    Modal,
  },
  setup() {
    const store = useStore();
    const activeKey = ref(0);
    const schoolInfo = computed(() => store.state.school.ownSchool).value;
    const customStyle =
      "background: #ffffff; border-radius: 4px; margin-bottom: 10px; border: none;";
    const answerNumber = ref("");
    const forum = ref("");
    const forumType = ref("");
    const dateOfLastOrientation = ref("");
    const isFetched = ref(false);
    const schoolLevelOption = ref("");

    const communicationQuestion = {
      flheQuestion: "Q1",
      flheQuestionType: "COMMUNICATION",
      answer: "",
    };

    const safetyQuestion = {
      flheQuestion: "Q2",
      flheQuestionType: "SRAG",
      answer: "",
    };

    const stigmaQuestion = {
      flheQuestion: "Q3",
      flheQuestionType: "SRAG",
      answer: "",
    };

    const grievanceQuestion = {
      flheQuestion: "Q4",
      flheQuestionType: "SRAG",
      answer: "",
    };

    const lifeSkillQuestionOne = {
      flheQuestion: "Q5",
      flheQuestionType: "LSBFHAE",
      answer: "",
    };

    const lifeSkillQuestionTwo = {
      flheQuestion: "Q6",
      flheQuestionType: "LSBFHAE_FOLLOWUP_QUESTION",
      answer: "",
    };

    const lifeSkillQuestionThree = {
      flheQuestion: "Q7",
      flheQuestionType: "LSBFHAE_FOLLOWUP_QUESTION",
      answer: "",
    };

    const lifeSkillQuestionFour = {
      flheQuestion: "Q8",
      flheQuestionType: "LSBFHAE_FOLLOWUP_QUESTION",
      answer: "",
    };

    const genderQuestionOneMale = {
      flheQuestionTypeGender: "Type3",
      flheQuestionGenderOption: "Q1",
      answerNumber: "",
      gender: "MALE",
    };

    const genderQuestionOneFemale = {
      flheQuestionTypeGender: "Type3",
      flheQuestionGenderOption: "Q1",
      answerNumber: "",
      gender: "FEMALE",
    };

    const genderQuestionTwoMale = {
      flheQuestionTypeGender: "Type1",
      flheQuestionGenderOption: "Q2",
      answerNumber: "",
      gender: "MALE",
    };
    const genderQuestionTwoFemale = {
      flheQuestionTypeGender: "Type1",
      flheQuestionGenderOption: "Q2",
      answerNumber: "",
      gender: "FEMALE",
    };

    const genderQuestionThreeMale = {
      flheQuestionTypeGender: "Type2",
      flheQuestionGenderOption: "Q3",
      answerNumber: "",
      gender: "MALE",
    };
    const genderQuestionThreeFemale = {
      flheQuestionTypeGender: "Type2",
      flheQuestionGenderOption: "Q3",
      answerNumber: "",
      gender: "FEMALE",
    };

    watch(forum, () => {
      forumType.value = lodash.upperCase(forum.value);
    });

    return {
      activeKey,
      customStyle,
      schoolInfo,
      answerNumber,
      forum,
      dateOfLastOrientation,
      schoolLevelOption,
      forumType,
      safetyQuestion,
      stigmaQuestion,
      grievanceQuestion,
      communicationQuestion,
      lifeSkillQuestionOne,
      lifeSkillQuestionTwo,
      lifeSkillQuestionThree,
      lifeSkillQuestionFour,
      genderQuestionOneMale,
      genderQuestionOneFemale,
      genderQuestionTwoMale,
      genderQuestionTwoFemale,
      genderQuestionThreeMale,
      genderQuestionThreeFemale,
      isFetched,
    };
  },
  computed: {
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage;
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message;
      }
      return "";
    },
    hasMessage() {
      return this.message !== null;
    },
  },
  methods: {
    ...mapMutations(["setQAMessage", "setResponseCode"]),
    ...mapActions([
      "submitFLHEOrientation",
      "submitFLHEQuestion",
      "submitFLHEGenderQuestion",
      "fetchFacilityRegistration",
    ]),

    async submitSchoolRules() {
      const questions = [
        this.communicationQuestion,
        this.safetyQuestion,
        this.stigmaQuestion,
        this.grievanceQuestion,
      ];

      questions.map(
        (question) => (question["schoolLevelOption"] = this.schoolLevelOption)
      );
      questions.map((question) => (question["school"] = this.schoolInfo.id));

      const requestObject = {
        id: this.schoolInfo.id,
        body: questions,
      };

      await this.submitFLHEQuestion(requestObject).then(() => {
        this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        });
      });
    },

    async submitFamilyLifeEducation() {
      const questions = [
        this.lifeSkillQuestionOne,
        this.lifeSkillQuestionTwo,
        this.lifeSkillQuestionThree,
        this.lifeSkillQuestionFour,
      ];

      questions.map(
        (question) => (question["schoolLevelOption"] = this.schoolLevelOption)
      );
      questions.map((question) => (question["school"] = this.schoolInfo.id));

      const requestObject = {
        id: this.schoolInfo.id,
        body: questions,
      };

      await this.submitFLHEQuestion(requestObject).then(() => {
        this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        });
      });
    },

    async submitOrientation() {
      if (
        this.answerNumber === "" ||
        this.forumType === "" ||
        this.dateOfLastOrientation === ""
      ) {
        this.setQAMessage("All fields are required");
      } else {
        const body = {
          answerNumber: this.answerNumber,
          forum: this.forumType.replaceAll(" ", "_"),
          dateOfLastOrientation: this.dateOfLastOrientation,
          flheQuestionOrientation: "Q1",
          schoolLevelOption: this.schoolLevelOption,
          school: this.schoolInfo.id,
        };

        const requestObject = {
          id: this.schoolInfo.id,
          body: body,
        };
        await this.submitFLHEOrientation(requestObject).then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          });
        });
      }
    },

    async submitLifeSkillsByGender() {
      const questions = [
        this.genderQuestionOneMale,
        this.genderQuestionOneFemale,
        this.genderQuestionTwoMale,
        this.genderQuestionTwoFemale,
        this.genderQuestionThreeMale,
        this.genderQuestionThreeFemale,
      ];

      questions.map(
        (question) => (question["schoolLevelOption"] = this.schoolLevelOption)
      );
      questions.map((question) => (question["school"] = this.schoolInfo.id));

      const requestObject = {
        id: this.schoolInfo.id,
        body: questions,
      };

      await this.submitFLHEGenderQuestion(requestObject).then(() => {
        this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        });
      });
    },

    async levelChanged(event) {
      const requestObject = {
        id: this.schoolInfo.id,
        level: event.target.value,
      };

      console.log(requestObject);
      await this.fetchFacilityRegistration(requestObject).then(() => {
        this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        });
        if (this.responseCode === 200) {
          this.$emit("reload");
        }
      });
    },

    closeModal() {
      this.setQAMessage(null);
      this.setResponseCode(null);
    },
  },
};
</script>
