<template>
  <div>
    <p class="font-md fw-bold my-4">Enrolment</p>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <div class="d-flex flex-column justify-content-between px-2">
        <pre-primary-and-primary v-if="isRegularSchoolAndHasPrimary" />
        <junior-sec-school v-if="hasJSS" />
        <senior-sec-school v-if="isRegularSchool && hasSSS" />
        <nab-teb-category v-if="!isRegularSchool" />
      </div>
      <!-- <div v-if="activeStage === null">
        <p>Please click continue to go to the Staff section.</p>
      </div> -->
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <span
        @click="$emit('saveEnrolment')"
        class="font-sm dm-color my-3 fw-medium pointer-no-bg"
      >
        <strong>Continue</strong>
      </span>
    </div>
  </div>
</template>

<script>
import {mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import JuniorSecSchool from "@/components/dashboardPage/school/ASCDashboard/questionnaire/levels/JuniorSecSchool";
import SeniorSecSchool from "@/components/dashboardPage/school/ASCDashboard/questionnaire/levels/SeniorSecSchool";
import NabTebCategory from "@/components/dashboardPage/school/ASCDashboard/questionnaire/levels/NabTebCategory";
import dataCollectionMixin from "@/helpers/mixins/dataCollectionMixin";
import PrePrimaryAndPrimary from "@/components/dashboardPage/school/ASCDashboard/questionnaire/levels/PrePrimaryAndPrimary";
import {onMounted, ref} from "vue";

export default {
  name: "SchoolEnrolment",
  components: {PrePrimaryAndPrimary, NabTebCategory, SeniorSecSchool, JuniorSecSchool},
  mixins: [dataCollectionMixin],
  setup() {
    const store = useStore()
    onMounted(() => {      
      store.dispatch('fetchMySchool')
    })
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const levels = ref([])
    if (schoolInfo !== null) {
      levels.value = schoolInfo.levelsOffered.map(item => item.toLowerCase())
    }

    const hasPrePrimary = levels.value.includes("pre_primary")
    const hasPreNPrimary = levels.value.includes("primary")
    const hasJSS = levels.value.includes("junior")
    const hasSSS = levels.value.includes("senior")
    const schoolCategory = schoolInfo.schoolType
    const specials = ["VOCATION_TRAINING", "SCIENCE_TECHNOLOGY", "SCIENCE_TECHNOLOGY_VOCATIONAL"]
    const isRegularSchool = !specials.includes(schoolCategory)
    const isRegularSchoolAndHasPrimary = isRegularSchool && hasPreNPrimary
  
    return {
      hasJSS,
      hasSSS,
      isRegularSchoolAndHasPrimary,
      isRegularSchool,
      hasPrePrimary,
    }
  },
  computed: {
    message() {
      return this.$store.state.questionnaireService.message
    },

    hasMessage() {
      return this.message !== null
    },

    activeStage() {
      return this.$store.state.questionnaireService.activeEnrolmentStage
    }
  },
  methods: {
    ...mapMutations(['setQAMessage', 'setResponseCode']),

    closeModal() {
      this.setQAMessage(null)
      this.setResponseCode(null)
    }
  }
}
</script>

<style scoped>

</style>