<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">

      <clip-loader v-if="loading" class="loader-item mt-3"></clip-loader>

      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"                  
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal     
      :visible="showModal" 
      title="Update FLHE Question" 
      @cancel="handleCancel">
      
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Update</a-button>
      </template>

      <a-form layout="vertical">   
        <a-row justify="space-between">
          <a-col :span="24">
            <a-form-item
              label="School Level"
              name="schoolLevelOption"
            >
              <input type="text" class="form-control" :value="schoolLevelOption" disabled>
            </a-form-item>          
          </a-col>        
        </a-row>

        <a-row justify="space-between">
          <a-col :span="24">
            <a-form-item
              :label="flheQuestion"
              name="answer"
            >
              <a-select              
                :value="answer"
                :bordered="false"
                style="width: 100%; height: 40px; borderRadius: 8px; border: none; backgroundColor: #f4f5fc; color: #212121DB"
                @change="handleChange"
              >
                <a-select-option value='true'>YES</a-select-option>
                <a-select-option value='false'>NO</a-select-option>
              </a-select>  
            </a-form-item>    
          </a-col>        
        </a-row>  
      </a-form>
    </a-modal>
  </div>
</template>

<script>

function replaceUnderScore(str) {
  if (str !== undefined) return str.replaceAll("_", " ")
}

function returnYesOrNo(someBool) {
  return (someBool) ? "YES" : "NO"
}

function returnTrueOrFalse(value) {
  if (value === "YES") return true
  else if (value === "NO") return false
  else return value
}

import { Table, Button, Space, Modal, Select, SelectOption, Form, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { onMounted, ref, computed } from 'vue'
import { mapActions, useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: 'FLHEQuestionsTable',
  props: ['title'],
  mixins: [enrolmentMixinVue], 
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form': Form,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
    'a-select': Select,
    'a-select-option': SelectOption,
    'a-form-item': FormItem,
    ClipLoader,
  },
  setup() {      
    const endpoint = 'questionaire/flhe-question'
    const store = useStore()  
    const loading = computed(() => store.state.classroom.updateLoading)
    const message = computed(() => store.state.classroom.updateMessage)

    onMounted(() => {
      store.dispatch('fetchRequest', endpoint)
      .then(() => {
        if (message.value != null) {
          const type = message.value.status
          if (type === 'error') {
            notification.open({
              type: type,
              message: 'Update Message',
              description: message.value.message
            });   
          }
        }
      })
    })

    const showModal = ref(false)
    const selectedObject = ref(null)
    const selectedQuestion = ref('')
    const schoolLevelOption = ref()
    const flheQuestion = ref()    
    const answer = ref()

    const handleChange = value => {
      answer.value = value
    };

    const flheQuestions = {
      "Q1" : "Has your school communicated information about the rules, regulations and guidelines to relevant stakeholders such as pupils, parents, teachers etc?",
      "Q2" : "Do the rules, regulations and guidelines in your school cover Physical Safety in School?",
      "Q3" : "Do the rules, regulations and guidelines in your school cover Stigma and discrimination towards staff or pupils/living " +
                "with/affected by HIV, or based on sex, race or ethnicity, religion or any other grounds, " +
                "sexual harassment and abuse",
      "Q4" : "Do the rules, regulations and guidelines in your school cover Grievance/disciplinary procedures in case of breach of the regualtions described " +
                "in the rules and guidelines.",
      "Q5" : "Did students of your school receive any form of life skills-based " +
                "Family Life Health Education (FLHE) in the previous academic year?",
      "Q6" : "Teaching on generic life skills (e.g. decision-making, communication, refusal skills) was covered in the FLHE program?",
      "Q7" : "Teaching on reproductive health/FLHE (e.g. teaching on human growth and " + 
                "development, family life, sexual abuse and transmission of STIs) was covered in the FLHE program?",
      "Q8" : "Teaching on HIV transmission and prevention was covered in the FLHE program?",    
    }

    const columns = [
      { title: 'School Class', dataIndex: 'schoolLevelOption', fixed: 'left'}, 
      { title: 'Question', dataIndex: 'flheQuestion', fixed: 'left', width: '60%'},      
      { title: 'Answer', dataIndex: 'answer', className: 'content-alignment' },
      {
        title: 'operation',
        dataIndex: 'operation', 
        fixed: 'right',  
      },
    ]

    return {    
      endpoint,
      columns,  
      showModal,
      selectedObject,
      schoolLevelOption,    
      flheQuestion,
      loading,
      answer,

      selectedQuestion,
      flheQuestions,

      handleChange,
    }
  },
  computed: {
    classroomData() {
      return this.$store.state.classroom.classroomData
    },
    updateMessage() {
      return this.$store.state.classroom.updateMessage
    },
    dataSource() {
      const dataItems = []      
      if (this.classroomData != null) {
        const data = Object.values(this.classroomData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolLevelOption: replaceUnderScore(element["schoolLevelOption"]),                
                flheQuestion: this.flheQuestions[element["flheQuestion"]],
                answer: returnYesOrNo(element["answer"]),
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {

    ...mapActions(['updateSubmissionRequest', 'fetchRequest']),    

    editRecord(key) {
      const detail = this.classroomData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolLevelOption = detail.schoolLevelOption      
        this.flheQuestion = this.flheQuestions[detail.flheQuestion]
        this.answer = returnYesOrNo(detail.answer)
      } 
    },

    handleSubmit() {
      const payload = {        
        endpoint: `questionaire/flhe-question/${this.selectedObject.id}`,
        body: {          
          answer: JSON.parse(returnTrueOrFalse(this.answer.toString())),
          schoolLevelOption: this.selectedObject.schoolLevelOption,
          flheQuestion: this.selectedObject.flheQuestion,
          flheQuestionType: this.selectedObject.flheQuestionType,
          school: this.selectedObject.school,             
        }
      }
      this.updateSubmissionRequest(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.flheQuestion = ''
            this.answer = ''
            
            const type = this.updateMessage.status

            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });            

            if (type === 'success') {              
              this.fetchRequest(this.endpoint)
            }                      
          }
        })  
    },

    handleCancel() {
      this.showModal = false
    },
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
