<template>
  <div class="bg-accent d-flex flex-column min-vh-100">
    <div class="container pt-4">
      <dashboard-header 
        @goBack="goBack"
        :hasBack="true"
      />
      <div v-if="loadingStatus" class="overlay">
        <clip-loader class="loading"></clip-loader>
      </div>
      <enrolment-public-pre-pry-education v-if="index === '0'" />
      <enrolment-private-pre-pry-education v-if="index === '1'" />
      <public-and-private-pre-pry-enrolment-by-gender-and-l-g-a v-if="index === '2'" />
      <enrolment-public-primary-schl-by-class v-if="index === '3'" />
      <enrolment-private-primary-schl-by-class v-if="index === '4'" />
      <public-and-private-primary-sch-enrolment v-if="index === '5'" />
      <public-pry-sch-enrolment-by-gender-and-age v-if="index === '6'" />
      <private-pry-sch-enrolment-by-gender-and-age v-if="index === '7'" />
      <combined-public-and-private-pry-enrolment v-if="index === '8'" />
      <enrolment-public-junior-schl-by-class v-if="index === '9'" />
      <enrolment-private-junior-schl-by-class v-if="index === '10'" />
      <public-and-private-junior-sch-enrolment v-if="index === '11'" />
      <public-jnr-sec-sch-enrolment-by-gender-and-age v-if="index === '12'" />
      <private-jnr-sec-sch-enrolment-by-gender-and-age v-if="index === '13'" />
      <combined-jnr-sec-sch-enrolment-by-gender-and-age v-if="index === '14'" />
      <enrolment-public-senior-schl-by-class v-if="index === '15'" />
      <enrolment-private-senior-schl-by-class v-if="index === '16'" />
      <public-and-private-senior-sch-enrolment v-if="index === '17'" />
      <public-snr-sec-sch-enrolment-by-gender-and-age v-if="index === '18'" />
      <private-snr-sec-sch-enrolment-by-gender-and-age v-if="index === '19'" />
      <combined-snr-sec-sch-enrolment-by-gender-and-age v-if="index === '20'" />
      <enrolment-public-sci-and-tech-junior v-if="index === '21'" />
      <enrolment-public-sci-and-tech-senior v-if="index === '22'" />
      <public-sci-and-tech-jnr-enrolment v-if="index === '23'" />
      <public-sci-and-tech-snr-enrolment v-if="index === '24'" />
      <special-needs-student-public-pry-schools v-if="index === '25'" />
      <special-needs-student-public-secondary-schools v-if="index === '26'" />
      <repeater-public-primary-sch v-if="index === '27'" />
      <repeater-public-secondary-sch v-if="index === '28'" />
      <enrolment-in-public-primary-with-pre-primary v-if="index === '29'" />
      <combined-pre-pry-school-enrolment-including-private v-if="index === '30'" />
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import EnrolmentPublicPrePryEducation
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/EnrolmentPublicPrePryEducation";
import EnrolmentPrivatePrePryEducation
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/EnrolmentPrivatePrePryEducation";
import PublicAndPrivatePrePryEnrolmentByGenderAndLGA
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PublicAndPrivatePrePryEnrolmentByGenderAndLGA";
import SpecialNeedsStudentPublicPrySchools
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/SpecialNeedsStudentPublicPrySchools";
import SpecialNeedsStudentPublicSecondarySchools
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/SpecialNeedsStudentPublicSecondarySchools";
import EnrolmentPublicPrimarySchlByClass
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/EnrolmentPublicPrimarySchlByClass";
import EnrolmentPublicJuniorSchlByClass
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/EnrolmentPublicJuniorSchlByClass";
import EnrolmentPublicSeniorSchlByClass
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/EnrolmentPublicSeniorSchlByClass";
import EnrolmentPrivatePrimarySchlByClass
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/EnrolmentPrivatePrimarySchlByClass";
import EnrolmentPrivateJuniorSchlByClass
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/EnrolmentPrivateJuniorSchlByClass";
import EnrolmentPrivateSeniorSchlByClass
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/EnrolmentPrivateSeniorSchlByClass";
import RepeaterPublicPrimarySch from "@/components/dashboardPage/dataTable/StudentsPupilsTables/RepeaterPublicPrimarySch";
import RepeaterPublicSecondarySch
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/RepeaterPublicSecondarySch";
import EnrolmentInPublicPrimaryWithPrePrimary
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/EnrolmentInPublicPrimaryWithPrePrimary";
import PublicAndPrivatePrimarySchEnrolment
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PublicAndPrivatePrimarySchEnrolment";
import CombinedPrePrySchoolEnrolmentIncludingPrivate
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/CombinedPrePrySchoolEnrolmentIncludingPrivate";
import PublicPrySchEnrolmentByGenderAndAge
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PublicPrySchEnrolmentByGenderAndAge";
import PrivatePrySchEnrolmentByGenderAndAge
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PrivatePrySchEnrolmentByGenderAndAge";
import CombinedPublicAndPrivatePryEnrolment
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/CombinedPublicAndPrivatePryEnrolment";
import PublicAndPrivateJuniorSchEnrolment
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PublicAndPrivateJuniorSchEnrolment";
import PublicJnrSecSchEnrolmentByGenderAndAge
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PublicJnrSecSchEnrolmentByGenderAndAge";
import PrivateJnrSecSchEnrolmentByGenderAndAge
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PrivateJnrSecSchEnrolmentByGenderAndAge";
import CombinedJnrSecSchEnrolmentByGenderAndAge
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/CombinedJnrSecSchEnrolmentByGenderAndAge";
import PublicAndPrivateSeniorSchEnrolment
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PublicAndPrivateSeniorSchEnrolment";
import PublicSnrSecSchEnrolmentByGenderAndAge
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PublicSnrSecSchEnrolmentByGenderAndAge";
import PrivateSnrSecSchEnrolmentByGenderAndAge
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PrivateSnrSecSchEnrolmentByGenderAndAge";
import CombinedSnrSecSchEnrolmentByGenderAndAge
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/CombinedSnrSecSchEnrolmentByGenderAndAge";
import EnrolmentPublicSciAndTechJunior
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/EnrolmentPublicSciAndTechJunior";
import EnrolmentPublicSciAndTechSenior
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/EnrolmentPublicSciAndTechSenior";
import PublicSciAndTechJnrEnrolment
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PublicSciAndTechJnrEnrolment";
import PublicSciAndTechSnrEnrolment
  from "@/components/dashboardPage/dataTable/StudentsPupilsTables/PublicSciAndTechSnrEnrolment";
import DashboardHeader from '@/components/dashboardPage/DashboardHeader.vue';  
import {useRoute, useRouter} from "vue-router";
import { useStore } from 'vuex';
import { computed } from 'vue'

export default {
  name: "StudentsPupilsTables",
  components: {
    DashboardHeader,
    ClipLoader,
    PublicSciAndTechSnrEnrolment,
    PublicSciAndTechJnrEnrolment,
    EnrolmentPublicSciAndTechSenior,
    EnrolmentPublicSciAndTechJunior,
    CombinedSnrSecSchEnrolmentByGenderAndAge,
    PrivateSnrSecSchEnrolmentByGenderAndAge,
    PublicSnrSecSchEnrolmentByGenderAndAge,
    PublicAndPrivateSeniorSchEnrolment,
    CombinedJnrSecSchEnrolmentByGenderAndAge,
    PrivateJnrSecSchEnrolmentByGenderAndAge,
    PublicJnrSecSchEnrolmentByGenderAndAge,
    PublicAndPrivateJuniorSchEnrolment,
    CombinedPublicAndPrivatePryEnrolment,
    PrivatePrySchEnrolmentByGenderAndAge,
    PublicPrySchEnrolmentByGenderAndAge,
    CombinedPrePrySchoolEnrolmentIncludingPrivate,
    PublicAndPrivatePrimarySchEnrolment,
    EnrolmentInPublicPrimaryWithPrePrimary,
    RepeaterPublicSecondarySch,
    RepeaterPublicPrimarySch,
    EnrolmentPrivateSeniorSchlByClass,
    EnrolmentPrivateJuniorSchlByClass,
    EnrolmentPrivatePrimarySchlByClass,
    EnrolmentPublicSeniorSchlByClass,
    EnrolmentPublicJuniorSchlByClass,
    EnrolmentPublicPrimarySchlByClass,
    SpecialNeedsStudentPublicSecondarySchools,
    SpecialNeedsStudentPublicPrySchools,
    PublicAndPrivatePrePryEnrolmentByGenderAndLGA,
    EnrolmentPrivatePrePryEducation,
    EnrolmentPublicPrePryEducation
  },
   setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const loadingStatus = computed(() => store.state.datatables.loadingStatus)
    const index = route.params.index
    const goBack = () => { store.commit('setCurrentStage', 2); router.back() }

    return {
      index,
      loadingStatus,
      goBack,
    }
  } 
}
</script>