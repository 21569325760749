<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"    
        :scroll="{ x: 1300 }"        
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal     
      :visible="showModal" 
      title="Update Enrolment By Age Junior Students" 
      @cancel="handleCancel">
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Update</a-button>
      </template>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="School Class"
            name="schoolClass"
          >
            <input type="text" class="form-control" :value="schoolClass" disabled>
          </a-form-item>          
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Gender"
            name="gender"
          >
            <input type="text" class="form-control" :value="gender" disabled>
          </a-form-item>          
        </a-col>
      </a-row>
      
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="Below Fifteen Years"
            name="belowFifteenYears"
          >
            <input type="number" class="form-control" v-model="belowFifteenYears">
          </a-form-item>    
        </a-col>
        <a-col :span="11">        
          <a-form-item
            label="Fifteen Years"
            name="fifteenYears"
          >
            <input type="number" class="form-control" v-model="fifteenYears">
          </a-form-item>        
        </a-col>
      </a-row>

      <a-row justify="space-between">
        <a-col :span="11">            
          <a-form-item
            label="Sixteen Years"
            name="sixteenYears"
          >
            <input type="number" class="form-control" v-model="sixteenYears">
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Seventeen Years"
            name="SeventeenYears"
          >
            <input type="number" class="form-control" v-model="seventeenYears">
          </a-form-item>                
        </a-col>
      </a-row>  

      <a-row justify="space-between">
        <a-col :span="24">
          <a-form-item
            label="Above Seventeen Years"
            name="aboveSeventeenYears"
          >
            <input type="number" class="form-control" v-model="aboveSeventeenYears">
          </a-form-item>           
        </a-col>
      </a-row>  
    </a-modal>    
  </div>
</template>

<script>
import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { onMounted, ref } from 'vue'
import { mapActions, mapMutations, useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'EnrolmentNewEntrantSSOneTable',
  emits: ['viewEnrolmentInfo'],
  props: ['title', 'schoolId'],
  mixins: [enrolmentMixinVue], 
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
  },
  setup() {
    const store = useStore()
    const showModal = ref(false)
    const selectedObject = ref(null)
    const schoolClass = ref()
    const gender = ref()
    const belowFifteenYears = ref()
    const fifteenYears = ref()
    const sixteenYears = ref()
    const seventeenYears = ref()
    const aboveSeventeenYears = ref()


    const columns = [
      { title: 'School Class', dataIndex: 'schoolClass', fixed: 'left', width: 120 }, 
      { title: 'Gender', dataIndex: 'gender', fixed: 'left', width: 100 },
      { title: 'Below Fifteen Years', dataIndex: 'belowFifteenYears', className: 'content-alignment' },
      { title: 'Fifteen Years', dataIndex: 'fifteenYears', className: 'content-alignment' },
      { title: 'Sixteen Years', dataIndex: 'sixteenYears', className: 'content-alignment' },    
      { title: 'Seventeen Years', dataIndex: 'seventeenYears', className: 'content-alignment' },    
      { title: 'Above Seventeen Years', dataIndex: 'aboveSeventeenYears', className: 'content-alignment' },
      {
        title: 'operation',
        dataIndex: 'operation', 
        fixed: 'right',  
        width: 100   
      },
    ]

    return {    
      columns,      
      showModal,
      selectedObject,
      schoolClass,
      gender,
      belowFifteenYears,
      fifteenYears,
      sixteenYears,
      seventeenYears,
      aboveSeventeenYears,      
    }
  },
  computed: {
    seniorNewEntrantData() {
      return this.$store.state.enrolment.seniorNewEntrantData
    },
    dataSource() {
      const dataItems = []      
      if (this.seniorNewEntrantData != null) {
        const data = Object.values(this.seniorNewEntrantData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolClass: element["schoolClass"],
                gender: element["gender"],
                belowFifteenYears: element["below15Year"],
                fifteenYears: element["fifteen15Year"],
                sixteenYears: element["sixteen16Year"],
                seventeenYears: element["seventeen17Year"],
                aboveSeventeenYears: element["above17Year"]
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {

    ...mapActions(['updateRequest', 'fetchSeniorNewEntrant']),
    ...mapMutations(['setSeniorNewEntrantData']),

    editRecord(key) {
      const detail = this.seniorNewEntrantData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolClass = detail.schoolClass
        this.gender = detail.gender
        this.belowFifteenYears = detail.below15Year
        this.fifteenYears = detail.fifteen15Year
        this.sixteenYears = detail.sixteen16Year
        this.seventeenYears = detail.seventeen17Year
        this.aboveSeventeenYears = detail.above17Year
      } 
    },

    handleSubmit() {
      const payload = {        
        endpoint: `questionaire/enrollment/section-b3/${this.selectedObject.id}`,
        body: {
          below15Year: Number(this.belowFifteenYears),
          fifteen15Year: Number(this.fifteenYears),
          sixteen16Year: Number(this.sixteenYears),
          seventeen17Year: Number(this.seventeenYears),
          above17Year: Number(this.aboveSeventeenYears),          
        }
      }
      this.updateRequest(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.belowFifteenYears = ''
            this.fifteenYears = ''
            this.sixteenYears = '' 
            this.seventeenYears = ''
            this.aboveSeventeenYears = ''
            
            const type = this.updateMessage.status

            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });            

            if (type === 'success') {              
              this.setSeniorNewEntrantData(null)
              this.fetchSeniorNewEntrant()
            }                      
          }
        })  
    },

    handleCancel() {
      this.showModal = false
    },    

  },
  mounted(){
    if(!this.schoolId) {
      this.$store.commit('setSeniorNewEntrantData', null)
      this.$store.dispatch('fetchSeniorNewEntrant')
    } else {
      this.$store.commit('setSeniorNewEntrantData', null)
      this.$store.dispatch('fetchSeniorNewEntrant', this.schoolId)
    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
