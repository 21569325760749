<template>
  <div class="container d-flex flex-column">
    <div class="d-flex flex-wrap ms-lg-4">
      <div class="col-6 px-1 col-lg-3 px-md-2 mb-2 mb-lg-0">
        <select class="form-select" aria-label="Default select example">
          <option selected>Open this select menu</option>
        </select>
      </div>
      <div class="col-6 px-1 col-lg-3 px-md-2 mb-2 mb-lg-0">
        <select class="form-select" aria-label="Default select example">
          <option selected>Open this select menu</option>
        </select>
      </div>
      <div class="col-6 px-1 col-lg-3 px-md-2 mb-2 mb-lg-0">
        <select class="form-select" aria-label="Default select example">
          <option selected>Open this select menu</option>
        </select>
      </div>
      <div class="col-6 px-2 col-lg-1">
        <button class="btn custom-primary-btn font-sm">Go</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>