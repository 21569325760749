<template>
  <div class="bg-accent d-flex flex-column min-vh-100">
    <div class="container pt-4">
      <dashboard-header 
        @goBack="goBack"
        :hasBack="true"
      />
      <div v-if="loadingStatus" class="overlay">
        <clip-loader class="loading"></clip-loader>
      </div>
      <gross-enrolment-ratio v-if="index === '0'" />
      <net-enrolment-rate v-if="index === '1'" />
      <gross-intake-ratio v-if="index === '2'" />
      <net-intake-rate v-if="index === '3'" />
      <completion-rate v-if="index === '4'" />
      <gender-parity-index v-if="index === '5'" />
      <cohort-analysis v-if="index === '6'" />
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import GrossEnrolmentRatio from "@/components/dashboardPage/dataTable/KeyEducationIndicators/GrossEnrolmentRatio";
import GenderParityIndex from "@/components/dashboardPage/dataTable/KeyEducationIndicators/GenderParityIndex";
import CompletionRate from "@/components/dashboardPage/dataTable/KeyEducationIndicators/CompletionRate";
import NetIntakeRate from "@/components/dashboardPage/dataTable/KeyEducationIndicators/NetIntakeRate";
import GrossIntakeRatio from "@/components/dashboardPage/dataTable/KeyEducationIndicators/GrossIntakeRatio";
import NetEnrolmentRate from "@/components/dashboardPage/dataTable/KeyEducationIndicators/NetEnrolmentRate";
import CohortAnalysis from "@/components/dashboardPage/dataTable/KeyEducationIndicators/CohortAnalysis";
import DashboardHeader from '@/components/dashboardPage/DashboardHeader.vue';
import {useRoute, useRouter} from "vue-router";
import { useStore } from 'vuex';
import { computed } from 'vue'

export default {
  name: "KeyIndicatorTables",
  components: {
    CohortAnalysis, NetEnrolmentRate, GrossIntakeRatio, NetIntakeRate,
    CompletionRate, GenderParityIndex, GrossEnrolmentRatio, ClipLoader,
    DashboardHeader,
  },
   setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const loadingStatus = computed(() => store.state.datatables.loadingStatus)
    const index = route.params.index
    const goBack = () => { store.commit('setCurrentStage', 5); router.back() }

    return {
      index,
      loadingStatus,
      goBack,
    }
  } 
}
</script>

<style scoped>

</style>