<template>
  <div>
    <section class="mb-5">
      <p class="font-sm fw-bold">
        Primary enrolment for the current school year - {{ schoolClass }}
      </p>
      <div class="d-flex mb-2">
        <div class="col-6"><p class="font-sm fw-bold">Pupil age</p></div>
        <span class="col-2 text-center font-sm fw-normal">Male</span>
        <span class="col-2 text-center font-sm fw-normal">Female</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6">
          <span class="font-sm fw-normal">Below 6 years</span>
        </div>
        <div class="col-2 me-3">
          <input
            class="form-control outline"
            v-model="newEntrantMale.belowSix6Years"
            type="number"
            aria-label="male"
          />
        </div>
        <div class="col-2">
          <input
            class="form-control outline"
            v-model="newEntrantFemale.belowSix6Years"
            type="number"
            aria-label="female"
          />
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">6 years</span></div>
        <div class="col-2 me-3">
          <input
            class="form-control outline"
            v-model="newEntrantMale.six6Years"
            type="number"
            aria-label="male"
          />
        </div>
        <div class="col-2">
          <input
            class="form-control outline"
            v-model="newEntrantFemale.six6Years"
            type="number"
            aria-label="female"
          />
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">7 years</span></div>
        <div class="col-2 me-3">
          <input
            class="form-control outline"
            v-model="newEntrantMale.seven7Years"
            type="number"
            aria-label="male"
          />
        </div>
        <div class="col-2">
          <input
            class="form-control outline"
            v-model="newEntrantFemale.seven7Years"
            type="number"
            aria-label="female"
          />
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">8 years</span></div>
        <div class="col-2 me-3">
          <input
            class="form-control outline"
            v-model="newEntrantMale.eight8Years"
            type="number"
            aria-label="male"
          />
        </div>
        <div class="col-2">
          <input
            class="form-control outline"
            v-model="newEntrantFemale.eight8Years"
            type="number"
            aria-label="female"
          />
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">9 years</span></div>
        <div class="col-2 me-3">
          <input
            class="form-control outline"
            v-model="newEntrantMale.nine9Years"
            type="number"
            aria-label="male"
          />
        </div>
        <div class="col-2">
          <input
            class="form-control outline"
            v-model="newEntrantFemale.nine9Years"
            type="number"
            aria-label="female"
          />
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">10 years</span></div>
        <div class="col-2 me-3">
          <input
            class="form-control outline"
            v-model="newEntrantMale.ten10Years"
            type="number"
            aria-label="male"
          />
        </div>
        <div class="col-2">
          <input
            class="form-control outline"
            v-model="newEntrantFemale.ten10Years"
            type="number"
            aria-label="female"
          />
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">11 years</span></div>
        <div class="col-2 me-3">
          <input
            class="form-control outline"
            v-model="newEntrantMale.eleven11Years"
            type="number"
            aria-label="male"
          />
        </div>
        <div class="col-2">
          <input
            class="form-control outline"
            v-model="newEntrantFemale.eleven11Years"
            type="number"
            aria-label="female"
          />
        </div>
      </div>
      <div class="d-flex">
        <div class="col-6">
          <span class="font-sm fw-normal">Above 11 years</span>
        </div>
        <div class="col-2 me-3">
          <input
            class="form-control outline"
            v-model="newEntrantMale.aboveEleven11Years"
            type="number"
            aria-label="male"
          />
        </div>
        <div class="col-2">
          <input
            class="form-control outline"
            v-model="newEntrantFemale.aboveEleven11Years"
            type="number"
            aria-label="female"
          />
        </div>
      </div>
    </section>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <button class="custom-btn font-tiny fw-bold" @click="submitForm">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
import { mapActions } from "vuex";

export default {
  props: ["schoolClass"],
  name: "EnrolmentByAgeForClass",
  setup(props) {
    const { schoolClass } = toRefs(props);
    const currentClass = schoolClass.value.replace(" ", "").toUpperCase();

    const newEntrantMale = {
      belowSix6Years: "",
      six6Years: "",
      seven7Years: "",
      eight8Years: "",
      nine9Years: "",
      ten10Years: "",
      eleven11Years: "",
      aboveEleven11Years: "",
      gender: "MALE",
      schoolClass: currentClass,
    };
    const newEntrantFemale = {
      belowSix6Years: "",
      six6Years: "",
      seven7Years: "",
      eight8Years: "",
      nine9Years: "",
      ten10Years: "",
      eleven11Years: "",
      aboveEleven11Years: "",
      gender: "FEMALE",
      schoolClass: currentClass,
    };

    return {
      newEntrantMale,
      newEntrantFemale,
    };
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool;
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode;
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage;
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message;
      }
      return "";
    },
  },
  methods: {
    ...mapActions(["submitEnrolmentByClass"]),

    async submitForm() {
      const body = {
        requestBody: [this.newEntrantMale, this.newEntrantFemale],
        school: this.ownSchool.id,
      };
      await this.submitEnrolmentByClass(body).then(() => {
        this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        });
        if (this.responseCode === 201) {
          this.$emit("submissionDone");
        }
      });
    },
  },
};
</script>

<style scoped></style>
