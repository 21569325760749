import axios from "axios";

const initState = {
  updateLoading: false,
  updateMessage: null,
  classroomData: null,
};

const state = initState;

const actions = {
  async fetchRequest({ commit, dispatch, state }, endpoint) {
    commit("setClassroomUpdateLoading", true);
    commit("setClassroomData", null);
    commit("setClassroomUpdateMessage", null);
    await axios
      .get(endpoint, { timeout: 15000 })
      .then((res) => {
        commit("setClassroomUpdateLoading", false);
        commit("setClassroomData", res.data);
      })
      .catch((err) => {
        dispatch("handleError", err);
      });
  },

  async updateSubmissionRequest({ commit, dispatch }, payload) {
    const { endpoint, body } = payload;
    commit("setClassroomUpdateLoading", true);
    commit("setClassroomUpdateMessage", null);
    await axios
      .patch(endpoint, body, { timeout: 15000 })
      .then((res) => {
        commit("setClassroomUpdateLoading", false);
        commit("setClassroomUpdateMessage", {
          status: "success",
          message: res.data.message,
        });
      })
      .catch((err) => {
        dispatch("handleError", err);
      });
  },

  handleError({ commit }, error) {
    commit("setClassroomUpdateLoading", false);
    let message = "";
    if (error.response) {
      if (error.response.status === 404) {
        message = error.response.data.error_description;
      } else if (error.response.data.description) {
        message = error.response.data.description;
      } else if (error.response.data.error_description) {
        message = error.response.data.error_description;
      } else {
        message = "Error updating data!";
      }
    } else if (error.request) {
      if (error.request.statusText === "") {
        message = "Something went wrong With the Network!";
      } else {
        message = error.request.statusText;
      }
    } else {
      console.log(error.message);
      message = error.message;
    }

    console.log(message);

    commit("setClassroomUpdateMessage", {
      status: "error",
      message: message,
    });
  },
};

const mutations = {
  setClassroomUpdateLoading(state, value) {
    state.updateLoading = value;
  },

  setClassroomUpdateMessage(state, value) {
    state.updateMessage = value;
  },

  setClassroomData(state, value) {
    state.classroomData = value;
  },

  resetClassroomState(state) {
    Object.assign(state, initState);
  },
};

export default {
  state,
  actions,
  mutations,
};
