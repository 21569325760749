<template>
  <div class="row">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div
      class="col-sm-12 col-lg-7 d-flex flex-column bg-accent align-items-stretch vh-100 pt-4 px-5"
    >
      <div class="d-flex flex-nowrap align-items-center ps-5">
        <router-link to="/">
          <img :src="state_logo" width="40" height="40" alt="" />
        </router-link>
        <span class="font-sm fw-bold ps-2">{{ stateName }} STATE EMIS</span>
      </div>
      <div class="row">
        <p class="my-4 px-5">
          Welcome to the Education Management Information System (EMIS). A
          Platform for accessing relevant education data and information.
        </p>
        <!-- /Borno/Yobe-->
        <p class="px-5">
          The {{ stateName }} EMIS will be used for the collection, integration,
          maintenance and dissemination of education data and information to
          support decision making, planning, policy analysis, monitoring and
          evaluation of all levels of education institutions and also provide
          information to the general public.
        </p>
      </div>
      <div>
        <img class="img-fluid" :src="login_artwork" alt="Logo hero" />
      </div>
    </div>
    <div class="col-sm-12 col-lg-5 d-flex flex-column p-5">
      <span class="display-6 fw-bold mx-5 mt-3 lh-sm"> Welcome to EMIS </span>

      <div class="login-form-container mt-4 max-vh-100">
        <form @submit.prevent="submit" method="post" class="login-form px-5">
          <div>
            <label class="mb-2 font-tiny"> Email </label>
            <input
              type="email"
              v-model="form.userEmail"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              class="login-inputs"
              placeholder="johndoe@gmail.com"
              required
            />
          </div>
          <div class="mt-3">
            <label class="mb-2 font-tiny"> Password </label>
            <input
              type="password"
              v-model="form.password"
              class="login-inputs"
              placeholder="**************"
              required
            />
          </div>
          <div class="mt-3">
            <span class="d-flex align-items-center justify-content-end font-sm"
              >Forgot password?
              <a href="#" class="reset-link"> Reset it</a></span
            >
          </div>
          <div class="mt-5 d-flex align-items-center justify-content-center">
            <button
              :disabled="localLoading"
              type="submit"
              class="submit-btn font-sm"
            >
              Login to your account
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import state_logo from "@/assets/images/adamawa_logo.png";
import login_artwork from "@/assets/images/login-hero.png";
import router from "@/router";
import { emisconfig } from "../../../app.config";

export default {
  name: "Login",
  components: { ClipLoader },
  data() {
    return {
      login_artwork,
      state_logo,
      form: {
        userEmail: "",
        password: "",
      },
      stateName: emisconfig.stateName,
      hasMessage: false,
      localLoading: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.auth.loading;
    },
    message() {
      return this.$store.state.auth.message;
    },
  },
  methods: {
    ...mapActions(["login", "getAuthUser"]),
    ...mapGetters(["isAuthenticated", "getMessage"]),
    ...mapMutations(["setMessage"]),

    async submit() {
      this.localLoading = true;
      const user = {
        email: this.form.userEmail,
        password: this.form.password,
      };
      await this.login(user);

      if (this.$store.state.auth.token) {
        await this.getAuthUser().then(() => {
          this.$notification.open({
            type: "success",
            message: "Success",
            description: "Login Successfull",
          });
          setTimeout(() => {
            router.push("/dashboard");
          }, 1000);
        });
      } else if (this.$store.state.auth.message && this.message.status == 401) {
        this.localLoading = false;
        this.$notification.open({
          type: "error",
          message: "Validation Error",
          description: this.message.message,
        });
      } else if (
        this.$store.state.auth.message &&
        this.message.status == "error"
      ) {
        this.localLoading = false;
        this.$notification.open({
          type: this.message.status,
          message: "Network error",
          description: "Something went wrong with the Network",
        });
      } else {
        this.localLoading = false;
        this.message = "Unable to login! Please check your connection.";
        this.hasMessage = true;
      }
    },

    closeModal() {
      this.hasMessage = false;
      this.message = null;
      this.setMessage(null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/variable";

.accent-background {
  background-color: $accent-color;
  height: 100vh;
}

.colx {
  background-color: $accent-color;
}

.reset-link {
  color: $blue-dm;
  margin-left: 5px;
}
.login-inputs {
  height: 50px;
  width: 100%;
  background-color: $accent-color;
  border: none;
  border-radius: $sm-size;
  padding-left: 20px;

  &:focus {
    outline: none;
    border: none;
  }
}

.subtitle-text {
  color: gray;
}
.bold-title-text {
  font-size: 58px;
  font-weight: 800;
}
.state-logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.submit-btn {
  height: 45px;
  width: 230px;
  color: white;
  border: none;
  border-radius: $sm-size;
  background-color: $blue-dm;
}

.login-area-container {
  height: 100vh;
}
.side-text {
  padding: 10px;
  margin-top: 10px;
  font-size: $tiny-size;
  font-weight: bold;
  text-transform: uppercase;
}

.login-form-container {
  width: 100%;
  position: relative;
  // left: -29px;
}
.large-artwork-container {
  background-image: url("../../assets/images/login-hero.png");
  width: 100%;
  object-fit: scale-down !important;
  background-repeat: no-repeat;
  height: 90vh;
  position: relative;
  top: -150px;
}
</style>
