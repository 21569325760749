<template>
  <div>
    <section class="mb-5">
      <p class="font-sm fw-bold">Number of pupils with special needs in the current school year - {{ schoolClass }}</p>
      <div class="d-flex mb-2">
        <div class="col-6"></div>
        <span class="col-2 text-center font-sm fw-normal">Male</span>
        <span class="col-2 text-center font-sm fw-normal">Female</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">Blind/visually impaired</span></div>
        <div class="col-2 me-1">
          <input class="form-control outline" v-model="specialNeedsMale.blind" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="specialNeedsFemale.blind" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">Hearing/speech impaired</span></div>
        <div class="col-2 me-1">
          <input class="form-control outline" v-model="specialNeedsMale.hearing" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="specialNeedsFemale.hearing" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">Physically challenged (other than visual and hearing)</span></div>
        <div class="col-2 me-1">
          <input class="form-control outline" v-model="specialNeedsMale.physicallyChallenged" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="specialNeedsFemale.physicallyChallenged" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">Mentally challenged</span></div>
        <div class="col-2 me-1">
          <input class="form-control outline" v-model="specialNeedsMale.mentallyChallenged" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="specialNeedsFemale.mentallyChallenged" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">Albinism</span></div>
        <div class="col-2 me-1">
          <input class="form-control outline" v-model="specialNeedsMale.albinism" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="specialNeedsFemale.albinism" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex">
        <div class="col-6"><span class="font-sm fw-normal">Autism</span></div>
        <div class="col-2 me-1">
          <input class="form-control outline" v-model="specialNeedsMale.autism" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="specialNeedsFemale.autism" type="number" aria-label="female">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {ref, toRefs} from 'vue'
import {mapActions} from "vuex";

export default {
  name: "PersonsWithSpecialNeedsByClass",
  props: ['schoolClass'],
  setup(props) {
    const {schoolClass} = toRefs(props)
    const currentClass = (schoolClass.value).replace(" ", "").toUpperCase()
    let newClass = ref('')
    if (currentClass.includes("ECCD")) {
      newClass = currentClass.replace("ECCD", "KG1")
    } else {
      newClass = currentClass
    }

    console.log(newClass)

    const specialNeedsMale = {
      blind: "",
      hearing: "",
      physicallyChallenged: "",
      mentallyChallenged: "",
      albinism: "",
      autism: "",
      gender: "MALE",
      schoolClass: newClass
    }

    const specialNeedsFemale = {
      blind: "",
      hearing: "",
      physicallyChallenged: "",
      mentallyChallenged: "",
      albinism: "",
      autism: "",
      gender: "FEMALE",
      schoolClass: newClass
    }

    return {
      specialNeedsMale,
      specialNeedsFemale
    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitEnrolmentStudentWithSpecialNeeds']),

    async submitForm() {
      const body = {
        requestBody: [this.specialNeedsMale, this.specialNeedsFemale],
        school: this.ownSchool.id
      }

      await this.submitEnrolmentStudentWithSpecialNeeds(body)
        .then(() => {
          if (this.responseCode === 201) {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            this.$emit('submissionDone')
            this.specialNeedsMale.blind = ""
            this.specialNeedsMale.hearing = ""
            this.specialNeedsMale.physicallyChallenged = ""
            this.specialNeedsMale.mentallyChallenged = ""
            this.specialNeedsMale.albinism = ""
            this.specialNeedsMale.autism = ""

            this.specialNeedsFemale.blind = ""
            this.specialNeedsFemale.hearing = ""
            this.specialNeedsFemale.physicallyChallenged = ""
            this.specialNeedsFemale.mentallyChallenged = ""
            this.specialNeedsFemale.albinism = ""
            this.specialNeedsFemale.autism = ""
          }
        })
    }
  }
}
</script>

<style scoped>

</style>