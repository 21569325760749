<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Private junior secondary school enrolment by gender And LGA</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-sm" aria-label="Users table">
        <thead>
        <tr>
          <th class="align-middle py-3 ps-4"></th>
          <th class="align-middle text-center py-3" colspan="3">
            Total
          </th>
          <th class="align-middle text-center py-3" colspan="3">
            Age 12-14
          </th>
        </tr>
        <tr>
          <th class="align-middle py-3 ps-4 lga-cell col-md-2">
            LGA
          </th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">
            Pupils
          </th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">
            Girls
          </th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">
            % Girls
          </th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">Pupils</th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">Girls</th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">% Girls</th>
        </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg" 
            v-for="(data, index) in tableData"
            :key="index"
          >
            <td class="py-3 ps-4 other-cells first">{{ data.lga }}</td>
            <td class="py-3 other-cells">{{ data.total_pupils }}</td>
            <td class="py-3 other-cells">{{ data.total_girls }}</td>
            <td class="py-3 other-cells">{{ data.total_percentage_girls }}</td>
            <td class="py-3 other-cells">{{ data.aged_12_to_14_pupils }}</td>
            <td class="py-3 other-cells">{{ data.aged_12_to_14_girls }}</td>
            <td class="py-3 other-cells">{{ data.aged_12_to_14_percentage_girls }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "PrivateJnrSecSchEnrolmentByGenderAndAge",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 49, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })

    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
.lga-cell {
  width: 28%;
}
.other-cells {
  width: 12%;
}
</style>