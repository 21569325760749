<template>
  <div class="min-vh-100 pb-5">

    <enrolments-chart :key="enrolmentKey" @reload="enrolmentKey++" />

    <combined-enrolment-chart :key="combinedKey" @reload="combinedKey++" />

    <not-combined-chart :key="notCombinedKey" @reload="notCombinedKey++" />

    <gross-enrolment-chart :key="grossEnrolmentKey" @reload="grossEnrolmentKey++" />

    <net-enrolment-chart :key="netEnrolmentKey" @reload="netEnrolmentKey++" />

    <total-enrolment-chart :key="totalEnrolmentKey" @reload="totalEnrolmentKey++" />

    <repeaters-rate-chart :key="repeaterRateKey" @reload="repeaterRateKey++" />

    <special-needs-chart :key="specialNeedsKey" @reload="specialNeedsKey++" />

    <!-- <section>
      <div class="d-flex bg-white p-4 mb-3 radius-full">
        <div class="col-6 bordered-header-active">
          <span>School buildings</span>
        </div>
        <div class="col-6 d-flex bordered-header justify-content-end">
          <span>Domitory buildings</span>
        </div>
      </div>
      <div class="bg-white py-4 ps-4 mb-4 radius-full">
        <div class="facility-container d-flex flex-column">
          <div 
            class="d-flex bg-accent px-3 py-2 mb-2 radius-half me-4"
            v-for="(item, index) in infrastructures"
            :key="index"
          >
            <div class="col font-sm">{{ item.name }}</div>
            <div class="col d-flex justify-content-center font-sm ">{{ item.yearOfEstablisment }}</div>
            <div class="col d-flex justify-content-end font-sm">
              <span :class="[item.condition.toLowerCase() === 'good' ? 'is-good': 'is-poor', 'px-2']">
                <span>{{ item.condition }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section> -->

  </div>
</template>

<script>
import EnrolmentsChart from "@/components/dashboardPage/main/access/charts/EnrolmentsChart";
import CombinedEnrolmentChart from "@/components/dashboardPage/main/access/charts/CombinedEnrolmentChart";
import NotCombinedChart from "@/components/dashboardPage/main/access/charts/NotCombinedChart";
import GrossEnrolmentChart from "@/components/dashboardPage/main/access/charts/GrossEnrolmentChart";
import NetEnrolmentChart from "@/components/dashboardPage/main/access/charts/NetEnrolmentChart";
import TotalEnrolmentChart from "@/components/dashboardPage/main/access/charts/TotalEnrolmentChart";
import RepeatersRateChart from "@/components/dashboardPage/main/access/charts/RepeatersRateChart";
import SpecialNeedsChart from "@/components/dashboardPage/main/access/charts/SpecialNeedsChart";
import {ref} from "vue";

export default {
  name: "AccessChartByLevel",
  props: ['level'],
  components: {
    EnrolmentsChart,
    NotCombinedChart,
    CombinedEnrolmentChart,
    GrossEnrolmentChart,
    NetEnrolmentChart,
    TotalEnrolmentChart,
    RepeatersRateChart,
    SpecialNeedsChart,
  },  
  data() {
    return {
      enrolmentKey: 0,    
      combinedKey: 0,
      notCombinedKey: 0,   
      grossEnrolmentKey: 0,   
      netEnrolmentKey: 0,
      totalEnrolmentKey: 0,
      repeaterRateKey: 0,
      specialNeedsKey: 0,
    }
  },
  setup() {
    // const state = Object.entries(stateNLG)[35];
    // const lgs = Object.values(state[1].locals);
    // const localGovs = lgs.map(lg => lg.name)
    const viewByOptions = ['Age', 'Gender', 'Location']
    const enrolmentRateOption = ref('')
    const repetitionRateOption = ref('')
    const dropoutRateOption = ref('')
    const educationLevelOption = ref('')
    const transitionRateOption = ref('')
  

    const infrastructures = [
      {
        name: "Building",
        yearOfEstablisment: "Established 2011",
        condition: "Good"
      },
      {
        name: "Heating",
        yearOfEstablisment: "Established 2012",
        condition: "Good"
      },
      {
        name: "Electricity",
        yearOfEstablisment: "Established 2017",
        condition: "Poor"
      },
      {
        name: "Fence",
        yearOfEstablisment: "Established 2021",
        condition: "Poor"
      }
    ]

    const enrolmentRateChange = (value) => {
      console.log(`selected value is ${value}`);
    }

    const repetitionRateChange = (value) => {
      console.log(`selected value is ${value}`);
    }

    const dropoutRateChange = (value) => {
      console.log(`selected value is ${value}`);
    }

    const educationLevelChange = (value) => {
      console.log(`selected value is ${value}`);
    }

    const transitionRateChange = (value) => {
      console.log(`selected value is ${value}`);
    }

    return {
      option: '',
      infrastructures,
      enrolmentRateOption,
      repetitionRateOption,
      dropoutRateOption,
      educationLevelOption,
      transitionRateOption,
      viewByOptions,

      enrolmentRateChange,
      repetitionRateChange,
      dropoutRateChange,
      educationLevelChange,
      transitionRateChange
    }
  },
  methods: {
    updateCombinedKey() {
      console.log(this.combinedKey);
      this.combinedKey += 1 
      console.log(this.combinedKey);
    }
  }
}
</script>

<style scoped>
  .bordered-header {
    border-bottom: 1px solid #efefef;
  }
  .bordered-header-active {
    border-bottom: 1px solid #0000FF !important;
  }
  .is-good {
    background: hsla(124, 98%, 35%, 0.09) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: green;  
  }
  .is-poor {
    background: hsla(16, 98%, 35%, 0.09) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    color: #B33102;  
    
  }
  .facility-container {
    max-height: 300px;
    overflow-y: scroll;
  }
</style>