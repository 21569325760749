<template>
  <div>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"            
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="viewFLHEDetail(record.key)">
                View
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Table, Button, Space } from "ant-design-vue"
import { ref } from 'vue'

export default {
  name: 'FamilyLifeTable',
  emits: ['viewFamilyLifeInfo'],
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,
  },
  setup(props, {emit}) {
    const columns = [
      { title: 'Submission Title', dataIndex: 'title', width: "75%" },    
      {
        title: 'operation',
        dataIndex: 'operation',        
      },
    ]

    const dataSource = ref([
      {
        key: '0',
        title: 'School Rules, Regulations & Guidelines; \nOrientation Process for Parent, Guardians & Pupils; \nLife Skills-based FLHE HIV/AIDS Education.',
      },
      {
        key: '1',
        title: 'Life Skills-based FLHE: Students Participation And Teacher Training And Teaching.',
      },
    ])

    const viewFLHEDetail = (key) => {
      emit('viewFamilyLifeInfo', key)
    }

    return {
      columns,
      dataSource,     
      viewFLHEDetail,    
    }
  },
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>