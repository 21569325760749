<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Number of public Schools, Enrollment & Teachers</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-sm" aria-label="Users table">
        <thead>
        <tr>
          <th class="align-middle py-3 ps-4"></th>
          <th class="align-middle py-3 ps-4"></th>
          <th class="align-middle text-center py-3 table-cell" colspan="3">Number of pupils</th>
          <th class="align-middle text-center py-3 table-cell" colspan="3">Number of teachers</th>
        </tr>
        <tr>
          <th class="align-middle py-3 ps-4 wider-cell first col-md-2">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">Level</span>
            </div>
          </th>
          <th class="align-middle py-3 wider-cell col-md-2">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">Number of schools</span>
            </div>
          </th>
          <th class="align-middle py-3 table-cell col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">Male</span>
            </div>
          </th>
          <th class="align-middle py-3 table-cell col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">Female</span>
            </div>
          </th>
          <th class="align-middle py-3 table-cell col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">Total</span>
            </div>
          </th>
          <th class="align-middle py-3 table-cell col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">Male</span>
            </div>
          </th>
          <th class="align-middle py-3 table-cell col-md-1" scope="col">Female</th>
          <th class="align-middle py-3 table-cell col-md-1" scope="col">Total</th>
        </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg" 
            v-for="(data, index) in tableData"
            :key="index"            
          >
            <td class="py-3 ps-4 table-cell first">{{ data.level }}</td>
            <td class="py-3 table-cell">{{ data.number_of_schools }}</td>
            <td class="py-3 table-cell">{{ data.number_of_pupils_male }}</td>
            <td class="py-3 table-cell">{{ data.number_of_pupils_female }}</td>
            <td class="py-3 table-cell">{{ data.number_of_pupils_total }}</td>
            <td class="py-3 table-cell">{{ data.number_of_teachers_male }}</td>
            <td class="py-3 table-cell">{{ data.number_of_teachers_female }}</td>
            <td class="py-3 table-cell">{{ data.number_of_teachers_total }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "NumberOfPublicSchoolsEnrolmentAndTeachers",
  setup() {
    const store = useStore() 
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 42, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })

    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>

</style>