<template>
  <div class="mt-2">
    <div class="table-responsive bg-white shadow-sm radius-full mx-5 pt-3 mb-5">
      <a-table
        class="ant-table-striped"
        size="small"      
        :data-source="dataSource"
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
        <a-table-column width="80%">
          <template #title><span class="ps-4">Table Name</span></template>
          <template #default="{record}">
            <span class="ps-4">{{ record.name }}</span>
          </template>
        </a-table-column>
        <a-table-column width="20%" key="action" title="Action">        
          <template #default="{record}">
            <span @click="showTableWithIndex(record.key)">view table</span>
          </template>
        </a-table-column >
      </a-table>
    </div>
  </div>
</template>

<script>

import {useRouter} from "vue-router";
import {Table, TableColumn} from "ant-design-vue";

class Item {
  constructor(key, name) {
    this.key = key
    this.name = name
  }
}

export default {
  name: "StudentsPupils",
  components: {
    'a-table': Table,
    'a-table-column': TableColumn
  },
  setup() {
    const router = useRouter()

    const tables = [
      "Enrolment in public pre-primary education by level, gender and LGA",
      "Enrolment in private pre-primary education by level, gender and LGA",
      "Public and private pre-primary school enrolment by gender and LGA",
      "Enrolment in public primary school by level, gender and LGA (Class 1-6)",
      "Enrolment in private primary school by level, gender and LGA (Class 1-6)",
      "Public and Private Primary school enrolment by gender and LGA",
      "Public primary school enrolment by gender and LGA",
      "Private primary school enrolment by gender and LGA",
      "Combined Public and private primary school enrolment by gender and LGA",
      "Enrolment in public junior secondary school by level, gender and LGA",
      "Enrolment in private junior secondary school by level, gender and LGA",
      "Public and private junior secondary school enrolment by gender and LGA",
      "Public junior secondary school enrolment by gender and LGA",
      "Private junior secondary school enrolment by gender and LGA",
      "Combined Public and private junior secondary school enrolment by gender and LGA",
      "Enrolment in public senior secondary school by level, gender and LGA",
      "Enrolment in private senior secondary school by level, gender and LGA",
      "Public and private senior secondary school enrolment by gender and LGA",
      "Public senior secondary school enrolment by gender and LGA",
      "Private senior secondary school enrolment by gender and LGA",
      "Combined Public and private senior secondary school enrolment by gender and LGA",
      "Enrolment in Public Science and Technical Secondary School by level, gender and LGA (JSS)",
      "Enrolment in Public Science and Technical Secondary School by level, gender and LGA (SSS)",
      "Public Science and Technical Junior Secondary School enrolment by gender and LGA",
      "Public Science and Technical Senior Secondary School enrolment by gender and LGA (with School age)",
      "Enrolment of special needs children in public primary schools",
      "Enrolment of special needs children in public secondary schools",
      "Repeaters in public primary schools by LGA, class and gender",
      "Repeaters in public JSS and SSS schools by LGA, class and gender",
      "Enrolment in public primary with pre-primary education, by gender and LGA",
      "Combined pre-primary school enrolment by gender and LGA including private"
    ]

    const dataSource = Array.from(tables, (item, index) => new Item(index, item))

    const showTableWithIndex = (index) => {
      router.push({ path: `/studentsPupilTables/${index}` })
    }

    return {
      dataSource,
      showTableWithIndex
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style lang="scss">
.big-cell {
  width: 80%;
}
.small-cell {
  width: 20%;
}
.view-table {
  color: #000;
  &:hover {
    text-decoration: underline;
  }
}
</style>
