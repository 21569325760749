<template>
  <div class="graph-container d-flex flex-column bg-white radius-full mt-4 mb-2">
    <div class="d-flex justify-content-between">
      <span class="font-sm fw-bold">Combined Enrolment rate of education</span>
      <a-select 
        :value="enrolmentRateOption"
        style="width: 120px"
        placeholder="View by"
        :options="viewByOptions.map(option => ({value: option}))"
        @change="enrolmentRateChange"
      />
    </div>
    <div class="d-flex">
      <!-- <div v-if="showPie" class="col-5">
        <canvas class="charts-pie" id="combined-enrolment-pie-chart" height="300"></canvas>      
      </div> -->
      <!-- <div :class="showPie ? 'col-7' : 'col-12'"> -->
      <!-- <canvas class="charts" id="combined-enrolment-chart" width="440" height="300"></canvas> -->
      <div class="col-12">
        <canvas class="charts" id="combined-enrolment-chart" height="300"></canvas>
      </div>
    </div>    
  </div>
</template>

<script>
import { computed, onMounted } from '@vue/runtime-dom'
import {Select} from "ant-design-vue"
import Chart from "chart.js/auto";
import { useStore } from 'vuex'
import { watch, ref } from 'vue'

export default {
  name: "CombinedEnrolmentChart",
  components: {
    "a-select": Select,
  },
  emits: ['reload'],
  setup(props, {emit}) {
    const enrolmentRateOption = ref('')
    const viewByOptions = ['LGA', 'Gender', 'Level', 'Age Group']
    const selectedOptions = ref('')
    const store = useStore()
    const params = ref()
    const chartData = ref(null)
    const pieChartData = ref(null)
    const xAxisLabel = ref([])
    const dataSet = ref([])    
    const combinedEnrolmentData = computed(() => store.state.analysis.combinedEnrolment)
    
    const showPie = ref(false)

    const populateChart = () => {      
      showPie.value = false
      dataSet.value = Object.values(combinedEnrolmentData.value.data.map(item => item.no_of_enrolment))
      let checkLGA = Object.values(combinedEnrolmentData.value.data)[0].id.lga
      let checkLevel = Object.values(combinedEnrolmentData.value.data)[0].id.level
      let checkAgeGroup = Object.values(combinedEnrolmentData.value.data)[0].id.age_group
      let checkClassification = Object.values(combinedEnrolmentData.value.data)[0].id.classification
      if (checkLGA !== undefined) {
        enrolmentRateOption.value = 'LGA'
        xAxisLabel.value = Object.values(combinedEnrolmentData.value.data.map(item => item.id.lga))      
      } else if (checkAgeGroup !== undefined) {
        enrolmentRateOption.value = 'Age Group'
        xAxisLabel.value = Object.values(combinedEnrolmentData.value.data.map(item => item.id.age_group))
      } else if (checkLevel !== undefined) {
        enrolmentRateOption.value = 'Level'
        xAxisLabel.value = Object.values(combinedEnrolmentData.value.data.map(item => item.id.level))
      }  else if (checkClassification !== undefined) {
        enrolmentRateOption.value = 'Classification'
        xAxisLabel.value = Object.values(combinedEnrolmentData.value.data.map(item => item.id.classification))
      } else {
        showPie.value = true
        const data = Object.values(combinedEnrolmentData.value.data.map(item => item.id.gender))
        enrolmentRateOption.value = 'Gender'
        xAxisLabel.value = data
        populatePieChart(data, dataSet.value)
      }
      
      chartData.value = {
        type: 'bar',
        data: {
          labels: xAxisLabel.value,
          datasets: [
            {
              label: "Combined Enrolment Rate",
              categoryPercentage: 0.3,
              borderRadius: 2,
              data: dataSet.value,
              backgroundColor: 'rgba(0, 204, 242, 1)',
            },
          ]
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        },
      }
    }

    const populatePieChart = (labels, data) => {
      pieChartData.value = {
        type: 'pie',
        labels: labels,
        datasets: [{
          data: data
        }],
        options: {
          plugins: {
            legend: false,
            tooltip: false,
          }, 
          elements: {
            arc: {
              backgroundColor: 'rgba(0, 204, 242, 1)',
              hoverBackgroundColor: 'rgba(0, 102, 121, .7)'
            }
          }
        }
      }
      // const pieChart = document.getElementById('combined-enrolment-pie-chart')
      // new Chart(pieChart, pieChartData.value)
    }

    // const colorize = (opaque, hover, ctx) {
    //   const v = ctx.parsed
    //   const c = v < -50 ? '#D60000'
    //       : v < 0 ? '#F46300'
    //       : v < 50 ? '#0358B6'
    //       : '#44DE28'
    //   const opacity = hover ? 1 - Math.abs(v / 150) - 0.2 : 1 - Math.abs(v / 150)

    //   return opaque ? c : 
    // }

    watch(
      () => combinedEnrolmentData.value,
      () => {        
        if (combinedEnrolmentData.value !== null && chartData.value === null) {    
          emit('reload')
        }
      }
    )

    onMounted(() => {
      if (combinedEnrolmentData.value === null) {
        store.dispatch('fetchCombinedEnrolmentChart', params.value)
      } else {
        populateChart()
        if (pieChartData.value !== null) {                    
        }
        const combinedEnrolmentChart = document.getElementById('combined-enrolment-chart')     
        new Chart(combinedEnrolmentChart, chartData.value)     
      }
    }) 

    const enrolmentRateChange = (value) => {      
      const param = value.toLowerCase().replace(' ', '_')
      selectedOptions.value = param
      chartData.value = null
      pieChartData.value = null    
      store.dispatch('fetchCombinedEnrolmentChart', param)
    }

    return {
      enrolmentRateOption,
      viewByOptions,
      enrolmentRateChange,
      showPie,
    }
  },
}
</script>

<style scoped>
.charts {
  max-height: 300px;
}
</style>