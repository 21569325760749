<template>
  <div>
    <section id="pre-n-primary">
      <pupils-book-section
          v-if="currentBookStage === stages[0]"
          @submissionDone="gotoNextStage" />
      <pupils-teacher-book-section
          v-if="currentBookStage === stages[1]"
          @submissionDone="gotoNextStage" />
      <care-giver-manual-section
          v-if="currentBookStage === stages[2]"
          @submissionDone="gotoNextStage" />
    </section>
  </div>
</template>

<script>
import PupilsBookSection
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/preprimary/PupilsBookSection";
import PupilsTeacherBookSection
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/preprimary/PupilsTeacherBookSection";
import CareGiverManualSection
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/preprimary/CareGiverManualSection";
import {computed} from "@vue/runtime-core";
import {mapMutations, useStore} from "vuex";

export default {
  name: "BookPrePrimaryAndPrimary",
  components: {CareGiverManualSection, PupilsTeacherBookSection, PupilsBookSection},
  emits: ['prePrimaryStagesCompleted'],
  setup() {
    const store = useStore()
    const stages = ['pupils book', 'teachers book', 'caregiver manuals']
    const currentBookStage = computed(() => store.state.questionnaireService.activeBookStage).value
    if (currentBookStage === null) {
      store.commit('setActiveBookStage', stages[0])
    }

    return {
      stages,
      currentBookStage
    }
  },
  methods: {
    ...mapMutations(['setActiveBookStage']),
    gotoNextStage() {
      const nextStageIndex = this.stages.indexOf(this.currentBookStage) + 1
      if (nextStageIndex < this.stages.length) {
        this.setActiveBookStage(this.stages[nextStageIndex])
      } else {
        this.$emit('prePrimaryStagesCompleted')
      }
    }
  }
}
</script>

<style scoped>

</style>