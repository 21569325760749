<template>
  <div class="row bg-white radius-full mb-4 px-5 pt-5">
    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have playroom for ECCD?
      </label>
      <div class="col-12">
        <input
            type="text"
            class="form-control"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :value="selectedPlayRoom"
        />
        <div
            class="dropdown-menu bg-accent shadow-sm w-100"
            aria-labelledby="featuresDropdown"
        >
          <div class="d-flex flex-column w-100">
            <div class="pe-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    v-model="eccdPlayRoom"
                    name="playRoom"
                    value="YES_BUT_NO_RUGS_OR_SANDFLOOR"
                    id="has-but-no-rug-or-sand-floor"
                />
                <label class="form-check-label" for="has-but-no-rug-or-sand-floor">
                  Yes, but no rugs or sand floor
                </label>
              </div>
            </div>
            <div class="pe-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    v-model="eccdPlayRoom"
                    name="playRoom"
                    value="YES_WITH_PLAY_RUGS"
                    id="has-with-rugs"
                />
                <label class="form-check-label" for="has-with-rugs">
                  Yes, with play rugs
                </label>
              </div>
            </div>
            <div class="pe-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    v-model="eccdPlayRoom"
                    name="playRoom"
                    value="YES_WITH_SAND_FLOOR"
                    id="has-with-sand-floor"
                />
                <label class="form-check-label" for="has-with-sand-floor">
                  Yes, with sand floor
                </label>
              </div>
            </div>
            <div class="pe-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    v-model="eccdPlayRoom"
                    name="playRoom"
                    value="NO_PLAY_ROOM"
                    id="no-playroom"
                />
                <label class="form-check-label" for="no-playroom">
                  No playroom
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have Merry go round as play facilities for ECCD?
      </label>
      <div class="d-flex">
        <div class="col-6 pe-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasMerryGoRound" value="true" v-model="facilityData.merryGoRound" id="yesHasMerryGoRound">
            <label class="form-check-label font-sm" for="yesHasMerryGoRound">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasMerryGoRound" value="false" v-model="facilityData.merryGoRound" id="noMerryGoRound">
            <label class="form-check-label font-sm" for="noMerryGoRound">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have Swing as play facilities for ECCD?
      </label>
      <div class="d-flex">
        <div class="col-6 pe-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasSwing" value="true" v-model="facilityData.swing" id="yesHasSwing">
            <label class="form-check-label font-sm" for="yesHasSwing">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasSwing" value="false" v-model="facilityData.swing" id="noSwing">
            <label class="form-check-label font-sm" for="noSwing">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have Ladder slide as play facilities for ECCD?
      </label>
      <div class="d-flex">
        <div class="col-6 pe-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasLadderSlide" value="true" v-model="facilityData.ladderSlide" id="yesHasLadderSlide">
            <label class="form-check-label font-sm" for="yesHasLadderSlide">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasLadderSlide" value="false" v-model="facilityData.ladderSlide" id="noLadderSlide">
            <label class="form-check-label font-sm" for="noLadderSlide">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have See saw as play facilities for ECCD?
      </label>
      <div class="d-flex">
        <div class="col-6 pe-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasSeeSaw" value="true" v-model="facilityData.seeSaw" id="yesHasSeeSaw">
            <label class="form-check-label font-sm" for="yesHasSeeSaw">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasSeeSaw" value="false" v-model="facilityData.seeSaw" id="noSeeSaw">
            <label class="form-check-label font-sm" for="noSeeSaw">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have Indoor play activity materials as play facilities for ECCD?
      </label>
      <div class="d-flex">
        <div class="col-6 pe-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasIndoorMaterials" value="true" v-model="facilityData.indoorplayActivityMaterials" id="yesHasIndoorMaterials">
            <label class="form-check-label font-sm" for="yesHasIndoorMaterials">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasIndoorMaterials" value="false" v-model="facilityData.indoorplayActivityMaterials" id="noIndoorMaterials">
            <label class="form-check-label font-sm" for="noIndoorMaterials">
              No
            </label>
          </div>
        </div>
      </div>
    </div>


    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have Charts as Learning material?
      </label>
      <div class="d-flex">
        <div class="col-6 pe-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasChart" value="true" v-model="facilityData.charts" id="yesHasChart">
            <label class="form-check-label font-sm" for="yesHasChart">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasChart" value="false" v-model="facilityData.charts" id="noChart">
            <label class="form-check-label font-sm" for="noChart">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have Posters as Learning material?
      </label>
      <div class="d-flex">
        <div class="col-6 pe-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasPoster" value="true" v-model="facilityData.posters" id="yesHasPoster">
            <label class="form-check-label font-sm" for="yesHasPoster">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasPoster" value="false" v-model="facilityData.posters" id="noPoster">
            <label class="form-check-label font-sm" for="noPoster">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have Toys as Learning material?
      </label>
      <div class="d-flex">
        <div class="col-6 pe-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasToys" value="true" v-model="facilityData.toys" id="yesHasToys">
            <label class="form-check-label font-sm" for="yesHasToys">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasToys" value="false" v-model="facilityData.toys" id="noToys">
            <label class="form-check-label font-sm" for="noToys">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have Audio/visual (radio, TV, DVD) as Learning material?
      </label>
      <div class="d-flex">
        <div class="col-6 pe-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasAudioVideo" value="true" v-model="facilityData.audioVisual" id="yesHasAudioVideo">
            <label class="form-check-label font-sm" for="yesHasAudioVideo">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasAudioVideo" value="false" v-model="facilityData.audioVisual" id="noAudioVideo">
            <label class="form-check-label font-sm" for="noAudioVideo">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have Word puzzle box as Learning material?
      </label>
      <div class="d-flex">
        <div class="col-6 pe-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasPuzzleBox" value="true" v-model="facilityData.wordPuzzleBox" id="yesHasPuzzleBox">
            <label class="form-check-label font-sm" for="yesHasPuzzleBox">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasPuzzleBox" value="false" v-model="facilityData.wordPuzzleBox" id="noPuzzleBox">
            <label class="form-check-label font-sm" for="noPuzzleBox">
              No
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        Does this school have Caregiver guide as Learning material?
      </label>
      <div class="d-flex">
        <div class="col-6 pe-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasCaregiverGuide" value="true" v-model="facilityData.caregiverGuide" id="yesHasCaregiverGuide">
            <label class="form-check-label font-sm" for="yesHasCaregiverGuide">
              Yes
            </label>
          </div>
        </div>
        <div class="col-6 ps-3">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="hasCaregiverGuide" value="false" v-model="facilityData.caregiverGuide" id="noCaregiverGuide">
            <label class="form-check-label font-sm" for="noCaregiverGuide">
              No
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center my-4">
      <button
          class="facilities-btn custom-btn font-tiny fw-bold"
          @click="submitSharedFacilities"
          :disabled="!isFetched"
      >
        {{ isFetched || showTitle == false ? 'Update' : 'Save' }}
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapActions, mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import {onMounted, ref, watch} from "vue";

export default {
  name: "ECCDFacilities",
  emits: ['reload'],
  props:['showTitle'],
  setup(props, {emit}) {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const eccdFacility = computed(() => store.state.questionnaireService.facilityECCD).value
    const selectedPlayRoom = ref('')
    const eccdPlayRoom = ref('')
    const isFetched = ref(false)

    onMounted(async () => {
      if (eccdFacility === null) {
        await store.dispatch('fetchFacilityECCD', schoolInfo.id)
          .then(() => {
            console.log(store.state.questionnaireService.responseCode)
            if (store.state.questionnaireService.responseCode === 200) {
              emit('reload')
            }
          })
        isFetched.value = true
      }
    })

    let playRoom = '', merryGoRound = '', swing = '', ladderSlide = '', seeSaw = '',
        indoorplayActivityMaterials = '', charts = '', posters = '', toys = '',
        audioVisual = '', wordPuzzleBox = '', caregiverGuide = ''

    if (eccdFacility !== null) {
      ({playRoom, merryGoRound, swing, ladderSlide, seeSaw,
        indoorplayActivityMaterials, charts, posters, toys,
        audioVisual, wordPuzzleBox, caregiverGuide} = eccdFacility)
      isFetched.value = true
      selectedPlayRoom.value = _.capitalize(playRoom).replaceAll("_", " ")
      eccdPlayRoom.value = playRoom
    }

    watch(eccdPlayRoom, () => {
      selectedPlayRoom.value = _.capitalize(eccdPlayRoom.value).replaceAll("_", " ")
    })

    return {
      eccdPlayRoom,
      selectedPlayRoom,
      isFetched,
      facilityData: {
        merryGoRound: merryGoRound,
        swing: swing,
        ladderSlide: ladderSlide,
        seeSaw: seeSaw,
        indoorplayActivityMaterials: indoorplayActivityMaterials,

        charts: charts,
        posters: posters,
        toys: toys,
        audioVisual: audioVisual,
        wordPuzzleBox: wordPuzzleBox,
        caregiverGuide: caregiverGuide,
      }
    }
  },
  computed: {
    schoolInfo() { return this.$store.state.school.ownSchool },
    responseCode() { return this.$store.state.questionnaireService.responseCode }
  },
  methods: {
    ...mapActions(['submitFacilityECCD', 'fetchFacilityECCD', 'updateFacilityECCD']),
    ...mapMutations(['setFacilityECCD']),

    async submitSharedFacilities() {
      this.facilityData['playRoom'] = this.eccdPlayRoom
      const requestBody = {
        id: this.schoolInfo.id,
        body: this.facilityData
      }

      if (this.isFetched) {
        console.log(this.isFetched)
        await this.updateFacilityECCD(requestBody)
          .then(() => {
            if (this.responseCode === 204 || this.responseCode === 200) {
              this.setFacilityECCD(null)
              this.$emit('reload')
            }
          })
      } else {
        await this.submitFacilityECCD(requestBody)
          .then(() => {
            if (this.responseCode === 201) {
              this.$emit('reload')
            }
          })
      }
    }
  }
}
</script>

<style scoped>

</style>