<template>
  <div class="bg-white radius-full py-4 mb-4">
    <section class="seaters p-3">
      <div>
        <a-collapse v-model:activeKey="activeKey" :bordered="false" accordion>
          <template #expandIcon="{ isActive }">
            <caret-right-outlined :rotate="isActive ? 90 : 0" />
          </template>
          <a-collapse-panel
            v-if="hasPrePrimaryOrPrimary"
            :style="customStyle"
            key="1"
            header="Pre-primary and primary"
          >
            <section class="mb-5 px-3">
              <div class="d-flex mb-2">
                <div class="col-3"><p class="font-sm fw-bold"></p></div>
                <span class="col me-1 text-center font-sm fw-normal"
                  >1 Seater</span
                >
                <span class="col me-1 text-center font-sm fw-normal"
                  >2 Seater</span
                >
                <span class="col me-1 text-center font-sm fw-normal"
                  >3 Seater</span
                >
                <span class="col me-1 text-center font-sm fw-normal"
                  >4 Seater</span
                >
                <span class="col me-1 text-center font-sm fw-normal"
                  >5 Seater</span
                >
                <span class="col me-1 text-center font-sm fw-normal"
                  >6 Seater</span
                >
              </div>
              <div class="d-flex mb-2">
                <div class="col-3">
                  <span class="font-sm fw-normal">ECCD</span>
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="prePrySeaterData.oneSeaterECCD.numberOfSeater"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="prePrySeaterData.twoSeaterECCD.numberOfSeater"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="prePrySeaterData.threeSeaterECCD.numberOfSeater"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="prePrySeaterData.fourSeaterECCD.numberOfSeater"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="prePrySeaterData.fiveSeaterECCD.numberOfSeater"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0">
                  <input
                    class="form-control outline"
                    v-model="prePrySeaterData.sixSeaterECCD.numberOfSeater"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    aria-label="female"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="col-3">
                  <span class="font-sm fw-normal">Pre-primary</span>
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.oneSeaterPrePrimary.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.twoSeaterPrePrimary.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.threeSeaterPrePrimary.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fourSeaterPrePrimary.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fiveSeaterPrePrimary.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.sixSeaterPrePrimary.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    aria-label="female"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="col-3">
                  <span class="font-sm fw-normal">Primary 1</span>
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.oneSeaterPrimaryOne.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.twoSeaterPrimaryOne.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.threeSeaterPrimaryOne.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fourSeaterPrimaryOne.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fiveSeaterPrimaryOne.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.sixSeaterPrimaryOne.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    aria-label="female"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="col-3">
                  <span class="font-sm fw-normal">Primary 2</span>
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.oneSeaterPrimaryTwo.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.twoSeaterPrimaryTwo.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.threeSeaterPrimaryTwo.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fourSeaterPrimaryTwo.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fiveSeaterPrimaryTwo.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.sixSeaterPrimaryTwo.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    aria-label="female"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="col-3">
                  <span class="font-sm fw-normal">Primary 3</span>
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.oneSeaterPrimaryThree.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.twoSeaterPrimaryThree.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.threeSeaterPrimaryThree.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fourSeaterPrimaryThree.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fiveSeaterPrimaryThree.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.sixSeaterPrimaryThree.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    aria-label="female"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="col-3">
                  <span class="font-sm fw-normal">Primary 4</span>
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.oneSeaterPrimaryFour.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.twoSeaterPrimaryFour.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.threeSeaterPrimaryFour.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fourSeaterPrimaryFour.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fiveSeaterPrimaryFour.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.sixSeaterPrimaryFour.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    aria-label="female"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="col-3">
                  <span class="font-sm fw-normal">Primary 5</span>
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.oneSeaterPrimaryFive.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.twoSeaterPrimaryFive.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.threeSeaterPrimaryFive.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fourSeaterPrimaryFive.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fiveSeaterPrimaryFive.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col px-0">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.sixSeaterPrimaryFive.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    aria-label="female"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="col-3">
                  <span class="font-sm fw-normal">Primary 6</span>
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.oneSeaterPrimarySix.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.twoSeaterPrimarySix.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.threeSeaterPrimarySix.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fourSeaterPrimarySix.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col me-1">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.fiveSeaterPrimarySix.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                  />
                </div>
                <div class="col">
                  <input
                    class="form-control outline"
                    v-model="
                      prePrySeaterData.sixSeaterPrimarySix.numberOfSeater
                    "
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    aria-label="female"
                  />
                </div>
              </div>
            </section>

            <div
              v-if="moeUser !== 'ROLE_MOE_ADMIN'"
              class="d-flex justify-content-center align-items-center"
            >
              <button
                class="custom-btn font-tiny fw-bold"
                @click="submitPrePrimaryAndPrimary"
              >
                {{ isFetched || showTitle == false ? "Update" : "Save" }}
              </button>
            </div>
          </a-collapse-panel>
          <a-collapse-panel
            v-if="hasJSS"
            :style="customStyle"
            key="2"
            header="Junior secondary school"
            :disabled="false"
          >
            <section class="mb-5 px-3">
              <div class="d-flex mb-2">
                <div class="col-6">
                  <p class="font-sm fw-bold"></p>
                </div>
                <span class="col me-1 text-center font-sm fw-normal"
                  >1 Seater</span
                >
                <span class="col me-1 text-center font-sm fw-normal"
                  >2 Seater</span
                >
                <span class="col me-1 text-center font-sm fw-normal"
                  >3 Seater</span
                >
              </div>
              <div class="d-flex mb-2">
                <div class="col-6">
                  <span class="font-sm fw-normal">JSS 1</span>
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="juniorSeaterData.oneSeaterJSOne.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="juniorSeaterData.twoSeaterJSOne.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="juniorSeaterData.threeSeaterJSOne.numberOfSeater"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="col-6">
                  <span class="font-sm fw-normal">JSS 2</span>
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="juniorSeaterData.oneSeaterJSTwo.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="juniorSeaterData.twoSeaterJSTwo.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="juniorSeaterData.threeSeaterJSTwo.numberOfSeater"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="col-6">
                  <span class="font-sm fw-normal">JSS 3</span>
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="juniorSeaterData.oneSeaterJSThree.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="juniorSeaterData.twoSeaterJSThree.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="juniorSeaterData.threeSeaterJSThree.numberOfSeater"
                  />
                </div>
              </div>
            </section>

            <div
              v-if="moeUser !== 'ROLE_MOE_ADMIN'"
              class="d-flex justify-content-center align-items-center"
            >
              <button
                class="custom-btn font-tiny fw-bold"
                @click="submitJunior"
              >
                {{ isFetched || showTitle == false ? "Update" : "Save" }}
              </button>
            </div>
          </a-collapse-panel>
          <a-collapse-panel
            v-if="hasSSS"
            :style="customStyle"
            key="3"
            header="Senior secondary school."
          >
            <section class="mb-5 px-3">
              <div class="d-flex mb-2">
                <div class="col-6">
                  <p class="font-sm fw-bold"></p>
                </div>
                <span class="col me-1 text-center font-sm fw-normal"
                  >1 Seater</span
                >
                <span class="col me-1 text-center font-sm fw-normal"
                  >2 Seater</span
                >
                <span class="col me-1 text-center font-sm fw-normal"
                  >3 Seater</span
                >
              </div>
              <div class="d-flex mb-2">
                <div class="col-6">
                  <span class="font-sm fw-normal">SSS 1</span>
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="seniorSeaterData.oneSeaterSSOne.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="seniorSeaterData.twoSeaterSSOne.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="seniorSeaterData.threeSeaterSSOne.numberOfSeater"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="col-6">
                  <span class="font-sm fw-normal">SSS 2</span>
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="seniorSeaterData.oneSeaterSSTwo.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="seniorSeaterData.twoSeaterSSTwo.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="seniorSeaterData.threeSeaterSSTwo.numberOfSeater"
                  />
                </div>
              </div>
              <div class="d-flex mb-2">
                <div class="col-6">
                  <span class="font-sm fw-normal">SSS 3</span>
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="seniorSeaterData.oneSeaterSSThree.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="seniorSeaterData.twoSeaterSSThree.numberOfSeater"
                  />
                </div>
                <div class="col px-0 me-1">
                  <input
                    class="form-control outline"
                    type="number"
                    :disabled="moeUser === 'ROLE_MOE_ADMIN'"
                    v-model="seniorSeaterData.threeSeaterSSThree.numberOfSeater"
                  />
                </div>
              </div>
            </section>
            <br />
            <br />
            <div
              v-if="moeUser !== 'ROLE_MOE_ADMIN'"
              class="d-flex justify-content-center align-items-center"
            >
              <button
                class="custom-btn font-tiny fw-bold"
                @click="submitSenior"
              >
                {{ isFetched || showTitle == false ? "Update" : "Save" }}
              </button>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, useStore } from "vuex";
import { computed, ref } from "@vue/runtime-core";
import { Collapse, CollapsePanel } from "ant-design-vue";
import { CaretRightOutlined } from "@ant-design/icons-vue";
import { onMounted } from "@vue/runtime-dom";
import {
  getNumberOfSeaterOrEmpty,
  getSeaterIdOrUndefined,
} from "@/helpers/utils/helper_functions";

export default {
  props: ["showTitle", "schoolInfo"],
  name: "SeatersRegistration",
  components: {
    "a-collapse": Collapse,
    "a-collapse-panel": CollapsePanel,
    CaretRightOutlined,
  },
  emits: ["reload"],
  setup(props, { emit }) {
    const store = useStore();
    const fetchedSeatersInfo = computed(() =>
      store.state.questionnaireService.facilitySeaters
        ? store.state.questionnaireService.facilitySeaters[0]
        : store.state.questionnaireService.facilitySeaters
    ).value;
    const responseCode = computed(
      () => store.state.questionnaireService.responseCode
    ).value;
    const isFetched = ref(false);

    onMounted(() => {
      console.log("I get called!");
      if (fetchedSeatersInfo === null) {
        store.dispatch("fetchFacilitySeaters", schoolInfo.id).then(() => {
          if (responseCode === 200) {
            emit("reload");
          }
        });
      }
    });

    const schoolInfo = computed(() =>
      props.schoolInfo ? props.schoolInfo : store.state.school.ownSchool
    ).value;
    const customStyle =
      "background: #ffffff; border-radius: 4px; margin-bottom: 20px; border: none;";
    const activeKey = ref(0);
    const specials = [
      "vocation_training",
      "science_technology",
      "science_technology_vocational",
    ];
    let levels = [];
    let isScienceOrVocational = ref(false);
    if (schoolInfo !== null) {
      levels = schoolInfo.levelsOffered.map((item) => item.toLowerCase());
      isScienceOrVocational.value = specials.includes(
        schoolInfo.schoolType.toLowerCase()
      );
    }
    const hasPrePrimary = levels.includes("pre_primary");
    const hasPrimary = levels.includes("primary");
    const hasJSS = levels.includes("junior");
    const hasSSS = levels.includes("senior");
    const moeUser = computed(() => store.state.auth.user.role);
    const hasPrePrimaryOrPrimary = computed(() => hasPrimary || hasPrePrimary);
    if (hasPrePrimaryOrPrimary.value) {
      activeKey.value = 1;
    } else if (hasJSS) {
      activeKey.value = 2;
    } else {
      activeKey.value = 3;
    }

    if (fetchedSeatersInfo !== null) {
      isFetched.value = true;
    }

    const prePrySeaterData = {
      oneSeaterECCD: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_1" && info.schoolClass === "KG"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "KG",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_1" && info.schoolClass === "KG"
            )
          ),
      },
      twoSeaterECCD: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_2" && info.schoolClass === "KG"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "KG",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_2" && info.schoolClass === "KG"
            )
          ),
      },
      threeSeaterECCD: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_3" && info.schoolClass === "KG"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "KG",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_3" && info.schoolClass === "KG"
            )
          ),
      },
      fourSeaterECCD: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_4" && info.schoolClass === "KG"
            )
          ),
        seater: "SEATER_4",
        schoolClass: "KG",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_4" && info.schoolClass === "KG"
            )
          ),
      },
      fiveSeaterECCD: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_5" && info.schoolClass === "KG"
            )
          ),
        seater: "SEATER_5",
        schoolClass: "KG",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_5" && info.schoolClass === "KG"
            )
          ),
      },
      sixSeaterECCD: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_6" && info.schoolClass === "KG"
            )
          ),
        seater: "SEATER_6",
        schoolClass: "KG",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_6" && info.schoolClass === "KG"
            )
          ),
      },

      oneSeaterPrePrimary: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "PRE_PRIMARY",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
      },
      twoSeaterPrePrimary: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "PRE_PRIMARY",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
      },
      threeSeaterPrePrimary: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "PRE_PRIMARY",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
      },
      fourSeaterPrePrimary: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
        seater: "SEATER_4",
        schoolClass: "PRE_PRIMARY",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
      },
      fiveSeaterPrePrimary: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
        seater: "SEATER_5",
        schoolClass: "PRE_PRIMARY",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
      },
      sixSeaterPrePrimary: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
        seater: "SEATER_6",
        schoolClass: "PRE_PRIMARY",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRE_PRIMARY"
            )
          ),
      },

      oneSeaterPrimaryOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY1"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "PRIMARY1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY1"
            )
          ),
      },
      twoSeaterPrimaryOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY1"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "PRIMARY1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY1"
            )
          ),
      },
      threeSeaterPrimaryOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY1"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "PRIMARY1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY1"
            )
          ),
      },
      fourSeaterPrimaryOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY1"
            )
          ),
        seater: "SEATER_4",
        schoolClass: "PRIMARY1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY1"
            )
          ),
      },
      fiveSeaterPrimaryOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY1"
            )
          ),
        seater: "SEATER_5",
        schoolClass: "PRIMARY1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY1"
            )
          ),
      },
      sixSeaterPrimaryOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY1"
            )
          ),
        seater: "SEATER_6",
        schoolClass: "PRIMARY1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY1"
            )
          ),
      },

      oneSeaterPrimaryTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY2"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "PRIMARY2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY2"
            )
          ),
      },
      twoSeaterPrimaryTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY2"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "PRIMARY2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY2"
            )
          ),
      },
      threeSeaterPrimaryTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY2"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "PRIMARY2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY2"
            )
          ),
      },
      fourSeaterPrimaryTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY2"
            )
          ),
        seater: "SEATER_4",
        schoolClass: "PRIMARY2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY2"
            )
          ),
      },
      fiveSeaterPrimaryTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY2"
            )
          ),
        seater: "SEATER_5",
        schoolClass: "PRIMARY2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY2"
            )
          ),
      },
      sixSeaterPrimaryTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY2"
            )
          ),
        seater: "SEATER_6",
        schoolClass: "PRIMARY2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY2"
            )
          ),
      },

      oneSeaterPrimaryThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY3"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "PRIMARY3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY3"
            )
          ),
      },
      twoSeaterPrimaryThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY3"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "PRIMARY3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY3"
            )
          ),
      },
      threeSeaterPrimaryThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY3"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "PRIMARY3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY3"
            )
          ),
      },
      fourSeaterPrimaryThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY3"
            )
          ),
        seater: "SEATER_4",
        schoolClass: "PRIMARY3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY3"
            )
          ),
      },
      fiveSeaterPrimaryThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY3"
            )
          ),
        seater: "SEATER_5",
        schoolClass: "PRIMARY3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY3"
            )
          ),
      },
      sixSeaterPrimaryThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY3"
            )
          ),
        seater: "SEATER_6",
        schoolClass: "PRIMARY3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY3"
            )
          ),
      },

      oneSeaterPrimaryFour: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY4"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "PRIMARY4",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY4"
            )
          ),
      },
      twoSeaterPrimaryFour: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY4"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "PRIMARY4",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY4"
            )
          ),
      },
      threeSeaterPrimaryFour: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY4"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "PRIMARY4",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY4"
            )
          ),
      },
      fourSeaterPrimaryFour: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY4"
            )
          ),
        seater: "SEATER_4",
        schoolClass: "PRIMARY4",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY4"
            )
          ),
      },
      fiveSeaterPrimaryFour: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY4"
            )
          ),
        seater: "SEATER_5",
        schoolClass: "PRIMARY4",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY4"
            )
          ),
      },
      sixSeaterPrimaryFour: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY4"
            )
          ),
        seater: "SEATER_6",
        schoolClass: "PRIMARY4",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY4"
            )
          ),
      },

      oneSeaterPrimaryFive: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY5"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "PRIMARY5",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY5"
            )
          ),
      },
      twoSeaterPrimaryFive: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY5"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "PRIMARY5",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY5"
            )
          ),
      },
      threeSeaterPrimaryFive: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY5"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "PRIMARY5",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY5"
            )
          ),
      },
      fourSeaterPrimaryFive: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY5"
            )
          ),
        seater: "SEATER_4",
        schoolClass: "PRIMARY5",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY5"
            )
          ),
      },
      fiveSeaterPrimaryFive: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY5"
            )
          ),
        seater: "SEATER_5",
        schoolClass: "PRIMARY5",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY5"
            )
          ),
      },
      sixSeaterPrimaryFive: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY5"
            )
          ),
        seater: "SEATER_6",
        schoolClass: "PRIMARY5",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY5"
            )
          ),
      },

      oneSeaterPrimarySix: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY6"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "PRIMARY6",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "PRIMARY6"
            )
          ),
      },
      twoSeaterPrimarySix: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY6"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "PRIMARY6",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "PRIMARY6"
            )
          ),
      },
      threeSeaterPrimarySix: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY6"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "PRIMARY6",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "PRIMARY6"
            )
          ),
      },
      fourSeaterPrimarySix: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY6"
            )
          ),
        seater: "SEATER_4",
        schoolClass: "PRIMARY6",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_4" && info.schoolClass === "PRIMARY6"
            )
          ),
      },
      fiveSeaterPrimarySix: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY6"
            )
          ),
        seater: "SEATER_5",
        schoolClass: "PRIMARY6",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_5" && info.schoolClass === "PRIMARY6"
            )
          ),
      },
      sixSeaterPrimarySix: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY6"
            )
          ),
        seater: "SEATER_6",
        schoolClass: "PRIMARY6",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_6" && info.schoolClass === "PRIMARY6"
            )
          ),
      },
    };

    const juniorSeaterData = {
      oneSeaterJSOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "JSS1"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "JSS1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "JSS1"
            )
          ),
      },
      twoSeaterJSOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "JSS1"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "JSS1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "JSS1"
            )
          ),
      },
      threeSeaterJSOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "JSS1"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "JSS1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "JSS1"
            )
          ),
      },

      oneSeaterJSTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "JSS2"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "JSS2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "JSS2"
            )
          ),
      },
      twoSeaterJSTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "JSS2"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "JSS2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "JSS2"
            )
          ),
      },
      threeSeaterJSTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "JSS2"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "JSS2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "JSS2"
            )
          ),
      },

      oneSeaterJSThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "JSS3"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "JSS3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_1" && info.schoolClass === "JSS3"
            )
          ),
      },
      twoSeaterJSThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "JSS3"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "JSS3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_2" && info.schoolClass === "JSS3"
            )
          ),
      },
      threeSeaterJSThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "JSS3"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "JSS3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) =>
                info.seater === "SEATER_3" && info.schoolClass === "JSS3"
            )
          ),
      },
    };

    const seniorSeaterData = {
      oneSeaterSSOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_1" && info.schoolClass === "SS1"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "SS1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_1" && info.schoolClass === "SS1"
            )
          ),
      },
      twoSeaterSSOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_2" && info.schoolClass === "SS1"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "SS1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_2" && info.schoolClass === "SS1"
            )
          ),
      },
      threeSeaterSSOne: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_3" && info.schoolClass === "SS1"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "SS1",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_3" && info.schoolClass === "SS1"
            )
          ),
      },

      oneSeaterSSTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_1" && info.schoolClass === "SS2"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "SS2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_1" && info.schoolClass === "SS2"
            )
          ),
      },
      twoSeaterSSTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_2" && info.schoolClass === "SS2"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "SS2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_2" && info.schoolClass === "SS2"
            )
          ),
      },
      threeSeaterSSTwo: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_3" && info.schoolClass === "SS2"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "SS2",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_3" && info.schoolClass === "SS2"
            )
          ),
      },

      oneSeaterSSThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_1" && info.schoolClass === "SS3"
            )
          ),
        seater: "SEATER_1",
        schoolClass: "SS3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_1" && info.schoolClass === "SS3"
            )
          ),
      },
      twoSeaterSSThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_2" && info.schoolClass === "SS3"
            )
          ),
        seater: "SEATER_2",
        schoolClass: "SS3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_2" && info.schoolClass === "SS3"
            )
          ),
      },
      threeSeaterSSThree: {
        id:
          fetchedSeatersInfo &&
          getSeaterIdOrUndefined(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_3" && info.schoolClass === "SS3"
            )
          ),
        seater: "SEATER_3",
        schoolClass: "SS3",
        numberOfSeater:
          fetchedSeatersInfo &&
          getNumberOfSeaterOrEmpty(
            fetchedSeatersInfo.find(
              (info) => info.seater === "SEATER_3" && info.schoolClass === "SS3"
            )
          ),
      },
    };

    return {
      moeUser,
      activeKey,
      hasPrePrimary,
      hasPrimary,
      hasJSS,
      hasSSS,
      hasPrePrimaryOrPrimary,
      notScienceOrVocational: !isScienceOrVocational,
      schoolInfo,
      prePrySeaterData,
      juniorSeaterData,
      seniorSeaterData,
      isFetched,
      customStyle,
    };
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode;
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage;
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message;
      }
      return "";
    },
  },
  methods: {
    ...mapActions(["submitFacilitySeaters", "updateFacilitySeaters"]),
    async submitPrePrimaryAndPrimary() {
      let dataCollection = [];
      if (this.hasPrePrimaryOrPrimary) {
        dataCollection = [
          ...dataCollection,
          ...Object.values(this.prePrySeaterData),
        ];
      }

      if (this.isFetched) {
        await this.updateFacilitySeaters(dataCollection).then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          });
          if (this.responseCode === 204 || this.responseCode === 200) {
            this.$emit("reload");
          }
        });
      } else {
        const requestBody = {
          id: this.schoolInfo.id,
          body: dataCollection,
        };
        await this.submitFacilitySeaters(requestBody).then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          });
          if (this.responseCode === 201) {
            this.$emit("reload");
          }
        });
      }
    },

    async submitJunior() {
      let dataCollection = [];
      if (this.hasJSS) {
        dataCollection = [
          ...dataCollection,
          ...Object.values(this.juniorSeaterData),
        ];
      }

      if (this.isFetched) {
        await this.updateFacilitySeaters(dataCollection).then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          });
          if (this.responseCode === 204 || this.responseCode === 200) {
            this.$emit("reload");
          }
        });
      } else {
        const requestBody = {
          id: this.schoolInfo.id,
          body: dataCollection,
        };
        await this.submitFacilitySeaters(requestBody).then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          });
          if (this.responseCode === 201) {
            this.$emit("reload");
          }
        });
      }
    },
    async submitSenior() {
      let dataCollection = [];
      if (this.hasSSS) {
        dataCollection = [
          ...dataCollection,
          ...Object.values(this.seniorSeaterData),
        ];
      }
      if (this.isFetched) {
        await this.updateFacilitySeaters(dataCollection).then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          });
          if (this.responseCode === 204 || this.responseCode === 200) {
            this.$emit("reload");
          }
        });
      } else {
        const requestBody = {
          id: this.schoolInfo.id,
          body: dataCollection,
        };
        await this.submitFacilitySeaters(requestBody).then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          });
          if (this.responseCode === 201) {
            this.$emit("reload");
          }
        });
      }
    },
  },
};
</script>
