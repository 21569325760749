<template>
  <div class="d-flex flex-column">
    <div class="graph-container d-flex flex-column bg-white radius-full mt-2 mb-2">
      <div class="d-flex justify-content-between">
        <span class="font-sm fw-bold">Enrolment rate of {{ level }} education</span>
        <span data-bs-toggle="dropdown"
              class="align-items-center fw-light font-tiny" aria-expanded="false"
        >
          <span>View by {{ option }}</span>
          <span class="pt-2"><i class="bi bi-chevron-down fw-bold chevron-tiny ms-4"></i></span>
        </span>
        <div class="dropdown-menu dropdown-menu-end py-0 shadow-sm">
          <div class="d-flex flex-column py-2">
            <span class="font-tiny fw-medium py-1 mx-3" @click="option = 'age'">Age</span>
            <span class="font-tiny fw-medium py-1 mx-3" @click="option = 'gender'">Gender</span>
            <span class="font-tiny fw-medium py-1 mx-3" @click="option = 'location'">Location</span>
          </div>
        </div>
      </div>
      <canvas class="charts" id="quality-first-chart" height="300"></canvas>
    </div>
  </div>
</template>

<script>
import stateNLG from "@/helpers/utils/state_local.json";
import {onMounted, ref} from "vue";
import Chart from "chart.js/auto";

export default {
  name: "Management",
  setup() {
    const isActive = ref('pre-primary')
    const level = ref('')
    const option = ref('')
    const state = Object.entries(stateNLG)[35];
    const lgs = Object.values(state[1].locals);
    const localGovs = lgs.map(lg => lg.name)
    const maleFemaleChartData = {
      type: 'bar',
      data: {
        labels: localGovs,
        datasets: [
          {
            label: "# of Underage",
            categoryPercentage: .3,
            borderRadius: 5,
            data: Array.from({length: lgs.length}, () => Math.floor(Math.random() * 100)),
            backgroundColor: 'rgba(0, 204, 242, 1)',
          },
          {
            label: "# of Regular age",
            categoryPercentage: .3,
            borderRadius: 5,
            data: Array.from({length: lgs.length}, () => Math.floor(Math.random() * 100)),
            backgroundColor: "rgba(113, 11, 121, 1)",
          }
        ]
      },
      maintainAspectRatio: false,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom'
          }
        }
      },
    }
    onMounted(() => {
      const firstChart = document.getElementById('quality-first-chart')
      new Chart(firstChart, maleFemaleChartData)
    })

    return {
      level,
      option,
      isActive
    }
  },
}
</script>

<style scoped>

</style>