<template>
  <div>
    <section class="mb-5">
      <p class="fw-bold">Senior Secondary Enrolment by age for the current school year</p>
      <div class="d-flex mb-2">
        <div class="col-6"></div>
        <span class="col-2 text-center font-sm fw-normal">SS1</span>
        <span class="col-2 text-center font-sm fw-normal">SS2</span>
        <span class="col-2 text-center font-sm fw-normal">SS3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">Number of streams</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="ss1Streams.numberOfStream" type="number" aria-label="male">
        </div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="ss2Streams.numberOfStream" type="number" aria-label="female">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="ss3Streams.numberOfStream" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex">
        <div class="col-6"><span class="font-sm fw-normal">Number of streams with multi-grade teaching</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="ss1Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="male">
        </div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="ss2Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="female">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="ss3Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="female">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "SeniorEnrolmentByStreams",
  setup() {
    const ss1Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "SS1"
    }
    const ss2Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "SS2"
    }
    const ss3Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "SS3"
    }

    return {
      ss1Streams,
      ss2Streams,
      ss3Streams
    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    }
  },
  methods: {
    ...mapActions(['submitEnrolmentByStream']),

    async submitForm() {
      const body = {
        requestBody: [this.ss1Streams, this.ss2Streams, this.ss3Streams],
        school: this.ownSchool.id
      }
      await this.submitEnrolmentByStream(body)
          .then(() => {
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>