<template>
  <div class="container d-flex flex-column">
    <p class="font-sm fw-bold mb-2">Click the file to download it:</p>
    <p class="font-tiny">
      Something cool and neat about downloading and using these resources
    </p>

    <div class="d-flex flex-column mt-2">
      <p class="mb-1">
        <span class="skew-box color-ef"></span
        ><span class="ms-2 mb-1 font-sm">
          <span class="fw-bold"
            >Federal Ministry of Education, Nigeria</span
          ></span
        >
      </p>
      <p class="mb-1">
        <span class="skew-box color-ef"></span>
        <span class="ms-2 mb-1 font-sm">
          <span class="fw-bold">Federal Ministry of Education, Nigeria</span>
        </span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ResourceView",
};
</script>
