<template>
  <div class="px-5">
    <p class="font-md fw-bold my-4">Learning Centre Identification</p>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <div class="row justify-content-between px-2 pb-4">
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Centre Code </label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter centre code"
          />
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Year of establishment </label>
          <input
            type="number"
            class="form-control"
            placeholder="Enter centre code"
          />
        </div>

        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Centre name </label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter centre name"
          />
        </div>

        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Centre coordinates
          </label>
          <div class="row col-12 px-0">
            <div class="col-4 pe-3">
              <input
                type="number"
                step="0.0000000001"
                class="form-control"
                placeholder="Elevation (in meters)"
              />
            </div>
            <div class="col-4 pe-3">
              <input
                type="number"
                step="0.0000000001"
                class="form-control"
                placeholder="Longitude (N)"
              />
            </div>
            <div class="col-4">
              <input
                type="number"
                step="0.0000000001"
                class="form-control"
                placeholder="Latitude (E)"
              />
            </div>
          </div>
        </div>

        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Location
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="location" value="urban" id="urban">
                <label class="form-check-label font-sm" for="urban">
                  Urban
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="location" value="rural"  id="rural">
                <label class="form-check-label font-sm" for="rural">
                  Rural
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Name of Centre coordinator
          </label>
          <div class="row col-12 px-0">
            <div class="col-4 pe-3">
              <input
                type="text"
                class="form-control"
                placeholder="First name"
              />
            </div>
            <div class="col-4 pe-3">
              <input
                type="text"
                class="form-control"
                placeholder="Middle name"
              />
            </div>
            <div class="col-4">
              <input
                type="text"
                class="form-control"
                placeholder="Last name"
              />
            </div>
          </div>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Age of Centre coordinator </label>
          <input
            type="number"
            class="form-control"
            placeholder="Enter age of coordinator"
          />
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Years of experience </label>
          <input
            type="number"
            class="form-control"
            placeholder="Enter year of experience"
          />
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Gender
          </label>
          <select class="form-select">
            <option value="">Select gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Ownership </label>
          <select class="form-select">
            <option value="">select school ownership</option>
            <option value="federal">Federal government</option>
            <option value="state">State government</option>
            <option value="lgea">Local government</option>
            <option value="private">Private</option>
            <option value="ngo">Non-governmental organization</option>
            <option value="fbo">Faith-base organization</option>
            <option value="community">Community</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Neighbourhood </label>
          <select class="form-select">
            <option value="">Select neighbourhood</option>
            <option value="market">Market</option>
            <option value="hotel">Hotel</option>
            <option value="motor-park">Motor park</option>
            <option value="mechanic village">Mechanic village</option>
            <option value="highway">Highway</option>
            <option value="factory">Factory</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Time of operation </label>
          <select class="form-select">
            <option value="">Select time of operation</option>
            <option value="morning">Morning</option>
            <option value="evening">Evening</option>
            <option value="night">Night</option>
          </select>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Time of meeting </label>
          <input
            type="number"
            class="form-control"
            placeholder="Enter time of meeting" />
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Days of operation </label>
          <select class="form-select">
            <option value="">Select days of operation</option>
            <option value="monday">Monday</option>
            <option value="tuesday">Tuesday</option>
            <option value="wednesday">Wednesday</option>
            <option value="thursday">Thursday</option>
            <option value="friday">Friday</option>
            <option value="saturday">Saturday</option>
            <option value="sunday">Sunday</option>
          </select>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Accessibility to the Centre </label>
          <select class="form-select">
            <option value="">Select accessibility</option>
            <option value="all season">All season</option>
            <option value="dry season">Dry season</option>
            <option value="rainy season">Rainy season</option>
          </select>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Number of contact hours/week </label>
          <input
            type="number"
            class="form-control"
            placeholder="Enter time of meeting" />
        </div>

      </div>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <input
        type="submit"
        class="custom-btn font-tiny fw-bold"
        value="Continue"
        @click="$emit('continue')"
      />
      <span class="font-sm dm-color my-3">Save and continue later</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CentreIdentification"
}
</script>

<style scoped>

</style>