<template>
  <div>
    <span 
      class="fw-medium" 
      :class="[activeComponent === 'sections' ? 'text-dark' : 'text-muted']"       
      @click="showClassSectionsTable"
    >
      Classroom sections / 
    </span>
    <span
      class="fw-medium" 
      :class="[activeComponent !== 'sections' ? 'text-dark' : 'text-muted']" 
    >
      {{childComponent}}
    </span>

    <clip-loader v-if="loading" class="loader-item mt-3"></clip-loader>

    <classroom-sections-table 
      v-if="activeComponent === 'sections'" 
      @viewSectionData="viewSectionInfo" />

    <available-classrooms-table v-if="activeComponent === dataSource[0]['title']" />

    <registered-classrooms-table 
      v-if="activeComponent === dataSource[1]['title']" 
      @viewClassroomInfo="viewClassroomRegInfo"/>

    <non-classrooms-table 
      v-if="activeComponent === dataSource[2]['title']"
      @viewNonClassroomInfo="viewNonClassroomInfo"    
    />

    <classroom-registration        
      v-if="activeComponent === 'classroomReg'"
      :selectedClassroomInfo="selectedClassroomInfo"
      @reload="backToRegistedClassrooms"
    />

    <non-classroom 
      v-if="activeComponent === 'nonClassroom'"
      :selectedNonClassroom="selectedNonClassroom"
      @reload="backToRegistedClassrooms" />

  </div>
</template>

<script>
import ClassroomRegistration
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/classroom/ClassroomRegistration";
import NonClassroom from "@/components/dashboardPage/school/ASCDashboard/questionnaire/classroom/NonClassroom";
import ClassroomSectionsTable from "@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/classroom/ClassroomSectionsTable";
import AvailableClassroomsTable from './classroom/AvailableClassroomsTable.vue';
import RegisteredClassroomsTable from './classroom/RegisteredClassroomsTable.vue';
import NonClassroomsTable from './classroom/NonClassroomsTable.vue';
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { computed, ref } from '@vue/reactivity';
import { useStore } from 'vuex';

export default {
  name: 'ClassroomViewAndUpdate',
  emits: ['viewClassroomInfo'],
  components: {
    ClassroomSectionsTable,
    AvailableClassroomsTable,
    RegisteredClassroomsTable,
    NonClassroomsTable,
    ClassroomRegistration,
    NonClassroom,
    ClipLoader,
  },
  setup() {  
    const store = useStore()
    const loading = computed(() => store.state.classroom.updateLoading)
    const activeComponent = ref('sections')
    const childComponent = ref('')
    const selectedClassroomInfo = ref()
    const selectedNonClassroom = ref()

    const classroomInfoKey = ref(0)

    const dataSource = ref([
      {
        key: '0',
        title: 'Available Classrooms',
      },
      {
        key: '1',
        title: 'Classroom Registration',
      },
      {
        key: '2',
        title: 'Non Classrooms',
      },      
    ])

    const showClassSectionsTable = () => {
      activeComponent.value = 'sections'
      childComponent.value = ''
    }

    const viewSectionInfo = (detail) => {      
      childComponent.value = detail.title
      activeComponent.value = detail.title
    }

    const viewClassroomRegInfo = (info) => {
      childComponent.value = 'Classroom Registration Update'
      activeComponent.value = 'classroomReg'
      selectedClassroomInfo.value = info
    }

    const viewNonClassroomInfo = (info) => {
      childComponent.value = 'Non-Classroom Update'
      activeComponent.value = 'nonClassroom'
      selectedNonClassroom.value = info
    }

    const backToRegistedClassrooms = () => {
      selectedClassroomInfo.value = undefined
      activeComponent.value = dataSource.value[1]['title']
    }

    return {
      activeComponent,
      childComponent,
      dataSource,
      loading,
      selectedClassroomInfo,
      selectedNonClassroom,
      classroomInfoKey,
      
      viewSectionInfo,
      showClassSectionsTable,
      viewClassroomRegInfo,
      backToRegistedClassrooms,
      viewNonClassroomInfo,
    }
  },
}
</script>

<style>
.loader-item {
  margin: auto;
}
</style>