<template>
  <div>
    <section class="mb-5">
      <p class="font-sm fw-bold">New entrants in Primary 1</p>
      <div class="d-flex mb-2">
        <div class="col-6"></div>
        <span class="col-2 text-center font-sm fw-normal">Male</span>
        <span class="col-2 text-center font-sm fw-normal">Female</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">Below 6 years</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="primary1Male.belowSix6Year" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="primary1Female.belowSix6Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">6 years</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="primary1Male.six6Year" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="primary1Female.six6Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">7 years</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="primary1Male.seven7Year" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="primary1Female.seven7Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">8 years</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="primary1Male.eight8Year" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="primary1Female.eight8Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">9 years</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="primary1Male.nine9Year" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="primary1Female.nine9Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">10 years</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="primary1Male.ten10Year" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="primary1Female.ten10Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">11 years</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="primary1Male.eleven11Year" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="primary1Female.eleven11Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex">
        <div class="col-6"><span class="font-sm fw-normal">Above 11 years</span></div>
        <div class="col-2 me-3">
          <input class="form-control outline" v-model="primary1Male.aboveEeleven11Year" type="number" aria-label="male">
        </div>
        <div class="col-2">
          <input class="form-control outline" v-model="primary1Female.aboveEeleven11Year" type="number" aria-label="female">
        </div>
      </div>

      <div class="d-flex flex-column align-items-center justify-content-center mt-4">
        <button
            class="custom-btn font-tiny fw-bold"
            @click="submitForm"
        >
          Save
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "NewEntrantsPrimaryOne",
  setup() {
    const primary1Male = {
      belowSix6Year: "",
      six6Year: "",
      seven7Year: "",
      eight8Year: "",
      nine9Year: "",
      ten10Year: "",
      eleven11Year: "",
      aboveEeleven11Year: "",
      gender: "MALE",
      schoolClass: "PRIMARY1"
    }
    const primary1Female = {
      belowSix6Year: "",
      six6Year: "",
      seven7Year: "",
      eight8Year: "",
      nine9Year: "",
      ten10Year: "",
      eleven11Year: "",
      aboveEeleven11Year: "",
      gender: "FEMALE",
      schoolClass: "PRIMARY1"
    }

    return {
      primary1Male,
      primary1Female,

    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitEnrolmentNewEntrant']),

    async submitForm() {
      const body = {
        requestBody: [this.primary1Male, this.primary1Female],
        school: this.ownSchool.id
      }

      await this.submitEnrolmentNewEntrant(body)
      .then(() => {
        this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        })
        if (this.responseCode === 201) {
          this.$emit('submissionDone')
        }
      })
    }
  }

}
</script>

<style scoped>

</style>