<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Toilets in public junior secondary schools</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-sm" aria-label="Users table">
        <thead>
          <tr>
            <th class="align-middle py-3 ps-4"></th>
            <th class="align-middle text-center py-3" colspan="5">
              Toilets by type
            </th>
            <th class="align-middle py-3 ps-4"></th>
            <th class="align-middle py-3 ps-4"></th>
          </tr>
          <tr>
            <th class="align-middle py-3 ps-4 bigger-cell first col-md-2">
              LGA
            </th>
            <th class="align-middle py-3 big-cell col-md-2">
              Pit
            </th>
            <th class="align-middle py-3 big-cell col-md-1" scope="col">
              Bucket
            </th>
            <th class="align-middle py-3 big-cell col-md-1" scope="col">
              Flush
            </th>
            <th class="align-middle py-3 big-cell col-md-1" scope="col">
              Other
            </th>
            <th class="align-middle py-3 big-cell col-md-1" scope="col">
              Total
            </th>
            <th class="align-middle py-3 big-cell col-md-1" scope="col">
              Enrolment JSS
            </th>
            <th class="align-middle py-3 small-cell col-md-1" scope="col">
              Pupil-Toilet Ratio
            </th>
          </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg" 
            v-for="(data, index) in tableData"
            :key="index"          
          >
            <td class="py-3 ps-4 bigger-cell first">{{ data.lga }}</td>
            <td class="py-3 big-cell">{{ data.toilets_by_type_pit }}</td>
            <td class="py-3 big-cell">{{ data.toilets_by_type_bucket }}</td>
            <td class="py-3 big-cell">{{ data.toilets_by_type_flush }}</td>
            <td class="py-3 big-cell">{{ data.toilets_by_type_other }}</td>
            <td class="py-3 big-cell">{{ data.toilets_by_type_total }}</td>
            <td class="py-3 big-cell">{{ data.enrolment_jss }}</td>
            <td class="py-3 small-cell">{{ data.pupil_toilet_ratio }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "ToiletPublicJuniorSchool",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 75, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })
    
    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
.bigger-cell {
  width: 18%;
}
.big-cell {
  width: 12%;
}
.small-cell {
  width: 10%;
}
</style>