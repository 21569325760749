<template>
  <div class="d-flex bg-accent vh-100">
    <div class="col-md-10 col-lg-9 mx-auto mt-4">
      <dashboard-header />
      <main class="body">
        <div class="d-flex flex-column">
          <div v-if="false" class="overlay">
            <clip-loader class="loading"></clip-loader>
          </div>
          <div class="d-flex justify-content-between align-items-center my-4">
            <span class="font-normal fw-bold">Audit Trail</span>
          </div>

          <div class="bg-white radius-full shadow-sm mb-5">
            <table class="table table-hover font-sm" aria-label="Audit trail table">
              <thead>
                <tr>
                  <th class="align-middle py-3 ps-4" scope="col">
                    <div class="d-flex flex-nowrap align-items-center">
                      <span>Time stampt</span>
                    </div>
                  </th>
                  <th class="align-middle py-3" scope="col">
                    <div class="d-flex flex-nowrap align-items-center">
                      <span>User name</span>
                    </div>
                  </th>
                  <th class="align-middle py-3" scope="col">
                    <div class="d-flex flex-nowrap align-items-center">
                      <span>User email</span>
                    </div>
                  </th>
                  <th class="align-middle py-3" scope="col">
                    <div class="d-flex flex-nowrap align-items-center">
                      <span>Object</span>
                    </div>
                  </th>
                  <th class="align-middle py-3" scope="col">
                    <div class="d-flex flex-nowrap align-items-center">
                      <span>Action</span>
                    </div>
                  </th>
                  <th class="align-middle py-3" scope="col">
                    <div class="d-flex flex-nowrap align-items-center">
                      <span>Result</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="pointer-no-bg"
                  v-for="(record, index) in records"
                  :key="index"
                >
                  <td class="align-middle py-3 ps-4">{{ new Date(parseInt(record.timestamp)) }}</td>
                  <td class="py-3">{{ record.username }}</td>
                  <td class="py-3">{{ record.email }}</td>
                  <td class="py-3">{{ record.object }}</td>
                  <td class="py-3">{{ record.action }}</td>
                  <td class="py-3">{{ record.result }}</td>          
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-end px-4 pb-4"><span class="font-sm fw-normal">Table number 2 of 6 &lt; &gt; </span></div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { computed } from "@vue/runtime-core";
import { mapMutations, useStore } from "vuex";
import DashboardHeader from '../../components/dashboardPage/DashboardHeader.vue';
import dashboardMixin from '../../helpers/mixins/dashboardMixin.vue';

export default {
  name: "AuditTrailDashboard",
  mixins: [dashboardMixin],
  components: { ClipLoader, DashboardHeader },
  created() {
    this.checkLoader();
  },
  setup() {
    const store = useStore();
    const loading = computed(() => store.getters.loadingStatus).value;
    const records = [
      {timestamp: "1024384013", username: "John Doe", email: "johny@example.com", 
        object: "Questionnaire", action: "Filled questionnaire", result: "Granted"},
      {timestamp: "1024384013", username: "John Doe", email: "johny@example.com", 
        object: "Questionnaire", action: "Filled questionnaire", result: "Granted"},
      {timestamp: "1024384013", username: "John Doe", email: "johny@example.com", 
        object: "Questionnaire", action: "Filled questionnaire", result: "Granted"},
      {timestamp: "1024384013", username: "John Doe", email: "johny@example.com", 
        object: "Questionnaire", action: "Filled questionnaire", result: "Granted"},
      {timestamp: "1024384013", username: "John Doe", email: "johny@example.com", 
        object: "Questionnaire", action: "Filled questionnaire", result: "Granted"},
      {timestamp: "1024384013", username: "John Doe", email: "johny@example.com", 
        object: "Questionnaire", action: "Filled questionnaire", result: "Granted"},                                  
    ]
    return {
      records,
      loading,
    };
  },
  methods: {
    ...mapMutations(["setLoadingStatus"]),

    checkLoader() {
      if (this.users != null) this.setLoadingStatus(false);
    },
  },
};
</script>