<template>
  <div class="d-flex flex-row-reverse bg-accent">    
    <data-table-dashboard-sidebar
      :screens="stages"
      :current-screen="currentStage"
      @gotoSelectedStage="showStage"/>
    <div class="col-md-9 main-content align-self-end">

      <div class="px-5 pt-4">
        <dashboard-header
          :hasBack="hasBack"
          @goBack="goBack"
          @userDashboard="gotoUserDashboard"
          @schoolDashboard="gotoSchoolDashboard"
          @ascDashboard="gotoASCDashboard" />
      </div>      

      <div class="d-flex radius-half bg-white shadow-sm  mx-5 p-3 mt-4  mb-3">
        <div class="col-8 d-flex flex-column ps-2">
          <span class="font-tiny fw-bold mb-2">Select a section</span>
          <div>
            <select
              class="form-select"
              aria-label="Year of fetched data"
              v-model="selectedYear"
              @change="levelChanged($event)"
            >            
              <option v-for="year in feasibleYears" :key="year" :value="year + '/' + (year + 1)">
                {{ year + "/" + (year + 1) }}
              </option>
            </select>
          </div>
        </div>
        <div class="col d-flex flex-column justify-content-end">          
          <div class="d-flex justify-content-start ps-4">
            <button
              class="custom-btn font-tiny fw-bold"              
            >
              Proceed
            </button>
          </div>
        </div>
      </div>

      <p class="fw-bold font-normal px-5">{{ currentStage }}</p>

      <key-indicator-public-primary-school v-if="currentStage === stages[0]" />

      <a-s-c-information-and-coverage 
        @gotoDataTable="currentStage === stages[1]"
        v-if="currentStage === stages[1]" />

      <students-pupils 
        @gotoDataTable="currentStage === stages[2]"
        v-if="currentStage === stages[2]" />

      <teachers 
        @gotoDataTable="currentStage === stages[3]"
        v-if="currentStage === stages[3]" />

      <facilities 
        @gotoDataTable="currentStage === stages[4]"
        v-if="currentStage === stages[4]" />

      <key-education-indicators 
        @gotoDataTable="currentStage === stages[5]"
        v-if="currentStage === stages[5]" />

      <school-age-population-projection 
        @gotoDataTable="currentStage === stages[6]"
        v-if="currentStage === stages[6]" />

      <historical-a-s-c-info 
        v-if="currentStage === stages[7]" />

      <download-report v-if="currentStage === stages[8]" />

    </div>
    <a-modal :visible="hasMessage" title="Planning message" @cancel="handleOkay" @ok="handleOkay">
      <p>{{ datatableMessage }}</p>
    </a-modal>
    <div v-if="loading" class="overlay">
      <moon-loader class="loading"></moon-loader>
    </div>
  </div>
</template>

<script>
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import dashboardMixinVue from "@/helpers/mixins/dashboardMixin.vue";
import DashboardHeader from "@/components/dashboardPage/DashboardHeader";
import DataTableDashboardSidebar from "@/components/dashboardPage/dataTable/DataTableDashboardSidebar";
import {computed, onMounted, ref} from "vue";
import KeyIndicatorPublicPrimarySchool from "@/components/dashboardPage/dataTable/KeyIndicatorPublicPrimarySchool";
import ASCInformationAndCoverage from "@/components/dashboardPage/dataTable/ASCInformationAndCoverage";
import StudentsPupils from "@/components/dashboardPage/dataTable/StudentsPupils";
import Teachers from "@/components/dashboardPage/dataTable/Teachers";
import Facilities from "@/components/dashboardPage/dataTable/Facilities";
import KeyEducationIndicators from "@/components/dashboardPage/dataTable/KeyEducationIndicators";
import SchoolAgePopulationProjection from "@/components/dashboardPage/dataTable/SchoolAgePopulationProjection";
import HistoricalASCInfo from "@/components/dashboardPage/dataTable/HistoricalASCInfo";
import DownloadReport from "@/components/dashboardPage/dataTable/DownloadReport";
import { Modal } from "ant-design-vue"
import {range} from "@/helpers/utils/helper_functions";
import { useStore } from 'vuex';

export default {
  name: "DataTableDashboard",
  components: {
    DownloadReport,
    HistoricalASCInfo,
    SchoolAgePopulationProjection,
    KeyEducationIndicators,
    Facilities,
    Teachers,
    StudentsPupils,
    ASCInformationAndCoverage,
    KeyIndicatorPublicPrimarySchool,
    DataTableDashboardSidebar,
    DashboardHeader,
    MoonLoader,
    'a-modal': Modal, 
  },
  mixins: [dashboardMixinVue],
  setup() {
    const store = useStore()
    onMounted(() => { 
      store.commit('setDatatableMessage', null)
    })
    const currentStage = ref('')
    const storedStage = computed(() => store.state.datatables.currentStage).value
    const loading = computed(() => store.state.datatables.loadingStatus)
    const selectedYear = ref('')    
    const today = new Date()
    const currentYear = today.getFullYear()
    const feasibleYears = range(currentYear - 10, currentYear);
    const message = ref('')
    
    const datatableMessage = computed(() => store.state.datatables.message)
    const hasMessage = computed(() => datatableMessage.value !== null)

    const stages = [
      "Key indicators in public primary schools", "ASC information & coverage",
      "Students/pupils", "Teachers", "Facilities", "Key education indicators",
      "School age population projections", "Historical ASC information", "Download report"
    ]
    if (storedStage === null) {
      currentStage.value = stages[0]
    } else {
      currentStage.value = stages[storedStage]
    }

    const showStage = (stageIndex) => { currentStage.value = stages[stageIndex]}

    const levelChanged = (event) => {
      store.commit('setDatatableSession', event.target.value)
    }

    const handleOkay = () => {
      store.commit('setDatatableMessage', null)
    }

    return {
      loading,
      hasBack: false,
      stages,
      currentStage,
      feasibleYears,
      selectedYear,
      message,
      hasMessage, 
      datatableMessage,

      showStage,
      levelChanged,
      handleOkay
    }
  }
}
</script>

<style scoped>

</style>