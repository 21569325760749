<template>
  <div>
    <div class="radius-full bg-white px-5 pt-5 pb-1 mb-4">
      <section 
        v-if="hasJSS"
        id="junior-sec">

        <number-of-subject-junior
          v-if="activeStage === 'js1'"
          @submissionDone="continueToNextStage"
          school-class="JSS1" />

        <number-of-subject-junior
          v-if="activeStage === 'js2'"
          @submissionDone="continueToNextStage"
          school-class="JSS2" />

        <number-of-subject-junior
          v-if="activeStage === 'js3'"
          @submissionDone="continueToNextStage"
          school-class="JSS3" />

      </section>  

      <section 
        v-if="hasSSS"
        id="senior-sec">

        <number-of-subject-senior
          v-if="activeStage === 'ss1'"
          @submissionDone="continueToNextStage"
          school-class="SS1" />

        <number-of-subject-senior
          v-if="activeStage === 'ss2'"
          @submissionDone="continueToNextStage"
          school-class="SS2" />

        <number-of-subject-senior
          v-if="activeStage === 'ss3'"
          school-class="SS3" />

      </section> 
    </div>
  </div>
</template>

<script>
import {mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import NumberOfSubjectJunior
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/subjects/levels/NumberOfSubjectJunior";
import NumberOfSubjectSenior
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/subjects/levels/NumberOfSubjectSenior";

export default {
  name: "RegularSchool",
  components: {NumberOfSubjectSenior, NumberOfSubjectJunior},
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const formStage = computed(() => store.state.questionnaireService.activeSubjectStage).value
    const hasJSS = schoolInfo.levelsOffered.includes("JUNIOR")
    const hasSSS = schoolInfo.levelsOffered.includes("SENIOR")
    let stages = []
    const juniorStages = [
      "js1", "js2", "js3"
    ]
    const seniorStages = [
      "ss1", "ss2", "ss3"
    ]
    if (hasJSS) {
      stages = [...juniorStages]
    }
    if (hasSSS) {
      stages = [...stages, ...seniorStages]
    }

    if (formStage === null) {
      store.commit('setActiveSubjectStage', stages[0])
    }

    return {
      hasJSS,
      hasSSS,
      stages,
    }
  },
  computed: {
    activeStage() {
      return this.$store.state.questionnaireService.activeSubjectStage
    }
  },
  methods: {
    ...mapMutations(['setActiveSubjectStage']),

    saveSubject() {
      this.$emit('saveSubject')
    },

    continueToNextStage() {
      let currentStageIndex = this.stages.indexOf(this.activeStage)
      const nextStage = this.stages[++currentStageIndex]
      console.log(currentStageIndex)
      console.log(nextStage)
      this.setActiveSubjectStage(nextStage)
    }
  }
}
</script>