<template>
  <div>

    <span 
      class="fw-medium" 
      :class="[activeComponent === 'sections' ? 'text-dark' : 'text-muted']"       
      @click="showSectionsTable"
    >
      Family-Life Sections / 
    </span>
    <span
      class="fw-medium" 
      :class="[activeComponent !== 'sections' ? 'text-dark' : 'text-muted']" 
    >
      {{childComponent}}
    </span>

    <family-life-table 
      v-if="activeComponent === 'family-life'"
      @viewFamilyLifeInfo="viewFamilyLifeInfo"
    />
  
    <f-l-h-e-questions-table v-if="activeComponent === sections[0]['component']" /> 
    
    <f-l-h-e-gender-based-questions-table v-if="activeComponent === sections[1]['component']" />

  
  </div>
</template>

<script>
import FamilyLifeTable from './familyLife/FamilyLifeTable.vue'
import FLHEQuestionsTable from './familyLife/FLHEQuestionsTable.vue'
import FLHEGenderBasedQuestionsTable from './familyLife/FLHEGenderBasedQuestionsTable.vue'
import { ref } from '@vue/reactivity'

export default {
  components: { 
    FamilyLifeTable,
    FLHEQuestionsTable,
    FLHEGenderBasedQuestionsTable 
  },
  name: "FamilyLifeViewAndUpdate",
  setup() {
    const activeComponent = ref('family-life')
    const childComponent = ref()
    const sections = ref([
      {
        key: '0',
        component: 'questions',
        section: 'Section One',
        title: 'School Rules, Regulations & Guidelines; \nOrientation Process for Parent, Guardians & Pupils; \nLife Skills-based FLHE HIV/AIDS Education.',
      },
      {
        key: '1',
        component: 'gender',
        section: 'Section Two',
        title: 'Life Skills-based FLHE: Students Participation And Teacher Training And Teaching.',
      },
    ])

    const viewFamilyLifeInfo = (key) => {
      const object = sections.value.find(item => item.key === `${key}`)
      activeComponent.value = object['component']
      childComponent.value = object['section']
    }

    const showSectionsTable = () => {
      activeComponent.value = 'family-life'
      childComponent.value = ''
    }

    return {
      activeComponent,
      childComponent,

      sections,

      viewFamilyLifeInfo,
      showSectionsTable
    }
  },
}
</script>