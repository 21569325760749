<template>
  <div class="pt-5">
    <a-collapse v-model:activeKey="activeKey" :bordered="false" accordion>
      <template #expandIcon="{ isActive }">
        <caret-right-outlined :rotate="isActive ? 90 : 0" />
      </template>

      <a-collapse-panel :style="customStyle" key="1" header="School Identification">
        <section class="mb-4 mx-3">
          <school-identification :operation="operation" />
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="2" header="School Characteristics">
        <section class="mb-4 mx-3">
          <school-characteristics :operation="operation" />
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="3" header="Enrolment">
        <section class="mb-4 mx-3">
          <enrolments-table 
            @viewEnrolmentInfo="enrolmentInfo" />
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="4" header="Staff">
        <section class="mb-4 mx-3">
          <staff-table
          v-if="!staffOperation"
          :showTitle="false"
          :school-id="3"
          @showStaffProfile="showProfile"
          @editStaff="editStaff "
          />
          <SchoolStaff v-if="staffOperation == 'edit'" :staffData="staffData" operation="edit" :showTitle="false" />
          <staff-profile v-if="staffOperation == 'view' && !isSupervisor" :staff-info="staffInfo" :showTitle="false" />
          <div v-if="staffOperation == 'edit' || staffOperation == 'view'"  class="d-flex align-items-center justify-content-center">
            <button class="custom-btn font-sm fw-bold" @click="staffOperation = ''" >Show staffs table</button>
          </div>
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="5" header="Classrooms">
        <section class="mb-4 mx-3">
          <classroom-view-and-update />
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="6" header="Facilities">
        <section class="mb-4 mx-3">
          <!-- <facility-table @viewFacilityInfo="facilityInfo"/>  -->
          <facilities
        @saveFacilities="saveFacilities"
        :showTitle="false"
        />
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="7" header="Number of students by subject">
        <section class="mb-4 mx-3">
          <student-by-subject-table />
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="8" header="Student/teaher book">
        <section class="mb-4 mx-3">
          <student-teacher-book />
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="9" header="Teachers qualification">
        <section class="mb-4 mx-3">
          <teacher-qualification-view-and-update />
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="10" header="Family life">
        <section class="mb-4 mx-3">
          <family-life-view-and-update />
        </section>
      </a-collapse-panel>
    </a-collapse>

    <transition name="modal">
      <modal v-if="hasMessage" @close="closeModal">
        <template v-slot:body>
          <p>{{ message }}</p>
        </template>
      </modal>
    </transition>
  </div>
</template>


<script>
import { Collapse, CollapsePanel} from "ant-design-vue";
import { CaretRightOutlined } from '@ant-design/icons-vue';
import SchoolIdentification from "@/components/dashboardPage/school/ASCDashboard/questionnaire/SchoolIdentification";
import EnrolmentsTable from "@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/EnrolmentsTable";
import FacilityTable from "@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/FacilityTable";
import ClassroomViewAndUpdate from "@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/ClassroomViewAndUpdate";
import TeacherQualificationViewAndUpdate from "@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/TeacherQualificationViewAndUpdate";
import FamilyLifeViewAndUpdate from "@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/FamilyLifeViewAndUpdate";
import Modal from "@/helpers/utils/Modal";
import SchoolCharacteristics from "@/components/dashboardPage/school/ASCDashboard/questionnaire/SchoolCharacteristics"
import {onMounted, ref, computed} from "vue"
import {useStore} from 'vuex'
import NewSchoolEnrollment from "@/components/dashboardPage/school/ASCDashboard/questionnaire/NewSchoolEnrollment"
import StaffTable from "@/components/dashboardPage/user/StaffTable";
import SchoolStaff from "@/components/dashboardPage/school/ASCDashboard/questionnaire/SchoolStaff";
import StaffProfile from "@/components/dashboardPage/school/ASCDashboard/StaffProfile";
import StudentBySubjectTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/studentBySubjectSection/StudentBySubjectTable.vue';
import StudentTeacherBook from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/studentTeacherBookSection/StudentTeacherBookTable.vue';
import Facilities from "@/components/dashboardPage/school/ASCDashboard/questionnaire/Facilities";
import Roles from "@/authorities/roles";

// import { useStore } from 'vuex';

export default {
  name: "SubmissionReview",
  props: ['institutionId'],
  components: {
    'a-collapse': Collapse,
    'a-collapse-panel': CollapsePanel,
    CaretRightOutlined,
    SchoolIdentification,
    SchoolCharacteristics,
    Modal,
    EnrolmentsTable,
    NewSchoolEnrollment,
    StaffTable,
    SchoolStaff,
    StaffProfile,
    StudentBySubjectTable,
    FacilityTable,
    StudentTeacherBook,
    Facilities,
    ClassroomViewAndUpdate,
    TeacherQualificationViewAndUpdate,
    FamilyLifeViewAndUpdate,
  },
  setup(props, { emit }) {
    const store = useStore()
    const operation = ref('view')
    const staffOperation = ref('')
    const staffData =ref('')
    const staffInfo = ref('')
    const userMe = computed(() => store.getters.loginUser).value;
    const isSupervisor = userMe.role === Roles.supervisor
    const activeKey = ref(0)
    const hasMessage = ref(false)
    const customStyle = 'background: #ffffff; border-radius: 4px; margin-bottom: 10px; border: none;'

    const enrolmentInfo = (key) => { emit('enrolmentInfo', key) }
    const facilityInfo = (key) => { emit('facilityInfo', key) } 
    
    onMounted(() => {      
      console.log(props.institutionId)
      // store.dispatch('viewSubmissionForSchoolWithId', props.institutionId)
    })
    function showProfile(staff){
      staffOperation.value = 'view'
      staffInfo.value = staff
      console.log(staff);
    }
    function editStaff(data){
      staffOperation.value = 'edit'
      staffData.value = data
    }
    function saveFacilities() {
      this.setFacilitiesDone(true)
      this.setPreviousScreen('facilities')
      this.showSubject()
    }

    return {
      activeKey,
      customStyle,
      hasMessage,
      operation,
      enrolmentInfo,
      facilityInfo,
      staffOperation,
      showProfile,
      editStaff,
      staffData,
      staffInfo,
      isSupervisor,
      saveFacilities
    }
  },
}
</script>