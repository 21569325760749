<template>
  <div class="d-flex flex-column px-0">
    <div class="d-flex justify-content-between flex-nowrap mt-4">
      <div class="col d-flex align-items-center">
        <span class="fw-bold font-md">01</span
        ><span class="font-tiny ms-2">{{ firstItem }}</span>
      </div>
      <div class="col d-flex align-items-center">
        <span class="fw-bold font-md">02</span
        ><span class="font-tiny ms-2">{{ secondItem }}</span>
      </div>
      <div class="col d-flex align-items-center">
        <span class="fw-bold font-md">03</span
        ><span class="font-tiny ms-2">{{ thirdItem }}</span>
      </div>
      <div 
        v-if="hasFourthItem"
        class="col d-flex align-items-center">
        <span class="fw-bold font-md">04</span
        ><span class="font-tiny ms-2">{{ fourthItem }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between flex-nowrap mb-2">
      <div class="col d-flex align-items-center pe-2">
        <span 
          @click="$emit('gotoPhaseOne')"
          class="material-icons font-lg dm-color overflow-hidden">
          {{ firstPhase ? 'check_circle' : 'radio_button_unchecked' }}
        </span>
        <span
          :class="[secondPhase ? 'progression-solid' : 'progression-dotted']"
          class="flex-grow-1 ms-2"
        ></span>
      </div>
      <div class="col d-flex align-items-center pe-2">
        <span 
          @click="$emit('gotoPhaseTwo')"
          class="material-icons font-lg dm-color overflow-hidden">
          {{ secondPhase ? 'check_circle' : 'radio_button_unchecked' }}
        </span>      
        <span
          :class="[thirdPhase ? 'progression-solid' : 'progression-dotted']"
          class="flex-grow-1 ms-2"
        ></span>
      </div>
      <div 
        v-if="hasFourthItem"
        class="col d-flex align-items-center pe-2">
        <span 
          @click="$emit('gotoPhaseThree')"
          class="material-icons font-lg dm-color overflow-hidden">
          {{ thirdPhase ? 'check_circle' : 'radio_button_unchecked' }}
        </span>   
        <span
          :class="[fourthPhase ? 'progression-solid' : 'progression-dotted']"
          class="flex-grow-1 ms-2"
        ></span>
      </div>
      <div   
        v-if="hasFourthItem"      
        class="col d-flex align-items-center pe-2">
        <span 
          @click="$emit('gotoPhaseThree')"
          class="material-icons font-lg dm-color overflow-hidden">
          {{ fourthPhase ? 'check_circle' : 'radio_button_unchecked' }}
        </span> 
      </div>
      <div         
        v-else
        class="col d-flex align-items-center pe-2">
        <span 
          @click="$emit('gotoPhaseThree')"
          class="material-icons font-lg dm-color overflow-hidden">
          {{ thirdPhase ? 'check_circle' : 'radio_button_unchecked' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressHeader",
  props: ["firstPhase", "secondPhase", "thirdPhase", "firstItem", "secondItem", "thirdItem", "hasFourthItem", "fourthPhase", "fourthItem"],
};
</script>