<template>
  <div class="px-5">
    <center-facilities-progress-header
      :firstPhase="phase.isEquipment"
      :secondPhase="phase.isClassroom"
      :thirdPhase="phase.isSeatAndDesk"
      :fourthPhase="phase.isToilet"
      :fifthPhase="phase.isLibrary"
      :sixthPhase="phase.isLight"
      :seventhPhase="phase.isWater"
    />

    <p class="font-md fw-bold mt-2 mb-4">Center Facilities and Equipment</p>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <section 
        v-if="stageIsEquipment"
        id="equipment">
        <p class="font-sm fw-bold mt-3 px-3 ms-1">Equipment</p>        
        <div class="row justify-content-between px-2 pb-4">          
          <div class="col-6 px-3">
            <label class="form-label font-sm fw-normal"> Name of equipment </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter name of equipment"
            />
          </div>

          <div class="col-6 px-3">
            <label class="form-label font-sm fw-normal"> Functional quantity available </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter quantity available"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Source </label>
            <select class="form-select">
              <option value="">Select source of equipment</option>
              <option value="federal government">Federal Government</option>
              <option value="state government">State Government</option>
              <option value="local government">Local Government</option>
              <option value="private">Private</option>
              <option value="NGOs">NGOs</option>
              <option value="community">Community</option>
              <option value="FBOs">FBOs</option>
              <option value=""></option>
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number required </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter number required"
            />
          </div>

          <div class="d-flex align-items-center justify-content-center">
          <span
            class="p-2 d-flex align-items-center justify-content-center pointer-no-bg"
          >
            <span class="material-icons-outlined dm-color me-1">
              add_circle_outline
            </span>
            <span class="font-sm fw-normal dm-color my-3"> Save and add new equipment </span>
          </span>
          </div>
        </div>
      </section>

      <section 
        v-if="stageIsClassroom"
        id="classroom">
        <p class="font-sm fw-bold mt-3 px-3 ms-1">Classroom</p>        
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Source of classrooms </label>
            <select class="form-select">
              <option value="">Select source of classrooms</option>
              <option value="federal government">Federal Government</option>
              <option value="state government">State Government</option>
              <option value="local government">Local Government</option>
              <option value="private">Private</option>
              <option value="NGOs">NGOs</option>
              <option value="community">Community</option>
              <option value="FBOs">FBOs</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Functional quantity available </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter number of functional classrooms"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> General classrooms status </label>
            <select class="form-select">
              <option value="">Select status of classrooms</option>
              <option value="useful">Useful</option>
              <option value="need repairs">Need repairs</option>
              <option value="unusable">Unusable</option>
            </select>
          </div>          

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number available </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter number classrooms available"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number required </label>
            <input
                type="number"
                class="form-control"
                placeholder="Enter number required"
            />
          </div>

          <div class="d-flex align-items-center justify-content-center">
          <span
              class="p-2 d-flex align-items-center justify-content-center pointer-no-bg"
          >
            <span class="material-icons-outlined dm-color me-1">
              add_circle_outline
            </span>
            <span class="font-sm fw-normal dm-color my-3"> Save and add new equipment </span>
          </span>
          </div>
        </div>
      </section>

      <section 
        v-if="stageIsSeatsAndDesk"
        id="seat and desk">
        <p class="font-sm fw-bold px-3 ms-1">Seat and desk</p>
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Source of seats and desks </label>
            <select class="form-select">
              <option value="">Select source of seats and desks</option>
              <option value="federal government">Federal Government</option>
              <option value="state government">State Government</option>
              <option value="local government">Local Government</option>
              <option value="private">Private</option>
              <option value="NGOs">NGOs</option>
              <option value="community">Community</option>
              <option value="FBOs">FBOs</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Functional quantity available </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter number of functional seats and desks"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> General seats and desks status </label>
            <select class="form-select">
              <option value="">Select status of seats and desks</option>
              <option value="useful">Useful</option>
              <option value="need repairs">Need repairs</option>
              <option value="unusable">Unusable</option>
            </select>
          </div>          

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number available </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter number seats and desks available"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number required </label>
            <input
                type="number"
                class="form-control"
                placeholder="Enter number required"
            />
          </div>

          <div class="d-flex align-items-center justify-content-center">
          <span
              class="p-2 d-flex align-items-center justify-content-center pointer-no-bg"
          >
            <span class="material-icons-outlined dm-color me-1">
              add_circle_outline
            </span>
            <span class="font-sm fw-normal dm-color my-3"> Save and add new equipment </span>
          </span>
          </div>
        </div>
      </section>

      <section 
        v-if="stageIsToilet"
        id="toilets">
        <p class="font-sm fw-bold px-3 ms-1">Toilet</p>   
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Source of toilets </label>
            <select class="form-select">
              <option value="">Select source of toilets</option>
              <option value="federal government">Federal Government</option>
              <option value="state government">State Government</option>
              <option value="local government">Local Government</option>
              <option value="private">Private</option>
              <option value="NGOs">NGOs</option>
              <option value="community">Community</option>
              <option value="FBOs">FBOs</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Functional quantity available </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter number of functional toilets"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> General toilets status </label>
            <select class="form-select">
              <option value="">Select status of toilets</option>
              <option value="useful">Useful</option>
              <option value="need repairs">Need repairs</option>
              <option value="unusable">Unusable</option>
            </select>
          </div>          

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number available </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter number toilets available"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number required </label>
            <input
                type="number"
                class="form-control"
                placeholder="Enter number required"
            />
          </div>

          <div class="d-flex align-items-center justify-content-center">
          <span
              class="p-2 d-flex align-items-center justify-content-center pointer-no-bg"
          >
            <span class="material-icons-outlined dm-color me-1">
              add_circle_outline
            </span>
            <span class="font-sm fw-normal dm-color my-3"> Save and add new equipment </span>
          </span>
          </div>
        </div>
      </section>

      <section 
        v-if="stageIsLibrary"
        id="library">
        <p class="font-sm fw-bold px-3 ms-1">Library</p>   
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Source of libraries </label>
            <select class="form-select">
              <option value="">Select source of libraries</option>
              <option value="federal government">Federal Government</option>
              <option value="state government">State Government</option>
              <option value="local government">Local Government</option>
              <option value="private">Private</option>
              <option value="NGOs">NGOs</option>
              <option value="community">Community</option>
              <option value="FBOs">FBOs</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Functional quantity available </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter number of functional libraries"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> General libraries status </label>
            <select class="form-select">
              <option value="">Select status of libraries</option>
              <option value="useful">Useful</option>
              <option value="need repairs">Need repairs</option>
              <option value="unusable">Unusable</option>
            </select>
          </div>          

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number available </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter number libraries available"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number required </label>
            <input
                type="number"
                class="form-control"
                placeholder="Enter number required"
            />
          </div>

          <div class="d-flex align-items-center justify-content-center">
          <span
              class="p-2 d-flex align-items-center justify-content-center pointer-no-bg"
          >
            <span class="material-icons-outlined dm-color me-1">
              add_circle_outline
            </span>
            <span class="font-sm fw-normal dm-color my-3"> Save and add new equipment </span>
          </span>
          </div>
        </div>
      </section>      

      <section 
        v-if="stageIsLight"
        id="light">
        <p class="font-sm fw-bold mt-3 px-3 ms-1">Light</p>   
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-6 px-3">
            <label class="form-label font-sm fw-normal"> Source of light </label>
            <select class="form-select">
              <option value="">Select source of light</option>
              <option value="federal government">Federal Government</option>
              <option value="state government">State Government</option>
              <option value="local government">Local Government</option>
              <option value="private">Private</option>
              <option value="NGOs">NGOs</option>
              <option value="community">Community</option>
              <option value="FBOs">FBOs</option>              
            </select>
          </div>

          <div class="col-6 px-3">
            <label class="form-label font-sm fw-normal"> Type of light source </label>
            <select class="form-select">
              <option value="">Select type of light</option>
              <option value="electricity">Electricity</option>
              <option value="generator">Generator</option>
              <option value="lantern/candles">Lantern/Candles</option>
              <option value="Other">Other</option>              
            </select>
          </div>
        </div>
      </section>

      <section 
        v-if="stageIsWater"
        id="water">
        <p class="font-sm fw-bold px-3 ms-1">Water</p>   
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-6 px-3">
            <label class="form-label font-sm fw-normal"> Source of water </label>
            <select class="form-select">
              <option value="">Select source of water</option>
              <option value="federal government">Federal Government</option>
              <option value="state government">State Government</option>
              <option value="local government">Local Government</option>
              <option value="private">Private</option>
              <option value="NGOs">NGOs</option>
              <option value="community">Community</option>
              <option value="FBOs">FBOs</option>              
            </select>
          </div>

          <div class="col-6 px-3">
            <label class="form-label font-sm fw-normal"> Type of water source </label>
            <select class="form-select">
              <option value="">Select type </option>
              <option value="stream">Stream</option>
              <option value="Borehole">Borehole</option>
              <option value="pipe borne water">Pipe borne water</option>
              <option value="Other">Other</option>              
            </select>
          </div>
        </div>
      </section>

      <div class="mb-3">
        <span v-if="stage !== 0" @click="gotoPreviousStage" class="d-flex align-items-center font-tiny dm-color fw-bold mx-2 float-start pointer-no-bg"> 
          <i class="bi bi-caret-left-fill"></i> 
          <span class="pb-1 ms-1"> Previous </span>
        </span>
        <span v-if="stage !== (stages.length - 1)" @click="gotoNextStage" class="d-flex align-items-center font-tiny dm-color fw-bold mx-2 float-end pointer-no-bg"> 
          <span class="pb-1 me-1"> Next </span>
          <i class="bi bi-caret-right-fill"></i> 
        </span>
      </div>

    </div>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <input
        type="submit"
        class="custom-btn font-tiny fw-bold"
        value="Continue"
        @click="$emit('continue')"
      />
      <span class="font-sm fw-normal dm-color my-3 pointer-no-bg">
        Save and continue later
      </span>
    </div>
  </div>
</template>

<script>
import CenterFacilitiesProgressHeader from '../../../../includes/CenterFacilitiesProgressHeader.vue'
export default {
  name: "CenterFacilities",
  components: { CenterFacilitiesProgressHeader },
  data() {
    return {
      stages: ["Equipment", "Classroom", "Seats and Desk", "Toilet", "Library", "Light", "Water"],
      stage: 0,
      currentStage: "Equipment",
      phase: {
        isEquipment: true,
        isClassroom: false,
        isSeatAndDesk: false,
        isToilet: false,
        isLibrary: false,
        isLight: false,
        isWater: false
      }
    }
  },
  computed: {
    stageIsEquipment(){ return this.currentStage === this.stages[0] },
    stageIsClassroom(){ return this.currentStage === this.stages[1] },
    stageIsSeatsAndDesk(){ return this.currentStage === this.stages[2] },
    stageIsToilet(){ return this.currentStage === this.stages[3] },
    stageIsLibrary(){ return this.currentStage === this.stages[4] },
    stageIsLight(){ return this.currentStage === this.stages[5] },
    stageIsWater(){ return this.currentStage === this.stages[6] },
  },
  methods: {
    gotoNextStage() {
      this.stage = ++this.stage
      if (this.stage === 1) this.phase.isClassroom = true
      if (this.stage === 2) this.phase.isSeatAndDesk = true
      if (this.stage === 3) this.phase.isToilet = true
      if (this.stage === 4) this.phase.isLibrary = true
      if (this.stage === 5) this.phase.isLight = true
      if (this.stage === 6) this.phase.isWater = true
      this.currentStage = this.stages[this.stage]
    },

    gotoPreviousStage() {
      this.stage = --this.stage
      if (this.stage === 0) this.phase.isClassroom = false
      if (this.stage === 1) this.phase.isSeatAndDesk = false
      if (this.stage === 2) this.phase.isToilet = false
      if (this.stage === 3) this.phase.isLibrary = false
      if (this.stage === 4) this.phase.isLight = false
      if (this.stage === 5) this.phase.isWater = false
      this.currentStage = this.stages[this.stage]
    }
  },
  
}
</script>

<style scoped>

</style>