<template>
  <div>
    <section id="SSS1" class="mb-5 px-lg-5">
      <p class="font-sm fw-bold">Number of students by subject in {{ schoolClass }}</p>
      <div class="d-flex mb-2">
        <div class="col-6"></div>
        <span class="col-3 text-center font-sm fw-normal">Male</span>
        <span class="col-3 text-center font-sm fw-normal">Female</span>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">English</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleEnglish.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleEnglish.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Mathematics</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleMathematics.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleMathematics.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Literature in English</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleLiteratureInEnglish.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleLiteratureInEnglish.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Biology</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleBiology.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleBiology.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Basic science</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleBasicScience.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleBasicScience.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Physics</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMalePhysics.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemalePhysics.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Chemistry</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleChemistry.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleChemistry.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Health science</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleHealthScience.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleHealthScience.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Civic education</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleCivicEducation.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleCivicEducation.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Agric science</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleAgriculture.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleAgriculture.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Computer</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleComputer.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleComputer.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Basic technology</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleBasicTech.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleBasicTech.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Food and nutrition</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleFood.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleFood.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Home management</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleHomeManagement.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleHomeManagement.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Home economics</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleHomeEconomics.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleHomeEconomics.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">History</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleHistory.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleHistory.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Geography</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleGeography.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleGeography.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Economics</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleEconomics.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleEconomics.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Business studies</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleBusinessStudies.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleBusinessStudies.number" type="number" aria-label="female">
        </div>
      </div>

      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Technical drawing</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleTechDrawing.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleTechDrawing.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Automechanics</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleAutomechics.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleAutomechics.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Building construction</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleBuildingConstruction.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleBuildingConstruction.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Woodwork</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleWoodwork.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleWoodwork.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Electrical/electronics</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleElectricalElectronics.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleElectricalElectronics.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Clothing and textile</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleClothing.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleClothing.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">PHE</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMalePHE.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemalePHE.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">French</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleFrench.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleFrench.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Arabic</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleArabic.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleArabic.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Hausa</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleHausa.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleHausa.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Igbo</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleIgbo.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleIgbo.number" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Yoruba</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="subjects.numberOfMaleYoruba.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="subjects.numberOfFemaleYoruba.number" type="number" aria-label="female">
        </div>
      </div>
    </section>
    <div class="d-flex flex-column align-items-center justify-content-center mb-4">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {toRefs} from "vue";
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "NumberOfSubjectSenior",
  props: ['schoolClass'],
  setup(props) {
    const store = useStore()
    const { schoolClass }  = toRefs(props)
    const currentClass = (schoolClass.value).replace(" ", "").toUpperCase()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const subjects = {
      numberOfMaleEnglish: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "ENGLISH",
        number: ''
      },
      numberOfFemaleEnglish: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "ENGLISH",
        number: ''
      },
      numberOfMaleMathematics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "MATHEMATICS",
        number: ''
      },
      numberOfFemaleMathematics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "MATHEMATICS",
        number: ''
      },
      numberOfMaleLiteratureInEnglish: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "LITERATURE",
        number: ''
      },
      numberOfFemaleLiteratureInEnglish: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "LITERATURE",
        number: ''
      },
      numberOfMaleBiology: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "BIOLOGY",
        number: ''
      },
      numberOfFemaleBiology: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "BIOLOGY",
        number: ''
      },
      numberOfMaleBasicScience: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "BASIC_SCIENCE",
        number: ''
      },
      numberOfFemaleBasicScience: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "BASIC_SCIENCE",
        number: ''
      },
      numberOfMalePhysics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "PHYSICS",
        number: ''
      },
      numberOfFemalePhysics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "PHYSICS",
        number: ''
      },
      numberOfMaleChemistry: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "CHEMISTRY",
        number: ''
      },
      numberOfFemaleChemistry: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "CHEMISTRY",
        number: ''
      },
      numberOfMaleHealthScience: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "HEALTH_SCIENCE",
        number: ''
      },
      numberOfFemaleHealthScience: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "HEALTH_SCIENCE",
        number: ''
      },
      numberOfMaleCivicEducation: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "CIVIC_EDUCATION",
        number: ''
      },
      numberOfFemaleCivicEducation: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "CIVIC_EDUCATION",
        number: ''
      },
      numberOfMaleAgriculture: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "AGRIC_SCIENCE",
        number: ''
      },
      numberOfFemaleAgriculture: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "AGRIC_SCIENCE",
        number: ''
      },
      numberOfMaleComputer: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "COMPUTER",
        number: ''
      },
      numberOfFemaleComputer: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "COMPUTER",
        number: ''
      },
      numberOfMaleBasicTech: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "BASIC_TECHNOLOGY",
        number: ''
      },
      numberOfFemaleBasicTech: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "BASIC_TECHNOLOGY",
        number: ''
      },
      numberOfMaleFood: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "FOOD_AND_NUTRITION",
        number: ''
      },
      numberOfFemaleFood: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "FOOD_AND_NUTRITION",
        number: ''
      },
      numberOfMaleHomeManagement: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "HOME_MANAGEMENT",
        number: ''
      },
      numberOfFemaleHomeManagement: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "HOME_MANAGEMENT",
        number: ''
      },
      numberOfMaleHomeEconomics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "HOME_ECONOMICS",
        number: ''
      },
      numberOfFemaleHomeEconomics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "HOME_ECONOMICS",
        number: ''
      },
      numberOfMaleHistory: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "HISTORY",
        number: ''
      },
      numberOfFemaleHistory: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "HISTORY",
        number: ''
      },
      numberOfMaleGeography: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "GEOGRAPHY",
        number: ''
      },
      numberOfFemaleGeography: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "GEOGRAPHY",
        number: ''
      },
      numberOfMaleEconomics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "ECONOMICS",
        number: ''
      },
      numberOfFemaleEconomics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "ECONOMICS",
        number: ''
      },
      numberOfMaleBusinessStudies: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "BUSINESS_STUDIES",
        number: ''
      },
      numberOfFemaleBusinessStudies: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "BUSINESS_STUDIES",
        number: ''
      },
      numberOfMaleTechDrawing: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "TECHNICAL_DRAWING",
        number: ''
      },
      numberOfFemaleTechDrawing: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "TECHNICAL_DRAWING",
        number: ''
      },
      numberOfMaleAutomechics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "AUTOMECHICS",
        number: ''
      },
      numberOfFemaleAutomechics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "AUTOMECHICS",
        number: ''
      },
      numberOfMaleBuildingConstruction: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "BUILDING_CONSTRUCTION",
        number: ''
      },
      numberOfFemaleBuildingConstruction: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "BUILDING_CONSTRUCTION",
        number: ''
      },
      numberOfMaleWoodwork: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "WOODWORK",
        number: ''
      },
      numberOfFemaleWoodwork: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "WOODWORK",
        number: ''
      },
      numberOfMaleElectricalElectronics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "ELECTRICAL_OR_ELECTRONICS",
        number: ''
      },
      numberOfFemaleElectricalElectronics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "ELECTRICAL_OR_ELECTRONICS",
        number: ''
      },
      numberOfMaleClothing: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "CLOTHING_AND_TEXTILE_DESIGN",
        number: ''
      },
      numberOfFemaleClothing: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "CLOTHING_AND_TEXTILE_DESIGN",
        number: ''
      },
      numberOfMalePHE: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "PHE",
        number: ''
      },
      numberOfFemalePHE: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "PHE",
        number: ''
      },
      numberOfMaleFrench: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "FRENCH_LANGUAGE",
        number: ''
      },
      numberOfFemaleFrench: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "FRENCH_LANGUAGE",
        number: ''
      },
      numberOfMaleArabic: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "ARABIC",
        number: ''
      },
      numberOfFemaleArabic: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "ARABIC",
        number: ''
      },
      numberOfMaleIgbo: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "IGBO",
        number: ''
      },
      numberOfFemaleIgbo: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "IGBO",
        number: ''
      },
      numberOfMaleHausa: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "HAUSA",
        number: ''
      },
      numberOfFemaleHausa: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "HAUSA",
        number: ''
      },
      numberOfMaleYoruba: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "YORUBA",
        number: ''
      },
      numberOfFemaleYoruba: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "YORUBA",
        number: ''
      },
    }

    return {
      subjects,
      id: schoolInfo.id,
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitNumberOfStudentBySubject']),

    async submitForm() {
      const {subjects, id} = this
      const entries = Object.entries(subjects)
      let entriesObject = []
      for (let i in entries) {
        entriesObject[i] = entries[i][1]
      }
      const requestObject = {
        id: id,
        body: entriesObject,
      }
      await this.submitNumberOfStudentBySubject(requestObject)
        .then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          })
          if (this.responseCode === 201) {
            this.$emit('submissionDone')
          }
        })
    }
  }
}
</script>

<style scoped>

</style>