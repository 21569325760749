<template>
  <div>
    <form @submit.prevent="submitForm" >
      <section class="first">
        <div class="row justify-content-between px-2 pb-4">
            <div class="col-12 mt-3 px-3">
              <label class="form-label font-sm fw-normal">
                School Level
              </label>
              <select class="form-select" required aria-label="Default select example" @change="checkLevel($event)" v-model="classroomInfo.level">
                <option value="">Select level</option>
                <option value="PRE_PRIMARY">Pre-Primary</option>
                <option value="PRIMARY">Primary</option>
                <option value="JUNIOR">Junior</option>
                <option value="SENIOR">Senior</option>
              </select>
            </div>
            <div :class="[!levelIsPrePrimary ? 'col-12' : 'col-6', 'mt-3', 'px-3']">
              <label class="form-label font-sm fw-normal"> How many classrooms are in this school? </label>
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter class number"
                  required
                  v-model="classroomInfo.numberOfClassrooms"
              />
            </div>
            <div
                v-if="levelIsPrePrimary"
                class="col-6 mt-3 px-3">
              <label class="form-label font-sm fw-normal"> How many playrooms are in this school for ECCD? </label>
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number of playrooms"
                  v-model="numberOfPlayroomsForECCD"
                  required
              />
            </div>
            <div class="col-12 mt-3 px-3">
              <label class="form-label font-sm fw-normal"> Are any classes held outside? (Because classrooms are unusable or insufficient) </label>
              <div class="d-flex">
                <div class="col-6 pe-3">
                  <div class="form-check" aria-label="Type of teaching is full-time">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="hasEnoughSeats"
                        v-model="classroomInfo.hasClassesOutside"
                        value="true"
                        id="has-class-outside"
                        required
                    />
                    <label class="form-check-label font-sm" for="has-class-outside">
                      Yes
                    </label>
                  </div>
                </div>
                <div class="col-6 ps-3">
                  <div class="form-check">
                    <input
                        class="form-check-input"
                        type="radio"
                        name="hasEnoughSeats"
                        v-model="classroomInfo.hasClassesOutside"
                        value="false"
                        id="no-class-outside"
                    />
                    <label class="form-check-label font-sm" for="no-class-outside">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <div class="d-flex flex-column align-items-center justify-content-center mb-4">
        <button v-if="isFetched" class="custom-btn font-tiny fw-bold">Update</button>
        <button v-else class="custom-btn font-tiny fw-bold">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import {mapActions, mapMutations, useStore} from "vuex";
import {reactive, ref} from "vue";
import {computed} from "vue";

export default {
  name: "AvailableClassroom",
  emits: ['reload'],
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const info = computed(() => store.state.questionnaireService.availableClassroom).value
    let id = '', areThereanyClassesHeldOUtside = '', howManyClassroomsAreThereInTheSchool = '',
        howManyPlayRoomsAreTHereInSchoolForEccd = '', schoolLevel = ''
    const isFetched = info !== null
    if (info !== null) {
      ({
        id, areThereanyClassesHeldOUtside,  howManyClassroomsAreThereInTheSchool, howManyPlayRoomsAreTHereInSchoolForEccd,
        schoolLevel
      } = info)
    }

    const classroomInfo = reactive({
      level: schoolLevel,
      numberOfClassrooms: howManyClassroomsAreThereInTheSchool,
      hasClassesOutside: areThereanyClassesHeldOUtside,
    })
    const numberOfPlayroomsForECCD = ref(howManyPlayRoomsAreTHereInSchoolForEccd)

    let levelIsPrePrimary = ref(classroomInfo.level === "PRE_PRIMARY")

    return {
      classroomInfo,
      levelIsPrePrimary,
      numberOfPlayroomsForECCD,
      schoolInfo,
      isFetched,
      id,
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitAvailableClassroom', 'updateAvailableClassroom', 'fetchAvailableClassroom']),
    ...mapMutations(['setAvailableClassroom']),

    async submitForm() {
      const body = {
        school: this.schoolInfo.id,
        schoolLevel: this.classroomInfo.level,
        howManyClassroomsAreThereInTheSchool: this.classroomInfo.numberOfClassrooms,
        areThereanyClassesHeldOUtside: this.classroomInfo.hasClassesOutside
      }
      if (this.levelIsPrePrimary) {
        body["howManyPlayRoomsAreTHereInSchoolForEccd"] = this.numberOfPlayroomsForECCD
      }
      if (this.isFetched) {
        const requestBody = {
          id: this.schoolInfo.id,
          body: body
        }
        await  this.updateAvailableClassroom(requestBody)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 204) {
              this.$emit('gotoNext')
            }
          })
      } else {
        await this.submitAvailableClassroom(body)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 201) {
              this.$emit('gotoNext')
            }
          })
      }
    },

    async checkLevel(event) {
      this.levelIsPrePrimary = event.target.value === "PRE_PRIMARY"
      const requestBody = {
        id: this.schoolInfo.id,
        level: this.classroomInfo.level
      }
      if (event.target.value !== "") {
        await this.fetchAvailableClassroom(requestBody)
            .then(() => {
              this.$notification.open({
                type: this.updateMessage.status,
                message: this.message,
              })
              if (this.responseCode === 200) {
                this.$emit('reload')
              } else {
                this.setAvailableClassroom(null)
                this.classroomInfo.hasClassesOutside = ""
                this.classroomInfo.numberOfClassrooms = ""
              }
            })
      }
    }
  }
}
</script>

<style scoped>

</style>