<template>
  <div class="dashboard-sb col-md-3 position-fixed top-0 start-0 pl-4">
    <div class="d-flex flex-column mt-lg-4 ms-lg-5 ps-lg-3">
      <div class="d-flex flex-nowrap align-items-center text-decoration-none">
        <router-link to="/">
          <img :src="logoSrc" width="40" height="40" alt="" />
        </router-link>
        <span class="font-sm fw-bold ps-2">{{ state }} STATE EMIS</span>
      </div>
      <div class="d-flex flex-column mt-4">
        <div
          v-if="isSupervisor"
          @click="showMySchool"
          class="d-flex flex-nowrap align-items-center mt-1 pointer-no-bg" :class="{'sb-active': mySchoolIsActive}">
          <i class="bi bi-house-door-fill"></i>
          <span class="font-sm ps-2" :class="{'fw-bold': mySchoolIsActive}">Assigned Schools</span>
        </div>
        <div
          v-else
          @click="showMySchool"
          class="d-flex flex-nowrap align-items-center mt-1 pointer-no-bg" :class="{'sb-active': mySchoolIsActive}">
          <i class="bi bi-house-door-fill"></i>
          <span class="font-sm ps-2" :class="{'fw-bold': mySchoolIsActive}">My School</span>
        </div>
        <div
          v-if="!isSupervisor"
          @click="showStaffTable"
          class="d-flex flex-nowrap align-items-center mt-1 pointer-no-bg" :class="{'sb-active': schoolStaffIsActive}">
          <i class="bi bi-person-fill"></i>
          <span class="font-sm ps-2 grey-accent" :class="{'fw-bold': schoolStaffIsActive}">School Staff</span>
        </div>
        <!-- <div 
          v-if="!isSupervisor"
          class="d-flex flex-nowrap align-items-center mt-1">
          <i class="bi bi-people-fill"></i>
          <span class="font-sm ps-2 grey-accent">Learners</span>
        </div>
        <div
          v-if="!isSupervisor"
          @click="showClassTable"
          class="d-flex flex-nowrap align-items-center mt-1 pointer-no-bg" :class="{'sb-active': classroomIsActive}">
          <i class="bi bi-person-fill"></i>
          <span class="font-sm ps-2 grey-accent" :class="{'fw-bold': classroomIsActive}">School Classrooms</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import stateLogo from "@/assets/images/adamawa_logo.png";
import { useStore } from 'vuex';
import { computed } from 'vue'
import Roles from "@/authorities/roles";

export default {
  name: "MySchoolDashboardSidebar",
  data() {
    const logoSrc = stateLogo;
    const state = "Adamawa".toUpperCase();
    const store = useStore()
    const userMe = computed(() => store.getters.loginUser).value;
    const isSupervisor = userMe.role === Roles.supervisor

    return {
      logoSrc,
      state,
      isSupervisor,
      mySchoolIsActive: true,
      schoolStaffIsActive: false,
      classroomIsActive: false,
    };
  },
  methods: {
    showStaffTable() {
      this.mySchoolIsActive = false
      this.classroomIsActive = false
      this.schoolStaffIsActive = true
      this.$emit('showTable')
    },

    showMySchool() {
      this.mySchoolIsActive = true
      this.schoolStaffIsActive = false
      this.classroomIsActive = false
      this.$emit('showMySchool')
    },

    showClassTable() {
      this.mySchoolIsActive = false
      this.schoolStaffIsActive = false
      this.classroomIsActive = true
      this.$emit('showClassTable')
    }
  }
};
</script>
