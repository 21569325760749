<template>
  <div class="dashboard-sb col-md-3 position-fixed top-0 start-0 pl-4">
    <div class="d-flex flex-column mt-lg-4 ms-lg-5 ps-lg-4">
      <div class="d-flex flex-nowrap align-items-center text-decoration-none">
        <router-link to="/">
          <img :src="logoSrc" width="40" height="40" alt="" />
        </router-link>
      </div>
      <div class="d-flex flex-column mt-5 ps-2">
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny material-icons-outlined"
            :class="[
              screens[0] === currentScreen ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
          <!--              stages.centerIdentification ? 'material-icons' : 'material-icons-outlined',-->
            <span class="progress-circle">circle</span>
          </span>
          <span
              @click="gotoStage(0)"
              class="progress-option"
              :class="{'isDone': false, 'isActive': screens[0] === currentScreen}"
          >
            Key indicators in public primary schools
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny material-icons-outlined"
            :class="[
                screens[1] === currentScreen ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle">circle</span>
          </span>
          <span
              @click="gotoStage(1)"
              class="progress-option"
              :class="{'isDone': false, 'isActive': screens[1] === currentScreen}"
          >
            ASC information & coverage
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny material-icons-outlined"
            :class="[
                screens[2] === currentScreen ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle">circle</span>
          </span>
          <span
              @click="gotoStage(2)"
              class="progress-option"
              :class="{'isDone': false, 'isActive': screens[2] === currentScreen}"
          >
            Students/pupils
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny material-icons-outlined"
            :class="[
                screens[3] === currentScreen ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle">circle</span>
          </span>
          <span
              @click="gotoStage(3)"
              class="progress-option"
              :class="{'isDone': false, 'isActive': screens[3] === currentScreen}"
          >
            Teachers
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny material-icons-outlined"
            :class="[
              screens[4] === currentScreen ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle">circle</span>
          </span>
          <span
              @click="gotoStage(4)"
              class="progress-option"
              :class="{'isDone': false, 'isActive': screens[4] === currentScreen}"
          >
            Facilities
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny material-icons-outlined"
            :class="[
                screens[5] === currentScreen ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle">circle</span>
          </span>
          <span
              @click="gotoStage(5)"
              class="progress-option"
              :class="{'isDone': false, 'isActive': screens[5] === currentScreen}"
          >
            Key education indicators
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny material-icons-outlined"
            :class="[
              screens[6] === currentScreen ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle">circle</span>
          </span>
          <span
              @click="gotoStage(6)"
              class="progress-option"
              :class="{'isDone': false, 'isActive': screens[6] === currentScreen}"
          >
            School age population projections
          </span>
        </div>
        <div class="progress-menu grey-accent">
          <span class="progress-circle-container nm-l-tiny material-icons-outlined"
            :class="[
              screens[7] === currentScreen ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle">circle</span>
          </span>
          <span
              @click="gotoStage(7)"
              class="progress-option last-option"
              :class="{'isDone': false, 'isActive': screens[7] === currentScreen}"
          >
            Historical ASC information
          </span>
        </div>
        <div class="progress-menu last grey-accent">
          <span class="progress-circle-container nm-l-tiny material-icons-outlined"
            :class="[
              screens[8] === currentScreen ? 'material-icons' : 'material-icons-outlined',
            ]"
          >
            <span class="progress-circle">circle</span>
          </span>
          <span
              @click="gotoStage(8)"
              class="progress-option last-option"
              :class="{'isDone': false, 'isActive': screens[8] === currentScreen}"
          >
            Download report
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stateLogo from "@/assets/images/adamawa_logo.png";

export default {
  name: "DataTableDashboardSidebar",
  props: ["screens", "currentScreen"],
  emits: ['gotoSelectedStage'],
  setup(props, {emit}) {
    const logoSrc = stateLogo

    const gotoStage = (stageIndex) => {
      emit('gotoSelectedStage', stageIndex)
    }

    return {
      logoSrc,
      gotoStage
    }
  }
}
</script>

<style scoped>

</style>