<template>
  <div class="container d-flex flex-wrap justify-content-center">
    <div class="col-10 col-lg-7">      
      <map-component></map-component>
    </div>
    <div class="col-12 col-lg-5">
      <div
        class="d-flex flex-column ps-5 ms-lg-auto mb-md-4"
      >
        <p class="font-tiny">Showing information for all state.</p>
        <div class="table-container">      
          <public-private-school-info-table @reload="tableKey++" />
        </div>
        <div class="d-flex flex-column mt-5">
          <p class="mb-2 font-tiny">Number of schools</p>
          <p class="mb-1 font-tiny">
            <span class="skew-box purple"></span
            ><span class="ms-2">0 - 20</span>
          </p>
          <p class="mb-1 font-tiny">
            <span class="skew-box deep-blue"></span
            ><span class="ms-2">21- 35</span>
          </p>
          <p class="mb-1 font-tiny">
            <span class="skew-box skye-blue"></span
            ><span class="ms-2">36- 47</span>
          </p>
          <p class="mb-1 font-tiny">
            <span class="skew-box pink-shade"></span
            ><span class="ms-2">48 - 105</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MapComponent from "@/helpers/utils/MapComponent.vue"
import PublicPrivateSchoolInfoTable from "./PublicPrivateSchoolInfoTable.vue"
import lodash from "lodash";
import { onMounted, ref } from '@vue/runtime-dom';
import { useStore } from 'vuex';

export default {
  name: "MapView",
  components: {
    MapComponent,
    PublicPrivateSchoolInfoTable,
  },
  setup() {
    const tableKey = ref(0)
    const store = useStore();
    onMounted(() => {
      store.dispatch('fetchPublicPrivateSchoolRecords');
    })

    return {
      lodash,
      tableKey,
    }
  }
};
</script>

<style lang="scss">
@import "src/style/variable.scss";
@import "~leaflet/dist/leaflet.css";

.table-container {
  padding: 1.5rem 2rem;
  font-size: $sm-size;
  background-color: $accent-color;
  border-radius: $full-rem;
  margin-right: 3rem;
  margin-top: 3rem;
}
</style>