<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2"> Enrolment in private senior school by level, gender and LGA</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-tiny" aria-label="Users table">
        <thead>
          <tr>
            <td class="align-middle py-3 ps-4"></td>
            <td class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-start">
                <span class="font-tiny">SS 1</span>
              </div>
            </td>
            <td class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-start">
                <span class="font-tiny">SS 2</span>
              </div>
            </td>
            <td class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-start">
                <span class="font-tiny">SS 3</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="align-middle py-3 ps-4 table-cell">LGA</td>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
          </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg"
            v-for="(data, index) in tableData"
            :key="index" 
          >
            <td class="py-3 ps-4 first-cell">{{ data.lga }}</td>
            <td class="py-3 cell-size">{{ data.boys_sss_1 }}</td>
            <td class="py-3 cell-size">{{ data.girls_sss_1 }}</td>
            <td class="py-3 cell-size">{{ data.total_sss_1 }}</td>
            <td class="py-3 cell-size">{{ data.boys_sss_2 }}</td>
            <td class="py-3 cell-size">{{ data.girls_sss_2 }}</td>
            <td class="py-3 cell-size">{{ data.total_sss_2 }}</td>
            <td class="py-3 cell-size">{{ data.boys_sss_3 }}</td>
            <td class="py-3 cell-size">{{ data.girls_sss_3 }}</td>
            <td class="py-3 cell-size">{{ data.total_sss_3 }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="10" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "EnrolmentPrivateSeniorSchlByClass",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 15, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })
 
    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
.first-cell {
  width: 19%;
}
.cell-size {
  width: 9%;
}
</style>