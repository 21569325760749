<template>
  <div>
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"           
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>
        <a-modal     
      :visible="showModal" 
      title="Update Number of Students By Subject" 
      @cancel="handleCancel">
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Submit</a-button>
      </template>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="School Class"
            name="schoolClass"
          >
            <input class="form-control" :value="schoolClass" disabled />
          </a-form-item>          
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Text Book"
            name="subject"
          >
            <input class="form-control" :value="subject" disabled />
          </a-form-item>          
        </a-col>
      </a-row>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="Number of Student By Subject"
            name="number"
          >
            <input class="form-control" type="number" :value="availableBook" />
          </a-form-item>          
        </a-col>
      </a-row>          
    </a-modal>
  </div>
</template>

<script>
import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification } from "ant-design-vue"
import { onMounted, ref } from 'vue'
import { useStore, mapActions, mapMutations } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'StudentTeacherBook',
  props: ['title', 'schoolId'],
  mixins: [enrolmentMixinVue],
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,
    'a-modal': Modal,
     'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
    'a-notification': Notification,
  },
  setup() {
    const schoolClass = ref('')
    const subject = ref('')
    const availableBook = ref('')
    const showModal = ref(false)

    const columns = [
      { title: 'School Class', dataIndex: 'schoolClass', width: '15%' }, 
      { title: 'Text Book', dataIndex: 'subject', width: '15%' },
      { title: 'Number of Textbooks', dataIndex: 'availableBook', width: '30%' },           
      {
        title: 'operation',
        dataIndex: 'operation',    
        width: '15%'
      },
    ]

    return {      
      columns, 
      showModal,
      schoolClass,
      subject,
      availableBook,
    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    studentTeacherBookData() {
      return this.$store.state.questionnaireService.studentTeacherBook
    },
    dataSource() {
      const dataItems = []      
      if (this.studentTeacherBookData != null && this.studentTeacherBookData != undefined) {
        const data = Object.values(this.studentTeacherBookData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolClass: element["schoolClass"],
                subject: element["subject"],
                availableBook: element["availableBook"]
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  mounted(){
    const selectedSchollId = this.schoolId ? this.schoolId : this.ownSchool.id
   this.$store.dispatch('fetchStudentTeacherBook', selectedSchollId)

  },
  methods: {
    ...mapActions(['updateStudentTeacherBook']),
    ...mapMutations(['setUpdateMessageLoading']),
     editRecord(key) {
      const detail = this.studentTeacherBookData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolClass = detail.schoolClass
        this.subject = detail.subject
        this.availableBook = detail.availableBook
      }  
    },
     handleSubmit() {
      const payload = {
        id: this.selectedObject.id,
        body: {
          schoolClass: this.schoolClass,
          subject: this.subject,
          availableBook: this.availableBook
        }
      }

      console.log(payload)
      this.updateStudentTeacherBook(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.schoolClass = ''
            this.subject = ''
            this.availableBook = ''
            
            const type = this.updateMessage.status + ""
                        
            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });
          }
        })      
    },
    handleCancel() {
      this.showModal = false
    },
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>