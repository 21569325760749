<template>
  <div class="px-5">
    <!-- <button class="btn btn-link">Download</button> -->
    <a class="btn btn-link" href="/public/Yobe_Asc_Data_Sample.xlsm" download
      >Download</a
    >
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "DownloadReport",
  setup() {
    const store = useStore();
    onMounted(() => {
      store.commit("setCurrentStage", null);
    });
  },
};
</script>

<style scoped></style>
