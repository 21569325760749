<template>
  <div>
    <section class="mb-5 mx-4 pt-4">
      <p class="font-sm fw-bold">Number of core subject Teachers' textbooks available in the School provided by government.</p>
      <div class="d-flex mb-2">
        <div class="col-5"><p class="font-sm fw-medium">Text book</p></div>
        <span class="col me-1 text-center font-sm fw-medium">JSS 1</span>
        <span class="col me-1 text-center font-sm fw-medium">JSS 2</span>
        <span class="col me-1 text-center font-sm fw-medium">JSS 3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">English</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Mathematics</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Social studies</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesJSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesJSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesJSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Basic Science</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicScienceJSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicScienceJSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicScienceJSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Basic technology</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSOneTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSTwoTeacher.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSThreeTeacher.number" type="text" aria-label="male">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "JuniorTeacherBookSection",
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const englishJSSOneTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "JSS1",
      number: ''
    }
    const englishJSSTwoTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "JSS2",
      number: ''
    }
    const englishJSSThreeTeacher = {
      user: "TEACHER",
      subject: "ENGLISH",
      schoolClass: "JSS3",
      number: ''
    }
    const mathJSSOneTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "JSS1",
      number: ''
    }
    const mathJSSTwoTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "JSS2",
      number: ''
    }
    const mathJSSThreeTeacher = {
      user: "TEACHER",
      subject: "MATHEMATICS",
      schoolClass: "JSS3",
      number: ''
    }
    const socialStudiesJSSOneTeacher = {
      user: "TEACHER",
      subject: "SOCIAL_STUDIES",
      schoolClass: "JSS1",
      number: ''
    }
    const socialStudiesJSSTwoTeacher = {
      user: "TEACHER",
      subject: "SOCIAL_STUDIES",
      schoolClass: "JSS2",
      number: ''
    }
    const socialStudiesJSSThreeTeacher = {
      user: "TEACHER",
      subject: "SOCIAL_STUDIES",
      schoolClass: "JSS3",
      number: ''
    }
    const basicScienceJSSOneTeacher = {
      user: "TEACHER",
      subject: "BASIC_SCIENCE",
      schoolClass: "JSS1",
      number: ''
    }
    const basicScienceJSSTwoTeacher = {
      user: "TEACHER",
      subject: "BASIC_SCIENCE",
      schoolClass: "JSS2",
      number: ''
    }
    const basicScienceJSSThreeTeacher = {
      user: "TEACHER",
      subject: "BASIC_SCIENCE",
      schoolClass: "JSS3",
      number: ''
    }
    const basicTechJSSOneTeacher = {
      user: "TEACHER",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS1",
      number: ''
    }
    const basicTechJSSTwoTeacher = {
      user: "TEACHER",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS2",
      number: ''
    }
    const basicTechJSSThreeTeacher = {
      user: "TEACHER",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS3",
      number: ''
    }

    return {
      englishJSSOneTeacher,
      englishJSSTwoTeacher,
      englishJSSThreeTeacher,
      mathJSSOneTeacher,
      mathJSSTwoTeacher,
      mathJSSThreeTeacher,
      socialStudiesJSSOneTeacher,
      socialStudiesJSSTwoTeacher,
      socialStudiesJSSThreeTeacher,
      basicScienceJSSOneTeacher,
      basicScienceJSSTwoTeacher,
      basicScienceJSSThreeTeacher,
      basicTechJSSOneTeacher,
      basicTechJSSTwoTeacher,
      basicTechJSSThreeTeacher,

      schoolInfo,
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    }
  },
  methods: {
    ...mapActions(['submitStudentTeacherBook']),
    submitForm() {
      const subjects = [
        this.englishJSSOneTeacher,
        this.englishJSSTwoTeacher,
        this.englishJSSThreeTeacher,
        this.mathJSSOneTeacher,
        this.mathJSSTwoTeacher,
        this.mathJSSThreeTeacher,
        this.socialStudiesJSSOneTeacher,
        this.socialStudiesJSSTwoTeacher,
        this.socialStudiesJSSThreeTeacher,
        this.basicScienceJSSOneTeacher,
        this.basicScienceJSSTwoTeacher,
        this.basicScienceJSSThreeTeacher,
        this.basicTechJSSOneTeacher,
        this.basicTechJSSTwoTeacher,
        this.basicTechJSSThreeTeacher,
      ]
      this.submitStudentTeacherBook({id: this.schoolInfo.id, body: subjects})
          .then(() => {
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>