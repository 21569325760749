<template>
  <div>
    <form @submit.prevent="submitForm">
      <section class="mb-5 px-lg-5">
        <p class="font-sm fw-bold">Number of students by subject in {{ schoolClass }}</p>
        <div class="d-flex mb-2">
        <div class="col-6"></div>
        <span class="col-3 text-center font-sm fw-normal">Male</span>
        <span class="col-3 text-center font-sm fw-normal">Female</span>
      </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">English</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleEnglish.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleEnglish.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Mathematics</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleMathematics.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleMathematics.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Basic science</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleBasicScience.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleBasicScience.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Biology</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleBiology.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleBiology.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Physics</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMalePhysics.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemalePhysics.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Chemistry</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleChemistry.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleChemistry.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Health Science</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleHealthScience.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleHealthScience.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Agric. Science</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleAgriculture.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleAgriculture.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Home economics</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleHomeEconomics.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleHomeEconomics.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Basic Technology</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleBasicTech.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleBasicTech.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Food & Nutrition</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleFood.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleFood.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">History</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleHistory.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleHistory.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Geography</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleGeography.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleGeography.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Economics</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleEconomics.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleEconomics.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Technical drawing</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleTechDrawing.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleTechDrawing.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Business studies</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleBusinessStudies.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleBusinessStudies.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">PHE</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMalePHE.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemalePHE.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Igbo</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleIgbo.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleIgbo.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Hausa</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleHausa.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleHausa.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Yoruba</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleYoruba.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleYoruba.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Arabic</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleArabic.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleArabic.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Electrical Installation</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleElectricalInstallation.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleElectricalInstallation.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Computer Operation</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleComputerOperation.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleComputerOperation.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Wood Metal Finishing</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleWoodMetalFinishing.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleWoodMetalFinishing.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Auto-mechics</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleAutomechics.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleAutomechics.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Building Construction</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleBuildingConstruction.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleBuildingConstruction.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">General Woodwork</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleGeneralWoodwork.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleGeneralWoodwork.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Electrical/Electronics</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleElectricalElectronics.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleElectricalElectronics.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Clothing/Textile Design</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleClothing.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleClothing.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Upholstery</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleUpholstery.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleUpholstery.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Introduction to building</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleIntroToBuilding.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleIntroToBuilding.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Walls, Floors and Ceiling Finishing</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleWallFloorFinishing.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleWallFloorFinishing.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Concreting</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleConcreting.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleConcreting.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Garment Construction</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleGarmentConstruction.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleGarmentConstruction.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Brick, Block Laying</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleBrickLaying.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleBrickLaying.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Refrigeration and Air Conditioning</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleRefrigeration.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleRefrigeration.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Fabrication and welding</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMaleFabrication.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemaleFabrication.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
          <div class="col-6"><span class="font-sm fw-normal">Plumbing</span></div>
          <div class="col-3 me-3">
            <input class="form-control outline" required v-model="subjects.numberOfMalePlumbing.number" type="number" aria-label="male">
          </div>
          <div class="col-3">
            <input class="form-control outline" required v-model="subjects.numberOfFemalePlumbing.number" type="number" aria-label="female">
          </div>
        </div>
        <div class="d-flex mb-2">
        <div class="col-6"><span class="font-sm fw-normal">Others</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" required v-model="subjects.numberOfMaleOther.number" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" required v-model="subjects.numberOfFemaleOther.number" type="number" aria-label="female">
        </div>
      </div>
      </section>
      <div class="d-flex flex-column align-items-center justify-content-center mb-4">
      <button
        class="custom-btn font-tiny fw-bold"
      >
        Save
      </button>
    </div>
    </form>
  </div>
</template>

<script>
import { toRefs } from "vue";
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "NumberOfSubjectByClassVocational",
  props: ['schoolClass'],
  emits: ['submissionDone'],
  setup(props) {
    const store = useStore()
    const { schoolClass }  = toRefs(props)
    const currentClass = (schoolClass.value).replace(" ", "").toUpperCase()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const subjects = {
      numberOfMaleEnglish: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "ENGLISH",
        number: ''
      },
      numberOfFemaleEnglish: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "ENGLISH",
        number: ''
      },
      numberOfMaleMathematics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "MATHEMATICS",
        number: ''
      },
      numberOfFemaleMathematics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "MATHEMATICS",
        number: ''
      },
      numberOfMaleBasicScience: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "BASIC_SCIENCE",
        number: ''
      },
      numberOfFemaleBasicScience: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "BASIC_SCIENCE",
        number: ''
      },
      numberOfMaleBiology: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "BIOLOGY",
        number: ''
      },
      numberOfFemaleBiology: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "BIOLOGY",
        number: ''
      },
      numberOfMalePhysics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "PHYSICS",
        number: ''
      },
      numberOfFemalePhysics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "PHYSICS",
        number: ''
      },
      numberOfMaleChemistry: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "CHEMISTRY",
        number: ''
      },
      numberOfFemaleChemistry: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "CHEMISTRY",
        number: ''
      },
      numberOfMaleHealthScience: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "HEALTH_SCIENCE",
        number: ''
      },
      numberOfFemaleHealthScience: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "HEALTH_SCIENCE",
        number: ''
      },
      numberOfMaleAgriculture: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "AGRIC_SCIENCE",
        number: ''
      },
      numberOfFemaleAgriculture: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "AGRIC_SCIENCE",
        number: ''
      },
      numberOfMaleHomeEconomics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "HOME_ECONOMICS",
        number: ''
      },
      numberOfFemaleHomeEconomics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "HOME_ECONOMICS",
        number: ''
      },
      numberOfMaleBasicTech: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "BASIC_TECHNOLOGY",
        number: ''
      },
      numberOfFemaleBasicTech: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "BASIC_TECHNOLOGY",
        number: ''
      },
      numberOfMaleFood: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "FOOD_AND_NUTRITION",
        number: ''
      },
      numberOfFemaleFood: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "FOOD_AND_NUTRITION",
        number: ''
      },
      numberOfMaleHistory: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "HISTORY",
        number: ''
      },
      numberOfFemaleHistory: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "HISTORY",
        number: ''
      },
      numberOfMaleGeography: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "GEOGRAPHY",
        number: ''
      },
      numberOfFemaleGeography: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "GEOGRAPHY",
        number: ''
      },
      numberOfMaleEconomics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "ECONOMICS",
        number: ''
      },
      numberOfFemaleEconomics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "ECONOMICS",
        number: ''
      },
      numberOfMaleTechDrawing: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "TECHNICAL_DRAWING",
        number: ''
      },
      numberOfFemaleTechDrawing: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "TECHNICAL_DRAWING",
        number: ''
      },
      numberOfMaleBusinessStudies: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "BUSINESS_STUDIES",
        number: ''
      },
      numberOfFemaleBusinessStudies: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "BUSINESS_STUDIES",
        number: ''
      },
      numberOfMalePHE: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "PHE",
        number: ''
      },
      numberOfFemalePHE: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "PHE",
        number: ''
      },
      numberOfMaleIgbo: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "IGBO",
        number: ''
      },
      numberOfFemaleIgbo: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "IGBO",
        number: ''
      },
      numberOfMaleHausa: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "HAUSA",
        number: ''
      },
      numberOfFemaleHausa: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "HAUSA",
        number: ''
      },
      numberOfMaleYoruba: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "YORUBA",
        number: ''
      },
      numberOfFemaleYoruba: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "YORUBA",
        number: ''
      },
      numberOfMaleArabic: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "ARABIC",
        number: ''
      },
      numberOfFemaleArabic: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "ARABIC",
        number: ''
      },
      numberOfMaleElectricalInstallation: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "ELECTRICAL_INSTALLATION",
        number: ''
      },
      numberOfFemaleElectricalInstallation: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "ELECTRICAL_INSTALLATION",
        number: ''
      },
      numberOfMaleComputerOperation: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "COMPUTER_OPERATION",
        number: ''
      },
      numberOfFemaleComputerOperation: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "COMPUTER_OPERATION",
        number: ''
      },
      numberOfMaleWoodMetalFinishing: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "WOOD_METAL_FINISHING",
        number: ''
      },
      numberOfFemaleWoodMetalFinishing: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "WOOD_METAL_FINISHING",
        number: ''
      },
      numberOfMaleAutomechics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "AUTOMECHICS",
        number: ''
      },
      numberOfFemaleAutomechics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "AUTOMECHICS",
        number: ''
      },
      numberOfMaleBuildingConstruction: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "BUILDING_CONSTRUCTION",
        number: ''
      },
      numberOfFemaleBuildingConstruction: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "BUILDING_CONSTRUCTION",
        number: ''
      },
      numberOfMaleGeneralWoodwork: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "GENERAL_WOODWORK",
        number: ''
      },
      numberOfFemaleGeneralWoodwork: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "GENERAL_WOODWORK",
        number: ''
      },
      numberOfMaleElectricalElectronics: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "ELECTRICAL_OR_ELECTRONICS",
        number: ''
      },
      numberOfFemaleElectricalElectronics: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "ELECTRICAL_OR_ELECTRONICS",
        number: ''
      },
      numberOfMaleClothing: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "CLOTHING_AND_TEXTILE_DESIGN",
        number: ''
      },
      numberOfFemaleClothing: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "CLOTHING_AND_TEXTILE_DESIGN",
        number: ''
      },
      numberOfMaleUpholstery: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "UPHOLSTERY",
        number: ''
      },
      numberOfFemaleUpholstery: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "UPHOLSTERY",
        number: ''
      },
      numberOfMaleIntroToBuilding: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "INTRODUCTION_TO_BUILDING",
        number: ''
      },
      numberOfFemaleIntroToBuilding: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "INTRODUCTION_TO_BUILDING",
        number: ''
      },
      numberOfMaleWallFloorFinishing: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "WALLS_FLOOR_AND_CEILING_FINISHING",
        number: ''
      },
      numberOfFemaleWallFloorFinishing: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "WALLS_FLOOR_AND_CEILING_FINISHING",
        number: ''
      },
      numberOfMaleConcreting: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "CONCRETING",
        number: ''
      },
      numberOfFemaleConcreting: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "CONCRETING",
        number: ''
      },
      numberOfMaleGarmentConstruction: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "GARMENT_CONSTRUCTION",
        number: ''
      },
      numberOfFemaleGarmentConstruction: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "GARMENT_CONSTRUCTION",
        number: ''
      },
      numberOfMaleBrickLaying: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "BRICK_BLOCK_LAYING",
        number: ''
      },
      numberOfFemaleBrickLaying: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "BRICK_BLOCK_LAYING",
        number: ''
      },
      numberOfMaleRefrigeration: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "REFRIGERATION_AND_AIR_CONDITIONING",
        number: ''
      },
      numberOfFemaleRefrigeration: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "REFRIGERATION_AND_AIR_CONDITIONING",
        number: ''
      },
      numberOfMaleFabrication: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "FABRICATION_AND_WELDING",
        number: ''
      },
      numberOfFemaleFabrication: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "FABRICATION_AND_WELDING",
        number: ''
      },
      numberOfMalePlumbing: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "PLUMBING",
        number: ''
      },
      numberOfFemalePlumbing: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "PLUMBING",
        number: ''
      },
      numberOfMaleOther: {
        gender: "MALE",
        schoolClass: currentClass,
        subject: "OTHER",
        number: ''
      },
      numberOfFemaleOther: {
        gender: "FEMALE",
        schoolClass: currentClass,
        subject: "OTHER",
        number: ''
      }
    }
    console.log(Object.entries(subjects)[0])
    return {
      subjects,
      id: schoolInfo.id
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    }
  },
  methods: {
    ...mapActions(['submitNumberOfStudentBySubject']),

    async submitForm() {
      const {subjects, id, responseCode} = this
      const entries = Object.entries(subjects)
      let entriesObject = []
      for (let i in entries) {
        entriesObject[i] = entries[i][1]
      }
      const requestObject = {
        id: id,
        body: entriesObject,
        type: 'v'
      }
      await this.submitNumberOfStudentBySubject(requestObject)
      .then(() => {
        console.log(responseCode)
        if (responseCode === 201) {
          this.$emit('submissionDone')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>