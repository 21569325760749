<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Number of Schools</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-tiny" aria-label="Users table">
        <thead>
          <tr>
            <th class="align-top py-3 ps-4 first-cell first">
                <span class="font-tiny">Original school list</span>
            </th>
            <th class="align-top py-3 other-cell">
                <span class="font-tiny">Pre-primary and primary</span>
            </th>
            <th class="align-top py-3 other-cell">
                <span class="font-tiny">Junior secondary</span>
            </th>
            <th class="align-top py-3 other-cell">
                <span class="font-tiny">Science & tech. schools</span>
            </th>
            <th class="align-top py-3 other-cell">
                <span class="font-tiny">Senior secondary</span>
            </th>
            <th class="align-top py-3 other-cell">
                <span class="font-tiny">Private</span>
            </th>
        </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr 
            v-for="(data, index) in tableData"
            :key="index"             
          >
            <td class="py-3 ps-4 first">{{data.original_school_list}}</td>
            <td class="py-3">{{data.pre_primary_and_primary}}</td>
            <td class="py-3">{{data.junior_secondary}}</td>
            <td class="py-3">{{data.science_and_tech_schools}}</td>
            <td class="py-3">{{data.senior_secondary}}</td>
            <td class="py-3">{{data.private}}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "NumberOfSchools",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 1, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })

    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
  .first-cell {
    width: 20%;
  }
  .other-cell {
    width: 16%;
  }
</style>