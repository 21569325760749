<template>
  <div><div id="map"></div></div>
</template>

<script>
export default {
  name: "MapComponent",
  mounted: function() {
    const simplemaps_countrymap = window.simplemaps_countrymap
    if (simplemaps_countrymap !== null && simplemaps_countrymap !== undefined) {
      simplemaps_countrymap.load()
    }
  },
}
</script>