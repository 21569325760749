<template>
  <div class="px-5">
    <p class="font-md fw-bold mt-2 mb-4">Suggestions for Improvement</p>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <div class="row align-items-center justify-content-between px-2 pb-4">
        <div class="col-11 mt-3 mx-auto px-3">
          <label class="form-label font-sm fw-normal"> Facilitators </label>
          <textarea name="facilitator-ta" rows="4" class="form-control form-control-ta" placeholder="Type text in here..."></textarea>
        </div>

        <div class="col-11 mt-3 mx-auto px-3">
          <label class="form-label font-sm fw-normal"> Learners </label>
          <textarea name="learner-ta" rows="4" class="form-control form-control-ta" placeholder="Type text in here..."></textarea>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <input
        type="submit"
        class="custom-btn font-tiny fw-bold"
        value="Continue"
        @click="$emit('continue')"
      />
      <!-- @click="saveCenterFacilities" -->
      <span class="font-sm fw-normal dm-color my-3 pointer-no-bg">
        Save and continue later
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Suggestions"
}
</script>

<style scoped>

</style>