<template>
  <div>
    <div v-if="selectedClassroom === undefined" class="col-12 d-flex flex-column mx-3 pe-3">
      <span class="font-tiny fw-bold mb-2">Search classrooms by file description?</span>
      <div class="d-flex w-100">
        <div class="custom-input-group bg-accent col-9">
          <span class="p-1"><i class="bi bi-search grey-shade"></i></span>
          <input
              type="text"
              v-model="description"
              class="ph-sm no-outline-input font-tiny fw-bolder"
              placeholder="Search if staff already exist..."
          />
        </div>
        <span
            @click="findClassroom"
            class="btn btn-primary text-center mx-3 py-2 font-sm"
        >
          Find staff
        </span>
      </div>
    </div>
    <section class="second">
      <div class="row justify-content-between px-2 pb-4">
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School type
          </label>
          <select class="form-select" aria-label="Default select example" @change="levelChanged($event)" v-model="classroomInfo.level">
            <option value="">Select level</option>
            <option value="PRE_PRIMARY">Pre-Primary</option>
            <option value="PRIMARY">Primary</option>
            <option value="JUNIOR">Junior</option>
            <option value="SENIOR">Senior</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Class number/label </label>
          <input
              type="text"
              class="form-control"
              placeholder="Enter class number"
              v-model="classroomInfo.classLabel"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Year of construction </label>
          <input
              type="number"
              class="form-control"
              placeholder="Enter year of construction"
              v-model="classroomInfo.yearOfConstruction"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> What is the present condition of this class? </label>
          <select class="form-select" aria-label="present condition" v-model="classroomInfo.presentCondition">
            <option value="">Select class condition</option>
            <option value="GOOD">Good</option>
            <option value="NEED_MINOR_REPAIRS">Needs minor repairs</option>
            <option value="NEED_MAJOR_REPAIRS">Needs major repairs</option>
            <option value="UNDER_CONSTRUCTION">Under construction</option>
            <option value="UNUSABLE">Unusable</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Length of classroom (in metres) </label>
          <input
              type="number"
              class="form-control"
              v-model="classroomInfo.length"
              placeholder="Enter length of classroom"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Width of classroom (in metres) </label>
          <input
              type="number"
              class="form-control"
              v-model="classroomInfo.width"
              placeholder="Enter width of classroom"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            What material is the floor made of?
          </label>
          <select
              class="form-select"
              aria-label="Material floor is made of"
              v-model="classroomInfo.floorMaterial"
          >
            <option value="">Select material</option>
            <option value="MUD_EARTH">Mud</option>
            <option value="CONCRETE">Concrete</option>
            <option value="WOOD">Wood</option>
            <option value="TILE_TERRAZZO">Tile/Terrazzo</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            What material is the roof made of?
          </label>
          <select
              class="form-select"
              aria-label="Material roof is made of"
              v-model="classroomInfo.roofMaterial"
          >
            <option value="">Select material</option>
            <option value="MUD">Mud</option>
            <option value="CEMENT_CONCRETE">Cement or Concrete</option>
            <option value="WOOD_BAMBOO">Wood or Bamboo</option>
            <option value="CERAMICS_TILES">Ceramic tiles</option>
            <option value="IRON_SHEET">Iron sheets</option>
            <option value="ASBESTOS">Asbestos</option>
            <option value="NO_ROOF">No roof</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            What material is the wall made of?
          </label>
          <select
              class="form-select"
              aria-label="Material wall is made of"
              v-model="classroomInfo.wallMaterial"
          >
            <option value="">Select material</option>
            <option value="MUD">Mud</option>
            <option value="CEMENT_CONCRETE">Cement or Concrete</option>
            <option value="WOOD_BAMBOO">Wood or Bamboo</option>
            <option value="IRON_SHEETS">Iron sheets</option>
            <option value="BURNT_BRICKS">Burnt bricks</option>
            <option value="STONE">Stone</option>
            <option value="NO_WALLS">No walls or dwarf walls</option>
          </select>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Is this workshop shared with other school(s)? </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check" aria-label="Type of teaching is full-time">
                <input
                    class="form-check-input"
                    type="radio"
                    name="classroomIsShared"
                    v-model="classroomInfo.shared"
                    value="true"
                    id="is-shared"
                />
                <label class="form-check-label font-sm" for="is-shared">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="classroomIsShared"
                    v-model="classroomInfo.shared"
                    value="false"
                    id="not-shared"
                />
                <label class="form-check-label font-sm" for="not-shared">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Does this classroom have good blackboard? </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check" aria-label="Type of teaching is full-time">
                <input
                    class="form-check-input"
                    type="radio"
                    name="hasBlackBoard"
                    v-model="classroomInfo.hasBlackBoard"
                    value="true"
                    id="has-blackboard"
                />
                <label class="form-check-label font-sm" for="has-blackboard">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="hasBlackBoard"
                    v-model="classroomInfo.hasBlackBoard"
                    value="false"
                    id="no-blackboard"
                />
                <label class="form-check-label font-sm" for="no-blackboard">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Are there enough seats for the children in this classroom? </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check" aria-label="Type of teaching is full-time">
                <input
                    class="form-check-input"
                    type="radio"
                    name="hasEnoughSeats"
                    v-model="classroomInfo.hasEnoughSeats"
                    value="true"
                    id="has-enough-seats"
                />
                <label class="form-check-label font-sm" for="has-enough-seats">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="hasEnoughSeats"
                    v-model="classroomInfo.hasEnoughSeats"
                    value="false"
                    id="no-enough-seats"
                />
                <label class="form-check-label font-sm" for="no-enough-seats">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isUpdate" class="d-flex flex-column align-items-center justify-content-center mb-4">
        <button
            class="custom-btn font-tiny fw-bold"
            @click="updateForm"
        >
          Update
        </button>
      </div>
      <div v-else class="d-flex flex-column align-items-center justify-content-center mb-4">
        <button
            class="custom-btn font-tiny fw-bold"
            @click="submitForm"
        >
          Save
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import {ref} from "vue";
import {mapActions, mapMutations, useStore} from "vuex";
import {computed, toRef} from "@vue/runtime-core";

export default {
  name: "ClassroomRegistration",
  props: ['hasPrePrimary', 'selectedClassroomInfo'],
  emits: ['reload'],
  setup(props) {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const classroomObjectInfo = ref()
    const selectedClassroom = toRef(props, 'selectedClassroomInfo')
    const classroomSearchResult = computed(() => store.state.questionnaireService.classSearchResult).value

    if (selectedClassroom.value !== undefined) {
      classroomObjectInfo.value = selectedClassroom.value
    } else {
      classroomObjectInfo.value = classroomSearchResult
    }

    let id = '', condition = '', description = '', enoughSeat = '', floorMaterial = '',
        goodBlackBoard = '', lenInMtr = '', roofMaterial = '', schoolLevel = '',
        shared = '', wallMaterial = '', widthInMtr = '', yearOfConstruction = ''
    
    let isUpdate = classroomObjectInfo.value !== null

    if (classroomObjectInfo.value !== null) {
      ({
        id, condition, description, enoughSeat, floorMaterial, goodBlackBoard,
        lenInMtr, roofMaterial, schoolLevel, shared, wallMaterial, widthInMtr, yearOfConstruction
      } = classroomObjectInfo.value)
    }

    const classroomInfo = {
      shared: shared,
      level: schoolLevel,
      classLabel: description,
      yearOfConstruction: yearOfConstruction,
      presentCondition: condition,
      length: lenInMtr,
      width: widthInMtr,
      floorMaterial: floorMaterial,
      roofMaterial: roofMaterial,
      wallMaterial: wallMaterial,
      hasBlackBoard: goodBlackBoard,
      hasWritingBoard: "",
      hasEnoughSeats: enoughSeat,
    }

    let levelIsPrePrimary = ref(false)
    const levelChanged = (event) => {
      levelIsPrePrimary.value = event.target.value === "PRE_PRIMARY";
    }

    return {
      classroomInfo,
      schoolInfo,
      description: "",
      isUpdate,
      id,
      levelChanged,
      selectedClassroom
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitClassRegistration', 'findClassroomByDescription', 'updateClassRegistration']),
    ...mapMutations(['setClassSearchResult', 'setClassroomUpdateLoading']),

    async submitForm() {
      const {classroomInfo, schoolInfo} = this
      const body = {
        school: schoolInfo.id,
        schoolLevel: classroomInfo.level,
        shared: classroomInfo.shared,
        description: classroomInfo.classLabel,
        yearOfConstruction: classroomInfo.yearOfConstruction,
        condition: classroomInfo.presentCondition,
        lenInMtr: classroomInfo.length,
        widthInMtr: classroomInfo.width,
        floorMaterial: classroomInfo.floorMaterial,
        wallMaterial: classroomInfo.wallMaterial,
        roofMaterial: classroomInfo.roofMaterial,
        enoughSeat: classroomInfo.hasEnoughSeats,
        goodBlackBoard: classroomInfo.hasBlackBoard,
      }

      await this.submitClassRegistration(body)
        .then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          })
          if (this.responseCode === 201) {            
            this.setClassSearchResult(null)
            this.$emit('reload')
          }
        })
    },

    async updateForm() {
      const {classroomInfo, schoolInfo, id} = this
      const body = {
        school: schoolInfo.id,
        schoolLevel: classroomInfo.level,
        shared: classroomInfo.shared,
        description: classroomInfo.classLabel,
        yearOfConstruction: classroomInfo.yearOfConstruction,
        condition: classroomInfo.presentCondition,
        lenInMtr: classroomInfo.length,
        widthInMtr: classroomInfo.width,
        floorMaterial: classroomInfo.floorMaterial,
        wallMaterial: classroomInfo.wallMaterial,
        roofMaterial: classroomInfo.roofMaterial,
        enoughSeat: classroomInfo.hasEnoughSeats,
        goodBlackBoard: classroomInfo.hasBlackBoard,
      }

      const requestBody = {
        id: id,
        body: body
      }

      this.setClassroomUpdateLoading(true)

      await this.updateClassRegistration(requestBody)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            this.setClassroomUpdateLoading(false)
            if (this.responseCode === 204 || this.responseCode === 200) {
              this.$emit('reload')
              this.$notification.open({
                type: 'success',
                message: 'Update Successful',
                description: 'Classroom information updated successfully.'
              });
            }
            this.setClassSearchResult(null)
          })
    },

    async findClassroom() {
      if (this.description !== '') {
        await this.findClassroomByDescription(this.description)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            this.$emit('reload')
          })
      }
    }
  }
}
</script>

<style scoped>

</style>