const initState = {
    identificationData: null,
    characteristicsData: null,
    enrolmentData: null,
    staffData: null,
    classroomData: null,
    facilitiesData: null,
    subjectData: null,
    bookData: null,
    qualificationData: null,
    familyLifeData: null
}

const state = initState

const mutations = {

    setIdentificationData(state, identification) {
        state.identificationData = identification
    },

    setCharacteristicsData(state, characteristics) {
        state.characteristicsData = characteristics
    },

    setEnrolmentData(state, enrolment) {
        state.enrolmentData = enrolment
    },

    setStaffData(state, staff) {
        state.staffData = staff
    },

    setClassroomData(state, classroom) {
        state.classroomData = classroom
    },

    setFacilitiesData(state, facilities) {
        state.facilities = facilities
    },

    setSubjectData(state, subject) {
        state.subjectData = subject
    },

    setBookData(state, book) {
        state.bookData = book
    },

    setQualificationData(state, qualification) {
        state.qualificationData = qualification
    },

    setFamilyLifeData(state, family) {
        state.familyLifeData = family
    },

    resetSectionState(state) {
        Object.assign(state, initState)  
    }
}

export default { state, mutations }