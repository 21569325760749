<script>
import router from "@/router";
import { mapGetters, mapMutations } from "vuex";

export default {
  methods: {
    ...mapGetters(["loginUser"]),
    ...mapMutations(["setUserDashboardActiveScreen"]),

    getLoginUser() {
      this.loginUser();
    },

    goBack() {
      router.back();
    },

    gotoDashboard() {
      router.push("/dashboard");
    },

    gotoUserDashboard() {
      router.push("/userDashboard");
    },

    gotoASCDashboard() {
      router.push("/ascDashboard");
    },

    gotoAccountSetting() {
      console.log("I got called!");
      if (this.$route.name === "User Dashboard") {
        this.$emit('accountSetting')
      } else {
        this.setUserDashboardActiveScreen('accountSetting')
        this.gotoUserDashboard()
      }
    },

    gotoUserProfile() {
      if (this.$route.name === "User Dashboard") {
        this.$emit('userProfile', this.loginUser().id)
      } else {
        this.setUserDashboardActiveScreen('userProfile')
        this.gotoUserDashboard()
      }
    },

    gotoEditProfile() {
      if (this.$route.name === "User Dashboard") {
        this.$emit('editUser')
      } else {
        this.setUserDashboardActiveScreen('editUser')
        this.gotoUserDashboard()
      }
    },

    gotoDashboardSetting() {
      if (this.$route.name === "User Dashboard") {
        this.$emit('dashboardSetting')
      } else {
        this.setUserDashboardActiveScreen('dashboardSetting')
        this.gotoUserDashboard()
      }
    },

    gotoSchoolDashboard() {
      router.push("/schoolDashboard");
    },

    gotoDataTableDashboard() {
      router.push("/dataTableDashboard")
    },

    gotoComparativeAnalysisDashboard() {
      router.push("/comparativeAnalysis")
    },

    gotoAuditTrailDashboard() {
      router.push("/auditTrailDashboard");
    },

    gotoMySchool() {
      router.push("/mySchool")
    },
 
    gotoHistoricalData() {
      router.push("/historicalData")
    },
    
    gotoExplore() {
      router.push("/exploreDashboard")
    }
  },
};
</script>
