<template>
  <div class="d-flex flex-column">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div
      v-if="title === undefined"
      class="d-flex justify-content-between align-items-center my-4"
    >
      <span class="font-normal fw-bold">All schools</span>
      <div class="d-flex">
        <div
          v-if="ability.can('create', 'School')"
          class="custom-btn d-flex align-items-center justify-content-center pointer-no-bg"
          @click="$emit('addNewSchool')"
        >
          <span class="material-icons-outlined font-md"> group_add </span>
          <span class="font-sm ps-2">Add new schools</span>
        </div>
      </div>
    </div>

    <div class="d-flex radius-half bg-white shadow-sm p-3 mb-3">
      <div class="col-6 d-flex flex-column pe-3">
        <span class="font-tiny fw-bold mb-2">What are you looking for?</span>
        <div class="custom-input-group bg-accent">
          <span class="p-1">
            <span class="material-icons font-normal pt-1 grey-shade">
              search
            </span>
          </span>
          <input
            type="text"
            class="ph-sm no-outline-input font-tiny fw-bolder"
            placeholder="Search"
            v-model="searchField"
          />
        </div>
      </div>
      <div class="col-3 d-flex flex-column pe-2">
        <span class="font-tiny fw-bold mb-2">Filter</span>
        <div>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
            v-model="searchFilter"
          >
            <option class="bg-white grey-shade" selected value="">All</option>
            <option class="bg-white" value="name">School name</option>
            <option class="bg-white" value="code">Code</option>
            <option class="bg-white" value="type">Type</option>
            <option class="bg-white" value="lga">LGA</option>
            <option class="bg-white" value="class">Class</option>
            <option class="bg-white" value="status">Status</option>
          </select>
        </div>
      </div>
      <div class="col-3 d-flex flex-column ps-2">
        <span class="font-tiny fw-bold mb-2">Sort</span>
        <div>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
            v-model="sortOption"
          >
            <option class="bg-white grey-shade" selected value="">None</option>
            <option class="bg-white" value="name">School name</option>
            <option class="bg-white" value="code">Code</option>
            <option class="bg-white" value="type">Type</option>
            <option class="bg-white" value="lga">LGA</option>
            <option class="bg-white" value="class">Class</option>
            <option class="bg-white" value="status">Status</option>
          </select>
        </div>
      </div>
    </div>

    <div class="bg-white radius-full shadow-sm mb-5">
      <table class="table table-hover font-sm" aria-label="school table">
        <thead>
          <tr>
            <th class="align-middle py-3 bigger-cell ps-4">
              <div class="d-flex flex-nowrap align-items-center">
                <span>School Name</span>
                <div class="vertical-icons ms-2">
                  <span
                    @click="nameArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_up
                  </span>
                  <span
                    @click="nameArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th v-if="title" class="align-middle normal-cell py-3">
              <div class="d-flex flex-nowrap align-items-center">
                <span>School Code</span>
                <div class="vertical-icons ms-2">
                  <span
                    @click="codeArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_up
                  </span>
                  <span
                    @click="codeArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th v-if="title" class="align-middle bigger-cell py-3">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Type</span>
                <div class="vertical-icons ms-2">
                  <span
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_up
                  </span>
                  <span
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th v-if="title" class="align-middle normal-cell py-3">
              <div class="d-flex flex-nowrap align-items-center">
                <span>LGA</span>
                <div class="vertical-icons ms-2">
                  <span
                    @click="lgaArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_up
                  </span>
                  <span
                    @click="lgaArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th v-if="title" class="align-middle normal-cell py-3">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Class</span>
                <div class="vertical-icons ms-2">
                  <span
                    @click="classArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_up
                  </span>
                  <span
                    @click="classArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th v-if="title" class="align-middle normal-cell py-3">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Status</span>
                <div class="vertical-icons ms-2">
                  <span
                    @click="statusArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_up
                  </span>
                  <span
                    @click="statusArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg"
                  >
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="align-middle small-cell py-3"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="pointer-no-bg bigger-cell"
            v-for="(school, index) in filteredSchools"
            :key="index"
            @click="schoolProfile(school)"
          >
            <td class="align-middle py-3 bigger-cell ps-4">
              {{ school.schoolName }}
            </td>
            <td v-if="title" class="py-3 normal-cell">
              {{ school.schoolCode }}
            </td>
            <td v-if="title" class="py-3 bigger-cell">{{ school.email }}</td>
            <td v-if="title" class="py-3 normal-cell">{{ school.lga }}</td>
            <td v-if="title" class="py-3 normal-cell">
              {{ school.classification }}
            </td>
            <td v-if="title" class="py-3 normal-cell">
              <span
                :class="[school.active ? 'color-green-shade' : 'color-ef']"
                class="skew-box-tiny"
              ></span>
              {{ school.active ? "active" : "inactive" }}
            </td>
            <td
              v-if="ability.cannot('manage', 'School')"
              class="py-3 small-cell"
            ></td>
            <td v-else class="text-end small-cell dropstart py-3" @click.stop>
              <span
                @click.stop
                class="material-icons-outlined grey-accent px-3"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                more_vert
              </span>
              <ul
                class="dropdown-menu action-menu bg-accent shadow font-tiny radius-half"
                aria-labelledby="options"
              >
                <li @click="$emit('showSchoolProfile', school.schoolCode)">
                  <span class="dropdown-item d-flex grey-accent">
                    <span
                      class="material-icons-outlined fw-medium font-md me-2"
                    >
                      school
                    </span>
                    <span>Detail</span>
                  </span>
                </li>
                <li
                  v-if="title"
                  @click="$emit('editSchoolInfo', school.schoolCode)"
                  class="pointer-no-bg"
                >
                  <span class="dropdown-item d-flex grey-accent">
                    <span
                      class="material-icons-outlined fw-medium font-md me-2"
                    >
                      edit
                    </span>
                    <span>Edit</span>
                  </span>
                </li>
                <li
                  v-if="title"
                  @click="toggleActiveStatus(school.id)"
                  class="pointer-no-bg"
                >
                  <span class="dropdown-item grey-accent">
                    <span
                      class="material-icons-outlined fw-medium font-md me-2"
                    >
                      {{ school.active ? "toggle_on" : "toggle_off" }}
                    </span>
                    {{ school.active ? "Deactivate" : "Activate" }}
                  </span>
                </li>
                <li
                  v-if="title"
                  @click="$emit('showSchoolStaffTable', school.id)"
                  class="pointer-no-bg"
                >
                  <span class="dropdown-item grey-accent">
                    <span
                      class="material-icons-outlined fw-medium font-md me-2"
                    >
                      groups
                    </span>
                    School Staff
                  </span>
                </li>
                <li
                  v-if="title"
                  @click="$emit('showLearnerInfo', school.schoolCode)"
                  class="pointer-no-bg"
                >
                  <span class="dropdown-item d-flex grey-accent">
                    <span
                      class="material-icons-outlined fw-medium font-md me-2"
                    >
                      groups
                    </span>
                    <span>School Learners</span>
                  </span>
                </li>
                <li
                  v-if="title"
                  @click="$emit('showClassrooms', school.id)"
                  class="pointer-no-bg"
                >
                  <span class="dropdown-item d-flex grey-accent">
                    <span
                      class="material-icons-outlined fw-medium font-md me-2"
                    >
                      note_alt
                    </span>
                    <span>School Classrooms</span>
                  </span>
                </li>
                <li
                  v-if="title"
                  @click="$emit('showQualifications', school.id)"
                  class="pointer-no-bg"
                >
                  <span class="dropdown-item d-flex grey-accent">
                    <span
                      class="material-icons-outlined fw-medium font-md me-2"
                    >
                      grade
                    </span>
                    <span>Teachers Qualification</span>
                  </span>
                </li>
                <li v-if="title" class="pointer-no-bg">
                  <span class="dropdown-item d-flex grey-accent">
                    <span
                      class="material-icons-outlined fw-medium font-md me-2"
                    >
                      home_repair_service
                    </span>
                    <span>School Facilities</span>
                  </span>
                </li>
                <!--                @click="deleteSchool(school.schoolCode)"-->
                <li v-if="title" class="pointer-no-bg">
                  <span class="dropdown-item d-flex grey-accent">
                    <span
                      class="material-icons-outlined fw-medium font-md me-2"
                    >
                      cancel
                    </span>
                    <span>Delete</span>
                  </span>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="d-flex justify-content-end align-items-center font-sm pb-3 px-4"
      >
        <span
          >Rows per page:
          <select
            v-model="rowCount"
            v-on:change="onNumRowChanged($event)"
            class="outline-clear"
            id="row"
          >
            <option value="12">12</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>

        <span class="font-sm ms-3"
          >1 to {{ rowCount }} of {{ totalRecord }}
          <span
            @click="fetchPrev"
            class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_back_ios
          </span>
          <span
            @click="fetchNext"
            class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_forward_ios
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { resetAll } from "@/helpers/utils/helper_functions";
import { mapActions, mapMutations, useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import defineAbilityFor from "@/authorities";
import { onMounted } from "vue";

export default {
  name: "SchoolsTable",
  components: { ClipLoader },
  props: ["title"],
  setup() {
    const store = useStore();
    const userMe = computed(() => store.getters.loginUser).value;
    const ability = defineAbilityFor(userMe);

    onMounted(() => {
      store.dispatch("fetchPagedSchools");
    });

    return {
      ability,
    };
  },
  data() {
    return {
      searchField: "",
      searchFilter: "",
      sortOption: "",
      arrowsState: {
        nameArrowUp: false,
        nameArrowDown: false,
        codeArrowUp: false,
        codeArrowDown: false,
        emailArrowUp: false,
        emailArrowDown: false,
        lgaArrowUp: false,
        lgaArrowDown: false,
        classArrowUp: false,
        classArrowDown: false,
        statusArrowUp: false,
        statusArrowDown: false,
      },
    };
  },
  computed: {
    loading() {
      return this.$store.state.school.loading;
    },

    rowCount() {
      return this.$store.state.school.numberOfRows;
    },

    schoolData() {
      return this.$store.state.school.pagedSchools;
    },

    schools() {
      if (this.schoolData !== null) {
        return this.schoolData.content;
      }
      return [];
    },

    totalRecord() {
      if (this.schoolData !== null) {
        return this.schoolData.totalElements;
      }
      return 0;
    },

    currentPage() {
      if (this.schoolData !== null) {
        return this.schoolData.pageNumber + 1;
      }
      return 0;
    },

    previousPage() {
      if (this.schoolData !== null) {
        return this.schoolData.pageNumber - 1;
      }
      return 0;
    },

    filteredSchools() {
      const { schools, searchField, sortOption, sortSchools } = this;
      const {
        nameArrowUp,
        nameArrowDown,
        codeArrowUp,
        codeArrowDown,
        emailArrowUp,
        emailArrowDown,
        lgaArrowUp,
        lgaArrowDown,
        classArrowUp,
        classArrowDown,
        statusArrowUp,
        statusArrowDown,
      } = this.arrowsState;

      if (nameArrowUp) {
        sortSchools("name");
      }
      if (nameArrowDown) {
        sortSchools("name", "DSC");
      }
      if (codeArrowUp) {
        sortSchools("code");
      }
      if (codeArrowDown) {
        sortSchools("code", "DSC");
      }
      if (emailArrowUp) {
        sortSchools("email");
      }
      if (emailArrowDown) {
        sortSchools("email", "DSC");
      }
      if (lgaArrowUp) {
        sortSchools("lga");
      }
      if (lgaArrowDown) {
        sortSchools("lga", "DSC");
      }
      if (classArrowUp) {
        sortSchools("class");
      }
      if (classArrowDown) {
        sortSchools("class", "DSC");
      }
      if (statusArrowUp) {
        sortSchools("status");
      }
      if (statusArrowDown) {
        sortSchools("status", "DSC");
      }

      sortSchools(sortOption);

      return schools.filter((school) =>
        this.filterSchoolsBy(school)
          .toLowerCase()
          .includes(searchField.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions(["fetchPagedSchools", "toggleSchoolActiveStatus"]),
    ...mapMutations(["setNumOfRows"]),

    schoolProfile(school) {
      this.$emit("showSchoolProfile", school);
    },

    toggleActiveStatus(schoolId) {
      this.toggleSchoolActiveStatus(schoolId);
    },

    filterSchoolsBy(school) {
      const { searchFilter } = this;
      if (searchFilter === "" || searchFilter === "name") {
        return school.schoolName;
      } else if (searchFilter === "code") {
        return school.schoolCode;
      } else if (searchFilter === "email") {
        return school.email;
      } else if (searchFilter === "lga") {
        return school.lga;
      } else if (searchFilter === "class") {
        return school.classification;
      } else {
        return school.active;
      }
    },

    sortSchools(sortFilter, order = "ASC") {
      const { schools } = this;
      if (sortFilter) {
        switch (sortFilter) {
          case "name":
            return schools.sort((school1, school2) => {
              if (order === "ASC") {
                return school1.name > school2.name
                  ? 1
                  : school2.name > school1.name
                  ? -1
                  : 0;
              }
              return school2.name > school1.name
                ? 1
                : school1.name > school2.name
                ? -1
                : 0;
            });

          case "code":
            return schools.sort((school1, school2) => {
              if (order === "ASC") {
                return school1.code > school2.code
                  ? 1
                  : school2.code > school1.code
                  ? -1
                  : 0;
              }
              return school2.code > school1.code
                ? 1
                : school1.code > school2.code
                ? -1
                : 0;
            });

          case "email":
            return schools.sort((school1, school2) => {
              if (order === "ASC") {
                return school1.email > school2.email
                  ? 1
                  : school2.email > school1.email
                  ? -1
                  : 0;
              }
              return school2.email > school1.email
                ? 1
                : school1.email > school2.email
                ? -1
                : 0;
            });

          case "lga":
            return schools.sort((school1, school2) => {
              if (order === "ASC") {
                return school1.lga > school2.lga
                  ? 1
                  : school2.lga > school1.lga
                  ? -1
                  : 0;
              }
              return school2.lga > school1.lga
                ? 1
                : school1.lga > school2.lga
                ? -1
                : 0;
            });

          case "class":
            return schools.sort((school1, school2) => {
              if (order === "ASC") {
                return school1.class > school2.class
                  ? 1
                  : school2.class > school1.class
                  ? -1
                  : 0;
              }
              return school2.class > school1.class
                ? 1
                : school1.class > school2.class
                ? -1
                : 0;
            });

          case "status":
            return schools.sort((school1, school2) => {
              if (order === "ASC") {
                return school1.status > school2.status
                  ? 1
                  : school2.status > school1.status
                  ? -1
                  : 0;
              }
              return school2.status > school1.status
                ? 1
                : school1.status > school2.status
                ? -1
                : 0;
            });
        }
      }
    },

    nameArrowUpClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.nameArrowUp = true;
    },

    nameArrowDownClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.nameArrowDown = true;
    },

    codeArrowUpClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.codeArrowUp = true;
    },

    codeArrowDownClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.codeArrowDown = true;
    },

    emailArrowUpClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.emailArrowUp = true;
    },

    emailArrowDownClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.emailArrowDown = true;
    },

    lgaArrowUpClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.lgaArrowUp = true;
    },

    lgaArrowDownClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.lgaArrowDown = true;
    },

    classArrowUpClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.classArrowUp = true;
    },

    classArrowDownClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.classArrowDown = true;
    },

    statusArrowUpClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.statusArrowUp = true;
    },

    statusArrowDownClicked() {
      resetAll(this.arrowsState);
      this.arrowsState.statusArrowDown = true;
    },

    onNumRowChanged(event) {
      const number = event.target.value;
      console.log("I'm now going to fetch " + number + " users.");
      this.setNumOfRows(number);
      this.fetchPagedSchools();
    },

    fetchNext() {
      console.log("I got tapped!");
      if (this.schoolData.last === false) {
        this.fetchPagedSchools(this.currentPage);
      }
    },

    fetchPrev() {
      console.log("I got tapped too!");
      if (this.schoolData.first === false) {
        this.fetchPagedSchools(this.previousPage);
      }
    },

    fetchEdited() {
      this.fetchPagedSchools(0);
    },
  },
  mounted() {
    this.$store.dispatch("fetchPagedSchools");
    this.fetchEdited();
    console.log(this.schools[0], "<<<<<<<<");
  },
};
</script>

<style scoped>
.bigger-cell {
  width: 24%;
}
.normal-cell {
  width: 12%;
}
.small-cell {
  width: 4%;
}
</style>
