<template>
  <div class="vh-100 d-flex flex-column">
    <div class="graph-container d-flex flex-column bg-white radius-full my-5">
      <p class="font-sm fw-bold">Percentage of pre-school administrators who are certified to manage according to national requirements</p>
      <canvas class="charts" id="certified-administrators-chart" height="300"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import {onMounted} from "vue";

export default {
  name: "CAManagement",
  setup() {
    const states = ["Adamawa", "Borno", "Yobe"]
    const certifiedAdminData = [80, 65, 85]
    const certifiedAdminChartData = {
      type: 'bar',
      maintainAspectRatio: false,
      data: {
        labels: states,
        datasets: [
          {
            label: "# of Underage",
            data: certifiedAdminData,
            barPercentage: .6,
            backgroundColor: [
              'rgba(0, 204, 242, 1)',
              'rgba(249, 136, 16, 1)',
              'rgba(0, 1, 126, .75)',
            ],
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom'
          }
        }
      },
    }

    onMounted(() => {
      const chart = document.getElementById("certified-administrators-chart")
      new Chart(chart, certifiedAdminChartData)
    })
  }
}
</script>

<style scoped>

</style>