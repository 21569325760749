<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Historical Public Primary School Enrolments, GER and GPI (From 2017/2018 through 2018/2019)</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-sm" aria-label="Users table">
        <thead>
        <tr>
          <th class="align-middle py-3 ps-4"></th>
          <th class="align-middle py-3" colspan="4">Enrolments</th>
          <th class="align-middle py-3" colspan="3">GER</th>
          <th class="align-middle py-3" ></th>
        </tr>
        <tr>
          <th class="align-middle py-3 ps-4 lga-cell col-md-2">Session</th>
          <th class="align-middle py-3 other-cells col-md-2">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">M</span>
            </div>
          </th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">F</span>
            </div>
          </th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">T</span>
            </div>
          </th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">% F</span>
            </div>
          </th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">
            <div class="d-flex flex-nowrap align-items-center">
              <span class="font-tiny">M</span>
            </div>
          </th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">F</th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">T</th>
          <th class="align-middle py-3 other-cells col-md-1" scope="col">GPI</th>
        </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg"
            v-for="(data, index) in tableData"
            :key="index" 
          >
            <td class="py-3 ps-4 other-cells first">{{ data.session }}</td>
            <td class="py-3 other-cells">{{ data.m_enrolments }}</td>
            <td class="py-3 other-cells">{{ data.f_enrolments }}</td>
            <td class="py-3 other-cells">{{ data.t_enrolments }}</td>
            <td class="py-3 other-cells">{{ data.percentage_f_enrolments }}</td>
            <td class="py-3 other-cells">{{ data.m_ger }}</td>
            <td class="py-3 other-cells">{{ data.f_ger }}</td>
            <td class="py-3 other-cells">{{ data.t_ger }}</td>
            <td class="py-3 other-cells">{{ data.gpi }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="9" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "HistoricalPublicPryEnrolments",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 28, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })

    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
.lga-cell {
  width: 20%;
}
.other-cells {
  width: 10%;
}
</style>