<template>
  <div class="container-fluid bg-accent p-5">
    <dashboard-header
      :has-back="true"
      @goBack="goBack"
    />
    <span class="d-flex justify-content-center">
      <span
          @click="isActive = 'access'"
          :class="[isActive === 'access' ? 'is-active-bordered' : 'is-inactive-bordered',
          'px-3 py-2 font-sm fw-normal pointer-no-bg text-center']"
      >
        Access
      </span>
      <span
          @click="isActive = 'quality'"
          :class="[isActive === 'quality' ? 'is-active-bordered' : 'is-inactive-bordered',
          'px-3 py-2 font-sm fw-normal pointer-no-bg text-center']"
      >
        Quality
      </span>
      <span
          @click="isActive = 'management'"
          :class="[isActive === 'management' ? 'is-active-bordered' : 'is-inactive-bordered',
          'px-3 py-2 font-sm fw-normal pointer-no-bg text-center']"
      >
        Management
      </span>
    </span>
    <c-a-access v-if="isActive === 'access'" />
    <c-a-quality v-if="isActive === 'quality'" />
    <c-a-management v-if="isActive === 'management'" />
  </div>
</template>

<script>
import DashboardHeader from "@/components/dashboardPage/DashboardHeader";
import CAAccess from "@/components/dashboardPage/ComparativeAnalysis/CAAccess";
import CAManagement from "@/components/dashboardPage/ComparativeAnalysis/CAManagement";
import {ref} from "vue";
import CAQuality from "@/components/dashboardPage/ComparativeAnalysis/CAQuality";
import dashboardMixinVue from '../../helpers/mixins/dashboardMixin.vue';

export default {
  name: "ComparativeAnalysisDashboard",
  mixins: [dashboardMixinVue],
  components: {CAQuality, DashboardHeader, CAManagement, CAAccess},
  setup() {
    const isActive = ref('access')

    return {
      isActive,
    }
  }
}
</script>

<style scoped>

</style>