<template>
  <div class="d-flex flex-column">
    <div class="graph-container d-flex flex-column bg-white radius-full mb-5">
      <p class="font-sm fw-bold">Historical Gross Enrolment Ratio</p>
      <canvas class="charts" id="ger-historical-data-chart" height="300"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex'

export default {
  name: "GERHistoricalDataChart",
  emits: ['reload'],
  setup(props, {emit}) {
    const store = useStore();
    const historicalDataLineChartData = ref({})
    const gerData = computed(() => store.state.analysis.gerHistoricalData)
    let chartDatasets = new Set()
    const colors = ['rgba(0, 204, 242, 1)', 'rgba(113, 11, 121, 1)', 'rgba(249, 136, 16, 1)']

    if (gerData.value === null) {
      store.dispatch('fetchGERHistoricalDataChartData')      
    } else {
      const dataList = Object.values(gerData.value.data)
      const xAxisLabels = dataList.map(data => Object.keys(data)).flat()
      const dataListValues = dataList.map(data => Object.values(data)).flat()      
      const firstDataValue = dataListValues[0]
      const groupKeys = Object.keys(firstDataValue)  // GET KEYS OF ITEMS

      groupKeys.forEach((key, index) => {
        let someArray = dataListValues.map(item => item[key])
        const set = {
          label: key,
          tension: .2,
          fill: false,
          data: someArray,
          borderColor: colors[index],
        } 

        chartDatasets.add(set)
      })

      if (chartDatasets.size > 0) {
        historicalDataLineChartData.value = {
          type: 'line',
          data: {
            labels: xAxisLabels,
            datasets: Array.from(chartDatasets)
          },
          maintainAspectRatio: false,
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'bottom'
              }
            }
          },
        }
      }        
    }

    watch(
      () => gerData.value,
      () => {
        if (gerData.value !== null) {
          emit('reload')
        }
      }
    )

    onMounted(() => {
      const gerHistoricalDataLineChart = document.getElementById("ger-historical-data-chart")          
      new Chart(gerHistoricalDataLineChart, historicalDataLineChartData.value)
    })
    
    return {}
  },
}
</script>