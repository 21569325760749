<template>
  <div class="bg-accent d-flex flex-column min-vh-100">
    <div v-if="loadingStatus" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div class="container pt-4">
      <dashboard-header 
        @goBack="goBack"
        :hasBack="true"
      />
      <pry-sch-population-projection v-if="index === '0'" />
      <j-s-s-population-projection v-if="index === '1'" />
      <s-s-s-population-projection v-if="index === '2'" />
    </div>
  </div>
</template>

<script>
import PrySchPopulationProjection
  from "@/components/dashboardPage/dataTable/SchoolAgePopProjection/PrySchPopulationProjection";
import JSSPopulationProjection
  from "@/components/dashboardPage/dataTable/SchoolAgePopProjection/JSSPopulationProjection";
import SSSPopulationProjection
  from "@/components/dashboardPage/dataTable/SchoolAgePopProjection/SSSPopulationProjection";
import DashboardHeader from '@/components/dashboardPage/DashboardHeader.vue';
import {useRoute, useRouter} from "vue-router";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import {computed} from "vue"
import { useStore } from 'vuex';

export default {
  name: "PopulationProjectionTables",
  components: {
    ClipLoader,
    SSSPopulationProjection,
    JSSPopulationProjection,
    PrySchPopulationProjection,
    DashboardHeader,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const loadingStatus = computed(() => store.state.datatables.loadingStatus)
    const goBack = () => { store.commit('setCurrentStage', 6); router.back() }
    const index = route.params.index
    return {
      index,
      loadingStatus,
      goBack,
    }
  }
}
</script>

<style scoped>

</style>