<template>
  <div id="benefits-view" class="d-flex flex-column align-items-center">
    <span class="dm-color fw-bold font-sm mb-3">BENEFITS</span>
    <h5 class="fw-bold mb-3">Fits In Well With Existing Models</h5>
    <div class="col-9 col-md-8 col-lg-3">
      <p class="text-center font-sm">
        Transforming Education Data Management for Enhanced Decision-Making and
        Improved Educational Outcomes.
      </p>
    </div>
    <div
      class="d-flex flex-wrap justify-content-center justify-content-lg-between flex-grow-1"
    >
      <card
        v-for="(item, index) in benefits"
        :key="index"
        :img="item.img"
        :color="item.color"
        :title="item.benefit"
        :description="item.description"
        :class="index % 2 !== 0 ? 'alternating-card' : ''"
      />
    </div>
  </div>
</template>

<script>
import Card from "./Card";
import stakeholder from "@/assets/images/stakeholder.png";
import efficiency from "@/assets/images/efficiency.png";
import quality from "@/assets/images/quality.png";
import data from "@/assets/images/data.png";
import nav from "@/assets/images/nav.png";

export default {
  name: "BenefitsView",
  data() {
    const benefits = [
      {
        benefit: "Stakeholder metrics",
        img: stakeholder,
        color: "color-sh",
        description:
          "Helps stakeholders meet the increasing demand for information",
      },
      {
        benefit: "Improved efficiency",
        img: efficiency,
        color: "color-ef",
        description: "Improves eﬃciency in reporting and analysis",
      },
      {
        benefit: "Improved quality",
        img: quality,
        color: "color-qual",
        description: "Improves the quality and integrity of data",
      },
      {
        benefit: "Real time data",
        img: data,
        color: "color-data",
        description: "Real time data to report against benchmark goals",
      },
      {
        benefit: "Easy navigation",
        img: nav,
        color: "color-nav",
        description: "Reports and dashboards for fast and easy navigation",
      },
    ];

    return {
      benefits,
    };
  },
  components: {
    Card,
  },
};
</script>

<style lang="scss">
@import "@/style/variable";

#benefits-view {
  min-height: 100vh;
  background-color: $accent-color;
  background-image: url("../../assets/images/bg-benefits.png");
  padding: 1.5rem;
  padding-top: 2.5rem;
}

.alternating-card {
  @media (min-width: 992px) {
    margin-top: 5rem;
  }
}
</style>
