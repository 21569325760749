<template>
  <a-table 
    :dataSource="dataSource"
    :columns="columns"
    :loading="loading"
    size="small"
  />
</template>
<script>
import {Table} from "ant-design-vue";
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex'
import { watch } from '@vue/runtime-dom';

class LGARecord {
  constructor(key, lga, publicSchool, privateSchool) {
    this.key = key
    this.lga = lga
    this.publicSchool = publicSchool
    this.privateSchool = privateSchool
  }
}

export default {
  name: "PublicPrivateSchoolInfoTable",
  components: {'a-table': Table,},
  setup(props, {emit}) {
    const store = useStore();
    const publicPrivateSchoolData = computed(() => store.state.analysis.publicPrivateSchoolRecords)
    const loading = computed(() => store.state.analysis.loading)
    const dataSource = []
    const columns = [
      {
        title: 'LGAs',
        dataIndex: 'lga',
        key: 'lga'
      },
      {
        title: 'Public Schools',
        dataIndex: 'publicSchool',
        key: 'publicSchool'
      },    
      {
        title: 'Private Schools',
        dataIndex: 'privateSchool',
        key: 'privateSchool'
      }
    ]

    if (publicPrivateSchoolData.value === null) {
      publicPrivateSchoolData.value = [] 
    } else {
      const data = Object.values(publicPrivateSchoolData.value);
      for (const item of data) {
        const lgData = Object.entries(item)[0];
        dataSource.push(
          new LGARecord(
            lgData[0],
            lgData[0],
            lgData[1]['PUBLIC'],
            lgData[1]['PRIVATE'],
          )
        )
      }
    }

    watch(
      () => publicPrivateSchoolData.value,
      () => {
        emit('reload')
      }
    )

    return {
      publicPrivateSchoolData,
      dataSource,
      columns,
      loading
    }
  },
}
</script>
