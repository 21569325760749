<template>
  <div class="d-flex flex-column">
    <div v-if="false" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div class="d-flex justify-content-between align-items-center my-4">
      <span class="font-normal fw-bold">Learners</span>
      <div class="d-flex">
        <div
          class="
            custom-btn
            d-flex
            align-items-center
            justify-content-center
            pointer-no-bg
          "
          @click="$emit('addNewLearner')"
        >
          <span class="material-icons font-normal">
            add
          </span>
          <span class="font-sm ps-1">Add new learner</span>
        </div>
      </div>
    </div>

    <div class="d-flex radius-half bg-white shadow-sm p-3 mb-3">
      <div class="col-6 d-flex flex-column pe-3">
        <span class="font-tiny fw-bold mb-2">What are you looking for?</span>
        <div class="custom-input-group bg-accent">
          <span class="p-1">
            <span class="material-icons font-normal pt-1 grey-shade">
              search
            </span>
          </span>
          <input
            type="text"
            class="ph-sm no-outline-input font-tiny fw-bolder"
            placeholder="Search"
          />
        </div>
      </div>
      <div class="col-3 d-flex flex-column pe-2">
        <span class="font-tiny fw-bold mb-2">Filter</span>
        <div>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
          >
            <option class="bg-white grey-shade" selected>All</option>
            <option class="bg-white" value="1">One</option>
            <option class="bg-white" value="2">Two</option>
            <option class="bg-white" value="3">Three</option>
          </select>
        </div>
      </div>
      <div class="col-3 d-flex flex-column ps-2">
        <span class="font-tiny fw-bold mb-2">Sort</span>
        <div>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
          >
            <option class="bg-white grey-shade" selected>All</option>
            <option class="bg-white" value="1">One</option>
            <option class="bg-white" value="2">Two</option>
            <option class="bg-white" value="3">Three</option>
          </select>
        </div>
      </div>
    </div>

    <div class="bg-white radius-full shadow-sm mb-5">
      <table class="table table-hover font-sm" aria-label="Users table">
        <thead>
          <tr>
            <th class="align-middle py-3 ps-4" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Full Name</span>
                <div class="vertical-icons ms-2">
                  <span 
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="align-middle py-3" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Gender</span>
                <div class="vertical-icons ms-2">
                  <span 
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="align-middle py-3" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Reg. number</span>
                <div class="vertical-icons ms-2">
                  <span 
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="align-middle py-3" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Age</span>
                <div class="vertical-icons ms-2">
                  <span 
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="align-middle py-3" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="pointer-no-bg"
            v-for="(user, index) in users"
            :key="index"
            @click="userProfile(user.id)"
          >
            <td class="align-middle py-3 ps-4">
              {{ user.firstName + " " + user.lastName }}
            </td>
            <td class="py-3">{{ user.gender }}</td>
            <td class="py-3">{{ user.email }}</td>
            <td class="py-3">{{ user.staffFileNumber }}</td>
            <td class="text-end dropend pe-2 py-3">
              <span @click.stop class="p-3">                
                <span 
                  @click.stop
                  class="material-icons-outlined grey-accent px-3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  more_vert
                </span>
                <ul
                  class="
                    dropdown-menu
                    action-menu
                    bg-accent
                    shadow
                    font-tiny
                    radius-half
                  "
                  aria-labelledby="options"
                >
                  <li @click="userProfile(user.id)">
                    <span class="dropdown-item d-flex grey-accent">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        person
                      </span>
                      <span>Profile</span>
                    </span>
                  </li>
                  <li @click="editUser(user.id)">
                    <span class="dropdown-item d-flex grey-accent" href="#">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        edit
                      </span>
                      <span>Edit</span>
                    </span>
                  </li>
                  <li @click="activateDeactive(user.id)">
                    <span class="dropdown-item d-flex grey-accent" href="#">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        toggle_off
                      </span>
                      <span>Disable</span>
                    </span>
                  </li>
                  <li>
                    <span class="dropdown-item d-flex grey-accent">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        cancel
                      </span> 
                      <span>Delete</span>
                    </span>
                  </li>
                </ul>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { computed } from "@vue/runtime-core";
import { mapActions, mapMutations, useStore } from "vuex";
export default {
  name: "LearnersTable",
  components: { ClipLoader },
  props: ['title'],
  created() {
    // this.fetchPagedUsers();
    this.checkLoader();
  },
  setup() {
    const store = useStore();
    const users = computed(() => store.state.auth.users).value;
    const loading = computed(() => store.getters.loadingStatus).value;
    return {
      users,
      loading
    };
  },
  methods: {
    ...mapActions(["fetchUsers", "activateDeactivate"]),
    ...mapMutations(["setLoadingStatus"]),
    userProfile(userId) {
      this.$emit("showUserProfile", [userId]);
    },

    checkLoader() {
      if (this.users != null) this.setLoadingStatus(false);
    },

    editUser(userId) {
      this.$emit("editUser", userId);
    },

    fetchPagedUsers() {
      this.fetchUsers();
    },

    async activateDeactive(userId) {
      await this.activateDeactivate(userId);
    },
  },
};
</script>