<template>
  <div>
    <p class="fw-bold mt-4">Number of students by subject</p>
    <div class="d-flex flex-column">
      <section 
        v-if="showNoPrePrimary"
        id="noPrePrimary">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <p class="fw-medium">This section has no form for Pre-primary and primary.</p>
        </div>
      </section>
      <vocational-and-science v-if="isScienceOrVocational" />
      <regular-school v-else />

      <div class="d-flex flex-column align-items-center justify-content-center">
        <span
            @click="$emit('continue')"
            class="font-sm dm-color my-3 pointer-no-bg"
        >
          <strong>Continue</strong>
        </span>
      </div>

    </div>
  </div>
</template>

<script>
import {mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import RegularSchool from "@/components/dashboardPage/school/ASCDashboard/questionnaire/subjects/RegularSchools";
import VocationalAndScience from "@/components/dashboardPage/school/ASCDashboard/questionnaire/subjects/VocationalAndScience";
import {onMounted} from "vue";
import Modal from "@/helpers/utils/Modal";

export default {
  name: "Subject",
  components: {VocationalAndScience, RegularSchool, Modal},
  setup() {
    const store = useStore()
    onMounted(() => {
      store.dispatch('fetchMySchool')
    })
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const specials = ["VOCATION_TRAINING", "SCIENCE_TECHNOLOGY", "SCIENCE_TECHNOLOGY_VOCATIONAL"]
    const isScienceOrVocational = specials.includes(schoolInfo.schoolType)
    const hasPrePrimary = schoolInfo.levelsOffered.includes("PRE_PRIMARY")
    const hasPrimary = schoolInfo.levelsOffered.includes("PRIMARY")

    let showNoPrePrimary = false;
    if (hasPrePrimary && schoolInfo.levelsOffered.length === 1) {
      showNoPrePrimary = true
    }

    return {
      hasPrimary,
      hasPrePrimary,
      isScienceOrVocational,
      showNoPrePrimary,
    }
  },
  computed: {
    message() {
      return this.$store.state.questionnaireService.message
    },
    hasMessage() {
      return this.message !== null
    },
  },
  methods: {
    ...mapMutations(['setQAMessage', 'setResponseCode']),

    saveSubject() {
      this.$emit('saveSubject')
    },
    closeModal() {
      this.setQAMessage(null)
      this.setResponseCode(null)
    },
  }
}
</script>

<style scoped>

</style>