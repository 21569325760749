<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2"> Enrolment in private primary school by level, gender and LGA (Class 1-6)</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-tiny" aria-label="Users table">
        <thead>
          <tr>
            <td class="align-middle py-3 ps-4"></td>
            <td class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-start">
                <span class="font-tiny">Class 1</span>
              </div>
            </td>
            <td class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-start">
                <span class="font-tiny">Class 2</span>
              </div>
            </td>
            <td class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-start">
                <span class="font-tiny">Class 3</span>
              </div>
            </td>
            <td class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-start">
                <span class="font-tiny">Class 4</span>
              </div>
            </td>
            <td class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-start">
                <span class="font-tiny">Class 5</span>
              </div>
            </td>
            <td class="align-middle py-3" colspan="3">
              <div class="d-flex flex-nowrap justify-content-start">
                <span class="font-tiny">Class 6</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="align-middle py-3 ps-4 table-cell">LGA</td>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
            <th class="align-middle py-3 cell-size" scope="col">Boys</th>
            <th class="align-middle py-3 cell-size" scope="col">Girls</th>
            <th class="align-middle py-3 cell-size" scope="col">Total</th>
          </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg"
            v-for="(data, index) in tableData"
            :key="index"
          >
            <td class="py-3 ps-4 table-cell">{{ data.lga }}</td>
            <td class="py-3 cell-size">{{ data.boys_class_1 }}</td>
            <td class="py-3 cell-size">{{ data.girls_class_1 }}</td>
            <td class="py-3 cell-size">{{ data.total_class_1 }}</td>
            <td class="py-3 cell-size">{{ data.boys_class_2 }}</td>
            <td class="py-3 cell-size">{{ data.girls_class_2 }}</td>
            <td class="py-3 cell-size">{{ data.total_class_2 }}</td>
            <td class="py-3 cell-size">{{ data.boys_class_3 }}</td>
            <td class="py-3 cell-size">{{ data.girls_class_3 }}</td>
            <td class="py-3 cell-size">{{ data.total_class_3 }}</td>
            <td class="py-3 cell-size">{{ data.boys_class_4 }}</td>
            <td class="py-3 cell-size">{{ data.girls_class_4 }}</td>
            <td class="py-3 cell-size">{{ data.total_class_4 }}</td>
            <td class="py-3 cell-size">{{ data.boys_class_5 }}</td>
            <td class="py-3 cell-size">{{ data.girls_class_5 }}</td>
            <td class="py-3 cell-size">{{ data.total_class_5 }}</td>
            <td class="py-3 cell-size">{{ data.boys_class_6 }}</td>
            <td class="py-3 cell-size">{{ data.girls_class_6 }}</td>
            <td class="py-3 cell-size">{{ data.total_class_6 }}</td>          
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="19" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "EnrolmentPrivatePrimarySchlByClass",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 14, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })
  
    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
.first-cell {
  width: 10%;
}
.cell-size {
  width: 5%;
}
</style>