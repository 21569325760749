<template>
  <div class="container-fluid bg-accent p-5">
    <dashboard-header
      @goBack="goBack"
      :has-back="true"
    />

    <p class="font-normal fw-medium">     
      Enrolment History, Gross Enrolment Ration and Gross Parity Index Charts.
    </p>

    <enrolment-historical-data-chart :key="historicalKey" @reload="historicalKey++" />

    <g-e-r-historical-data-chart :key="gerKey" @reload="gerKey++" />

    <g-p-i-historical-data-chart :key="gpiKey" @reload="gpiKey++" /> 

  </div>
</template>

<script>
import DashboardHeader from "@/components/dashboardPage/DashboardHeader";
import EnrolmentHistoricalDataChart from "@/components/dashboardPage/historical/EnrolmentHistoricalDataChart";
import GERHistoricalDataChart from "@/components/dashboardPage/historical/GERHistoricalDataChart";
import GPIHistoricalDataChart from "@/components/dashboardPage/historical/GPIHistoricalDataChart";

import {ref} from 'vue'
import dashboardMixinVue from '../../helpers/mixins/dashboardMixin.vue';
export default {
  name: "HistoricalDataDashboard",
  mixins: [dashboardMixinVue],
  components: {
    EnrolmentHistoricalDataChart,
    GERHistoricalDataChart,
    GPIHistoricalDataChart,
    DashboardHeader,
  },
  setup() {
    const isActive = ref('access-line')
    const historicalKey = ref(0)
    const gerKey = ref(0)
    const gpiKey = ref(0)

    return {
      isActive,
      historicalKey,
      gerKey,
      gpiKey,
    }
  }
}
</script>