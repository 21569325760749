<template>
  <div id="tabbed-view" class="d-flex flex-column">
    <div class="col-11 col-lg-5 tab-container">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        @click="currentTab = tab.component"
        :class="[
          'btn btn-transparent font-sm tabs',
          { active: currentTab === tab.component },
        ]"
      >
        {{ tab.title }}
      </button>
    </div>
    <div class="tab-body">
      <component :is="currentTabComponent"></component>
    </div>
  </div>
</template>

<script>
import MapView from "./tabs/MapView";
import StatisticsView from "./tabs/StatisticsView";
import DataFilterView from "./tabs/DataFilterView";
import DownloadView from "./tabs/DownloadView";
import ResourceView from "./tabs/ResourceView";
import ApiView from "./tabs/ApiView";

export default {
  name: "TabbedView",
  components: {
    MapView,
    StatisticsView,
    DataFilterView,
    DownloadView,
    ResourceView,
    ApiView,
  },
  data() {
    const tabs = [
      { component: "Map", title: "Map" },
      { component: "Statistics", title: "Statistics" },
      { component: "DataFilter", title: "Data filters" },
      { component: "Download", title: "Data download" },
      { component: "Resource", title: "Resources" },
      { component: "Api", title: "APIs" },
    ];
    const currentTab = "Map";

    return {
      tabs,
      currentTab,
    };
  },
  computed: {
    currentTabComponent() {
      return this.currentTab + "View";
    },
  },
};
</script>

<style lang="scss">
@import "../../style/variable.scss";

#tabbed-view {
  min-height: 100vh;
}

.tab-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: $accent-color;
  border-radius: $half-rem;
  padding: 0.25rem 0.25rem;
  margin: 2rem auto;

  @media (max-width: 760px) {
    justify-content: space-around;
  }
}

.tabs {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: $half-rem;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.tabs.active {
  background-color: #017ebe !important;
  color: #fff;

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}
</style>