<template>
  <div>
    <div class="bg-white radius-full py-4 px-5 my-4">
      <p class="font-sm fw-bold mb-3">Facilities registration (by school level) in current academic class.</p>
      <div class="col-12 mt-3 mb-4">
        <label class="form-label font-sm fw-normal">
          Please select school level
        </label>
        <select class="form-select" required aria-label="Default select example" @change="levelChanged($event)" v-model="schoolLevel">
          <option value="">Select level</option>
          <option value="PRE_PRIMARY">Pre-Primary</option>
          <option value="PRIMARY">Primary</option>
          <option value="JUNIOR">Junior</option>
          <option value="SENIOR">Senior</option>
        </select>
      </div>
    </div>
    <div class="col-12 bg-white radius-full py-4 px-5 my-4">
      <label class="form-label font-sm fw-normal">
        Are the premise owned, rented or shared?
      </label>
      <div class="col-12">
        <input
            type="text"
            class="form-control"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :value="type"
        />
        <div
            class="dropdown-menu bg-accent shadow-sm w-100"
            aria-labelledby="featuresDropdown"
        >
          <div class="d-flex flex-column w-100">
            <div class="pe-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="facility-type"
                    v-model="facilityOwnershipType"
                    value="OWNED"
                    id="owned"
                />
                <label class="form-check-label" for="owned">
                  Owned
                </label>
              </div>
            </div>
            <div class="pe-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="facility-type"
                    v-model="facilityOwnershipType"
                    value="RENTED"
                    id="rented"
                />
                <label class="form-check-label" for="rented">
                  Rented
                </label>
              </div>
            </div>
            <div class="pe-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="facility-type"
                    v-model="facilityOwnershipType"
                    value="GRANTED_FOR_FREE"
                    id="granted-free"
                />
                <label class="form-check-label" for="granted-free">
                  Granted for free
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center justify-content-center mt-4">
        <button
            v-if="isFetched || showTitle == false "
            class="facilities-btn custom-btn font-tiny fw-bold"
            :disabled="!isFetched"
            @click="updateFacilityType"
        >
          Update
        </button>
        <button
            v-else
            class="facilities-btn custom-btn font-tiny fw-bold"
            @click="submitFacilityOwnership"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, useStore} from "vuex";
import lodash from 'lodash'
import {ref, watch} from "vue";
import {computed} from "@vue/runtime-core";

export default {
  name: "FacilityType",
  props:['showTitle'],
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const ownershipInfo = computed(() => store.state.questionnaireService.facilityOwnershipInfo).value
    const facilityOwnershipType = ref('')
    const type = ref('')
    const isFetched = ref(false)
    let schoolLevel = '', premise = ''

    if (ownershipInfo !== null) {
      ({
        schoolLevel, premise
      } = ownershipInfo)
      facilityOwnershipType.value = premise
      type.value = lodash.capitalize(facilityOwnershipType.value).replaceAll("_", " ")
      isFetched.value = true
    }

    watch(facilityOwnershipType, () => {
      type.value = lodash.capitalize(facilityOwnershipType.value).replaceAll("_", " ")
    })

    return {
      schoolLevel: schoolLevel,
      facilityOwnershipType,
      type,
      schoolInfo,
      isFetched
    }
  },
  computed: {
    responseCode() { return this.$store.state.questionnaireService.responseCode }
  },
  methods: {
    ...mapActions(['submitFacilityType', 'fetchFacilityOwnership', 'updateFacilityOwnership']),
    ...mapMutations(['setQAMessage', 'setFacilityOwnershipInfo']),

    levelChanged(event) {
      const requestObject = {
        id: this.schoolInfo.id,
        level: event.target.value
      }
      this.fetchFacilityOwnership(requestObject)
        .then(() => {
          if (this.responseCode === 200) {
            this.$emit('reload')
          }
        })
    },

    async submitFacilityOwnership() {
      if (this.schoolLevel === '') {
        this.setQAMessage("Please select school level!")
      } else {
        const body = {
          premise: this.facilityOwnershipType,
          schoolLevel: this.schoolLevel
        }
        const requestObject = {
          id: this.schoolInfo.id,
          body: body
        }
        await this.submitFacilityType(requestObject)
          .then(() => {
            if (this.responseCode === 201) {
              this.$emit('reload')
            }
          })
      }
    },

    async updateFacilityType() {
      if (this.schoolLevel === '') {
        this.setQAMessage("Please select school level!")
      } else {
        const body = {
          premise: this.facilityOwnershipType,
          schoolLevel: this.schoolLevel
        }
        const requestObject = {
          id: this.schoolInfo.id,
          body: body
        }
        await this.updateFacilityOwnership(requestObject)
            .then(() => {
              if (this.responseCode === 204) {
                this.setFacilityOwnershipInfo(null)
                this.$emit('reload')
              }
            })
      }
    }
  }
}
</script>

<style scoped>

</style>