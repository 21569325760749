<template>
  <div>
    <section class="mb-5">
      <p class="font-sm fw-bold">Pre-primary enrolment by age for <strong>MALE</strong> in the current school year</p>
      <div class="d-flex mb-2">
        <div class="col-3"><p class="font-sm fw-bold">Pupil age</p></div>
        <span class="col me-1 text-center font-sm fw-normal">ECCD 1</span>
        <span class="col me-1 text-center font-sm fw-normal">ECCD 2</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 1</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 2</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Below 3 years</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc1Male.belowThree3Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc2Male.belowThree3Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery1Male.belowThree3Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery2Male.belowThree3Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery3Male.belowThree3Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">3 years</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc1Male.three3Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc2Male.three3Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery1Male.three3Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery2Male.three3Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery3Male.three3Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">4 years</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc1Male.four4year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc2Male.four4year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery1Male.four4year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery2Male.four4year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery3Male.four4year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">5 years</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc1Male.five5Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc2Male.five5Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery1Male.five5Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery2Male.five5Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery3Male.five5Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Above 5 years</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc1Male.aboveFive5Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc2Male.aboveFive5Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery1Male.aboveFive5Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery2Male.aboveFive5Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery3Male.aboveFive5Year" type="number" aria-label="female">
        </div>
      </div>
    </section>

    <section class="mb-5">
      <p class="font-sm fw-bold">Pre-primary enrolment by age for <strong>FEMALE</strong> in the current school year</p>
      <div class="d-flex mb-2">
        <div class="col-3"><p class="font-sm fw-bold">Pupil age</p></div>
        <span class="col me-1 text-center font-sm fw-normal">ECCD 1</span>
        <span class="col me-1 text-center font-sm fw-normal">ECCD 2</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 1</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 2</span>
        <span class="col me-1 text-center font-sm fw-normal">Nursery 3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Below 3 years</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc1Female.belowThree3Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc2Female.belowThree3Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery1Female.belowThree3Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery2Female.belowThree3Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery3Female.belowThree3Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">3 years</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc1Female.three3Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc2Female.three3Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery1Female.three3Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery2Female.three3Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery3Female.three3Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">4 years</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc1Female.four4year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc2Female.four4year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery1Female.four4year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery2Female.four4year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery3Female.four4year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">5 years</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc1Female.five5Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc2Female.five5Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery1Female.five5Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery2Female.five5Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery3Female.five5Year" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Above 5 years</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc1Female.aboveFive5Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="edc2Female.aboveFive5Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery1Female.aboveFive5Year" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery2Female.aboveFive5Year" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="nursery3Female.aboveFive5Year" type="number" aria-label="female">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "PrePrimaryAndPrimaryEnrolmentByAge",
  setup() {
    const edc1Male = {
      belowThree3Year: "",
      three3Year: "",
      four4year: "",
      five5Year: "",
      aboveFive5Year: "",
      gender: "MALE",
      schoolClass: "KG1"
    }
    const edc2Male = {
      belowThree3Year: "",
      three3Year: "",
      four4year: "",
      five5Year: "",
      aboveFive5Year: "",
      gender: "MALE",
      schoolClass: "KG2"
    }
    const nursery1Male = {
      belowThree3Year: "",
      three3Year: "",
      four4year: "",
      five5Year: "",
      aboveFive5Year: "",
      gender: "MALE",
      schoolClass: "NURSERY1"
    }
    const nursery2Male = {
      belowThree3Year: "",
      three3Year: "",
      four4year: "",
      five5Year: "",
      aboveFive5Year: "",
      gender: "MALE",
      schoolClass: "NURSERY2"
    }
    const nursery3Male = {
      belowThree3Year: "",
      three3Year: "",
      four4year: "",
      five5Year: "",
      aboveFive5Year: "",
      gender: "MALE",
      schoolClass: "NURSERY3"
    }
    const edc1Female = {
      belowThree3Year: "",
      three3Year: "",
      four4year: "",
      five5Year: "",
      aboveFive5Year: "",
      gender: "FEMALE",
      schoolClass: "KG1"
    }
    const edc2Female = {
      belowThree3Year: "",
      three3Year: "",
      four4year: "",
      five5Year: "",
      aboveFive5Year: "",
      gender: "FEMALE",
      schoolClass: "KG2"
    }
    const nursery1Female = {
      belowThree3Year: "",
      three3Year: "",
      four4year: "",
      five5Year: "",
      aboveFive5Year: "",
      gender: "FEMALE",
      schoolClass: "NURSERY1"
    }
    const nursery2Female = {
      belowThree3Year: "",
      three3Year: "",
      four4year: "",
      five5Year: "",
      aboveFive5Year: "",
      gender: "FEMALE",
      schoolClass: "NURSERY2"
    }
    const nursery3Female = {
      belowThree3Year: "",
      three3Year: "",
      four4year: "",
      five5Year: "",
      aboveFive5Year: "",
      gender: "FEMALE",
      schoolClass: "NURSERY3"
    }


    return {
      edc1Male,
      edc2Male,
      nursery1Male,
      nursery2Male,
      nursery3Male,

      edc1Female,
      edc2Female,
      nursery1Female,
      nursery2Female,
      nursery3Female,
    }
  },

  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
    hasMessage() {
      return this.message !== null
    },
  },

  methods: {
    ...mapActions(['submitEnrolmentByAgeCurrentYear']),

    async submitForm() {
      const body = {
        requestBody: [
          this.edc1Male, this.edc1Female, this.edc2Male, this.edc2Female,
          this.nursery1Male, this.nursery1Female, this.nursery2Male, this.nursery2Female,
          this.nursery3Male, this.nursery3Female
        ],
        school: this.ownSchool.id
      }

      await this.submitEnrolmentByAgeCurrentYear(body)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>