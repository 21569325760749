import axios from "axios";
import { emisconfig } from "../../../../app.config";

const uninterceptedAxios = axios.create();
uninterceptedAxios.defaults.baseURL = `${emisconfig}/`;
uninterceptedAxios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

const initState = {
  identification: null,
  responseCode: null,
  loading: false,
  message: null,
  teacherQualifications: null,
  classSearchResult: null,
  availableClassroom: null,
  nonClassroomInfo: null,
  workshopSearchResult: null,
  activeSubjectStage: null,
  activeEnrolmentStage: null,
  activeBookStage: null,
  facilityRegInfo: null,
  facilityOwnershipInfo: null,
  facilityPowerSourceInfo: null,
  facilityBuilding: null,
  facilityFence: null,
  facilityHealth: null,
  facilityECCD: null,
  facilitySeaters: null,
  facilityToilets: null,
  supervisorInstitutions: null,
  submissionData: null,
  submittedQuestionaires: null,
  numberOfStudentBySubject: null,
  studentTeacherBook: null,
  updateMessage: null,
  updateLoading: false,
  attestationData: null,
};

const state = initState;

const actions = {
  async submitIdentification({ commit, dispatch, state }, requestBody) {
    const { id, body } = requestBody;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`schools/schools/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });

        if (res.data !== null) {
          commit("setResponseCode", res.data.status_code);
          commit("setQAMessage", res.data.message);
        }
      })
      .catch((err) => {
        dispatch("handleError", err);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
      });
  },

  async submitCharacteristics({ commit, dispatch, state }, body) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/school-characteristics`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);

        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        if (res.data !== null) {
          commit("setResponseCode", res.data.status_code);
          console.log(res.data.status_code);
          commit("setQAMessage", res.data.message);
        } else {
          console.log(res.data.body);
        }
      })
      .catch((err) => {
        // dispatch('handleError', err)
        commit("setQuestionnaireLoadingStatus", false);

        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed!",
        });
        commit("setResponseCode", err.response.status);
      });
  },

  async submitEnrolmentBirthCert({ commit, dispatch, state }, body) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-a/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitEnrolmentByAgeCurrentYear({ commit, dispatch, state }, body) {
    const { requestBody, school } = body;
    console.log(requestBody);
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-c4/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);

        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitJuniorEnrolmentByAgeCurrentYear(
    { commit, dispatch, state },
    body
  ) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-c2/${school}`, requestBody)
      .then((res) => {
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
        commit("setQuestionnaireLoadingStatus", false);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitSeniorEnrolmentByAgeCurrentYear(
    { commit, dispatch, state },
    body
  ) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-c5/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitEnrolmentNewEntrant({ commit, dispatch, state }, body) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-b2/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitEnrolmentNewEntrantJunior({ commit, dispatch, state }, body) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-b1/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitEnrolmentNewEntrantSenior({ commit, dispatch, state }, body) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-b3/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitEnrolmentByStream({ commit, dispatch, state }, body) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-c1/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitEnrolmentByClass({ commit, dispatch, state }, body) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-c3/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitEnrolmentStudentFlowByClass({ commit, dispatch, state }, body) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-g/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitEnrolmentStudentWithSpecialNeeds(
    { commit, dispatch, state },
    body
  ) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-f/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitEnrolmentNumberOfOrphans({ commit, dispatch, state }, body) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment-all/section-e/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitStaffInfo({ commit, dispatch, state }, body) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`auth/staff`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async updateStaffDetail({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`auth/staff/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitNABTEBEnrolment({ commit, dispatch, state }, body) {
    const { requestBody, school } = body;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/enrollment/section-d/${school}`, requestBody)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  // CLASSROOM/FACILITY
  async fetchAvailableClassroom({ commit, dispatch, state }, requestBody) {
    const { id, level } = requestBody;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/classrooms-by-school/${id}/and-level/${level}`, {
        timeout: 10000,
      })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setAvailableClassroom", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitAvailableClassroom({ commit, dispatch, state }, body) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/classrooms`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", "Classroom submitted successfully!");
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async updateAvailableClassroom({ commit, dispatch, state }, requestBody) {
    const { id, body } = requestBody;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/classrooms/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", "Classroom updated successfully!");
        commit("setAvailableClassroom", null);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitClassRegistration({ commit, dispatch, state }, body) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/classroom-info`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit(
          "setQAMessage",
          "Classroom registration submitted successfully!"
        );
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async updateClassRegistration({ commit, dispatch, state }, requestBody) {
    const { id, body } = requestBody;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/classroom-info/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async fetchNonClassroomRegistration(
    { commit, dispatch, state },
    requestBody
  ) {
    const { id, level } = requestBody;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/classroom-numbers-by-school/${id}/and-level/${level}`, {
        timeout: 10000,
      })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setNonClassroomInfo", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        commit("setQuestionnaireLoadingStatus", false);
        dispatch("handleError", err);
      });
  },

  async submitNonClassroomRegistration({ commit, dispatch, state }, body) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/classroom-numbers`, body)
      .then((res) => {
        const message = `${res.data.message} Click continue to go the the next section.`;
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async updateNonClassroomRegistration(
    { commit, dispatch, state },
    requestBody
  ) {
    const { id, body } = requestBody;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/classroom-numbers/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.status);
        commit("setQAMessage", "Classroom updated successfully!");
        commit("setNonClassroomInfo", null);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitTypeOfWorkshop({ commit, dispatch, state }, body) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/workshop`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        const message = `${res.data.message} Click continue to go the the next section.`;
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async updateTypeOfWorkshop({ commit, dispatch, state }, requestBody) {
    const { id, body } = requestBody;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/workshop/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        dispatch("handleError", err);
      });
  },

  async findWorkshopByDescription({ commit, dispatch, state }, description) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/workshop-by-description/${description}`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setResponseCode", res.data.status_code);
        commit("setWorkshopSearchResult", res.data);
      })
      .catch((err) => {
        dispatch("handleError", err);
      });
  },

  async findClassroomByDescription({ commit, dispatch, state }, description) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/classroom-info-by-description/${description}`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setClassSearchResult", res.data);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitNumberOfStudentBySubject(
    { commit, dispatch, state },
    requestBody
  ) {
    const { id, body, type } = requestBody;
    const url =
      type !== undefined
        ? `questionaire/number-of-student-by-subject-all/${id}?type=${type}`
        : `questionaire/number-of-student-by-subject-all/${id}`;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(url, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async updateRequest({ commit }, payload) {
    const { endpoint, body } = payload;
    commit("setUpdateLoadingStatus", true);
    await uninterceptedAxios
      .patch(endpoint, body, { timeout: 15000 })
      .then((res) => {
        commit("setUpdateLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
      })
      .catch(() => {
        commit("setUpdateLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Error updating data!",
        });
      });
  },

  async updateNumberOfStudentBySubject({ dispatch, state }, payload) {
    const { id, body } = payload;
    const requestObject = {
      endpoint: `questionaire/number-of-student-by-subject/${id}`,
      body: body,
    };
    await dispatch("updateRequest", requestObject);
  },
  async updateStudentTeacherBook({ dispatch }, payload) {
    const { id, body } = payload;
    const requestObject = {
      endpoint: `questionaire/books/${id}`,
      body: body,
    };
    await dispatch("updateRequest", requestObject);
  },
  async submitStudentTeacherBook({ commit, dispatch, state }, requestBody) {
    const { id, body } = requestBody;
    commit("setUpdateLoadingStatus", true);
    await axios
      .post(`questionaire/books-all/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async fetchStudentTeacherBook({ commit, state }, id) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`questionaire/books/school/${id}`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setResponseCode", res.status);
        commit("setStudentTeacherBook", res.data);
      })
      .catch((err) => {
        commit("setQuestionnaireLoadingStatus", false);
        if (err.response !== undefined) {
          const message =
            err.response.data.error_description !== undefined
              ? err.response.data.error_description
              : err.response.data.body !== undefined
              ? err.response.data.body
              : err.response.data.message;
          commit("setQAMessage", message);
        } else {
          commit("setQAMessage", "Timeout");
        }
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        dispatch("handleError", err);
      });
  },

  async submitCaregiverResource({ commit, dispatch }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/care-giver/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        dispatch("handleError", err);
      });
  },

  async submitTeachersQualification(
    { commit, dispatch, state },
    requestObject
  ) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`auth/qualifications-all/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        dispatch("handleError", err);
      });
  },

  async updateTeachersQualification(
    { commit, dispatch, state },
    requestObject
  ) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`auth/qualification-all`, requestObject, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Error Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async fetchSchoolTeachersQualificationUnpaged(
    { commit, dispatch, state },
    requestObject
  ) {
    const { id, level } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`auth/qualifications-unpaged/school/${id}/level/${level}`, {
        timeout: 10000,
      })
      .then((res) => {
        const { data } = res;
        if (data.length > 0) {
          commit("setUpdateMessageLoading", {
            status: "success",
            message: "Record found",
          });
        } else {
          commit("setUpdateMessageLoading", {
            status: "warning",
            message: "No record found",
          });
        }
        commit("setQuestionnaireLoadingStatus", false);

        commit("setResponseCode", res.status);
        commit("setTeacherQualifications", res.data);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Error Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async fetchNumberOfStudentBySubject({ commit, state }, schoolId) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(
        `questionaire/number-of-student-by-subject/school/${schoolId}/paged`,
        { timeout: 10000 }
      )
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setResponseCode", res.status);
        commit("setNumberOfStudentBySubject", res.data.content);
      })
      .catch((err) => {
        commit("setQuestionnaireLoadingStatus", false);
        if (err.response !== undefined) {
          const message =
            err.response.data.error_description !== undefined
              ? err.response.data.error_description
              : err.response.data.body !== undefined
              ? err.response.data.body
              : err.response.data.message;
          commit("setQAMessage", message);
        } else {
          commit("setQAMessage", "Timeout");
        }
      });
  },

  async submitFacility({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/facility/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitSourceOfPower({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/facility-power/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitFacilityFence({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/facility-wall/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitHealthFacility({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/facility-health/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitFacilitySeaters({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/facility-classrooms-all/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });

        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitFacilityToilets({ commit, dispatch, state }, requestObject) {
    const { id, level, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/facility-usable-all/${id}/with-level/${level}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitFacilityType({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/facility-ownership/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });

        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitFacilityECCD({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`f/facilities-ecd/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async fetchFacilityRegistration({ commit, dispatch, state }, requestObject) {
    const { id, level } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/facility-by-level/${level}/id/${id}`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setFacilityRegInfo", res.data);
        console.log(res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        commit("setFacilityRegInfo", null);
        dispatch("handleError", err);
      });
  },

  async updateFacilityRegistration({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/facility/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
        commit("setResponseCode", err.response.status);
      });
  },

  async fetchFacilityOwnership({ commit, dispatch, state }, requestObject) {
    const { id, level } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/facility-ownership-by-level/${level}/id/${id}`, {
        timeout: 10000,
      })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setFacilityOwnershipInfo", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        commit("setFacilityOwnershipInfo", null);
        dispatch("handleError", err);
      });
  },

  async updateFacilityOwnership({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/facility-ownership/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        commit("setResponseCode", err.response.status);
        dispatch("handleError", err);
      });
  },

  async fetchFacilityPowerSource({ commit, dispatch, state }, requestObject) {
    const { id, level } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/facility-power-by-level/${level}/id/${id}`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setFacilityPowerSourceInfo", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        if (err.message == "Request failed with status code 404") {
          commit("setUpdateMessageLoading", {
            status: "warning",
            message: "Facility Not Found!",
          });
          commit("setResponseCode", 404);
        } else {
          commit("setUpdateMessageLoading", {
            status: "error",
            message: "Something failed",
          });
        }

        commit("setFacilityPowerSourceInfo", null);
        dispatch("handleError", err);
      });
  },

  async updateFacilityPowerSource({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/facility-power/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
        commit("setResponseCode", err.response.status);
      });
  },

  async fetchFacilityBuilding({ commit, dispatch, state }, requestObject) {
    const { id, level } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/facility-buildings-by-level/${level}/id/${id}`, {
        timeout: 10000,
      })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setFacilityBuilding", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        commit("setFacilityBuilding", null);
        dispatch("handleError", err);
      });
  },

  async updateFacilityBuilding({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/facility-buildings/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
        commit("setResponseCode", err.response.status);
      });
  },

  async fetchFacilityECCD({ state, commit, dispatch }, id) {
    commit("setQuestionnaireLoadingStatus", true);
    if (state.facilityECCD === null) {
      await axios
        .get(`f/facility-eccds-by-level/id/${id}`, { timeout: 10000 })
        .then((res) => {
          commit("setQuestionnaireLoadingStatus", false);
          commit("setUpdateMessageLoading", {
            status: "success",
            message: res.data.message,
          });
          commit("setFacilityECCD", res.data);
          commit("setResponseCode", res.status);
        })
        .catch((err) => {
          commit("setUpdateMessageLoading", {
            status: "error",
            message: "Something failed",
          });
          commit("setFacilityECCD", null);
          dispatch("handleError", err);
        });
    }
  },

  async updateFacilityECCD({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/facilities-ecd/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
        commit("setResponseCode", err.response.status);
      });
  },

  async fetchFacilityHealth({ commit, dispatch, state }, requestObject) {
    const { id, level } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/facility-health-by-level/${level}/id/${id}`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setFacilityHealth", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        if (err.message == "Request failed with status code 404") {
          commit("setUpdateMessageLoading", {
            status: "warning",
            message: "Facility Not Found!",
          });
          commit("setResponseCode", 404);
        } else {
          commit("setUpdateMessageLoading", {
            status: "error",
            message: "Something failed",
          });
        }
        commit("setFacilityHealth", null);
        dispatch("handleError", err);
      });
  },

  async updateFacilityHealth({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/facility-health/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
        commit("setResponseCode", err.response.status);
      });
  },

  async fetchFacilityFence({ commit, dispatch, state }, requestObject) {
    const { id, level } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/facility-wall-by-level/${level}/id/${id}`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setFacilityFence", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        if (err.message == "Request failed with status code 404") {
          commit("setUpdateMessageLoading", {
            status: "warning",
            message: "Facility Not Found!",
          });
          commit("setResponseCode", 404);
        } else {
          commit("setUpdateMessageLoading", {
            status: "error",
            message: "Something failed",
          });
        }
        commit("setFacilityFence", null);
        dispatch("handleError", err);
      });
  },

  async updateFacilityFence({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/facility-wall/${id}`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
        commit("setResponseCode", err.response.status);
      });
  },

  async fetchFacilitySeaters({ commit, dispatch, state }, id) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/facility-classrooms-by-school/${id}`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setFacilitySeaters", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        commit("setFacilitySeaters", null);
        dispatch("handleError", err);
      });
  },

  async updateFacilitySeaters({ commit, dispatch, state }, body) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/facility-classrooms-all`, body, { timeout: 10000 })
      .then((res) => {
        if (res.data.status_code === 204) {
          commit("setFacilitySeaters", null);
        }
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
        commit("setResponseCode", err.status);
      });
  },

  async fetchToiletFacility({ commit, dispatch, state }, requestObject) {
    const { id, level } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`f/facility-usable-by-level/${level}/id/${id}`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setFacilityToilets", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        commit("setFacilityToilets", null);
        dispatch("handleError", err);
      });
  },

  async updateToiletFacilitySeaters({ commit, dispatch, state }, body) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .patch(`f/facility-usable-all`, body, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
        commit("setResponseCode", err.response.status);
      });
  },

  async submitFLHEOrientation({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/flhe-orientation/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitFLHEQuestion({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/flhe-question-all/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitFLHEGenderQuestion({ commit, dispatch, state }, requestObject) {
    const { id, body } = requestObject;
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .post(`questionaire/flhe-gender-all/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setResponseCode", res.data.status_code);
        commit("setQAMessage", res.data.message);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async submitAttestationDocuments({ commit, dispatch, state }, requestBody) {
    const { id, body } = requestBody;
    commit("setQuestionnaireLoadingStatus", true);
    console.log(body);
    await axios
      .post(`undertaking/signature/${id}`, body)
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Error Something failed",
        });
        dispatch("handleError", err);
      });
  },

  async fetchAttestation({ commit, dispatch, state }) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`undertaking/signature`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setAttestationData", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        commit("setSupervisorInstitutions", null);
        dispatch("handleError", err);
      });
  },
  async fetchSupervisorInstitutions({ commit, dispatch }) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`auth/supervisor-institutions`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        commit("setSupervisorInstitutions", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        commit("setUpdateMessageLoading", {
          status: "error",
          message: "Something failed",
        });
        commit("setSupervisorInstitutions", null);
        dispatch("handleError", err);
      });
  },

  async viewSubmissionForSchoolWithId({ state, commit, dispatch }, schoolId) {
    if (state.submissionData === null) {
      commit("setQuestionnaireLoadingStatus", true);
      await axios
        .get(`view/questionaire/${schoolId}`, { timeout: 10000 })
        .then((res) => {
          commit("setQuestionnaireLoadingStatus", false);
          console.log(res.data);
          commit("setSubmissionData", res.data);
        })
        .catch((err) => {
          dispatch("handleError", err);
        });
    }
  },

  async fetchSubmittedQuestionsForOfficers({ commit, dispatch }) {
    commit("setQuestionnaireLoadingStatus", true);
    await axios
      .get(`view/questionaires-submitted`, { timeout: 10000 })
      .then((res) => {
        commit("setQuestionnaireLoadingStatus", false);
        commit("setSubmittedQuestionaires", res.data);
        commit("setResponseCode", res.status);
      })
      .catch((err) => {
        commit("setSubmittedQuestionaires", null);
        dispatch("handleError", err);
      });
  },

  handleError({ commit }, error) {
    commit("setQuestionnaireLoadingStatus", false);
    if (error.response) {
      if (error.response.status === 404) {
        commit("setQAMessage", error.response.data.error_description);
      } else if (error.response.data.description) {
        commit("setQAMessage", error.response.data.description);
      } else if (error.response.data.error_description) {
        commit("setQAMessage", error.response.data.error_description);
      } else {
        commit("setQAMessage", error.response.statusText);
      }
    } else if (error.request) {
      if (error.request.statusText === "") {
        commit("setQAMessage", "Something went wrong with the Network!");
      } else {
        commit("setQAMessage", error.response.statusText);
      }
    } else {
      commit("setQAMessage", error.message);
    }
  },
};

const mutations = {
  setAttestationData(state, payload) {
    state.attestationData = payload;
  },
  setUpdateLoadingStatus(state, value) {
    state.updateLoading = value;
  },
  setUpdateMessageLoading(state, value) {
    state.updateMessage = value;
  },
  setQuestionnaireLoadingStatus(state, value) {
    state.loading = value;
  },

  setResponseCode(state, value) {
    state.responseCode = value;
  },

  setQAMessage(state, message) {
    state.message = message;
  },

  setActiveEnrolmentStage(state, stage) {
    state.activeEnrolmentStage = stage;
  },

  setActiveSubjectStage(state, stage) {
    state.activeSubjectStage = stage;
  },

  setClassSearchResult(state, value) {
    state.classSearchResult = value;
  },

  setWorkshopSearchResult(state, value) {
    state.workshopSearchResult = value;
  },
  setStudentTeacherBook(state, value) {
    state.studentTeacherBook = value;
  },

  setAvailableClassroom(state, value) {
    state.availableClassroom = value;
  },

  setNonClassroomInfo(state, value) {
    state.nonClassroomInfo = value;
  },

  setActiveBookStage(state, value) {
    state.activeBookStage = value;
  },

  setTeacherQualifications(state, value) {
    state.teacherQualifications = value;
  },
  setNumberOfStudentBySubject(state, value) {
    state.numberOfStudentBySubject = value;
  },

  setFacilityRegInfo(state, value) {
    state.facilityRegInfo = value;
  },

  setFacilityOwnershipInfo(state, value) {
    state.facilityOwnershipInfo = value;
  },

  setFacilityPowerSourceInfo(state, value) {
    state.facilityPowerSourceInfo = value;
  },

  setFacilityFence(state, value) {
    state.facilityFence = value;
  },

  setFacilityHealth(state, value) {
    state.facilityHealth = value;
  },

  setFacilityECCD(state, value) {
    state.facilityECCD = value;
  },

  setFacilitySeaters(state, value) {
    state.facilitySeaters = value;
  },

  setFacilityToilets(state, value) {
    state.facilityToilets = value;
  },

  setFacilityBuilding(state, value) {
    state.facilityBuilding = value;
  },

  setSupervisorInstitutions(state, value) {
    state.supervisorInstitutions = value;
  },

  setSubmissionData(state, value) {
    state.submissionData = value;
  },

  setSubmittedQuestionaires(state, value) {
    state.submittedQuestionaires = value;
  },

  resetServiceState(state) {
    Object.assign(state, initState);
  },
};

export default {
  state,
  actions,
  mutations,
};
