<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Pupil-Teacher Ratio by LGA, Public Junior Secondary School.</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-tiny" aria-label="Users table">
        <thead>
        <tr>
          <th class="align-top py-3 ps-4 bigger-cell first">
            LGA
          </th>
          <th class="align-top py-3 big-cell">
            Enrolment (M+F)
          </th>
          <th class="align-top py-3 big-cell">
            All Teachers (M+F)
          </th>
          <th class="align-top py-3 big-cell">
            All Teacher-Ratio
          </th>
          <th class="align-top py-3 big-cell">
            Qualified Teachers
          </th>
          <th class="align-top py-3 bigger-cell">
            Qualified Teacher-Ratio
          </th>
        </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg" 
            v-for="(data, index) in tableData"
            :key="index"          
          >
            <td class="py-3 ps-4 first">{{ data.lga }}</td>
            <td class="py-3">{{ data.enrolment_m_f }}</td>
            <td class="py-3">{{ data.all_teachers_m_f }}</td>
            <td class="py-3">{{ data.all_teacher_ratio }}</td>
            <td class="py-3">{{ data.qualified_teachers }}</td>
            <td class="py-3">{{ data.qualified_teacher_ratio }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "PupilTeacherRatioPublicJunior",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 62, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })

    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
.bigger-cell {
  width: 20%;
}
.big-cell {
  width: 15%;
}
</style>