<template>
  <div class="graph-container d-flex flex-column bg-white radius-full mt-4 mb-4">
    <div class="d-flex justify-content-between">
      <span class="font-sm fw-bold">Number of Schools With Chart</span>
      <a-select 
        :value="numberOfSchWithChartOption"
        style="width: 120px"
        placeholder="View by"
        :options="viewByOptions.map(option => ({value: option}))"
        @change="numberOfSchWithChartOptionChange"
      />
    </div>
    <div>
      <canvas class="charts" id="number-of-schools-with-chart" height="260"></canvas>
    </div>    
  </div>
</template>

<script>
import { computed, onMounted } from '@vue/runtime-dom'
import {Select} from "ant-design-vue"
import Chart from "chart.js/auto";
import { useStore } from 'vuex'
import { watch, ref } from 'vue'

export default {
  name: "NumberOfSchoolWithChart",
  components: {
    "a-select": Select,
  },
  emits: ['reload'],
  setup(props, {emit}) {
    const numberOfSchWithChartOption = ref('')
    const store = useStore()
    const params = ref()
    const viewByOptions = ['Level', 'Location']
    const selectedOptions = ref('')
    const chartData = ref(null)
    const xAxisLabel = ref([])
    const dataSet = ref([])    
    const numberOfSchWithChartData = computed(() => store.state.analysis.numberOfSchoolsWithData)

    const populateChart = () => {            
      dataSet.value = Object.values(numberOfSchWithChartData.value.data.map(item => item.value))      
      let checkLevel = Object.values(numberOfSchWithChartData.value.data)[0].id.level
      let checkLocation = Object.values(numberOfSchWithChartData.value.data)[0].id.location      
      
      if (checkLevel !== undefined) {
        numberOfSchWithChartOption.value = 'Level'
        xAxisLabel.value = Object.values(numberOfSchWithChartData.value.data.map(item => item.id.level))
      } else if (checkLocation !== undefined) {
        numberOfSchWithChartOption.value = 'Location'
        xAxisLabel.value = Object.values(numberOfSchWithChartData.value.data.map(item => item.id.location))
      } else {
        numberOfSchWithChartOption.value = ''
        xAxisLabel.value = Object.values(numberOfSchWithChartData.value.data.map(item => item.id.no_of_school_with))
      }
      
      chartData.value = {
        type: 'bar',
        data: {
          labels: xAxisLabel.value,
          datasets: [
            {
              label: "Schools With",
              categoryPercentage: 0.3,
              borderRadius: 2,
              data: dataSet.value,
              backgroundColor: 'rgba(0, 242, 104, 1)',
            },
          ]
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        },
      }
    }
    
    watch(
      () => numberOfSchWithChartData.value,
      () => {        
        if (numberOfSchWithChartData.value !== null && chartData.value === null) {    
          emit('reload')
        }
      }
    )

    onMounted(() => {
      if (numberOfSchWithChartData.value === null) {
        store.dispatch('fetchNumberOfSchWithChartData', params.value)
      } else {
        populateChart()
        const numberOfSchWithChart = document.getElementById('number-of-schools-with-chart')     
        new Chart(numberOfSchWithChart, chartData.value)     
      }
    }) 

    const numberOfSchWithChartOptionChange = (value) => {      
      const param = value.toLowerCase().replace(' ', '_')
      selectedOptions.value = param
      chartData.value = null
      store.dispatch('fetchNumberOfSchWithChartData', param)
    }

    return {
      viewByOptions,
      selectedOptions,
      numberOfSchWithChartOption,
      numberOfSchWithChartOptionChange
    }
  },
}
</script>

<style scoped>
.charts {
  max-height: 300px;
}
</style>