<template>
  <div class="mt-2">
    <div class="table-responsive bg-white shadow-sm radius-full mx-5 pt-3 mb-5">
      <a-table
          class="ant-table-striped"
          size="small"      
          :data-source="dataSource"
          :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
        <a-table-column width="80%">
          <template #title><span class="ps-4">Table Name</span></template>
          <template #default="{record}">
            <span class="ps-4">{{ record.name }}</span>
          </template>
        </a-table-column>
        <a-table-column width="20%" key="action" title="Action">        
          <template #default="{record}">
            <span @click="showTableWithIndex(record.key)">view table</span>
          </template>
        </a-table-column >
      </a-table>
    </div>
  </div>
</template>

<script>
import {useRouter} from "vue-router";
import {Table, TableColumn} from "ant-design-vue";

class Item {
  constructor(key, name) {
    this.key = key
    this.name = name
  }
}

export default {
  name: "Facilities",
   components: {
    'a-table': Table,
    'a-table-column': TableColumn
  },
  setup() {
    const router = useRouter()
    const tables = [
      "Number of classrooms by school type and LGA",
      "Pupil-classroom ratio by school type and LGA",
      "Characteristics of Public Pre-Primary and Primary schools with various needs", 
      "Selective characteristics of Public Pre-Primary and Primary school facilities", 
      "Characteristics of Public Junior Secondary Schools with various needs", 
      "Selective characteristics of Public Junior Secondary school facilities",
      "Characteristics of Public Senior Secondary Schools with various needs",
      "Selective characteristics of Public Senior Secondary school facilities",
      "Characteristics of Public Science & Technical Secondary Schools with various needs", 
      "Selective characteristics of Public Science & Technical Secondary Schools facilities",
      "Main source of safe water in public primary schools (Percentage)",
      "Main source of safe water in public Junior Secondary schools (Percentage)",
      "Main source of safe water in public Senior Secondary schools (Percentage)",
      "Toilets in public primary schools",
      "Toilets in public Junior Secondary schools",
      "Toilets in public Senior Secondary schools",
    ]

    const dataSource = Array.from(tables, (item, index) => new Item(index, item) )

    const showTableWithIndex = (index) => {
      router.push({ path: `/facilitiesTables/${index}` })
    }

    return {
      dataSource,
      showTableWithIndex
    }
  }
 }
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style lang="scss">
.big-cell {
  width: 80%;
}
.small-cell {
  width: 20%;
}
.view-table {
  color: #000;
  &:hover {
    text-decoration: underline;
  }
}
</style>