import axios from "axios";
import { emisconfig } from "../../../app.config";
const uninterceptedAxios = axios.create();
uninterceptedAxios.defaults.baseURL = `${emisconfig.baseURL}/`;
uninterceptedAxios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
const state = {
  loading: false,
  message: null,
  updateMessage: null,
  activePlanning: null,
  ascTerms: null,
};

const actions = {
  async submitTerms({ commit, state }, id) {
    await uninterceptedAxios
      .post(`asc/school/${id}`)
      .then((res) => {
        commit("setLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: "You have successfully accept our terms and condition",
        });
      })
      .catch((err) => {
        if (err.response.data.error_description !== undefined) {
          commit("setASCPlanningMessage", err.response.data.error_description);
          commit("setUpdateMessageLoading", {
            status: "error",
            message: err.response.data.error_description,
          });
        }
      });
  },
  async submitPlanning({ commit, state }, body) {
    commit("setLoadingStatus", true);
    await axios
      .post(`asc/planning`, body)
      .then((res) => {
        commit("setLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
        if (res.data.message !== undefined) {
          commit("setASCPlanningMessage", res.data.message);
        }
      })
      .catch((err) => {
        commit("setLoadingStatus", false);
        if (err.response.data.error_description !== undefined) {
          commit("setUpdateMessageLoading", {
            status: "error",
            message: err.response.data.error,
          });
          commit("setASCPlanningMessage", err.response.data.error_description);
        }
      });
  },

  async checkPlanning({ commit, state }) {
    commit("setLoadingStatus", true);
    await axios
      .get(`asc/v1/planning-current`)
      .then((res) => {
        if (res.status === 200) {
          commit("setActivePlanningStatus", res.data);
          commit("setLoadingStatus", false);
        } else {
          commit("setActivePlanningStatus", {});
          commit("setUpdateMessageLoading", {
            status: "error",
            message: "Asc Planning Not Found!",
          });
          commit("setLoadingStatus", false);
        }
      })
      .catch((err) => {
        commit("setActivePlanningStatus", {});
        if (err.response.data.error_description !== undefined) {
          commit("setLoadingStatus", false);
          commit("setUpdateMessageLoading", {
            status: "error",
            message: err.response.data.error_description,
          });
          // commit('setASCPlanningMessage', err.response.data.error_description)
        }
      });
  },

  async updatePlanning({ commit, state }, body) {
    commit("setLoadingStatus", true);
    await axios
      .put(`asc/planning/${body.id}`, body)
      .then((res) => {
        commit("setLoadingStatus", false);
        if (res.data.message !== undefined) {
          commit("setASCPlanningMessage", res.data.message);
        }
      })
      .catch((err) => {
        commit("setLoadingStatus", false);
        if (err.response.data.error_description !== undefined) {
          commit("setASCPlanningMessage", err.response.data.error_description);
        }
      });
  },

  async deActivatePlanning({ commit, state }, body) {
    commit("setLoadingStatus", true);
    await axios
      .put(`asc/planning-deactivate`, body)
      .then((res) => {
        commit("setLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "success",
          message: res.data.message,
        });
      })
      .catch((err) => {
        commit("setLoadingStatus", false);
        commit("setUpdateMessageLoading", {
          status: "error",
          message: err.response.data.error_description,
        });
        if (err.response.data.error_description !== undefined) {
          commit("setASCPlanningMessage", err.response.data.error_description);
        }
      });
  },

  async checkTerms({ commit }, id) {
    commit("setLoadingStatus", true);
    // await uninterceptedAxios.get(`${host.host}:8085/asc/terms/school/${id}`)
    await uninterceptedAxios
      .get(`asc/terms/school/${id}`)
      .then((res) => {
        if (res.status === 200) {
          commit("setLoadingStatus", false);
          commit("setUpdateMessageLoading", {
            status: "success",
            message: res.data.message,
          });
          commit("setAscTerms", true);
        } else if (res.status === 404) {
          commit("setUpdateMessageLoading", {
            status: "error",
            message:
              "You have to accept terms and condition before you proceed",
          });
          commit("setAscTerms", false);
          commit("setLoadingStatus", false);
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          commit("setUpdateMessageLoading", {
            status: "error",
            message:
              "You have to accept terms and condition before you proceed",
          });
          commit("setAscTerms", false);
          commit("setLoadingStatus", false);
          commit("setASCPlanningMessage", err.response.data.error_description);
        }
      });
  },
};

const mutations = {
  setUpdateMessageLoading(state, value) {
    state.updateMessage = value;
  },
  setLoadingStatus(state, status) {
    state.loading = status;
  },
  setASCPlanningMessage(state, message) {
    state.message = message;
  },
  setActivePlanningStatus(state, payload) {
    state.activePlanning = payload;
  },
  setAscTerms(state, payload) {
    state.ascTerms = payload;
  },
};

export default {
  state,
  mutations,
  actions,
};
