<template>
  <div>
    <section class="mb-5 mx-4 pt-4">
      <p class="font-sm fw-bold">Junior secondary school.</p>
      <p class="font-sm fw-bold">Number of core subject textbooks available to pupils provided by government.</p>
      <div class="d-flex mb-2">
        <div class="col-5"><p class="font-sm fw-medium">Text book</p></div>
        <span class="col me-1 text-center font-sm fw-medium">JSS 1</span>
        <span class="col me-1 text-center font-sm fw-medium">JSS 2</span>
        <span class="col me-1 text-center font-sm fw-medium">JSS 3</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">English</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="englishJSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Mathematics</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="mathJSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Social studies</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesJSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesJSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="socialStudiesJSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Basic Science</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicScienceJSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicScienceJSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicScienceJSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-5"><span class="font-sm fw-normal">Basic technology</span></div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSOneStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSTwoStudent.number" type="text" aria-label="male">
        </div>
        <div class="col px-0 me-1">
          <input class="form-control px-1 outline" v-model="basicTechJSSThreeStudent.number" type="text" aria-label="male">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "JuniorStudentBookSection",
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const englishJSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "JSS1",
      number: ''
    }
    const englishJSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "JSS2",
      number: ''
    }
    const englishJSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "ENGLISH",
      schoolClass: "JSS3",
      number: ''
    }
    const mathJSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "JSS1",
      number: ''
    }
    const mathJSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "JSS2",
      number: ''
    }
    const mathJSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "MATHEMATICS",
      schoolClass: "JSS3",
      number: ''
    }
    const socialStudiesJSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "SOCIAL_STUDIES",
      schoolClass: "JSS1",
      number: ''
    }
    const socialStudiesJSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "SOCIAL_STUDIES",
      schoolClass: "JSS2",
      number: ''
    }
    const socialStudiesJSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "SOCIAL_STUDIES",
      schoolClass: "JSS3",
      number: ''
    }
    const basicScienceJSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_SCIENCE",
      schoolClass: "JSS1",
      number: ''
    }
    const basicScienceJSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_SCIENCE",
      schoolClass: "JSS2",
      number: ''
    }
    const basicScienceJSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_SCIENCE",
      schoolClass: "JSS3",
      number: ''
    }
    const basicTechJSSOneStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS1",
      number: ''
    }
    const basicTechJSSTwoStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS2",
      number: ''
    }
    const basicTechJSSThreeStudent = {
      user: "STUDENT_PUPIL",
      subject: "BASIC_TECHNOLOGY",
      schoolClass: "JSS3",
      number: ''
    }

    return {
      englishJSSOneStudent,
      englishJSSTwoStudent,
      englishJSSThreeStudent,
      mathJSSOneStudent,
      mathJSSTwoStudent,
      mathJSSThreeStudent,
      socialStudiesJSSOneStudent,
      socialStudiesJSSTwoStudent,
      socialStudiesJSSThreeStudent,
      basicScienceJSSOneStudent,
      basicScienceJSSTwoStudent,
      basicScienceJSSThreeStudent,
      basicTechJSSOneStudent,
      basicTechJSSTwoStudent,
      basicTechJSSThreeStudent,

      schoolInfo,
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    }
  },
  methods: {
    ...mapActions(['submitStudentTeacherBook']),
    submitForm() {
      const subjects = [
        this.englishJSSOneStudent,
        this.englishJSSTwoStudent,
        this.englishJSSThreeStudent,
        this.mathJSSOneStudent,
        this.mathJSSTwoStudent,
        this.mathJSSThreeStudent,
        this.socialStudiesJSSOneStudent,
        this.socialStudiesJSSTwoStudent,
        this.socialStudiesJSSThreeStudent,
        this.basicScienceJSSOneStudent,
        this.basicScienceJSSTwoStudent,
        this.basicScienceJSSThreeStudent,
        this.basicTechJSSOneStudent,
        this.basicTechJSSTwoStudent,
        this.basicTechJSSThreeStudent,
      ]
      this.submitStudentTeacherBook({id: this.schoolInfo.id, body: subjects})
          .then(() => {
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>