<template>
  <div class="mt-5">
    <div class="d-flex justify-content-between">
      <div v-if="loading" class="overlay">
        <clip-loader class="loading"></clip-loader>
      </div>
      <p class="font-md fw-bold mb-2">Attestation</p>
      <!-- <p class="font-sm fw-bold mb-0 pointer-no-bg dm-color" @click="$emit('showReview')">Review Questionnaire</p> -->
      
    </div>
    <div class="bg-white radius-full py-4 px-5 mb-4">
      <p class="font-sm fw-bold mx-3 mb-3">Attestation (by school level) in current academic class.</p>
      <div class="mt-3 mb-4 mx-3">
        <label class="form-label font-sm fw-normal">
          Please select school level
        </label>
        <select class="form-select" required aria-label="Default select example" @change="checkAttestation" v-model="schoolLevelOption">
          <option value="">Select level</option>
          <option value="PRE_PRIMARY">Pre-Primary</option>
          <option value="PRIMARY">Primary</option>
          <option value="JUNIOR">Junior</option>
          <option value="SENIOR">Senior</option>
        </select>
      </div>
    </div>
    <div>
      <div v-if="filteredAttestation.length > 0" class="bg-white radius-full py-4 px-5 mb-4">
        <a-row :gutter="32">
          <a-space align="center">
            <a-col class="gutter-row" :span="6" v-for="(item, index) in filteredAttestation" :key="index">
              <a-card hoverable size="small" :title="item.schoolLevelOption" style="width: 150px">
                  <a-image alt="example" :src="pdfIcon" width="80" />
                <template #actions>
                  <a href=""><download-outlined /></a>
                </template>
              </a-card>
            </a-col>
          </a-space>
        </a-row>
        
      </div>
    </div>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <p class="text-center fw-bold">Upload signature</p>
      <p class="text-center fw-normal font-sm mt-2">
        Public officers completing this form are reminded that Civil Service Rule 04107 Section 1 requires the 
        recording and supply of accurate data. Failure to do so amounts to gross misconduct punishable by 
        sanctions that may include dismissal.
      </p>
      <p class="text-center font-tiny fw-normal mt-2">I have checked the information in this form and can confirm that it is complete and correct</p>

      <div class="d-flex flex-column justify-content-center align-items-center my-4">
        <form enctype="multipart/form-data" novalidate v-if="isInitial">
          <div class="dropbox">
            <input type="file" :name="uploadFieldName" :disabled="isSaving"
                 @change="fileChange($event.target.name, $event.target.files); fileCount=$event.target.files.length" accept="image/*" class="input-file">
<!--              @change="fileChange($event.target.name, $event.target.files); fileCount=$event.target.files.length" accept="image/*,.doc,.pdf" class="input-file">            -->
            <span class="d-flex flex-column justify-content-center align-items-center h-100" v-if="isInitial">
              <img :src="icon" class="upload_icon" alt="Upload image">
              <span class="font-sm fw-normal">Upload image</span>
            </span>
            <span class="text-center h-100" v-if="isSaving">
              Uploading {{ fileCount }} file
            </span>
          </div>
        </form>

        <div v-if="isSaving" class="files-container d-flex align-items-center justify-content-between px-3 bg-accent">
          <span class="d-flex">
            <img :src="pdfIcon" class="upload_icon" alt="Selected file">
            <span class="font-sm fw-normal ms-2">{{fileName}} <br>{{fileSize}}</span>
          </span>
          <span @click="remove" class="text-danger font-sm fw-normal" style="cursor: pointer">Remove</span>
        </div>
      </div>      
      <div class="d-flex justify-content-center">
        <button class="custom-btn font-tiny fw-bold" :disabled="schoolLevelOption && noUpload" @click="save">Finish</button>
      </div>
    </div>
  </div>
</template>

<script>
import UploadIcon from "@/assets/images/file_upload.png";
import PDFIcon from "@/assets/images/pdf.png";
import { computed } from '@vue/runtime-core';
import { ref, onMounted } from 'vue';
import {useStore} from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { Card, Row, Col, Space, Image, notification} from 'ant-design-vue'
import { DownloadOutlined, EyeOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons-vue'

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3

export default {
  name: "Attestation",
  components:{
    ClipLoader,
    'a-card': Card,
    'a-row': Row,
    'a-col': Col,
    'a-space': Space,
    'a-image': Image,
    DownloadOutlined,
    EditOutlined,
    EllipsisOutlined,
    EyeOutlined,
  },
  setup() {
    const store = useStore()
    const currentStatus = ref(STATUS_INITIAL)
    const uploadFieldName = "file"
    const fileName = ref('')
    const fileSize = ref('')
    const uploadError = ref('')
    const noUpload = ref(true)
    const isInitial = computed(() => currentStatus.value === STATUS_INITIAL)
    const isSaving = computed(() => currentStatus.value === STATUS_SAVING)
    const isSuccess = computed(() => currentStatus.value === STATUS_SUCCESS)
    const failed = computed(() => currentStatus.value === STATUS_FAILED)
    const fileUploaded = ref(null)
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const responseCode = computed(() => store.state.questionnaireService.responseCode)
    const loading = computed(() => store.state.questionnaireService.loading ) 
    const updateMessage = computed(() =>store.state.questionnaireService.updateMessage)
    const attestationData = computed(() => store.state.questionnaireService.attestationData).value
    const schoolLevelOption = ref('')
    const filteredAttestation = ref('')

    onMounted(()=>{
      checkAttestation()
    })

    const checkAttestation = ()=> {
      filteredAttestation.value = []
      store.dispatch('fetchAttestation').then(() => {
        if (updateMessage.value.status != 'success') {
          notification.open({
            type: updateMessage.value.status,
            message: updateMessage.value.message,
          })
        }
        console.log(attestationData)
        console.log(schoolInfo)
        filteredAttestation.value = attestationData.filter(school => school.school === schoolInfo.id)
        // console.log(filteredAttestation)
      })
    }
    const fileChange = (fieldName, fileList) => {
      if (fileList.length === 1) {
        fileUploaded.value = fileList[0]
        fileName.value = fileUploaded.value.name
        fileSize.value = fileUploaded.value.size    
        currentStatus.value = STATUS_SAVING
        noUpload.value = false    
      }
    } 

    const remove = () => {
      currentStatus.value = STATUS_INITIAL
      fileUploaded.value = null
      fileName.value = null
      fileSize.value = null
      noUpload.value = true
    }

    const save = async () => {
      if (fileUploaded.value !== null && schoolLevelOption !== null) {
        currentStatus.value = STATUS_SAVING
        const undertaking = {
          schoolLevelOption: schoolLevelOption.value,
        }
        console.log(fileUploaded.value)
        let formData = new FormData()
        formData.append('undertaking', new Blob([JSON.stringify(undertaking)], {
          type: "application/json"
        }))
        formData.append('file', fileUploaded.value, fileUploaded.value.name)
        const requestObject = {
          id: schoolInfo.id,
          body: formData
        }
        await store.dispatch('submitAttestationDocuments', requestObject)
          .then(() => {
            notification.open({
              type: updateMessage.value.status,
              message: updateMessage.value.message,
            })
            if (responseCode.value === 201) {
              currentStatus.value = STATUS_SUCCESS
              remove()
            }
          }).catch(err => {
            uploadError.value = err.response
            currentStatus.value = STATUS_FAILED
            notification.open({
              type: updateMessage.value.status,
              message: updateMessage.value.message,
            })
          })
      } else {
        notification.open({
          type: 'error',
          message: 'All filed are required to submit Attestation'
        })
      }
      
    }

    return {
      icon: UploadIcon,
      pdfIcon: PDFIcon,
      fileUploaded,
      uploadError,
      uploadFieldName,
      fileName,
      fileSize,
      isInitial,
      isSaving,      
      isSuccess,
      failed,
      noUpload,
      schoolLevelOption,
      save,     
      remove, 
      fileChange,
      loading,
      filteredAttestation,
      checkAttestation,
    }
  },
}
</script>
<style scoped>
.gutter-example :deep(.ant-row > div) {
  background: transparent;
  border: 0;
}

.gutter-box {
  background: #00a0e9;
  padding: 5px 0;
}
</style>