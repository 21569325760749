<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"    
        :scroll="{ x: 1300 }"        
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal     
      :visible="showModal" 
      title="Enrolment By Stream Update" 
      @cancel="handleCancel">
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Update</a-button>
      </template>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="School Class"
            name="schoolClass"
          >
            <input type="text" class="form-control" :value="schoolClass" disabled>
          </a-form-item>          
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="School Type"
            name="schoolType"
          >
            <input type="text" class="form-control" :value="schoolType" disabled>
          </a-form-item>          
        </a-col>
      </a-row>
      
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="Classification"
            name="classification"
          >
            <input type="text" class="form-control" v-model="classification" disabled>
          </a-form-item>    
        </a-col>
        <a-col :span="11">        
          <a-form-item
            label="Local Gov. Area"
            name="lga"
          >
            <input type="text" class="form-control" v-model="lga" disabled>
          </a-form-item>        
        </a-col>
      </a-row>

      <a-row justify="space-between">
        <a-col :span="11">            
          <a-form-item
            label="Number of Stream"
            name="numberOfStream"
          >
            <input type="number" class="form-control" v-model="numberOfStream">
          </a-form-item>
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Multi-Grade Teaching"
            name="numberOfStreamMultiGradeTeaching"
          >
            <input type="number" class="form-control" v-model="numberOfStreamMultiGradeTeaching">
          </a-form-item>                
        </a-col>
      </a-row>  
    </a-modal>     
  </div>
</template>

<script>
import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { onMounted, ref } from 'vue'
import { mapActions, mapMutations, useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'EnrolmentByStreamTable',
  emits: ['viewEnrolmentInfo'],
  props: ['title', 'schoolId'],
  mixins: [enrolmentMixinVue], 
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
  },
  setup() {
    const store = useStore()
    const showModal = ref(false)
    const selectedObject = ref(null)
    const schoolClass = ref()
    const numberOfStream = ref()
    const numberOfStreamMultiGradeTeaching = ref()
    const lga = ref()
    const classification = ref()
    const schoolType = ref()

    const columns = [
      { title: 'School Class', dataIndex: 'schoolClass', fixed: 'left', width: 120 }, 
      { title: 'Number Of Stream', dataIndex: 'numberOfStream', className: 'content-alignment' },
      { title: 'No. of Stream With Multi-grade Teaching', dataIndex: 'numberOfStreamMultiGradeTeaching', className: 'content-alignment' },
      { title: 'LGA', dataIndex: 'lga', className: 'content-alignment' },    
      { title: 'Classification', dataIndex: 'classification', className: 'content-alignment' },    
      { title: 'School Type', dataIndex: 'schoolType', className: 'content-alignment' },
      {
        title: 'operation',
        dataIndex: 'operation', 
        fixed: 'right',  
        width: 100   
      },
    ]

    return {    
      columns,        
      showModal,
      selectedObject,
      schoolClass,
      numberOfStream,
      numberOfStreamMultiGradeTeaching,
      lga,
      classification,
      schoolType,
    }
  },
  computed: {
    streamEnrolmentData() {
      return this.$store.state.enrolment.streamEnrolmentData
    },
    dataSource() {
      const dataItems = []      
      if (this.streamEnrolmentData != null) {
        const data = Object.values(this.streamEnrolmentData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolClass: element["schoolClass"],
                numberOfStream: element["numberOfStream"],
                numberOfStreamMultiGradeTeaching: element["numberOfStreamMultiGradeTeaching"],
                lga: element["lga"],
                classification: element["classification"],
                schoolType: element["schoolType"]
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {

    ...mapActions(['updateRequest', 'fetchStreamEnrolmentData']),
    ...mapMutations(['setStreamEnrolmentData']),

    editRecord(key) {
      const detail = this.streamEnrolmentData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolClass = detail.schoolClass
        this.numberOfStream = detail.numberOfStream
        this.numberOfStreamMultiGradeTeaching = detail.numberOfStreamMultiGradeTeaching
        this.lga = detail.lga
        this.classification = detail.classification
        this.schoolType = detail.schoolType
      }
    },

    handleSubmit() {
      const payload = {        
        endpoint: `questionaire/enrollment/section-c1/${this.selectedObject.id}`,
        body: {
          numberOfStream: Number(this.numberOfStream),
          numberOfStreamMultiGradeTeaching: Number(this.numberOfStreamMultiGradeTeaching),          
        }
      }
      this.updateRequest(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.belowFifteenYears = ''
            this.fifteenYears = ''
            this.sixteenYears = '' 
            this.seventeenYears = ''
            this.aboveSeventeenYears = ''
            
            const type = this.updateMessage.status

            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });            

            if (type === 'success') {              
              this.setStreamEnrolmentData(null)
              this.fetchStreamEnrolmentData()
            }                      
          }
        })  
    },

    handleCancel() {
      this.showModal = false
    },      

  },
  mounted(){
    if(!this.schoolId) {
      this.$store.commit('setStreamEnrolmentData', null)
      this.$store.dispatch('fetchStreamEnrolmentData')
    } else {
      this.$store.commit('setStreamEnrolmentData', null)
      this.$store.dispatch('fetchStreamEnrolmentData', this.schoolId)
    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
