<template>
  <div class="d-flex flex-row-reverse bg-accent">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <my-school-dashboard-sidebar
      @showTable="showSchoolStaffTable"
      @showMySchool="showMySchool"
      @showClassTable="showClassroomTable"
    />
    <div class="col-md-9 px-5 main-content align-self-end pt-4">
      <dashboard-header
        :hasBack="hasBack"     
      />
      <main>
        <school-information v-if="showInfo && !isSupervisor" :school="selectedSchool" :key="schoolInfoKey" @reload="schoolInfoKey++" />
        <assigned-schools-table
          v-else-if="showInfo && isSupervisor"
          @viewSubmission="viewSubmissionForSchoolWithId" />

        <staff-table
          v-if="showStaffTable"
          :school-id="schoolId"
          @showStaffProfile="showStaffInfo" />

        <submission-review 
          v-if="showSubmissionReview" 
          :institutionId="institutionId" />

        <staff-profile v-if="showStaffProfile && !isSupervisor" :staff-info="staffInfo" />

        <classroom-table v-if="showClassrooms && !isSupervisor" />        
      </main>
    </div>
  </div>
</template>

<script>
import DashboardHeader from "@/components/dashboardPage/DashboardHeader";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import {computed, onMounted} from 'vue'
import {useStore} from 'vuex'
import MySchoolDashboardSidebar from "@/components/dashboardPage/MySchoolDashboardSidebar";
import SchoolInformation from "@/components/dashboardPage/school/schoolCreation/SchoolInformation";
import StaffTable from "@/components/dashboardPage/user/StaffTable";
import StaffProfile from "@/components/dashboardPage/school/ASCDashboard/StaffProfile";
import AssignedSchoolsTable from "@/components/dashboardPage/school/ASCDashboard/AssignedSchoolsTable";
import SubmissionReview from "@/components/dashboardPage/school/ASCDashboard/supervisorComponents/SubmissionReview";
import ClassroomTable from "@/components/dashboardPage/ClassroomTable";
import Roles from "@/authorities/roles";

export default {
  name: "MySchoolDashboard",
  components: {
    ClassroomTable,
    StaffProfile,
    StaffTable,
    SchoolInformation,
    MySchoolDashboardSidebar,
    DashboardHeader,
    ClipLoader,
    AssignedSchoolsTable,
    SubmissionReview,
  },
  setup() {
    const store = useStore()
    const loading = computed(() => store.state.school.loading)
    const message = computed(() => store.state.school.schoolMessage)
    const userMe = computed(() => store.getters.loginUser).value;
    const isSupervisor = userMe.role === Roles.supervisor

    onMounted(() => {
      if (!isSupervisor) {
        store.dispatch('fetchMySchool')
      }      
    })

    return {      
      hasBack: true,
      loading,
      message,
      isSupervisor,
    }
  },
  data() {
    return {
      dashboardKey: 0,
      showInfo: true,
      showStaffTable: false,
      showStaffProfile: false,
      showClassrooms: false,
      showSubmissionReview: false,
      staffInfo: null,
      schoolInfoKey: 0,
      institutionId: null,
    }
  },
  computed: {
    selectedSchool() {
      return this.$store.state.school.ownSchool
    },

    schoolId() {
      if (this.selectedSchool) {
        return this.selectedSchool.id
      }
      return undefined
    },

    responseCode() {
      return this.$store.state.school.resCode
    }
  },
  methods: {    
    showSchoolStaffTable() {
      this.showStaffTable = true
      this.showInfo = false
      this.showStaffProfile = false      
      this.showClassrooms = false
      this.showSubmissionReview = false
    },

    showClassroomTable() {
      this.showClassrooms = true
      this.showInfo = false
      this.showStaffTable = false      
      this.showStaffProfile = false
      this.showSubmissionReview = false
    },

    showMySchool() {
      this.showInfo = true
      this.showStaffTable = false
      this.showClassrooms = false
      this.showStaffProfile = false
      this.showSubmissionReview = false
    },

    showStaffInfo(info) {
      this.staffInfo = info
      this.showInfo = false
      this.showStaffTable = false
      this.showClassrooms = false
      this.showSubmissionReview = false
      this.showStaffProfile = true      
    },

    viewSubmissionForSchoolWithId(key) {
      this.institutionId = key
      this.showInfo = false
      this.showStaffTable = false
      this.showClassrooms = false
      this.showStaffProfile = false  
      this.showSubmissionReview = true
    },

    showProfile() {},
    
    editSchoolProfile() {},
  }
}
</script>