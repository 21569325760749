<template>
  <div class="d-flex flex-column">    
    <div v-if="false" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <p class="font-md fw-bold mt-3 mb-2">State Validation</p>
    <div class="bg-white radius-full shadow-sm mb-5">
      <table class="table table-hover font-sm" aria-label="Users table">
        <thead>
          <tr>
            <th class="align-middle py-3 ps-4" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>LGA</span>            
              </div>
            </th>
            <th class="align-middle py-3" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Last submission date</span>            
              </div>
            </th>
            <th class="align-middle py-3" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Number of schools</span>            
              </div>
            </th>
            <th class="align-middle py-3" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="pointer-no-bg"
            v-for="(lga, index) in stateLGAs"
            :key="index"
            @click="showLGA(lga)"
          >
            <td class="align-middle py-3 ps-4">
              {{ lga.name }}
            </td>
            <td class="py-3">{{ lga.date }}</td>
            <td class="py-3">{{ lga.count }}</td>
            <td class="text-end dropend pe-2 py-3">
              <span @click.stop class="p-3">
                <i
                  class="bi bi-three-dots-vertical grey-accent px-3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ></i>
                <ul
                  class="
                    dropdown-menu
                    action-menu
                    bg-accent
                    shadow
                    font-tiny
                    radius-half
                  "
                  aria-labelledby="options"
                >                  
                  <li>
                    <span class="dropdown-item grey-accent" >
                      <i class="fw-bold font-sm bi bi-x-lg me-2"></i>
                      Delete
                    </span>
                  </li>
                </ul>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "StateValidation",
  components: { ClipLoader },
  setup(props, {emit}) {
    const stateLGAs = [
      {
        id: 1,
        name: "Maru",
        date: "24-09-2021",
        count: "50"
      },
      {
        id: 2,
        name: "Damaturu",
        date: "24-09-2021",
        count: "10"
      },
      {
        id: 3,
        name: "Bakura",
        date: "24-09-2021",
        count: "12"
      },
      {
        id: 4,
        name: "Bungudu",
        date: "24-09-2021",
        count: "50"
      },
    ]
    const showLGA = (lga) => {      
      const {id, name} = lga
      emit('showLGA', [id, name])      
    }
    return {
      stateLGAs,
      showLGA
    }
  },
}
</script>