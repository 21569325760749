<template>
  <div class="d-flex flex-column bg-white radius-full pt-3 px-5 my-4">
    <section>
      <vocational-student-book />
      <vocational-teacher-book />
    </section>
  </div>
</template>

<script>
import VocationalStudentBook
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/vocational/VocationalStudentBook";
import VocationalTeacherBook
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/vocational/VocationalTeacherBook";
export default {
  name: "VocationalAndScience",
  components: {VocationalTeacherBook, VocationalStudentBook},
  methods: {
    saveSubject() {
      this.$emit('saveBook')
    }
  }
}
</script>

<style scoped>

</style>