<template>
  <div>
    <p class="fw-bold font-tiny mt-4 pt-2">Selective characteristics of Public Science & Technical Secondary school facilities</p>
    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-striped table-borderless font-sm" aria-label="Users table">
        <thead>
          <tr>
            <th class="align-middle py-3 ps-4"></th>
            <th class="align-middle text-center py-3" colspan="3">
              Percentage of Usable Classrooms
            </th>
            <th class="align-middle text-center py-3" colspan="2">
              Percentage of Schools
            </th>
          </tr>
          <tr>
            <th class="align-middle py-3 ps-4 bigger-cell first col-md-2">
              LGA
            </th>
            <th class="align-middle py-3 big-cell col-md-2">
              In need of Major Repairs
            </th>
            <th class="align-middle py-3 big-cell col-md-1" scope="col">
              With insufficient seating
            </th>
            <th class="align-middle py-3 big-cell col-md-1" scope="col">
              Without a good blackboard
            </th>
            <th class="align-middle py-3 big-cell col-md-1" scope="col">
              Where some classes are held outside
            </th>
            <th class="align-middle py-3 big-cell col-md-1" scope="col">
              Without a health facility
            </th>
          </tr>
        </thead>
        <tbody v-if="tableData.length > 0">
          <tr class="pointer-no-bg" 
            v-for="(data, index) in tableData"
            :key="index"           
          >
            <td class="py-3 ps-4 bigger-cell first">{{ data.lga }}</td>
            <td class="py-3 big-cell">{{ data.percentage_of_usable_classrooms_in_need_of_major_repairs }}</td>
            <td class="py-3 big-cell">{{ data.percentage_of_usable_classrooms_with_insufficient_seating }}</td>
            <td class="py-3 big-cell">{{ data.percentage_of_usable_classrooms_without_a_good_blackboard }}</td>
            <td class="py-3 big-cell">{{ data.percentage_of_schools_where_some_classes_are_held_outside }}</td>
            <td class="py-3 big-cell">{{ data.percentage_of_schools_without_a_health_facility }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="text-center fw-medium">
              No record found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, computed, watch} from "vue";
import { useStore } from 'vuex';

export default {
  name: "SelectiveCharacteristicsSciTechSchool",
  setup() {
    const store = useStore()
    const session = computed(() => store.state.datatables.datatableSession)

    const fetchAnalysis = () => {
      if (session.value !== null) {
        store.commit("setCurrentStage", null)
        store.dispatch('fetchAnalysis', {index: 72, session: session.value})      
      } else {
        store.commit("setASCPlanningMessage", "Please select a session.")
      }  
    } 

    onMounted(() => {  
      fetchAnalysis()
    })  

    watch(session, () => {
      fetchAnalysis()
    })

    return {}
  },
  computed: {
    tableData() {
      const data = this.$store.state.datatables.tableData
      if (data === null) {
        return []
      } else {
        return data
      }
    },
    state() { 
      return this.tableData[0].state
    },
    year() { 
      return this.tableData[0].year
    },
    session() { 
      return this.tableData[0].session
    },
  }
}
</script>

<style scoped>
.bigger-cell {
  width: 25%;
}
.big-cell {
  width: 15%;
}
</style>