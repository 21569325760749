import { AbilityBuilder, Ability } from "@casl/ability";
import Roles from "./roles";

const roleWithAllAbility = [Roles.super_admin, Roles.moe_admin, Roles.subeb_admin]
const schoolHeadsAbility = [Roles.principal, Roles.head_teacher, Roles.enumerator]
const supervisoryAbility = [Roles.supervisor, Roles.sbmc]
const officersAbility = [Roles.smoe_emis_lga_officer, Roles.smoe_subeb_lga_officer,
Roles.smoe_emis_state_officer, Roles.smoe_subeb_state_officer]
const nonRegularSchoolAbility = [Roles.smora_admin, Roles.smora_prs]

export default function defineAbilityFor(user) {
  const { can, cannot, build } = new AbilityBuilder(Ability);

  if (user !== undefined) {

    if (roleWithAllAbility.includes(user.role)) {
      // console.log("We're the super ppl");
      can('manage', 'all')
      cannot('create', 'ASC')
    }

    if (schoolHeadsAbility.includes(user.role)) {
      // console.log("We're just school ppl");
      can('manage', ['ASC', 'Report'])
      can('read', 'User', { userId: user.id })
      can('update', 'User', { userId: user.id })

      cannot('delete', 'all')
      cannot('manage', 'AuditTrail')

      cannot('read', 'planning')
      cannot('read', 'state validation')
      cannot('read', 'state validation')
      cannot('read', 'report generation')
    }

    if (supervisoryAbility.includes(user.role)) {
      // console.log("We're supervisors");
      can('manage', ['ASC', 'Report'])
      cannot('manage', 'AuditTrail')
      can('read', 'User', { userId: user.id })
    }

    if (user.role === Roles.adsmeb_admin) {
      // console.log("We're adsmeb");
      can('read', 'Report')
    }

    if (officersAbility.includes(user.role)) {
      // console.log("We're just officers");
      can('manage', ['ASC', 'School', 'Report'])
      can('read', 'User', { userId: user.id })
    }

    if (nonRegularSchoolAbility.includes(user.role)) {
      // console.log("We're the SMORA ppl.");
      cannot('manage', ['ASC', 'User'])
      cannot('create', 'School')
      can('read', 'School')
    }

  }
  return build()
}
