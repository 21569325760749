<template>
  <div class="dashboard-sb col-md-3 position-fixed top-0 start-0 pl-4">
    <div class="d-flex flex-column mt-lg-4 ms-lg-5 ps-lg-4">
      <div class="d-flex flex-nowrap align-items-center text-decoration-none">
        <router-link to="/">
          <img :src="logoSrc" width="40" height="40" alt="" />
        </router-link>
        <span class="font-sm fw-bold ps-2">{{ state }} STATE EMIS</span>
      </div>
      <div class="d-flex flex-column mt-4">
        <div
          class="d-flex flex-nowrap align-items-center my-2 pointer-no-bg"
          :class="[manageUser ? 'sb-active fw-bold' : 'grey-accent']"
          @click="$emit('showUsersManagement')"
        >
          <span class="material-icons font-normal"> people </span>
          <span class="font-sm ps-2">Manage Users</span>
        </div>
        <div
          class="d-flex flex-nowrap align-items-center my-2 pointer-no-bg"
          :class="[profile ? 'sb-active fw-bold' : 'grey-accent']"
          @click="$emit('showProfile', userId)"
        >
          <span class="material-icons font-normal"> person </span>
          <span class="font-sm ps-2">User Profile</span>
        </div>
        <div
          class="d-flex flex-nowrap align-items-center my-2 pointer-no-bg"
          :class="[account ? 'sb-active fw-bold' : 'grey-accent']"
          @click="$emit('showAccSetting')"
        >
          <span class="material-icons font-normal"> manage_accounts </span>
          <span class="font-sm ps-2">Account Settings</span>
        </div>
        <!-- <div
          class="d-flex flex-nowrap align-items-center my-2 pointer-no-bg"
          :class="[dashboard ? 'sb-active fw-bold' : 'grey-accent']"
          @click="$emit('showDbSetting')"
        >
          <span class="material-icons font-normal"> dashboard_customize </span>
          <span class="font-sm ps-2">Dashboard Settings</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import stateLogo from "@/assets/images/adamawa_logo.png";
import {computed} from "@vue/runtime-core"
import { useStore } from 'vuex';


export default {
  name: "Sidebar",
  props: ["profile", "account", "dashboard", "manageUser"],
  setup() {
    const logoSrc = stateLogo;
    const state = "Yobe".toUpperCase();
    const store = useStore()
    const userId = computed(() => store.state.auth.user).value.id
    return {
      logoSrc,
      state,
      isActive: false,
      userId,
    };
  },
};
</script>

