import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@/style/global.scss";
import "ant-design-vue/dist/antd.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { notification, message } from "ant-design-vue";
// import cors from 'cors'

// import "dotenv/config";

import { emisconfig } from "../app.config";

// console.log(emisconfig.baseURL);
axios.defaults.baseURL = `${emisconfig.baseURL}/`;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

axios.interceptors.response.use(function (config) {
  setTimeout(() => console.log("Request taking too long"), 10000);
  return config;
});

axios.interceptors.request.use(function (config) {
  setTimeout(() => console.log("Request taking too long"), 10000);
  const token = store.state.auth.token;
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

const app = createApp(App);

app.use(notification);

app.use(router);
app.use(store);
// app.use(cors)
app.mount("#app");
app.config.globalProperties.$notification = notification;
// app.use(vueMoment)
