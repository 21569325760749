<template>
  <div class="mt-4">
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"            
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal     
      :visible="showModal" 
      title="Student Flow Enrolment Update" 
      @cancel="handleCancel">
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancel</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleSubmit">Update</a-button>
      </template>
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="School Class"
            name="schoolClass"
          >
            <input type="text" class="form-control" :value="schoolClass" disabled>
          </a-form-item>          
        </a-col>
        <a-col :span="11">
          <a-form-item
            label="Gender"
            name="gender"
          >
            <input type="text" class="form-control" :value="gender" disabled>
          </a-form-item>          
        </a-col>
      </a-row>
      
      <a-row justify="space-between">
        <a-col :span="11">
          <a-form-item
            label="Promoted"
            name="promoted"
          >
            <input type="number" class="form-control" v-model="promoted">
          </a-form-item>    
        </a-col>
        <a-col :span="11">            
          <a-form-item
            label="Dropouts"
            name="dropout"
          >
            <input type="number" class="form-control" v-model="dropout">
          </a-form-item>
        </a-col>
      </a-row>

      <a-row justify="space-between">
        <a-col :span="11">        
          <a-form-item
            label="Transfer Out"
            name="transferOut"
          >
            <input type="number" class="form-control" v-model="transferOut">
          </a-form-item>        
        </a-col>        
        <a-col :span="11">
          <a-form-item
            label="Transfer In"
            name="transferIn"
          >
            <input type="number" class="form-control" v-model="transferIn">
          </a-form-item>                
        </a-col>
      </a-row>  
    </a-modal>   

  </div>
</template>

<script>
import { Table, Button, Space, Modal, FormItem, Input, Row, Col, notification  } from "ant-design-vue"
import { onMounted, ref } from 'vue'
import { mapActions, mapMutations, useStore } from 'vuex'
import enrolmentMixinVue from '@/helpers/mixins/enrolmentMixin.vue'

export default {
  name: 'StudentFlowByClassTable',  
  props: ['title', 'schoolId'],
  mixins: [enrolmentMixinVue], 
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,    
    'a-modal': Modal,
    'a-form-item': FormItem,
    'a-input': Input,
    'a-row': Row,
    'a-col': Col,
  },
  setup() {
    const store = useStore()
    const showModal = ref(false)
    const selectedObject = ref(null)
    const schoolClass = ref()
    const gender = ref()  
    const dropout = ref()
    const transferIn = ref()
    const transferOut = ref()
    const promoted = ref()
  

    const columns = [
      { title: 'School Class', dataIndex: 'schoolClass' }, 
      { title: 'Gender', dataIndex: 'gender' },
      { title: 'Dropouts', dataIndex: 'dropout', className: 'content-alignment' },
      { title: 'Transfer In', dataIndex: 'transferIn', className: 'content-alignment' },
      { title: 'Transfer Out', dataIndex: 'transferOut', className: 'content-alignment' },    
      { title: 'Promoted', dataIndex: 'promoted', className: 'content-alignment' },          
      {
        title: 'operation',
        dataIndex: 'operation',       
        width: 100   
      },
    ]

    return {    
      columns,      
      showModal,
      selectedObject,
      schoolClass,
      dropout,
      transferIn,
      transferOut,
      promoted,
      gender,      
    }
  },
  computed: {
    studentFlowData() {
      return this.$store.state.enrolment.studentFlowData
    },
    dataSource() {
      const dataItems = []      
      if (this.studentFlowData != null) {
        const data = Object.values(this.studentFlowData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolClass: element["schoolClass"],
                gender: element["gender"],
                dropout: element["dropOut"],
                transferIn: element["transferIn"],
                transferOut: element["transferOut"],
                promoted: element["promoted"]
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {

    ...mapActions(['updateRequest', 'fetchStudentFlowData']),
    ...mapMutations(['setStudentFlowData']),

    editRecord(key) {
      const detail = this.studentFlowData.find(record => record["id"] === key)
      if (detail !== null) {
        this.showModal = true     
        this.selectedObject = detail 
        this.schoolClass = detail.schoolClass
        this.gender = detail.gender
        this.dropout = detail.dropOut
        this.transferIn = detail.transferIn
        this.transferOut = detail.transferOut
        this.promoted = detail.promoted        
      }
    },

    handleSubmit() {
      const payload = {        
        endpoint: `questionaire/enrollment/section-g/${this.selectedObject.id}`,
        body: {
          dropOut: Number(this.dropout),
          transferIn: Number(this.transferIn),  
          transferOut: Number(this.transferOut),
          promoted: Number(this.promoted),          
        }
      }
      this.updateRequest(payload)
        .then(() => {
          if (this.loading === false) {
            this.showModal = false
            this.selectedObject = null 
            this.gender = ''
            this.dropout = ''
            this.transferIn = ''
            this.transferOut = '' 
            this.promoted = ''
            this.schoolClass = ''
            
            const type = this.updateMessage.status

            notification.open({
              type: type,
              message: 'Update Message',
              description: this.message
            });            

            if (type === 'success') {              
              this.setStudentFlowData(null)
              this.fetchStudentFlowData()
            }                      
          }
        })  
    },

    handleCancel() {
      this.showModal = false
    },  

  },
  mounted(){
    if(!this.schoolId){
      this.$store.commit('setStudentFlowData', null)
      this.$store.dispatch('fetchStudentFlowData')
    } else {
      this.$store.commit('setStudentFlowData', null)
      this.$store.dispatch('fetchStudentFlowData', this.schoolId)
    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
