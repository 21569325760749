<template>
  <div>
    <h6>{{ title }}</h6>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="dataSource"    
        :scroll="{ x: 1300 }"        
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >
            <a-space> 
              <a-button @click="editRecord(record.key)">
                Edit
              </a-button>        
            </a-space>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Table, Button, Space, Modal } from "ant-design-vue"
import { onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'EnrolmentNABTEBTable',
  emits: ['viewEnrolmentInfo'],
  props: ['title', 'schoolId'],
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,
    'a-modal': Modal,
  },
  setup() {
    const store = useStore()
    const columns = [
      { title: 'School Class', dataIndex: 'schoolClass', fixed: 'left', width: 120 }, 
      { title: 'Gender', dataIndex: 'gender', fixed: 'left', width: 100 },
      { title: 'Examination', dataIndex: 'examination', className: 'content-alignment' },
      { title: 'Registered Students', dataIndex: 'registeredStudents', className: 'content-alignment' },
      { title: 'No. of Participant', dataIndex: 'numberOfStudentTookPart', className: 'content-alignment' },    
      { title: 'No. of Pass', dataIndex: 'numberOfStudentPassed', className: 'content-alignment' },    
      { title: 'LGA', dataIndex: 'lga', className: 'content-alignment' },
      {
        title: 'operation',
        dataIndex: 'operation', 
        fixed: 'right',  
        width: 100   
      },
    ]

    return {    
      columns,      
    }
  },
  computed: {
    nabtebEnrolmentData() {
      return this.$store.state.enrolment.nabtebEnrolmentData
    },
    dataSource() {
      const dataItems = []      
      if (this.nabtebEnrolmentData != null) {
        const data = Object.values(this.nabtebEnrolmentData)    
        if (data.length > 0) {
          data.forEach(element => {
            dataItems.push(
              {            
                key: element["id"],
                schoolClass: element["schoolClass"],
                gender: element["gender"],
                examination: element["examination"],
                registeredStudents: element["registeredStudents"],
                numberOfStudentTookPart: element["numberOfStudentTookPart"],
                numberOfStudentPassed: element["numberOfStudentPassed"],
                lga: element["lga"],              
              }
            )
          });      
        }
      }    
      return dataItems
    }
  },
  methods: {
    editRecord(key) {
      const detail = this.nabtebEnrolmentData.find(record => record["id"] === key)
      console.log(detail);
    }
  },
  mounted(){
    if(!this.schoolId){
      this.$store.dispatch('fetchNABTEBEnrolmentData')
    } else {
      this.$store.dispatch('fetchNABTEBEnrolmentData', this.schoolId)
    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>
<style>
th.content-alignment,
td.content-alignment {
  text-align: center !important;
}
</style>
