<template>
  <div class="dashboard-sb col-md-2 position-fixed top-0 start-0 pl-4">
    <div class="d-flex flex-nowrap text-decoration-none mt-4 mx-3">
      <router-link to="/">
        <img :src="logoSrc" width="32" height="32" alt="" />
      </router-link>
      <span class="font-tiny fw-bold ps-2">{{ state }} STATE EMIS</span>
    </div>
    <div class="d-flex flex-column mt-lg-4 ps-lg-3">
      <div class="d-flex flex-column mt-2">
        <div
          @click="$emit('showAccess')"
          class="d-flex flex-nowrap mt-1 pointer-no-bg"
          :class="{ 'sb-active': activeScreen === 'access' }"
        >
          <span class="material-icons tiny-padding font-normal">
            account_box
          </span>
          <span
            class="font-sm fw-medium ps-2"
            :class="{ 'fw-bold': activeScreen === 'access' }"
            >Access</span
          >
        </div>
        <div
          @click="$emit('showQuality')"
          class="d-flex flex-nowrap mt-1 pointer-no-bg"
          :class="{ 'sb-active': activeScreen === 'quality' }"
        >
          <span class="material-icons tiny-padding font-normal"> group </span>
          <span
            class="font-sm fw-medium ps-2 grey-accent"
            :class="{ 'fw-bold': activeScreen === 'quality' }"
            >Quality</span
          >
        </div>
        <!-- <div
          @click="$emit('showManagement')"
          class="d-flex flex-nowrap mt-1 pointer-no-bg" :class="{'sb-active': activeScreen === 'management'}">
          <span class="material-icons tiny-padding font-normal">
            manage_accounts
          </span>
          <span class="font-sm fw-medium ps-2 grey-accent" :class="{'fw-bold': activeScreen === 'management'}">Management</span>
        </div> -->
        <!-- <div
          @click="$emit('showComparativeAnalysis')"
          class="d-flex flex-nowrap mt-1 pointer-no-bg" :class="{'sb-active': activeScreen === 'comparativeAnalysis'}">
          <span class="material-icons tiny-padding font-normal">
            insert_chart_outlined
          </span>
          <span class="font-sm fw-medium ps-2 grey-accent" :class="{'fw-bold': activeScreen === 'comparativeAnalysis'}">Comparative Analysis</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import stateLogo from "@/assets/images/adamawa_logo.png";
import { emisconfig } from "../../../app.config";
export default {
  name: "DashboardSidebar",
  props: ["activeScreen"],
  emits: [
    "showAccess",
    "showQuality",
    "showManagement",
    "showComparativeAnalysis",
  ],
  setup() {
    const logoSrc = stateLogo;
    const state = emisconfig.stateName.toUpperCase();

    return {
      logoSrc,
      state,
    };
  },
};
</script>

<style scoped></style>
