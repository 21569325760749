<template>
  <div>
    <section class="mb-5 mx-4">
      <p class="font-sm fw-bold">Care giver manuals provided by the school in the current academic year.</p>
      <div class="d-flex align-items-center mb-2">
        <div class="col-5 pe-3">
          <span class="font-sm fw-normal">Caregiver Guide/Handbook</span>
        </div>
        <div class="col pe-2">
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                name="caregiver-guide"
                v-model="caregiverGuide"
                value="true"
                id="has-caregiver-handbook"
            />
            <label class="form-check-label font-sm" for="has-caregiver-handbook">
              Yes
            </label>
          </div>
        </div>
        <div class="col ps-2">
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                name="caregiver-guide"
                v-model="caregiverGuide"
                value="false"
                id="no-caregiver-handbook"
            />
            <label class="form-check-label font-sm" for="no-caregiver-handbook">
              No
            </label>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center mb-2">
        <div class="col-5 pe-3">
          <span class="font-sm fw-normal">National Policy on ECCDE / Pre-primary school</span>
        </div>
        <div class="col pe-2">
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                name="npe"
                v-model="nationalPolicyOnECCD"
                value="true"
                id="has-policy"
            />
            <label class="form-check-label font-sm" for="has-policy">
              Yes
            </label>
          </div>
        </div>
        <div class="col ps-2">
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                name="npe"
                v-model="nationalPolicyOnECCD"
                value="false"
                id="no-policy"
            />
            <label class="form-check-label font-sm" for="no-policy">
              No
            </label>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center mb-2">
        <div class="col-5 pe-3">
          <span class="font-sm fw-normal">National Curriculum</span>
        </div>
        <div class="col pe-2">
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                name="nationalCurriculum"
                v-model="nationalCurriculum"
                value="true"
                id="has-national-curriculum"
            />
            <label class="form-check-label font-sm" for="has-national-curriculum">
              Yes
            </label>
          </div>
        </div>
        <div class="col ps-2">
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                name="nationalCurriculum"
                v-model="nationalCurriculum"
                value="false"
                id="no-national-curriculum"
            />
            <label class="form-check-label font-sm" for="no-national-curriculum">
              No
            </label>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center mb-2">
        <div class="col-5 pe-3">
          <span class="font-sm fw-normal">Toy Making Manuals</span>
        </div>
        <div class="col pe-2">
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                name="toyManual"
                v-model="toyMakingManual"
                value="true"
                id="yesIsMember"
            />
            <label class="form-check-label font-sm" for="yesIsMember">
              Yes
            </label>
          </div>
        </div>
        <div class="col ps-2">
          <div class="form-check">
            <input
                class="form-check-input"
                type="radio"
                name="toyManual"
                v-model="toyMakingManual"
                value="false"
                id="noIsMember"
            />
            <label class="form-check-label font-sm" for="noIsMember">
              No
            </label>
          </div>
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, useStore} from "vuex";
import {computed} from "@vue/runtime-core";

export default {
  name: "CareGiverManualSection",
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    return {
      caregiverGuide: '',
      nationalPolicyOnECCD: '',
      nationalCurriculum: '',
      toyMakingManual: '',
      schoolInfo
    }
  },
  computed: {
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    }
  },
  methods: {
    ...mapActions(['submitCaregiverResource']),

    submitForm() {
      const body = {
        'toyMakingManuals': this.toyMakingManual,
        'nationalCuriculum': this.nationalCurriculum,
        'nationalPolicyOnEccdePrePrimary': this.nationalPolicyOnECCD,
        'careGiverGuide': this.caregiverGuide
      }
      this.submitCaregiverResource({id: this.schoolInfo.id, body: body})
          .then(() => {
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>