<template>
  <div>
    <div class="bg-white radius-full py-4 px-5 my-4">
      <p class="font-sm fw-bold mx-3 mb-3">Facilities registration (by school level) in current academic class.</p>
      <div class="mt-3 mb-4 mx-3">
        <label class="form-label font-sm fw-normal">
          Please select school level
        </label>
        <select class="form-select" required aria-label="Default select example" @change="levelChanged($event)" v-model="facilities.schoolLevel">
          <option value="">Select level</option>
          <option value="PRE_PRIMARY">Pre-Primary</option>
          <option value="PRIMARY">Primary</option>
          <option value="JUNIOR">Junior</option>
          <option value="SENIOR">Senior</option>
        </select>
      </div>
    </div>
    <section class="main bg-white radius-full py-4 my-4">
      <div class="d-flex flex-column px-5 py-4">
        <section>
          <div class="col-12 my-3 px-3">
            <label class="form-label font-sm fw-normal"> Source of safe drinking water </label>
            <div class="col-12">
              <input
                  type="text"
                  class="form-control"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  :value="source"
              />
              <div
                  class="dropdown-menu bg-accent shadow-sm w-100"
                  aria-labelledby="featuresDropdown"
              >
                <div class="d-flex flex-column w-100">
                  <div class="pe-2">
                    <div class="form-check">
                      <input
                          class="form-check-input"
                          type="radio"
                          name="sourceOfWater"
                          v-model="waterSource"
                          value="PIPE_BORNE_WATER"
                          id="pipe-borne"
                      />
                      <label class="form-check-label" for="pipe-borne">
                        Pipe borne water
                      </label>
                    </div>
                  </div>
                  <div class="pe-2">
                    <div class="form-check">
                      <input
                          class="form-check-input"
                          type="radio"
                          name="sourceOfWater"
                          v-model="waterSource"
                          value="BOREHOLE"
                          id="borehole"
                      />
                      <label class="form-check-label" for="borehole">
                        Borehole
                      </label>
                    </div>
                  </div>
                  <div class="pe-2">
                    <div class="form-check">
                      <input
                          class="form-check-input"
                          type="radio"
                          name="sourceOfWater"
                          v-model="waterSource"
                          value="WELL"
                          id="well"
                      />
                      <label class="form-check-label" for="well">
                        Well
                      </label>
                    </div>
                  </div>
                  <div class="pe-2">
                    <div class="form-check">
                      <input
                          class="form-check-input"
                          type="radio"
                          name="sourceOfWater"
                          v-model="waterSource"
                          value="OTHER"
                          id="other"
                      />
                      <label class="form-check-label" for="other">
                        Other
                      </label>
                    </div>
                  </div>
                  <div class="pe-2">
                    <div class="form-check">
                      <input
                          class="form-check-input"
                          type="radio"
                          name="sourceOfWater"
                          v-model="waterSource"
                          value="NO_SOURCE_OF_WATER"
                          id="no-safe-water"
                      />
                      <label class="form-check-label" for="no-safe-water">
                        No safe source of drinking water
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="row justify-content-between mt-2 px-2 pb-4">
          <p class="ms-3 font-sm fw-medium">Enter the number of usable and non-usable facilities.</p>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal mb-3"></div>
            <div class="col-3 ps-3 mb-2 text-center">
              <span class="font-sm fw-bold">Usable</span>
            </div>
            <div class="col-3 ps-3 mb-2 text-center">
              <span class="font-sm fw-bold">Unusable</span>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal mb-2">
              Toilets
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.usableToiletNumber"
              />
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.nonUsableToiletNumber"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal mb-2">
              Computers
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.usableComputer"
              />
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.nonUsableComputer"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal mb-2">
              Laboratory
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.usableLab"
              />
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.nonUsableLab"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal mb-2">
              Classrooms
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.usableClassRoom"
              />
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.nonUsableCalssRoom"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal mb-2">
              Library
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.usableLibraries"
              />
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.nonUsabaleLibraries"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal mb-2">
              Playground
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.usablePlayground"
              />
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.nonUsabalePlayground"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal mb-2">
              Wash Hand Facilities
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.usbaleWashHandFacilities"
              />
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.nonUsbaleWashHandFacilities"
              />
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal mb-2">
              Other
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.usableOther"
              />
            </div>
            <div class="col-3 ps-3 mb-2">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter number"
                  v-model="facilities.nonUsableOther"
              />
            </div>
          </div>
        </section>

        <section class="row flex-wrap justify-content-between px-2 pb-4">
          <p class="ms-3 font-sm fw-medium"> What facilities does this school share with other schools? </p>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal">
              Toilets
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.toiletShared" name="toilet" value="true" id="sharesToilet">
                <label class="form-check-label font-sm" for="sharesToilet">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.toiletShared" name="toilet" value="false" id="notShareToilet">
                <label class="form-check-label font-sm" for="notShareToilet">
                  No
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal">
              Computer
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.computerShared" name="computer" value="true" id="sharesComputer">
                <label class="form-check-label font-sm" for="sharesComputer">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.computerShared" name="computer" value="false" id="notShareComputer">
                <label class="form-check-label font-sm" for="notShareComputer">
                  No
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal">
              Water source
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.waterSourceShared" name="water-source" value="true" id="sharesWaterSource">
                <label class="form-check-label font-sm" for="sharesWaterSource">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.waterSourceShared" name="water-source" value="false" id="notShareWater">
                <label class="form-check-label font-sm" for="notShareWater">
                  No
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal">
              Classrooms
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.classRoomShared" name="classrooms" value="true" id="sharesClassrooms">
                <label class="form-check-label font-sm" for="sharesClassrooms">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.classRoomShared" name="classrooms" value="false" id="notShareClassrooms">
                <label class="form-check-label font-sm" for="notShareClassrooms">
                  No
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal">
              Library
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.libraryShared" name="library" value="true" id="shareLibrary">
                <label class="form-check-label font-sm" for="shareLibrary">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.libraryShared" name="library" value="false" id="notShareLibrary">
                <label class="form-check-label font-sm" for="notShareLibrary">
                  No
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal">
              Playground
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.playGroundShared" name="playground" value="true" id="sharesPlayground">
                <label class="form-check-label font-sm" for="sharesPlayground">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.playGroundShared" name="playground" value="false" id="notSharePlayground">
                <label class="form-check-label font-sm" for="notSharePlayground">
                  No
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-5 ms-3 font-sm fw-normal">
              wash Hand Facilities
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.washHandFacilitiesShared" name="wash-hand" value="true" id="sharesHandWasher">
                <label class="form-check-label font-sm" for="sharesHandWasher">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-3 ps-3 pb-2">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-model="facilities.washHandFacilitiesShared" name="wash-hand" value="false" id="notShareHandWasher">
                <label class="form-check-label font-sm" for="notShareHandWasher">
                  No
                </label>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="d-flex flex-column align-items-center justify-content-center">
        <button
            v-if="isFetched"
            class="facilities-btn custom-btn font-tiny fw-bold"
            @click="updateForm"
        >
          Update
        </button>
        <button
            v-else
            class="facilities-btn custom-btn font-tiny fw-bold"
            @click="submitForm"
        >
          Save
        </button>

      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import {ref, watch} from "vue";
import lodash from 'lodash'

export default {
  name: "FacilityRegistration",
  emits: ['reload'],
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    let levels = []
    if (schoolInfo !== null) {
      levels = schoolInfo.levelsOffered.map(item => item.toLowerCase())
    }
    const hasPrePrimary = levels.includes("pre_primary")
    const hasPrimary = levels.includes("primary")
    const hasPrePrimaryOrPrimary = computed(() => hasPrimary || hasPrePrimary)
    const isFetched = ref(false)

    const fetchedFacility = computed(() => store.state.questionnaireService.facilityRegInfo).value
    const waterSource = ref('')
    const source = ref('')

    let sourceOfWater = '', otherSourceOfdrinkingWater = '', usableToiletNumber = '', nonUsableToiletNumber = '',
        usableComputer = '', nonUsableComputer = '', usableLab = '', nonUsableLab = '', usableLibraries = '',
        nonUsabaleLibraries = '', usablePlayground = '', nonUsabalePlayground = '', usableClassRoom = '',
        nonUsableCalssRoom = '', usbaleWashHandFacilities = '', nonUsbaleWashHandFacilities = '', usableOther = '',
        nonUsableOther = '', toiletShared = '', classRoomShared = '', libraryShared = '', computerShared = '',
        playGroundShared = '', waterSourceShared = '', washHandFacilitiesShared = '', schoolLevel = ''

    if (fetchedFacility !== null) {
      ({sourceOfWater, otherSourceOfdrinkingWater, usableToiletNumber, nonUsableToiletNumber,
        usableComputer, nonUsableComputer, usableLab, nonUsableLab, usableLibraries,
        nonUsabaleLibraries, usablePlayground, nonUsabalePlayground, usableClassRoom,
        nonUsableCalssRoom, usbaleWashHandFacilities, nonUsbaleWashHandFacilities, usableOther,
        nonUsableOther, toiletShared, classRoomShared, libraryShared, computerShared,
        playGroundShared, waterSourceShared, washHandFacilitiesShared, schoolLevel} = fetchedFacility)

      isFetched.value = true
      waterSource.value = sourceOfWater
      source.value = lodash.capitalize(waterSource.value).replaceAll("_", " ")
    }

    watch(waterSource, () => {
      console.log(waterSource.value)
      source.value = lodash.capitalize(waterSource.value).replaceAll("_", " ")
    })

    return {
      lodash,
      waterSource,
      source,
      hasPrePrimaryOrPrimary,
      schoolInfo,
      isFetched,
      facilities: {
        schoolLevel: schoolLevel,
        // sourceOfWater: waterSource.value,
        otherSourceOfdrinkingWater: otherSourceOfdrinkingWater,
        usableToiletNumber: usableToiletNumber,
        nonUsableToiletNumber: nonUsableToiletNumber,
        usableComputer: usableComputer,
        nonUsableComputer: nonUsableComputer,
        usableLab: usableLab,
        nonUsableLab: nonUsableLab,
        usableLibraries: usableLibraries,
        nonUsabaleLibraries: nonUsabaleLibraries,
        usablePlayground: usablePlayground,
        nonUsabalePlayground: nonUsabalePlayground,
        usableClassRoom: usableClassRoom,
        nonUsableCalssRoom: nonUsableCalssRoom,
        usbaleWashHandFacilities: usbaleWashHandFacilities,
        nonUsbaleWashHandFacilities: nonUsbaleWashHandFacilities,
        usableOther: usableOther,
        nonUsableOther: nonUsableOther,
        toiletShared: toiletShared,
        classRoomShared: classRoomShared,
        libraryShared: libraryShared,
        computerShared: computerShared,
        playGroundShared: playGroundShared,
        waterSourceShared: waterSourceShared,
        washHandFacilitiesShared: washHandFacilitiesShared,
      },
    }
  },
  computed: {
    // sourceOfWater() {
    //   return this.facilities.sourceOfWater.replaceAll("_", " ")
    // },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    }
  },
  methods: {
    ...mapActions(['submitFacility', 'fetchFacilityRegistration', 'updateFacilityRegistration']),
    ...mapMutations(['setFacilityRegInfo']),

    async submitForm() {
      this.facilities['sourceOfWater'] = this.waterSource
      const requestObject = {
        id: this.schoolInfo.id,
        body: this.facilities
      }

      await this.submitFacility(requestObject)
        .then(() => {
          if (this.responseCode === 201) {
            this.$emit('reload')
          }
        })
    },

    async updateForm() {
      const requestObject = {
        id: this.schoolInfo.id,
        body: this.facilities
      }

      await this.updateFacilityRegistration(requestObject)
        .then(() => {
          if (this.responseCode === 204) {
            this.setFacilityRegInfo(null)
            this.$emit('reload')
          }
        })
    },

    async levelChanged(event) {
      const requestObject = {
        id: this.schoolInfo.id,
        level: event.target.value
      }
      await this.fetchFacilityRegistration(requestObject)
      .then(() => {
        if (this.responseCode === 200) {
          this.$emit('reload')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>