<template>
  <div class="graph-container d-flex flex-column bg-white radius-full mt-4 mb-4">
    <div class="d-flex justify-content-between">
      <span class="font-sm fw-bold">Toilet records</span>
      <a-select 
        :value="toiletOption"
        style="width: 120px"
        placeholder="View by"
        :options="viewByOptions.map(option => ({value: option}))"
        @change="toiletOptionChange"
      />
    </div>
    <div>
      <canvas class="charts" id="toilet-chart" height="300"></canvas>
    </div>    
  </div>
</template>

<script>
import { computed, onMounted } from '@vue/runtime-dom'
import {Select} from "ant-design-vue"
import Chart from "chart.js/auto";
import { useStore } from 'vuex'
import { watch, ref } from 'vue'

export default {
  name: "ToiletChart",
  components: {
    "a-select": Select,
  },
  emits: ['reload'],
  setup(props, {emit}) {
    const toiletOption = ref('')
    const store = useStore()
    const params = ref()
    const viewByOptions = ['LGA', 'Level', 'Classification', 'Toilet']
    const selectedOptions = ref('')
    const chartData = ref(null)
    const xAxisLabel = ref([])
    const dataSet = ref([])    
    const toiletData = computed(() => store.state.analysis.toiletChartData)

    const populateChart = () => {            
      dataSet.value = Object.values(toiletData.value.data.map(item => item.value))
      let checkLGA = Object.values(toiletData.value.data)[0].id.lga
      let checkLevel = Object.values(toiletData.value.data)[0].id.level
      let checkClassification = Object.values(toiletData.value.data)[0].id.classification      
      if (checkLGA !== undefined) {
        toiletOption.value = 'LGA'
        xAxisLabel.value = Object.values(toiletData.value.data.map(item => item.id.lga))      
      } else if (checkLevel !== undefined) {
        toiletOption.value = 'Level'
        xAxisLabel.value = Object.values(toiletData.value.data.map(item => item.id.level))
      } else if (checkClassification !== undefined) {
        toiletOption.value = 'Classification'
        xAxisLabel.value = Object.values(toiletData.value.data.map(item => item.id.classification))
      } else {
        toiletOption.value = 'Toilet'
        xAxisLabel.value = Object.values(toiletData.value.data.map(item => item.id.toilet))
      }
      
      chartData.value = {
        type: 'bar',
        data: {
          labels: xAxisLabel.value,
          datasets: [
            {
              label: "Toilet Infrastructure",
              categoryPercentage: 0.3,
              borderRadius: 2,
              data: dataSet.value,
              backgroundColor: 'rgba(0, 242, 104, 1)',
            },
          ]
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        },
      }
    }
    
    watch(
      () => toiletData.value,
      () => {        
        if (toiletData.value !== null && chartData.value === null) {    
          console.log("I'm reloading now!");
          emit('reload')
        }
      }
    )

    onMounted(() => {
      if (toiletData.value === null) {
        store.dispatch('fetchToiletChartData', params.value)
      } else {
        populateChart()
        const combinedEnrolmentChart = document.getElementById('toilet-chart')     
        new Chart(combinedEnrolmentChart, chartData.value)     
      }
    }) 

    const toiletOptionChange = (value) => {      
      const param = value.toLowerCase().replace(' ', '_')
      selectedOptions.value = param
      chartData.value = null
      store.dispatch('fetchToiletChartData', param)
    }

    return {
      viewByOptions,
      selectedOptions,
      toiletOption,
      toiletOptionChange
    }
  },
}
</script>

<style scoped>
.charts {
  max-height: 300px;
}
</style>