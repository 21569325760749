<template>
  <div class="d-flex flex-column bg-white radius-full pt-3 pb-5 px-5 my-4">
    <book-pre-primary-and-primary
      v-if="hasPrePrimary"
      @prePrimaryStagesCompleted="gotoJuniorIfAvailable" />

    <book-junior
      v-if="hasJSS"
      @juniorStagesCompleted="gotoSeniorIfAvailable" />

    <book-senior
      v-if="hasSSS"
      @seniorStagesCompleted="regularSchoolBookCompleted"/>
  </div>
</template>

<script>
import {mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import BookPrePrimaryAndPrimary
  from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/BookPrePrimaryAndPrimary";
import BookJunior from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/BookJunior";
import BookSenior from "@/components/dashboardPage/school/ASCDashboard/questionnaire/book/BookSenior";

export default {
  name: "RegularSchools",
  components: {BookSenior, BookJunior, BookPrePrimaryAndPrimary},
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const hasPrePrimary = schoolInfo.levelsOffered.includes("PRE_PRIMARY")
    const hasJSS = schoolInfo.levelsOffered.includes("JUNIOR")
    const hasSSS = schoolInfo.levelsOffered.includes("SENIOR")
    return {
      hasPrePrimary,
      hasJSS,
      hasSSS,
    }
  },
  methods: {
    ...mapMutations(['setActiveBookStage']),
    saveSubject() {
      this.$emit('saveBook')
    },

    gotoJuniorIfAvailable() {
      this.setActiveBookStage("junior-student")
    },

    gotoSeniorIfAvailable() {
      this.setActiveBookStage("senior-student")
    },

    regularSchoolBookCompleted() {}
  }
}
</script>

<style scoped>

</style>