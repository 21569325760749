import axios from "axios";

const initState = {
  metadata: null,
  chartData: null,
  activeTab: "access",
  loading: false,
};

const state = initState;

const mutations = {
  setMetadata(state, value) {
    state.metadata = value;
  },
  setExploreLoadingStatus(state, value) {
    state.loading = value;
  },
  setChartData(state, value) {
    state.chartData = value;
  },
  setActiveTab(state, value) {
    state.activeTab = value;
  },

  resetExploreState(state) {
    Object.assign(state, initState);
  },
};

const actions = {
  async fetchExploreMetadata({ state, commit }, section) {
    let url = "";
    if (section !== undefined) {
      url = `analysis/explore-metadata?section=${section}`;
    } else {
      url = `analysis/explore-metadata`;
    }
    if (state.metadata === null || section !== undefined) {
      commit("setExploreLoadingStatus", true);
      await axios
        .get(url)
        .then((res) => {
          commit("setExploreLoadingStatus", false);
          commit("setMetadata", res.data.data);
        })
        .catch((err) => {
          commit("setExploreLoadingStatus", false);
          console.log(err.message);
        });
    }
  },

  async fetchChartData({ commit, state }, params) {
    commit("setExploreLoadingStatus", true);
    const [index, attributes, group, indicator] = params;
    await axios
      .get(
        `analysis/explore?index=${index}&params=${attributes}&output=${indicator}&x=${group}`
      )
      .then((res) => {
        commit("setExploreLoadingStatus", false);
        if (res.data.data != undefined) {
          commit("setChartData", res.data.data);
        }
      })
      .catch((err) => {
        commit("setExploreLoadingStatus", false);
        console.log(err.message);
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
