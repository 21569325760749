import axios from "axios";

const initState = {
  enrolmentData: null,
  combinedEnrolment: null,
  enrolment: null,
  specialNeedsEnrolment: null,
  notCombinedEnrolment: null,
  totalEnrolment: null,
  grossEnrolment: null,
  netEnrolment: null,
  repeaterRate: null,
  selectedoption: "LGA",

  classroomChartData: null,
  toiletChartData: null,
  waterChartData: null,
  pupilTeachersData: null,
  realQualityData: null,
  teachersData: null,
  totalSchoolsData: null,
  numberOfSchoolsWithData: null,

  enrolmentHistoricalData: null,
  gerHistoricalData: null,
  gpiHistoricalData: null,

  lastPupilTeacherOutput: "total_students",
  lastPupilTeacherParam: "lga",
  lastTeachersOutput: "no_of_teachers",
  lastTeachersParam: "lga",

  publicPrivateSchoolRecords: null,

  loading: false,
  host: "http://ec2-3-80-156-41.compute-1.amazonaws.com",
};

const state = initState;

const mutations = {
  setEnrolmentData(state, value) {
    state.enrolmentData = value;
  },

  setCombinedEnrolment(state, value) {
    state.combinedEnrolment = value;
  },

  setNotCombinedEnrolment(state, value) {
    state.notCombinedEnrolment = value;
  },

  setGrossEnrolment(state, value) {
    state.grossEnrolment = value;
  },

  setNetEnrolment(state, value) {
    state.netEnrolment = value;
  },

  setTotalEnrolment(state, value) {
    state.totalEnrolment = value;
  },

  setSpecialNeedsEnrolment(state, value) {
    state.specialNeedsEnrolment = value;
  },

  setRepeaterRate(state, value) {
    state.repeaterRate = value;
  },

  // QUALITY MOTATIONS
  setClassroomChartData(state, value) {
    state.classroomChartData = value;
  },

  setToiletChartData(state, value) {
    state.toiletChartData = value;
  },

  setWaterChartData(state, value) {
    state.waterChartData = value;
  },

  setPupilTeachersData(state, value) {
    state.pupilTeachersData = value;
  },

  setRealQualityData(state, value) {
    state.realQualityData = value;
  },

  setTeachersData(state, value) {
    state.teachersData = value;
  },

  setTotalSchoolsData(state, value) {
    state.totalSchoolsData = value;
  },

  setNumberOfSchoolsWithData(state, value) {
    state.numberOfSchoolsWithData = value;
  },

  setLastPupilTeacherOutput(state, value) {
    state.lastPupilTeacherOutput = value;
  },

  setLastPupilTeacherParam(state, value) {
    state.lastPupilTeacherParam = value;
  },

  setTeachersOutput(state, value) {
    state.lastTeachersOutput = value;
  },

  setTeachersParam(state, value) {
    state.lastTeachersParam = value;
  },

  setEnrolmentHistoricalData(state, value) {
    state.enrolmentHistoricalData = value;
  },

  setGERHistoricalData(state, value) {
    state.gerHistoricalData = value;
  },

  setGPIHistoricalData(state, value) {
    state.gpiHistoricalData = value;
  },

  setPublicPrivateSchoolRecords(state, value) {
    state.publicPrivateSchoolRecords = value;
  },

  setAnalysisLoadingStatus(state, value) {
    state.loading = value;
  },

  setSelectedOption(state, value) {
    state.selectedoption = value;
  },

  resetAnalysisState(state) {
    Object.assign(state, initState);
  },
};

const actions = {
  async fetchEnrolmentData({ state, commit }, params) {
    var url;
    if (params !== undefined) {
      url = `analysis/dashboard?index=2&params=${params}&output=enrolment`;
    } else {
      url = `analysis/dashboard?index=2&params=lga&output=enrolment`;
    }
    if (state.enrolmentData === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setEnrolmentData", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchCombinedEnrolmentChart({ state, commit }, params) {
    var url;

    console.log(`Params are ${params}`);

    if (params !== undefined) {
      url = `analysis/dashboard?index=1&params=${params}&output=no_of_enrolment`;
    } else {
      url = `analysis/dashboard?index=1&params=lga&output=no_of_enrolment`;
    }
    if (state.combinedEnrolment === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setCombinedEnrolment", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchNotCombinedEnrolmentChart({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=5&params=${params}&output=no_of_enrolment`;
    } else {
      url = `analysis/dashboard?index=5&params=lga&output=no_of_enrolment`;
    }
    if (state.notCombinedEnrolment === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setNotCombinedEnrolment", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchGrossEnrolmentChart({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=7&params=${params}&output=gross_enrolment_rate`;
    } else {
      url = `analysis/dashboard?index=7&params=lga&output=gross_enrolment_rate`;
    }
    if (state.grossEnrolment === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setGrossEnrolment", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchNetEnrolmentChart({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=8&params=${params}&output=net_enrolment`;
    } else {
      url = `analysis/dashboard?index=8&params=lga&output=net_enrolment`;
    }
    if (state.netEnrolment === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setNetEnrolment", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchTotalEnrolmentChart({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=6&params=${params}&output=total_enrolment`;
    } else {
      url = `analysis/dashboard?index=6&params=lga&output=total_enrolment`;
    }
    if (state.totalEnrolment === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setTotalEnrolment", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchRepeaterRateChart({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=9&params=${params}&output=value`;
    } else {
      url = `analysis/dashboard?index=9&params=gender&output=value`;
    }
    if (state.repeaterRate === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setRepeaterRate", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchSpecialNeedsEnrolment({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=4&params=${params}&output=enrolment`;
    } else {
      url = `analysis/dashboard?index=4&params=disability&output=enrolment`;
    }
    if (state.specialNeedsEnrolment === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setSpecialNeedsEnrolment", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  // QUALITY
  async fetchClassroomChartData({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=10&params=${params}&output=number`;
    } else {
      url = `analysis/dashboard?index=10&params=lga&output=number`;
    }
    if (state.classroomChartData === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setClassroomChartData", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchToiletChartData({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=11&params=${params}&output=value`;
    } else {
      url = `analysis/dashboard?index=11&params=lga&output=value`;
    }
    if (state.toiletChartData === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setToiletChartData", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchWaterChartData({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=12&params=${params}&output=percentage`;
    } else {
      url = `analysis/dashboard?index=12&params=lga&output=percentage`;
    }
    if (state.toiletChartData === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setWaterChartData", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchPupilTeachersRatioData({ state, commit }, param) {
    var url;
    const outputs = [
      "Total Students",
      "Total Teachers",
      "Qualified Teachers",
      "Pupil Teacher Ratio",
      "Pupil Qualified Teacher Ratio",
    ];
    const params = ["LGA", "Level", "Classification"];

    const formattedOutputs = outputs.map((item) =>
      item.replaceAll(" ", "_").toLowerCase()
    );
    const formattedParams = params.map((item) =>
      item.replaceAll(" ", "_").toLowerCase()
    );

    if (param !== undefined && formattedOutputs.includes(param)) {
      commit("setLastPupilTeacherOutput", param);
      url = `analysis/dashboard?index=13&params=${state.lastPupilTeacherParam}&output=${param}`;
    } else if (param !== undefined && formattedParams.includes(param)) {
      commit("setLastPupilTeacherParam", param);
      url = `analysis/dashboard?index=13&params=${param}&output=${state.lastPupilTeacherOutput}`;
    } else {
      url = `analysis/dashboard?index=13&params=${state.lastPupilTeacherParam}&output=${state.lastPupilTeacherOutput}`;
    }
    if (state.pupilTeachersData === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setPupilTeachersData", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchRealQualityChartData({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=14&params=${params}&output=number`;
    } else {
      url = `analysis/dashboard?index=14&params=lga&output=number`;
    }
    if (state.realQualityData === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setRealQualityData", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchTeachersChartData({ state, commit }, param) {
    var url;

    const outputs = ["No of Teachers", "Qualified"];
    const paramOptions = ["LGA", "Gender", "Level", "Classification"];

    const formattedOutputs = outputs.map((item) =>
      item.replaceAll(" ", "_").toLowerCase()
    );
    const formattedParams = paramOptions.map((item) =>
      item.replaceAll(" ", "_").toLowerCase()
    );

    if (param !== undefined && formattedOutputs.includes(param)) {
      commit("setTeachersOutput", param);
      url = `analysis/dashboard?index=16&params=${state.lastTeachersParam}&output=${param}`;
    } else if (param !== undefined && formattedParams.includes(param)) {
      commit("setTeachersParam", param);
      url = `analysis/dashboard?index=16&params=${param}&output=${state.lastTeachersOutput}`;
    } else {
      url = `analysis/dashboard?index=16&params=${state.lastTeachersParam}&output=${state.lastTeachersOutput}`;
    }
    if (state.teachersData === null || param !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setTeachersData", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },

  async fetchTotalSchoolsChartData({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=17&params=${params}&output=total_no_of_schools`;
    } else {
      url = `analysis/dashboard?index=17&params=lga&output=total_no_of_schools`;
    }
    if (state.totalSchoolsData === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setTotalSchoolsData", res.data);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  },

  async fetchNumberOfSchWithChartData({ state, commit }, params) {
    var url;

    if (params !== undefined) {
      url = `analysis/dashboard?index=18&params=${params}&output=value`;
    } else {
      url = `analysis/dashboard?index=18&params=no_of_school_with&output=value`;
    }
    if (state.numberOfSchoolsWithData === null || params !== undefined) {
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          if (res !== null) {
            commit("setNumberOfSchoolsWithData", res.data);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  },

  async fetchEnrolmentHistoricalDataChartData({ commit }) {
    axios
      .get(
        `analysis/explore?index=3&params=session,gender&output=enrolment&x=session`,
        { timeout: 10000 }
      )
      .then((res) => {
        if (res !== null) {
          commit("setEnrolmentHistoricalData", res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  },

  async fetchGERHistoricalDataChartData({ commit }) {
    axios
      .get(
        `analysis/explore?index=3&params=session,gender&output=ger&x=session`,
        { timeout: 10000 }
      )
      .then((res) => {
        if (res !== null) {
          commit("setGERHistoricalData", res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  },

  async fetchGPIHistoricalDataChartData({ commit }) {
    axios
      .get(
        `analysis/explore?index=3&params=session,gender&output=gpi&x=session`,
        { timeout: 10000 }
      )
      .then((res) => {
        if (res !== null) {
          commit("setGPIHistoricalData", res.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  },

  async fetchPublicPrivateSchoolRecords({ commit }) {
    axios
      .get(
        `analysis/explore?index=17&params=lga,classification&output=total_no_of_schools&x=lga`,
        { timeout: 10000 }
      )
      .then((res) => {
        commit("setAnalysisLoadingStatus", true);
        if (res !== null) {
          commit("setAnalysisLoadingStatus", false);
          commit("setPublicPrivateSchoolRecords", res.data.data);
        }
      })
      .catch((err) => {
        commit("setAnalysisLoadingStatus", false);
        console.log(err.message);
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
