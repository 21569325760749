<template>
  <div class="d-flex flex-column px-0">
    <div class="d-flex justify-content-between flex-nowrap mt-4">
      <div class="col d-flex align-items-center">
        <span class="fw-bold font-md">01</span
        ><span class="font-tiny ms-2">Equipment</span>
      </div>
      <div class="col d-flex align-items-center">
        <span class="fw-bold font-md">02</span
        ><span class="font-tiny ms-2">Classroom</span>
      </div>
      <div class="col d-flex align-items-center">
        <span class="fw-bold font-md">03</span
        ><span class="font-tiny ms-2">Seats and desk</span>
      </div>
      <div 
        class="col d-flex align-items-center">
        <span class="fw-bold font-md">04</span
        ><span class="font-tiny ms-2">Toilets</span>
      </div>
      <div 
        class="col d-flex align-items-center">
        <span class="fw-bold font-md">05</span
        ><span class="font-tiny ms-2">Library</span>
      </div>
      <div 
        class="col d-flex align-items-center">
        <span class="fw-bold font-md">06</span
        ><span class="font-tiny ms-2">Light</span>
      </div>
      <div 
        class="col d-flex align-items-center">
        <span class="fw-bold font-md">07</span
        ><span class="font-tiny ms-2">Water</span>
      </div>
    </div>
    <div class="d-flex justify-content-between flex-nowrap mb-2">
      <div class="col d-flex align-items-center">
        <i
          :class="[firstPhase ? 'bi bi-check-circle-fill' : 'bi bi-circle']"
          class="font-lg dm-color overflow-hidden"
          @click="$emit('gotoPhaseOne')"
        ></i>
        <span
          :class="[secondPhase ? 'progression-solid' : 'shorter-progress-dotted']"
          class="flex-grow-1 px-1"
        ></span>
      </div>
      <div class="col d-flex align-items-center">
        <i
          :class="[secondPhase ? 'bi bi-check-circle-fill' : 'bi bi-circle']"
          class="font-lg dm-color overflow-hidden"
          @click="$emit('gotoPhaseTwo')"
        ></i>
        <span
          :class="[thirdPhase ? 'progression-solid' : 'shorter-progress-dotted']"
          class="flex-grow-1 px-1"
        ></span>
      </div>
      <div class="col d-flex align-items-center">
        <i
          :class="[thirdPhase ? 'bi bi-check-circle-fill' : 'bi bi-circle']"
          class="font-lg dm-color overflow-hidden"
          @click="$emit('gotoPhaseTwo')"
        ></i>
        <span
          :class="[fourthPhase ? 'progression-solid' : 'shorter-progress-dotted']"
          class="flex-grow-1 px-1"
        ></span>
      </div>
      <div class="col d-flex align-items-center">
        <i
          :class="[fourthPhase ? 'bi bi-check-circle-fill' : 'bi bi-circle']"
          class="font-lg dm-color overflow-hidden"
          @click="$emit('gotoPhaseTwo')"
        ></i>
        <span
          :class="[fifthPhase ? 'progression-solid' : 'shorter-progress-dotted']"
          class="flex-grow-1 px-1"
        ></span>
      </div>
      <div class="col d-flex align-items-center">
        <i
          :class="[fifthPhase ? 'bi bi-check-circle-fill' : 'bi bi-circle']"
          class="font-lg dm-color overflow-hidden"
          @click="$emit('gotoPhaseTwo')"
        ></i>
        <span
          :class="[sixthPhase ? 'progression-solid' : 'shorter-progress-dotted']"
          class="flex-grow-1 px-1"
        ></span>
      </div>
      <div class="col d-flex align-items-center">
        <i
          :class="[sixthPhase ? 'bi bi-check-circle-fill' : 'bi bi-circle']"
          class="font-lg dm-color overflow-hidden"
          @click="$emit('gotoPhaseTwo')"
        ></i>
        <span
          :class="[seventhPhase ? 'progression-solid' : 'shorter-progress-dotted']"
          class="flex-grow-1 px-1"
        ></span>
      </div> 
      <div class="col d-flex align-items-center">
        <i
          :class="[seventhPhase ? 'bi bi-check-circle-fill' : 'bi bi-circle']"
          class="font-lg dm-color overflow-hidden"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressHeader",
  props: ["firstPhase", "secondPhase", "thirdPhase", "fourthPhase", 
          "fifthPhase", "sixthPhase", "seventhPhase"],
};
</script>