<template>
  <div class="graph-container d-flex flex-column bg-white radius-full mt-4 mb-2">
    <div class="d-flex justify-content-between">
      <span class="font-sm fw-bold">Net Enrolment rate of education</span>
      <a-select 
        :value="netEnrolmentOption"
        style="width: 120px"
        placeholder="View by"
        size="small"
        :options="viewByOptions.map(option => ({value: option}))"
        @change="enrolmentRateChange"
      />
    </div>
    <canvas class="charts" id="net-enrolment-chart" height="300"></canvas>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import Chart from "chart.js/auto";
import {Select} from "ant-design-vue"

export default {
  name: "NetEnrolmentChart",
  components: {"a-select": Select},
    setup(props, {emit}) {
    const netEnrolmentOption = ref('')
    const store = useStore()
    const params = ref()
    const viewByOptions = ['LGA', 'Gender', 'Level']
    const selectedOptions = ref('')
    const chartData = ref(null)
    const xAxisLabel = ref([])
    const dataSet = ref([])    
    const netEnrolment = computed(() => store.state.analysis.netEnrolment)

    const populateChart = () => {            
      dataSet.value = Object.values(netEnrolment.value.data.map(item => item.net_enrolment))
      let checkLGA = Object.values(netEnrolment.value.data)[0].id.lga
      let checkLevel = Object.values(netEnrolment.value.data)[0].id.level
      if (checkLGA !== undefined) {
        netEnrolmentOption.value = 'LGA'
        xAxisLabel.value = Object.values(netEnrolment.value.data.map(item => item.id.lga))      
      } else if (checkLevel !== undefined) {
        netEnrolmentOption.value = 'Level'
        xAxisLabel.value = Object.values(netEnrolment.value.data.map(item => item.id.level))
      } else {
        netEnrolmentOption.value = 'Gender'
        xAxisLabel.value = Object.values(netEnrolment.value.data.map(item => item.id.gender))
      }
      
      chartData.value = {
        type: 'bar',
        data: {
          labels: xAxisLabel.value,
          datasets: [
            {
              label: "Net Enrolment Rate",
              categoryPercentage: 0.3,
              borderRadius: 2,
              data: dataSet.value,
              backgroundColor: 'rgba(0, 242, 104, 1)',
            },
          ]
        },
        maintainAspectRatio: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          }
        },
      }
    }
    
    watch(
      () => netEnrolment.value,
      () => {        
        if (netEnrolment.value !== null && chartData.value === null) {    
          emit('reload')
        }
      }
    )

    onMounted(() => {
      if (netEnrolment.value === null) {
        store.dispatch('fetchNetEnrolmentChart', params.value)
      } else {
        populateChart()
        const netEnrolmentChart = document.getElementById('net-enrolment-chart')     
        new Chart(netEnrolmentChart, chartData.value)     
      }
    }) 

    const enrolmentRateChange = (value) => {      
      const param = value.toLowerCase().replace(' ', '_')
      selectedOptions.value = param
      chartData.value = null    
      store.dispatch('fetchNetEnrolmentChart', param)
    }

    return {
      viewByOptions,
      selectedOptions,
      netEnrolmentOption,
      enrolmentRateChange
    }
  }
}
</script>