const state = {
    previousScreen: null,
    stages: {
        centerIdentification: false,
        facilitiesAndEquipment: false,
        facilitatorsInfo: false,
        learnersInfo: false,
        learningMaterials: false,
        instructionalMaterials: false,
        vocation: false,
        challenges: false,
        suggestions: false,
    }
}

const mutations = {

    setNMECPreviousScreen(state, screen) {
        state.previousScreen = screen
    },

    setCenterIdentificationDone(state, isDone) {
        state.stages.centerIdentification = isDone
    },

    setFacilitiesAndEquipmentDone(state, isDone) {
        state.stages.facilitiesAndEquipment = isDone
    },

    setFacilitatorsInfoDone(state, isDone) {
        state.stages.facilitatorsInfo = isDone
    },

    setLearnersInfoDone(state, isDone) {
        state.stages.learnersInfo = isDone
    },

    setLearningMaterialsDone(state, isDone) {
        state.stages.learningMaterials = isDone
    },

    setInstructionalMaterialsDone(state, isDone) {
        state.stages.instructionalMaterials = isDone
    },

    setVocationDone(state, isDone) {
        state.stages.vocation = isDone
    },

    setChallengesDone(state, isDone) {
        state.stages.challenges = isDone
    },

    setSuggestionsDone(state, isDone) {
        state.stages.suggestions = isDone
    },

    resetStages(state) {
        state.stages.centerIdentification = false
        state.stages.facilitiesAndEquipment = false
        state.stages.facilitatorsInfo = false
        state.stages.learnersInfo = false
        state.stages.learningMaterials = false
        state.stages.instructionalMaterials = false
        state.stages.vocation = false
        state.stages.challenges = false
        state.stages.suggestions = false
    }
}

export default { state, mutations }