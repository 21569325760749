<template>
  <div class="bg-accent d-flex flex-column min-vh-100">
    <div class="container pt-4">
      <dashboard-header 
        @goBack="goBack"
        :hasBack="true"
      />
      <div v-if="loadingStatus" class="overlay">
        <clip-loader class="loading"></clip-loader>
      </div>
      <number-of-schools v-if="index === '0'" />
      <number-of-private-schools v-if="index === '1'" />
      <number-of-public-schools-enrolment-and-teachers v-if="index === '2'" />
      <number-of-special-curriculum-schools v-if="index === '3'" />
      <absolute-number-characteristics v-if="index === '4'" />
      <percentage-characteristics v-if="index === '5'" />
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import AbsoluteNumberCharacteristics
  from "@/components/dashboardPage/dataTable/ASCCoverageTables/AbsoluteNumberCharacteristics";
import PercentageCharacteristics
  from "@/components/dashboardPage/dataTable/ASCCoverageTables/PercentageCharacteristics";
import NumberOfSpecialCurriculumSchools
  from "@/components/dashboardPage/dataTable/ASCCoverageTables/NumberOfSpecialCurriculumSchools";
import NumberOfPrivateSchools from "@/components/dashboardPage/dataTable/ASCCoverageTables/NumberOfPrivateSchools";
import NumberOfSchools from "@/components/dashboardPage/dataTable/ASCCoverageTables/NumberOfSchools";
import NumberOfPublicSchoolsEnrolmentAndTeachers from "@/components/dashboardPage/dataTable/ASCCoverageTables/NumberOfPublicSchoolsEnrolmentAndTeachers";
import DashboardHeader from '@/components/dashboardPage/DashboardHeader.vue';
import stateNLG from "@/helpers/utils/state_local.json";
import {range} from "@/helpers/utils/helper_functions";
import {reactive, ref, computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import { useStore } from 'vuex';

export default {
  name: "ASCInfoAndCoverageTables",
  emits: ['gotoDataTable'],
  components: {
    DashboardHeader,
    AbsoluteNumberCharacteristics,
    PercentageCharacteristics,
    NumberOfSpecialCurriculumSchools,
    NumberOfPrivateSchools,
    NumberOfSchools,
    NumberOfPublicSchoolsEnrolmentAndTeachers,  
    ClipLoader,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const loadingStatus = computed(() => store.state.datatables.loadingStatus)
    const index = route.params.index
    const state = Object.entries(stateNLG)[35]
    const lgs = Object.values(state[1].locals)
    const today = new Date()
    const currentYear = today.getFullYear()
    const feasibleYears = range(currentYear - 35, currentYear);
    const currentSession = ref(currentYear - 1 + "/" + (currentYear))
    const searchField = ref('')

    const dataObject = reactive({
      lga: '',
      selectedYear: currentSession,
    })

    const onDownloadClicked = () => { console.log("Downloading...") }

    const goBack = () => { store.commit('setCurrentStage', 1); router.back() }

    return {
      index,
      lgs,
      feasibleYears,
      currentSession,
      searchField,
      dataObject,
      loadingStatus,
      onDownloadClicked,
      goBack,
    }
  },
}
</script>

<style scoped>

</style>