<template>
  <div class="d-flex flex-column">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div class="d-flex justify-content-between align-items-center wide-container my-4">
      <span class="font-normal fw-bold">Users</span>
      <div class="d-flex">
        <div
          class="
            custom-btn
            d-flex
            align-items-center
            justify-content-center
            pointer-no-bg
          "
          @click="$emit('addNewUser')"
        >
          <span class="material-icons font-normal">
            add
          </span>
          <span class="font-sm ps-1">Add new user</span>
        </div>
      </div>
    </div>

    <div class="d-flex radius-half wide-container bg-white shadow-sm p-3 mb-3">
      <div class="col-6 d-flex flex-column pe-3">
        <span class="font-tiny fw-bold mb-2">What are you looking for?</span>
        <div class="custom-input-group bg-accent">
          <span class="p-1">
            <span class="material-icons font-normal pt-1 grey-shade">
              search
            </span>
          </span>
          <input
            type="text"
            class="ph-sm no-outline-input font-tiny fw-bolder"
            placeholder="Search"
            v-model="searchField"
          />
        </div>
      </div>
      <div class="col-3 d-flex flex-column pe-2">
        <span class="font-tiny fw-bold mb-2">Filter</span>
        <div>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
            v-model="searchFilter"
          >
            <option class="bg-white" selected value="">All</option>
            <option class="bg-white" value="name">Name</option>
            <option class="bg-white" value="gender">Gender</option>
            <option class="bg-white" value="email">Email</option>
            <option class="bg-white" value="lga">LGA</option>
            <option class="bg-white" value="status">Status</option>
            <option class="bg-white" value="position">Position</option>
            <option class="bg-white" value="organization">Organization name</option>
          </select>
        </div>
      </div>
      <div class="col-3 d-flex flex-column ps-2">
        <span class="font-tiny fw-bold mb-2">Sort</span>
        <div>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
            v-model="sortOption"
          >
            <option class="bg-white" selected value="">All</option>
            <option class="bg-white" value="name">Name</option>
            <option class="bg-white" value="gender">Gender</option>
            <option class="bg-white" value="email">Email</option>
            <option class="bg-white" value="lga">LGA</option>
            <option class="bg-white" value="status">Status</option>
            <option class="bg-white" value="position">Position</option>
            <option class="bg-white" value="organization">Organization name</option>
          </select>
        </div>
      </div>
    </div>

    <div class="table-responsive bg-white shadow-sm radius-full mb-5 wide-container">
      <table class="table table-hover font-sm" aria-label="Users table">
        <thead>
          <tr>
            <th class="align-middle py-3 ps-4 table-cell first" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Full Name</span>
                <div class="vertical-icons ms-2">
                  <span 
                    @click="nameArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="nameArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="align-middle py-3 table-cell" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Gender</span>
                <div class="vertical-icons ms-2">
                  <span 
                    @click="genderArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="genderArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>              
              </div>
            </th>
            <th class="align-middle py-3 table-cell" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Email</span>
                <div class="vertical-icons ms-2">
                  <span 
                    @click="emailArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="emailArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div> 
              </div>
            </th>
            <th class="align-middle py-3 table-cell" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Position</span>
                <div class="vertical-icons ms-2">
                  <span 
                    @click="positionArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="positionArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div> 
              </div>
            </th>
            <th class="align-middle py-3 table-cell" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>LGA</span>
                <div class="vertical-icons ms-2">
                  <span 
                    @click="lgaArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="lgaArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="align-middle py-3 table-cell" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Status</span>
                <div class="vertical-icons ms-2">
                  <span 
                    @click="statusArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="statusArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="align-middle py-3 table-cell" scope="col"></th>
          </tr>
        </thead>
        <tbody v-if="filteredUsers.length > 0">
          <tr
            class="pointer-no-bg"
            v-for="(user, index) in filteredUsers"
            :key="index"
            @click="userProfile(user.id)"
          >
            <td class="py-3 ps-4 table-cell first">
              {{ user.firstName + " " + user.lastName }}
            </td>
            <td class="py-3 table-cell">{{ user.gender }}</td>
            <td class="py-3 table-cell">{{ user.email }}</td>
            <td class="py-3 table-cell">{{ roles.get(user.role) }}</td>
            <td class="py-3 table-cell">{{ user.lga }}</td>
            <td class="py-3 table-cell">
              <span
                :class="[user.active ? 'color-green-shade' : 'color-ef']"
                class="skew-box-tiny"
              ></span>
              {{ user.active ? "active" : "inactive" }}
            </td>
            <td class="text-end dropend pe-2 py-3">
              <span @click.stop class="p-3">              
                <span 
                  class="material-icons-outlined grey-accent px-3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  more_vert
                </span>
                <ul
                  class="
                    dropdown-menu
                    action-menu
                    bg-accent
                    shadow
                    font-tiny
                    radius-half
                  "
                  aria-labelledby="options"
                >
                  <li @click="userProfile(user.id)">
                    <span class="dropdown-item d-flex grey-accent">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        person
                      </span>
                      <span>Profile</span>
                    </span>
                  </li>
                  <li @click="editUser(user)">
                    <span class="dropdown-item d-flex grey-accent" href="#">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        edit
                      </span>
                      <span>Edit</span>                      
                    </span>
                  </li>
                  <li @click="showUpdateRoleModal(user)">
                    <span class="dropdown-item d-flex grey-accent" href="#">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        update
                      </span>                      
                      <span>Update Role</span>  
                    </span>
                  </li>
                  <li @click="toggleUserStatus(user.id)">
                    <span class="dropdown-item d-flex grey-accent" href="#">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        {{ user.active ? 'toggle_on' : 'toggle_off' }}
                      </span>
                      <span>{{ user.active ? "Deactivate" : "Activate" }}</span>                              
                    </span>
                  </li>
                  <li>
                    <span class="dropdown-item d-flex grey-accent" href="#">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        cancel
                      </span>   
                      <span>Delete</span>                      
                    </span>
                  </li>
                </ul>
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8" class="text-center fw-medium">
              {{ message }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end align-items-center font-sm pb-3 px-4">
        <span>Rows per page:
          <select v-model="numberOfRows" v-on:change="onRowsChanged($event)" class="outline-clear" id="row">
            <option value="12">12</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>

        <span class="font-sm ms-3">1 to {{numberOfRows}} of {{totalRecord}}
          <span
            @click="fetchPrev"
            class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_back_ios
          </span>
          <span
            @click="fetchNext"
            class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_forward_ios
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import {stringifiedRoles} from "@/helpers/utils/user_roles.js"
import {resetAll} from "@/helpers/utils/helper_functions";
import {mapActions, mapMutations, useStore} from "vuex";
import {computed, onMounted} from 'vue';

export default {
  name: "UsersTable",
  components: { ClipLoader },
  props: ['title'],
  setup() {
    const store = useStore();
    
    const roles = stringifiedRoles
    const numberOfRows = computed(() => store.state.auth.numberOfRows).value
    return {
      numberOfRows,
      roles,
    };
  },
  data() {
    return {
      searchFilter: '',
      searchField: '',
      sortOption: '',
      selectedUser: null,
      arrowsState: {
        nameArrowUp: false,
        nameArrowDown: false,
        genderArrowUp: false,
        genderArrowDown: false,
        emailArrowUp: false,
        emailArrowDown: false,
        positionArrowUp: false,
        positionArrowDown: false,
        organizationArrowUp: false,
        organizationArrowDown: false,
        lgaArrowUp: false,
        lgaArrowDown: false,
        statusArrowUp: false,
        statusArrowDown: false,
      }
    }
  },
  computed: {
    fetchedContent() {
      return this.$store.state.auth.users
    },

    users() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.content
      }
      return []
    },

    message() {
      return this.$store.state.auth.message ? this.$store.state.auth.message : "No record found!"
    },

    filteredUsers() {
      const { users, searchField, filterBy, sortOption } = this
      const { nameArrowUp, nameArrowDown, genderArrowUp, genderArrowDown, emailArrowUp, emailArrowDown,
              positionArrowUp, positionArrowDown, organizationArrowUp, organizationArrowDown,
              lgaArrowUp, lgaArrowDown, statusArrowUp, statusArrowDown
      } = this.arrowsState

      if (nameArrowUp) {
        this.sortUsers(users, "name")
      }
      if (nameArrowDown) {
        this.sortUsers(users, "name", "DSC")
      }
      if (genderArrowUp) {
        this.sortUsers(users, "gender")
      }
      if (genderArrowDown) {
        this.sortUsers(users, "gender", "DSC")
      }
      if (emailArrowUp) {
        this.sortUsers(users, "email")
      }
      if (emailArrowDown) {
        this.sortUsers(users, "email", "DSC")
      }
      if (positionArrowUp) {
        this.sortUsers(users, "position")
      }
      if (positionArrowDown) {
        this.sortUsers(users, "position", "DSC")
      }
      if (organizationArrowUp) {
        this.sortUsers(users, "organization")
      }
      if (organizationArrowDown) {
        this.sortUsers(users, "organization", "DSC")
      }
      if (lgaArrowUp) {
        this.sortUsers(users, "lga")
      }
      if (lgaArrowDown) {
        this.sortUsers(users, "lga", "DSC")
      }
      if (statusArrowUp) {
        this.sortUsers(users, "status")
      }
      if (statusArrowDown) {
        this.sortUsers(users, "status", "DSC")
      }

      this.sortUsers(users, sortOption)
      if (users) {
        return users.filter(user => filterBy(user).toLowerCase().includes(searchField.toLowerCase()))
      } else {
        return []
      }
    },

    totalRecord() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.totalElements
      }
      return 0
    },

    currentPage() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.pageNumber + 1
      }
      return 0
    },

    previousPage() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.pageNumber - 1
      }
      return 0
    },

    loading() {
      return this.$store.state.auth.loading
    },

    requestError() {
      console.log(this.$store.state.auth.message);
      return this.$store.state.auth.message
    },
  },
  methods: {
    ...mapActions(["fetchUsers", "activateDeactivate"]),
    ...mapMutations(["setLoadingStatus", "setNumberOfRows"]),
    userProfile(userId) {
      this.$emit("showUserProfile", userId);
    },

    editUser(user) {
      this.$emit("editUser", user);
    },

    fetchPagedUsers() {
      console.log("Fetching users...")
      this.fetchUsers();
    },

    onRowsChanged(event) {
      const number = event.target.value
      console.log("I'm now going to fetch " + number + " users.");
      this.setNumberOfRows(number)
      this.fetchUsers()
    },

    fetchNext() {
      if (this.fetchedContent.last === false) {
        this.fetchUsers(this.currentPage)
      }
    },
    fetchEdited(){
      this.fetchUsers(0)
      console.log(this.currentPage);
    },
    fetchPrev() {
      if (this.fetchedContent.first === false) {        
        this.fetchUsers(this.previousPage)
        console.log(this.previousPage);
      }
    },

    filterBy(user) {
      if (this.searchFilter === "" || this.searchFilter === "name") {
        return `${user.firstName} ${user.lastName}`
      }
      else if (this.searchFilter === "gender") {
        return user.gender
      }
      else if (this.searchFilter === "email") {
        return user.email
      }
      else if (this.searchFilter === "position") {
        return user.role
      }
      else if (this.searchFilter === "organization") {
        return user.organization
      }
      else if (this.searchFilter === "lga") {
        return user.lga
      }
      else {
        return user.active
      }
    },

    sortUsers(users, sortFilter, order = "ASC") {
      if (sortFilter) {
        switch (sortFilter) {
          case "name":
            return users.sort((user1, user2) => {
              const firstUser = `${user1.firstName} ${user1.lastName}`
              const secondUser = `${user2.firstName} ${user2.lastName}`
              if (order === "ASC") {
                return (firstUser > secondUser) ? 1 : ((secondUser > firstUser) ? -1 : 0)
              }
              return (secondUser > firstUser) ? 1 : ((firstUser > secondUser) ? -1 : 0)
            });

          case "gender":
            return users.sort((user1, user2) => {
              if (order === "ASC") {
                return (user1.gender > user2.gender) ? 1 : ((user2.gender > user1.gender) ? -1 : 0)
              }
              return (user2.gender > user1.gender) ? 1 : ((user1.gender > user2.gender) ? -1 : 0)
            });

          case "email":
            return users.sort((user1, user2) => {
              if (order === "ASC") {
                return (user1.email > user2.email) ? 1 : ((user2.email > user1.email) ? -1 : 0)
              }
              return (user2.email > user1.email) ? 1 : ((user1.email > user2.email) ? -1 : 0)
            });

          case "lga":
            return users.sort((user1, user2) => {
              if (order === "ASC") {
                return (user1.lga > user2.lga) ? 1 : ((user2.lga > user1.lga) ? -1 : 0)
              }
              return (user2.lga > user1.lga) ? 1 : ((user1.lga > user2.lga) ? -1 : 0)
            });

          case "position":
            return users.sort((user1, user2) => {
              if (order === "ASC") {
                return (user1.role > user2.role) ? 1 : ((user2.role > user1.role) ? -1 : 0)
              }
              return (user2.role > user1.role) ? 1 : ((user1.role > user2.role) ? -1 : 0)
            });

          case "organization":
            return users.sort((user1, user2) => {
              if (order === "ASC") {
                return (user1.organization > user2.organization) ? 1 : ((user2.organization > user1.organization) ? -1 : 0)
              }
              return (user2.organization > user1.organization) ? 1 : ((user1.organization > user2.organization) ? -1 : 0)
            });

          case "status":
            return users.sort((user1, user2) => {
              if (order === "ASC") {
                return (user1.active > user2.active) ? 1 : ((user2.active > user1.active) ? -1 : 0)
              }
              return (user2.active > user1.active) ? 1 : ((user1.active > user2.active) ? -1 : 0)
            });
        }
      }
    },

    nameArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.nameArrowUp = true
    },

    nameArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.nameArrowDown = true
    },

    genderArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.genderArrowUp = true
    },

    genderArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.genderArrowDown = true
    },

    sfnArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.sfnArrowUp = true
    },

    sfnArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.sfnArrowDown = true
    },

    emailArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.emailArrowUp = true
    },

    emailArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.emailArrowDown = true
    },

    positionArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.positionArrowUp = true
    },

    positionArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.positionArrowDown = true
    },

    organizationArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.organizationArrowUp = true
    },

    organizationArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.organizationArrowDown = true
    },

    lgaArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.lgaArrowUp = true
    },

    lgaArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.lgaArrowDown = true
    },

    statusArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.statusArrowUp = true
    },

    statusArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.statusArrowDown = true
    },

    showUpdateRoleModal(user) {
      this.selectedUser = user
      this.$emit("showUpdateRoleModal", user)
    },

    async toggleUserStatus(userId) {
      await this.activateDeactivate(userId);
    },
  },
  mounted() {
      this.$store.dispatch('fetchUsers')
      // this.users()
      this.fetchEdited()
      
    }
};
</script>