<template>
  <div class="mt-5">
    <p class="font-md fw-bold mb-2">Review Questionnaire</p>
    <div class="d-flex px-5 py-4 mb-4 radius-full bg-white">
      <div class="col mt-3 px-3">
        <label class="form-label font-sm fw-normal">
          Choose section
        </label>
        <select class="form-select" aria-label="Questionnaire sections" v-model="section">
          <option selected>Choose section</option>
          <option value="identification">School Identification</option>
          <option value="characteristics">School Characteristics</option>
          <option value="enrolment">Enrolment</option>
          <option value="staff">Staff</option>
          <option value="classroom">Classroom</option>
          <option value="facilities">Facilities</option>
          <option value="subject">Number of students by subject</option>
          <option value="book">book</option>
          <option value="qualification">qualification</option>
          <option value="family">family</option>
          <option value="attestation">attestation</option>
        </select>
      </div>
      <div class="col-2 align-self-end">
        <button class="btn btn-outline-custom font-tiny btn-rd px-4 py-2 mb-1" @click="gotoSection">Go</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  name: "ReviewQuestionnaire",
  setup() {
    const section = ref('')
    const gotoSection = () => {
      console.log(section.value);
    }

    return {
      section,
      gotoSection
    }
  },
}
</script>