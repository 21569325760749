<template>
  <div class="d-flex flex-row-reverse bg-accent">
    <n-m-e-c-sidebar
      @showCenterIdentification="showCenterIdentification"
      @showFacilitiesAndEquipment="showFacilitiesAndEquipment"
      @showFacilitatorsInfo="showFacilitatorsInfo"
      @showLearnersInfo="showLearnersInfo"
      @showLearningMaterials="showLearningMaterials"
      @showInstructionalMaterials="showInstructionalMaterials"
      @showVocationProgram="showVocationProgram"
      @showChallenges="showChallenges"
      @showSuggestion="showSuggestion" 
      :screens="screens"/>

    <div class="col-md-9 main-content align-self-end p-5">
      <div class="px-5"><dashboard-header /></div>
      <component :is="activeComponent.name" @continue="activeComponent.on.action"></component>
    </div>
  </div>
</template>

<script>
import NMECSidebar from "@/components/dashboardPage/school/ASCDashboard/nmec/NMECSidebar";
import DashboardHeader from "@/components/dashboardPage/DashboardHeader";
import CenterIdentification from "@/components/dashboardPage/school/ASCDashboard/nmec/CenterIdentification";
import CenterFacilities from "@/components/dashboardPage/school/ASCDashboard/nmec/CenterFacilities";
import FacilitatorsInfo from "@/components/dashboardPage/school/ASCDashboard/nmec/FacilitatorsInfo";
import LearnersInfo from "@/components/dashboardPage/school/ASCDashboard/nmec/LearnersInfo";
import LearningMaterials from "@/components/dashboardPage/school/ASCDashboard/nmec/LearningMaterials";
import InstructionalMaterials from "@/components/dashboardPage/school/ASCDashboard/nmec/InstructionalMaterials";
import VocationEducation from "@/components/dashboardPage/school/ASCDashboard/nmec/VocationEducation";
import Challenges from "@/components/dashboardPage/school/ASCDashboard/nmec/Challenges";
import Suggestions from "@/components/dashboardPage/school/ASCDashboard/nmec/Suggestions";
import {mapMutations} from "vuex";
import { resetAll } from '../../helpers/utils/helper_functions';

export default {
  name: "NMECDashboard",
  components: {
    Suggestions,
    Challenges,
    VocationEducation,
    InstructionalMaterials,
    LearningMaterials,
    LearnersInfo,
    FacilitatorsInfo,
    CenterFacilities,
    CenterIdentification,
    DashboardHeader,
    NMECSidebar,
  },
  created() {
    this.resetStages()
  },
  data() {
    const components = [
      {
        name: "CenterIdentification",
        props: {data: "Center identification"},
        on: { action: () => { this.showFacilitiesAndEquipment() } }
      },
      {
        name: "CenterFacilities",
        props: {data: "Center facilities"},
        on: { action: () => { this.showFacilitatorsInfo() } }
      },
      {
        name: "FacilitatorsInfo",
        props: {data: "Facilities info"},
        on: {action: () => { this.showLearnersInfo() } }
      },
      {
        name: "LearnersInfo",
        props: {data: "Learners info"},
        on: {action: () => { this.showLearningMaterials() } }
      },
      {
        name: "LearningMaterials",
        props: {data: "Learning materials"},
        on: {action: () => { this.showInstructionalMaterials() } }
      },
      {
        name: "InstructionalMaterials",
        props: {data: "Instructional materials"},
        on: {action: () => { this.showVocationProgram() } }
      },
      {
        name: "VocationEducation",
        props: {data: "Vocation education"},
        on: {action: () => { this.showChallenges() } }
      },
      {
        name: "Challenges",
        props: {data: "Challenges"},
        on: {action: () => { this.showSuggestion() } }
      },
      {
        name: "Suggestions",
        props: {data: "Suggestions"},
        on: {action: () => { this.setSuggestionsData(true); alert("Well done! That's all.") } }
      },
    ]
    const screens = {
      identification: true,
      facilities: false,
      facilitators: false,
      learners: false,
      learningMaterials: false,
      instructionalMaterials: false,
      vocation: false,
      challenges: false,
      suggestions: false
    }
    return {
      components,
      currentComponent: components[0],
      screens
    }
  },
  computed: {
    activeComponent() { return this.currentComponent }
  },
  methods: {
    ...mapMutations(["setCenterIdentificationDone", "setFacilitiesAndEquipmentDone", "setFacilitatorsInfoDone",
      "setLearnersInfoDone", "setLearningMaterialsDone", "setInstructionalMaterialsDone",
      "setVocationDone", "setChallengesDone", "setSuggestionsDone", "resetStages"]),

    showCenterIdentification() {
      resetAll(this.screens)
      this.screens.identification = true
      this.currentComponent = this.components[0]
    },

    showFacilitiesAndEquipment() {
      resetAll(this.screens)
      this.screens.facilities = true
      this.setCenterIdentificationDone(true)
      this.currentComponent = this.components[1]      
    },

    showFacilitatorsInfo() {
      resetAll(this.screens)
      this.screens.facilitators = true
      this.setFacilitiesAndEquipmentDone(true)
      this.currentComponent = this.components[2]
    },

    showLearnersInfo() {
      resetAll(this.screens)
      this.screens.learners = true
      this.setFacilitatorsInfoDone(true)
      this.currentComponent = this.components[3]      
    },

    showLearningMaterials() {
      resetAll(this.screens)
      this.screens.learningMaterials = true
      this.setLearnersInfoDone(true)
      this.currentComponent = this.components[4]
    },

    showInstructionalMaterials() {
      resetAll(this.screens)
      this.screens.instructionalMaterials = true
      this.setLearningMaterialsDone(true)
      this.currentComponent = this.components[5]
    },

    showVocationProgram() {
      resetAll(this.screens)
      this.screens.vocation = true
      this.setInstructionalMaterialsDone(true)
      this.currentComponent = this.components[6]
    },

    showChallenges() {
      resetAll(this.screens)
      this.screens.challenges = true
      this.setVocationDone(true)
      this.currentComponent = this.components[7]
    },

    showSuggestion() {
      resetAll(this.screens)
      this.screens.suggestions = true      
      this.setChallengesDone(true)
      this.currentComponent = this.components[8]
    },

    // resetStages() {
    //   this.resetStages()
    // }
  }
}
</script>