<template>
  <div class="px-5">
    <p class="font-md fw-bold mt-2 mb-4">Learning Materials Available for Different Programmes</p>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <section class="first">      
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Learning material </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter name of learning material"            
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Programme </label>
              <select class="form-select">  
              <option value="">Select programme</option>
              <option value="basic literacy">Basic Literacy</option>
              <option value="post literacy">Post Literacy</option>
              <option value="literacy by radio">Literacy By Radio</option>
              <option value="NFE girl-child education">NFE Girl-Child Education</option>
              <option value="NFE out-of-school (boys education)">NFE Out-Of-School (Boys Education)</option>
              <option value="integrated qur'anic education">Integrated Qur'anic Education</option>
              <option value="workers education">Workers Education</option>
              <option value="normadic adult literacy">Normadic Adult Literacy</option>
              <option value="women in purdah">Women In Purdah</option>
              <option value="women education">Women Education</option>
              <option value="literacy for correctional education">Literacy For Correctional Education</option>
              <option value="NFE out-of-school (girls education)">NFE Out-Of-School (Girls Education)</option>
              <option value="e-learning">E-Learning</option>
              <option value="traditional class (mixed)">Traditional Class (Mixed)</option>
              <option value="vocational education">Vocational Education</option>
              <option value="continuing education">Continuing Education</option>
              <option value="Others">Others</option>
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Source </label>
            <select class="form-select">
              <option value="">Select source</option>
              <option value="personal">Personal</option>
              <option value="provided by an organisation">Provided by an organisation</option>              
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number available </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter number available" />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Number required </label>
            <input
              type="number"
              class="form-control"
              placeholder="Enter number required" />
          </div>
          
          <div class="d-flex align-items-center justify-content-center">
            <span
              class="p-2 d-flex align-items-center justify-content-center pointer-no-bg"              
            >
              <span class="material-icons-outlined dm-color me-1">
                add_circle_outline
              </span>
              <span class="font-sm fw-normal dm-color my-3"> Save and add new material </span>
            </span>
          </div>
        </div>
      </section>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <input
        type="submit"
        class="custom-btn font-tiny fw-bold"
        value="Continue"
        @click="$emit('continue')"
      />
      <span class="font-sm fw-normal dm-color my-3 pointer-no-bg">
        Save and continue later
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LearningMaterials"
}
</script>

<style scoped>

</style>