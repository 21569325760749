<template>
  <div>
    <div class="container bg-white shadow-sm radius-half p-3 font-tiny mt-3">
      <a-table 
        class="ant-table-striped" 
        size="small" 
        :columns="columns" 
        :dataSource="data"            
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" 
      >
        <template #bodyCell="{ column, record }" >       
          <template v-if="column.dataIndex === 'operation'" >   
            <a-space>
              <a-button @click="viewSchoolSubmissions(record.key)">
                View
              </a-button>
              <a-upload
                :file-list="fileList"
                name="file"
                action="https://emis.vhdo.org/undertaking/signature/" + record.key
                :headers="headers"
                @change="handleChange"
              >
                <a-button>
                  Upload
                </a-button>
              </a-upload>       
            </a-space>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Table, Button, Space, Upload, message } from "ant-design-vue"
import { useStore } from 'vuex'
import { onMounted, ref } from 'vue'

export default {
  name: "AssignedSchoolsTable",
  emits: ['viewSubmission'],
  components: {
    'a-table': Table,
    'a-space': Space,
    'a-button': Button,
    'a-upload': Upload,
  },
  setup(props, {emit}) {
    const store = useStore()
    const fileList = ref()    
    onMounted(() => {
      store.dispatch('fetchSupervisorInstitutions')
    })

    const columns = [
      { title: 'Institution name', dataIndex: 'institutionName', width: "50%" },
      { title: 'Session', dataIndex: 'session', width: "30%" },
      { title: 'operation', dataIndex: 'operation'},
    ]

    const viewSchoolSubmissions = (key) => {
      emit('viewSubmission', key)    
    }

    const handleChange = (info) => {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }

      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully!`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file failed to upload!`)
      }
    }

    return {
      columns,
      fileList, 
      headers: {
        authorization: 'authorization-text',
      },
      viewSchoolSubmissions,
      handleChange,
    }
  },
  computed: {
    data() {
      const data = this.$store.state.questionnaireService.supervisorInstitutions
      if (data == null) {
        return []
      } else {
        return data.map( row => ({
          key: row.id,
          institutionName: row.institutionName,
          session: row.session,
        }))
      }

    }
  }
}
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td, th {
  background-color: #f4f5fc;
}
</style>