<template>
  <div>
    <p v-if="showTitle" class="font-md fw-bold my-4">Staff</p>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <div class="row justify-content-between px-2 pb-4">
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> First name </label>
          <input
              type="text"
              class="form-control"
              :value="staffInfo.firstName"
              :disabled="!showTitle"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Last name </label>
          <input
              type="text"
              class="form-control"
              :value="staffInfo.lastName" 
              :disabled="!showTitle"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Staff file number</label>
          <input
              type="text"
              class="form-control"
              :value="staffInfo.staffFileNo"
              :disabled="!showTitle"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Gender </label>
          <input
              type="text"
              class="form-control"
              :value="lodash.capitalize(staffInfo.gender)"
              :disabled="!showTitle"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Source of salary </label>
          <input
              type="text"
              class="form-control"
              :value="staffInfo.sourceOfSalary"
              :disabled="!showTitle"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Date of birth </label>
          <input
              class="form-control"
              type="text"
              :value="staffInfo.dateOfBirth"
              :disabled="!showTitle" />

        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            What level(s) does this staff teaches?
          </label>
          <input
              class="form-control"
              type="text"
              :value="staffInfo.schoolLevel"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Year of first appointment
          </label>
          <input
              class="form-control"
              type="text"
              :value="staffInfo.yearOfAppointment"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Year of current appointment
          </label>
          <input
              class="form-control"
              type="text"
              :value="staffInfo.yearOfPresentAppointment"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Year of posting to this school
          </label>
          <input
              class="form-control"
              type="text"
              :value="staffInfo.yearOfPostingToSchool"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Grade level/step </label>
          <input
              class="form-control"
              type="text"
              :value="lodash.capitalize(staffInfo.gradeLevel.replace('_', ' '))"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Presence </label>
          <input
              class="form-control"
              type="text"
              :value="lodash.capitalize(staffInfo.present).replace('_', ' ')"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Academic qualification
          </label>
          <input
              class="form-control"
              type="text"
              :value="lodash.capitalize(staffInfo.academicQualification).replace('_', ' ')"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Teaching qualification
          </label>
          <input
              class="form-control"
              type="text"
              :value="lodash.capitalize(staffInfo.teachingQualification)"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Subject of qualification
          </label>
          <input
              class="form-control"
              type="text"
              :value="lodash.capitalize(staffInfo.subjectOfQualification)"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Area of specialization
          </label>
          <input
              class="form-control"
              type="text"
              :value="lodash.capitalize(staffInfo.areaOfSpecialization)"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Main subject taught
          </label>
          <input
              class="form-control"
              type="text"
              :value="lodash.capitalize(staffInfo.mainSubjectTaught)"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Type of staff
          </label>
          <input
              class="form-control"
              type="text"
              :value="lodash.capitalize(staffInfo.typeOfStaff).replace('_', ' ')"
              :disabled="!showTitle" />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Teaching type </label>
          <input
              class="form-control"
              name="typeOfTeaching"
              :value="staffInfo.teachingType"
              :disabled="!showTitle" />
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Does this teacher teach junior classes in
            this school?
          </label>
          <input
              class="form-control"
              :value="staffInfo.teachJunior"
              disabled
          />
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Does this teacher teach senior classes in
            this school?
          </label>
          <input
              class="form-control"
              disabled
              :value="staffInfo.teachSenior" />
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Has this teacher attended workshops in the past 12 months?
          </label>
          <input
              class="form-control"
              :value="staffInfo.attendedTrainingSeminarWorkshop"
              disabled />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";
export default {
  name: "StaffProfile",
  props: ['staffInfo', 'showTitle'],
  setup() {
    return {
      lodash
    }
  }
}
</script>

<style scoped>

</style>