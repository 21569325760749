<template>
  <div class="d-flex flex-column px-0">
    <div class="d-flex justify-content-between flex-nowrap mt-4">
      <div class="col d-flex align-items-center">
        <span class="fw-bold font-md">01</span
        ><span class="font-tiny ms-2">{{ firstItem }}</span>
      </div>
      <div class="col d-flex align-items-center">
        <span class="fw-bold font-md">02</span
        ><span class="font-tiny ms-2">{{ secondItem }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between flex-nowrap mb-2">
      <div class="col d-flex align-items-center pe-2">
        <i
          :class="[firstPhase ? 'bi bi-check-circle-fill' : 'bi bi-circle']"
          class="font-lg dm-color overflow-hidden"
          @click="$emit('gotoPhaseOne')"
        ></i>
        <span
          :class="[secondPhase ? 'progression-solid' : 'longer-progress-dotted']"
          class="flex-grow-1 ms-2"
        ></span>
      </div>
      <div class="col d-flex align-items-center pe-2">
        <i
          :class="[secondPhase ? 'bi bi-check-circle-fill' : 'bi bi-circle']"
          class="font-lg dm-color overflow-hidden"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressHeader",
  props: ["firstPhase", "secondPhase", "firstItem", "secondItem"],
};
</script>