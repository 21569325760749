<template>
  <div>
    <section>
      <p class="fw-bold">NABTEB examination in the previous school year</p>
      <div class="d-flex mb-2">
        <div class="col-6"></div>
        <span class="col-3 text-center font-sm fw-normal">Male</span>
        <span class="col-3 text-center font-sm fw-normal">Female</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">How many students were registered for NABTEB?</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="nabtebMale.registeredStudents" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="nabtebFemale.registeredStudents" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-6"><span class="font-sm fw-normal">How many students took part in the NABTEB?</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="nabtebMale.numberOfStudentTookPart" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="nabtebFemale.numberOfStudentTookPart" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex">
        <div class="col-6"><span class="font-sm fw-normal">How many students passed
              (5 credit including English and Maths) in NABTEB exams?</span></div>
        <div class="col-3 me-3">
          <input class="form-control outline" v-model="nabtebMale.numberThatPassed" type="number" aria-label="male">
        </div>
        <div class="col-3">
          <input class="form-control outline" v-model="nabtebFemale.numberThatPassed" type="number" aria-label="female">
        </div>
      </div>
    </section>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  name: "NabTebCategory",
  setup() {
    const store = useStore()
    const ownSchool = computed(() => store.state.school.ownSchool.id)
    const nabtebMale = ref({
      examination: "",
      registeredStudents: "",
      numberOfStudentTookPart: "",
      numberThatPassed: "",
      gender: "MALE"
    })
    const nabtebFemale = ref({
      examination: "",
      registeredStudents: "",
      numberOfStudentTookPart: "",
      numberThatPassed: "",
      gender: "FEMALE"
    })

    const submitForm = async () => {
      const body = {
        requestBody: [nabtebMale.value, nabtebFemale.value],
        school: ownSchool.value
      }

      store.dispatch('submitNABTEBEnrolment', body)
        .then(() => {
          if (store.state.questionnaireService.responseCode === 201) {

            this.$notification.open({
              type: 'success',
              message: 'Record created',
            })
            nabtebMale.value = {
              examination: "",
              registeredStudents: "",
              numberOfStudentTookPart: "",
              numberThatPassed: "",
              gender: "MALE"
            }
            nabtebFemale.value = {
              examination: "",
              registeredStudents: "",
              numberOfStudentTookPart: "",
              numberThatPassed: "",
              gender: "FEMALE"
            }
          } else {
            this.$notification.open({
              type: 'error',
              message: 'Something failed',
            })
          }
        })
    }

    return {
      nabtebMale,
      nabtebFemale,
      submitForm,
    }
  }
}
</script>

<style scoped>

</style>