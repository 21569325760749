<template>
   <div>
       <div>
          <pre-primary-and-primary v-if="isRegularSchoolAndHasPrimary" />
        <junior-sec-school v-if="hasJSS" />
        <senior-sec-school v-if="isRegularSchool && hasSSS" />
        <nab-teb-category v-if="!isRegularSchool" />  
     </div>
   </div>
</template>

<script>
import {mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import {onMounted, ref} from "vue";
import dataCollectionMixin from "@/helpers/mixins/dataCollectionMixin";
import PrePrimaryAndPrimary from "@/components/dashboardPage/school/ASCDashboard/questionnaire/levels/PrePrimaryAndPrimary";
import JuniorSecSchool from "@/components/dashboardPage/school/ASCDashboard/questionnaire/levels/JuniorSecSchool";
import SeniorSecSchool from "@/components/dashboardPage/school/ASCDashboard/questionnaire/levels/SeniorSecSchool";
import NabTebCategory from "@/components/dashboardPage/school/ASCDashboard/questionnaire/levels/NabTebCategory";

export default {
    name: 'BasicStepperForm',
    components: {
        PrePrimaryAndPrimary,
        JuniorSecSchool,
        SeniorSecSchool,
        NabTebCategory
    },
  setup(){
      const store = useStore()

      onMounted(() => {
      store.dispatch('fetchMySchool')
      // console.log(hasSSS);
    })

    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const levels = ref([])
    if (schoolInfo !== null) {
      levels.value = schoolInfo.levelsOffered.map(item => item.toLowerCase())
    }

    const hasPrePrimary = levels.value.includes("pre_primary")
    const hasPreNPrimary = levels.value.includes("primary")
    const hasJSS = levels.value.includes("junior")
    const hasSSS = levels.value.includes("senior")
    const schoolCategory = schoolInfo.schoolType
    const specials = ["VOCATION_TRAINING", "SCIENCE_TECHNOLOGY", "SCIENCE_TECHNOLOGY_VOCATIONAL"]
    const isRegularSchool = !specials.includes(schoolCategory)
    const isRegularSchoolAndHasPrimary = isRegularSchool && hasPreNPrimary
  
    return {
      hasJSS,
      hasSSS,
      isRegularSchoolAndHasPrimary,
      isRegularSchool,
      hasPrePrimary,
    }
  },
     computed: {
    message() {
      return this.$store.state.questionnaireService.message
    },

    hasMessage() {
      return this.message !== null
    },

    activeStage() {
      return this.$store.state.questionnaireService.activeEnrolmentStage
    }
  },
  methods: {
    ...mapMutations(['setQAMessage', 'setResponseCode']),

    closeModal() {
      this.setQAMessage(null)
      this.setResponseCode(null)
    }
  }
    
}
</script>