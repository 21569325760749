<template>
  <div class="d-flex flex-column">    
    <div v-if="false" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <p class="font-md fw-bold mt-3 mb-2">{{ lga }}</p>
    <div class="bg-white radius-full shadow-sm mb-5">
      <table class="table table-hover font-sm" aria-label="Users table">
        <thead>
          <tr>
            <th class="align-middle py-3 ps-4" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>School Name</span>            
              </div>
            </th>
            <th class="align-middle py-3" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Submission date</span>            
              </div>
            </th>
            <th class="align-middle py-3" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Date updated</span>            
              </div>
            </th>
            <th class="align-middle py-3" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Status</span>            
              </div>
            </th>
            <th class="align-middle py-3" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="pointer-no-bg"
            v-for="(school, index) in schools"
            :key="index"            
          >
            <td class="align-middle py-3 ps-4">
              {{ school.name }}
            </td>
            <td class="py-3">{{ school.subDate }}</td>
            <td class="py-3">{{ school.dateUdpated }}</td>
            <td class="py-3">{{ school.status }}</td>
            <td class="text-end dropend pe-2 py-3">
              <span @click.stop class="p-3">
                <i
                  class="bi bi-three-dots-vertical grey-accent px-3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                ></i>
                <ul
                  class="
                    dropdown-menu
                    action-menu
                    bg-accent
                    shadow
                    font-tiny
                    radius-half
                  "
                  aria-labelledby="options"
                >
                  <li>
                    <span class="dropdown-item grey-accent" href="#"
                      ><i class="fw-bold font-sm bi bi-x-lg me-2"></i>
                      Delete</span
                    >
                  </li>
                </ul>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "LGAValidation",
  components: { ClipLoader },
  setup() {
    const schools = [
      {
        id: 1,
        name: "John Quincy Adams Middle School",
        subDate: "12-12-2001",
        dateUdpated: "12-2-2002",
        status: "In progress"
      },
      {
        id: 2,
        name: "Demonstration Secondary School",
        subDate: "12-12-2001",
        dateUdpated: "12-2-2002",
        status: "In progress"
      },
      {
        id: 3,
        name: "Olivet Heights International School",
        subDate: "12-12-2001",
        dateUdpated: "12-2-2002",
        status: "In progress"
      },
      {
        id: 4,
        name: "Skykrest Junior and Primary School",
        subDate: "12-12-2001",
        dateUdpated: "12-2-2002",
        status: "In progress"
      },
    ]
    return {
      lga: "Damaturu",
      schools
    }
  },
}
</script>